<script setup lang="ts">
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { createCompleteSurvey } from '@/composables/repos/SurveyRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'
import SurveyInputFundsAsync from '@/components/survey/SurveyInputFundsAsync.vue'

const { t } = useTrans({
  en: {
    i_created_by_label: 'GB creator *',
    i_created_by_ph: 'Select a member',
    i_company_label: 'Company name *',
    i_company_ph: 'Example Corporation',
    i_responder_name_label: 'Responder name *',
    i_responder_name_ph: 'John Doe',
    i_responder_title_label: 'Responder title *',
    i_responder_title_ph: 'CEO',
    submit: 'Register on behalf'
  },
  ja: {
    i_created_by_label: 'GBの作成者 *',
    i_created_by_ph: 'メンバーを選択',
    i_company_label: '会社名 *',
    i_company_ph: 'サンプル・カンパニー',
    i_responder_name_label: '回答者氏名 *',
    i_responder_name_ph: '山田 太郎',
    i_responder_title_label: '回答者役職 *',
    i_responder_title_ph: 'CEO',
    submit: '代理登録する'
  }
})

const router = useRouter()

const { data: users } = useActiveUserList()

const createCompleteSurveyMutation = reactive(createCompleteSurvey())

const userOps = useUserOps()
const surveyOps = useSurveyOps()

const { data } = useData({
  createdById: null as number | null,
  companyName: null as string | null,
  responderName: null as string | null,
  responderTitle: null as string | null,
  fundGroupIds: [] as number[]
})

const { validation, validateAndNotify } = useValidation(data, {
  createdById: {
    required: required()
  },
  companyName: {
    required: required(),
    maxLength: maxLength(255)
  },
  responderName: {
    required: required(),
    maxLength: maxLength(70)
  },
  responderTitle: {
    required: required(),
    maxLength: maxLength(255)
  }
})

const memberOptions = computed(() => {
  return userOps.createDropdownOptions(users.value ?? [])
})

async function submit() {
  if (await validateAndNotify()) {
    const survey = await createCompleteSurveyMutation.execute({
      createdById: data.value.createdById!,
      companyName: data.value.companyName!,
      responderName: data.value.responderName!,
      responderTitle: data.value.responderTitle!,
      fundGroupIds: data.value.fundGroupIds
    })

    router.push(surveyOps.path(survey))
  }
}
</script>

<template>
  <Suspense>
    <SCard>
      <SCardBlock class="s-p-48">
        <SDoc>
          <SContent>
            <h1>
              <STrans lang="en">Register a survay on behalf</STrans>
              <STrans lang="ja">Surveyを代理登録する</STrans>
            </h1>
          </SContent>
          <SInputDropdown
            :label="t.i_created_by_label"
            :placeholder="t.i_created_by_ph"
            :options="memberOptions"
            v-model="data.createdById"
            :validation="validation.createdById"
          />
          <SInputText
            :label="t.i_company_label"
            :placeholder="t.i_company_ph"
            v-model="data.companyName"
            :validation="validation.companyName"
          />
          <SInputText
            :label="t.i_responder_name_label"
            :placeholder="t.i_responder_name_ph"
            v-model="data.responderName"
            :validation="validation.responderName"
          />
          <SInputText
            :label="t.i_responder_title_label"
            :placeholder="t.i_responder_title_ph"
            v-model="data.responderTitle"
            :validation="validation.responderTitle"
          />
          <SurveyInputFundsAsync
            v-model="data.fundGroupIds"
          />
        </SDoc>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-48">
        <SControl>
          <SControlRight>
            <SControlButton
              mode="info"
              :label="t.submit"
              :loading="createCompleteSurveyMutation.loading"
              @click="submit"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </Suspense>
</template>
