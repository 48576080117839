import { Model } from './Model'
import { StatSourcingRankingApproach, type StatSourcingRankingApproachInput } from './StatSourcingRankingApproach'

export interface StatSourcingRankingInput {
  newApproaches: StatSourcingRankingApproachInput[]
  reApproaches: StatSourcingRankingApproachInput[]
}

export class StatSourcingRanking extends Model {
  newApproaches: StatSourcingRankingApproach[]
  reApproaches: StatSourcingRankingApproach[]

  constructor(input: StatSourcingRankingInput) {
    super()
    this.newApproaches = this.hasMany(StatSourcingRankingApproach, input.newApproaches)
    this.reApproaches = this.hasMany(StatSourcingRankingApproach, input.reApproaches)
  }
}
