import { type MaybeRefOrGetter, toValue } from 'vue'
import { type CompanyBusinessReportFrag, FundReportRecordStatus, type FundWithIdAndOperatosFrag } from '@/graphql'
import { type PolicyResponse, allow, deny, pending, usePolicyResponse } from '../Policy'

export function useCanSaveCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (
      cbr.status === FundReportRecordStatus.Draft
      || cbr.status === FundReportRecordStatus.ReviewRequested
      || cbr.status === FundReportRecordStatus.InReview
      || cbr.status === FundReportRecordStatus.ChangeRequested
    ) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }
    }

    if (
      cbr.status === FundReportRecordStatus.Draft
      || cbr.status === FundReportRecordStatus.ChangeRequested
    ) {
      if (cbr.company?.primaryInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.deputyInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.participants.some((p) => p.id === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanRequestReviewOnCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.Draft || cbr.status === FundReportRecordStatus.ChangeRequested) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }

      if (cbr.company?.primaryInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.deputyInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.participants.some((p) => p.id === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanStartReviewOnCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.ReviewRequested) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanWithdrawReviewOnCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.ReviewRequested) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }

      if (cbr.company?.primaryInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.deputyInCharge?.id === user.id) {
        return allow()
      }

      if (cbr.company?.participants.some((p) => p.id === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanRequestChangesOnCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.InReview) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanCompleteCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.InReview) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanIncompleteCompanyBusinessReport(
  companyBusinessReport: MaybeRefOrGetter<CompanyBusinessReportFrag | null | undefined>,
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const cbr = toValue(companyBusinessReport)
    const fnd = toValue(fund)

    if (!cbr) {
      return pending()
    }

    if (cbr.status === FundReportRecordStatus.Completed) {
      if (user.hasRoleGod()) {
        return allow()
      }

      if (fnd?.operators.some((o) => o.userID === user.id)) {
        return allow()
      }
    }

    return deny()
  })
}

export function useCanDeleteCompanyBusinessReport(): PolicyResponse {
  return usePolicyResponse((user) => {
    if (user.hasRoleGod()) {
      return allow()
    }

    return deny()
  })
}

export function useCanExportAllCompanyBusinessReport(
  fund: MaybeRefOrGetter<FundWithIdAndOperatosFrag | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    if (user.hasRoleGod()) {
      return allow()
    }

    const f = toValue(fund)

    return !f
      ? pending()
      : f.operators.some((f) => f.userID === user.id) ? allow() : deny()
  })
}
