import { BallotSetting } from './BallotSetting'
import { Model } from './Model'

export class DealEventTemplate extends Model {
  id?: number
  name?: string
  hasBallot?: boolean
  skippable?: boolean
  reExecutable?: boolean
  rank?: number

  ballotSetting: BallotSetting | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.name = data.name
    this.hasBallot = data.hasBallot
    this.skippable = data.skippable
    this.reExecutable = data.reExecutable
    this.rank = data.rank

    this.ballotSetting = this.hasOne(BallotSetting, data.ballot)
  }
}
