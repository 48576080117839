<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type UserFrag } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'
import { useRetireUser } from '@/composables/repos/UserRepo'

const props = defineProps<{
  user: UserFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'retired'): void
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    retire: 'Retire user'
  },
  ja: {
    cancel: 'キャンセル',
    retire: 'ユーザを退職'
  }
})

const {
  execute: doRetireUser,
  loading: retiringUser
} = useRetireUser()

const userOps = useUserOps()

async function retire() {
  await doRetireUser(props.user.id)
  emit('retired')
}
</script>

<template>
  <SCard size="medium" mode="danger">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Retire this user</h2>
            <p>You are about to retire “<strong>{{ userOps.name(user) }}</strong>”.</p>
            <p>The user will be unassigned from all companies. The role related settings such as user tags, fund assignments will also get removed.</p>
            <p>This action cannot be undone. Make sure that you are retiring the correct user.</p>
          </STrans>
          <STrans lang="ja">
            <h2>ユーザを退職させる</h2>
            <p>「<strong>{{ userOps.name(user) }}</strong>」を退職させます。</p>
            <p>ユーザは全ての企業の担当者から外されます。また、ユーザのロールやファンドの担当設定など、ユーザに紐づく権限設定も削除されます。</p>
            <p>この操作は取り消すことができません。退職させるユーザに間違いがないことを確認してください。</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="large" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.retire"
            :loading="retiringUser"
            @click="retire"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
