import { useTrans } from 'sefirot/composables/Lang'
import {
  type ProposableFundGroupFrag,
  ProposableFundGroupReason,
  ProposalStatus
} from '@/graphql'
import { defineOps } from './Ops'

export const ProposalStatusTextDict = {
  [ProposalStatus.Hidden]: 'Hidden',
  [ProposalStatus.Open]: 'Open',
  [ProposalStatus.Closed]: 'Closed'
} as const

export const useProposalOps = defineOps(() => {
  function statusText(status: ProposalStatus): string {
    return ProposalStatusTextDict[status]
  }

  return {
    statusText
  }
})

export const useProposableFundGroupOps = defineOps(() => {
  const { t: ProposableFundGroupReasonTextDict } = useTrans({
    en: {
      [ProposableFundGroupReason.AlreadyOpen]: 'Already open.',
      [ProposableFundGroupReason.Invested]: 'Invested in the past. Must open.',
      [ProposableFundGroupReason.InitialReviewMeetingNotCompleted]: 'Initial Review Meeting not completed. Cannot open.',
      [ProposableFundGroupReason.SourcedByLp]: 'The opportunity is sourced by LP. Free select.',
      [ProposableFundGroupReason.NotSurveyTarget]: 'Survey not required. Free select.',
      [ProposableFundGroupReason.OpenByNoSurvey]: 'Survey required but no survey is linked. Must open.',
      [ProposableFundGroupReason.OpenBySurvey]: 'Survey required and survey is linked. Must open.',
      [ProposableFundGroupReason.HiddenBySurvey]: 'Survey required and survey is linked. Must not open.'
    },
    ja: {
      [ProposableFundGroupReason.AlreadyOpen]: '既にOpenしています。',
      [ProposableFundGroupReason.Invested]: '投資済みファンドです。Openにする必要があります。',
      [ProposableFundGroupReason.InitialReviewMeetingNotCompleted]: 'Initial Review Meetingが完了していません。Openにできません。',
      [ProposableFundGroupReason.SourcedByLp]: 'LPソーシングの案件のため自由選択です。',
      [ProposableFundGroupReason.NotSurveyTarget]: 'Survey対象外のため自由選択です。',
      [ProposableFundGroupReason.OpenByNoSurvey]: 'Survey対象ですがSurveyが紐づいていません。Openにする必要があります。',
      [ProposableFundGroupReason.OpenBySurvey]: 'Survey対象でSurveyが紐づいています。Openにする必要があります。',
      [ProposableFundGroupReason.HiddenBySurvey]: 'Survey対象でSurveyが紐づいています。Openにできません。'
    }
  })

  function reasonText(pfg: ProposableFundGroupFrag): string {
    return ProposableFundGroupReasonTextDict[pfg.reason]
  }

  return {
    reasonText
  }
})
