import { DealEventType } from '@/graphql'
import { BallotResult, BallotResultType } from './BallotResult'
import { type Day, Model } from './Model'

export { DealEventType }

export class DealEvent extends Model {
  id?: number
  templateId?: number
  type?: DealEventType
  result?: BallotResultType
  date: Day | null
  skipReason?: string | null
  reExecutedReason?: string | null
  createdById?: number
  createdAt: Day | null
  updatedById?: number
  updatedAt: Day | null

  ballotResult: BallotResult | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.templateId = data.templateId
    this.type = data.type
    this.result = data.result
    this.date = this.day(data.date)
    this.skipReason = data.skipReason
    this.reExecutedReason = data.reExecutedReason
    this.createdById = data.createdById
    this.createdAt = this.day(data.createdAt)
    this.updatedById = data.updatedById
    this.updatedAt = this.day(data.updatedAt)

    this.ballotResult = this.hasOne(BallotResult, data.ballot)
  }

  isExecuted(): boolean {
    return this.type === DealEventType.Executed
  }

  isReExecuted(): boolean {
    return this.type === DealEventType.ReExecuted
  }

  isDone(): boolean {
    return this.isExecuted() || this.isReExecuted()
  }

  isSkipped(): boolean {
    return this.type === DealEventType.Skipped
  }

  isApproved(): boolean {
    return this.result === BallotResultType.Approved
  }

  isConditionallyApproved(): boolean {
    return this.result === BallotResultType.ConditionallyApproved
  }

  isSuccess(): boolean {
    return this.isApproved() || this.isConditionallyApproved()
  }

  isRejected(): boolean {
    return this.result === BallotResultType.Rejected
  }
}
