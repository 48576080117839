<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import {
  type DealEventAndBallotFrag,
  type DealEventFrag,
  type DealEventTemplateAndBallotFrag,
  type DealEventTemplateFrag,
  type DealWithIdFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useState } from '@/composables/State'
import DealCardEventListDelete from './DealCardEventListDelete.vue'
import DealCardEventListDesc from './DealCardEventListDesc.vue'
import DealCardEventListUpdate from './DealCardEventListUpdate.vue'

defineProps<{
  deal: DealWithIdFrag & WithPermissionFrag
  template: DealEventTemplateFrag | DealEventTemplateAndBallotFrag
  event: DealEventFrag | DealEventAndBallotFrag
}>()

const emit = defineEmits<{
  (e: 'saved'): void
}>()

const mode = useState<'view' | 'edit' | 'delete'>('view')
const deleteModal = usePower(false)

function onSaved() {
  emit('saved')
  mode.set('view')
}
</script>

<template>
  <DealCardEventListDesc
    v-if="mode.data.value === 'view'"
    :deal="deal"
    :template="template"
    :event="event"
    @edit="mode.set('edit')"
    @delete="deleteModal.on"
  />
  <DealCardEventListUpdate
    v-else-if="mode.data.value === 'edit'"
    :template="template"
    :event="event"
    @saved="onSaved"
    @cancel="mode.set('view')"
  />
  <DModal size="small" :open="deleteModal.state.value">
    <DealCardEventListDelete
      :event="event"
      @cancel="deleteModal.off"
      @deleted="$emit('saved')"
    />
  </DModal>
</template>
