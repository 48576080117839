import { GeneratedCreateCompanyInputDocument } from '@/graphql'
import { Request } from './Request'

export class GeneratedCreateCompanyInputRequest extends Request {
  async fetch(url: string, countryId: number) {
    return this.request({
      query: GeneratedCreateCompanyInputDocument,
      variables: {
        url,
        countryId
      }
    })
  }
}
