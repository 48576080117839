import { type ViewCondition, type ViewOrder } from '@/graphql'
import { type Day, Model } from './Model'
import { User } from './User'
import { type ViewSchema } from './ViewSchema'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export class View extends Model {
  subject = AccessResource.view
  permittedActions!: AccessControlled['permittedActions']

  id?: number

  viewSchemaId?: number
  viewSchema: ViewSchema
  entityIds: string[]
  sharedUsers: User[]
  lastExportedBy: User | null
  name?: string
  formula?: string
  orders: ViewOrder[]
  conditions: ViewCondition[]
  frozen?: boolean
  lastExportedAt?: string
  exportAvailableAt: Day | null
  createdAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.permittedActions = data.permittedActions ?? []

    this.id = data.id

    this.viewSchemaId = data.viewSchemaId
    this.viewSchema = data.viewSchema
    this.entityIds = data.entityIds
    this.sharedUsers = this.hasMany(User, data.sharedUsers)
    this.lastExportedBy = this.hasOne(User, data.lastExportedBy)
    this.name = data.name
    this.formula = data.formula
    this.orders = data.orders
    this.conditions = data.conditions
    this.frozen = data.frozen
    this.lastExportedAt = data.lastExportedAt
    this.exportAvailableAt = this.day(data.exportAvailableAt)
    this.createdAt = this.day(data.createdAt)
  }

  get path(): string {
    return `/views/${this.id}`
  }
}
