<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})
</script>

<template>
  <DCard :header="false">
    <DCardClose @close="$emit('close')" />

    <DForm>
      <DFormSection>
        <STrans lang="en">
          <DFormTitle>Deal completed</DFormTitle>
          <DFormMedium>
            <p>Congratulations. We sincerely appreciate your contributions. The inputted information will be utilized for the growth of GB.</p>
            <p>Completed deals are locked for editing to ensure consistency, including statistical information. If updates are needed, please contact the system administrator.</p>
          </DFormMedium>
        </STrans>
        <STrans lang="ja">
          <DFormTitle>ディールをコンプリートしました</DFormTitle>
          <DFormMedium>
            <p>おめでとうございます。あなたの貢献に心から感謝します。入力された情報はGBの成長に活用されます。</p>
            <p>コンプリートされたディールは、統計情報などの整合性を確保するために編集がロックされます。更新が必要な場合にはシステム管理者までご連絡ください。</p>
          </DFormMedium>
        </STrans>
      </DFormSection>
    </DForm>

    <DCardFooter>
      <DCardFooterActions>
        <DCardFooterAction
          :label="t.close"
          @click="$emit('close')"
        />
      </DCardFooterActions>
    </DCardFooter>
  </DCard>
</template>
