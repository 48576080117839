<script setup lang="ts">
import IconCaretDown from '~icons/ph/caret-down-bold'
import { useFlyout } from 'sefirot/composables/Flyout'
import { ref } from 'vue'
import { useMe } from '@/composables/Auth'
import DAvatar from '../DAvatar.vue'
import LayoutMainHeaderAccountDialog from './LayoutMainHeaderAccountDialog.vue'

const { user } = useMe()

const container = ref<any>(null)

const { isOpen, toggle } = useFlyout(container)
</script>

<template>
  <div class="LayoutMainHeaderAccount" @click="toggle">
    <div class="container" role="button" ref="container">
      <div class="avatar">
        <DAvatar :user="user" />
      </div>

      <div class="data">
        <p class="name">{{ user.name }}</p>
      </div>

      <button class="open">
        <IconCaretDown class="open-icon" />
      </button>

      <transition name="fade">
        <div v-show="isOpen" class="dialog">
          <LayoutMainHeaderAccountDialog />
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.LayoutMainHeaderAccount {
  position: relative;
  height: 56px;
  background-color: var(--c-bg-elv-2);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 0 20px;
  height: 56px;
}

.avatar {
  transition: box-shadow .25s;
}

.data {
  padding-left: 12px;
}

.name {
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-1);
  white-space: nowrap;
  transition: color 0.25s;

  .LayoutMainHeaderAccount:hover & {
    color: var(--c-text-2);
  }
}

.open {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 40px;
  height: 48px;
  color: var(--c-text-2);
  transition: color 0.25s;

  .LayoutMainHeaderAccount:hover & {
    color: var(--c-text-3);
  }
}

.open-icon {
  width: 12px;
  height: 12px;
  fill: currentColor;
}

.dialog {
  position: absolute;
  top: 52px;
  right: 8px;
  transition: opacity 0.25s, transform 0.25s;
}

.dialog.fade-enter-from,
.dialog.fade-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}
</style>
