<script setup lang="ts">
import SInputDropdown, { type Option as DropdownOption } from 'sefirot/components/SInputDropdown.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { type OpportunityInboundOrOutbound } from '@/models/OpportunityInboundOrOutbound'
import { type OpportunityPromotedSource } from '@/models/OpportunityPromotedSource'
import { type User } from '@/models/User'
import { useState } from '@/composables/State'
import { useCompanyPortfolioDataUpdatePromoter } from '@/composables/company/CompanyData'
import { useUserOps } from '@/composables/ops/UserOps'
import { useOpportunityPromotedSourceList } from '@/composables/repos/OportunityPromotedSourceRepo'
import { useOpportunityInboundOrOutboundList } from '@/composables/repos/OpportunityInboundOrOutboundRepo'
import { useUserActiveCollection } from '@/composables/user/UserData'
import CompanyPortfolioDataFormUpdatePromoterConfirm from '@/components/company/CompanyPortfolioDataFormUpdatePromoterConfirm.vue'

export interface Data {
  promoterIds: NonNullable<User['id']>[]
  promotedInboundOrOutboundId: NonNullable<OpportunityInboundOrOutbound['id']> | null
  promotedSourceId: NonNullable<OpportunityPromotedSource['id']> | null
  promotedBackground: CompanyPortfolioData['promotedBackground']
}

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const userOps = useUserOps()

const { data } = useData<Data>({
  promoterIds: props.portfolioData.promoters.map(({ id }) => id!),
  promotedInboundOrOutboundId: props.portfolioData.promotedInboundOrOutbound?.id ?? null,
  promotedSourceId: props.portfolioData.promotedSource?.id ?? null,
  promotedBackground: props.portfolioData.promotedBackground
})

const { validation, validateAndNotify } = useValidation(data, {
  promoterIds: {
    required: required()
  },
  promotedInboundOrOutboundId: {
    required: required()
  },
  promotedSourceId: {
    required: required()
  },
  promotedBackground: {
    required: required(),
    maxLength: maxLength(4000)
  }
})

const { data: users } = useUserActiveCollection()
const promoterOptions = computed(() => (users.value || []).map<DropdownOption>((user) => ({
  type: 'avatar',
  image: userOps.avatarPath(user as UserFrag),
  label: user.name,
  value: user.id!,
  disabled: !user.isActive()
})))

const { data: inboundOrOutbound } = useOpportunityInboundOrOutboundList()
const inboundOrOutboundOptions = computed(() => (inboundOrOutbound.value || []).map<DropdownOption>((ioro) => ({
  type: 'text',
  label: ioro.name!,
  value: ioro.id!,
  disabled: !ioro.enabled
})))

const { data: promotedSources } = useOpportunityPromotedSourceList()
const promotedSourceOptions = computed(() => (promotedSources.value || []).map<DropdownOption>((promotedSource) => ({
  type: 'text',
  label: promotedSource.name!,
  value: promotedSource.id!,
  disabled: !promotedSource.enabled
})))

const { data: modal, set, clear } = useState<'updating' | 'confirming'>('updating')

const { execute, loading } = useCompanyPortfolioDataUpdatePromoter(props.portfolioData.companyId!, () => ({
  promoterIds: data.value.promoterIds,
  promotedInboundOrOutboundId: data.value.promotedInboundOrOutboundId!,
  promotedSourceId: data.value.promotedSourceId!,
  promotedBackground: data.value.promotedBackground!
}))

async function confirm() {
  if (await validateAndNotify()) {
    set('confirming')
  }
}

async function update() {
  await execute()
  emit('updated')
}
</script>

<template>
  <DForm>
    <DFormTitle>Update promoter</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputDropdown
          label="Promoters *"
          placeholder="Select promoters"
          v-model="data.promoterIds"
          :options="promoterOptions"
          :validation="validation.promoterIds"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputSelect
          label="Inbound / Outbound *"
          placeholder="Select type"
          v-model="data.promotedInboundOrOutboundId"
          :options="inboundOrOutboundOptions"
          :validation="validation.promotedInboundOrOutboundId"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputSelect
          label="Promoted source *"
          placeholder="Select type"
          v-model="data.promotedSourceId"
          :options="promotedSourceOptions"
          :validation="validation.promotedSourceId"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Contact background *"
          placeholder="How did the promoter meet the company?"
          :rows="8"
          v-model="data.promotedBackground"
          :validation="validation.promotedBackground"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="confirm" />
      </DFormFooterActions>
    </DFormFooter>

    <SModal :open="modal === 'confirming'">
      <CompanyPortfolioDataFormUpdatePromoterConfirm
        @confirmed="clear(() => update())"
        @cancel="clear(() => set('updating'))"
      />
    </SModal>
  </DForm>
</template>
