<script setup lang="ts">
import IconInfo from '~icons/ph/info'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useOpportunityInitialReviewMeetingOps, useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useDashboardDdTeamUpcomingOpportunityPage } from '@/composables/repos/DashboardRepo'
import DashboardDdTeamUpcomingOpportunityCatalogAbout from './DashboardDdTeamUpcomingOpportunityCatalogAbout.vue'

const { t } = useTrans({
  en: {
    title: 'Upcoming opportunities',
    id: 'Oppo ID',
    company: 'Company',
    round: 'Round',
    irm_created_at: 'IRM entry date',
    irm_status: 'IRM status',
    pic: 'Primary in-charge',
    dic: 'Deputy in-charge',
    createdAt: 'Created at',
    updatedAt: 'Updated at'
  },
  ja: {
    title: 'Upcoming opportunities',
    id: 'Oppo ID',
    company: 'Company',
    round: 'Round',
    irm_created_at: 'IRM申請日',
    irm_status: 'IRMステータス',
    pic: '主担当',
    dic: '副担当',
    createdAt: '作成日時',
    updatedAt: '更新日時'
  }
})

const { data: options } = useData({
  page: 1,
  perPage: 50
})

useUrlQuerySync(options, {
  casts: { page: Number },
  exclude: ['perPage']
})

const { data, loading } = useDashboardDdTeamUpcomingOpportunityPage(options)

const userOps = useUserOps()
const companyOps = useCompanyOps()
const RoundOps = useRoundOps()
const oppoOps = useOpportunityOps()
const irmOps = useOpportunityInitialReviewMeetingOps()

const aboutDialog = usePower()

const table = useTable({
  records: computed(() => data.value?.data ?? []),
  loading,
  borderless: true,
  orders: [
    'id',
    'company',
    'round',
    'irmCreatedAt',
    'irmStatus',
    'pic',
    'dic',
    'createdAt',
    'updatedAt',
    'empty'
  ],
  columns: {
    id: {
      label: t.id,
      cell: (_, oppo) => ({
        type: 'text',
        value: oppoOps.displayId(oppo),
        link: oppoOps.path(oppo),
        color: 'info'
      })
    },
    company: {
      label: t.company,
      cell: (_, oppo) => ({
        type: 'text',
        value: companyOps.name(oppo.company),
        link: companyOps.path(oppo.company)
      })
    },
    round: {
      label: t.round,
      cell: (_, oppo) => ({
        type: 'text',
        value: oppo.round ? RoundOps.name(oppo.round) : null
      })
    },
    irmCreatedAt: {
      label: t.irm_created_at,
      cell: (_, oppo) => {
        const irm = oppoOps.latestInitialReviewMeeting(oppo as any)
        const value = irm ? irmOps.createdAt(irm) : null
        return { type: 'day', value, format: 'YYYY-MM-DD' }
      }
    },
    irmStatus: {
      label: t.irm_status,
      cell: (_, oppo) => {
        const irm = oppoOps.latestInitialReviewMeeting(oppo as any)
        const state = irm ? irmOps.resultState(irm) : { label: 'Unkown' }
        return { type: 'state', ...state }
      }
    },
    pic: {
      label: t.pic,
      cell: (_, oppo) => {
        const user = companyOps.picUser(oppo.company)
        return {
          type: 'avatar',
          name: user ? userOps.name(user) : null,
          image: user ? userOps.avatarPath(user) : null
        }
      }
    },
    dic: {
      label: t.dic,
      cell: (_, oppo) => {
        const user = companyOps.dicUser(oppo.company)
        return {
          type: 'avatar',
          name: user ? userOps.name(user) : null,
          image: user ? userOps.avatarPath(user) : null
        }
      }
    },
    createdAt: {
      label: t.createdAt,
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.createdAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: t.updatedAt,
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.updatedAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    empty: {
      cell: { type: 'empty' }
    }
  }
})
</script>

<template>
  <div class="DashboardDdTeamUpcomingOpportunityCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-pl-16 s-pr-12">
        <SControl>
          <SControlLeft>
            <SControlText>{{ t.title }}</SControlText>
          </SControlLeft>
          <SControlRight>
            <SControlButton
              type="outline"
              mode="mute"
              :icon="IconInfo"
              @click="aboutDialog.on"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SCardBlock class="table">
        <STable :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="data?.meta.total ?? 0"
              :page="data?.meta.page ?? 0"
              :per-page="data?.meta.perPage ?? 0"
              @prev="options.page--"
              @next="options.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
    <SModal :open="aboutDialog.state.value" @close="aboutDialog.off">
      <DashboardDdTeamUpcomingOpportunityCatalogAbout />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.table :deep(.col-id) {
  font-feature-settings: "tnum";
}

.table :deep(.col-id)           { --table-col-width: 144px; }
.table :deep(.col-company)      { --table-col-width: 168px; }
.table :deep(.col-round)        { --table-col-width: 128px; }
.table :deep(.col-irmCreatedAt) { --table-col-width: 144px; }
.table :deep(.col-irmStatus)    { --table-col-width: 240px; }
.table :deep(.col-pic)          { --table-col-width: 168px; }
.table :deep(.col-dic)          { --table-col-width: 168px; }
.table :deep(.col-createdAt)    { --table-col-width: 192px; }
.table :deep(.col-updatedAt)    { --table-col-width: 192px; }
.table :deep(.col-empty)        { --table-col-width: 40px; }
</style>
