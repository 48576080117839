<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { computedWhen } from 'sefirot/composables/Utils'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import {
  type DealWithIdFrag,
  type DealWithRoundCurrencyFrag,
  SecurityKind
} from '@/graphql'
import { useDealSecurityOps } from '@/composables/ops/DealSecurityOps'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { type AddDealSecurityInput, useAddDealSecurity } from '@/composables/repos/DealRepo'
import {
  useAntiDilutionProvisionList,
  useLiquidationPreferencePatternList
} from '@/composables/repos/DealSecurityRepo'
import DInputMoney from '../DInputMoney.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithRoundCurrencyFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'added'): void
}>()

const { data: liquidationPreferencePatterns } = useLiquidationPreferencePatternList()
const { data: antiDilutionProvisions } = useAntiDilutionProvisionList()
const { execute: doAddSecurity, loading } = useAddDealSecurity()

const securityOps = useDealSecurityOps()
const roundOps = useRoundOps()

const { data } = useData({
  kind: 'Tbd' as SecurityKind | 'Tbd',
  name: null as string | null,
  plannedTotalPrice: null as string | null,
  liquidationPreferenceMultiple: null as number | null,
  liquidationPreferencePatternId: null as number | null,
  antiDilutionProvisionId: null as number | null,
  annualInterest: null as number | null,
  repaymentDate: null as Day | null,
  repaymentRight: null as boolean | null,
  discount: null as number | null,
  valuationCap: null as string | null,
  eligibleFinancingAmount: null as string | null
})

const isPreferredStock = computed(() => data.value.kind === SecurityKind.ClassifiedStock)
const isSecurity = computed(
  () =>
    data.value.kind !== 'Tbd'
    && data.value.kind !== SecurityKind.CommonStock
    && data.value.kind !== SecurityKind.ClassifiedStock
)

const { validation, validateAndNotify } = useValidation(data, () => {
  const rules: Record<string, any> = {
    name: {
      maxLength: maxLength(255)
    },
    plannedTotalPrice: {
      required: required(),
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
  }

  if (isPreferredStock.value) {
    rules.liquidationPreferenceMultiple = {
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
  }

  if (isSecurity.value) {
    rules.annualInterest = {
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
    rules.discount = {
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
    rules.valuationCap = {
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
    rules.eligibleFinancingAmount = {
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
  }

  return rules
})

const kindOptions = [
  { label: 'TBD', value: 'Tbd' },
  ...securityOps.kindTextEntries().map(([kind, text]) => ({
    label: text,
    value: kind
  }))
]

const liquidationPreferencePatternOptions = computedWhen(liquidationPreferencePatterns, (liquidationPreferencePatterns) => {
  return liquidationPreferencePatterns.map((pattern) => ({
    label: pattern.name,
    value: pattern.id,
    disabled: !pattern.enabled
  }))
}, [])

const antiDilutionProvisionOptions = computedWhen(antiDilutionProvisions, (antiDilutionProvisions) => {
  return antiDilutionProvisions.map((provision) => ({
    label: provision.name,
    value: provision.id,
    disabled: !provision.enabled
  }))
}, [])

const repaymentRightOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

async function add() {
  if (await validateAndNotify()) {
    await doAddSecurity(props.deal.id, createInput())
    emit('added')
  }
}

function createInput() {
  const input: AddDealSecurityInput = {
    kind: data.value.kind === 'Tbd' ? null : data.value.kind,
    name: data.value.name,
    plannedTotalPrice: data.value.plannedTotalPrice,
    liquidationPreferenceMultiple: null,
    liquidationPreferencePatternId: null,
    antiDilutionProvisionId: null,
    annualInterest: null,
    repaymentDate: null,
    repaymentRight: null,
    discount: null,
    valuationCap: null,
    eligibleFinancingAmount: null
  }

  if (isPreferredStock.value) {
    input.liquidationPreferenceMultiple = stringOrNull(data.value.liquidationPreferenceMultiple)
    input.liquidationPreferencePatternId = data.value.liquidationPreferencePatternId
    input.antiDilutionProvisionId = data.value.antiDilutionProvisionId
  }

  if (isSecurity.value) {
    input.annualInterest = stringOrNull(data.value.annualInterest)
    input.repaymentDate = data.value.repaymentDate?.format('YYYY-MM-DD') ?? null
    input.repaymentRight = data.value.repaymentRight
    input.discount = stringOrNull(data.value.discount)
    input.valuationCap = data.value.valuationCap
    input.eligibleFinancingAmount = data.value.eligibleFinancingAmount
  }

  return input
}

function stringOrNull(value: number | null) {
  return value === null ? null : String(value)
}
</script>

<template>
  <DForm>
    <DFormTitle>Add a new security</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="2">
        <SInputSelect
          label="Security kind"
          :options="kindOptions"
          v-model="data.kind"
        />
      </DFormGridItem>
      <DFormGridItem span="4">
        <SInputText
          label="Security name"
          placeholder="Enter the actual security name"
          :validation="validation.name"
          v-model="data.name"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <DInputMoney
          label="Total amount *"
          placeholder="10,000,000"
          info="The currency is linked to the currency defined in the round details."
          :currency="roundOps.currency(deal.round)"
          v-model="data.plannedTotalPrice"
          :validation="validation.plannedTotalPrice"
        />
      </DFormGridItem>

      <template v-if="isPreferredStock">
        <DFormGridItem span="3">
          <SInputNumber
            label="Liquidation preference (multiple)"
            placeholder="2"
            v-model="data.liquidationPreferenceMultiple"
          />
        </DFormGridItem>
        <DFormGridItem span="3">
          <SInputSelect
            label="Liquidation preference (pattern)"
            placeholder="Not determined yet"
            nullable
            :options="liquidationPreferencePatternOptions"
            v-model="data.liquidationPreferencePatternId"
          />
        </DFormGridItem>
        <DFormGridItem span="3">
          <SInputSelect
            label="Anti-dilution"
            placeholder="Not determined yet"
            nullable
            :options="antiDilutionProvisionOptions"
            v-model="data.antiDilutionProvisionId"
          />
        </DFormGridItem>
      </template>

      <template v-if="isSecurity">
        <DFormGridItem span="2">
          <SInputNumber
            label="Annual interest"
            placeholder="0.45"
            unit-after="%"
            v-model="data.annualInterest"
          />
        </DFormGridItem>
        <DFormGridItem span="2">
          <SInputSelect
            label="Repayment right"
            placeholder="Not determined yet"
            nullable
            :options="repaymentRightOptions"
            v-model="data.repaymentRight"
          />
        </DFormGridItem>
        <DFormGridItem span="2">
          <SInputDate
            label="Repayment date"
            block
            v-model="data.repaymentDate"
          />
        </DFormGridItem>
        <DFormGridItem span="2">
          <SInputNumber
            label="Discount"
            placeholder="10"
            unit-after="%"
            v-model="data.discount"
            :validation="validation.discount"
          />
        </DFormGridItem>
        <DFormGridItem span="4" />
        <DFormGridItem span="3">
          <DInputMoney
            label="Valuation cap"
            placeholder="10,000,000"
            :currency="roundOps.currency(deal.round)"
            v-model="data.valuationCap"
            :validation="validation.valuationCap"
          />
        </DFormGridItem>
        <DFormGridItem span="3">
          <DInputMoney
            label="Eligible financing amount"
            placeholder="10,000,000"
            :currency="roundOps.currency(deal.round)"
            v-model="data.eligibleFinancingAmount "
            :validation="validation.eligibleFinancingAmount"
          />
        </DFormGridItem>
      </template>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Add" :loading="loading" @click="add" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
