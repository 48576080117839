<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { KadomonyNewsSource, KadomonyNewsType } from '@/graph/Kadomony'
import { useKadomonyNewsOps } from '@/composables/ops/KadomonyOps'
import CompanyNewsPageFiltersSection from './CompanyNewsPageFiltersSection.vue'

const types = defineModel<KadomonyNewsType[]>('types', { required: true })
const sources = defineModel<KadomonyNewsSource[]>('sources', { required: true })

const { t } = useTrans({
  en: {
    type_title: 'Filter by news type',
    source_title: 'Filter by source'
  },
  ja: {
    type_title: 'ニュース種別でフィルター',
    source_title: 'ソースでフィルター'
  }
})

const newsOps = useKadomonyNewsOps()

const typeOptions = [
  { label: newsOps.typeTextByValue(KadomonyNewsType.FundRaising), value: KadomonyNewsType.FundRaising },
  { label: newsOps.typeTextByValue(KadomonyNewsType.ServiceRelease), value: KadomonyNewsType.ServiceRelease },
  { label: newsOps.typeTextByValue(KadomonyNewsType.AddPortfolio), value: KadomonyNewsType.AddPortfolio }
]

const sourceOptions = [
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.PrTimes), value: KadomonyNewsSource.PrTimes },
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.Accel), value: KadomonyNewsSource.Accel },
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.AndreessenHorowitz), value: KadomonyNewsSource.AndreessenHorowitz },
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.GoogleVentures), value: KadomonyNewsSource.GoogleVentures },
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.KleinerPerkins), value: KadomonyNewsSource.KleinerPerkins },
  { label: newsOps.sourceTextByValue(KadomonyNewsSource.SequoiaCapital), value: KadomonyNewsSource.SequoiaCapital }
]
</script>

<template>
  <div class="CompanyNewsPageFilters">
    <CompanyNewsPageFiltersSection
      :title="t.type_title"
      :options="typeOptions"
      v-model="types"
    />
    <CompanyNewsPageFiltersSection
      :title="t.source_title"
      :options="sourceOptions"
      v-model="sources"
    />
  </div>
</template>

<style scoped lang="postcss">
.CompanyNewsPageFilters {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
