import { orderBy } from 'lodash-es'
import { type ColorModes, type TableCellAvatarsOption } from 'sefirot/composables/Table'
import { type Day, day } from 'sefirot/support/Day'
import {
  type Company,
  type CompanyCautionNote,
  type CompanyIdFrag,
  type CompanyParticipation
} from '@/graph/Company'
import { type User } from '@/graph/User'
import {
  CompanyActionStatus,
  type CompanyFinancialFiguresWithNotesUpdatedAtFrag,
  type CompanyFinancialResultsWithUpdatedAtFrag,
  type CompanyNameFrag,
  CompanyParticipationAuthority,
  type CompanyParticipationFrag,
  CompanyPortfolioStatus,
  type CompanyWithActionStatusFrag,
  type CompanyWithAllPortfolioOfsFrag,
  type CompanyWithAssigneesFrag,
  type CompanyWithLocationFrag,
  type CompanyWithNameFrag,
  type CompanyWithPortfolioStatusFrag,
  type CompanyWithTimestampFrag,
  type FundFrag,
  type UserFrag
} from '@/graphql'
import { useMe } from '../Auth'
import { defineOps } from './Ops'
import { useUserOps } from './UserOps'

export const CompanyPortfolioStatusDict = {
  [CompanyPortfolioStatus.NotPortfolio]: 'Not portfolio',
  [CompanyPortfolioStatus.Portfolio]: 'Portfolio',
  [CompanyPortfolioStatus.ExPortfolio]: 'Ex-Portfolio'
} as const

export const CompanyPortfolioStatusModeDict = {
  [CompanyPortfolioStatus.NotPortfolio]: 'default',
  [CompanyPortfolioStatus.Portfolio]: 'success',
  [CompanyPortfolioStatus.ExPortfolio]: 'mute'
} as const

export const CompanyActionStatusDict = {
  [CompanyActionStatus.Monitoring]: 'Monitoring',
  [CompanyActionStatus.Done]: 'Done',
  [CompanyActionStatus.InProgress]: 'In progress',
  [CompanyActionStatus.KeepInTouch]: 'Keep in touch',
  [CompanyActionStatus.Approachable]: 'Approachable',
  [CompanyActionStatus.Unapproachable]: 'Unapproachable'
} as const

export const CompanyActionStatusModeDict = {
  [CompanyActionStatus.Monitoring]: 'success',
  [CompanyActionStatus.Done]: 'mute',
  [CompanyActionStatus.InProgress]: 'info',
  [CompanyActionStatus.KeepInTouch]: 'default',
  [CompanyActionStatus.Approachable]: 'warning',
  [CompanyActionStatus.Unapproachable]: 'mute'
} as const

export const CompanyParticipationAuthorityTextAbbrDict = {
  [CompanyParticipationAuthority.PrimaryInCharge]: 'PIC',
  [CompanyParticipationAuthority.DeputyInCharge]: 'DIC',
  [CompanyParticipationAuthority.Participant]: 'Part'
} as const

export const useCompanyOps = defineOps(() => {
  const { user } = useMe()

  function path(company: Company | CompanyIdFrag, path?: string): string {
    return pathFromId(company.id, path)
  }

  function pathFromId(id: number, path?: string): string {
    path = path ? `/${path}` : ''
    return `/companies/${id}${path}`
  }

  function displayId(company: Company): string {
    return `COM-${String(company.id).padStart(6, '0')}`
  }

  function name(company: Company | CompanyWithNameFrag): string {
    return latestName(company).displayName
  }

  function officialNameLocal(company: Company): string {
    return latestName(company).officialNameLocal
  }

  function officialNameEn(company: Company): string {
    return latestName(company).officialNameEn
  }

  function latestName(company: Company | CompanyWithNameFrag): CompanyNameFrag {
    return company.names.find((n) => n.until === null)!
  }

  function portfolioStatusText(company: CompanyWithPortfolioStatusFrag): string {
    return CompanyPortfolioStatusDict[company.portfolioStatus]
  }

  function portfolioStatusTextByValue(status: CompanyPortfolioStatus): string {
    return CompanyPortfolioStatusDict[status]
  }

  function portfolioStatusMode(company: CompanyWithPortfolioStatusFrag): ColorModes {
    return CompanyPortfolioStatusModeDict[company.portfolioStatus]
  }

  function actionStatusText(company: CompanyWithActionStatusFrag): string {
    return CompanyActionStatusDict[company.actionStatus]
  }

  function actionStatusTextByValue(status: CompanyActionStatus): string {
    return CompanyActionStatusDict[status]
  }

  function actionStatusMode(company: CompanyWithActionStatusFrag): ColorModes {
    return CompanyActionStatusModeDict[company.actionStatus]
  }

  function location(company: CompanyWithLocationFrag): string {
    return `${company.address}, ${company.country?.name}`
  }

  function picUser(company: Company): User | null {
    return company.participations.find((p) => {
      return (p.authority === 'PrimaryInCharge') && (p.until === null)
    })?.user ?? null
  }

  function dicUser(company: Company): User | null {
    return company.participations.find((p) => {
      return (p.authority === 'DeputyInCharge') && (p.until === null)
    })?.user ?? null
  }

  /**
   * Check if the currently signed in user is assigned to the company.
   */
  function isAssigned(company: CompanyWithAssigneesFrag): boolean {
    return (
      company.primaryInChargeId === user.id
      || company.deputyInChargeId === user.id
      || company.participants.some((p) => p.id === user.id)
    )
  }

  /**
   * Get PIC, DIC, and participants in a single array.
   */
  function assignees(company: CompanyWithAssigneesFrag): UserFrag[] {
    const assignees: UserFrag[] = []

    if (company.primaryInCharge) {
      assignees.push(company.primaryInCharge)
    }

    if (company.deputyInCharge) {
      assignees.push(company.deputyInCharge)
    }

    assignees.push(...company.participants)

    return assignees
  }

  /**
   * Get PIC, DIC, and participants in a single array, except the currently
   * signed in user.
   */
  function assigneesExceptMe(company: CompanyWithAssigneesFrag): UserFrag[] {
    return assignees(company).filter((u) => u.id !== user.id)
  }

  function allPortfolioOf(company: CompanyWithAllPortfolioOfsFrag): FundFrag[] {
    return [...company.portfolioOf, ...company.exPortfolioOf]
  }

  function isAnyPortfolio(company: CompanyWithAllPortfolioOfsFrag): boolean {
    return allPortfolioOf(company).length > 0
  }

  function isNotPortofolio(company: CompanyWithPortfolioStatusFrag): boolean {
    return company.portfolioStatus === CompanyPortfolioStatus.NotPortfolio
  }

  function isUnapproachable(company: CompanyWithActionStatusFrag): boolean {
    return company.actionStatus === CompanyActionStatus.Unapproachable
  }

  function createdAt(company: CompanyWithTimestampFrag): Day {
    return day(company.createdAt)
  }

  function updatedAt(company: CompanyWithTimestampFrag): Day {
    return day(company.updatedAt)
  }

  return {
    path,
    pathFromId,
    displayId,
    name,
    officialNameLocal,
    officialNameEn,
    portfolioStatusText,
    portfolioStatusTextByValue,
    portfolioStatusMode,
    actionStatusText,
    actionStatusTextByValue,
    actionStatusMode,
    latestName,
    location,
    picUser,
    dicUser,
    isAssigned,
    assignees,
    assigneesExceptMe,
    allPortfolioOf,
    isAnyPortfolio,
    isNotPortofolio,
    isUnapproachable,
    createdAt,
    updatedAt
  }
})

export const useCompanyFinancialFiguresOps = defineOps(() => {
  function notesUpdatedAt(ff: CompanyFinancialFiguresWithNotesUpdatedAtFrag): Day | null {
    return ff.notesUpdatedAt ? day(ff.notesUpdatedAt) : null
  }

  return {
    notesUpdatedAt
  }
})

export const useCompanyFinancialResultOps = defineOps(() => {
  function updatedAt(result: CompanyFinancialResultsWithUpdatedAtFrag): Day {
    return day(result.updatedAt)
  }

  return {
    updatedAt
  }
})

export const useCompanyParticipationListOps = defineOps(() => {
  const userOps = useUserOps()

  function createTableCellAvatarsOptions(cps: CompanyParticipation[]): TableCellAvatarsOption[] {
    const pic: CompanyParticipation[] = []
    const dic: CompanyParticipation[] = []
    const participants: CompanyParticipation[] = []

    orderBy(cps, (cp) => userOps.name(cp.user)).forEach((cp) => {
      if (cp.until !== null) {
        return
      }

      if (cp.authority === 'PrimaryInCharge') {
        pic.push(cp)
      } else if (cp.authority === 'DeputyInCharge') {
        dic.push(cp)
      } else {
        participants.push(cp)
      }
    })

    return [...pic, ...dic, ...participants]
      .filter((o) => o !== null)
      .map((o) => ({
        image: userOps.avatarPath(o.user),
        name: userOps.name(o.user)
      }))
  }

  return {
    createTableCellAvatarsOptions
  }
})

export const useCompanyParticipationOps = defineOps(() => {
  function authorityAbbr(cp: CompanyParticipationFrag): string {
    return CompanyParticipationAuthorityTextAbbrDict[cp.authority]
  }

  return {
    authorityAbbr
  }
})

export const useCompanyCautionNoteOps = defineOps(() => {
  function updatedAt(note: CompanyCautionNote): Day {
    return day(note.updatedAt)
  }

  return {
    updatedAt
  }
})
