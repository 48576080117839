<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed, watch } from 'vue'
import { type DealForReportDetailFrag } from '@/graphql'
import { useCompletedDealReportCountByCompany, useCompletedDealReportCountByCompanyAndFund } from '@/composables/repos/DealReportRepo'

const props = defineProps<{
  open: boolean
  incompleting: boolean
  deal: DealForReportDetailFrag
}>()

defineEmits<{
  (e: 'incomplete'): void
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    incomplete: 'Convert to draft',
    close: 'Cancel'
  },
  ja: {
    incomplete: 'ドラフトに戻す',
    close: 'キャンセル'
  }
})

const {
  data: completedDealReportCount,
  loading: loadingCompletedDealReportCount,
  execute: executeCompletedDealReportCount
} = useCompletedDealReportCountByCompany(props.deal.round.company.id)

const {
  data: completedDealReportCountByCompanyAndFund,
  loading: loadingCompletedDealReportCountByCompanyAndFund,
  execute: executeCompletedDealReportCountByCompanyAndFund
} = useCompletedDealReportCountByCompanyAndFund(props.deal.round.company.id, props.deal.fund.id)

watch(() => props.open, async () => {
  if (props.open) {
    executeCompletedDealReportCount()
    executeCompletedDealReportCountByCompanyAndFund()
  }
})

const isLastCompletedReport = computed(() => completedDealReportCount.value === 1)
const isLastCompletedReportOfFund = computed(() => completedDealReportCountByCompanyAndFund.value === 1)

const shouldShowAlert = computed(() => {
  if (loadingCompletedDealReportCount.value || loadingCompletedDealReportCountByCompanyAndFund.value) {
    return false
  }

  return isLastCompletedReport.value || isLastCompletedReportOfFund.value
})
</script>

<template>
  <SModal :open="open" @close="$emit('close')">
    <SCard size="medium" :header="false">
      <SCardBlock class="s-p-32">
        <SDoc>
          <SContent>
            <STrans lang="en">
              <h2>Convert deal report to draft</h2>
              <p>You will not be able to complete the deal if you convert this deal report to draft status. To complete a deal, all deal reports must be in a complete status.</p>
            </STrans>
            <STrans lang="ja">
              <h2>投資報告書をドラフトに戻す</h2>
              <p>投資報告書をドラフト状態に戻す場合、ディールをコンプリートできなくなることにご注意ください。ディールをコンプリートするには、すべての投資報告書がコンプリート状態である必要があります。</p>
            </STrans>
          </SContent>
          <SAlert v-if="shouldShowAlert" mode="warning">
            <p v-if="isLastCompletedReport">
              <STrans lang="en">This is the only completed deal report associated with the company. If you convert this deal report to draft status, the company portfolio data will be excluded from portfolio of the fund, and the company portfolio data will be also deleted permanently.</STrans>
              <STrans lang="ja">この投資報告書は、当該の会社に紐づく唯一のコンプリートされている投資報告書です。この投資報告書をドラフト状態に戻した場合、当該の会社がファンドのポートフォリオからはずれ、当該の会社のポートフォリオデータも完全に削除されます。</STrans>
            </p>
            <p v-else>
              <STrans lang="en">This is the only completed deal report of the fund associated with the company. If you convert this deal report to draft status, the company will be excluded from portfolio of the fund.</STrans>
              <STrans lang="ja">この投資報告書は、当該の会社に紐づく、ファンドでコンプリートされている唯一の投資報告書です。この投資報告書をドラフト状態に戻した場合、当該の会社がファンドのポートフォリオからはずれます。</STrans>
            </p>
          </SAlert>
        </SDoc>
      </SCardBlock>
      <SCardBlock size="large" class="s-px-32">
        <SControl>
          <SControlRight>
            <SControlButton
              :label="t.close"
              :disabled="incompleting"
              @click="$emit('close')"
            />
            <SControlButton
              mode="danger"
              :label="t.incomplete"
              :loading="incompleting || loadingCompletedDealReportCount || loadingCompletedDealReportCountByCompanyAndFund"
              @click="$emit('incomplete')"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </SModal>
</template>
