import { type Query, useQuery } from 'sefirot/composables/Api'
import { type MaybeRef, toValue } from 'vue'
import {
  type BasicPaginationInput,
  type StatUserActivities,
  type StatUserActivitiesCondition,
  type StatUserActivitiesOrder
} from '@/graphql'
import { StatUserActivitiesRequest } from '@/requests/StatUserActivitiesRequest'

export type StatUserActivityPage = Query<StatUserActivities>

export interface PageOptions {
  page: BasicPaginationInput
  condition: StatUserActivitiesCondition
  orderBy: StatUserActivitiesOrder
}

export function useStatUserActivitiesPage(options: MaybeRef<PageOptions>) {
  return useQuery(async () => {
    const o = toValue(options)
    return (await new StatUserActivitiesRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )).data.statUserActivities
  }, {
    watch: () => toValue(options)
  })
}
