<script setup lang="ts">
import { AuthorizationError } from 'sefirot/errors'
import { useCanCreateCompleteSurvey } from '@/composables/policies/SurveyPolicy'
import SurveyFormRegisterBehalf from '@/components/survey/SurveyFormRegisterBehalf.vue'

const canCreateCompleteSurvey = useCanCreateCompleteSurvey()

if (!canCreateCompleteSurvey.value.ok) {
  throw new AuthorizationError()
}
</script>

<template>
  <DPage title="Surveys - Create Complete" class="SurveysCreateComplete">
    <div class="s-mx-auto s-max-w-md">
      <SurveyFormRegisterBehalf />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.SurveysCreateComplete {
  padding: 32px 32px 128px;
}
</style>
