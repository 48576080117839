import { FundGroupsDocument } from '@/graphql'
import { Request } from './Request'

export class FundGroupRequest extends Request {
  fetchAll() {
    return this.request({
      query: FundGroupsDocument
    })
  }
}
