import { type OptionAvatar as DropdownOptionAvatar } from 'sefirot/components/SInputDropdown.vue'
import { type Day, day } from 'sefirot/support/Day'
import { type User } from '@/graph/User'
import {
  type UserFrag,
  UserStatus,
  UserTag,
  type UserWithDeactivatedAtFrag,
  type UserWithRolesFrag,
  type UserWithTimestampFrag
} from '@/graphql'
import { isLocal } from '@/support/Utils'
import { defineOps } from './Ops'

export const UserTagTextDict = {
  [UserTag.GeneralPartner]: 'General Partner',
  [UserTag.Meister]: 'Meister'
} as const

export const useUserOps = defineOps(({ isJa }) => {
  function slug(user: User | UserFrag): string {
    return user.email.split('@')[0]
  }

  function name(user: User | UserFrag): string {
    return isJa ? nameJa(user) : nameEn(user)
  }

  function nameEn(user: User | UserFrag): string {
    return `${user.firstNameEn} ${user.lastNameEn}`
  }

  function nameJa(user: User | UserFrag): string {
    return `${user.lastNameJa} ${user.firstNameJa}`
  }

  function name1st(user: User | UserFrag): string {
    return isJa ? name1stJa(user) : name1stEn(user)
  }

  function name1stEn(user: User | UserFrag): string {
    return user.firstNameEn
  }

  function name1stJa(user: User | UserFrag): string {
    return user.lastNameJa
  }

  function name2nd(user: User | UserFrag): string {
    return isJa ? name2ndJa(user) : name2ndEn(user)
  }

  function name2ndEn(user: User | UserFrag): string {
    return user.lastNameEn
  }

  function name2ndJa(user: User | UserFrag): string {
    return user.firstNameJa
  }

  /**
   * Generate the user's avatar path. In production, this should target the
   * Fatima endpoint. In local development, we will stub it to always fetch
   * the default avatar image so that we don't have to worry about setting up
   * the Fatima server locally.
   */
  function avatarPath(user: User | UserFrag): string {
    if (isLocal()) {
      return 'https://portal.globalbrains.com/api/avatars/default'
    }

    return `${import.meta.env.FATIMA_BASE_URL}/api/avatars/${slug(user)}`
  }

  function path(user: UserFrag): string {
    return `/users/${user.id}`
  }

  function isActive(user: User | UserFrag): boolean {
    return user.status === UserStatus.Active
  }

  function createdAt(user: UserWithTimestampFrag): Day {
    return day(user.createdAt)
  }

  function updatedAt(user: UserWithTimestampFrag): Day {
    return day(user.updatedAt)
  }

  function deactivatedAt(user: UserWithDeactivatedAtFrag): Day | null {
    return user.deactivatedAt ? day(user.deactivatedAt) : null
  }

  function hasRole(user: UserWithRolesFrag, role: string): boolean {
    return user.roles.some((r) => r.name === role)
  }

  function hasAnyRole(user: UserWithRolesFrag, roles: string[]): boolean {
    return user.roles.some((r) => roles.includes(r.name))
  }

  function createDropdownOptions(users?: User[] | UserFrag[] | null): DropdownOptionAvatar[] {
    return users?.map((u) => ({
      type: 'avatar',
      image: avatarPath(u),
      avatar: avatarPath(u),
      label: name(u),
      value: u.id,
      enabled: isActive(u)
    })) ?? []
  }

  return {
    slug,
    name,
    nameEn,
    nameJa,
    name1st,
    name1stEn,
    name1stJa,
    name2nd,
    name2ndEn,
    name2ndJa,
    avatarPath,
    path,
    isActive,
    createdAt,
    updatedAt,
    deactivatedAt,
    hasRole,
    hasAnyRole,
    createDropdownOptions
  }
})
