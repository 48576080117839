<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { type DealWithExitPlansFrag, type DealWithIdFrag, type WithPermissionFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealFormUpdateExitPlan from './DealFormUpdateExitPlan.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithExitPlansFrag & WithPermissionFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)
const dealOps = useDealOps()
const { state: modal, on, off } = usePower(false)

const actions = computedWhen(canUpdate, () => {
  return [{ icon: 'edit', onClick: on }]
}, [])

const exitMethods = computed(() => {
  return props.deal.estimatedExitMethods.map((method) => ({
    label: dealOps.exitMethodText(method)
  }))
})
</script>

<template>
  <DCard title="Exit plan" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="1">
        <DCardGridLabel text="Exit method" />
        <DCardGridPills :items="exitMethods" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Exit time" />
        <DCardGridText :text="dealOps.estimatedExitTime(deal)?.format('YYYY/M')" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Est. ROI" />
        <DCardGridText :text="deal.estimatedROI" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Est. IRR" />
        <DCardGridText :text="deal.estimatedIRR ? `${deal.estimatedIRR}%` : null" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal">
      <DealFormUpdateExitPlan
        :deal="deal"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </DCard>
</template>

<style scoped lang="postcss">
.empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.empty-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
