import { type Get, useGet } from 'sefirot/composables/Api'
import { type GeneratedCreateCompanyInput } from '@/graphql'
import { GeneratedCreateCompanyInputRequest } from '@/requests/GeneratedCreateCompanyInputRequest'

export type GetGeneratedCreateCompanyInputItem = Get<
  GeneratedCreateCompanyInput,
  [url: string, countryId: number]
>

export function useGetGeneratedCreateCompanyInputItem(): GetGeneratedCreateCompanyInputItem {
  return useGet(async (_, url, countryId) => {
    return (await (new GeneratedCreateCompanyInputRequest()).fetch(url, countryId)).data.generatedCompany
  })
}
