<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength, required, requiredIf } from 'sefirot/validation/rules'
import { type BallotVoteChoice, type BallotVoterFrag, DealEventType } from '@/graphql'
import DealCardEventListInputsVotes from './DealCardEventListInputsVotes.vue'

export interface Vote {
  voter: BallotVoterFrag
  choice: BallotVoteChoice
  condition: string | null
  comment: string | null
}

const props = defineProps<{
  type: DealEventType
  date: Day | null
  reExecutedReason: string | null
  skipReason: string | null
  votes: Vote[]
}>()

defineEmits<{
  (e: 'update:type', value: DealEventType): void
  (e: 'update:date', value: Day | null): void
  (e: 'update:re-executed-reason', value: string | null): void
  (e: 'update:skip-reason', value: string | null): void
  (e: 'update:votes', value: Vote[]): void
}>()

const { validation } = useValidation(() => ({
  date: props.date,
  reExecutedReason: props.reExecutedReason,
  skipReason: props.skipReason
}), {
  date: {
    required: required()
  },
  reExecutedReason: {
    requiredIf: requiredIf(() => props.type === DealEventType.ReExecuted),
    maxLength: maxLength(100)
  },
  skipReason: {
    requiredIf: requiredIf(() => props.type === DealEventType.Skipped),
    maxLength: maxLength(100)
  }
})

const typeOptions = [
  { label: 'Executed', value: DealEventType.Executed },
  { label: 'Re-executed', value: DealEventType.ReExecuted },
  { label: 'Skipped', value: DealEventType.Skipped }
]
</script>

<template>
  <div class="DealInputEvent">
    <div class="grid">
      <div class="item">
        <SInputRadios
          label="Was it executed or skipped?"
          :options="typeOptions"
          :value="type"
          @change="(v) => $emit('update:type', v)"
        />
      </div>
      <DFormGridItem v-if="type === DealEventType.ReExecuted" span="6">
        <SInputText
          label="Re-executed reason"
          placeholder="Describe why the action was re-executed"
          :model-value="reExecutedReason"
          :validation="validation.reExecutedReason"
          @update:model-value="(v) => $emit('update:re-executed-reason', v)"
        />
      </DFormGridItem>
      <div v-if="type === DealEventType.Skipped" class="input">
        <SInputText
          label="Skipped reason"
          placeholder="Describe why the action was skipped"
          :model-value="skipReason"
          :validation="validation.skipReason"
          @update:model-value="(v) => $emit('update:skip-reason', v)"
        />
      </div>
      <div class="item date">
        <SInputDate
          label="Date"
          help="In case you skipped the action, select a date around when you have decided to skip the action. It doesn't have to be precise."
          block
          :model-value="date"
          :validation="validation.date"
          @update:model-value="(v) => $emit('update:date', v)"
        />
      </div>
    </div>

    <DealCardEventListInputsVotes
      v-if="type !== DealEventType.Skipped && votes.length"
      :model-value="votes"
      @update:model-value="(v) => $emit('update:votes', v)"
    />
  </div>
</template>

<style scoped lang="postcss">
.DealInputEvent {
  display: grid;
  gap: 24px;
}

.grid {
  display: grid;
  gap: 16px;
}

.item.date {
  width: 592px;
}
</style>
