<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { usePower } from 'sefirot/composables/Power'
import { type Company } from '@/models/Company'
import CompanyFormAddName from './CompanyFormAddName.vue'

defineProps<{
  company: Company
}>()

defineEmits<{
  (e: 'added'): void
}>()

const { state: modal, on, off } = usePower(false)
</script>

<template>
  <div class="CompanyCardNameHistoryAdd">
    <div class="intro">
      <p class="title">Add a new company name</p>
      <p class="lead">When a company changes its name, add a new name so that we can track old company names. If you're trying to fix a typo in the current name, update the names in the below section.</p>
    </div>
    <div class="action">
      <SButton mode="info" label="Add name" block @click="on" />
    </div>

    <SModal :open="modal" @close="off">
      <CompanyFormAddName
        :company="company"
        @added="off(() => $emit('added'))"
        @cancel="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanyCardNameHistoryAdd {
  display: flex;
  align-items: center;
  border: 1px solid var(--c-divider-light);
  border-radius: 6px;
  padding: 24px 32px 24px 24px;
  background-color: var(--c-bg-elv);
}

.intro {
  flex-grow: 1;
  padding-right: 32px;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.lead {
  padding-top: 2px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}

.action {
  flex-shrink: 0;
  width: 96px;
}
</style>
