import { Model } from './Model'

export class RoundName extends Model {
  id: number | null
  name: string
  rank: number
  enabled: boolean

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.name = data.name
    this.rank = data.rank
    this.enabled = data.enabled
  }
}
