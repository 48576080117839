<script setup lang="ts">
import { type MaybeRef, unref } from 'vue'
import { useUserOps } from '@/composables/ops/UserOps'

defineProps<{
  value?: any
  record?: any
  color?: 'neutral' | 'info'
  urlSearch?: MaybeRef<string>
}>()

const userOps = useUserOps()
</script>

<template>
  <SLink
    class="DTableCellUser"
    :class="[color ?? 'info']"
    :href="`/users/${value.id}/profile${unref(urlSearch) ?? ''}`"
  >
    <DAvatar size="mini" :user="value" />
    <div class="name">{{ value?.name ?? userOps.name(value) }}</div>
  </SLink>
</template>

<style scoped lang="postcss">
.DTableCellUser {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.name {
  padding-left: 8px;
  line-height: 24px;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-font-weight);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s;

  .DTableCellUser.neutral & { color: var(--c-text-1); }
  .DTableCellUser.info &    { color: var(--c-info-text); }

  .DTableCellUser.neutral:hover & { color: var(--c-info-text); }
  .DTableCellUser.info:hover    & { color: var(--c-info-text-dark); }
}
</style>
