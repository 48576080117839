import { type Day, day } from 'sefirot/support/Day'
import { type CompanySupportLog } from '@/graph/Company'
import { defineOps } from './Ops'

export const useCompanySupportLogOps = defineOps(() => {
  function displayId(sl: CompanySupportLog): string {
    return `SPT-${sl.id.toString().padStart(6, '0')}`
  }

  function createdAt(sl: CompanySupportLog): Day {
    return day(sl.createdAt)
  }

  function updatedAt(sl: CompanySupportLog): Day {
    return day(sl.updatedAt)
  }

  return {
    displayId,
    createdAt,
    updatedAt
  }
})
