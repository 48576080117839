<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength, requiredIf } from 'sefirot/validation/rules'
import { type DataYesOrNo } from './DealFormUpdateReport.vue'

const props = defineProps<{
  strict: DataYesOrNo
  editable: DataYesOrNo
  name: string | null
  investedDate: Day | null
  reportedDate: Day | null
}>()

defineEmits<{
  (e: 'update:name', value: string | null): void
  (e: 'update:invested-date', value: Day | null): void
  (e: 'update:reported-date', value: Day | null): void
}>()

const { t } = useTrans({
  en: {
    i_name_label: 'Report name',
    i_name_ph: 'e.g. Whole investment, Common Stock purchase, etc.',
    i_invested_date_label: 'Invested date *',
    i_report_date_label: 'Report date *',
    i_report_date_info: 'This field is usually entered by the fund management team when they output the report. If you are a capitalist, you can leave this field blank.'
  },
  ja: {
    i_name_label: '報告書名',
    i_name_ph: '例: 全株式購入、普通株払込、etc.',
    i_invested_date_label: '投資日 *',
    i_report_date_label: '報告日 *',
    i_report_date_info: 'この項目は通常、ファンド管理チームが報告書を出力する際に入力されます。あなたがキャピタリストの場合は空欄のままで構いません。'
  }
})

const { validation } = useValidation(() => ({
  name: props.name,
  investedDate: props.investedDate,
  reportedDate: props.reportedDate
}), () => ({
  name: {
    requiredIf: requiredIf(() => props.strict === 'yes'),
    maxLength: maxLength(255)
  },
  investedDate: {
    requiredIf: requiredIf(() => props.strict === 'yes')
  },
  reportedDate: {
    requiredIf: requiredIf(() => props.strict === 'yes')
  }
}))
</script>

<template>
  <DFormGrid>
    <DFormGridItem span="6">
      <SInputText
        :label="t.i_name_label"
        :placeholder="t.i_name_ph"
        :disabled="editable === 'no'"
        :model-value="name"
        :validation="validation.name"
        @update:model-value="v => $emit('update:name', v)"
      >
        <template #info>
          <STrans lang="en">This name is only used on GB Base. Please give a name that describes the content of the report.</STrans>
          <STrans lang="ja">この名称はGB Base上でのみ使用されます。報告書の内容を表す名前をつけてください。</STrans>
        </template>
      </SInputText>
    </DFormGridItem>
    <DFormGridItem span="3">
      <SInputDate
        :label="t.i_invested_date_label"
        block
        :disabled="editable === 'no'"
        :model-value="investedDate"
        :validation="validation.investedDate"
        @update:model-value="v => $emit('update:invested-date', v)"
      />
    </DFormGridItem>
    <DFormGridItem span="3">
      <SInputDate
        :label="t.i_report_date_label"
        :info="t.i_report_date_info"
        block
        :disabled="editable === 'no'"
        :model-value="reportedDate"
        :validation="validation.reportedDate"
        @update:model-value="v => $emit('update:reported-date', v)"
      />
    </DFormGridItem>
  </DFormGrid>
</template>
