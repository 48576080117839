<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type SurveyResponseFrag } from '@/graphql'
import { useSurveyResponseOps } from '@/composables/ops/SurveyOps'

defineProps<{
  response: SurveyResponseFrag
}>()

const { t } = useTrans({
  en: {
    card_title: 'Confirmation from the startp',
    company: 'Company',
    name: 'Name',
    title: 'Title',
    email: 'Email',
    funds: 'Accepted funds',
    createdAt: 'Created At'
  },
  ja: {
    card_title: 'スタートアップからの回答',
    company: '会社名',
    name: '氏名',
    title: '役職',
    email: 'Email',
    funds: '公開対象ファンド',
    createdAt: '作成日時'
  }
})

const responseOps = useSurveyResponseOps()
</script>

<template>
  <SCard>
    <SCardBlock class="block">
      <div class="header">
        <div class="title">{{ t.card_title }}</div>
      </div>
      <SDesc cols="2" gap="16">
        <SDescItem span="1">
          <SDescLabel>{{ t.company }}</SDescLabel>
          <SDescText>{{ response.companyName }}</SDescText>
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.email }}</SDescLabel>
          <SDescText>{{ response.responderEmail }}</SDescText>
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.name }}</SDescLabel>
          <SDescText>{{ response.responderName }}</SDescText>
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.title }}</SDescLabel>
          <SDescText>{{ response.responderTitle }}</SDescText>
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel>{{ t.funds }}</SDescLabel>
          <SDescPill :pill="response.fundGroups.map((fg) => ({ label: fg.name }))" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel>{{ t.createdAt }}</SDescLabel>
          <SDescText>{{ responseOps.createdAt(response).format('YYYY-MM-DD HH:mm') }}</SDescText>
        </SDescItem>
      </SDesc>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.block {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px 16px;
}

.header {
  display: flex;
  align-items: center;
  height: 32px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
