<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type CompanyCautionNote } from '@/graph/Company'
import { useUpdateCompanyCautionNote } from '@/composables/repos/CompanyCautionNoteRepo'
import CompanyCautionNoteInputDescription from './input/CompanyCautionNoteInputDescription.vue'

const props = defineProps<{
  companyCautionNote: CompanyCautionNote
}>()

const emit = defineEmits<{
  cancel: []
  updated: [companyCautionNote: CompanyCautionNote]
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    update: 'Update internal memo'
  },
  ja: {
    cancel: 'キャンセル',
    update: '社内メモを更新'
  }
})

const mutation = useUpdateCompanyCautionNote()

const { data } = useData({
  body: props.companyCautionNote.body
})

const { validateAndNotify } = useValidation()

async function update() {
  if (await validateAndNotify()) {
    const cautionNote = await mutation.execute(props.companyCautionNote.companyId, {
      body: data.value.body!
    })
    emit('updated', cautionNote)
  }
}
</script>

<template>
  <SCard size="large" class="UpdateFormAddCautionNote">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update this internal memo</h2>
          </STrans>
          <STrans lang="ja">
            <h2>社内メモを更新する</h2>
          </STrans>
        </SContent>
        <CompanyCautionNoteInputDescription
          v-model="data.body"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-p-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            :label="t.update"
            mode="info"
            :loading="mutation.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
