<script setup lang="ts">
import { type User } from '@/models/User'
import { type View } from '@/models/View'
import { useViewUnshare } from '@/composables/view/ViewData'

const props = defineProps<{
  view?: View | null
  user: User
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'deleted'): void
}>()

const { execute, loading } = useViewUnshare(props.view!.id!, () => ({
  userIds: [props.user.id!]
}))

async function remove() {
  await execute()
  emit('deleted')
}
</script>

<template>
  <DForm>
    <DFormTitle>Remove shared users</DFormTitle>

    <DFormMedium>
      <p>
        This action cannot be undone.
        This will permanently delete the action result.
      </p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="danger" label="Delete" :loading="loading" @click="remove" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
