import {
  RetireUserDocument,
  UpdateUserFundAssignmentsDocument,
  type UpdateUserFundAssignmentsInput,
  UpdateUserRolesDocument,
  UpdateUserSettingsLangDocument,
  UpdateUserSettingsThemeDocument,
  type UserActivityCondition,
  UserActivityDealDroppedDocument,
  UserActivityDealInvestedDocument,
  UserActivityDealOpenedDocument,
  UserActivityOverviewDocument,
  UserAndRolesDocument,
  UserCompanyPromotedDocument,
  UserDealOpenDocument,
  UserDocument,
  UserFundAssignmentsDocument,
  UserPortfolioDocument,
  UserSettingsLang,
  UserSettingsTheme,
  UserTagsDocument,
  type UsersCondition,
  UsersDocument,
  UsersForCatalogDocument,
  WhoAmIDocument
} from '@/graphql'
import { Request } from './Request'

export {
  UserSettingsLang,
  UserSettingsTheme,
  type UserActivityCondition,
  type UsersCondition
}

export class UserRequest extends Request {
  whoami() {
    return this.request({
      query: WhoAmIDocument
    })
  }

  fetchAll(condition?: UsersCondition) {
    return this.request({
      query: UsersDocument,
      variables: {
        condition: condition ?? null
      }
    })
  }

  fetchForCatalog() {
    return this.request({
      query: UsersForCatalogDocument
    })
  }

  fetch(id: number) {
    return this.request({
      query: UserDocument,
      variables: { id }
    })
  }

  andRoles(id: number) {
    return this.request({
      query: UserAndRolesDocument,
      variables: { id }
    })
  }

  fetchFundAssignments(id: number) {
    return this.request({
      query: UserFundAssignmentsDocument,
      variables: { id }
    })
  }

  updateRoles(id: number, roleIds: number[]) {
    return this.request({
      query: UpdateUserRolesDocument,
      variables: { id, roleIds }
    })
  }
}

export class UserTagRequest extends Request {
  fetchAll() {
    return this.request({
      query: UserTagsDocument
    })
  }
}

export class UserSettingsRequest extends Request {
  updateLang(lang: UserSettingsLang) {
    return this.request({
      query: UpdateUserSettingsLangDocument,
      variables: { lang }
    })
  }

  updateTheme(theme: UserSettingsTheme) {
    return this.request({
      query: UpdateUserSettingsThemeDocument,
      variables: { theme }
    })
  }

  updateFundAssignments(id: number, inputs: UpdateUserFundAssignmentsInput[]) {
    return this.request({
      query: UpdateUserFundAssignmentsDocument,
      variables: { id, inputs }
    })
  }

  retire(id: number) {
    return this.request({
      query: RetireUserDocument,
      variables: { id }
    })
  }
}

export class UserProfileRequest extends Request {
  fetchActivityOverviewItem(id: number, condition: UserActivityCondition) {
    return this.request({
      query: UserActivityOverviewDocument,
      variables: { id, condition }
    })
  }

  fetchActivityDealOpenedCollection(id: number, condition: UserActivityCondition) {
    return this.request({
      query: UserActivityDealOpenedDocument,
      variables: { id, condition }
    })
  }

  fetchActivityDealInvestedCollection(id: number, condition: UserActivityCondition) {
    return this.request({
      query: UserActivityDealInvestedDocument,
      variables: { id, condition }
    })
  }

  fetchActivityDealDroppedCollection(id: number, condition: UserActivityCondition) {
    return this.request({
      query: UserActivityDealDroppedDocument,
      variables: { id, condition }
    })
  }

  fetchCompanyAssignedCollection(id: number) {
    return this.request({
      query: UserPortfolioDocument,
      variables: { id }
    })
  }

  fetchCompanyPromotedCollection(id: number) {
    return this.request({
      query: UserCompanyPromotedDocument,
      variables: { id }
    })
  }

  fetchDealOpenedCollection(id: number) {
    return this.request({
      query: UserDealOpenDocument,
      variables: { id }
    })
  }
}
