<script setup lang="ts">
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const totalInvestment = computed(() => props.portfolioData.totalInvestmentAmountFMC)
</script>

<template>
  <DCard title="Investment">
    <DCardGrid>
      <DCardGridItem span="4">
        <DCardGridLabel text="Total investment amount (JPY)" />
        <DCardGridMoney :money="totalInvestment" />
      </DCardGridItem>
    </DCardGrid>
  </DCard>
</template>
