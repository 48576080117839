import {
  AddOpportunityProposalsDocument,
  type AddOpportunityProposalsInput,
  AddOpportunityRoundDocument,
  AddOpportunitySourceDocument,
  type AddOpportunitySourceInput,
  type BasicPaginationInput,
  CreateOpportunityDocument,
  type CreateOpportunityInput,
  type CreateOpportunityRoundInput,
  DeleteAllOpportunityProposalsDocument,
  DeleteOpportunitySourceDocument,
  DeleteRoundDocument,
  type OpportunityCondition,
  OpportunityDocument,
  OpportunityForDetailsDocument,
  OpportunityForLocalNavDocument,
  type OpportunityOrder,
  OpportunityOrderField,
  OpportunityPageDocument,
  OpportunityPageForSearchDocument,
  OpportunityPageForStatusDocument,
  UpdateOpportunityBusinessDocument,
  type UpdateOpportunityBusinessInput,
  UpdateOpportunityIsProposalsDisabledDocument,
  UpdateOpportunitySourceDocument,
  type UpdateOpportunitySourceInput,
  UpdateOpportunityStatusDocument,
  type UpdateOpportunityStatusInput,
  UpdateOpportunitySurveyDocument,
  UpdateRoundDocument,
  type UpdateRoundInput
} from '@/graphql'
import { Request } from './Request'

export type { OpportunityCondition, OpportunityOrder }
export { OpportunityOrderField }

export class OpportunityRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: OpportunityCondition,
    orderBy: OpportunityOrder
  ) {
    return this.request({
      query: OpportunityPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchPageForSearch(
    page: BasicPaginationInput,
    condition: OpportunityCondition,
    orderBy: OpportunityOrder
  ) {
    return this.request({
      query: OpportunityPageForSearchDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchPageForStatus(
    page: BasicPaginationInput,
    condition: OpportunityCondition,
    orderBy: OpportunityOrder
  ) {
    return this.request({
      query: OpportunityPageForStatusDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: OpportunityDocument,
      variables: { id }
    })
  }

  fetchForDetails(id: number) {
    return this.request({
      query: OpportunityForDetailsDocument,
      variables: { id }
    })
  }

  fetchForLocalNav(id: number) {
    return this.request({
      query: OpportunityForLocalNavDocument,
      variables: { id }
    })
  }

  create(companyId: number, input: CreateOpportunityInput) {
    return this.request({
      query: CreateOpportunityDocument,
      variables: { companyId, input }
    })
  }

  updateStatus(id: number, input: UpdateOpportunityStatusInput) {
    return this.request({
      query: UpdateOpportunityStatusDocument,
      variables: { id, input }
    })
  }

  updateSurvey(id: number, surveyId: number | null) {
    return this.request({
      query: UpdateOpportunitySurveyDocument,
      variables: { id, surveyId }
    })
  }

  updateBusiness(id: number, input: UpdateOpportunityBusinessInput) {
    return this.request({
      query: UpdateOpportunityBusinessDocument,
      variables: { id, input }
    })
  }

  addSource(id: number, input: AddOpportunitySourceInput) {
    return this.request({
      query: AddOpportunitySourceDocument,
      variables: { id, input }
    })
  }

  updateSource(sourceId: number, input: UpdateOpportunitySourceInput) {
    return this.request({
      query: UpdateOpportunitySourceDocument,
      variables: { sourceId, input }
    })
  }

  deleteSource(sourceId: number) {
    return this.request({
      query: DeleteOpportunitySourceDocument,
      variables: { sourceId }
    })
  }

  addRound(id: number, input: CreateOpportunityRoundInput) {
    return this.request({
      query: AddOpportunityRoundDocument,
      variables: { id, input }
    })
  }

  updateRound(roundId: number, input: UpdateRoundInput) {
    return this.request({
      query: UpdateRoundDocument,
      variables: { id: roundId, input }
    })
  }

  deleteRound(roundId: number) {
    return this.request({
      query: DeleteRoundDocument,
      variables: { id: roundId }
    })
  }

  updateIsProposalsDisabled(id: number, isProposalsDisabled: boolean) {
    return this.request({
      query: UpdateOpportunityIsProposalsDisabledDocument,
      variables: { id, isProposalsDisabled }
    })
  }

  addProposals(id: number, proposals: AddOpportunityProposalsInput) {
    return this.request({
      query: AddOpportunityProposalsDocument,
      variables: { id, input: proposals }
    })
  }

  deleteAllProposals(id: number) {
    return this.request({
      query: DeleteAllOpportunityProposalsDocument,
      variables: { id }
    })
  }
}
