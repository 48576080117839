import { type MaybeRefOrGetter, toValue } from 'vue'
import { type SurveyAndAllFrag } from '@/graphql'
import { type PolicyResponse, allow, deny, pending, usePolicyResponse } from '../Policy'

export function useCanCreateSurvey(): PolicyResponse {
  return usePolicyResponse((user) => {
    return user.allow('add', 'survey') ? allow() : deny()
  })
}

export function useCanCreateCompleteSurvey(): PolicyResponse {
  return usePolicyResponse((user) => {
    return user.allow('complete', 'survey') ? allow() : deny()
  })
}

export function useCanUpdateSurvey(survey: MaybeRefOrGetter<SurveyAndAllFrag>): PolicyResponse {
  return usePolicyResponse((user) => {
    const s = toValue(survey)

    if (s === undefined) {
      return pending()
    }

    return user.allow('edit', 'survey', s) ? allow() : deny()
  })
}
