<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type SurveyAndAllFrag } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'

defineProps<{
  survey: SurveyAndAllFrag
}>()

const { t } = useTrans({
  en: {
    createdBy: 'Created By'
  },
  ja: {
    createdBy: '作成者'
  }
})

const userOps = useUserOps()
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc gap="24">
      <SDescItem>
        <SDescLabel>{{ t.createdBy }}</SDescLabel>
        <SDescText>{{ userOps.name(survey.createdBy) }}</SDescText>
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.user {
  display: block;
}
</style>
