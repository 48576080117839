import {
  CompanyBusinessReportFinancialFiguresAnnotationsDocument,
  type CompanyBusinessReportFinancialFiguresAnnotationsQuery,
  type CompanyBusinessReportFinancialFiguresAnnotationsQueryVariables
} from '@/graphql'
import { Request } from './Request'

export default class CompanyBusinessReportFinancialFiguresAnnotationRequest extends Request {
  fetchAll() {
    return this.request<CompanyBusinessReportFinancialFiguresAnnotationsQuery, CompanyBusinessReportFinancialFiguresAnnotationsQueryVariables>({
      query: CompanyBusinessReportFinancialFiguresAnnotationsDocument
    })
  }
}
