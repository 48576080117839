<script setup lang="ts">
import { useRoute } from 'vue-router'
import { type ActionNoteFrag } from '@/graphql'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCompanyAndActionNotesAndPermissionItem } from '@/composables/repos/CompanyRepo'
import OpportunityFormCreate from '@/components/opportunity/OpportunityFormCreate.vue'

const route = useRoute()

const { data: company } = useCompanyAndActionNotesAndPermissionItem(Number(route.params.id))

const companyOps = useCompanyOps()

// TODO: Check authorization if a user can add an oppo once Sefirote is ready.

function onActionNoteCreated(actionNote: ActionNoteFrag) {
  company.value!.actionNotes.unshift(actionNote)
}

function onActionNoteUpdated(actionNote: ActionNoteFrag) {
  const i = company.value!.actionNotes.findIndex((an) => an.id === actionNote.id)
  company.value!.actionNotes.splice(i, 1, actionNote)
}

function onActionNoteDeleted(actionNote: ActionNoteFrag) {
  const i = company.value!.actionNotes.findIndex((an) => an.id === actionNote.id)
  company.value!.actionNotes.splice(i, 1)
}
</script>

<template>
  <DPage
    class="CompaniesIdOpportunitiesCreate"
    :title="company ? `${companyOps.name(company)} - Create Opportunity` : undefined"
  >
    <div class="container">
      <OpportunityFormCreate
        v-if="company"
        :company="company"
        @action-note-created="onActionNoteCreated"
        @action-note-updated="onActionNoteUpdated"
        @action-note-deleted="onActionNoteDeleted"
      />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdOpportunitiesCreate {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
