<script setup lang="ts">
import { omit } from 'lodash-es'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type ProposableFundGroupFrag, ProposableFundGroupReason } from '@/graphql'
import OpportunityProposalAddWizardSelectFundGroupTable from './OpportunityProposalAddWizardSelectFundGroupTable.vue'

const props = defineProps<{
  proposableFundGroups: ProposableFundGroupFrag[]
}>()

const emit = defineEmits<{
  cancel: []
  selected: [pfgs: ProposableFundGroupFrag[]]
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_select: 'Select funds'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_select: 'ファンドを選択'
  }
})

const records = computed(() => props.proposableFundGroups
  .filter((pfg) => pfg.reason === ProposableFundGroupReason.Invested)
  .map((pfg) => ({ ...pfg, id: pfg.fundGroup.id }))
)

const { data } = useData({
  fundGroupIds: records.value.map((pfg) => pfg.id)
})

const { validation, validateAndNotify } = useValidation(data, {
  fundGroupIds: {
    required: required()
  }
})

async function onSelect() {
  if (await validateAndNotify()) {
    emit('selected', records.value.map((pfg) => omit(pfg, 'id')))
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds that already invested</h2>
            <p>There are funds previously invested in this company. In this case, you must open this opportunity to those funds before you can open to any other funds.</p>
            <p>To open this opportunity to other funds, first open to funds listed below, then re-open this wizard after that.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityを既存投資ファンドへ開示する</h2>
            <p>過去に当企業に出資したファンドが存在します。この場合、他のファンドへOpportunityを開示する前に、当該ファンドへ公開する必要があります。</p>
            <p>他のファンドへOpportunityを開示したい場合、まず以下のファンドへの開示処理を完了し、再度このウィザードを開いてください。</p>
          </STrans>
        </SContent>
        <OpportunityProposalAddWizardSelectFundGroupTable
          :records="records"
          :data="data"
          :validation="validation"
          :disable-selection="() => false"
          @update:data="($event) => data.fundGroupIds = $event"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_select"
            @click="onSelect"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
