<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { useCanRetireUser } from '@/composables/policies/UserPolicy'
import UserSettingsRetireDialog from './UserSettingsRetireDialog.vue'

const props = defineProps<{
  user: UserFrag
}>()

const emit = defineEmits<{
  (e: 'retired'): void
}>()

const { t } = useTrans({
  en: {
    title: 'Retire this user',
    text: 'The user will be unassigned from all companies. The role related settings such as user tags, fund assignments will also get removed.',
    retire: 'Retire user',
    e_is_me: 'You cannot retire yourself.',
    e_is_retired: 'This user is already retired.'
  },
  ja: {
    title: 'ユーザを退職させる',
    text: 'ユーザは全ての企業の担当者から外されます。また、ユーザのロールやファンドの担当設定など、ユーザに紐づく権限設定も削除されます。',
    retire: 'ユーザを退職',
    e_is_me: '自分自身を退職させることはできません。',
    e_is_retired: 'すでに退職済みのユーザです。'
  }
})

const canRetireUser = useCanRetireUser(() => props.user)

const dialog = usePower()

const errorMsg = computed(() => {
  if (canRetireUser.value.is('me')) {
    return t.e_is_me
  }

  if (canRetireUser.value.is('inactive')) {
    return t.e_is_retired
  }

  return undefined
})

function onRetired() {
  dialog.off()
  emit('retired')
}
</script>

<template>
  <SCard mode="danger">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>{{ t.title }}</h2>
          <p>{{ t.text }}</p>
        </SContent>
        <div class="actions">
          <SButton
            mode="danger"
            :label="t.retire"
            :disabled="!canRetireUser.ok"
            :tooltip="{ text: errorMsg }"
            @click="dialog.on"
          />
        </div>
      </SDoc>
    </SCardBlock>
    <SModal :open="dialog.state.value" @close="dialog.off">
      <UserSettingsRetireDialog
        :user="user"
        @cancel="dialog.off"
        @retired="onRetired"
      />
    </SModal>
  </SCard>
</template>
