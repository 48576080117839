<script setup lang="ts">
import IconActivity from '~icons/ph/activity'
import IconChartBar from '~icons/ph/chart-bar'
import IconDatabase from '~icons/ph/database'
import IconFileText from '~icons/ph/file-text'
import IconFlag from '~icons/ph/flag'
import IconGear from '~icons/ph/gear'
import IconGlobe from '~icons/ph/globe'
import IconMapPin from '~icons/ph/map-pin'
import IconNewspaperClipping from '~icons/ph/newspaper-clipping'
import IconNotebook from '~icons/ph/notebook'
import IconPaperPlane from '~icons/ph/paper-plane-tilt'
import IconDrive from '~icons/ri/drive-fill'
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useListener } from '@/composables/Channel'
import { useCompanyItemForLocalNav, useCompanyPortfolioDataItem, useCompanyVecItem } from '@/composables/company/CompanyData'
import { useCompanyPolicy, useCompanyPortfolioDataPolicy, useCompanyVecPolicy } from '@/composables/company/CompanyPolicy'
import { provideCompanyShowPage } from '@/composables/pages/CompanyPage'
import { useCanViewCompanySettings } from '@/composables/policies/CompanyPolicy'
import { useCompanyForDetailsItem } from '@/composables/repos/CompanyRepo'

const route = useRoute()

const companyId = computed(() => Number(route.params.id))

const { data: company, execute } = useCompanyItemForLocalNav(companyId.value, {
  catch: [401, 404]
})

const { data: portfolioData } = useCompanyPortfolioDataItem(companyId.value, {
  catch: [401, 404]
})

const { data: companyVec } = useCompanyVecItem(companyId.value, {
  catch: [401, 404]
})

const { data: companyForDetails, execute: executeForDetails } = useCompanyForDetailsItem(companyId.value)

useListener('company-name-updated', execute)
useListener('company-approachable-status-updated', executeForDetails)
useListener('company-exit-status-updated', execute)

const { canView: canViewCompany } = useCompanyPolicy(company)

const { canView: canViewCompanyPortflioData } = useCompanyPortfolioDataPolicy(portfolioData)

const { canView: canViewCompanyVec } = useCompanyVecPolicy(companyVec)

const canViewSettings = useCanViewCompanySettings(companyForDetails)

const pills = computedArrayWhen(company, (items, c) => {
  if (c.ipo) {
    items.push({ label: 'IPO', mode: 'success' })
  }
})

const actions = computedArrayWhen(company, (items, c) => {
  if (c.driveId) {
    items.push({ icon: IconDrive, label: 'Google Drive', href: `https://drive.google.com/drive/folders/${c.driveId}` })
  }
})

const tags = computedArrayWhen(company, (items, c) => {
  items.push(
    { icon: IconGlobe, label: c.website ?? '–', link: c.website },
    { icon: IconMapPin, label: c.country!.name },
    { icon: IconFlag, label: c.primarySector!.name }
  )
})

const menu = computed(() => {
  const items = []

  items.push([
    { icon: IconNotebook, label: 'Profile', link: `${company.value?.path}/profile` },
    { icon: IconFileText, label: 'Action Notes', link: `${company.value?.path}/action-notes` },
    { icon: IconNewspaperClipping, label: 'News', link: `${company.value?.path}/news` }
  ])

  if (canViewCompanyPortflioData.value) {
    items.push([
      { icon: IconDatabase, label: 'Portfolio Data', link: `${company.value?.path}/portfolio-data` },
      { icon: IconChartBar, label: 'Financial Figures', link: `${company.value?.path}/financial-figures` },
      { icon: IconActivity, label: 'Support Logs', link: `${company.value?.path}/support-logs` }
    ])
  }

  if (canViewCompanyVec.value) {
    items.push([
      { icon: IconPaperPlane, label: 'VEC', link: `${company.value?.path}/vec` }
    ])
  }

  if (canViewSettings.value.ok) {
    items.push([
      { icon: IconGear, label: 'Settings', link: `${company.value?.path}/settings` }
    ])
  }

  return items
})

provideCompanyShowPage(company as any)
</script>

<template>
  <DView
    class="CompaniesId"
    :loading="!company"
    :hide="!canViewCompany"
    :title="company?.name"
    :pills="pills"
    :actions="actions"
    :tags="tags"
    :menu="menu"
  />
</template>
