<script setup lang="ts">
import { type Pill } from 'sefirot/components/SDescPill.vue'
import { computed } from 'vue'
import {
  BallotVoteChoice,
  type BallotVoteFrag,
  type DealEventTemplateAndBallotFrag
} from '@/graphql'
import { useBallotVoterOps } from '@/composables/ops/BallotOps'

const props = defineProps<{
  template: DealEventTemplateAndBallotFrag
  vote: BallotVoteFrag
}>()

const ballotVoterOps = useBallotVoterOps()

const voter = computed(() => {
  return props.template.event.ballot!.voters.find((voter) => voter.id === props.vote.voterId)!
})

const choice = computed<Pill>(() => {
  switch (props.vote.choice) {
    case BallotVoteChoice.For:
      return { mode: 'success', label: 'For' }
    case BallotVoteChoice.ConditionallyFor:
      return { mode: 'success', label: 'Cond. For' }
    case BallotVoteChoice.Against:
      return { mode: 'danger', label: 'Against' }
    case BallotVoteChoice.Reject:
      return { mode: 'danger', label: 'Rject' }
    default:
      return { mode: 'mute', label: 'Unkown' }
  }
})
</script>

<template>
  <SDesc cols="2" gap="24">
    <SDescItem span="1">
      <SDescLabel value="Voter" />
      <SDescText :value="ballotVoterOps.name(voter)" />
    </SDescItem>
    <SDescItem span="1">
      <SDescLabel value="Vote" />
      <SDescPill :pill="[choice]" />
    </SDescItem>
    <SDescItem span="1">
      <SDescLabel value="Condition" />
      <SDescText :value="vote.condition" />
    </SDescItem>
    <SDescItem span="1">
      <SDescLabel value="Comment" />
      <SDescText :value="vote.comment" />
    </SDescItem>
  </SDesc>
</template>
