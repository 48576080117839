export interface KadomonyNews {
  companyName: string | null
  companyDomain: string | null
  title: string
  url: string
  type: KadomonyNewsType
  source: KadomonyNewsSource
  publishedAt: string
  company: KadomonyNewsCompany | null
}

export interface KadomonyNewsCompany {
  id: number
  name: string
}

export enum KadomonyNewsType {
  FundRaising = 'fund-raising',
  ServiceRelease = 'service-release',
  AddPortfolio = 'add_portfolio'
}

export enum KadomonyNewsSource {
  PrTimes = 'pr-times',
  Accel = 'accel',
  AndreessenHorowitz = 'andreessen-horowitz',
  GoogleVentures = 'google-ventures',
  KleinerPerkins = 'kleiner-perkins',
  SequoiaCapital = 'sequoia-capital'
}
