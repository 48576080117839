<script setup lang="ts">
import { type Pill } from 'sefirot/components/SDescPill.vue'
import { type State } from 'sefirot/components/SDescState.vue'
import { computed } from 'vue'
import { type CompanyForDetailsFrag } from '@/graphql'
import { useCompanyOps } from '@/composables/ops/CompanyOps'

const props = defineProps<{
  company: CompanyForDetailsFrag
}>()

const companyOps = useCompanyOps()

const actionStatusState = computed<State>(() => ({
  mode: companyOps.actionStatusMode(props.company),
  label: companyOps.actionStatusText(props.company)
}))

const portfolioStatus = computed<State | null>(() => {
  if (companyOps.isNotPortofolio(props.company)) {
    return null
  }

  return {
    mode: companyOps.portfolioStatusMode(props.company),
    label: companyOps.portfolioStatusText(props.company)
  }
})

const portfolioOf = computed<Pill[]>(() => {
  return props.company.portfolioOf.map((f) => ({ label: f.nameAbbreviated }))
})

const exPortfolioOf = computed<Pill[]>(() => {
  return props.company.exPortfolioOf.map((f) => ({ label: f.nameAbbreviated }))
})
</script>

<template>
  <DCard title="Company status">
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel>Status</SDescLabel>
          <SDescState :state="actionStatusState" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>Is portfolio</SDescLabel>
          <SDescState :state="portfolioStatus" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>Portfolio of</SDescLabel>
          <SDescPill :pill="portfolioOf" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>Ex-portfolio of</SDescLabel>
          <SDescPill :pill="exPortfolioOf" />
        </SDescItem>
        <SDescItem v-if="companyOps.isUnapproachable(company)" span="2">
          <SDescLabel>Closed type</SDescLabel>
          <SDescText :value="company.approachableStatus" />
        </SDescItem>
        <SDescItem v-if="companyOps.isUnapproachable(company)" span="2">
          <SDescLabel>Closed reason</SDescLabel>
          <SDescText :value="company.approachableNote" />
        </SDescItem>
      </SDesc>
    </SCardBlock>
  </DCard>
</template>
