<script setup lang="ts">
import { orderBy } from 'lodash-es'
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { type Opportunity, type OpportunityFile, type OpportunityFileRequest } from '@/graph/Opportunity'
import { useBroadcast } from '@/composables/Channel'
import { useOpportunityItem } from '@/composables/opportunity/OpportunityData'
import { useOpportunityPolicy } from '@/composables/opportunity/OpportunityPolicy'
import { useOpportunityFileDownloadList, useOpportunityFileList, useOpportunityFileRequestList, useOpportunityForDetails } from '@/composables/repos/OpportunityRepo'
import { useProposalViewExportStatusList } from '@/composables/repos/ViewRepo'
import CompanyInfoAssignees from '@/components/company/CompanyInfoAssignees.vue'
import DealList from '@/components/deal/DealList.vue'
import OpportunityCardProposals from '@/components/opportunity/OpportunityCardProposals.vue'
import OpportunityCardSourcing from '@/components/opportunity/OpportunityCardSourcing.vue'
import OpportunityDescBusiness from '@/components/opportunity/OpportunityDescBusiness.vue'
import OpportunityDescConflictManagementCommittee from '@/components/opportunity/OpportunityDescConflictManagementCommittee.vue'
import OpportunityDescFileDownloadHistory from '@/components/opportunity/OpportunityDescFileDownloadHistory.vue'
import OpportunityDescInitialReviewMeeting from '@/components/opportunity/OpportunityDescInitialReviewMeeting.vue'
import OpportunityDescRound from '@/components/opportunity/OpportunityDescRound.vue'
import OpportunityDescStatus from '@/components/opportunity/OpportunityDescStatus.vue'
import OpportunityDescSurvey from '@/components/opportunity/OpportunityDescSurvey.vue'
import OpportunityDescSystem from '@/components/opportunity/OpportunityDescSystem.vue'
import OpportunityFormEditFileComment from '@/components/opportunity/OpportunityFormEditFileComment.vue'
import OpportunityFormRemoveFileComment from '@/components/opportunity/OpportunityFormRemoveFileComment.vue'
import OpportunityFormSelectFileActions from '@/components/opportunity/OpportunityFormSelectFileActions.vue'
import OpportunitySideFiles from '@/components/opportunity/OpportunitySideFiles.vue'
import OpportunityFileFormUpload from '@/components/opportunity-file/OpportunityFileFormUpload.vue'
import OpportunityFileRequestFormCreate from '@/components/opportunity-file-request/OpportunityFileRequestFormCreate.vue'

const route = useRoute()
const oppoId = Number(route.params.id)

const { data: oppo, execute } = useOpportunityItem(oppoId)
const { data: oppoForDetail, execute: executeForDetail } = useOpportunityForDetails(oppoId)
const { data: proposalViewExportStatuses } = useProposalViewExportStatusList()
const { data: files, execute: refreshFiles } = useOpportunityFileList(oppoId, { immediate: false })
const { data: fileRequests, execute: refreshFileRequests } = useOpportunityFileRequestList(oppoId, { immediate: false })
const { data: fileDownloads, execute: refreshFileDownloads } = useOpportunityFileDownloadList(oppoId, { immediate: false })

const { canView, canUpdate, canViewFiles, canRequestFiles } = useOpportunityPolicy(oppo)

const { broadcast } = useBroadcast('round-name-updated')

const title = computedWhen(oppo, (o) => {
  broadcast()
  return `${o.company!.name} - ${o.title}`
})

const selectFileActionsDialog = usePower()
const uploadFilesDialog = usePower()
const editFileCommentDialog = usePower()
const requestFileDialog = usePower()
const removeFileCommentDialog = usePower()
const downloadHistoryDialog = usePower()

const isDialogOpen = computed(() => {
  return (
    selectFileActionsDialog.state.value
    || uploadFilesDialog.state.value
    || requestFileDialog.state.value
    || editFileCommentDialog.state.value
    || removeFileCommentDialog.state.value
    || downloadHistoryDialog.state.value
  )
})

const selectFileActionsType = ref<'normal' | 'chain'>('normal')
const droppedFiles = ref<File[]>([])

watch(canViewFiles, (v) => {
  if (v) {
    refreshFiles()
    refreshFileRequests()
  }
})

watch(canUpdate, (v) => {
  if (v) {
    refreshFileDownloads()
  }
})

onMounted(() => {
  if (route.query.initialized) {
    selectFileActionsType.value = 'chain'
    selectFileActionsDialog.on()
    window.history.replaceState({}, document.title, window.location.pathname)
  }
})

function onSelectFileActions() {
  selectFileActionsType.value = 'normal'
  selectFileActionsDialog.on()
}

function onSelectFileActionsUpload() {
  selectFileActionsDialog.off()
  onUploadFiles([])
}

function onSelectFileActionsComment() {
  selectFileActionsDialog.off()
  editFileCommentDialog.on()
}

function onUploadFiles(files: File[]) {
  droppedFiles.value = files
  uploadFilesDialog.on()
}

function onFileFieldsUpdated(oppo: Opportunity) {
  oppoForDetail.value!.hasNoPlanToAddFiles = oppo.hasNoPlanToAddFiles
  oppoForDetail.value!.fileComment = oppo.fileComment
  editFileCommentDialog.off()
  removeFileCommentDialog.off()
}

function onSelectFileActionsAborted() {
  oppoForDetail.value!.hasNoPlanToAddFiles = true
  selectFileActionsDialog.off()
}

function onFileUploadCompleted(newFiles: OpportunityFile[]) {
  droppedFiles.value = []
  uploadFilesDialog.off()
  const allFiles = [...files.value!, ...newFiles]
  files.value = orderBy(allFiles, 'name')
  oppoForDetail.value!.hasNoPlanToAddFiles = false
}

function onFileUploadCancel() {
  uploadFilesDialog.off()
}

function onSelectFileRequest() {
  requestFileDialog.on()
}

async function onFileRequestSubmitted(ofr: OpportunityFileRequest) {
  fileRequests.value!.push(ofr)
  requestFileDialog.off()
}

function onFileRemoved(file: OpportunityFile) {
  files.value = files.value!.filter((f) => f.id !== file.id)
}

function onShowDownloadHistory() {
  downloadHistoryDialog.on()
}

function closeDialog() {
  selectFileActionsDialog.off()
  uploadFilesDialog.off()
  editFileCommentDialog.off()
  requestFileDialog.off()
  removeFileCommentDialog.off()
  downloadHistoryDialog.off()
}
</script>

<template>
  <DPage class="OpportunitiesIdProfile" :title="title" :authorize="canView">
    <div class="board">
      <div class="grid">
        <OpportunityDescStatus v-if="oppoForDetail" :opportunity="oppoForDetail!" @updated="executeForDetail" />
        <OpportunityCardSourcing v-if="oppoForDetail" :opportunity="oppoForDetail!" @updated="executeForDetail" />
        <OpportunityDescBusiness :opportunity="oppo!" @updated="execute" />
        <OpportunityDescRound v-if="oppoForDetail" :opportunity="oppoForDetail" @updated="executeForDetail" />
        <OpportunityDescSurvey v-if="oppoForDetail" :opportunity="oppoForDetail" @updated="executeForDetail" />
        <OpportunityDescInitialReviewMeeting v-if="oppoForDetail" :opportunity="oppoForDetail" />
        <OpportunityDescConflictManagementCommittee v-if="oppoForDetail" :opportunity="oppoForDetail" />
        <OpportunityCardProposals v-if="oppoForDetail && proposalViewExportStatuses" :opportunity="oppoForDetail" :proposal-view-export-statuses="proposalViewExportStatuses" @updated="executeForDetail" />
        <OpportunityDescSystem :opportunity="oppo!" />
      </div>
      <div class="grid">
        <CompanyInfoAssignees
          :company="oppo!.company!"
          title="Company assignees"
          @updated="execute"
        />
        <DealList
          :deals="oppo!.dealsThroughRound()"
          :opportunity="oppoForDetail!"
        />
        <OpportunitySideFiles
          v-if="oppoForDetail && files && fileRequests && canViewFiles"
          :opportunity-id="oppo!.id!"
          :has-no-plan-to-add-files="oppoForDetail!.hasNoPlanToAddFiles"
          :file-comment="oppoForDetail!.fileComment"
          :files
          :file-requests="fileRequests"
          :file-downloads="fileDownloads ?? []"
          :can-edit="!!canUpdate"
          :can-request="!!canRequestFiles"
          @select-actions="onSelectFileActions"
          @select-file-request="onSelectFileRequest"
          @upload-files="onUploadFiles"
          @edit-file-comment="editFileCommentDialog.on"
          @remove-file-comment="removeFileCommentDialog.on"
          @file-removed="onFileRemoved"
          @aborted="onFileFieldsUpdated"
          @file-comment-updated="onFileFieldsUpdated"
          @show-download-history="onShowDownloadHistory"
        />
      </div>
    </div>

    <SModal :open="isDialogOpen" @close="closeDialog">
      <Transition name="fade" mode="out-in">
        <OpportunityFormSelectFileActions
          v-if="selectFileActionsDialog.state.value"
          class="modal"
          :type="selectFileActionsType"
          :opportunity-id="oppoId"
          @upload="onSelectFileActionsUpload"
          @comment="onSelectFileActionsComment"
          @skip="selectFileActionsDialog.off"
          @aborted="onSelectFileActionsAborted"
        />
        <OpportunityFileFormUpload
          v-else-if="uploadFilesDialog.state.value"
          class="modal"
          :opportunity-id="oppoId"
          :init-files="droppedFiles"
          @cancel="onFileUploadCancel"
          @uploaded="onFileUploadCompleted"
        />
        <OpportunityFileRequestFormCreate
          v-else-if="requestFileDialog.state.value"
          :opportunity-id="oppoId"
          @cancel="requestFileDialog.off"
          @submit="onFileRequestSubmitted"
        />
        <OpportunityFormEditFileComment
          v-else-if="editFileCommentDialog.state.value"
          class="modal"
          :opportunity-id="oppoId"
          :has-no-plan-to-add-files="oppoForDetail!.hasNoPlanToAddFiles"
          :file-comment="oppoForDetail!.fileComment"
          @cancel="editFileCommentDialog.off"
          @updated="onFileFieldsUpdated"
        />
        <OpportunityFormRemoveFileComment
          v-else-if="removeFileCommentDialog.state.value"
          class="modal"
          :opportunity-id="oppoId"
          :has-no-plan-to-add-files="oppoForDetail!.hasNoPlanToAddFiles"
          @cancel="removeFileCommentDialog.off"
          @updated="onFileFieldsUpdated"
        />
        <OpportunityDescFileDownloadHistory
          v-else-if="downloadHistoryDialog.state.value"
          class="modal"
          :file-downloads="fileDownloads ?? []"
          @close="downloadHistoryDialog.off"
        />
      </Transition>
    </SModal>
  </DPage>
</template>

<style scoped lang="postcss">
.OpportunitiesIdProfile {
  padding: 32px 32px 128px;
}

.board {
  display: grid;
  grid-template-columns: minmax(664px, 768px) minmax(344px, 1fr);
  gap: 16px;
  margin: 0 auto;
  max-width: 1216px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal {
  transition: opacity 0.5s, transform 0.5s;
}

.modal.fade-enter-from {
  opacity: 0;
  transform: translateY(8px);
}

.modal.fade-leave-to {
  opacity: 0;
  transform: translateY(-8px);
}
</style>
