<script setup lang="ts">
import IconClipboardText from '~icons/ph/clipboard-text-bold'
import { cloneDeep, isEqual, xor } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { type Ref, computed, reactive, ref } from 'vue'
import { type FundReportOrder, FundReportOrderField, OrderDirection, useFundReportCollection } from '@/composables/fund-report/FundReportData'
import { useCanAddFundReport } from '@/composables/policies/FundReportPolicy'
import { useFundList } from '@/composables/repos/FundRepo'
import FundReportFormAdd from './FundReportFormAdd.vue'

const { t } = useTrans({
  en: {
    title: 'Fund Reports',
    new_report: 'New report',
    c_name: 'Report name',
    c_updated_at: 'Updated at',
    sort_asc: 'Sort ascending (A...Z)',
    sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    title: '業務報告書',
    new_report: '新規作成',
    c_name: '報告書名',
    c_updated_at: '更新日時',
    sort_asc: 'ソート: 昇順 (A...Z)',
    sort_desc: 'ソート: 降順 (Z...A)'
  }
})

const { state: modal, on, off } = usePower()

const fund = ref<number[]>([])

const orderBy = ref<FundReportOrder>({
  field: FundReportOrderField.UpdatedAt,
  direction: OrderDirection.Desc
})

const options = reactive({
  page: { page: 0, perPage: 50 },
  condition: {
    fund
  },
  orderBy
})

const initialOptions = cloneDeep(options)

const { data: reports, loading } = useFundReportCollection(options)
const { data: funds } = useFundList()

const canCreate = useCanAddFundReport(funds)

useUrlQuerySync({
  state: options,
  casts: {
    'page.page': Number,
    'condition.fund': (v: number[]) => v.map(Number)
  },
  exclude: ['page.perPage']
})

const isDirty = computed(() => !isEqual(options, initialOptions))

const table = useTable({
  orders: [
    'name',
    'updatedAt'
  ],

  columns: {
    name: {
      label: t.c_name,
      grow: true,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: t.sort_asc, onClick: () => { updateOrder(FundReportOrderField.FundName, OrderDirection.Asc) } },
            { label: t.sort_desc, onClick: () => { updateOrder(FundReportOrderField.FundName, OrderDirection.Desc) } }
          ]
        },
        {
          type: 'filter',
          search: true,
          selected: fund,
          options: computed(() => funds.value?.map((fund) => {
            return { label: fund.nameAbbreviated + (fund.group?.lp ? ` (${fund.group?.lp})` : ''), value: fund.id! }
          }) ?? []),
          onClick: (value) => updateFilter(fund, value)
        }
      ]),
      cell: (_, record) => ({
        type: 'text',
        icon: IconClipboardText,
        value: record.name,
        link: record.path,
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    updatedAt: {
      label: t.c_updated_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: t.sort_asc, onClick: () => { updateOrder(FundReportOrderField.UpdatedAt, OrderDirection.Asc) } },
            { label: t.sort_desc, onClick: () => { updateOrder(FundReportOrderField.UpdatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: record.updatedAt,
        format: 'YYYY-MM-DD HH:mm'
      })
    }
  },

  records: computed(() => reports.value?.data ?? []),
  total: computed(() => reports.value?.page.total ?? 0),
  page: computed(() => reports.value && reports.value?.page.page + 1),
  perPage: computed(() => reports.value?.page.perPage),
  loading,
  reset: isDirty,
  onPrev: () => { options.page.page = options.page.page - 1 },
  onNext: () => { options.page.page = options.page.page + 1 },
  onReset: reset
})

function reset() {
  options.page.page = 0
  fund.value = []
  orderBy.value = { field: FundReportOrderField.UpdatedAt, direction: OrderDirection.Desc }
}

function updateFilter(filter: Ref<any>, value: any) {
  filter.value = xor(filter.value, [value])
}

function updateOrder(field: FundReportOrderField, direction: OrderDirection) {
  orderBy.value.field = field
  orderBy.value.direction = direction
}
</script>

<template>
  <div class="FundReportCatalog">
    <div class="header">
      <div class="title">
        {{ t.title }}
      </div>
      <SButton
        v-if="canCreate.ok"
        mode="info"
        :label="t.new_report"
        @click="on"
      />
    </div>
    <STable class="list" :options="table" />
    <SModal :open="modal" @close="off">
      <FundReportFormAdd
        @cancel="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.FundReportCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
}

.action {
  margin-left: auto;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name)      { --table-col-width: 512px; }
.list :deep(.col-updatedAt) { --table-col-width: 192px; }
</style>
