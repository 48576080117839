<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputFile from 'sefirot/components/SInputFile.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { checked, maxLength, maxTotalFileSize, required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsDdKickOffMinimalRequest } from '@/graph/Fund'
import {
  DealEntryDDKickOffConflictCheckStatus,
  DealEntryDDKickOffPreReviewStatus
} from '@/graphql'
import { type Deal } from '@/models/Deal'
import { useRequestDdMinimal } from '@/composables/repos/DealEntryRepo'
import DealEntryDdRequestMinimalComplete from './DealEntryDdRequestMinimalComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsDdKickOffMinimalRequest
}>()

const { t } = useTrans({
  en: {
    i_date_label: 'DD Kickoff date *',
    i_date_help: 'Select an approximately date.',
    i_ic_date_label: 'Investment Committee date (preferred date) *',
    i_ic_date_help: 'Select an preferred date.',
    i_prs_label: 'Pre Review Status *',
    i_prs_o_passed: 'Passed',
    i_prs_o_passed_cond: 'Passed conditionally',
    i_prs_o_not_held: 'Not held',
    i_ccs_label: 'Conflict Check Status *',
    i_ccs_o_completed: 'Completed',
    i_ccs_o_in_progress: 'In progress',
    i_ccs_o_unexamined: 'Unexamined',
    i_ccs_o_not_applicable: 'Not applicable',
    i_pr_file_label: 'File used for Pre Review',
    i_pr_file_text: 'Choose File',
    i_pr_file_ph: 'No file chosen',
    i_pr_file_help: 'Pre Review referes to Pre Review Commitee, Business Review Committee, or any committee equivalent to those.',
    i_file_label: 'Files gathered from the startup',
    i_file_text: 'Choose File',
    i_file_ph: 'No file chosen',
    i_esg_text: 'I have understood the ESG Checklist',
    i_notes_ph: 'Add any additional notes on the DD Kickoff',
    submit: 'Submit request'
  },
  ja: {
    i_date_label: 'DD Kickoff開催希望日 *',
    i_date_help: 'おおよそこの日頃の日付を選択してください。',
    i_ic_date_label: '投資委員会予定日（希望日） *',
    i_ic_date_help: '投資委員会開催を希望する日付を選択してください。',
    i_prs_label: 'プレ検討・事業検討会状況 *',
    i_prs_o_passed: '通過',
    i_prs_o_passed_cond: '条件付き通過',
    i_prs_o_not_held: '未実施',
    i_ccs_label: 'コンフリクト回避確認状況 *',
    i_ccs_o_completed: '完了',
    i_ccs_o_in_progress: '検討中',
    i_ccs_o_unexamined: '未検討',
    i_ccs_o_not_applicable: '該当なし',
    i_pr_file_label: 'プレ検・事業検討会資料',
    i_pr_file_text: 'ファイルを選択',
    i_pr_file_ph: 'ファイルが選択されていません',
    i_pr_file_help: '社内プレ検討会、事業検討会、あるいはこれらと同等の会議で使用した資料を添付してください。',
    i_file_label: '受領済み資料',
    i_file_text: 'ファイルを選択',
    i_file_ph: 'ファイルが選択されていません',
    i_esg_text: 'ESGチェック項目の確認について理解した',
    i_notes_ph: '追加でコメントがあれば入力してください',
    submit: '送信する'
  }
})

const router = useRouter()

const { execute: requestDdFull, loading } = useRequestDdMinimal(() => props.deal.id!, () => ({
  date: data.value.date!.format('YYYY-MM-DD'),
  investmentCommitteeDate: data.value.investmentCommitteeDate!.format('YYYY-MM-DD'),
  preReviewStatus: data.value.preReviewStatus!,
  conflictCheckStatus: data.value.conflictCheckStatus!,
  preReviewFile: data.value.preReviewFile,
  file: data.value.file,
  notes: data.value.notes
}))

const completeDialog = usePower()

const { data } = useData({
  date: null as Day | null,
  investmentCommitteeDate: null as Day | null,
  preReviewStatus: null as DealEntryDDKickOffPreReviewStatus | null,
  conflictCheckStatus: null as DealEntryDDKickOffConflictCheckStatus | null,
  preReviewFile: null as File | null,
  file: null as File | null,
  esg: false,
  notes: null as string | null
})

const allFiles = computed(() => {
  return [
    data.value.preReviewFile,
    data.value.file
  ].reduce<File[]>((carry, file) => {
    file && carry.push(file)
    return carry
  }, [])
})

const { validation, validateAndNotify } = useValidation(() => ({
  ...data.value,
  allFiles: allFiles.value
}), {
  date: {
    required: required()
  },
  investmentCommitteeDate: {
    required: required()
  },
  preReviewStatus: {
    required: required()
  },
  conflictCheckStatus: {
    required: required()
  },
  allFiles: {
    maxTotalFileSize: maxTotalFileSize('50MB')
  },
  esg: {
    checked: checked()
  },
  notes: {
    maxLength: maxLength(2000)
  }
})

const preReviewStatusOptions = [
  { label: t.i_prs_o_passed, value: DealEntryDDKickOffPreReviewStatus.Passed },
  { label: t.i_prs_o_passed_cond, value: DealEntryDDKickOffPreReviewStatus.PassedConditionally },
  { label: t.i_prs_o_not_held, value: DealEntryDDKickOffPreReviewStatus.NotHeld }
]

const conflictCheckStatusOptions = [
  { label: t.i_ccs_o_completed, value: DealEntryDDKickOffConflictCheckStatus.Completed },
  { label: t.i_ccs_o_in_progress, value: DealEntryDDKickOffConflictCheckStatus.InProgress },
  { label: t.i_ccs_o_unexamined, value: DealEntryDDKickOffConflictCheckStatus.Unexamined },
  { label: t.i_ccs_o_not_applicable, value: DealEntryDDKickOffConflictCheckStatus.NotApplicable }
]

async function submit() {
  if (await validateAndNotify()) {
    await requestDdFull()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryDdRequestMinimal">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SDocSection class="intro">
              <SContent>
                <STrans lang="en">
                  <h1>Request DD Kickoff MTG (Follow-up Deal)</h1>
                  <p>You may request DD Kickoff MTG from this form. <SLink class="link" href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.qmk07zc6r01w">Refer to this link</SLink> for more details about DD Kickoff MTG.</p>
                </STrans>
                <STrans lang="ja">
                  <h1>DD Kickoff MTG 開催依頼（追加）</h1>
                  <p>DD Kickoff MTGの開催を依頼するためのエントリーフォームです。DD Kickoff MTGの<SLink class="link" href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.qmk07zc6r01w">詳細についてはこちら</SLink>を確認してください。</p>
                </STrans>
              </SContent>
            </SDocSection>
            <SDivider />
            <SDocSection class="dates">
              <SContent>
                <STrans lang="en">
                  <h2>Desired metting date</h2>
                  <p>Fill in your desired date to have kickoff meeting. Note that The DD kickoff date should (in principle) be set at least 30 days before the investment committee meeting.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>キックオフ希望日程</h2>
                  <p>キックオフを開催したい希望日程を選択してください。キックオフ開催日は原則、投資委員会開催日の30日以上前に設定してください。</p>
                </STrans>
              </SContent>
              <SInputDate
                :label="t.i_date_label"
                :help="t.i_date_help"
                v-model="data.date"
                block
                :validation="validation.date"
              />
              <SInputDate
                :label="t.i_ic_date_label"
                :help="t.i_ic_date_help"
                v-model="data.investmentCommitteeDate"
                block
                :validation="validation.investmentCommitteeDate"
              />
            </SDocSection>
            <SDivider />
            <SDocSection class="review-status">
              <SContent>
                <STrans lang="en">
                  <h2>Deal review status</h2>
                  <p>Select the status of the deal. If you select “Not held” or “Unexamined”, please describe what you plan to do with those in “Notes” section.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>ディール検討状況</h2>
                  <p>ディールの検討状況を選択してください。「未実施」や「未検討」を選択する場合、「備考」欄に今後の予定を記載してください。</p>
                </STrans>
              </SContent>
              <SInputRadios
                :label="t.i_prs_label"
                :options="preReviewStatusOptions"
                v-model="data.preReviewStatus"
                :validation="validation.preReviewStatus"
              >
                <template #info>
                  <STrans lang="en">The status of Pre Review Committee or Business Review Committee or any event equivalent to it.</STrans>
                  <STrans lang="ja">社内プレ検討会や事業検討会、またはそれに準ずる検討状況を指します。</STrans>
                </template>
              </SInputRadios>
              <SInputRadios
                :label="t.i_ccs_label"
                :options="conflictCheckStatusOptions"
                v-model="data.conflictCheckStatus"
                :validation="validation.conflictCheckStatus"
              >
                <template #info>
                  <STrans lang="en">Whether the <SLink href="https://docs.google.com/document/d/1qYAAAKk862bAojQe7rBUPFMyyU7QUPnV-ItozozAObo/edit">conflict check</SLink> has been completed or not.</STrans>
                  <STrans lang="ja"><SLink href="https://docs.google.com/document/d/1qYAAAKk862bAojQe7rBUPFMyyU7QUPnV-ItozozAObo/edit">コンフリクト回避ルール</SLink>で定められた項目の確認状況を指します。</STrans>
                </template>
              </SInputRadios>
            </SDocSection>
            <SDivider />
            <SDocSection class="documents">
              <SContent>
                <STrans lang="en">
                  <h2>Documents</h2>
                  <p>Attach the required documents for the DD. If you do not yet have these documents on hand, you may leave the fields empty. You can find the documents you should obtain from the startup in “<SLink href="https://docs.google.com/document/d/1-kFozPvuNTkHTDP1P5kTJKwYGxlywehFuydru8tuQCc">GB Resources: 07-01 Investment process</SLink>”.</p>
                  <p>If there are multiple files, pack them into a ZIP file. You may attach up to 50 MB in total. If the files are too large to attach, share it on Slack or via Google Drive after being invited to the Slack channel.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>ドキュメント</h2>
                  <p>DDに必要な文書を添付してください。まだこれらの文書が手元にない場合には、以下の項目は空の状態で送信して構いません。スタートアップから収集するべき文書については「<SLink href="https://docs.google.com/document/d/1-kFozPvuNTkHTDP1P5kTJKwYGxlywehFuydru8tuQCc">GB Resources: 07-01 投資検討プロセス</SLink>」を参照してください。</p>
                  <p>ファイルが複数ある場合にはZIPに固めて添付してください。全てのファイルで合計50MBまで添付可能です。ファイル容量が大きいために添付できない場合、Slackチャンネルに招待された後、チャンネル上もしくはGoogle Drive経由で共有してください。</p>
                </STrans>
              </SContent>
              <SInputFile
                :label="t.i_pr_file_label"
                :text="t.i_pr_file_text"
                :placeholder="t.i_pr_file_ph"
                :help="t.i_pr_file_help"
                v-model="data.preReviewFile"
              />
              <SInputFile
                :label="t.i_file_label"
                :text="t.i_file_text"
                :placeholder="t.i_file_ph"
                v-model="data.file"
              />
              <SAlert v-if="validation.allFiles.$invalid" mode="danger">
                <STrans lang="en">The file size must be less than 50MB in total.</STrans>
                <STrans lang="ja">全てのファイルで合計50MB以下にしてください。</STrans>
              </SAlert>
            </SDocSection>
            <SDivider />
            <SDocSection>
              <SContent>
                <STrans lang="en">
                  <h2>ESG Checklist</h2>
                  <p>When considering an investment, it is necessary to explain and inquire about the ESG Policy to the startup and review the <SLink href="https://docs.google.com/presentation/d/1ddl5Cs4yrL8s_V4WfwgQZSE86mhKwY4U/edit#slide=id.p28">ESG Checklist</SLink>.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>ESG対応</h2>
                  <p>投資検討時には、投資検討先に対してESGポリシーの説明とヒヤリングを行い、<SLink href="https://docs.google.com/presentation/d/1ddl5Cs4yrL8s_V4WfwgQZSE86mhKwY4U/edit#slide=id.p28">ESGチェック項目</SLink>を確認する必要があります。</p>
                </STrans>
              </SContent>
              <SInputCheckbox
                :text="t.i_esg_text"
                v-model="data.esg"
                :validation="validation.esg"
              />
            </SDocSection>
            <SDivider />
            <SDocSection class="notes">
              <SContent>
                <STrans lang="en">
                  <h2>Notes</h2>
                  <p>Add additional notes on the DD kickoff if there’s any. This is optional.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>備考</h2>
                  <p>追加でコメントがあれば入力してください。本項目は任意入力です。</p>
                </STrans>
              </SContent>
              <SInputTextarea
                :placeholder="t.i_notes_ph"
                :rows="5"
                v-model="data.notes"
                :validation="validation.notes"
              />
            </SDocSection>
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryDdRequestMinimalComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryDdRequestMinimal {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
