import { type DealProgress, DealStatus, ExitMethod, type SecurityKind } from '@/graphql'
import { type Ymd, dayFromYearMonth, ymdFromYearMonth } from '@/support/Day'
import { assert } from '@/support/Error'
import { AntiDilutionProvision } from './AntiDilutionProvision'
import { type Company } from './Company'
import { DealDroppedReasonType } from './DealDroppedReasonType'
import { DealEvent } from './DealEvent'
import { DealEventTemplate } from './DealEventTemplate'
import { DealShareRank } from './DealShareRank'
import { Fund } from './Fund'
import { LiquidationPreferencePattern } from './LiquidationPreferencePattern'
import { type Day, Model } from './Model'
import { Money } from './Money'
import { type Opportunity } from './Opportunity'
import { Round } from './Round'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export { SecurityKind } from '@/graphql'
export type { DealProgress, ExitMethod } from '@/graphql'

export const DealStatuses = {
  [DealStatus.Closing]: 'Closing',
  [DealStatus.Contract]: 'Contract',
  [DealStatus.Dropped]: 'Dropped',
  [DealStatus.Finalize]: 'Finalize',
  [DealStatus.InitialReview]: 'Initial Review',
  [DealStatus.Invested]: 'Invested',
  [DealStatus.InvestmentCommittee]: 'Investment Committee',
  [DealStatus.Remittance]: 'Remittance',
  [DealStatus.Report]: 'Report'
} as const

export const DealStatusesAbbreviated = {
  InitialReview: 'IR',
  InvestmentCommittee: 'IC',
  Contract: 'Contract',
  Remittance: 'Remittance',
  Invested: 'Invested',
  Dropped: 'Dropped'
} as const

export const SecurityKinds = {
  CommonStock: 'Common Stock',
  ClassifiedStock: 'Preferred Stock',
  Safe: 'SAFE',
  JKiss: 'J-KISS',
  Warrant: 'Warrant',
  ConvertibleBond: 'Convertible Bond',
  ConvertibleNote: 'Convertible Note',
  ConvertibleLoan: 'Convertible Loan'
} as const

export const ExitMethods = {
  [ExitMethod.Ipo]: 'IPO',
  [ExitMethod.Ma]: 'MA',
  [ExitMethod.SecondarySale]: 'Secondary Sale'
} as const

export class Deal extends Model implements AccessControlled {
  subject = AccessResource.deal
  permittedActions: AccessControlled['permittedActions']

  id?: number

  openedDate?: Day
  investedDate?: Day
  droppedDate?: Day

  assignedAs: string | null
  partnerInCharge: User | null
  primaryInCharge: User | null
  deputyInCharge: User | null
  participants: User[]
  shareRankAmongExternalInvestors: DealShareRank | null
  gbShareRankAmongExternalInvestors: DealShareRank | null

  status?: DealStatus
  progress: DealProgress[]

  droppedReasonType: DealDroppedReasonType | null
  droppedReason?: string

  deadline: Day | null

  lead?: boolean
  investmentAmount: Money | null
  investedAmount: Money | null
  bookValue?: string
  ddExpense?: string

  securityKind?: SecurityKind
  securityName?: string
  securityQuantity?: number
  securityUnitPrice: Money | null
  liquidationPreferenceMultiple?: string
  liquidationPreferencePattern: LiquidationPreferencePattern | null
  antiDilutionProvision: AntiDilutionProvision | null
  annualInterest?: string
  repaymentRight?: boolean
  repaymentDate?: string
  discount?: string
  valuationCap: Money | null
  eligibleFinancingAmount: Money | null

  informationRight?: boolean
  boardObserverRight?: boolean
  rightToAppointDirector?: boolean
  rightForFirstOffer?: boolean
  preemptiveRight?: boolean
  keymanClause?: boolean
  cosaleRight?: boolean
  dragAlongRight?: boolean
  otherRights?: string

  estimatedExitMethods?: ExitMethod[]
  estimatedExitTime?: string
  estimatedROI?: string
  estimatedIRR?: string

  roundId?: number
  round: Round | null

  fundId?: number
  fund: Fund | null

  eventTemplates: DealEventTemplate[]
  events: DealEvent[]

  createdAt?: Day | null
  createdById?: number
  createdBy: User | null
  updatedAt?: Day | null
  updatedById?: number
  updatedBy: User | null

  constructor(data: Record<string, any>) {
    super()
    this.permittedActions = data.permittedActions ?? []

    this.id = data.id

    this.openedDate = this.day(data.openedDate)
    this.investedDate = this.day(data.investedDate)
    this.droppedDate = this.day(data.droppedDate)

    this.assignedAs = data.assignedAs
    this.partnerInCharge = this.hasOne(User, data.partnerInCharge)
    this.primaryInCharge = this.hasOne(User, data.primaryInCharge)
    this.deputyInCharge = this.hasOne(User, data.deputyInCharge)
    this.participants = this.hasMany(User, data.participants)
    this.shareRankAmongExternalInvestors = this.hasOne(DealShareRank, data.shareRankAmongExternalInvestors)
    this.gbShareRankAmongExternalInvestors = this.hasOne(DealShareRank, data.gbShareRankAmongExternalInvestors)

    this.status = data.status
    this.progress = data.progress
    this.deadline = this.day(data.deadline)

    this.lead = data.lead
    this.investmentAmount = this.hasOne(Money, data.investmentAmount)
    this.investedAmount = this.hasOne(Money, data.investedAmount)
    this.bookValue = data.bookValue
    this.ddExpense = data.ddExpense

    this.securityKind = data.securityKind
    this.securityName = data.securityName
    this.securityQuantity = data.securityQuantity
    this.securityUnitPrice = this.hasOne(Money, data.securityUnitPrice)
    this.liquidationPreferenceMultiple = data.liquidationPreferenceMultiple

    this.liquidationPreferencePattern = this.hasOne(LiquidationPreferencePattern, data.liquidationPreferencePattern)
    this.antiDilutionProvision = this.hasOne(AntiDilutionProvision, data.antiDilutionProvision)
    this.annualInterest = data.annualInterest
    this.repaymentRight = data.repaymentRight
    this.repaymentDate = data.repaymentDate
    this.discount = data.discount
    this.valuationCap = this.hasOne(Money, data.valuationCap)
    this.eligibleFinancingAmount = this.hasOne(Money, data.eligibleFinancingAmount)

    this.informationRight = data.informationRight
    this.boardObserverRight = data.boardObserverRight
    this.rightToAppointDirector = data.rightToAppointDirector
    this.rightForFirstOffer = data.rightForFirstOffer
    this.preemptiveRight = data.preemptiveRight
    this.keymanClause = data.keymanClause
    this.cosaleRight = data.cosaleRight
    this.dragAlongRight = data.dragAlongRight
    this.otherRights = data.otherRights

    this.estimatedExitMethods = data.estimatedExitMethods
    this.estimatedExitTime = data.estimatedExitTime
    this.estimatedROI = data.estimatedROI
    this.estimatedIRR = data.estimatedIRR

    this.roundId = data.roundId
    this.round = this.hasOne(Round, data.round)
    this.fundId = data.fundId
    this.fund = this.hasOne(Fund, data.fund)

    this.eventTemplates = this.createEventTemplatesField(data.eventTemplates)
    this.events = this.hasMany(DealEvent, data.events)

    this.droppedReasonType = this.hasOne(DealDroppedReasonType, data.droppedReasonType)
    this.droppedReason = data.droppedReason

    this.createdAt = this.day(data.createdAt)
    this.createdById = data.createdById
    this.createdBy = this.hasOne(User, data.createdBy)
    this.updatedAt = this.day(data.updatedAt)
    this.updatedById = data.updatedById
    this.updatedBy = this.hasOne(User, data.updatedBy)
  }

  static statusEntries() {
    return Object.entries(DealStatuses)
  }

  static statusOptions() {
    return Deal.statusEntries()
      .map(([value, label]) => {
        return { label, value }
      })
  }

  get path(): string {
    return `/deals/${this.id}`
  }

  get title(): string {
    assert(!!this.fund, [
      'Trying to access the "deal.title" but the `deal.fund` relation is not',
      'loaded. The `deal.fund` is required to generate the deal title. Check,',
      'how you\'re querying the deal model.'
    ])

    const fundName = this.fund!.nameAbbreviated
    const createdAt = this.createdAt!.format('YYYY-MM-DD')

    return `${fundName} ${createdAt}`
  }

  get statusValue(): string {
    return DealStatuses[this.status!]
  }

  get estimatedExitMethodsValue(): string[] {
    return (this.estimatedExitMethods ?? []).map((key) => {
      return ExitMethods[key]
    })
  }

  get estimatedExitTimeAsDay(): Day | null {
    return this.estimatedExitTime ? dayFromYearMonth(this.estimatedExitTime) : null
  }

  get estimatedExitTimeAsYmd(): Ymd {
    return this.estimatedExitTime
      ? ymdFromYearMonth(this.estimatedExitTime)
      : { year: null, month: null, date: null }
  }

  protected createEventTemplatesField(templates?: any[]): DealEventTemplate[] {
    return templates?.map((template) => {
      return new DealEventTemplate(template.event)
    }) ?? []
  }

  isInvested(): boolean {
    return this.status === DealStatus.Invested
  }

  isDropped(): boolean {
    return this.status === DealStatus.Dropped
  }

  companyThroughRound(): Company | null {
    return this.round?.company ?? null
  }

  opportunityThroughRound(): Opportunity | null {
    return this.round?.opportunity ?? null
  }

  ddExpenseAsMoney(): Money {
    return Money.createJpy(this.ddExpense!)
  }

  getAssignees(): User[] {
    const assignees: User[] = []

    assignees.push(this.primaryInCharge!)

    if (this.deputyInCharge) {
      assignees.push(this.deputyInCharge)
    }

    if (this.participants.length) {
      assignees.push(...this.participants)
    }

    return assignees
  }
}
