import { useLang, useTrans } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import { type Fund } from '@/graph/Fund'
import {
  type FundFrag,
  FundGroupType,
  type FundOperatorFrag,
  FundOperatorRole,
  FundStatus,
  type FundWithTimestampFrag
} from '@/graphql'
import { abbreviate } from '@/support/Num'
import { type Entries } from '@/support/Types'
import { defineOps } from './Ops'

export interface FundSelectOption {
  label: string
  value: number
}

export const FundGroupTypeDict = {
  [FundGroupType.Cvc]: 'CVC',
  [FundGroupType.Flagship]: 'Flagship'
} as const

export const FundOperatorRoleTextDict = {
  [FundOperatorRole.Administrator]: 'Administrator',
  [FundOperatorRole.BackOffice]: 'Back Office',
  [FundOperatorRole.Front]: 'Front'
} as const

export const useFundOps = defineOps(() => {
  const lang = useLang()

  const { t: statusTextDict } = useTrans({
    en: {
      NotReady: 'In fundraising',
      Active: 'Active',
      ActiveOnlyFollowOn: 'Follow-on only',
      Inactive: 'Inactive',
      Liquidated: 'Liquidated'
    },
    ja: {
      NotReady: '組成中',
      Active: 'アクティブ',
      ActiveOnlyFollowOn: '新規投資終了',
      Inactive: '運用終了',
      Liquidated: '清算済'
    }
  })

  const statusModeDict = {
    NotReady: 'info',
    Active: 'success',
    ActiveOnlyFollowOn: 'success',
    Inactive: 'mute',
    Liquidated: 'mute'
  } as const

  function path(fund: Fund): string {
    return `/funds/${fund.id}`
  }

  function statusText(fund: Fund): string {
    return statusTextDict[fund.status]
  }

  function statusTextByValue(status: FundStatus): string {
    return statusTextDict[status]
  }

  function statusMode(fund: Fund): 'info' | 'success' | 'mute' {
    return statusModeDict[fund.status]
  }

  function sizeAbbr(fund: Fund, fraction: number = 1): string | null {
    return fund.size == null ? null : abbreviate(Number(fund.size), fraction, lang)
  }

  function investableAmountAbbr(fund: Fund, fraction: number = 1): string | null {
    return fund.investableAmount == null ? null : abbreviate(Number(fund.investableAmount), fraction, lang)
  }

  function startDate(fund: Fund): Day | null {
    return fund.startDate ? day(fund.startDate) : null
  }

  function investmentEndDate(fund: Fund): Day | null {
    return fund.investmentEndDate ? day(fund.investmentEndDate) : null
  }

  function endDate(fund: Fund): Day | null {
    return fund.endDate ? day(fund.endDate) : null
  }

  function createdAt(fund: Fund | FundWithTimestampFrag): Day {
    return day(fund.createdAt)
  }

  function updatedAt(fund: Fund | FundWithTimestampFrag): Day {
    return day(fund.updatedAt)
  }

  function isOngoing(fund: Fund): boolean {
    return isActive(fund) || isActiveOnlyFollowOn(fund)
  }

  function isActive(fund: Fund | FundFrag): boolean {
    return fund.status === FundStatus.Active
  }

  function isActiveOnlyFollowOn(fund: Fund | FundFrag): boolean {
    return fund.status === FundStatus.ActiveOnlyFollowOn
  }

  function createSelectOptions(funds: FundFrag[]): FundSelectOption[] {
    return funds.map((f) => ({
      label: f.nameAbbreviated + (f.group.lp ? ` (${f.group.lp})` : ''),
      value: f.id
    }))
  }

  return {
    path,
    statusText,
    statusTextByValue,
    statusMode,
    sizeAbbr,
    investableAmountAbbr,
    startDate,
    investmentEndDate,
    endDate,
    createdAt,
    updatedAt,
    isOngoing,
    isActive,
    isActiveOnlyFollowOn,
    createSelectOptions
  }
})

export const useFundOperatorOps = defineOps(() => {
  function roleText(fo: FundOperatorFrag): string {
    return FundOperatorRoleTextDict[fo.role]
  }

  function roleTextEntries(): Entries<typeof FundOperatorRoleTextDict> {
    return Object.entries(FundOperatorRoleTextDict)
  }

  return {
    roleText,
    roleTextEntries
  }
})
