<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Competitive advantages',
    info: 'What kind of strength and advantage the startup has against competitors.',
    ph: 'Strong network of local farmers. Government support. etc.'
  },
  ja: {
    label: 'Competitive advantages',
    info: '競合に対してどのような強み、優位性を持っているか。',
    ph: '20XX年にシードラウンドを調達。20XX年にシリーズAラウンドを調達。など。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
