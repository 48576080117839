<script setup lang="ts">
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SInputYMD from 'sefirot/components/SInputYMD.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Ymd } from 'sefirot/support/Day'
import { decimal, maxLength, maxValue, required, requiredYmd, ymd } from 'sefirot/validation/rules'
import { type CurrencyFrag } from '@/graphql'
import DInputMoney from '../DInputMoney.vue'
import DInputMonth from '../DInputMonth.vue'
import { type DataYesOrNo } from './DealFormUpdateReport.vue'

const props = defineProps<{
  editable: DataYesOrNo
  currencies?: CurrencyFrag[] | null
  company: string | null
  representativeName: string | null
  representativeTitle: string | null
  founded: Ymd
  fiscalMonth: number | null
  capitalValue: string | null
  capitalCurrency: CurrencyFrag | null
  employees: number | null
  address: string | null
  description: string | null
}>()

defineEmits<{
  (e: 'update:company', value: string | null): void
  (e: 'update:representative-name', value: string | null): void
  (e: 'update:representative-title', value: string | null): void
  (e: 'update:founded', value: Ymd): void
  (e: 'update:fiscal-month', value: number | null): void
  (e: 'update:capital-value', value: string | null): void
  (e: 'update:capital-currency', value: CurrencyFrag | null): void
  (e: 'update:employees', value: number | null): void
  (e: 'update:address', value: string | null): void
  (e: 'update:description', value: string | null): void
}>()

const { t } = useTrans({
  en: {
    i_name_label: 'Company name *',
    i_name_ph: 'Awesome Company',
    i_representative_name_label: 'Representative name *',
    i_representative_name_ph: 'John Doe',
    i_representative_title_label: 'Representative title *',
    i_representative_title_ph: 'CEO',
    i_founded_label: 'Founded at *',
    i_fiscal_month_label: 'Fiscal month *',
    i_fiscal_month_ph: 'Select month',
    i_capital_label: 'Capital *',
    i_capital_info: 'If no capital exists, enter 0 in this field.',
    i_capital_ph: '10,000,000',
    i_employees_label: 'Number of employees *',
    i_employees_ph: '100',
    i_address_label: 'Address *',
    i_address_ph: '10-11 Sakuragaoka-cho, Shibuya-ku, Tokyo',
    i_description_label: 'Business description *',
    i_description_ph: 'e.g. Offering XXX solutions powered by YYY technology, etc.'
  },
  ja: {
    i_name_label: '企業名 *',
    i_name_ph: '株式会社オーサム',
    i_representative_name_label: '代表者名 *',
    i_representative_name_ph: '山田 太郎',
    i_representative_title_label: '代表者役職 *',
    i_representative_title_ph: 'CEO',
    i_founded_label: '設立年月 *',
    i_fiscal_month_label: '決算月 *',
    i_fiscal_month_ph: '月を選択してください',
    i_capital_label: '資本金 *',
    i_capital_info: '企業の中には資本金が存在しないケースがあります。その場合には「0」を入力してください。',
    i_capital_ph: '10,000,000',
    i_employees_label: '従業員数 *',
    i_employees_ph: '100',
    i_address_label: '住所 *',
    i_address_ph: '〒150-0031 東京都渋谷区桜丘町10-11',
    i_description_label: '事業内容 *',
    i_description_ph: '例: XXXソリューションをYYYテクノロジーで提供'
  }
})

const { validation } = useValidation(() => ({
  company: props.company,
  representativeName: props.representativeName,
  representativeTitle: props.representativeTitle,
  founded: props.founded,
  fiscalMonth: props.fiscalMonth,
  capitalValue: props.capitalValue,
  employees: props.employees,
  address: props.address,
  description: props.description
}), {
  company: {
    required: required(),
    maxLength: maxLength(100)
  },
  representativeName: {
    required: required(),
    maxLength: maxLength(70)
  },
  representativeTitle: {
    required: required(),
    maxLength: maxLength(100)
  },
  founded: {
    requiredYmd: requiredYmd(['y', 'm']),
    ymd: ymd(['y', 'm'])
  },
  fiscalMonth: {
    required: required()
  },
  capitalValue: {
    required: required(),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  employees: {
    required: required(),
    maxValue: maxValue(1000000)
  },
  address: {
    required: required(),
    maxLength: maxLength(255)
  },
  description: {
    required: required(),
    maxLength: maxLength(3000)
  }
})
</script>

<template>
  <DFormSection>
    <STrans lang="en">
      <DFormTitle>Company information</DFormTitle>
      <DFormLead>Enter company information.</DFormLead>
    </STrans>
    <STrans lang="ja">
      <DFormTitle>企業情報</DFormTitle>
      <DFormLead>企業に関する情報を入力してください。</DFormLead>
    </STrans>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputText
          :label="t.i_name_label"
          :placeholder="t.i_name_ph"
          :disabled="editable === 'no'"
          :model-value="company"
          :validation="validation.company"
          @update:model-value="v => $emit('update:company', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          :label="t.i_representative_name_label"
          :placeholder="t.i_representative_name_ph"
          :disabled="editable === 'no'"
          :model-value="representativeName"
          :validation="validation.representativeName"
          @update:model-value="v => $emit('update:representative-name', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          :label="t.i_representative_title_label"
          :placeholder="t.i_representative_title_ph"
          :disabled="editable === 'no'"
          :model-value="representativeTitle"
          :validation="validation.representativeTitle"
          @update:model-value="v => $emit('update:representative-title', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <SInputYMD
          :label="t.i_founded_label"
          no-date
          block
          :disabled="editable === 'no'"
          :model-value="founded"
          :validation="validation.founded"
          @update:model-value="v => $emit('update:founded', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="4" />
      <DFormGridItem span="2">
        <DInputMonth
          :label="t.i_fiscal_month_label"
          :placeholder="t.i_fiscal_month_ph"
          :disabled="editable === 'no'"
          :model-value="fiscalMonth"
          :validation="validation.fiscalMonth"
          @update:model-value="v => $emit('update:fiscal-month', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="4">
        <DInputMoney
          :label="t.i_capital_label"
          :info="t.i_capital_info"
          :placeholder="t.i_capital_ph"
          :currencies="currencies"
          :disabled="editable === 'no'"
          :currency="capitalCurrency"
          :model-value="capitalValue"
          :validation="validation.capitalValue"
          @update:model-value="v => $emit('update:capital-value', v)"
          @update:currency="v => $emit('update:capital-currency', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <SInputNumber
          :label="t.i_employees_label"
          :placeholder="t.i_employees_ph"
          separator
          :disabled="editable === 'no'"
          :model-value="employees"
          :validation="validation.employees"
          @update:model-value="v => $emit('update:employees', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="4" />
      <DFormGridItem span="6">
        <SInputText
          :label="t.i_address_label"
          :placeholder="t.i_address_ph"
          :disabled="editable === 'no'"
          :model-value="address"
          :validation="validation.address"
          @update:model-value="v => $emit('update:address', v)"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          :label="t.i_description_label"
          :placeholder="t.i_description_ph"
          :disabled="editable === 'no'"
          :model-value="description"
          :validation="validation.description"
          @update:model-value="v => $emit('update:description', v)"
        />
      </DFormGridItem>
    </DFormGrid>
  </DFormSection>
</template>
