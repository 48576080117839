<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Round name suffix',
    info: 'Extra name for the round. For example, when there are “Series A” and “Series A2”.',
    ph: 'e.g. ’, 1, 2nd'
  },
  ja: {
    label: 'Round name suffix',
    info: 'ラウンドの拡張子。例えば、「シリーズA」と「シリーズA2」があるような場合。',
    ph: '例：’、1、2nd'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(10)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputText
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    v-model="model"
    :validation="validation.model"
  />
</template>
