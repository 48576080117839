<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import { type Validatable } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { type FieldInputText } from '@/support/Field'
import { useFieldOps } from '@/composables/ops/FieldOps'

const props = defineProps<{
  field: FieldInputText
  modelValue: string | null
  validation?: Validatable
  editable: boolean
}>()

defineEmits<{
  (e: 'update:model-value', value: string): void
}>()

const fieldOps = useFieldOps()

const isRequired = computed(() => {
  const keys = Object.keys(props.validation ?? {})
  return keys.includes('required') || keys.includes('requiredIf')
})
</script>

<template>
  <SInputText
    :label="fieldOps.label(field)"
    :note="isRequired ? '*' : undefined"
    :placeholder="fieldOps.placeholder(field)"
    :model-value="modelValue"
    :validation="validation"
    :disabled="!editable"
    @update:model-value="v => $emit('update:model-value', v ?? '')"
  />
</template>
