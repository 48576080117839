<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { type DealWithExpenseFrag, type DealWithIdFrag, type WithPermissionFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealFormUpdateExpense from './DealFormUpdateExpense.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithExpenseFrag & WithPermissionFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)
const dealOps = useDealOps()
const { state: modal, on, off } = usePower(false)

const actions = computedWhen(canUpdate, () => {
  return [{ icon: 'edit', onClick: on }]
}, [])
</script>

<template>
  <DCard title="Expense" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="1">
        <DCardGridLabel text="DD expense" />
        <DCardGridMoney :money="dealOps.ddExpense(deal)" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal">
      <DealFormUpdateExpense
        :deal="deal"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </DCard>
</template>

<style scoped lang="postcss">
.empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.empty-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
