<script setup lang="ts">
import IconCaretLeft from '~icons/ph/caret-left-bold'
import { omit } from 'lodash-es'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import {
  type AddOpportunityProposalsInputItem,
  type OpportunityForDetailsFrag,
  type ProposableFundGroupFrag,
  ProposalStatus
} from '@/graphql'
import OpportunityProposalAddWizardFillDetailsItem from './OpportunityProposalAddWizardFillDetailsItem.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  proposableFundGroups: ProposableFundGroupFrag[]
}>()

const emit = defineEmits<{
  back: []
  cancel: []
  filled: [input: AddOpportunityProposalsInputItem[]]
}>()

const { t } = useTrans({
  en: {
    a_back: 'Back',
    a_cancel: 'Cancel',
    a_select: 'Fill in data'
  },
  ja: {
    a_back: '戻る',
    a_cancel: 'キャンセル',
    a_select: '入力を確定'
  }
})

const { data } = useData({
  items: createItemsData()
})

const { validateAndNotify } = useValidation()

function createItemsData() {
  return props.proposableFundGroups.map<AddOpportunityProposalsInputItem>((pfg) => ({
    fundGroupId: pfg.fundGroup.id,
    sourceId: props.opportunity.sources[0].id,
    data: {
      fundGroupId: pfg.fundGroup.id,
      status: ProposalStatus.Open
    }
  }))
}

async function select() {
  if (await validateAndNotify()) {
    const input = data.value.items.map((item) => ({
      fundGroupId: item.fundGroupId,
      sourceId: item.sourceId,
      data: JSON.stringify(omit(item.data, 'fundGroupId', 'status'))
    }))

    emit('filled', input)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds</h2>
            <p>Fill in required details for each funds.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityをファンドへ開示する</h2>
            <p>各ファンドへの開示に必要な追加情報を入力してください。</p>
          </STrans>
        </SContent>
        <OpportunityProposalAddWizardFillDetailsItem
          v-for="pfg, i in proposableFundGroups"
          :key="pfg.fundGroup.id"
          :opportunity="opportunity"
          :fund-group="pfg.fundGroup"
          v-model="data.items[i]"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlLeft>
          <SControlButton
            :icon="IconCaretLeft"
            :label="t.a_back"
            @click="$emit('back')"
          />
        </SControlLeft>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_select"
            @click="select"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
