import { orderBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type OpportunityInboundOrOutbound } from '@/graphql'
import { OpportunityInboundOrOutboundRequest } from '@/requests/OpportunityInboundOrOutboundRequest'

export type OpportunityInboundOrOutboundList = Query<OpportunityInboundOrOutbound[]>
export type GetOpportunityInboundOrOutboundList = Get<OpportunityInboundOrOutbound[]>

export function useOpportunityInboundOrOutboundList(): OpportunityInboundOrOutboundList {
  return useQuery(() => fetchOpportunityInboundOrOutboundList())
}

export function useGetOpportunityInboundOrOutboundList(): GetOpportunityInboundOrOutboundList {
  return useGet(() => fetchOpportunityInboundOrOutboundList())
}

async function fetchOpportunityInboundOrOutboundList(): Promise<OpportunityInboundOrOutbound[]> {
  const res = await new OpportunityInboundOrOutboundRequest().fetchAll()
  return orderBy(res.data.opportunityInboundOrOutbounds, (o) => o.rank)
}
