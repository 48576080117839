import { CompanyPortfolioDataExitStatus, type CompanyShare } from '@/graphql'
import { CompanyFinancialFigures } from './CompanyFinancialFigures'
import { type Day, Model } from './Model'
import { Money } from './Money'
import { OpportunityInboundOrOutbound } from './OpportunityInboundOrOutbound'
import { OpportunityPromotedSource } from './OpportunityPromotedSource'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export {
  CompanyPortfolioDataExitStatus
}

interface CompanyPortfolioDataEmergencyContact {
  id: number
  companyId: number
  name: string
  title: string
  email: string
  createdAt: string
  updatedAt: string
}

export const CompanyPortfolioDataExitStatuses = {
  [CompanyPortfolioDataExitStatus.Ipo]: 'IPO',
  [CompanyPortfolioDataExitStatus.SecondarySale]: 'Secondary Sale',
  [CompanyPortfolioDataExitStatus.Ma]: 'M&A',
  [CompanyPortfolioDataExitStatus.Repurchase]: 'Repurchase',
  [CompanyPortfolioDataExitStatus.Liquidation]: 'Liquidation',
  [CompanyPortfolioDataExitStatus.Other]: 'Other'
} as const

export class CompanyPortfolioData extends Model implements AccessControlled {
  subject = AccessResource.companyPortfolioData

  companyId: number | null
  permittedActions: AccessControlled['permittedActions']
  totalInvestmentAmountFMC: Money
  capital: Money | null
  capitalNotes: string | null
  issuedShares: CompanyShare[]
  totalIssuedShares: number | null
  issuedSharesNotes: string | null
  financialFigures: CompanyFinancialFigures | null
  totalPotentialDilutiveShares: number | null
  potentialDilutiveSharesNotes: string | null
  isActivePortfolio: boolean | null
  exitDate: Day | null
  exitStatus: CompanyPortfolioDataExitStatus | null
  ipoLeadUnderwriter: string | null
  ipoListingMarket: string | null
  exitDetails: string | null
  promoters: User[]
  promotedInboundOrOutbound: OpportunityInboundOrOutbound | null
  promotedSource: OpportunityPromotedSource | null
  promotedBackground: string | null
  emergencyContacts: CompanyPortfolioDataEmergencyContact[]

  updatedBy: User
  updatedAt: Day

  constructor(data: Record<string, any>) {
    super()
    this.companyId = data.companyId ?? null
    this.permittedActions = data.permittedActions ?? []
    this.totalInvestmentAmountFMC = Money.createJpy(data.totalInvestmentAmountFMC)
    this.capital = this.hasOne(Money, data.capital)
    this.capitalNotes = data.capitalNotes ?? null
    this.issuedShares = data.issuedShares ?? []
    this.totalIssuedShares = data.totalIssuedShares ?? null
    this.issuedSharesNotes = data.issuedSharesNotes ?? null
    this.financialFigures = this.hasOne(CompanyFinancialFigures, data.financialFigures)
    this.totalPotentialDilutiveShares = data.totalPotentialDilutiveShares ?? null
    this.potentialDilutiveSharesNotes = data.potentialDilutiveSharesNotes ?? null
    this.isActivePortfolio = data.isActivePortfolio ?? null
    this.exitDate = data.exitDate ? this.day(data.exitDate) : null
    this.exitStatus = data.exitStatus ?? null
    this.ipoLeadUnderwriter = data.ipoLeadUnderwriter ?? null
    this.ipoListingMarket = data.ipoListingMarket ?? null
    this.exitDetails = data.exitDetails ?? null
    this.promoters = this.hasMany(User, data.promoters)
    this.promotedInboundOrOutbound = this.hasOne(OpportunityInboundOrOutbound, data.promotedInboundOrOutbound)
    this.promotedSource = this.hasOne(OpportunityPromotedSource, data.promotedSource)
    this.promotedBackground = data.promotedBackground ?? null
    this.emergencyContacts = data.emergencyContacts ?? []

    this.updatedBy = this.hasOne(User, data.updatedBy as object)
    this.updatedAt = this.day(data.updatedAt)
  }
}
