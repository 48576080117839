<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useItem } from '@/composables/repos/FundClosingCheckListRepo'

const route = useRoute()

const { data: fundClosingCheckList } = useItem(Number(route.params.id))

const title = computed(() => [
  { text: 'ファンド決算チェックリスト', link: '/fund-closing-check-lists' },
  { text: fundClosingCheckList.value?.date?.format('YYYYMMDD') }
])
</script>

<template>
  <DView
    class="FundClosingCheckListId"
    :title="title"
  />
</template>
