<template>
  <div class="DealPageReportsMigrated">
    <div class="box">
      <div class="text">
        <STrans lang="en">This deal is migrated from the old Salesforce and do not have deal report. If you need access to the report, please contact a system administrator.</STrans>
        <STrans lang="ja">このディールは旧Salesforceから移行されたもので、投資報告書が存在しません。投資報告書が必要な場合には、システム管理者に問い合わせてください。</STrans>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsMigrated {
  padding: 32px 32px 128px;
}

.box {
  margin: 0 auto;
  max-width: 768px;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 48px;
  background-color: var(--c-bg-elv-3);
}

.text {
  max-width: 640px;
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
