<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const table = useTable({
  orders: [
    'name',
    'title',
    'email'
  ],
  columns: {
    name: {
      label: 'Name',
      grow: true,
      cell: {
        type: 'text'
      }
    },
    title: {
      label: 'Job title',
      cell: {
        type: 'text'
      }
    },
    email: {
      label: 'Email',
      resizable: false,
      cell: {
        type: 'text'
      }
    }
  },
  records: computed(() => props.portfolioData.emergencyContacts)
})
</script>

<template>
  <STable class="CompanyPortfolioDataDescEmergencyContactsEmpty" :options="table" />
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataDescEmergencyContactsEmpty {
  --table-border-top: 0;
  --table-border-right: 0;
  --table-border-bottom: 0;
  --table-border-left: 0;
  --table-border-radius: 0;

  --table-padding-right: 8px;
  --table-padding-left: 8px;
}

.CompanyPortfolioDataDescEmergencyContactsEmpty :deep(.col-name)  { --table-col-width: 192px; }
.CompanyPortfolioDataDescEmergencyContactsEmpty :deep(.col-title) { --table-col-width: 192px; }
.CompanyPortfolioDataDescEmergencyContactsEmpty :deep(.col-email) { --table-col-width: 256px; }
</style>
