<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'
import { useGetCompanyParticipantChangeNote, useUpdateCompanyParticipantChangeNote } from '@/composables/repos/CompanyRepo'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    note_label: 'Assignee change note',
    note_ph: 'Fill in any additional information',
    cancel: 'Cancel',
    update: 'Update note'
  },
  ja: {
    note_label: '担当者変更メモ',
    note_ph: '特記事項があれば入力してください',
    cancel: 'キャンセル',
    update: 'メモを更新'
  }
})

const { execute: getNote } = useGetCompanyParticipantChangeNote(props.companyId)

const { data } = useData({
  note: await getNote()
})

const { validation, validateAndNotify } = useValidation(data, {
  note: {
    maxLength: maxLength(2000)
  }
})

const { loading, execute } = useUpdateCompanyParticipantChangeNote()

async function update() {
  if (await validateAndNotify()) {
    await execute(props.companyId, data.value.note)
    emit('updated')
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Update participant change note</STrans>
            <STrans lang="ja">担当者変更メモを更新</STrans>
          </h2>
        </SContent>

        <SGrid gap="24" cols="6">
          <SGridItem span="6">
            <SInputTextarea
              :label="t.note_label"
              :placeholder="t.note_ph"
              v-model="data.note"
              :validation="validation.note"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.cancel" @click="$emit('cancel')" />
          <SControlButton mode="info" :label="t.update" :loading="loading" @click="update" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
