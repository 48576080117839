<script setup lang="ts">
import IconCaretRight from '~icons/ph/caret-right-bold'
import IconClipboardText from '~icons/ph/clipboard-text-bold'
import { useTrans } from 'sefirot/composables/Lang'

const { t } = useTrans({
  en: {
    name_fund_report: 'Fund Reports',
    name_fund_closing_checklist: 'Fund Closing Checklists'
  },
  ja: {
    name_fund_report: '業務報告書',
    name_fund_closing_checklist: 'ファンド決算チェックリスト'
  }
})

const items = [
  { name: t.name_fund_report, link: '/fund-reports' },
  { name: t.name_fund_closing_checklist, link: '/fund-closing-check-lists' }
]
</script>

<template>
  <div class="ReportList">
    <SCard class="card">
      <SCardBlock v-for="item in items" :key="item.link">
        <SLink class="item" :href="item.link">
          <IconClipboardText class="icon-svg" />
          <span class="name">{{ item.name }}</span>
          <IconCaretRight class="icon-svg caret" />
        </SLink>
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.card {
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 16px;
  transition: color 0.25s, background-color 0.25s;

  &:hover {
    color: var(--c-text-info-1);
    background-color: var(--c-bg-elv-4);
    cursor: pointer;
  }
}

.name {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
}

.icon-svg {
  width: 16px;
  height: 16px;
  color: var(--c-text-2);
}

.caret {
  margin-left: auto;
  color: var(--c-text-3);
}
</style>
