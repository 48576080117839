<script setup lang="ts">
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxValue, requiredIf } from 'sefirot/validation/rules'
import { type CurrencyFrag, type DealSecurityFrag } from '@/graphql'
import { useCurrencyOps } from '@/composables/ops/CurrencyOps'
import DInputMoney from '../DInputMoney.vue'
import { type DataYesOrNo } from './DealFormUpdateReport.vue'

const props = defineProps<{
  strict: DataYesOrNo
  editable: DataYesOrNo
  security: DealSecurityFrag
  currency: CurrencyFrag
  quantity: number | null
  unitPrice: string | null
  totalPrice: string | null
  totalPriceFmc: string | null
}>()

defineEmits<{
  (e: 'update:quantity', value: number | null): void
  (e: 'update:unit-price', value: string | null): void
  (e: 'update:total-price', value: string | null): void
  (e: 'update:total-price-fmc', value: string | null): void
}>()

const { t } = useTrans({
  en: {
    i_quantity_label: 'Quantity',
    i_unit_price_label: 'Per share price',
    i_total_price_label: 'Total price',
    i_total_price_fmc_label: 'Total price (JPY)'
  },
  ja: {
    i_quantity_label: '数量',
    i_unit_price_label: '単価',
    i_total_price_label: '取得価額',
    i_total_price_fmc_label: '取得価額 (JPY)'
  }
})

const currencyOps = useCurrencyOps()

const { validation } = useValidation(() => ({
  quantity: props.quantity,
  unitPrice: props.unitPrice,
  totalPrice: props.totalPrice,
  totalPriceFmc: props.totalPriceFmc
}), {
  quantity: {
    required: requiredIf(() => props.strict === 'yes'),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  unitPrice: {
    required: requiredIf(() => props.strict === 'yes'),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  totalPrice: {
    required: requiredIf(() => props.strict === 'yes'),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  totalPriceFmc: {
    required: requiredIf(() => props.strict === 'yes'),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  }
})
</script>

<template>
  <div class="DealFormUpdateReportSecuritiesItem">
    <div class="title">{{ security.name }}</div>
    <div class="field">
      <div class="label">{{ t.i_quantity_label }}</div>
      <div class="input">
        <SInputNumber
          placeholder="1,000"
          :disabled="editable === 'no'"
          :model-value="quantity"
          :validation="validation.quantity"
          hide-error
          @input="(v) => $emit('update:quantity', v)"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">{{ t.i_unit_price_label }}</div>
      <div class="input">
        <DInputMoney
          placeholder="1,000"
          :disabled="editable === 'no'"
          :currency="currency"
          :model-value="unitPrice"
          :validation="validation.unitPrice"
          hide-error
          @update:model-value="(v) => $emit('update:unit-price', v)"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">{{ t.i_total_price_label }}</div>
      <div class="input">
        <DInputMoney
          placeholder="1,000,000"
          :disabled="editable === 'no'"
          :currency="currency"
          :model-value="totalPrice"
          :validation="validation.totalPrice"
          hide-error
          @update:model-value="(v) => $emit('update:total-price', v)"
        />
      </div>
    </div>
    <div class="field">
      <div class="label">{{ t.i_total_price_fmc_label }}</div>
      <div class="input">
        <DInputMoney
          placeholder="1,000,000"
          :disabled="editable === 'no'"
          :currency="currencyOps.createJpy()"
          :model-value="totalPriceFmc"
          :validation="validation.totalPriceFmc"
          hide-error
          @update:model-value="(v) => $emit('update:total-price-fmc', v)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.title {
  padding: 0 16px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--c-soft);
}

.field {
  display: grid;
  grid-template-columns: 160px 1fr;
  border-top: 1px solid var(--c-divider-2);
}

.label {
  padding: 0 16px;
  line-height: 48px;
  font-size: 14px;
  color: var(--c-text-2);
  background-color: var(--c-soft);
}

.input {
  border-left: 1px solid var(--c-divider-2);
  padding: 4px;
}
</style>
