import { Currency } from './Currency'
import { type Day, Model } from './Model'
import { User } from './User'

export class CompanyFinancialResult extends Model {
  id?: number
  companyId?: number
  period?: string
  currencyId?: number | null
  currency?: Currency | null
  netSales?: string
  ordinaryIncome?: string
  netIncome?: string
  totalAssets?: string
  capital?: string
  netAssets?: string
  createdBy: User | null
  updatedBy: User | null
  createdAt: Day | null
  updatedAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.companyId = data.companyId
    this.period = data.period
    this.currencyId = data.currencyId
    this.currency = this.hasOne(Currency, data.currency)
    this.netSales = data.netSales
    this.ordinaryIncome = data.ordinaryIncome
    this.netIncome = data.netIncome
    this.totalAssets = data.totalAssets
    this.capital = data.capital
    this.netAssets = data.netAssets
    this.createdBy = this.hasOne(User, data.createdBy)
    this.updatedBy = this.hasOne(User, data.updatedBy)
    this.createdAt = this.day(data.createdAt)
    this.updatedAt = this.day(data.updatedAt)
  }
}
