<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useViewExecute, useViewItem } from '@/composables/view/ViewData'
import ViewHeader from '@/components/view/ViewHeader.vue'
import ViewResultTable from '@/components/view/ViewResultTable.vue'

const route = useRoute()
const viewId = Number(route.params.id)
const title = computed(() => view.value ? `${view.value.name} - View` : 'Loading...')

const { data: view, execute: executeView } = useViewItem(viewId)
const { data: viewResult, loading: loadingViewResult, execute: executeViewResult } = useViewExecute(viewId)

executeViewResult()
</script>

<template>
  <DPage class="ViewIdIndex" :title="title">
    <div class="catalog">
      <ViewHeader
        :view="view"
        @exported="executeView"
        @updated="executeView"
        @run="executeViewResult"
      />
      <ViewResultTable
        :view="view"
        :view-result="viewResult"
        :loading="loadingViewResult"
      />
    </div>
  </DPage>
</template>
