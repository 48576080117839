<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: { close: 'Close' },
  ja: { close: '閉じる' }
})
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Request submitted</h2>
          <p>The personel responsible for adjusting the schedule will contact you soon on Slack for further discussion.</p>
          <p>The agenda for the DD Kickoff is as follows. Please be prepared before the Kickoff takes place.</p>
          <ol>
            <li>Brief business overview</li>
            <li>Purpose/background and timetable for this funding</li>
            <li>Status of issues raised in the previous DD</li>
            <li>Key points in this DD (things that are of concern when monitoring, matters that should be checked additionally)</li>
          </ol>
          <p>The same agenda is also sent by Email. Please check your inbox as well.</p>
        </STrans>
        <STrans lang="ja">
          <h2>エントリーが完了しました</h2>
          <p>追って担当者よりSlackで連絡がありますので、これ以降のやりとりはSlack上で行ってください。</p>
          <p>DD Kickoffのアジェンダは以下の通りです。Kickoff開催までに準備をお願いします。</p>
          <ol>
            <li>簡単にビジネス概要を説明</li>
            <li>今回の資金調達の目的・背景、スケジュール</li>
            <li>前回DDで挙がった論点の状況</li>
            <li>今回のDDにおけるポイント（担当者として、モニタリングしていて気になる事、追加で確認したほうがいい事項）</li>
          </ol>
          <p>同内容のアジェンダをEmailでも連絡しています。合わせて確認してください。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.close" @click="$emit('close')" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
