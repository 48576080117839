import {
  type BasicPaginationInput,
  type CompanyBusinessReportCondition,
  CompanyBusinessReportDocument,
  type CompanyBusinessReportFinancialResultInput,
  CompanyBusinessReportForLocalNavDocument,
  type CompanyBusinessReportOrder,
  CompanyBusinessReportOrderField,
  CompanyBusinessReportPageDocument,
  CompleteCompanyBusinessReportDocument,
  IncompleteCompanyBusinessReportDocument,
  OrderDirection,
  PreviousCompanyBusinessReportDocument,
  RequestChangesOnCompanyBusinessReportDocument,
  RequestReviewOnCompanyBusinessReportDocument,
  StartExportingAllCompanyBusinessReportDocument,
  StartExportingCompanyBusinessReportDocument,
  StartReviewOnCompanyBusinessReportDocument,
  UpdateCompanyBusinessReportDocument,
  type UpdateCompanyBusinessReportInput,
  WithdrawReviewOnCompanyBusinessReportDocument
} from '@/graphql'
import { Request } from './Request'

export {
  type BasicPaginationInput,
  type UpdateCompanyBusinessReportInput,
  type CompanyBusinessReportFinancialResultInput,
  type CompanyBusinessReportCondition,
  type CompanyBusinessReportOrder,
  CompanyBusinessReportOrderField,
  OrderDirection
}

export class CompanyBusinessReportRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: CompanyBusinessReportCondition,
    orderBy: CompanyBusinessReportOrder
  ) {
    return this.request({
      query: CompanyBusinessReportPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: CompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  fetchForLocalNav(id: number) {
    return this.request({
      query: CompanyBusinessReportForLocalNavDocument,
      variables: {
        id
      }
    })
  }

  fetchPrevious(id: number) {
    return this.request({
      query: PreviousCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  update(id: number, input: UpdateCompanyBusinessReportInput) {
    return this.request({
      query: UpdateCompanyBusinessReportDocument,
      variables: {
        id,
        input
      }
    })
  }

  requestReview(id: number) {
    return this.request({
      query: RequestReviewOnCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  startReview(id: number) {
    return this.request({
      query: StartReviewOnCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  withdrawReview(id: number) {
    return this.request({
      query: WithdrawReviewOnCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  requestChanges(id: number) {
    return this.request({
      query: RequestChangesOnCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  complete(id: number) {
    return this.request({
      query: CompleteCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  incomplete(id: number) {
    return this.request({
      query: IncompleteCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }

  exportAll(fundReportId: number) {
    return this.request({
      query: StartExportingAllCompanyBusinessReportDocument,
      variables: {
        fundReportId
      }
    })
  }

  export(id: number) {
    return this.request({
      query: StartExportingCompanyBusinessReportDocument,
      variables: {
        id
      }
    })
  }
}
