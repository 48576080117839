import { type Get, type Mutation, useGet, useMutation } from 'sefirot/composables/Api'
import { type OpportunityFile } from '@/graph/Opportunity'

export type DownloadOpportunityFile = Get<void, [id: number]>
export type UploadOpportunityFile = Mutation<OpportunityFile, [opportunityId: number, file: File]>
export type DeleteOpportunityFile = Mutation<void, [id: number]>

export function useDownloadOpportunityFile(): DownloadOpportunityFile {
  return useGet(async (http, id) => {
    await http.download(`/api/opportunity-files/${id}`)
  })
}

export function useUploadOpportunityFile(): UploadOpportunityFile {
  return useMutation(async (http, opportunityId, file) => {
    return await http.upload<OpportunityFile>('/api/opportunity-files', {
      opportunityId,
      file
    })
  })
}

export function useDeleteOpportunityFile(): DeleteOpportunityFile {
  return useMutation(async (http, id) => {
    await http.delete<void>(`/api/opportunity-files/${id}`)
  })
}
