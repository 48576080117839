<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { type Company } from '@/models/Company'
import { useBroadcast } from '@/composables/Channel'
import CompanyCardNameHistory from './CompanyCardNameHistory.vue'

defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'updated'): void
}>()

const { state: modal, on, off } = usePower(false)

const { broadcast } = useBroadcast('company-name-updated')

function onUpdate() {
  broadcast()
  emit('updated')
}
</script>

<template>
  <DCard
    class="CompanyDescName"
    title="Company name"
    :actions="[{ icon: 'list', onClick: on }]"
  >
    <DCardBody>
      <DGrid>
        <DGridItem span="2">
          <DGridLabel text="Display name" />
          <DGridText :text="company.name" />
        </DGridItem>
        <DGridItem span="2" />
        <DGridItem span="2">
          <DGridLabel text="Official name" />
          <DGridText :text="company.officialName" />
        </DGridItem>
        <DGridItem span="2">
          <DGridLabel text="English name" />
          <DGridText :text="company.englishName" />
        </DGridItem>
      </DGrid>
    </DCardBody>

    <SModal :open="modal" @close="off">
      <CompanyCardNameHistory
        :company="company"
        @updated="onUpdate"
        @close="off"
      />
    </SModal>
  </DCard>
</template>
