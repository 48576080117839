import { type GraphQLRequestContext, type GraphQLResponse } from 'graphql-request/dist/types'

export interface RequestErrorOptions {
  request: GraphQLRequestContext
  response: GraphQLResponse
  status?: number
}

export class RequestError extends Error {
  request: GraphQLRequestContext
  response: GraphQLResponse
  status: number

  constructor(message: string, options: RequestErrorOptions) {
    super(message)

    this.name = 'RequestError'
    this.request = options.request
    this.response = options.response
    this.status = options.status || options.response.status
  }
}
