<script setup lang="ts">
import { computed, useSlots } from 'vue'

const slots = useSlots()

const items = computed(() => slots.default?.() ?? [])
</script>

<template>
  <SCardBlock bg="elv-2" class="s-px-16 s-pb-16">
    <template v-for="item, index in items" :key="index">
      <div class="line" />
      <component :is="item" />
    </template>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.line {
  margin-left: 24px;
  width: 1px;
  height: 16px;
  background-color: var(--c-divider);
}
</style>
