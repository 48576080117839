<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type CompanyCautionNote } from '@/graph/Company'
import { useCreateCompanyCautionNote } from '@/composables/repos/CompanyCautionNoteRepo'
import CompanyCautionNoteInputDescription from './input/CompanyCautionNoteInputDescription.vue'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  cancel: []
  created: [companyCautionNote: CompanyCautionNote]
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    create: 'Create internal memo'
  },
  ja: {
    cancel: 'キャンセル',
    create: '社内メモを作成'
  }
})

const mutation = useCreateCompanyCautionNote()

const { data } = useData({
  body: null as string | null
})

const { validateAndNotify } = useValidation()

async function create() {
  if (await validateAndNotify()) {
    const cautionNote = await mutation.execute({
      companyId: props.companyId,
      body: data.value.body!
    })
    emit('created', cautionNote)
  }
}
</script>

<template>
  <SCard size="large" class="CompanyFormAddCautionNote">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Create a new internal memo</h2>
            <p>You can record internal memo about this company. These memo will be prominently displayed at the top of this page.</p>
          </STrans>
          <STrans lang="ja">
            <h2>社内メモを作成する</h2>
            <p>この会社に関する社内向けのメモを記録することができます。このメモは当ページの最上部に目立つ形で表示されます。</p>
          </STrans>
        </SContent>
        <CompanyCautionNoteInputDescription
          v-model="data.body"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-p-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            :label="t.create"
            mode="info"
            :loading="mutation.loading.value"
            @click="create"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
