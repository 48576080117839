<template>
  <div class="DFormGrid">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  width: 100%;
}
</style>
