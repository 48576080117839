<script setup lang="ts">
import SLocalNav from 'sefirot/components/SLocalNav.vue'
import { AuthorizationError } from 'sefirot/errors/AuthorizationError'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { provideFundShowPage } from '@/composables/pages/FundPage'
import { useCanViewFundStats } from '@/composables/policies/FundPolicy'
import { useGetFundWithPerformanceAdminsItemCache } from '@/composables/repos/FundRepo'

const id = Number(useRoute().params.id as string)

const { execute: getFundWithPerformanceAdminsItem } = useGetFundWithPerformanceAdminsItemCache()

const fund = ref(await getFundWithPerformanceAdminsItem(id))

const canViewFund = useCanViewFundStats(fund.value)

if (!canViewFund.value.ok) {
  throw new AuthorizationError()
}

const navTitle = [
  { text: 'Funds', link: '/funds' },
  { text: fund.value.nameAbbreviated }
]

const navMenu = [[
  { text: 'Dashboard', link: `/funds/${fund.value.id}/dashboard` }
]]

provideFundShowPage(fund)
</script>

<template>
  <div v-if="fund" class="FundsId">
    <SLocalNav :title="navTitle" :menu="navMenu" />
    <RouterView />
  </div>
</template>

<style scoped lang="postcss">
.FundsId {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  background-color: var(--c-gutter);
}
</style>
