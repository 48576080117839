import { type MaybeRefOrGetter, toValue } from 'vue'
import { type UserFrag } from '@/graphql'
import {
  type PolicyResponse,
  allow,
  deny,
  usePolicyResponse
} from '../Policy'
import { useUserOps } from '../ops/UserOps'

export function useCanViewAny(): PolicyResponse {
  return usePolicyResponse((user) => {
    return user.hasRole('God') ? allow() : deny()
  })
}

export function useCanViewUserSettings(): PolicyResponse {
  return usePolicyResponse((user) => {
    return user.hasRole('God') ? allow() : deny()
  })
}

export function useCanUpdateUserRole(): PolicyResponse {
  return usePolicyResponse((me) => {
    if (me.hasRoleGod()) {
      return allow()
    }

    return deny()
  })
}

export function useCanUpdateUserFundAssignments(): PolicyResponse {
  return usePolicyResponse((me) => {
    if (me.hasRoleGod()) {
      return allow()
    }

    return deny()
  })
}

export function useCanRetireUser(
  user: MaybeRefOrGetter<UserFrag>
): PolicyResponse<'ok' | 'unauthorized' | 'inactive' | 'me'> {
  const userOps = useUserOps()

  return usePolicyResponse((me) => {
    if (!me.hasRole('God')) {
      return deny('unauthorized')
    }

    const u = toValue(user)

    if (!userOps.isActive(u)) {
      return deny('inactive')
    }

    if (u.id === me.id) {
      return deny('me')
    }

    return allow('ok')
  })
}
