<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCanAddDeal } from '@/composables/policies/OpportunityPolicy'
import { useOpportunityForDetails } from '@/composables/repos/OpportunityRepo'
import DealFormCreate from '@/components/deal/DealFormCreate.vue'

const route = useRoute()

const companyOps = useCompanyOps()
const oppoOps = useOpportunityOps()

const { data: oppoForDetail } = useOpportunityForDetails(Number(route.params.id))

const canAddDeal = useCanAddDeal(oppoForDetail)

const title = computedWhen(oppoForDetail, (o) => {
  return `${companyOps.name(o.company)} - ${oppoOps.title(o)} - Create Deal`
})
</script>

<template>
  <DPage class="OpportunitiesIdDealsCreate" :title="title" :authorize="canAddDeal.ok">
    <div class="container">
      <DealFormCreate :opportunity="oppoForDetail!" />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.OpportunitiesIdDealsCreate {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
