<script setup lang="ts">
import { type PolicyResponse } from 'sefirot/composables/Policy'
import { AuthorizationError } from 'sefirot/errors'
import { computed, watchEffect } from 'vue'
import { useTitle } from '@/composables/Title'

export interface Props {
  title?: string
  redirect?: string
  authorize?: PolicyResponse | boolean | null
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  authorize: true
})

const isAuthorized = computed(() => {
  if (props.authorize === true) {
    return true
  }

  if (props.authorize === false) {
    return false
  }

  return props.authorize?.ok ?? null
})

useTitle(computed(() => {
  if (isAuthorized.value === true) {
    return props.title ?? '???'
  }

  if (isAuthorized.value === false) {
    return 'Error'
  }

  return 'Loading...'
}))

watchEffect(() => {
  if (isAuthorized.value === false) {
    throw new AuthorizationError()
  }
})
</script>

<template>
  <div class="DPage">
    <template v-if="authorize">
      <slot />
    </template>
  </div>
</template>

<style scoped lang="postcss">
.DPage {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: var(--c-bg-elv-2);
}
</style>
