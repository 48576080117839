import { CurrenciesDocument } from '@/graphql'
import { Request } from './Request'

export class CurrencyRequest extends Request {
  fetchAll() {
    return this.request({
      query: CurrenciesDocument
    })
  }
}
