<template>
  <div class="DCardFooterActions">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DCardFooterActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
</style>
