<script setup lang="ts">
import { type DealSecurityFrag } from '@/graphql'
import { useDeleteDealSecurity } from '@/composables/repos/DealSecurityRepo'

const props = defineProps<{
  security: DealSecurityFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'deleted'): void
}>()

const { loading, execute } = useDeleteDealSecurity()

async function destroy() {
  await execute(props.security.id)
  emit('deleted')
}
</script>

<template>
  <DForm mode="danger">
    <DFormTitle>Delete security</DFormTitle>

    <DFormMedium>
      <p>You’re about delete the security. This action can’t be undone. Are you absolutely sure you want to delete this action note?</p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="danger" label="Delete" :loading="loading" @click="destroy" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
