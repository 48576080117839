<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputSelect, { type Value as SelectValue } from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SInputYMD, { type Value as Ymd } from 'sefirot/components/SInputYMD.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxLength, maxValue, required, requiredIf, ymd } from 'sefirot/validation/rules'
import { computed } from 'vue'
import {
  type CompanyBusinessReportFinanceTransitionRecordFrag,
  type CompanyBusinessReportFinanceTransitionRecordRowFrag,
  type CompanyBusinessReportFinanceTransitionRecordTemplate,
  type CompanyBusinessReportFinancialFiguresAnnotation,
  type CompanyBusinessReportFinancialFiguresUnit,
  type CompanyBusinessReportFrag,
  type CurrencyFrag
} from '@/graphql'
import { type Day } from '@/models/Model'
import { useCanSaveCompanyBusinessReport } from '@/composables/policies/CompanyBusinessReportPolicy'
import DInputMoney from '@/components/DInputMoney.vue'
import DInputShares, { type Share } from '@/components/DInputShares.vue'
import { type Data } from '@/components/company-business-report/CompanyBusinessReportFormUpdate.vue'
import CompanyBusinessReportInputFinancialResults, { type FinancialResultData } from './CompanyBusinessReportInputFinancialResults.vue'
import CompanyBusinessReportInputFinanceTransitionRecords from './input/CompanyBusinessReportInputFinanceTransitionRecords.vue'

const props = defineProps<{
  formData: Data
  strict: boolean
  annotations: CompanyBusinessReportFinancialFiguresAnnotation[]
  currencies: CurrencyFrag[]
  units: CompanyBusinessReportFinancialFiguresUnit[]
  financialFiguresNotes: string | null
  financialFiguresNotesUpdatedAt: Day | null
  financeTransitionRecordPlaceholders: CompanyBusinessReportFinanceTransitionRecordRowFrag[][]
  companyBusinessReport: CompanyBusinessReportFrag
  isOldFinancialFigures: boolean
}>()

const emit = defineEmits<{
  'update:official-name': [value: string | null]
  'update:past-names': [value: string | null]
  'update:representative-name': [value: string | null]
  'update:founded-year-month': [value: Ymd]
  'update:address': [value: string | null]
  'update:description': [value: string | null]
  'update:issued-shares': [value: Share[]]
  'update:issued-shares-notes': [value: string | null]
  'update:total-potential-dilutive-shares': [value: number | null]
  'update:potential-dilutive-shares-notes': [value: string | null]
  'update:capital-amount': [value: string | null]
  'update:capital-currency': [value: CurrencyFrag]
  'update:capital-notes': [value: string | null]
  'update:other-notes': [value: string | null]
  'update:financial-results': [value: FinancialResultData[]]
  'update:financial-figures-annotation-id': [value: Exclude<SelectValue, string | boolean>]
  'update:financial-result-notes': [value: string | null]
  'update:finance-transition': [value: string | null]
  'update:finance-transition-records': [value: CompanyBusinessReportFinanceTransitionRecordFrag[]]
  'add-finance-transition-record': [templateName: CompanyBusinessReportFinanceTransitionRecordTemplate]
  'delete-finance-transition-record': [recordIndex: number]
  'add-finance-transition-record-row': [recordIndex: number]
  'update-finance-transition-record-row': [recordIndex: number, rowIndex: number, row: CompanyBusinessReportFinanceTransitionRecordRowFrag]
  'delete-finance-transition-record-row': [recordIndex: number, rowIndex: number]
  'update:overview-and-perspective': [value: string | null]
  'refresh-financial-figures': []
}>()

const { t } = useTrans({
  en: {
    company_information_title: 'Company information',
    company_information_lead: 'Enter the basic company information. Note that updating following data will not update the actual company data.',
    annual_financial_figures_note_title: 'Note',
    annual_financial_figures_note_updated_at: (date: string) => `Updated at ${date}`,
    annual_financial_figures_warning: 'This report presents scaled values instead of raw financial figures. It maintains its original format, as the decision to include raw values in recent reports postdates the creation of this report.',
    finance_transition_title: 'Finance transition',
    finance_transition_lead: 'Enter the trends in finance. <strong>List all financing since the fund\'s initial investment.</strong>',
    overview_and_perspective_title: 'Overview and perspective',
    overview_and_perspective_lead: 'Enter a summary of the business overview and future prospects (min 300 characters). <strong>Refrain from indicating the name of the collaborative partner(s) and/or business partner(s) unless it has been permitted by the portfolio company or the name has been publicly disclosed, as disclosing the name could impact the partner company/companies. Example) The name of a partner company may be indicated if the company\'s name is disclosed as a partner on the web, etc. Otherwise, it is recommended to hide the specific name of the partner company by only referring to the sector of the company.</strong>',
    i_company_name_label: 'Company name',
    i_company_name_placeholder: 'Company name',
    i_previous_company_name_label: 'Previous company name',
    i_previous_company_name_placeholder: 'Old company name A, Old company name B',
    i_representative_name_label: 'Representative name',
    i_representative_name_placeholder: 'CEO　John Doe',
    i_founded_at_label: 'Founded at',
    i_location_label: 'Address',
    i_location_placeholder: 'Shibuya, Tokyo, Japan',
    i_business_description_label: 'Business description',
    i_business_description_placeholder: 'Enter company description.',
    i_issued_shares_label: 'Issued shares',
    i_notes_on_issued_shares_label: 'Notes on issued shares',
    i_notes_on_issued_shares_placeholder: 'Add any notes about shares.',
    i_potential_dilutive_shares_label: 'Potential dilutive shares',
    i_potential_dilutive_shares_placeholder: '10,000',
    i_notes_on_potential_dilutive_shares_label: 'Notes on potential dilutive shares',
    i_notes_on_potential_dilutive_shares_placeholder: 'Add any notes about options/warrants.',
    i_capital_label: 'Capital',
    i_capital_info: 'If no capital exists, enter 0 in this field.',
    i_capital_placeholder: '100,000,000',
    i_notes_on_capital_label: 'Notes on capital',
    i_notes_on_capital_placeholder: 'Add any notes about capital.',
    i_others_label: 'Others',
    i_others_placeholder: 'Enter any other special information about the company.',
    i_annual_financial_figures_annotation_label: 'Annotation',
    i_annual_financial_figures_annotation_placeholder: '--None--',
    i_notes_on_financial_results_label: 'Notes on financial results',
    i_notes_on_financial_results_placeholder: 'Add extra notes if there\'s anything.',
    i_finance_transition_placeholder: 'List all financing since the fund\'s initial investment.',
    i_overview_and_perspective_placeholder: 'Enter a summary of the business overview and future prospects.',
    save_report: 'Save report',
    request_review: 'Request review',
    withdraw_review: 'Withdraw review',
    request_changes: 'Request changes',
    complete: 'Complete',
    incomplete: 'Incomplete'
  },
  ja: {
    company_information_title: '会社情報',
    company_information_lead: '会社の基本情報を入力してください。この報告書内で会社情報を更新しても、オリジナルの会社情報は更新されない点に注意してください。',
    annual_financial_figures_note_title: 'メモ',
    annual_financial_figures_note_updated_at: (date: string) => `更新日時: ${date}`,
    annual_financial_figures_warning: 'この報告書では、丸められた財務数値が表示されています。最新の報告書では、財務数値は丸めず、1円単位まで入力するルールとなっていますが、この報告書はその決定が行われる前に作成されているため、元の形式が維持されています。',
    finance_transition_title: 'ファイナンスの推移',
    finance_transition_lead: 'ファイナンスの推移を記入してください。<strong>当該ファンドの初期投資からすべてのファイナンスを列挙してください。</strong>',
    overview_and_perspective_title: '事業の概況及び今後の事業見通し',
    overview_and_perspective_lead: '事業の概況及び今後の事業見通しを記入してください（300字程度以上）。<strong>なお、協業先/取引先の個社名の記載は、協業先/取引先にも影響を及ぼす可能性があるため、投資先から同意を得たもの/対外的に公表されている場合のみご記載ください。例）相手方の個社名がネット等で公表されている場合個社名記載可。そうでない場合は●●系企業等個社名は伏せての記載を推奨。</strong>',
    i_company_name_label: '会社名',
    i_company_name_placeholder: '会社名',
    i_previous_company_name_label: '旧社名',
    i_previous_company_name_placeholder: '旧社名A, 旧社名B',
    i_representative_name_label: '代表者名',
    i_representative_name_placeholder: '代表取締役　山田太郎',
    i_founded_at_label: '設立年月日',
    i_location_label: '所在地',
    i_location_placeholder: '日本東京都渋谷区',
    i_business_description_label: '事業内容',
    i_business_description_placeholder: '事業内容を記入してください。',
    i_issued_shares_label: '発行済株式数',
    i_notes_on_issued_shares_label: '発行済株式数 補足事項',
    i_notes_on_issued_shares_placeholder: '発行済株式に関する補足を記入してください。',
    i_potential_dilutive_shares_label: '潜在株式数',
    i_potential_dilutive_shares_placeholder: '10,000',
    i_notes_on_potential_dilutive_shares_label: '潜在株式数 補足事項',
    i_notes_on_potential_dilutive_shares_placeholder: '潜在株式に関する補足を記入してください。',
    i_capital_label: '資本金',
    i_capital_info: '企業の中には資本金が存在しないケースがあります。その場合には「0」を入力してください。',
    i_capital_placeholder: '100,000,000',
    i_notes_on_capital_label: '資本金 補足事項',
    i_notes_on_capital_placeholder: '資本金に関する補足を記入してください。',
    i_others_label: 'その他',
    i_others_placeholder: 'その他に関する補足を記入してください。',
    i_annual_financial_figures_annotation_label: '注釈',
    i_annual_financial_figures_annotation_placeholder: '--なし--',
    i_notes_on_financial_results_label: '財務数値に関するその他報告事項',
    i_notes_on_financial_results_placeholder: '財務数値資本金に関する補足を記入してください。',
    i_finance_transition_placeholder: '当該ファンドの初期投資からすべてのファイナンスを列挙してください。',
    i_overview_and_perspective_placeholder: '事業の概況及び今後の事業見通しを記入してください。',
    save_report: '保存する',
    request_review: 'レビュー依頼',
    withdraw_review: 'レビュー取り下げ',
    request_changes: '変更依頼',
    complete: '完了する',
    incomplete: '完了取り下げ'
  }
})

const canSaveCompanyBusinessReport = useCanSaveCompanyBusinessReport(computed(() => props.companyBusinessReport), () => props.companyBusinessReport.report.fund)

const { validation } = useValidation(computed(() => props.formData), {
  officialName: { maxLength: maxLength(255) },
  foundedYearMonth: { ymd: ymd(['y', 'm']) },
  description: { maxLength: maxLength(4000) },
  issuedSharesNotes: { maxLength: maxLength(4000) },
  potentialDilutiveSharesNotes: { maxLength: maxLength(4000) },
  capitalAmount: { required: requiredIf(() => props.strict), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  capitalNotes: { maxLength: maxLength(4000) },
  otherNotes: { maxLength: maxLength(4000) },
  financeTransition: { maxLength: maxLength(4000) },
  overviewAndPerspective: { maxLength: maxLength(4000) }
})

const annotationOptions = computed(() => {
  return props.annotations.map(({ id, name, enabled }) => {
    return { label: name!, value: id!, disabled: !enabled }
  }) ?? []
})

const issuedSharesRules = {
  name: { required: required() },
  count: { required: required(), decimal: decimal() }
}
</script>

<template>
  <SGrid gap="1" class="CompanyBusinessReportFormUpdateMain">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SDocSection class="company-info">
          <SContent>
            <h2>{{ t.company_information_title }}</h2>
            <p>{{ t.company_information_lead }}</p>
          </SContent>
          <SGrid gap="24" cols="6">
            <SGridItem span="3">
              <SInputText
                :label="t.i_company_name_label"
                :placeholder="t.i_company_name_placeholder"
                :model-value="props.formData.officialName"
                @update:model-value="emit('update:official-name', $event)"
                :validation="validation.officialName"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputText
                :label="t.i_previous_company_name_label"
                :placeholder="t.i_previous_company_name_placeholder"
                :model-value="props.formData.pastNames"
                @update:model-value="emit('update:past-names', $event)"
                :validation="validation.pastNames"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputText
                :label="t.i_representative_name_label"
                :placeholder="t.i_representative_name_placeholder"
                :model-value="props.formData.representativeName"
                @update:model-value="emit('update:representative-name', $event)"
                :validation="validation.representativeName"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputYMD
                size="small"
                :label="t.i_founded_at_label"
                no-date
                :model-value="props.formData.foundedYearMonth"
                @update:model-value="emit('update:founded-year-month', $event)"
                :validation="validation.foundedYearMonth"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputText
                :label="t.i_location_label"
                :placeholder="t.i_location_placeholder"
                :model-value="props.formData.address"
                @update:model-value="emit('update:address', $event)"
                :validation="validation.address"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_business_description_label"
                :placeholder="t.i_business_description_placeholder"
                :rows="8"
                :model-value="props.formData.description"
                @update:model-value="emit('update:description', $event)"
                :validation="validation.description"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="issued-shares">
          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <DInputShares
                :label="t.i_issued_shares_label"
                :model-value="props.formData.issuedShares"
                @update:model-value="emit('update:issued-shares', $event)"
                :rules="issuedSharesRules"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_notes_on_issued_shares_label"
                :placeholder="t.i_notes_on_issued_shares_placeholder"
                :rows="5"
                :model-value="props.formData.issuedSharesNotes"
                @update:model-value="emit('update:issued-shares-notes', $event)"
                :validation="validation.issuedSharesNotes"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="potential-shares">
          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <SInputNumber
                :label="t.i_potential_dilutive_shares_label"
                :placeholder="t.i_potential_dilutive_shares_placeholder"
                separator
                :model-value="props.formData.totalPotentialDilutiveShares"
                @update:model-value="emit('update:total-potential-dilutive-shares', $event)"
                :validation="validation.totalPotentialDilutiveShares"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_notes_on_potential_dilutive_shares_label"
                :placeholder="t.i_notes_on_potential_dilutive_shares_placeholder"
                :rows="5"
                :model-value="props.formData.potentialDilutiveSharesNotes"
                @update:model-value="emit('update:potential-dilutive-shares-notes', $event)"
                :validation="validation.potentialDilutiveSharesNotes"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="capital">
          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <DInputMoney
                :label="t.i_capital_label"
                :info="t.i_capital_info"
                :placeholder="t.i_capital_placeholder"
                separator
                :model-value="props.formData.capitalAmount"
                @update:model-value="emit('update:capital-amount', $event)"
                :currency="props.formData.capitalCurrency"
                @update:currency="emit('update:capital-currency', $event!)"
                :validation="validation.capitalAmount"
                :currencies="props.currencies"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_notes_on_capital_label"
                :placeholder="t.i_notes_on_capital_placeholder"
                :rows="5"
                :model-value="props.formData.capitalNotes"
                @update:model-value="emit('update:capital-notes', $event)"
                :validation="validation.capitalNotes"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="others">
          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_others_label"
                :placeholder="t.i_others_placeholder"
                :rows="5"
                :model-value="props.formData.otherNotes"
                @update:model-value="emit('update:other-notes', $event)"
                :validation="validation.otherNotes"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="financial-results">
          <SContent>
            <STrans lang="en">
              <h2>Annual financial figures</h2>
              <p>By clicking the refresh button, the latest information will be copied from the Company Financial Figures. If you need to add a new fiscal year or find any errors in the figures, correct them on the <SLink :href="`/companies/${companyBusinessReport.companyId}/financial-figures`" external>Company Financial Figures page</SLink>. After making corrections, click the update button again to reflect the latest data.</p>
            </STrans>
            <STrans lang="ja">
              <h2>年度別財務数値</h2>
              <p>更新ボタンをクリックすることで、最新の情報をCompany Financial Figuresからコピーします。新しい年度の追加が必要な場合や、数値の間違いを見つけた場合、<SLink :href="`/companies/${companyBusinessReport.companyId}/financial-figures`" external>Company Financial Figuresページ</SLink>で修正してください。修正後、再度更新ボタンをクリックすると、最新のデータが反映されます。</p>
            </STrans>
          </SContent>
          <SGrid gap="24" cols="6">
            <SGridItem v-if="props.financialFiguresNotes" span="6">
              <div class="financial-figures-notes">
                <div class="financial-figures-notes-header">
                  <div class="financial-figures-notes-title">{{ t.annual_financial_figures_note_title }}</div>
                  <div class="financial-figures-notes-updated-at">
                    {{ t.annual_financial_figures_note_updated_at(financialFiguresNotesUpdatedAt!.format('YYYY-MM-DD HH:mm')) }}
                  </div>
                </div>
                <div class="financial-figures-notes-body">
                  {{ financialFiguresNotes }}
                </div>
              </div>
            </SGridItem>
            <SGridItem v-if="isOldFinancialFigures" span="6">
              <SAlert mode="warning">
                <p>{{ t.annual_financial_figures_warning }}</p>
              </SAlert>
            </SGridItem>
            <SGridItem span="6">
              <CompanyBusinessReportInputFinancialResults
                :results="props.formData.financialResults"
                :units="props.units"
                :on-update="results => emit('update:financial-results', results)"
                :disabled="!canSaveCompanyBusinessReport.ok"
                :is-legacy="isOldFinancialFigures"
                @refresh="emit('refresh-financial-figures')"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputSelect
                :label="t.i_annual_financial_figures_annotation_label"
                :placeholder="t.i_annual_financial_figures_annotation_placeholder"
                :options="annotationOptions"
                :model-value="props.formData.financialFiguresAnnotationId"
                @update:model-value="emit('update:financial-figures-annotation-id', $event as Exclude<SelectValue, string | boolean>)"
                :validation="validation.financialFiguresAnnotationId"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_notes_on_financial_results_label"
                :placeholder="t.i_notes_on_financial_results_placeholder"
                :rows="4"
                :model-value="props.formData.financialResultNotes"
                @update:model-value="emit('update:financial-result-notes', $event)"
                :validation="validation.financialResultNotes"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="finance-transition">
          <SContent>
            <h2>{{ t.finance_transition_title }}</h2>
            <p v-html="t.finance_transition_lead" />
          </SContent>
          <SGrid v-if="formData.financeTransitionRecords === null" gap="24" cols="6">
            <SGridItem span="6">
              <SInputTextarea
                :placeholder="t.i_finance_transition_placeholder"
                :rows="15"
                :model-value="props.formData.financeTransition"
                @update:model-value="emit('update:finance-transition', $event)"
                :validation="validation.financeTransition"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
          <SGrid v-else gap="24" cols="6">
            <SGridItem span="6">
              <CompanyBusinessReportInputFinanceTransitionRecords
                :strict="strict"
                :disabled="!canSaveCompanyBusinessReport.ok"
                :model-value="formData.financeTransitionRecords"
                @update:model-value="(v) => emit('update:finance-transition-records', v)"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="overview-and-perspective">
          <SContent>
            <h2>{{ t.overview_and_perspective_title }}</h2>
            <p v-html="t.overview_and_perspective_lead" />
          </SContent>
          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <SInputTextarea
                :placeholder="t.i_overview_and_perspective_placeholder"
                :rows="20"
                :model-value="props.formData.overviewAndPerspective"
                @update:model-value="emit('update:overview-and-perspective', $event)"
                :validation="validation.overviewAndPerspective"
                :disabled="!canSaveCompanyBusinessReport.ok"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
      </SDoc>
    </SCardBlock>
  </SGrid>
</template>

<style scoped lang="postcss">
.divider {
  width: 100%;
  height: 1px;
  background-color: var(--c-divider);
}

.financial-figures-notes {
  border-left: 2px solid var(--c-divider);
  padding-left: 16px;
}

.financial-figures-notes-header {
  display: flex;
  align-items: baseline;
  gap: 12px;
}

.financial-figures-notes-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.financial-figures-notes-updated-at {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: var(--c-text-2);
}

.financial-figures-notes-body {
  font-size: 14px;
  white-space: pre-wrap;
}

.financial-results-mode {
  margin-bottom: 8px;
}
</style>
