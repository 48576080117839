<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: { close: 'Close' },
  ja: { close: '閉じる' }
})
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Docs submitted</h2>
          <p>The personel responsible for adjusting the schedule will contact you soon on Slack for further discussion.</p>
        </STrans>
        <STrans lang="ja">
          <h2>資料の送付が完了しました</h2>
          <p>追って担当者よりSlackで連絡がありますので、これ以降のやりとりはSlack上で行ってください。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.close" @click="$emit('close')" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
