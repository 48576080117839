<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { useDuration } from '@/composables/Duration'
import { useItem } from '@/composables/repos/StatInvestedDealsRepo'
import DashboardOverviewCard from './DashboardOverviewCard.vue'

const { from, to, hasNext, label, prev, next } = useDuration('year')

const { data: stats, loading } = useItem(from, to)

const table = useTable({
  orders: [
    'name',
    'date',
    'amount',
    'sector',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Deal',
      cell: (_, record) => ({
        type: 'text',
        link: record.path,
        color: 'info'
      })
    },
    date: {
      label: 'Invested date'
    },
    amount: {
      label: 'Investment amount'
    },
    sector: {
      label: 'Sector'
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  },

  records: computed(() => {
    return stats.value?.map((s) => ({
      name: s.name,
      path: s.path(),
      date: s.investedDate.format('YYYY/MM/DD'),
      amount: s.investmentAmount.format(),
      sector: s.sector
    }))
  }),

  borderless: true
})
</script>

<template>
  <DashboardOverviewCard
    title="Annual invested deals"
    :has-next="hasNext"
    :current="label"
    @prev="prev"
    @next="next"
    :loading="loading"
  >
    <STable class="list" :options="table" />
  </DashboardOverviewCard>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-head-top: 0px;
  --table-padding-left: 16px;
  --table-border-top: 0;
  --table-border-bottom: 0;

  max-height: 240px;
  overflow: auto;
}

.list :deep(.col-name)   { --table-col-width: 600px; }
.list :deep(.col-date)   { --table-col-width: 132px; }
.list :deep(.col-amount) { --table-col-width: 200px; }
.list :deep(.col-sector) { --table-col-width: 172px; }
.list :deep(.col-spacer) { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
