import dayjs, { type Dayjs } from 'dayjs'
import { type MaybeRef, computed, ref, watch } from 'vue'

export type DurationType = 'month' | 'quarter' | 'half' | 'year'

export function useDuration(initialType: MaybeRef<DurationType>) {
  const type = ref(initialType)
  const from = ref(getFirstDateOfType(dayjs(), type.value))
  const to = computed(() => from.value.add(getDiff() - 1, 'month').endOf('month'))
  const hasNext = computed(() => from.value.isBefore(getFirstDateOfType(dayjs(), type.value), 'date'))

  watch(type, () => from.value = getFirstDateOfType(from.value, type.value))

  const currentMonth = computed(() => from.value.month())
  const currentQuarter = computed(() => currentMonth.value < 3 ? 1 : currentMonth.value < 6 ? 2 : currentMonth.value < 9 ? 3 : 4)
  const currentHalf = computed(() => currentMonth.value < 6 ? 1 : 2)
  const currentYear = computed(() => from.value.year())
  const label = computed(() => {
    if (type.value === 'month') {
      return `${currentYear.value}M${currentMonth.value}`
    }

    if (type.value === 'quarter') {
      return `${currentYear.value}Q${currentQuarter.value}`
    }

    if (type.value === 'half') {
      return `${currentYear.value}H${currentHalf.value}`
    }

    return `${currentYear.value}`
  })

  function getFirstDateOfType(date: Dayjs, type: DurationType): Dayjs {
    if (type === 'month') {
      return date.startOf('month')
    }

    if (type === 'quarter') {
      const month = date.month()
      const index = month < 3 ? 0 : month < 6 ? 3 : month < 9 ? 6 : 9

      return date.month(index).startOf('month')
    }

    if (type === 'half') {
      const month = date.month()
      const index = month < 6 ? 0 : 6

      return date.month(index).startOf('month')
    }

    return date.startOf('year')
  }

  function getDiff() {
    return type.value === 'month' ? 1 : type.value === 'quarter' ? 3 : type.value === 'half' ? 6 : 12
  }

  function prev() {
    from.value = from.value.subtract(getDiff(), 'month')
  }

  function next() {
    if (hasNext.value) {
      from.value = from.value.add(getDiff(), 'month')
    }
  }

  function set(fromAsString: string, toAsString: string) {
    const _from = dayjs(fromAsString).startOf('month')
    const _to = dayjs(toAsString).startOf('month')
    const diff = _to.diff(_from, 'month')

    if (diff) {
      type.value = diff === 1 ? 'month' : diff <= 3 ? 'quarter' : diff <= 6 ? 'half' : 'year'
    }

    from.value = getFirstDateOfType(_from, type.value)
  }

  return {
    type,
    from,
    to,
    hasNext,
    label,
    prev,
    next,
    set
  }
}
