<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { AuthorizationError, PageNotFoundError } from 'sefirot/errors'
import { computed } from 'vue'

const props = defineProps<{
  error: Error | unknown
}>()

const { t } = useTrans({
  en: {
    unauthorized: {
      title: 'Unauthorized Error',
      desc: 'You are not authorized to view this page. If you believe you should have access, contact support for assistance.',
      action: {
        label: 'Return to home',
        onClick: () => { window.location.href = '/' }
      }
    },
    notfound: {
      title: 'Page Not Found',
      desc: 'The page does not exist.',
      action: {
        label: 'Return to home',
        onClick: () => { window.location.href = '/' }
      }
    },
    unexpected: {
      title: 'Unexpected Error',
      desc: 'An unexpected error has occurred, which is not supposed to happen. Try restoring the page or contact support if the issue persists.',
      action: {
        label: 'Restore page',
        onClick: () => { window.location.reload() }
      }
    }
  },
  ja: {
    unauthorized: {
      title: '認証エラー',
      desc: 'このページを閲覧する権限がありません。アクセス権があるはずだと思われる場合は、サポートに連絡してください。',
      action: {
        label: 'ホームに戻る',
        onClick: () => { window.location.href = '/' }
      }
    },
    notfound: {
      title: 'ページが見つかりません',
      desc: 'ページが存在しません。',
      action: {
        label: 'ホームに戻る',
        onClick: () => { window.location.href = '/' }
      }
    },
    unexpected: {
      title: '予期しないエラー',
      desc: '予期しないエラーが発生しました。ページの復元を試してください。問題が解決しない場合は、サポートに連絡してください。',
      action: {
        label: 'ページを復元',
        onClick: () => { window.location.reload() }
      }
    }
  }
})

const data = computed(() => {
  switch (true) {
    case props.error instanceof AuthorizationError:
      return t.unauthorized
    case props.error instanceof PageNotFoundError:
      return t.notfound
    default:
      return t.unexpected
  }
})
</script>

<template>
  <div class="ErrorPage">
    <div class="container">
      <SCard>
        <SCardBlock class="box">
          <div class="body">
            <div class="title">{{ data.title }}</div>
            <div class="desc">{{ data.desc }}</div>
          </div>
          <div class="action">
            <SButton
              size="small"
              mode="info"
              :label="data.action.label"
              @click="data.action.onClick"
            />
          </div>
        </SCardBlock>
      </SCard>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.ErrorPage {
  flex-grow: 1;
  padding: 32px 32px 128px;
  background-color: var(--c-bg-elv-2);
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
}

.title {
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.desc {
  max-width: 512px;
  font-size: 14px;
}
</style>
