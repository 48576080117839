<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, required } from 'sefirot/validation/rules'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { toNumberOrNull } from '@/support/Num'
import { toStringOrNull } from '@/support/Str'
import { useCompanyPortfolioDataUpdateIssuedShares } from '@/composables/company/CompanyData'
import DInputShares, { type Share } from '@/components/DInputShares.vue'

export interface Data {
  issuedShares: Share[]
  issuedSharesNotes: string | null
}

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data } = useData<Data>({
  issuedShares: props.portfolioData.issuedShares.map(({ name, count }) => ({
    name: name!,
    count: toNumberOrNull(count)!
  })),
  issuedSharesNotes: props.portfolioData.issuedSharesNotes
})

const { validateAndNotify } = useValidation()

const issuedSharesRules = {
  name: { required: required() },
  count: { required: required(), decimal: decimal() }
}

const { execute, loading } = useCompanyPortfolioDataUpdateIssuedShares(
  props.portfolioData.companyId!,
  () => ({
    issuedShares: data.value.issuedShares.map(({ name, count }) => ({
      name: name!,
      count: toStringOrNull(count)!
    })),
    issuedSharesNotes: data.value.issuedSharesNotes
  })
)

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update outstanding shares</DFormTitle>
    <DFormLead>It should list all shares the company has issued at the moment, including shares not owned by Global Brain.</DFormLead>

    <DFormGrid>
      <DFormGridItem span="6">
        <DInputShares
          v-model="data.issuedShares"
          :rules="issuedSharesRules"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Notes"
          placeholder="Add any notes about shares."
          :rows="8"
          v-model="data.issuedSharesNotes"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
