<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import SInputAddon from 'sefirot/components/SInputAddon.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { ref } from 'vue'
import { type SurveyFrag } from '@/graphql'
import { useSurveyOps } from '@/composables/ops/SurveyOps'

const props = defineProps<{
  survey: SurveyFrag
}>()

defineEmits<{
  'close': []
}>()

const { t } = useTrans({
  en: {
    copy: 'Copy URL',
    close: 'Close dialog'
  },
  ja: {
    copy: 'URLをコピー',
    close: '閉じる'
  }
})

const surveyOps = useSurveyOps()

const { copy, copied } = useClipboard({
  source: surveyOps.publicPath(props.survey),
  copiedDuring: 3000
})

const copyCount = ref(0)

function onCopy() {
  copy()
  copyCount.value++
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-pt-32 s-px-32 s-pb-24">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Survey created</STrans>
            <STrans lang="ja">Surveyが作成されました</STrans>
          </h2>
          <p>
            <STrans lang="en">Send this URL to startup in order to get confirmation.</STrans>
            <STrans lang="ja">このURLをスタートアップに共有して回答を受領してください。</STrans>
          </p>
        </SContent>
        <div class="input">
          <SInputText
            :model-value="surveyOps.publicPath(survey)"
            disabled
          >
            <template #addon-after>
              <SInputAddon :label="t.copy" @click="onCopy" />
            </template>
          </SInputText>
          <div class="copied">
            <Transition name="fade">
              <div v-if="copied" :key="copyCount" class="copied-text">
                <STrans lang="en">Copied url to clipboard.</STrans>
                <STrans lang="ja">クリップボードにコピーしました。</STrans>
              </div>
            </Transition>
          </div>
        </div>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="large" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.close"
            @click="$emit('close')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.copied {
  position: relative;
  height: 20px;
}

.copied-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 20px;
  padding-top: 6px;
  text-align: right;
  font-size: 12px;
  color: var(--c-text-info-1);
  transition: opacity 0.25s, transform 0.25s;
}

.copied-text.fade-enter-from {
  opacity: 0;
  transform: translateY(-4px);
}

.copied-text.fade-leave-to {
  opacity: 0;
  transform: translateY(4px);
}
</style>
