import { type Day, type Ymd, createYmd, day } from 'sefirot/support/Day'
import {
  type CompanyBusinessReportFinanceTransitionRecordFrag,
  CompanyBusinessReportFinanceTransitionRecordTemplate,
  type CompanyBusinessReportWithCompanyFrag,
  type CompanyBusinessReportWithFirstInvestedDateFrag,
  type CompanyBusinessReportWithFoundedYearMonthFrag,
  type CompanyBusinessReportWithIdFrag,
  type CompanyBusinessReportWithStatusFrag,
  type CompanyBusinessReportWithTimestampFrag,
  FundReportRecordStatus,
  type UserFrag
} from '@/graphql'
import { ymdFromYearMonth } from '@/support/Day'
import { type ValueOf } from '@/support/Types'
import { useCompanyOps } from './CompanyOps'
import { defineOps } from './Ops'

export const useCompanyBusinessReportOps = defineOps(() => {
  const companyOps = useCompanyOps()

  const FundReportRecordStatusDict = {
    [FundReportRecordStatus.Draft]: 'Draft',
    [FundReportRecordStatus.ReviewRequested]: 'Review requested',
    [FundReportRecordStatus.InReview]: 'In review',
    [FundReportRecordStatus.ChangeRequested]: 'Change requested',
    [FundReportRecordStatus.Completed]: 'Completed'
  } as const

  const FundReportRecordModeDict = {
    [FundReportRecordStatus.Draft]: 'mute',
    [FundReportRecordStatus.ReviewRequested]: 'info',
    [FundReportRecordStatus.InReview]: 'info',
    [FundReportRecordStatus.ChangeRequested]: 'danger',
    [FundReportRecordStatus.Completed]: 'success'
  } as const

  function name(cbr: CompanyBusinessReportWithCompanyFrag): string {
    return companyOps.name(cbr.company)
  }

  function path(cbr: CompanyBusinessReportWithIdFrag): string {
    return `/company-business-reports/${cbr.id}`
  }

  function statusText(cbr: CompanyBusinessReportWithStatusFrag): string {
    return FundReportRecordStatusDict[cbr.status]
  }

  function statusMode(cbr: CompanyBusinessReportWithStatusFrag): ValueOf<typeof FundReportRecordModeDict> {
    return FundReportRecordModeDict[cbr.status]
  }

  function foundedYearMonthAsYmd(cbr: CompanyBusinessReportWithFoundedYearMonthFrag): Ymd {
    return cbr.foundedYearMonth
      ? ymdFromYearMonth(cbr.foundedYearMonth)
      : createYmd()
  }

  function firstInvestedDate(cbr: CompanyBusinessReportWithFirstInvestedDateFrag): Day {
    return day(cbr.firstInvestedDate)
  }

  function assignees(cbr: CompanyBusinessReportWithCompanyFrag): UserFrag[] {
    return [
      cbr.company.primaryInCharge,
      cbr.company.deputyInCharge,
      ...cbr.company.participants
    ].filter(Boolean) as UserFrag[]
  }

  function updatedAt(cbr: CompanyBusinessReportWithTimestampFrag): Day {
    return day(cbr.updatedAt)
  }

  function isStatusInReview(cbr: CompanyBusinessReportWithStatusFrag): boolean {
    return cbr.status === FundReportRecordStatus.InReview
  }

  return {
    name,
    path,
    statusText,
    statusMode,
    foundedYearMonthAsYmd,
    firstInvestedDate,
    assignees,
    updatedAt,
    isStatusInReview
  }
})

export const useCompanyBusinessReportFinanceTransitionRecordOps = defineOps(() => {
  const TemplateTextDict = {
    [CompanyBusinessReportFinanceTransitionRecordTemplate.ThirdPartyAllocation]: 'テンプレート: 第三者割当増資',
    [CompanyBusinessReportFinanceTransitionRecordTemplate.ShareConversion]: 'テンプレート: 株式の転換',
    [CompanyBusinessReportFinanceTransitionRecordTemplate.RightExecution]: 'テンプレート: 株式の行使',
    [CompanyBusinessReportFinanceTransitionRecordTemplate.BondIssuing]: 'テンプレート: 債券の発行'
  }

  function templateTextByValue(template: CompanyBusinessReportFinanceTransitionRecordTemplate): string {
    return TemplateTextDict[template]
  }

  function createData(template: CompanyBusinessReportFinanceTransitionRecordTemplate): CompanyBusinessReportFinanceTransitionRecordFrag {
    if (template === CompanyBusinessReportFinanceTransitionRecordTemplate.ThirdPartyAllocation) {
      return {
        rows: [
          { key: day().format('YYYY年M月'), value: '第三者割当増資' },
          { key: '発行価格', value: '例: USD 10.1234' },
          { key: '種類', value: '例: Series A Preferred Stock' },
          { key: '発行株式数', value: '例: 1,234,567株' },
          { key: '発行総額', value: '例: USD 12,345,678.91' }
        ]
      }
    }

    if (template === CompanyBusinessReportFinanceTransitionRecordTemplate.ShareConversion) {
      return {
        rows: [
          { key: day().format('YYYY年M月'), value: 'xxx 株式転換' },
          { key: '転換価格', value: '例: USD 10.1234' },
          { key: '種類', value: '例: Series A Preferred Stock' },
          { key: '転換株式数', value: '例: 1,234,567株' },
          { key: '転換総額', value: '例: USD 12,345,678.91' }
        ]
      }
    }

    if (template === CompanyBusinessReportFinanceTransitionRecordTemplate.RightExecution) {
      return {
        rows: [
          { key: day().format('YYYY年M月'), value: 'xxx 行使' },
          { key: '行使価格', value: '例: USD 10.1234' },
          { key: '種類', value: '例: Series A Preferred Stock' },
          { key: '発行株式数', value: '例: 1,234,567株' }
        ]
      }
    }

    return {
      rows: [
        { key: day().format('YYYY年M月'), value: 'xxx 発行' },
        { key: '発行総額', value: '例: USD 12,345,678.91' }
      ]
    }
  }

  function createTemplateSelectOptions(): { label: string; value: CompanyBusinessReportFinanceTransitionRecordTemplate }[] {
    return [
      {
        label: templateTextByValue(CompanyBusinessReportFinanceTransitionRecordTemplate.ThirdPartyAllocation),
        value: CompanyBusinessReportFinanceTransitionRecordTemplate.ThirdPartyAllocation
      },
      {
        label: templateTextByValue(CompanyBusinessReportFinanceTransitionRecordTemplate.ShareConversion),
        value: CompanyBusinessReportFinanceTransitionRecordTemplate.ShareConversion
      },
      {
        label: templateTextByValue(CompanyBusinessReportFinanceTransitionRecordTemplate.RightExecution),
        value: CompanyBusinessReportFinanceTransitionRecordTemplate.RightExecution
      },
      {
        label: templateTextByValue(CompanyBusinessReportFinanceTransitionRecordTemplate.BondIssuing),
        value: CompanyBusinessReportFinanceTransitionRecordTemplate.BondIssuing
      }
    ]
  }

  return {
    templateTextByValue,
    createData,
    createTemplateSelectOptions
  }
})
