<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength, required } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsExecutivePresentationRequest } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { useRequestEp } from '@/composables/repos/DealEntryRepo'
import DealEntryEpRequestComplete from './DealEntryEpRequestComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsExecutivePresentationRequest
}>()

const { t } = useTrans({
  en: {
    i_date_from_label: 'From *',
    i_date_to_label: 'Until *',
    i_date_for_ic_label: 'Planned Investment Committee date *',
    i_notes_label: 'Notes',
    i_notes_ph: 'Fill in any additional information',
    submit: 'Submit request'
  },
  ja: {
    i_date_from_label: 'この日から *',
    i_date_to_label: 'この日までに *',
    i_date_for_ic_label: '投資委員会実施想定日（希望日） *',
    i_notes_label: '備考',
    i_notes_ph: '特記事項があれば入力ください',
    submit: '送信する'
  }
})

const router = useRouter()

const { execute: requestIc, loading } = useRequestEp(() => props.deal.id!, () => ({
  dateFrom: data.value.dateFrom!.format('YYYY-MM-DD'),
  dateTo: data.value.dateTo!.format('YYYY-MM-DD'),
  dateForInvestmentCommittee: data.value.dateForInvestmentCommittee!.format('YYYY-MM-DD'),
  notes: data.value.notes
}))

const { data } = useData({
  dateFrom: null as Day | null,
  dateTo: null as Day | null,
  dateForInvestmentCommittee: null as Day | null,
  notes: null
})

const { validation, validateAndNotify } = useValidation(data, {
  dateFrom: {
    required: required()
  },
  dateTo: {
    required: required()
  },
  dateForInvestmentCommittee: {
    required: required()
  },
  notes: {
    maxLength: maxLength(1000)
  }
})

const completeDialog = usePower()

async function submit() {
  if (await validateAndNotify()) {
    await requestIc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryEpRequest">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SDocSection class="intro">
              <SContent>
                <STrans lang="en">
                  <h1>Request Executive Presentation</h1>
                  <p>
                    This is a meeting where the CEO (CxO) explains the business to the GP in order for the voting members to confirm the qualifications of the management. As soon as Yurimoto-san confirms the availability of the meeting, the fund management team will adjust the date  based on the status of other entries and contact you. <SLink href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.nhhrraj39d2u">Refer to this link</SLink> for more details about Executive Presentation.
                  </p>
                </STrans>
                <STrans lang="ja">
                  <h1>経営者プレゼン 開催依頼</h1>
                  <p>経営者の資質を議決権者が確認するために、CEO（CxO）よりGPへ事業説明を行うための会議体です。毎週水曜日午後開催。エントリー後、百合本さんに実施可否確認でき次第、ファンド管理チームより他のエントリー状況を踏まえ開催日時を調整し、連絡します。 経営者プレゼンの<SLink class="link" href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.nhhrraj39d2u">詳細についてはこちら</SLink>を確認してください。</p>
                </STrans>
              </SContent>
            </SDocSection>
            <SDivider />
            <SDocSection class="dates">
              <SContent>
                <STrans lang="en">
                  <h2>Desired date</h2>
                  <p>Specify the time frame during which you would like to hold the Executive Presentation. Fund Management team will try to schedule the meeting within this period. If you already have specific date and time options in mind, specify the time frame here and note those details in the "Notes" section.</p>
                  <SAlert v-if="settings.datesAlertInfoEn" mode="info">
                    <p>{{ settings.datesAlertInfoEn }}</p>
                  </SAlert>
                </STrans>
                <STrans lang="ja">
                  <h2>希望日程</h2>
                  <p>いつからいつまでの間に経営者プレゼンの開催を希望するか指定してください。この期間の中から日程を調整します。具体的な日時の候補が存在する場合、ここで期間を指定した上で、「備考」にその旨を記載してください。</p>
                  <SAlert v-if="settings.datesAlertInfoJa" mode="info">
                    <p>{{ settings.datesAlertInfoJa }}</p>
                  </SAlert>
                </STrans>
              </SContent>
              <div class="period">
                <SInputDate
                  :label="t.i_date_from_label"
                  v-model="data.dateFrom"
                  :validation="validation.dateFrom"
                />
                <div class="period-divider">–</div>
                <SInputDate
                  :label="t.i_date_to_label"
                  v-model="data.dateTo"
                  :validation="validation.dateTo"
                />
              </div>
            </SDocSection>
            <SDivider />
            <SDocSection class="details">
              <SContent>
                <STrans lang="en">
                  <h2>Details</h2>
                  <p>Fill in the details for the presentation.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>その他詳細</h2>
                  <p>経営者プレゼンに関する情報を入力してください。</p>
                </STrans>
              </SContent>
              <SInputDate
                :label="t.i_date_for_ic_label"
                block
                v-model="data.dateForInvestmentCommittee"
              />
              <SInputTextarea
                :label="t.i_notes_label"
                :placeholder="t.i_notes_ph"
                v-model="data.notes"
                :validation="validation.notes"
              />
            </SDocSection>
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryEpRequestComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryEpRequest {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.period {
  display: flex;
  align-items: center;
  gap: 16px;
}

.period-divider {
  padding-top: 26px;
  color: var(--c-text-3);
}
</style>
