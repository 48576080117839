<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type CompanyCautionNote } from '@/graph/Company'
import { useDeleteCompanyCautionNote } from '@/composables/repos/CompanyCautionNoteRepo'

const props = defineProps<{
  companyCautionNote: CompanyCautionNote
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    delete: 'Delete internal memo'
  },
  ja: {
    cancel: 'キャンセル',
    delete: '社内メモを削除'
  }
})

const mutation = useDeleteCompanyCautionNote()

async function destroy() {
  await mutation.execute(props.companyCautionNote.companyId)
  emit('deleted')
}
</script>

<template>
  <SCard class="UpdateFormAddCautionNote" size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Delete this internal memo</h2>
          <p>You are about to delete this internal memo. This action cannot be undone.</p>
        </STrans>
        <STrans lang="ja">
          <h2>この社内メモを削除する</h2>
          <p>社内メモを削除します。この操作は取り消すことができません。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-p-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            :label="t.delete"
            mode="danger"
            :loading="mutation.loading.value"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
