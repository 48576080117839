<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { type Company } from '@/models/Company'
import CompanyFormUpdateBusiness from './CompanyFormUpdateBusiness.vue'

defineProps<{
  company: Company
  canUpdate: boolean | null
}>()

defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    title: 'Business information',
    l_active_countries: 'Active countries',
    l_num_employees: 'Number of employees',
    l_marketing_model: 'Marketing model',
    l_primary_sector: 'Primary sector',
    l_sub_sectors: 'Sub sectors',
    l_description: 'Description'
  },
  ja: {
    title: '事業情報',
    l_active_countries: '事業を展開している国',
    l_num_employees: '従業員数',
    l_marketing_model: 'マーケティングモデル',
    l_primary_sector: 'Primary sector',
    l_sub_sectors: 'Sub sectors',
    l_description: '企業概要'
  }
})

const updateDialog = usePower()
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">{{ t.title }}</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton v-if="canUpdate" :icon="IconNotePencil" @click="updateDialog.on" />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="4" gap="24">
        <SDescItem span="2">
          <SDescLabel :value="t.l_active_countries" />
          <SDescPill :pill="company.activeCountries.map((c) => ({ label: c.name! }))" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel :value="t.l_num_employees" />
          <SDescNumber :value="company.numEmployees" />
        </SDescItem>
        <SDescItem>
          <SDescLabel :value="t.l_marketing_model" />
          <SDescPill :pill="company.marketingModel ? ({ label: company.marketingModel.name! }) : null" />
        </SDescItem>
        <SDescItem>
          <SDescLabel :value="t.l_primary_sector" />
          <SDescPill :pill="company.primarySector ? ({ label: company.primarySector.name! }) : null" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel :value="t.l_sub_sectors" />
          <SDescPill :pill="company.subSectors.map((c) => ({ label: c.name! }))" />
        </SDescItem>
        <SDescItem span="4">
          <SDescLabel :value="t.l_description" />
          <SDescText :value="company.description" pre-wrap linkify />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <CompanyFormUpdateBusiness
        :company="company"
        @updated="updateDialog.off(() => $emit('updated'))"
        @cancel="updateDialog.off"
      />
    </SModal>
  </SCard>
</template>
