import { format as formatNumber } from 'sefirot/support/Num'
import { type CurrencyFrag, type MoneyFrag, type MoneyWithoutIdFrag } from '@/graphql'
import { defineOps } from './Ops'

export const useMoneyOps = defineOps(() => {
  function create(currency: CurrencyFrag, value: string): MoneyFrag {
    return {
      currencyId: currency.id,
      currency,
      value
    }
  }

  function createJpy(value: string): MoneyWithoutIdFrag {
    return {
      currency: { symbol: '¥', name: 'JPY' },
      value
    }
  }

  function format(money: MoneyWithoutIdFrag): string {
    return `${money.currency.symbol} ${formatValue(money)} ${money.currency.name}`
  }

  function formatWithCurrency(money: MoneyWithoutIdFrag): string {
    return `${money.currency.symbol} ${formatValue(money)}`
  }

  function formatWithCurrencyName(money: MoneyWithoutIdFrag): string {
    return `${money.currency.name} ${formatValue(money)}`
  }

  function formatValue(money: MoneyWithoutIdFrag): string {
    const value = money.value.split('.')

    value[0] = formatNumber(Number(value[0]))

    return value.join('.')
  }

  function valueAsNumber(money: MoneyWithoutIdFrag): number {
    return Number(money.value)
  }

  return {
    create,
    createJpy,
    format,
    formatWithCurrency,
    formatWithCurrencyName,
    formatValue,
    valueAsNumber
  }
})
