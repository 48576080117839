<script setup lang="ts">
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, maxValue } from 'sefirot/validation/rules'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { useCompanyPortfolioDataUpdatePotentialShares } from '@/composables/company/CompanyData'

export interface Data {
  totalPotentialDilutiveShares: number | null
  potentialDilutiveSharesNotes: string | null
}

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data } = useData<Data>({
  totalPotentialDilutiveShares: props.portfolioData.totalPotentialDilutiveShares,
  potentialDilutiveSharesNotes: props.portfolioData.potentialDilutiveSharesNotes
})

const { validation, validateAndNotify } = useValidation(data, {
  totalPotentialDilutiveShares: {
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  potentialDilutiveSharesNotes: {
    maxLength: maxLength(4000)
  }
})

const { execute, loading } = useCompanyPortfolioDataUpdatePotentialShares(props.portfolioData.companyId!, () => ({
  totalPotentialDilutiveShares: data.value.totalPotentialDilutiveShares,
  potentialDilutiveSharesNotes: data.value.potentialDilutiveSharesNotes
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update options/warrants</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputNumber
          label="Options/Warrants"
          placeholder="10000"
          separator
          v-model="data.totalPotentialDilutiveShares"
          :validation="validation.totalPotentialDilutiveShares"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Notes"
          placeholder="Add any notes about the options/warrants."
          :rows="8"
          v-model="data.potentialDilutiveSharesNotes"
          :validation="validation.potentialDilutiveSharesNotes"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
