<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength, required } from 'sefirot/validation/rules'
import { type Company } from '@/models/Company'
import { useCompanyAddName } from '@/composables/company/CompanyData'

export interface Data {
  displayName: string | null
  officialName: string | null
  englishName: string | null
  until: Day | null
}

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'added'): void
}>()

const { data } = useData<Data>({
  displayName: null,
  officialName: null,
  englishName: null,
  until: null
})

const { validation, validateAndNotify } = useValidation(data, {
  displayName: {
    required: required(),
    maxLength: maxLength(100)
  },
  officialName: {
    required: required(),
    maxLength: maxLength(100)
  },
  englishName: {
    required: required(),
    maxLength: maxLength(100)
  }
})

const { execute, loading } = useCompanyAddName(props.company.id!, () => ({
  displayName: data.value.displayName!,
  officialNameLocal: data.value.officialName!,
  officialNameEn: data.value.englishName!,
  until: data.value.until ? data.value.until.format('YYYY-MM-DD') : null
}))

async function add() {
  if (await validateAndNotify()) {
    await execute()
    emit('added')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Add a new name</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="3">
        <SInputText
          label="Display name *"
          placeholder="Awesome Company"
          v-model="data.displayName"
          :validation="validation.displayName"
        />
      </DFormGridItem>
      <DFormGridItem span="3" />
      <DFormGridItem span="3">
        <SInputText
          label="Official name *"
          placeholder="Awesome Company, Inc."
          v-model="data.officialName"
          :validation="validation.officialName"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          label="English name *"
          placeholder="English Company"
          v-model="data.englishName"
          :validation="validation.englishName"
        />
      </DFormGridItem>
      <DFormGridItem span="4">
        <SInputDate
          label="Until"
          help="
            By setting the “until” value it indicates that this company name
            is an old name. If you’re willing to add a new name, keep it blank.
          "
          v-model="data.until"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Add" :loading="loading" @click="add" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
