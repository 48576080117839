<script setup lang="ts">
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type CompanyVecFrag } from '@/graphql'
import { useCompanyVecJapanRegionList } from '@/composables/repos/CompanyVecJapanRegionRepo'
import { useUpdateCompanyVec } from '@/composables/repos/CompanyVecRepo'
import { useCompanyVecSectorList } from '@/composables/repos/CompanyVecSectorRepo'

const props = defineProps<{
  companyVec: CompanyVecFrag
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_update: 'Update VEC data'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_update: 'VEC情報を更新'
  }
})

const { data } = useData({
  sectorId: props.companyVec.sectorId ?? null,
  japanRegionId: props.companyVec.japanRegionId ?? null,
  isIoT: props.companyVec.isIoT
})

const { validation, validateAndNotify } = useValidation(data, {
  isIoT: {
    required: required()
  }
})

const { data: companyVecSectors } = useCompanyVecSectorList()

const sectorOptions = computed(() => (companyVecSectors.value ?? []).flatMap(({ name, id, enabled }) => {
  return (name && id)
    ? [{ label: name, value: id, disabled: !enabled }]
    : []
}))

const { data: japanRegions } = useCompanyVecJapanRegionList()

const japanRegionOptions = computed(() => (japanRegions.value ?? []).flatMap(({ name, id, enabled }) => {
  return (name && id)
    ? [{ label: name, value: id, disabled: !enabled }]
    : []
}))

const iotOptions = computed(() => [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
])

const { execute, loading } = useUpdateCompanyVec()

async function update() {
  if (await validateAndNotify()) {
    await execute(props.companyVec.companyId!, {
      sectorId: data.value.sectorId,
      japanRegionId: data.value.japanRegionId,
      isIoT: data.value.isIoT
    })
    emit('updated')
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Update this VEC data</STrans>
            <STrans lang="ja">VEC情報を更新する</STrans>
          </h2>
        </SContent>
        <SGrid cols="1" gap="24">
          <SGridItem span="1">
            <SInputSelect
              label="VEC sector"
              placeholder="Select a sector"
              nullable
              v-model="data.sectorId"
              :options="sectorOptions"
            />
          </SGridItem>
          <SGridItem span="1">
            <SInputSelect
              label="Japan region"
              placeholder="Select a region"
              nullable
              v-model="data.japanRegionId"
              :options="japanRegionOptions"
            />
          </SGridItem>
          <SGridItem span="1">
            <SInputSelect
              label="IoT related *"
              v-model="data.isIoT"
              :options="iotOptions"
              :validation="validation.isIoT"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
