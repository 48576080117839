<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed, reactive } from 'vue'
import { type Deal } from '@/models/Deal'
import DealFromDrop from './DealFormDrop.vue'

const props = defineProps<{
  deal: Deal
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const dropDialog = reactive(usePower())

const state = computed(() => {
  if (props.deal.isInvested()) {
    return 'invested'
  } else if (props.deal.isDropped()) {
    return 'dropped'
  } else {
    return 'droppable'
  }
})
</script>

<template>
  <SCard mode="danger">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Drop this deal</h2>
          <p v-if="state === 'invested'">The deal is invested and cannot be closed. If you need to re-open this deal, contact the administrator.</p>
          <p v-else-if="state === 'dropped'">The deal is already dropped. If you need to re-open this deal, contact the administrator.</p>
          <p v-else>You cannot reopen a dropped deal. Also, by dropping the deal, all of the fields will be locked, and you may not update the data anymore.</p>
        </SContent>
        <div v-if="state === 'droppable'" class="action">
          <SButton
            mode="danger"
            label="Drop deal"
            @click="dropDialog.on"
          />
        </div>
      </SDoc>
    </SCardBlock>
    <SModal :open="dropDialog.state" @close="dropDialog.off">
      <DealFromDrop
        :deal="deal"
        @cancel="dropDialog.off"
        @dropped="dropDialog.off(() => $emit('updated'))"
      />
    </SModal>
  </SCard>
</template>
