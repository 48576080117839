import { type Query, useQuery } from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { type DashboardCMCUpcomingOpportunity } from '@/graph/Dashboard'
import { type OpportunityWithCompanyAndRound } from '@/graph/Opportunity'
import { type Pagination } from '@/graph/Pagination'

export type DashboardDdTeamUpcomingOpportunityPage = Query<Pagination<OpportunityWithCompanyAndRound>>
export type DashboardCmcUpcomingOpportunityPage = Query<Pagination<DashboardCMCUpcomingOpportunity>>

export interface DashboardDdTeamUpcomingOpportunityPageOptions {
  page: number
  perPage: number
}

export interface DashboardCmcUpcomingOpportunityPageOptions {
  page: number
  perPage: number
}

export function useDashboardDdTeamUpcomingOpportunityPage(
  options: MaybeRefOrGetter<DashboardDdTeamUpcomingOpportunityPageOptions>
): DashboardDdTeamUpcomingOpportunityPage {
  return useQuery(async (http) => {
    return http.get<Pagination<OpportunityWithCompanyAndRound>>('/api/dashboard/dd-team/upcoming-opportunities', {
      params: toValue(options)
    })
  }, { watch: () => toValue(options) })
}

export function useDashboardCmcUpcomingOpportunityPage(
  options: MaybeRefOrGetter<DashboardCmcUpcomingOpportunityPageOptions>
): DashboardCmcUpcomingOpportunityPage {
  return useQuery(async (http) => {
    return http.get<Pagination<DashboardCMCUpcomingOpportunity>>('/api/dashboard/cmc/upcoming-opportunities', {
      params: toValue(options)
    })
  }, { watch: () => toValue(options) })
}
