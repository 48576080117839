<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconTrash from '~icons/ph/trash-bold'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useState } from '@/composables/State'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityDescRoundContent from './OpportunityDescRoundContent.vue'
import OpportunityDescRoundEmpty from './OpportunityDescRoundEmpty.vue'
import OpportunityFormAddRound from './OpportunityFormAddRound.vue'
import OpportunityFormDeleteRound from './OpportunityFormDeleteRound.vue'
import OpportunityFormUpdateRound from './OpportunityFormUpdateRound.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const canUpdate = useCanUpdateOpportunityPolicy(() => props.opportunity)
const { data: modal, set, clear } = useState<'add' | 'update' | 'delete'>(null)

function onDeleted() {
  clear()
  setTimeout(() => emit('updated'), 500)
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Round
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok && !!opportunity.round"
              :icon="IconNotePencil"
              @click="set('update')"
            />
            <SControlActionBarButton
              v-if="canUpdate.ok && opportunity.round?.deals?.length === 0"
              :icon="IconTrash"
              @click="set('delete')"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunityDescRoundContent
      v-if="opportunity.round"
      :round="opportunity.round"
    />
    <OpportunityDescRoundEmpty
      v-else
      :opportunity="opportunity"
      @click="set('add')"
    />

    <SModal :open="modal === 'add'">
      <OpportunityFormAddRound
        :opportunity="opportunity"
        @added="clear(() => $emit('updated'))"
        @cancel="clear"
      />
    </SModal>
    <SModal :open="modal === 'update'">
      <OpportunityFormUpdateRound
        :round="opportunity.round!"
        @updated="clear(() => $emit('updated'))"
        @cancel="clear"
      />
    </SModal>
    <SModal :open="modal === 'delete'">
      <OpportunityFormDeleteRound
        :round="opportunity.round!"
        @deleted="onDeleted"
        @cancel="clear"
      />
    </SModal>
  </SCard>
</template>
