<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type CompanyPortfolioData as CompanyPortfolioDataGraph } from '@/graph/CompanyPortfolioData'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import CompanyPortfolioDataFormUpdateExitStatus from './CompanyPortfolioDataFormUpdateExitStatus.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
  canUpdate: boolean | null
}>()

const emit = defineEmits<{
  updated: [companyPortfolioData: CompanyPortfolioDataGraph]
}>()

const exitStatus = computed(() => props.portfolioData.exitStatus)
const hasExitStatus = computed(() => !!exitStatus.value)
const formattedIsActivePortfolio = computed(() => props.portfolioData.isActivePortfolio ? 'YES' : 'NO')
const formattedExitDate = computed(() => props.portfolioData.exitDate?.format('YYYY.MM.DD'))
const isIpo = computed(() => props.portfolioData.exitStatus === 'Ipo')
const ipoLeadUnderwriter = computed(() => props.portfolioData.ipoLeadUnderwriter)
const ipoListingMarket = computed(() => props.portfolioData.ipoListingMarket)
const exitDetails = computed(() => props.portfolioData.exitDetails)

const updateDialog = usePower()

const actions = computed(() => {
  return props.canUpdate ? [{ icon: 'edit', onClick: updateDialog.on }] : []
})

function onUpdate(cpd: CompanyPortfolioDataGraph) {
  updateDialog.off()
  emit('updated', cpd)
}
</script>

<template>
  <DCard title="Exit status" :actions="actions">
    <DCardGrid>
      <template v-if="hasExitStatus">
        <DCardGridItem span="4">
          <DCardGridLabel text="Status" />
          <DCardGridText :text="exitStatus" />
        </DCardGridItem>
        <DCardGridItem span="4">
          <DCardGridLabel text="Is active portfolio" />
          <DCardGridText :text="formattedIsActivePortfolio" />
        </DCardGridItem>
        <DCardGridItem span="4">
          <DCardGridLabel text="Date" />
          <DCardGridText :text="formattedExitDate" />
        </DCardGridItem>
        <template v-if="isIpo">
          <DCardGridItem span="4">
            <DCardGridLabel text="Lead underwriter" />
            <DCardGridText :text="ipoLeadUnderwriter" />
          </DCardGridItem>
          <DCardGridItem span="4">
            <DCardGridLabel text="Listing market" />
            <DCardGridText :text="ipoListingMarket" />
          </DCardGridItem>
        </template>
        <DCardGridItem span="4">
          <DCardGridLabel text="Details" />
          <DCardGridText :text="exitDetails" />
        </DCardGridItem>
      </template>
      <DCardGridItem v-else span="4">
        <DCardGridLabel text="Status" />
        <DCardGridText text="Active" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <CompanyPortfolioDataFormUpdateExitStatus
        :portfolio-data="portfolioData"
        @updated="onUpdate"
        @cancel="updateDialog.off"
      />
    </SModal>
  </DCard>
</template>
