<script setup lang="ts">
import SInputSelect, { type Option } from 'sefirot/components/SInputSelect.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useGetOpportunityInboundOrOutboundList } from '@/composables/repos/OpportunityInboundOrOutboundRepo'

const model = defineModel<number>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Inbound / Outbound *',
    info: 'Whether this sourcing was inbound or outbound.'
  },
  ja: {
    label: 'Inbound / Outbound *',
    info: 'ソーシングがインバウンドかアウトバウンドだったか。'
  }
})

const { execute: getOpportunityInboundOrOutboundList } = useGetOpportunityInboundOrOutboundList()

const inboundOrOutbounds = await getOpportunityInboundOrOutboundList()

const options: Option[] = inboundOrOutbounds.map((s) => {
  return { label: s.name!, value: s.id!, disabled: !s.enabled }
})

if (model.value === 0) {
  model.value = options[0].value
}
</script>

<template>
  <SInputSelect
    :label="t.label"
    :info="t.info"
    :options="options"
    v-model="model"
  />
</template>
