<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { PageNotFoundError } from 'sefirot/errors/PageNotFoundError'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useDealItem } from '@/composables/deal/DealData'
import { useDealPolicy } from '@/composables/deal/DealPolicy'
import { useRFundItem } from '@/composables/repos/FundRepo'
import DealEntryIcDocs from '@/components/deal-entry/DealEntryIcDocs.vue'

const route = useRoute()

const id = Number(route.params.id)
const fundId = ref<number | undefined>()

const { data: deal } = useDealItem(id, { catch: [401, 404] })
const { data: fund } = useRFundItem(fundId)

const { canUpdate } = useDealPolicy(deal)

const title = computedWhen(deal, (d) => {
  const company = d.companyThroughRound()!
  const oppo = d.opportunityThroughRound()!
  return `${company.name} / ${oppo.title} / ${d.title} - Submit Docs for Investment Committee`
})

const settings = computed(() => {
  return fund.value?.dealEntrySettings.investmentCommitteeSubmitDoc
})

watch(deal, (d) => {
  if (d) {
    fundId.value = d.fundId
  }
})

watch(fund, (f) => {
  if (f && !f.dealEntrySettings.investmentCommitteeSubmitDoc) {
    throw new PageNotFoundError()
  }
})
</script>

<template>
  <DPage class="DealsIdEntriesSubmitDocsForIc" :title="title" :authorize="canUpdate">
    <DealEntryIcDocs
      v-if="deal && settings"
      :deal="deal"
      :settings="settings"
    />
  </DPage>
</template>
