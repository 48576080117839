<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { ref, watch } from 'vue'
import { type CompanyBusinessReportFinancialFiguresUnit } from '@/graphql'
import { type Company } from '@/models/Company'
import { toNumberOrNull } from '@/support/Num'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCompanyItemForCompanyBusinessReportCopy } from '@/composables/repos/CompanyRepo'
import { type Data } from './CompanyBusinessReportFormUpdate.vue'

interface DiffItem {
  title: string
  isSame: boolean
}

const props = defineProps<{
  reportData: Data
  companyId: Exclude<Company['id'], undefined>
  units: CompanyBusinessReportFinancialFiguresUnit[]
}>()

const emit = defineEmits<{
  'cancel': []
  'request-review': []
}>()

const { t } = useTrans({
  en: {

    company_name: 'Company name',
    past_names: 'Previous company name',
    representative_name_as: 'Representative name (as well as title and profile)',
    founded_at: 'Founded at',
    address: 'Address',
    description: 'Description',
    issued_shares: 'Issued shares',
    notes_on_issued_shares: 'Notes on issued shares',
    amount_of_ootential_dilutive_shares: 'Amount of Potential dilutive shares',
    notes_on_potential_dilutive_shares: 'Notes on potential dilutive shares',
    currency_of_capital: 'Currency of capital',
    amount_of_capital: 'Amount of capital',
    notes_on_capital: 'Notes on capital',
    financial_results: 'Financial results',
    cancel: 'Cancel',
    request_review: 'Request review'
  },
  ja: {
    company_name: '会社名',
    past_names: '旧社名',
    representative_name_as: '代表者名 (およびプロフィール)',
    founded_at: '設立年月日',
    address: '所在地',
    description: '事業内容',
    issued_shares: '発行済株式数',
    notes_on_issued_shares: '発行済株式数 補足事項',
    amount_of_ootential_dilutive_shares: '潜在株式数',
    notes_on_potential_dilutive_shares: '潜在株式数 補足事項',
    currency_of_capital: '資本金の通貨',
    amount_of_capital: '資本金の数量',
    notes_on_capital: '資本金 補足事項',
    financial_results: '財務数値',
    cancel: 'キャンセル',
    request_review: 'レビュー依頼'
  }
})

const companyOps = useCompanyOps()

const diffs = ref<DiffItem[]>([])
const isAllSame = ref(false)

const { data: company, loading: loadingCompany } = useCompanyItemForCompanyBusinessReportCopy(props.companyId)

watch(company, () => {
  const companyNameDiff: DiffItem = {
    title: t.company_name,
    isSame: props.reportData.officialName === (company.value?.names[0].officialNameLocal || null)
  }
  const pastNamesDiff: DiffItem = {
    title: t.past_names,
    isSame: props.reportData.pastNames === (company.value?.names.slice(1).map((n) => n.officialNameLocal).join(', ') || null)
  }
  const representativeNameDiff: DiffItem = {
    title: t.representative_name_as,
    isSame: props.reportData.representativeName === (company.value?.representativeName || null)
  }
  const foundedAtDiff: DiffItem = {
    title: t.founded_at,
    isSame: (
      props.reportData.foundedYearMonth?.year === company.value?.foundedYear
      && props.reportData.foundedYearMonth?.month === company.value?.foundedMonth
    )
  }
  const addressDiff: DiffItem = {
    title: t.address,
    isSame: props.reportData.address === (company.value ? companyOps.location(company.value) : null)
  }
  const descriptionDiff: DiffItem = {
    title: t.description,
    isSame: props.reportData.description === (company.value?.description || null)
  }
  const issuedSharesDiff: DiffItem = {
    title: t.issued_shares,
    isSame: isIssuedSharesSame()
  }
  const issuedSharesNotesDiff: DiffItem = {
    title: t.notes_on_issued_shares,
    isSame: props.reportData.issuedSharesNotes === (company.value?.portfolioData?.issuedSharesNotes || null)
  }
  const potentialDilutiveSharesDiff: DiffItem = {
    title: t.amount_of_ootential_dilutive_shares,
    isSame: props.reportData.totalPotentialDilutiveShares === (company.value?.portfolioData?.totalPotentialDilutiveShares ?? null)
  }
  const potentialDilutiveSharesNotesDiff: DiffItem = {
    title: t.notes_on_potential_dilutive_shares,
    isSame: props.reportData.potentialDilutiveSharesNotes === (company.value?.portfolioData?.potentialDilutiveSharesNotes || null)
  }
  const capitalCurrencyDiff: DiffItem = {
    title: t.currency_of_capital,
    isSame: props.reportData.capitalCurrency.id === (company.value?.portfolioData?.capital?.currency?.id ?? null)
  }
  const capitalAmountDiff: DiffItem = {
    title: t.amount_of_capital,
    isSame: props.reportData.capitalAmount === (company.value?.portfolioData?.capital?.value ?? null)
  }
  const capitalNotesDiff: DiffItem = {
    title: t.notes_on_capital,
    isSame: props.reportData.capitalNotes === (company.value?.portfolioData?.capitalNotes || null)
  }
  const financialResultsDiff: DiffItem = {
    title: t.financial_results,
    isSame: isFinancialResultsSame()
  }

  diffs.value = [
    companyNameDiff,
    pastNamesDiff,
    representativeNameDiff,
    foundedAtDiff,
    addressDiff,
    descriptionDiff,
    issuedSharesDiff,
    issuedSharesNotesDiff,
    potentialDilutiveSharesDiff,
    potentialDilutiveSharesNotesDiff,
    capitalCurrencyDiff,
    capitalAmountDiff,
    capitalNotesDiff,
    financialResultsDiff
  ].filter(({ isSame }) => !isSame)

  if (!diffs.value.length) {
    isAllSame.value = true
  }
}, { once: true })

function isIssuedSharesSame() {
  if (!props.reportData.issuedShares.length && !company.value?.portfolioData?.issuedShares?.length) {
    return true
  }

  const entries = Object.fromEntries(props.reportData.issuedShares.map(({ name, count }) => [name!, { asIs: count, isSame: false }]))

  for (const { name, count } of company.value?.portfolioData?.issuedShares ?? []) {
    if (entries[name]) {
      entries[name].isSame = entries[name].asIs === toNumberOrNull(count)
    } else {
      return false
    }
  }

  return Object.values(entries).every((entry) => entry.isSame)
}

function isFinancialResultsSame() {
  if (!props.reportData.financialResults.length && !company.value?.portfolioData?.financialFigures?.results?.length) {
    return true
  }

  const reportResults = props.reportData.financialResults.filter((result) => {
    if (result.period?.year && result.period?.month) {
      return true
    }

    return [
      result.unitId,
      result.netSales,
      result.ordinaryIncome,
      result.netIncome,
      result.totalAssets,
      result.capital,
      result.netAssets
    ].some((figure) => (figure as any) !== '' && figure !== null)
  })
  const companyResults = company.value?.portfolioData?.financialFigures?.results ?? []

  return !reportResults.length && !companyResults.length
}
</script>

<template>
  <SCard v-if="!loadingCompany" size="medium" class="CopmanyBusinessReportUpdateCompanyPrompt">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent v-if="!isAllSame">
          <STrans lang="en">
            <h2>Update company data</h2>
            <p>There seems to be some differences between company business report and the company data. It is advised to update the original company data as needed.</p>
          </STrans>
          <STrans lang="ja">
            <h2>会社情報の更新</h2>
            <p>報告書の内容とオリジナルの会社情報に差異があるようです。必要に応じて元の会社情報を更新することをおすすめします。</p>
          </STrans>
          <ul>
            <template v-for="diff in diffs">
              <li v-if="!diff.isSame" :key="diff.title">
                {{ diff.title }}
              </li>
            </template>
          </ul>
        </SContent>
        <SContent>
          <STrans lang="en">
            <h2>Submit related documents</h2>
            <p>Please submit the following documents to fund management team (<a href="mailto:submit@globalbrains.com">submit@globalbrains.com</a>). You do not need to submit them if the company is newly invested or if you have already done so.</p>
            <ul>
              <li>Shareholder list with the latest number of issued shares and potential dilutive shares</li>
              <li>Capitalization table</li>
              <li>Copy of a company register with the latest amount of capital</li>
              <li>Administration of Share Option Registry if applicable</li>
              <li>Latest annual financial statements (not a trial balance but the financial statements approved by the shareholders meeting after the closing of the fiscal year)</li>
            </ul>
          </STrans>
          <STrans lang="ja">
            <h2>関連書類の提出</h2>
            <p>以下の書類をファンド管理チーム (<a href="mailto:submit@globalbrains.com">submit@globalbrains.com</a>) に提出してください。新規投資の場合、または既に提出済みの場合には必要はありません。</p>
            <ul>
              <li>株主名簿 (最新の発行済株式数と潜在株式も確認できる資料)</li>
              <li>資本政策表</li>
              <li>登記簿謄本 (最新の資本金額が確認できる資料)</li>
              <li>新株予約権簿 (発行している場合)</li>
              <li>直近の年度決算書 (試算表ではなく決算締め後の株主総会承認済みの決算書)</li>
            </ul>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-pl-8 s-px-32">
      <SControl size="medium">
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.request_review"
            :loading="loadingCompany"
            @click="emit('request-review')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
