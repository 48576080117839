<script setup lang="ts">
import SAvatar, { type Size } from 'sefirot/components/SAvatar.vue'
import { computed } from 'vue'
import { type User } from '@/graph/User'
import { type UserFrag } from '@/graphql'
import { User as UserClass } from '@/models/User'
import { useUserOps } from '@/composables/ops/UserOps'

const props = withDefaults(defineProps<{
  size?: Size
  user: User | UserFrag | UserClass
}>(), {
  size: 'small'
})

const userOps = useUserOps()

const name = computed(() => {
  const user = props.user

  return UserClass.isUserModel(user)
    ? user.name ?? null
    : userOps.name(user)
})
</script>

<template>
  <SAvatar
    class="DAvatar"
    :size="size"
    :avatar="userOps.avatarPath(user as UserFrag)"
    :name="name"
  />
</template>
