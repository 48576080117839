import {
  type BasicPaginationInput,
  type FundCondition,
  FundDocument,
  type FundOrder,
  FundPageDocument,
  MyAssignedFundGroupTypesDocument
} from '@/graphql'
import { Request } from './Request'

export class FundRequest extends Request {
  fetchAll() {
    return this.request({
      query: FundDocument
    })
  }

  fetchAllMyAssignedFundGroupTypes() {
    return this.request({
      query: MyAssignedFundGroupTypesDocument
    })
  }

  fetchPage(
    page: BasicPaginationInput,
    condition: FundCondition,
    orderBy: FundOrder
  ) {
    return this.request({
      query: FundPageDocument,
      variables: { page, condition, orderBy }
    })
  }
}
