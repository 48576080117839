<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { computed } from 'vue'
import { type View } from '@/models/View'
import { type ViewResult } from '@/models/ViewResult'
import { useViewResultTable } from '@/composables/view/ViewResultTable'

const props = defineProps<{
  view?: View | null
  viewResult?: ViewResult | null
  loading?: boolean
}>()

const table = useViewResultTable(
  computed(() => props.view),
  computed(() => props.viewResult),
  computed(() => props.loading)
)
</script>

<template>
  <STable class="list" :options="table" />
</template>

<style scoped lang="postcss">
.list {
  --table-head-top: var(--header-height);
  --table-max-height: calc(100vh - 224px);
  --table-padding-left: 16px;
}

:deep(.STableItem) { --table-col-width: 150px; }
:deep(.STableItem:last-child) { --table-col-max-width: auto; }
</style>
