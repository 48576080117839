<script setup lang="ts">
import { groupBy, keyBy, sumBy } from 'lodash-es'
import SChartPie from 'sefirot/components/SChartPie.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type KV } from 'sefirot/support/Chart'
import { computed } from 'vue'
import { type FundPortfolioStats } from '@/graph/FundPortfolioStats'

const props = defineProps<{
  portfolioStats: FundPortfolioStats[]
}>()

const { t } = useTrans({
  en: {
    title: 'Portfolio by country'
  },
  ja: {
    title: '国別ポートフォリオ数'
  }
})

const colors = ['blue', 'green', 'tomato', 'pink', 'iris', 'gray']

const data = computed(() => {
  const total = props.portfolioStats.length

  if (total === 0) {
    return { total: 0, items: [] }
  }

  const grouped = groupBy(props.portfolioStats, 'country.name')
  const all = Object.entries(grouped).map(([countryName, stats]) => {
    return {
      countryName,
      count: stats.length
    }
  }).toSorted((a, b) => b.count - a.count)

  const border = 5
  const regulars = all.slice(0, border)
  const others = all.slice(border)

  const othersAggregated = {
    countryName: 'Other',
    count: sumBy(others, 'count')
  }

  const items = (others.length === 0 ? regulars : [...regulars, othersAggregated]).map((d) => {
    return {
      key: d.countryName,
      count: d.count,
      percentage: Math.round(d.count / total * 100)
    }
  })

  return { total, items }
})

const chartData = computed(() => {
  return data.value.items.map((c) => {
    return {
      key: c.key,
      value: c.percentage
    }
  })
})

const dataItemDictByKey = computed(() => {
  return keyBy(data.value.items, 'key')
})

function tooltipFormat(kv: KV) {
  const d = dataItemDictByKey.value[kv.key]
  return `${kv.key}: ${d.count} (${d.percentage}%)`
}
</script>

<template>
  <SCard class="FundChartPortfolioCountByCountry">
    <SCardBlock class="box">
      <div class="header">
        <div class="title">{{ t.title }}</div>
      </div>
      <div v-if="chartData.length" class="chart">
        <SChartPie
          :data="chartData"
          :colors="colors"
          :inner-radius="o => o * 0.9"
          :tooltip-format="tooltipFormat"
          legend-font-size="12px"
          :legend-format-value="(l) => `${l.value}%`"
          :legend-padding="96"
          :margins="{
            top: 8,
            right: 32,
            bottom: 0,
            left: 32
          }"
        />
      </div>
      <div v-else class="empty">
        <div class="empty-text">
          <STrans lang="en">The fund has no portfolio companies yet.</STrans>
          <STrans lang="ja">まだポートフォリオ企業がありません。</STrans>
        </div>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.FundChartPortfolioCountByCountry {
  grid-column: span 1;
  background-color: transparent;

  --c-bg-elv-3: var(--c-bg-1);
}

.box {
  height: 280px;
}

.header {
  padding: 24px;
}

.title {
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
}

.chart {
  width: 100%;
  height: 192px;
}

.empty {
  padding: 0 24px;
  height: 192px;
}

.empty-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed var(--c-divider);
  border-radius: 6px;
  padding: 0 16px;
  height: 100%;
  font-size: 14px;
  color: var(--c-text-3);
}
</style>
