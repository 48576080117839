<script setup lang="ts">
import IconPlus from '~icons/ph/plus'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type CurrencyFrag, type MoneyWithoutIdFrag } from '@/graphql'
import { type SecurityKind } from '@/models/Deal'
import { useMoneyOps } from '@/composables/ops/MoneyOps'
import DealFormCreateSecuritiesItem from './DealFormCreateSecuritiesItem.vue'

interface Security {
  kind: SecurityKind | null
  name: string | null
  plannedTotalPrice: string | null
  liquidationPreferenceMultiple: null
  liquidationPreferencePatternId: null
  antiDilutionProvisionId: null
  annualInterest: null
  repaymentDate: null
  repaymentRight: null
  discount: null
  valuationCap: null
  eligibleFinancingAmount: null
}

const props = defineProps<{
  currency: CurrencyFrag
  modelValue: Security[]
}>()

const { t } = useTrans({
  en: {
    i_add_security_label: 'Add security',
    total_amount: 'Total amount'
  },
  ja: {
    i_add_security_label: '証券を追加',
    total_amount: '合計金額'
  }
})

const moneyOps = useMoneyOps()

const totalAmount = computed<MoneyWithoutIdFrag>(() => {
  const value = props.modelValue.reduce((sum, s) => {
    const v = s.plannedTotalPrice ?? '0'
    return String(Number(sum) + Number(v))
  }, '0')

  return { currency: props.currency, value }
})

const emit = defineEmits<{
  'update:model-value': [Security[]]
  add: []
}>()

function update(index: number, value: Security) {
  emit(
    'update:model-value',
    props.modelValue.map((s, i) => (i === index) ? value : s)
  )
}

function remove(index: number) {
  emit(
    'update:model-value',
    props.modelValue.filter((_, i) => i !== index)
  )
}
</script>

<template>
  <SCard>
    <DealFormCreateSecuritiesItem
      v-for="security, index in modelValue"
      :key="index"
      :index="index + 1"
      :currency="currency"
      :security="security"
      :removable="modelValue.length > 1"
      @update="v => update(index, v)"
      @remove="remove(index)"
    />
    <SCardBlock size="medium" class="s-pl-8 s-px-16">
      <SControl>
        <SControlLeft>
          <SControlButton
            size="small"
            type="text"
            mode="info"
            :icon="IconPlus"
            :label="t.i_add_security_label"
            @click="$emit('add')"
          />
        </SControlLeft>
        <SControlRight>
          <div class="total">
            <span class="total-label">{{ t.total_amount }}:</span>
            <span class="total-value">
              {{ moneyOps.formatWithCurrencyName(totalAmount) }}
            </span>
          </div>
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.total {
  display: flex;
  gap: 8px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
}

.total-label,
.total-value {
  color: var(--c-text-2);
}
</style>
