<script setup lang="ts">
import IconSparkle from '~icons/ph/sparkle-bold'
import SButton from 'sefirot/components/SButton.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required, url as urlRule } from 'sefirot/validation/rules'
import { computed, ref } from 'vue'
import DInputCountry from '../DInputCountry.vue'

const url = defineModel<string | null>('url', { required: true })
const countryId = defineModel<number | null>('countryId', { required: true })

const emit = defineEmits<{
  execute: []
}>()

const { t } = useTrans({
  en: {
    i_country_ph: 'Select country',
    fill_by_ai: 'Auto fill by AI'
  },
  ja: {
    i_country_ph: '国を選択',
    fill_by_ai: 'AIで自動入力'
  }
})

const prevUrl = ref<string | null>(null)
const prevCountryId = ref<number | null>(null)

const { validation, validate } = useValidation(() => ({
  url: url.value,
  countryId: countryId.value
}), {
  url: {
    required: required(),
    url: urlRule(),
    maxLength: maxLength(255)
  },
  countryId: {
    required: required()
  }
}, { $stopPropagation: true })

const disableButton = computed(() => {
  if (prevUrl.value === null || prevCountryId.value === null) {
    return false
  }

  return (
    url.value === prevUrl.value
    && countryId.value === prevCountryId.value
  )
})

async function fillByAi() {
  if (await validate()) {
    emit('execute')
    prevUrl.value = url.value
    prevCountryId.value = countryId.value
  }
}
</script>

<template>
  <SCard class="CompanyFormCreateFillByAi">
    <SCardBlock class="s-p-16">
      <div class="block">
        <div class="main">
          <div class="header">
            <STrans lang="en">
              <div class="title">Auto fill company data by AI.</div>
              <div class="lead">AI can help you fill in company data from the company’s URL and country.</div>
            </STrans>
            <STrans lang="ja">
              <div class="title">AIによる会社データ自動入力</div>
              <div class="lead">AIを活用して、ホームページのURLと国名から会社データを自動的に入力できます。</div>
            </STrans>
          </div>

          <div class="input">
            <div class="input-item">
              <SInputText
                placeholder="https://example.com"
                v-model="url"
                :validation="validation.url"
              />
            </div>
            <div class="input-item">
              <DInputCountry
                :placeholder="t.i_country_ph"
                v-model="countryId"
                :validation="validation.countryId"
              />
            </div>
            <div class="input-action">
              <SButton
                :icon="IconSparkle"
                :label="t.fill_by_ai"
                :disabled="disableButton"
                @click="fillByAi"
              />
            </div>
          </div>
        </div>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.lead {
  font-size: 14px;
  color: var(--c-text-2);
}

.input {
  display: flex;
  gap: 12px;
}

.input-item {
  flex-grow: 1;
  width: 100%;
}

.input-action {
  flex-shrink: 0;
}
</style>
