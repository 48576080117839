<script setup lang="ts">
import { xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdownFilter } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { type SurveyStatus } from '@/graphql'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanCreateCompleteSurvey, useCanCreateSurvey } from '@/composables/policies/SurveyPolicy'
import { useSurveyTargetFundGroupList } from '@/composables/repos/FundRepo'
import { useSurveyPage } from '@/composables/repos/SurveyRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'

const { t } = useTrans({
  en: {
    c_search_ph: 'Search by ID and Company',
    c_reset_label: 'Reset filters',
    c_new_label: 'New survey',
    c_new_complete_label: 'Register on behalf',
    t_id_label: 'Survey ID',
    t_status_label: 'Status',
    t_company_label: 'Company',
    t_funds_label: 'Funds',
    t_created_by_label: 'Created by',
    t_created_at_label: 'Created at',
    t_linked_opportunity_label: 'Linked opportunity'
  },
  ja: {
    c_search_ph: 'ID、会社名で検索',
    c_reset_label: 'フィルターをリセット',
    c_new_label: '新規Survey',
    c_new_complete_label: '代理登録',
    t_id_label: 'Survey ID',
    t_status_label: 'ステータス',
    t_company_label: '会社',
    t_funds_label: 'ファンド',
    t_created_by_label: '作成者',
    t_created_at_label: '作成日時',
    t_linked_opportunity_label: '紐づくOpportunity'
  }
})

const { data: options, init } = useData({
  page: {
    page: 0,
    perPage: 100
  },
  condition: {
    query: null as string | null,
    status: [] as SurveyStatus[],
    fundGroup: [] as number[],
    createdBy: [] as number[]
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': (v: string) => Number(v),
    'condition.query': (v) => (v === '' || v === null) ? null : v,
    'condition.status': (v) => v.map((_v: any) => _v === '' || _v === null ? null : _v),
    'condition.fundGroup': (v) => v.map(Number),
    'condition.createdBy': (v) => v.map(Number)
  },
  exclude: [
    'perPage'
  ]
})

const { data: surveys, loading: fetchingSurveys } = useSurveyPage(options)
const { data: users } = useActiveUserList()
const { data: targetFundGroups } = useSurveyTargetFundGroupList()

const canCreateSurvey = useCanCreateSurvey()
const canCreateCompleteSurvey = useCanCreateCompleteSurvey()

const userOps = useUserOps()
const surveyOps = useSurveyOps()
const oppoOps = useOpportunityOps()

const table = useTable({
  records: computed(() => surveys.value?.items ?? []),
  loading: fetchingSurveys,
  borderless: true,
  orders: [
    'id',
    'status',
    'companyName',
    'fundGroups',
    'createdBy',
    'createdAt',
    'opportunity',
    'empty'
  ],
  columns: {
    id: {
      label: t.t_id_label,
      cell: (_, survey) => ({
        type: 'text',
        value: surveyOps.displayId(survey),
        link: surveyOps.path(survey),
        color: 'info'
      })
    },
    status: {
      label: t.t_status_label,
      dropdown: [
        createDropdownFilter({
          selected: computed(() => options.value.condition.status),
          options: computed(() => surveyOps.createStatusOptions()),
          onClick(value: SurveyStatus) {
            options.value.condition.status = xor(options.value.condition.status, [value])
          }
        })
      ],
      cell: (_, survey) => ({
        type: 'state',
        label: surveyOps.statusText(survey),
        mode: surveyOps.statusMode(survey)
      })
    },
    companyName: {
      label: t.t_company_label
    },
    fundGroups: {
      label: t.t_funds_label,
      dropdown: [
        createDropdownFilter({
          search: true,
          selected: computed(() => options.value.condition.fundGroup),
          options: computed(() => targetFundGroups.value?.map((fg) => ({
            label: fg.name,
            value: fg.id
          })) ?? []),
          onClick(value: number) {
            options.value.condition.fundGroup = xor(options.value.condition.fundGroup, [value])
          }
        })
      ],
      cell: (_, survey) => ({
        type: 'pills',
        pills: survey.fundGroups.map((fg) => ({
          label: fg.name
        }))
      })
    },
    createdBy: {
      label: t.t_created_by_label,
      dropdown: [
        createDropdownFilter({
          search: true,
          selected: computed(() => options.value.condition.createdBy),
          options: computed(() => users.value?.map((u) => ({
            label: userOps.name(u),
            value: u.id
          })) ?? []),
          onClick(value: number) {
            options.value.condition.createdBy = xor(options.value.condition.createdBy, [value])
          }
        })
      ],
      cell: (_, survey) => ({
        type: 'avatar',
        image: userOps.avatarPath(survey.createdBy),
        name: userOps.name(survey.createdBy)
      })
    },
    createdAt: {
      label: t.t_created_at_label,
      cell: (_, survey) => ({
        type: 'day',
        value: surveyOps.createdAt(survey),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    opportunity: {
      label: t.t_linked_opportunity_label,
      cell: (_, survey) => ({
        type: 'text',
        value: survey.opportunity?.company ? oppoOps.titleWithCompany(survey.opportunity) : null,
        link: survey.opportunity?.id ? oppoOps.path(survey.opportunity) : null,
        color: 'info'
      })
    },
    empty: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})
</script>

<template>
  <SCard class="SurveyCatalog s-overflow-hidden">
    <SCardBlock size="medium" class="s-pl-16 s-pr-12">
      <SControl>
        <SControlInputSearch
          class="s-max-w-320"
          :placeholder="t.c_search_ph"
          :model-value="options.condition.query"
          @enter="options.condition.query = $event"
        />
        <SControlLeft>
          <SControlButton
            type="outline"
            mode="mute"
            :label="t.c_reset_label"
            @click="init"
          />
        </SControlLeft>
        <SControlRight>
          <SControlButton
            v-if="canCreateCompleteSurvey.ok"
            mode="mute"
            :label="t.c_new_complete_label"
            href="/surveys/create-complete"
          />
          <SControlButton
            v-if="canCreateSurvey.ok"
            mode="info"
            :label="t.c_new_label"
            href="/surveys/create"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock>
      <STable :options="table" />
    </SCardBlock>
    <SCardBlock v-if="surveys && !fetchingSurveys" size="medium" class="s-px-12">
      <SControl>
        <SControlRight>
          <SControlPagination
            :page="surveys.pageInfo.page + 1"
            :per-page="surveys.pageInfo.perPage"
            :total="surveys.pageInfo.total"
            @prev="options.page.page--"
            @next="options.page.page++"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.SurveyCatalog :deep(.col-id)          { --table-col-width: 160px; }
.SurveyCatalog :deep(.col-status)      { --table-col-width: 160px; }
.SurveyCatalog :deep(.col-companyName) { --table-col-width: 256px; }
.SurveyCatalog :deep(.col-fundGroups)  { --table-col-width: 320px; }
.SurveyCatalog :deep(.col-createdBy)   { --table-col-width: 192px; }
.SurveyCatalog :deep(.col-createdAt)   { --table-col-width: 192px; }
.SurveyCatalog :deep(.col-opportunity) { --table-col-width: 384px; }
.SurveyCatalog :deep(.col-empty)       { --table-col-width: 40px; }
</style>
