<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'
import { type Opportunity } from '@/graph/Opportunity'
import { useUpdateOpportunityFileFields } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  opportunityId: number
  hasNoPlanToAddFiles: boolean
  fileComment: string | null
}>()

const emit = defineEmits<{
  cancel: []
  updated: [oppo: Opportunity]
}>()

const { t } = useTrans({
  en: {
    i_comment_label: 'Comment',
    i_comment_ph: 'You can download the deck from the following Google Drive:\nhttps://drive.google.com/123456789',
    a_cancel: 'Cancel',
    a_update: 'Update comment'
  },
  ja: {
    i_comment_label: 'コメント',
    i_comment_ph: '以下のGoogle Driveよりデックがダウンロードできます:\nhttps://drive.google.com/123456789',
    a_cancel: 'キャンセル',
    a_update: 'コメントを更新'
  }
})

const updateOpportunityFileFields = useUpdateOpportunityFileFields()

const { data } = useData({
  fileComment: props.fileComment
})

const { validation, validateAndNotify } = useValidation({
  fileComment: {
    maxLength: maxLength(1000)
  }
})

async function update() {
  if (await validateAndNotify()) {
    const oppo = await updateOpportunityFileFields.execute(props.opportunityId, {
      hasNoPlanToAddFiles: props.hasNoPlanToAddFiles,
      fileComment: data.value.fileComment
    })
    emit('updated', oppo)
  }
}
</script>

<template>
  <SCard class="OpportunityFormUpdateFileComment" size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update comment for pitch deck</h2>
            <p>Leave comments regarding the document. If the received materials are provided via an external URL such as Google Drive or Box, you can use this feature to share the link.</p>
          </STrans>
          <STrans lang="ja">
            <h2>ピッチデックに関するコメントを追加する</h2>
            <p>資料に関するコメントを記載してください。受領資料がGoogle DriveやBoxなどの外部URLで提供されている場合、こちらを使ってリンクを共有できます。</p>
          </STrans>
        </SContent>
        <SAlert mode="warning">
          <p>
            <STrans lang="en">Shared files may be shared with the LPs of the fund that disclosed the Opportunity without prior notice. Ensure that you only list files that can be shared without any issues.</STrans>
            <STrans lang="ja">共有したファイルは、予告なくOpportunityを開示したファンドのLPへ共有される可能性があります。共有されても問題のないファイルのみ記載するようにしてください。</STrans>
          </p>
        </SAlert>
        <SInputTextarea
          :label="t.i_comment_label"
          :placeholder="t.i_comment_ph"
          :rows="5"
          v-model="data.fileComment"
          :validation="validation.fileComment"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock class="s-px-32" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="updateOpportunityFileFields.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            :loading="updateOpportunityFileFields.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
