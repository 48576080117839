<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { type State } from 'sefirot/components/SDescState.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityFormUpdateStatus from './OpportunityFormUpdateStatus.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const canUpdate = useCanUpdateOpportunityPolicy(props.opportunity)
const { state: modal, on, off } = usePower(false)
const oppoOps = useOpportunityOps()

const status = computed<State>(() => {
  return {
    label: oppoOps.statusText(props.opportunity),
    mode: oppoOps.statusMode(props.opportunity)
  }
})
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Opportunity status
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok"
              :icon="IconNotePencil"
              @click="on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel value="Status" />
          <SDescState :state="status" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Initial evaluation by PIC" />
          <SDescText :value="oppoOps.initialEvaluationText(opportunity)" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel value="Review status" />
          <SDescText :value="opportunity.reviewStatus" pre-wrap />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="modal">
      <OpportunityFormUpdateStatus
        :opportunity="opportunity"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </SCard>
</template>
