<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useLang, useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, rule } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type Company } from '@/models/Company'
import { useCompanyUpdateLatestAssignees } from '@/composables/company/CompanyData'
import { useGetCompanyParticipantChangeNote, useUpdateCompanyParticipantChangeNote } from '@/composables/repos/CompanyRepo'
import { useUserActiveCollection } from '@/composables/user/UserData'

export interface Data {
  primaryInChargeId: number | null
  deputyInChargeId: number | null
  participantIds: number[]
}

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    pic_label: 'Primary in-charge',
    pic_ph: 'Select a member',
    dic_label: 'Deputy in-charge',
    dic_ph: 'Select a member',
    participants_label: 'Participants',
    participants_ph: 'Select members',
    note_label: 'Assignee change note',
    note_ph: 'To strengthen support for our new business initiatives, we are adding XXX to the team. Additionally, due to YYY taking parental leave, ZZZ will be taking over their responsibilities.',
    cancel: 'Cancel',
    update: 'Update assignees'
  },
  ja: {
    pic_label: '主担当',
    pic_ph: 'メンバーを選択',
    dic_label: '副担当',
    dic_ph: 'メンバーを選択',
    participants_label: '関係者',
    participants_ph: 'メンバーを選択',
    note_label: '担当者変更メモ',
    note_ph: '新規事業の支援体制強化を目的に●●さんに入っていただく。また××さん育児休暇にともない△△さんに担当を引き継ぐ。',
    cancel: 'キャンセル',
    update: '担当者を更新'
  }
})

const lang = useLang()

const isEn = lang === 'en'

const { data: users } = useUserActiveCollection()
const { execute: getNote } = useGetCompanyParticipantChangeNote(props.company.id!)

const { data } = useData({
  primaryInChargeId: props.company.primaryInCharge?.id ?? null,
  deputyInChargeId: props.company.deputyInCharge?.id ?? null,
  participantIds: props.company.participants.map((u) => u.id!),
  note: await getNote()
})

const { validation, validateAndNotify } = useValidation(data, {
  deputyInChargeId: {
    notSameAs: rule(
      (value) => value !== data.value.primaryInChargeId,
      isEn ? 'Already assigned as Primary in-charge.' : 'すでにPrimary in-chargeとして設定されています。'
    )
  },
  participantIds: {
    notSameAsPic: rule(
      (value) => data.value.primaryInChargeId ? !((value as unknown[]).includes(data.value.primaryInChargeId)) : true,
      isEn ? 'Some member is already assigned as Primary in-charge.' : '選択されたユーザーがすでにPrimary in-chargeとして設定されています。'
    ),
    notSameAsDic: rule(
      (value) => data.value.deputyInChargeId ? !((value as unknown[]).includes(data.value.deputyInChargeId)) : true,
      isEn ? 'Some member is already assigned as Deputy in-charge.' : '選択されたユーザーがすでにDeputy in-chargeとして設定されています。'
    )
  },
  note: {
    maxLength: maxLength(2000)
  }
})

const userOptions = computed<Option[]>(() => {
  return users.value?.map((user) => {
    return { type: 'avatar', label: user.name!, value: user.id! }
  }) ?? []
})

const updateAssigneeMutation = useCompanyUpdateLatestAssignees(props.company.id!, () => ({
  primaryInChargeId: data.value.primaryInChargeId!,
  deputyInChargeId: data.value.deputyInChargeId,
  participantIds: data.value.participantIds
}))
const updateParticipantChangeNoteMutation = useUpdateCompanyParticipantChangeNote()

const loading = computed(() => {
  return updateAssigneeMutation.loading.value && updateParticipantChangeNoteMutation.loading.value
})

async function update() {
  if (await validateAndNotify()) {
    await updateAssigneeMutation.execute()
    await updateParticipantChangeNoteMutation.execute(props.company.id!, data.value.note)
    emit('updated')
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Update assignees</STrans>
            <STrans lang="ja">担当者を更新</STrans>
          </h2>
        </SContent>

        <SGrid gap="24" cols="6">
          <SGridItem span="3">
            <SInputDropdown
              :label="t.pic_label"
              :placeholder="t.pic_ph"
              nullable
              :options="userOptions"
              v-model="data.primaryInChargeId"
            />
          </SGridItem>

          <SGridItem span="3">
            <SInputDropdown
              :label="t.dic_label"
              :placeholder="t.dic_ph"
              nullable
              :options="userOptions"
              v-model="data.deputyInChargeId"
              :validation="validation.deputyInChargeId"
            />
          </SGridItem>

          <SGridItem span="6">
            <SInputDropdown
              :label="t.participants_label"
              :placeholder="t.participants_ph"
              :options="userOptions"
              nullable
              v-model="data.participantIds"
              :validation="validation.participantIds"
            />
          </SGridItem>

          <SGridItem span="6">
            <SInputTextarea
              :label="t.note_label"
              :placeholder="t.note_ph"
              v-model="data.note"
              :validation="validation.note"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.cancel" @click="$emit('cancel')" />
          <SControlButton mode="info" :label="t.update" :loading="loading" @click="update" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
