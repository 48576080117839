<script setup lang="ts">
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { format } from '@/support/Num'
import { useState } from '@/composables/State'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import CompanyPortfolioDataFormUpdatePotentialShares from './CompanyPortfolioDataFormUpdatePotentialShares.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const totalPotentialDilutiveShares = computed(() => format(props.portfolioData.totalPotentialDilutiveShares ?? 0))
const potentialDilutiveSharesNotes = computed(() => props.portfolioData.potentialDilutiveSharesNotes)

const { data: modal, set, clear } = useState<'update'>()
const { canUpdate } = useCompanyPortfolioDataPolicy(props.portfolioData)
const actions = computedArrayWhen(canUpdate, (items) => {
  items.push({ icon: 'edit', onClick: () => set('update') })
})
</script>

<template>
  <DCard title="Options/Warrants" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="4">
        <DCardGridLabel text="Options/Warrants" />
        <DCardGridText :text="totalPotentialDilutiveShares" />
      </DCardGridItem>
      <DCardGridItem span="4">
        <DCardGridLabel text="Notes" />
        <DCardGridText :text="potentialDilutiveSharesNotes" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal === 'update'">
      <CompanyPortfolioDataFormUpdatePotentialShares
        :portfolio-data="portfolioData"
        @updated="clear(() => emit('updated'))"
        @cancel="clear(() => emit('cancel'))"
      />
    </SModal>
  </DCard>
</template>
