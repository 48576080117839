<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import FundReportCatalog from '@/components/fund-report/FundReportCatalog.vue'

const { t } = useTrans({
  en: {
    title: 'Fund Reports'
  },
  ja: {
    title: '業務報告書'
  }
})
</script>

<template>
  <DPage class="FundReportsIndex" :title="t.title">
    <FundReportCatalog />
  </DPage>
</template>
