<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Review status *',
    info: 'How PIC feel about this opportunity and plans what to do next.',
    ph: 'e.g.\n- Promising business model. Planning to continue evaluation.\n- Not suited forr flagship fund. Opening to CVCs to see if there is any interest.'
  },
  ja: {
    label: 'Review status *',
    info: '案件に対する所感や次のステップ。',
    ph: '例：\n- 有望なビジネスモデル。検討継続予定。\n- 純投資観点では見送り。CVCに開示して興味があるか確認する。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required(),
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="3"
    v-model="model"
    :validation="validation.model"
  />
</template>
