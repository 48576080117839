<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type Fund } from '@/graph/Fund'
import { type FundStats } from '@/graph/FundStats'
import { useFundOps } from '@/composables/ops/FundOps'
import StatBlock from '../stat/StatBlock.vue'
import StatBlockHeader from '../stat/StatBlockHeader.vue'
import StatBlockValue from '../stat/StatBlockValue.vue'

defineProps<{
  fund: Fund
  stats: FundStats
}>()

const { t } = useTrans({
  en: {
    title: 'Fund size',
    help: (v: string) => `Investable amount: ${v}`
  },
  ja: {
    title: 'ファンド運用総額',
    help: (v: string) => `投資可能額: ${v}`
  }
})

const fundOps = useFundOps()
</script>

<template>
  <StatBlock>
    <StatBlockHeader :title="t.title" currency="JPY" />
    <StatBlockValue
      :value="fundOps.sizeAbbr(fund) ?? '???'"
      :help="t.help(fundOps.investableAmountAbbr(fund) ?? '???')"
    />
  </StatBlock>
</template>
