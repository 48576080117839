<script setup lang="ts">
import { useRoute } from 'vue-router'
import UsersProfilePerformanceCatalog from '@/components/users/UsersProfilePerformanceCatalog.vue'

const route = useRoute()
</script>

<template>
  <DPage
    class="UsersIdProfilePerformance"
    title="Profile - Performance"
  >
    <UsersProfilePerformanceCatalog :key="route.fullPath" />
  </DPage>
</template>
