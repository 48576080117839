<script setup lang="ts">
import IconBuilding from '~icons/ph/buildings'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { type Company } from '@/models/Company'
import { useUserOps } from '@/composables/ops/UserOps'

const props = withDefaults(defineProps<{
  query: string | null
  loading: boolean
  total?: number
  companies?: Company[]
}>(), {
  companies: () => []
})

const userOps = useUserOps()

const table = useTable({
  orders: [
    'name',
    'isPortfolio',
    'portfolioOf',
    'exit',
    'assignee',
    'sector',
    'country',
    'createdAt',
    'updatedAt'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: (_, record) => ({
        type: 'text',
        icon: IconBuilding,
        link: record.path,
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    isPortfolio: {
      label: 'Status',
      cell: (value) => ({
        type: 'pill',
        color: value === 'Portfolio' ? 'info' : 'mute'
      })
    },
    portfolioOf: {
      label: 'Portfolio of',
      cell: {
        type: 'text',
        color: 'neutral'
      }
    },
    exit: {
      label: 'Exit',
      cell: {
        type: 'pill',
        color: 'success'
      }
    },
    assignee: {
      label: 'Assignee',
      cell: (users) => ({
        type: 'avatars',
        avatars: users.map((user) => ({
          image: userOps.avatarPath(user as UserFrag),
          name: user?.firstNameEn ?? ''
        })),
        color: 'soft'
      })
    },
    sector: {
      label: 'Sector',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    country: {
      label: 'Country',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    updatedAt: {
      label: 'Updated at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    createdAt: {
      label: 'Created at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    }
  },
  records: computed(() => props.companies.map((c) => {
    return {
      name: c.name,
      path: c.path,
      isPortfolio: c.isActivePortfolio()
        ? 'Portfolio'
        : c.isExPortfolio() ? 'Ex-portfolio' : null,
      portfolioOf: c.isActivePortfolio()
        ? c.portfolioOf.map((f) => f.nameAbbreviated).join('・')
        : c.isExPortfolio()
          ? c.exPortfolioOf.map((f) => f.nameAbbreviated).join('・')
          : null,
      exit: c.ipo ? 'IPO' : null,
      assignee: [c.primaryInCharge, c.deputyInCharge, ...c.participants].filter(Boolean),
      sector: c.primarySector?.name,
      country: c.country?.name,
      updatedAt: c.updatedAt?.format('YYYY/MM/DD'),
      createdAt: c.createdAt?.format('YYYY/MM/DD')
    }
  })),
  total: computed(() => props.total ?? 0),
  loading: computed(() => props.loading)
})

const link = computed(() => {
  return props.query
    ? `/companies?condition[query]=${props.query}`
    : '/companies'
})
</script>

<template>
  <div class="SearchResultContentCompany">
    <div class="catalog">
      <STable class="list" :options="table" />

      <div v-if="!loading && companies?.length" class="action">
        <SButton
          type="outline"
          mode="info"
          :href="link"
          label="View more"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)        { --table-col-width: 288px; }
.list :deep(.col-isPortfolio) { --table-col-width: 112px; }
.list :deep(.col-portfolioOf) { --table-col-width: 128px; }
.list :deep(.col-exit)        { --table-col-width: 80px; }
.list :deep(.col-assignee)    { --table-col-width: 304px; }
.list :deep(.col-sector)      { --table-col-width: 160px; }
.list :deep(.col-country)     { --table-col-width: 112px; }
.list :deep(.col-createdAt)   { --table-col-width: 152px; }
.list :deep(.col-updatedAt)   { --table-col-width: 152px; }

.action {
  padding-top: 32px;
  text-align: center;

  --button-padding: 0 24px;
}
</style>
