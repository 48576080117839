<script setup lang="ts">
import { computed } from 'vue'
import { type User } from '@/models/User'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  user: User | null
}>()

const userOps = useUserOps()

const avatar = computed(() => ({
  avatar: userOps.avatarPath(props.user as any),
  name: userOps.name(props.user as any)
}))
</script>

<template>
  <SDescAvatar :avatar="avatar" />
</template>
