<script setup lang="ts">
import { orderBy } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useSnackbars } from 'sefirot/stores/Snackbars'
import { computed, reactive } from 'vue'
import {
  type FundOperatorRole,
  type UserAndRolesFrag,
  type UserFundAssignmentFrag
} from '@/graphql'
import { plural } from '@/support/Str'
import { useFundOperatorOps, useFundOps } from '@/composables/ops/FundOps'
import { useCanUpdateUserFundAssignments } from '@/composables/policies/UserPolicy'
import { useGetFundList } from '@/composables/repos/FundRepo'
import {
  useGetUserFundAssignmentList,
  useUpdateUserFundAssignments
} from '@/composables/repos/UserRepo'
import UserSettingsUpdateFundAssignmentsAsyncAddFund from './UserSettingsUpdateFundAssignmentsAsyncAddFund.vue'

const props = defineProps<{
  user: UserAndRolesFrag
}>()

const { t } = useTrans({
  en: {
    remove: 'Remove',
    updated: 'Fund assignments updated.',
    b_add_fund: 'Add fund',
    b_add_fund_disabled: 'No more funds to add',
    b_save: 'Update assignments'
  },
  ja: {
    remove: '削除',
    updated: '担当ファンドが更新されました.',
    b_add_fund: 'ファンドを追加',
    b_add_fund_disabled: '追加できるファンドがありません',
    b_save: '担当を更新'
  }
})

const canUpdateUserAssignments = useCanUpdateUserFundAssignments()

const { execute: getFundList } = useGetFundList()
const { execute: getUserFundAssignmentList } = useGetUserFundAssignmentList(props.user.id)

const [funds, fundAssignments] = await Promise.all([
  getFundList(),
  getUserFundAssignmentList()
])

const updateUserFundAssignmentsMutation = reactive(useUpdateUserFundAssignments())

const snackbars = useSnackbars()
const addDialog = reactive(usePower())

const fundOps = useFundOps()
const fundOperatorOps = useFundOperatorOps()

const { data } = useData({
  fundAssignments: createFundAssignmentsData(fundAssignments)
})

const fundOptions = computed(() => {
  const filteredFunds = funds.filter((f) => {
    return data.value.fundAssignments.every((fa) => fa.fundId !== f.id)
  })

  return fundOps.createSelectOptions(filteredFunds)
})

const operatorRoleOptions = fundOperatorOps.roleTextEntries().map(([value, label]) => {
  return { label, value }
})

const canAddAssignments = computed(() => {
  return funds.length > data.value.fundAssignments.length
})

const addAssignmentTooltip = computed(() => {
  return canAddAssignments.value
    ? undefined
    : { text: t.b_add_fund_disabled }
})

function createFundAssignmentsData(data: UserFundAssignmentFrag[]) {
  return data.map((fa) => ({
    fundId: fa.fundId,
    name: fa.fund.nameAbbreviated,
    role: fa.role,
    rank: fa.fund.rank
  }))
}

function addAssignment(fundId: number, role: FundOperatorRole) {
  const fund = funds.find((f) => f.id === fundId)!

  const newAssignment = {
    fundId: fund.id,
    name: fund.nameAbbreviated,
    role,
    rank: fund.rank
  }

  data.value.fundAssignments = orderBy(
    [...data.value.fundAssignments, newAssignment],
    'rank'
  )
}

function removeAssignment(fundId: number) {
  data.value.fundAssignments = data.value.fundAssignments.filter((assignment) => {
    return assignment.fundId !== fundId
  })
}

async function update() {
  const input = data.value.fundAssignments.map((assignment) => {
    return {
      fundId: assignment.fundId,
      role: assignment.role
    }
  })

  await updateUserFundAssignmentsMutation.execute(props.user.id, input)

  snackbars.push({ mode: 'info', text: t.updated })
}
</script>

<template>
  <SCard>
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Fund assignments</STrans>
            <STrans lang="ja">担当ファンド</STrans>
          </h2>
        </SContent>

        <SCard>
          <SCardBlock size="small" class="s-pl-16 s-pr-8">
            <SControl>
              <SControlLeft>
                <div class="s-font-12 s-text-2">
                  <STrans lang="en">Assigned to {{ plural(['fund', 'funds'], data.fundAssignments.length) }}</STrans>
                  <STrans lang="ja">{{ data.fundAssignments.length }}ファンドを担当中</STrans>
                </div>
              </SControlLeft>
              <SControlRight>
                <SControlButton
                  v-if="canUpdateUserAssignments.ok"
                  :label="t.b_add_fund"
                  :disabled="!canAddAssignments"
                  :tooltip="addAssignmentTooltip"
                  @click="addDialog.on"
                />
              </SControlRight>
            </SControl>
          </SCardBlock>
          <template v-if="data.fundAssignments.length">
            <SCardBlock v-for="fa in data.fundAssignments" :key="fa.fundId">
              <div class="input">
                <div class="name">
                  {{ fa.name }}
                </div>
                <div class="select">
                  <SInputSelect
                    size="mini"
                    :options="operatorRoleOptions"
                    :disabled="!canUpdateUserAssignments.ok"
                    v-model="fa.role"
                  />
                </div>
                <div v-if="canUpdateUserAssignments.ok" class="button">
                  <SButton
                    size="mini"
                    type="text"
                    mode="mute"
                    :label="t.remove"
                    @click="removeAssignment(fa.fundId)"
                  />
                </div>
              </div>
            </SCardBlock>
          </template>
          <template v-else>
            <SCardBlock>
              <div class="empty">
                <STrans lang="en">Not assigned to any funds.</STrans>
                <STrans lang="ja">どのファンドの担当でもありません。</STrans>
              </div>
            </SCardBlock>
          </template>
        </SCard>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            mode="info"
            :label="t.b_save"
            :loading="updateUserFundAssignmentsMutation.loading"
            :disabled="!canUpdateUserAssignments.ok"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>

  <SModal :open="addDialog.state" @close="addDialog.off">
    <UserSettingsUpdateFundAssignmentsAsyncAddFund
      :fund-options="fundOptions"
      :operator-role-options="operatorRoleOptions"
      @cancel="addDialog.off"
      @add="(fundId, role) => addDialog.off(() => addAssignment(fundId, role))"
    />
  </SModal>
</template>

<style scoped lang="postcss">
.input {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px 0 16px;
  height: 48px;
}

.name {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
}

.select {
  flex-shrink: 0;
  width: 192px;
}

.empty {
  padding: 12px 16px;
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
