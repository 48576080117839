<script setup lang="ts">
import IconArrowRight from '~icons/ph/arrow-right-bold'
import { isEqual } from 'lodash-es'
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed, reactive, ref, watch } from 'vue'
import {
  type CompanyBusinessReportFinanceTransitionRecordFrag,
  type CompanyBusinessReportFinancialFiguresAnnotation,
  type PreviousCompanyBusinessReportFrag
} from '@/graphql'
import { hasProp } from '@/support/Utils'
import { type Data } from './CompanyBusinessReportFormUpdate.vue'

interface Diff {
  basicCompanyInfo: DiffSection<{
    otherNotes: DiffDetailOfString
  }>
  financialFigures: DiffSection<{
    financialFiguresAnnotationId: DiffDetailOfNumber
    financialResultNotes: DiffDetailOfString
  }>
  ftAndBo: DiffSection<{
    financeTransition: DiffDetailOfString
    financeTransitionRecords: DiffDetailOfFinanceTransitionRecords
    overviewAndPerspective: DiffDetailOfString
  }>
}

interface DiffSection<Detail extends Record<string, DiffSectionDetail> = Record<string, DiffSectionDetail>> {
  title: string
  isSame: boolean
  isSelected?: boolean | 'indeterminate'
  details: Detail
}

type DiffSectionDetail = DiffDetail

type DiffDetail = DiffDetailOfString | DiffDetailOfNumber | DiffDetailOfFinanceTransitionRecords

interface DiffDetailBase {
  title: string
  enabled?: boolean
  template?: 'financeTransitionRecords'
  isSame: boolean
  isSelected: boolean
}

interface DiffDetailOfString extends DiffDetailBase {
  asIs: string | null
  asIsDisplayValue?: string | null
  toBe: string | null
  toBeDisplayValue?: string | null
}

interface DiffDetailOfNumber extends DiffDetailBase {
  asIs: number | null
  asIsDisplayValue?: number | string | null
  toBe: number | null
  toBeDisplayValue?: number | string | null
}

interface DiffDetailOfFinanceTransitionRecords extends DiffDetailBase {
  asIs: CompanyBusinessReportFinanceTransitionRecordFrag[]
  asIsDisplayValue?: CompanyBusinessReportFinanceTransitionRecordFrag[]
  toBe: CompanyBusinessReportFinanceTransitionRecordFrag[]
  toBeDisplayValue?: CompanyBusinessReportFinanceTransitionRecordFrag[]
}

export type NewDataFromCompanyBusinessReport = Partial<Pick<Data,
  | 'otherNotes'
  | 'financialFiguresAnnotationId'
  | 'financialResultNotes'
  | 'financeTransition'
  | 'financeTransitionRecords'
  | 'overviewAndPerspective'
>>

const props = defineProps<{
  currentData: Data
  companyBusinessReport: PreviousCompanyBusinessReportFrag
  copyData(newData: NewDataFromCompanyBusinessReport): void
  annotations: CompanyBusinessReportFinancialFiguresAnnotation[]
}>()

const emit = defineEmits<{
  cancel: []
  copied: []
}>()

const { t } = useTrans({
  en: {
    title: 'Copy from company business report data',
    lead: 'You are about to copy data from other company business report. Review below diffs and select changes to apply as needed.',
    asis: 'As-Is',
    tobe: 'To-Be',
    cancel: 'Cancel',
    apply_data: 'Apply data',
    i_company_information_label: 'Basic company information',
    i_others_label: 'Others',
    i_annual_financial_figures_label: 'Annual financial figures',
    i_annual_financial_figures_annotation_label: 'Annotation',
    i_annual_financial_figures_notes_label: 'Notes',
    i_finance_transition_and_business_overview: 'Finance and perspective',
    i_finance_transition: 'Finance transition',
    i_overview_and_perspective: 'Overview and perspective'
  },
  ja: {
    title: '他の報告書からコピーする',
    lead: '他の報告書をコピーしようとしています。以下を確認して適用する差分を選択してください。',
    asis: '現在',
    tobe: 'コピー後',
    cancel: 'キャンセル',
    apply_data: 'コピーする',
    i_company_information_label: '会社情報',
    i_others_label: 'その他',
    i_annual_financial_figures_label: '年度別財務数値',
    i_annual_financial_figures_annotation_label: '注釈',
    i_annual_financial_figures_notes_label: '補足事項',
    i_finance_transition_and_business_overview: 'ファイナンスの推移と事業見通し',
    i_finance_transition: 'ファイナンスの推移',
    i_overview_and_perspective: '事業の概況及び今後の事業見通し'
  }
})

type FinanceTransitionCopyType = 'old' | 'new' | 'unsupported'

const financeTransitionCopyType = computed<FinanceTransitionCopyType>(() => {
  const isFinanceTransitionRecordsSupportedInSourceReport = props.companyBusinessReport.financeTransitionRecords !== null
  const isFinanceTransitionRecordsSupportedInTargetReport = props.currentData.financeTransitionRecords !== null

  if (isFinanceTransitionRecordsSupportedInSourceReport && isFinanceTransitionRecordsSupportedInTargetReport) {
    return 'new'
  }
  if (!isFinanceTransitionRecordsSupportedInSourceReport && !isFinanceTransitionRecordsSupportedInTargetReport) {
    return 'old'
  }
  return 'unsupported'
})

const diff = reactive<Diff>({
  basicCompanyInfo: {
    title: t.i_company_information_label,
    isSame: (props.currentData.otherNotes || null) === (props.companyBusinessReport.otherNotes || null),
    isSelected: (props.currentData.otherNotes || null) !== (props.companyBusinessReport.otherNotes || null),
    details: {
      otherNotes: {
        title: t.i_others_label,
        isSame: (props.currentData.otherNotes || null) === (props.companyBusinessReport.otherNotes || null),
        isSelected: (props.currentData.otherNotes || null) !== (props.companyBusinessReport.otherNotes || null),
        asIs: props.currentData.otherNotes || null,
        toBe: props.companyBusinessReport.otherNotes || null
      }
    }
  },
  financialFigures: {
    title: t.i_annual_financial_figures_label,
    isSame: (
      props.currentData.financialFiguresAnnotationId === props.companyBusinessReport.financialFiguresAnnotationId
      && (props.currentData.financialResultNotes || null) === (props.companyBusinessReport.financialResultNotes || null)
    ),
    isSelected: (
      props.currentData.financialFiguresAnnotationId !== props.companyBusinessReport.financialFiguresAnnotationId
      && (props.currentData.financialResultNotes || null) !== (props.companyBusinessReport.financialResultNotes || null)
    ),
    details: {
      financialFiguresAnnotationId: {
        title: t.i_annual_financial_figures_annotation_label,
        isSame: props.currentData.financialFiguresAnnotationId === props.companyBusinessReport.financialFiguresAnnotationId,
        isSelected: props.currentData.financialFiguresAnnotationId !== props.companyBusinessReport.financialFiguresAnnotationId,
        asIs: props.currentData.financialFiguresAnnotationId ?? null,
        asIsDisplayValue: props.annotations.find(({ id }) => id === props.currentData.financialFiguresAnnotationId)?.name ?? null,
        toBe: props.companyBusinessReport.financialFiguresAnnotationId ?? null,
        toBeDisplayValue: props.annotations.find(({ id }) => id === props.companyBusinessReport.financialFiguresAnnotationId)?.name ?? null
      },
      financialResultNotes: {
        title: t.i_annual_financial_figures_notes_label,
        isSame: (props.currentData.financialResultNotes || null) === (props.companyBusinessReport.financialResultNotes || null),
        isSelected: (props.currentData.financialResultNotes || null) !== (props.companyBusinessReport.financialResultNotes || null),
        asIs: props.currentData.financialResultNotes || null,
        toBe: props.companyBusinessReport.financialResultNotes || null
      }
    }
  },
  ftAndBo: {
    title: t.i_finance_transition_and_business_overview,
    isSame: (
      (props.currentData.financeTransition || null) === (props.companyBusinessReport.financeTransition || null)
      && (props.currentData.overviewAndPerspective || null) === (props.companyBusinessReport.overviewAndPerspective || null)
    ),
    isSelected: (props.currentData.financeTransition || null) !== (props.companyBusinessReport.financeTransition || null)
      && (props.currentData.overviewAndPerspective || null) !== (props.companyBusinessReport.overviewAndPerspective || null),
    details: {
      financeTransition: {
        title: t.i_finance_transition,
        enabled: financeTransitionCopyType.value === 'old',
        isSame: (props.currentData.financeTransition || null) === (props.companyBusinessReport.financeTransition || null),
        isSelected: (props.currentData.financeTransition || null) !== (props.companyBusinessReport.financeTransition || null),
        asIs: props.currentData.financeTransition || null,
        toBe: props.companyBusinessReport.financeTransition || null
      },
      financeTransitionRecords: initializeDiffDetailFinanceTransitionRecords(),
      overviewAndPerspective: {
        title: t.i_overview_and_perspective,
        isSame: (props.currentData.overviewAndPerspective || null) === (props.companyBusinessReport.overviewAndPerspective || null),
        isSelected: (props.currentData.overviewAndPerspective || null) !== (props.companyBusinessReport.overviewAndPerspective || null),
        asIs: props.currentData.overviewAndPerspective || null,
        toBe: props.companyBusinessReport.overviewAndPerspective || null
      }
    }
  }
})

function initializeDiffDetailFinanceTransitionRecords(): DiffDetailOfFinanceTransitionRecords {
  if (financeTransitionCopyType.value !== 'new') {
    return {
      title: t.i_finance_transition,
      enabled: false,
      template: 'financeTransitionRecords',
      isSame: false,
      isSelected: false,
      asIs: [],
      asIsDisplayValue: [],
      toBe: [],
      toBeDisplayValue: []
    }
  }

  const asIs = props.currentData.financeTransitionRecords ?? [{ rows: [] }] as CompanyBusinessReportFinanceTransitionRecordFrag[]
  const toBe = props.companyBusinessReport.financeTransitionRecords ?? [{ rows: [] }] as CompanyBusinessReportFinanceTransitionRecordFrag[]

  const isAsIsAndToBeSame = isEqual(asIs, toBe)

  return {
    title: t.i_finance_transition,
    enabled: true,
    template: 'financeTransitionRecords',
    isSame: isAsIsAndToBeSame,
    isSelected: !isAsIsAndToBeSame,
    asIs,
    asIsDisplayValue: asIs,
    toBe,
    toBeDisplayValue: toBe
  }
}

// Update state after initialization
if (diff.ftAndBo.details.financeTransition.enabled) {
  diff.ftAndBo.isSame = diff.ftAndBo.details.financeTransition.isSame && diff.ftAndBo.details.overviewAndPerspective.isSame
}

if (diff.ftAndBo.details.financeTransitionRecords.enabled) {
  diff.ftAndBo.isSame = diff.ftAndBo.details.financeTransitionRecords.isSame && diff.ftAndBo.details.overviewAndPerspective.isSame
}

const isAllSame = computed(() => diff.basicCompanyInfo.isSame && diff.financialFigures.isSame && diff.ftAndBo.isSame)

const isAnySelected = ref(false)

for (const key in diff) {
  if (!hasProp(diff, key)) { continue }
  const d: DiffSection = diff[key]

  watch(() => d.isSelected, () => {
    const isSelected = d.isSelected

    if (typeof isSelected === 'boolean') {
      Object.values(d.details).forEach((detail) => {
        if (!detail.isSame) {
          detail.isSelected = isSelected
        }
      })
    }
  })

  watch(() => d.details, () => {
    const selectableDetail = Object.values(d.details).filter((detail) => !detail.isSame)

    if (selectableDetail.length && selectableDetail.every((detail) => detail.isSelected)) {
      d.isSelected = true
    } else if (selectableDetail.some((detail) => detail.isSelected)) {
      d.isSelected = 'indeterminate'
    } else {
      d.isSelected = false
    }

    isAnySelected.value = Object.values(diff)
      .flatMap((section: DiffSection) => Object.values(section.details))
      .some((detail) => detail.isSelected)
  }, { deep: true, immediate: true })
}

const loadingCopy = ref(false)

function copyCompanyBusinessReportData() {
  loadingCopy.value = true

  const newData: NewDataFromCompanyBusinessReport = {}

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.otherNotes.isSelected) {
    newData.otherNotes = diff.basicCompanyInfo.details.otherNotes.toBe
  }

  if (diff.financialFigures.isSelected === true || diff.financialFigures.details.financialFiguresAnnotationId.isSelected) {
    newData.financialFiguresAnnotationId = diff.financialFigures.details.financialFiguresAnnotationId.toBe
  }

  if (diff.financialFigures.isSelected === true || diff.financialFigures.details.financialResultNotes.isSelected) {
    newData.financialResultNotes = diff.financialFigures.details.financialResultNotes.toBe
  }

  if (diff.ftAndBo.isSelected === true || diff.ftAndBo.details.financeTransition.isSelected) {
    if (diff.ftAndBo.details.financeTransition.enabled) {
      newData.financeTransition = diff.ftAndBo.details.financeTransition.toBe
    }
  }

  if (diff.ftAndBo.isSelected === true || diff.ftAndBo.details.financeTransitionRecords.isSelected) {
    if (diff.ftAndBo.details.financeTransitionRecords.enabled) {
      newData.financeTransitionRecords = diff.ftAndBo.details.financeTransitionRecords.toBe
    }
  }

  if (diff.ftAndBo.isSelected === true || diff.ftAndBo.details.overviewAndPerspective.isSelected) {
    newData.overviewAndPerspective = diff.ftAndBo.details.overviewAndPerspective.toBe
  }

  props.copyData(newData)
  emit('copied')

  loadingCopy.value = false
}
</script>

<template>
  <SCard
    class="CopmanyBusinessReportCopyFromOther"
    size="xxlarge"
  >
    <SCardBlock class="s-p-24">
      <SHead>
        <SHeadTitle>{{ t.title }}</SHeadTitle>
        <SHeadLead class="lead">{{ t.lead }}</SHeadLead>
      </SHead>
    </SCardBlock>
    <SCardBlock>
      <SGrid v-for="diffSection in diff" :key="diffSection.title" gap="1" class="diff-viewer">
        <SGrid
          cols="24"
          gap="1"
          class="diff-viewer-header"
          :class="{ 'no-change': diffSection.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="diffSection.isSelected"
              :text="diffSection.title"
              :disabled="diffSection.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.asis }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell" />
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.tobe }}
          </SGridItem>
        </SGrid>
        <template v-for="detail in Object.values(diffSection.details)">
          <template v-if="detail.enabled !== false">
            <SGrid
              v-if="detail.template === 'financeTransitionRecords'"
              :key="detail.title + detail.template"
              cols="24"
              gap="1"
              class="diff-viewer-row"
              :class="{ 'no-change': detail.isSame }"
            >
              <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
                <SInputCheckbox v-model="detail.isSelected" :text="detail.title" :disabled="detail.isSame" />
              </SGridItem>
              <SGridItem span="8" class="diff-viwer-cell content">
                <div class="ftr">
                  <div v-for="(asIsRecord, recordIndex) in detail.asIs" :key="recordIndex" class="ftr-box">
                    <div v-for="(row, rowIndex) in asIsRecord.rows" :key="rowIndex" class="ftr-row">
                      <div class="ftr-key">{{ row.key }}</div>
                      <div class="ftr-value">{{ row.value }}</div>
                    </div>
                  </div>
                </div>
              </SGridItem>
              <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
                <IconArrowRight />
              </SGridItem>
              <SGridItem span="8" class="diff-viwer-cell content">
                <div class="ftr">
                  <div v-for="(toBeRecord, recordIndex) in detail.toBe" :key="recordIndex" class="ftr-box">
                    <div v-for="(row, rowIndex) in toBeRecord.rows" :key="rowIndex" class="ftr-row">
                      <div class="ftr-key">{{ row.key }}</div>
                      <div class="ftr-value">{{ row.value }}</div>
                    </div>
                  </div>
                </div>
              </SGridItem>
            </SGrid>
            <SGrid
              v-else
              :key="detail.title"
              cols="24"
              gap="1"
              class="diff-viewer-row"
              :class="{ 'no-change': detail.isSame }"
            >
              <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
                <SInputCheckbox v-model="detail.isSelected" :text="detail.title" :disabled="detail.isSame" />
              </SGridItem>
              <SGridItem span="8" class="diff-viwer-cell content">
                {{ detail.asIsDisplayValue ?? detail.asIs }}
              </SGridItem>
              <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
                <IconArrowRight />
              </SGridItem>
              <SGridItem span="8" class="diff-viwer-cell content">
                {{ detail.toBeDisplayValue ?? detail.toBe }}
              </SGridItem>
            </SGrid>
          </template>
        </template>
      </SGrid>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-p-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loadingCopy"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.apply_data"
            :loading="loadingCopy"
            :disabled="isAllSame || !isAnySelected"
            @click="copyCompanyBusinessReportData"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.lead {
  max-width: 640px;
}

.diff-viewer {
  border-top: 1px solid var(--c-gutter);
  font-size: 14px;
  background-color: var(--c-gutter);

  &:first-child {
    border-top: 0;
  }
}

.no-change {
  &.diff-viewer-header,
  &.diff-viewer-row {
    color: var(--c-text-3);
  }
}

.no-change-only-values {
  & .diff-viwer-cell:not(.diff-viewer-cell-title) {
    color: var(--c-text-3);
  }
}

.diff-viwer-cell {
  padding: 12px 16px;
  background-color: var(--c-bg-elv-3);

  .diff-viewer-header & {
    background-color: var(--c-bg-elv-4);
  }

  &.content {
    white-space: pre-wrap;
  }

  &.diff-viwer-arrow {
    display: flex;
    justify-content: center;
    padding: 14px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.diff-viewer-cell-title {
  padding: 8px 16px 8px 24px;

  :deep(.diff-viewer-header & .text) {
    font-weight: 600;
  }
}

.ftr {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  padding: 8px;
  background-color: var(--c-gutter);
}

.ftr-box {
  display: flex;
  flex-direction: column;
  gap: 1px;
  border: 1px solid var(--c-divider);
  border-radius: 3px;
}

.ftr-row {
  display: flex;
  gap: 1px;
}

.ftr-key,
.ftr-value {
  padding: 10px 12px;
  line-height: 20px;
  font-size: 12px;
  background-color: var(--c-bg-elv-3);
}

.ftr-key {
  min-width: 112px;
}

.ftr-value {
  flex-grow: 1;
}

:deep(.SInputCheckbox .box) {
  min-width: 16px;
}
</style>
