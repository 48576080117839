import day from 'dayjs'
import { keyBy } from 'lodash-es'
import { type ProposalFrag, type ProposalViewExportStatusFrag } from '@/graphql'
import { defineOps } from './Ops'

export const useProposalViewExportStatusOps = defineOps(() => {
  function exportedProposals(pves: ProposalViewExportStatusFrag[], proposals: ProposalFrag[]): ProposalFrag[] {
    const exportStatusDict = keyBy(pves, 'fundGroupId')

    return proposals.filter((p) => {
      const exportedAt = exportStatusDict[p.fundGroupId]?.lastExportedAt
      return exportedAt ? day(exportedAt).isAfter(p.createdAt) : false
    })
  }

  function isSomeProposalExported(pves: ProposalViewExportStatusFrag[], proposals: ProposalFrag[]): boolean {
    const exportStatusDict = keyBy(pves, 'fundGroupId')

    return proposals.some((p) => {
      const exportedAt = exportStatusDict[p.fundGroupId]?.lastExportedAt
      return exportedAt ? day(exportedAt).isAfter(p.createdAt) : false
    })
  }

  return {
    exportedProposals,
    isSomeProposalExported
  }
})
