<script setup lang="ts">
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { computedArray } from 'sefirot/composables/Utils'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, requiredIf } from 'sefirot/validation/rules'
import { BallotVoteChoice, type BallotVoterFrag } from '@/graphql'
import { useBallotVoterOps } from '@/composables/ops/BallotOps'

const props = defineProps<{
  voter: BallotVoterFrag
  choice: BallotVoteChoice
  condition: string | null
  comment: string | null
}>()

defineEmits<{
  (e: 'update:choice', value: BallotVoteChoice): void
  (e: 'update:condition', value: string | null): void
  (e: 'update:comment', value: string | null): void
}>()

const ballotVoterOps = useBallotVoterOps()

const { validation } = useValidation(() => ({
  condition: props.condition,
  comment: props.comment
}), {
  condition: {
    requiredIf: requiredIf(() => props.choice === BallotVoteChoice.ConditionallyFor),
    maxLength: maxLength(500)
  },
  comment: {
    maxLength: maxLength(500)
  }
})

const choiceOptions = computedArray((arr) => {
  arr.push({ label: 'For', value: BallotVoteChoice.For })
  arr.push({ label: 'Cond. For', value: BallotVoteChoice.ConditionallyFor })
  arr.push({ label: 'Against', value: BallotVoteChoice.Against })

  if (props.voter.canReject) {
    arr.push({ label: 'Reject', value: BallotVoteChoice.Reject })
  }
})
</script>

<template>
  <div class="DealInputEventVotesItem">
    <DFormGrid class="grid">
      <DFormGridItem span="3">
        <SInputText
          label="Voter"
          :model-value="ballotVoterOps.name(voter)"
          disabled
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputRadios
          label="Vote"
          :options="choiceOptions"
          :model-value="choice"
          @update:model-value="(v) => $emit('update:choice', v)"
        />
      </DFormGridItem>
      <DFormGridItem v-if="choice === BallotVoteChoice.ConditionallyFor" span="6">
        <SInputTextarea
          label="Condition"
          placeholder="Describe the conditions"
          :model-value="condition"
          @update:model-value="(v) => $emit('update:condition', v)"
          :validation="validation.condition"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Comment"
          placeholder="Add comments if there’re any"
          :model-value="comment"
          @update:model-value="(v) => $emit('update:comment', v)"
          :validation="validation.comment"
        />
      </DFormGridItem>
    </DFormGrid>
  </div>
</template>

<style scoped lang="postcss">
.DealInputEventVotesItem {
  border-top: 1px solid var(--c-divider-2);
  padding-top: 24px;
}
</style>
