<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type CompanyPortfolioDataWithFinancialFiguresFrag } from '@/graphql'
import { useCanUpdateCompanyPortfolioData } from '@/composables/policies/CompanyPolicy'
import CompanyFinancialFiguresNotes from './CompanyFinancialFiguresNotes.vue'
import CompanyFinancialResultGrid from './CompanyFinancialResultGrid.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioDataWithFinancialFiguresFrag
}>()

const emit = defineEmits<{
  created: []
  updated: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    no_item: 'No financial results have been entered yet.'
  },
  ja: {
    no_item: '財務数値がまだ入力されていません。'
  }
})

const canUpdate = useCanUpdateCompanyPortfolioData(props.portfolioData)

const hasResults = computed(() => !!props.portfolioData.financialFigures?.results?.length)
</script>

<template>
  <div class="CompanyFinancialFiguresDesc">
    <template v-if="canUpdate.ok || hasResults">
      <CompanyFinancialResultGrid
        :portfolio-data="portfolioData"
        @created="emit('created')"
        @updated="emit('updated')"
        @deleted="emit('deleted')"
      />
    </template>
    <p v-else class="text-box">{{ t.no_item }}</p>
    <div class="notes">
      <CompanyFinancialFiguresNotes
        :portfolio-data="portfolioData"
        @updated="emit('updated')"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.text-box {
  max-width: 512px;
  padding: 24px;
  border: 1px solid var(--c-divider-light);
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: var(--c-text-2);
}

.notes {
  padding-top: 16px;
}
</style>
