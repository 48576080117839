<script setup lang="ts">
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { useState } from '@/composables/State'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import CompanyPortfolioDataFormUpdatePromoter from '@/components/company/CompanyPortfolioDataFormUpdatePromoter.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const promoters = computed(() => props.portfolioData.promoters)
const inboundOrOutboundName = computed(() => props.portfolioData.promotedInboundOrOutbound?.name)
const promotedSourceName = computed(() => props.portfolioData.promotedSource?.name)
const promotedBackground = computed(() => props.portfolioData.promotedBackground)

const { data: modal, set, clear } = useState<'update'>()
const { canUpdatePromoter } = useCompanyPortfolioDataPolicy(props.portfolioData)
const actions = computedArrayWhen(canUpdatePromoter, (items) => {
  items.push({ icon: 'edit', onClick: () => set('update') })
})
</script>

<template>
  <DCard title="Promoter" :actions="actions">
    <DCardInfo>
      <DCardInfoItem>
        <DCardInfoLabel text="Promoters" />
        <DCardInfoUsers :users="promoters" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Inbound / Outbound" />
        <DCardInfoText :text="inboundOrOutboundName" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Promoted source" />
        <DCardInfoText :text="promotedSourceName" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Contact background" />
        <DCardInfoText :text="promotedBackground" />
      </DCardInfoItem>
    </DCardInfo>

    <SModal :open="modal === 'update'">
      <CompanyPortfolioDataFormUpdatePromoter
        :portfolio-data="portfolioData"
        @updated="clear(() => emit('updated'))"
        @cancel="clear(() => emit('cancel'))"
      />
    </SModal>
  </DCard>
</template>
