<script setup lang="ts">
import { useMoneyOps } from '@/composables/ops/MoneyOps'

defineProps<{
  money?: any
}>()

const moneyOps = useMoneyOps()
</script>

<template>
  <div class="DCardGridMoney">
    <div class="text" v-if="money">
      <span class="symbol">{{ money.currency.symbol }}</span>
      <span class="value">{{ moneyOps.formatValue(money) }}</span>
      <span class="name">{{ money.currency.name }}</span>
    </div>

    <div v-else class="empty">—</div>
  </div>
</template>

<style scoped lang="postcss">
.DCardGridMoney {
  padding: 2px 0;
  line-height: 20px;
  font-size: 14px;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.symbol {
  display: inline-block;
  margin-right: 4px;
}

.name {
  display: inline-block;
  margin-left: 4px;
  color: var(--c-text-2);
}

.empty {
  color: var(--c-text-3);
}
</style>
