<template>
  <SCard size="medium" class="DashboardDdTeamUpcomingOpportunityCatalogAbout">
    <SCardBlock class="s-p-32">
      <SContent>
        <STrans lang="en">
          <h2>About</h2>
          <p>This table lists Opportunities where a DD Kickoff might take place soon. The filtering criteria are as follows:</p>
          <ul>
            <li>The Opportunity's status is not “Closed”.</li>
            <li>An Initial Review Meeting has been requested.</li>
            <li>The DD has not been kicked off.</li>
          </ul>
          <p>The sorting order is by the date of the Initial Review Meeting request in descending order.</p>
        </STrans>
        <STrans lang="ja">
          <h2>ヘルプ</h2>
          <p>本テーブルは、近くDD Kickoffが行われるかもしれないOpportunityを一覧しています。フィルター条件は以下の通りです。</p>
          <ul>
            <li>Opportunityのステータスが「Closed」ではない。</li>
            <li>Initial Review Meetingに申し込まれている。</li>
            <li>DDがKickoffされていない。</li>
          </ul>
          <p>並び順は、Initial Review Meeting申請日の降順です。</p>
        </STrans>
      </SContent>
    </SCardBlock>
  </SCard>
</template>
