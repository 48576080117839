import { type ComputedRef, type UnwrapRef, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export interface SearchController {
  type: ComputedRef<typeof SEARCH_TYPES[number] | null>
  query: ComputedRef<string | null>
  initialize(): void
  search(query: RawSearchControllerQuery): void
  setType(type: RawSearchControllerType): void
}

type RawSearchControllerType = UnwrapRef<SearchController['type']>
type RawSearchControllerValidType = Exclude<RawSearchControllerType, null>
type RawSearchControllerQuery = UnwrapRef<SearchController['query']>

const SEARCH_TYPES = [
  'companies',
  'action-notes',
  'opportunities',
  'deals'
] as const

export const SEARCH_TYPE_SET = new Set(SEARCH_TYPES)

export const DEFAULT_SEARCH_TYPE = 'companies'

function isTypeValid(type: string | null): type is RawSearchControllerValidType {
  if (type === null) {
    return false
  }

  return SEARCH_TYPE_SET.has(type as RawSearchControllerValidType)
}

export function useSearch(): SearchController {
  const router = useRouter()
  const route = useRoute()
  const type = computed(() => route.query.type as RawSearchControllerType || null)
  const query = computed(() => route.query.q as string || null)

  function initialize(): void {
    (typeof type.value === 'string' && isTypeValid(type.value))
      ? setType(type.value)
      : setType(DEFAULT_SEARCH_TYPE)
  }

  function search(query: RawSearchControllerQuery): void {
    route.path === '/search' ? pushAndSearch(query) : jumpAndSearch(query)
  }

  function jumpAndSearch(query: RawSearchControllerQuery) {
    router.push({
      path: '/search',
      query: { type: type.value, q: query }
    })
  }

  function pushAndSearch(query: RawSearchControllerQuery) {
    router.push({
      path: route.path,
      query: { type: type.value, q: query }
    })
  }

  function setType(type: RawSearchControllerType): void {
    router.push({
      path: route.path,
      query: { q: query.value, type }
    })
  }

  return {
    type,
    query,
    initialize,
    search,
    setType
  }
}
