<script setup lang="ts">
import IconPlusCircle from '~icons/ph/plus-circle'
import SButton from 'sefirot/components/SButton.vue'
import SInputBase from 'sefirot/components/SInputBase.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import CompanySupportLogInputMembersItem, { type Data as ItemData } from './CompanySupportLogInputMembersItem.vue'

export interface Data {
  userId: number | null
  weight: number | null
}

const model = defineModel<ItemData[]>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Members *',
    member: 'Member',
    weight: 'Weight',
    add: 'Add member'
  },
  ja: {
    label: 'メンバー *',
    member: 'メンバー',
    weight: '実績ウェイト',
    add: 'メンバーを追加'
  }
})

const { validation } = useValidation(() => ({
  model: model.value
}), {
  model: {
    required: required()
  }
})
</script>

<template>
  <div class="CompanySupportLogInputMembers">
    <SInputBase
      class="small"
      :label="t.label"
      :validation="validation.model"
    >
      <SCard>
        <SCardBlock class="columns">
          <div class="column">{{ t.member }}</div>
          <div class="column">{{ t.weight }}</div>
        </SCardBlock>
        <SCardBlock v-for="_, i in model" :key="i" class="item">
          <CompanySupportLogInputMembersItem
            :can-delete="model.length > 1"
            v-model="model[i]"
            @delete="model.splice(i, 1)"
          />
        </SCardBlock>
        <SCardBlock class="item">
          <SButton
            type="text"
            mode="mute"
            :icon="IconPlusCircle"
            :label="t.add"
            @click="model.push({ userId: null, weight: null })"
          />
        </SCardBlock>
      </SCard>
    </SInputBase>
  </div>
</template>

<style scoped lang="postcss">
.columns {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 8px;
  padding: 0 8px;
  height: 40px;
}

.column {
  display: flex;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.item {
  padding: 8px;
}
</style>
