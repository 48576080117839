import { useTrans } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import {
  type SurveyFrag,
  type SurveyProposalFrag,
  type SurveyResponseFrag,
  SurveyStatus
} from '@/graphql'
import { type ValueOf } from '@/support/Types'
import { defineOps } from './Ops'

export const useSurveyOps = defineOps(() => {
  const { t: statusDict } = useTrans({
    en: {
      [SurveyStatus.Pending]: 'Pending',
      [SurveyStatus.Confirmed]: 'Confirmed'
    },
    ja: {
      [SurveyStatus.Pending]: '回答待ち',
      [SurveyStatus.Confirmed]: '回答受領済み'
    }
  })

  const statusModeDict = {
    [SurveyStatus.Pending]: 'mute',
    [SurveyStatus.Confirmed]: 'success'
  } as const

  function displayId(survey: SurveyFrag): string {
    return `SRV-${survey.id.toString().padStart(6, '0')}`
  }

  function statusText(survey: SurveyFrag): string {
    return statusDict[survey.status]
  }

  function statusMode(survey: SurveyFrag): ValueOf<typeof statusModeDict> {
    return statusModeDict[survey.status]
  }

  function createdAt(survey: SurveyFrag): Day {
    return day(survey.createdAt)
  }

  function updatedAt(survey: SurveyFrag): Day {
    return day(survey.updatedAt)
  }

  function path(survey: SurveyFrag): string {
    return `/surveys/${survey.id}`
  }

  function publicPath(survey: SurveyFrag): string {
    return `${import.meta.env.PUBLIC_BASE_URL}/surveys/${survey.hash}`
  }

  function createStatusOptions() {
    return [
      { label: statusDict[SurveyStatus.Pending], value: SurveyStatus.Pending },
      { label: statusDict[SurveyStatus.Confirmed], value: SurveyStatus.Confirmed }
    ]
  }

  return {
    displayId,
    statusText,
    statusMode,
    createdAt,
    updatedAt,
    path,
    publicPath,
    createStatusOptions
  }
})

export const useSurveyProposalOps = defineOps(() => {
  function createdAt(survey: SurveyProposalFrag): Day {
    return day(survey.createdAt)
  }

  function updatedAt(survey: SurveyProposalFrag): Day {
    return day(survey.updatedAt)
  }

  return {
    createdAt,
    updatedAt
  }
})

export const useSurveyResponseOps = defineOps(() => {
  function createdAt(survey: SurveyResponseFrag): Day {
    return day(survey.createdAt)
  }

  return {
    createdAt
  }
})
