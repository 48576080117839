<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconTrash from '~icons/ph/trash-bold'
import { computed } from 'vue'
import { type CompanyFinancialResultFrag, type CompanyPortfolioDataWithFinancialFiguresFrag, type UserFrag } from '@/graphql'
import { format } from '@/support/Num'
import { useState } from '@/composables/State'
import { useCompanyFinancialResultOps } from '@/composables/ops/CompanyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanUpdateCompanyPortfolioData } from '@/composables/policies/CompanyPolicy'
import CompanyFormDeleteFinancialResult from './CompanyFormDeleteFinancialResult.vue'
import CompanyFormUpdateFinancialResult from './CompanyFormUpdateFinancialResult.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioDataWithFinancialFiguresFrag
  financialResult: CompanyFinancialResultFrag
}>()

const emit = defineEmits<{
  updated: []
  deleted: []
}>()

const canUpdate = useCanUpdateCompanyPortfolioData(props.portfolioData)

const userOps = useUserOps()
const financialResultOps = useCompanyFinancialResultOps()

const period = computed(() => props.financialResult.period)

const bodyCells = computed(() => {
  const {
    currency,
    netSales,
    ordinaryIncome,
    netIncome,
    totalAssets,
    capital,
    netAssets,
    updatedBy
  } = props.financialResult

  const currencyCell = {
    text: `${currency?.symbol ?? '-'} ${currency?.name ?? ''}`.trim(),
    class: currency?.symbol && currency?.name
      ? ''
      : 'mute'
  }
  const figureCells = [
    netSales,
    ordinaryIncome,
    netIncome,
    totalAssets,
    capital,
    netAssets
  ].map((value) => ({
    text: value ? format(Number(value)) : '—',
    class: (value === null)
      ? 'mute'
      : Number(value) === 0
        ? 'mute'
        : Number(value) > 0 ? 'success' : 'danger'
  }))
  const updateByCell = {
    text: userOps.name(updatedBy as UserFrag),
    class: ''
  }
  const updateAtCell = {
    text: financialResultOps.updatedAt(props.financialResult).format('YYYY-MM-DD HH:mm'),
    class: ''
  }

  return [currencyCell, ...figureCells, updateByCell, updateAtCell]
})

const { data: modal, set, clear } = useState<'update' | 'delete'>()
</script>

<template>
  <div class="CompanyFinancialResultItem">
    <div class="header">
      <p class="header-text">{{ period ?? '2023 / 01' }}</p>
      <div v-if="canUpdate.ok" class="header-actions">
        <button class="header-action edit" @click="set('update')">
          <IconNotePencil class="header-action-icon" />
        </button>
        <button class="header-action delete" @click="set('delete')">
          <IconTrash class="header-action-icon" />
        </button>
      </div>
    </div>
    <ul class="body">
      <li
        v-for="(bodyCell, index) in bodyCells"
        :key="`${bodyCell.text}${bodyCell.class}${index}`"
        class="body-text"
        :class="bodyCell.class"
      >
        {{ bodyCell.text }}
      </li>
    </ul>

    <SModal :open="modal === 'update'">
      <CompanyFormUpdateFinancialResult
        :financial-result="props.financialResult"
        @updated="clear(() => emit('updated'))"
        @cancel="clear"
      />
    </SModal>

    <SModal :open="modal === 'delete'">
      <CompanyFormDeleteFinancialResult
        :financial-result="props.financialResult"
        @deleted="clear(() => emit('deleted'))"
        @cancel="clear"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanyFinancialResultItem {
  flex: none;
  width: 184px;
  border-right: 1px solid var(--c-divider-2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 32px;
  padding: 4px 4px 4px 12px;
  border-bottom: 1px solid var(--c-divider-2);
  background-color: var(--c-soft);
}

.header-text {
  font-size: 14px;
  font-weight: 500;
}

.header-actions {
  display: flex;
}

.header-action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  color: var(--c-text-2);
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--c-mute);
  }
}

.header-action-icon {
  width: 14px;
  height: 14px;
}

.body {
  flex-grow: 1;
  overflow-x: auto;
}

.body-text {
  padding: 0 12px;
  text-align: right;
  line-height: 40px;
  font-size: 14px;

  & + & {
    border-top: 1px solid var(--c-divider-2);
  }

  &.mute    { color: var(--c-text-3); }
  &.success { color: var(--c-success-text); }
  &.danger  { color: var(--c-danger-text); }
}
</style>
