<script setup lang="ts">
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputYMD from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxValue, ymd } from 'sefirot/validation/rules'
import { type DealWithExitPlansFrag, type DealWithIdFrag } from '@/graphql'
import { yearMonthFromYmdOrNull } from '@/support/Day'
import { useDealOps } from '@/composables/ops/DealOps'
import { useUpdateDealExitPlan } from '@/composables/repos/DealRepo'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithExitPlansFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { execute, loading } = useUpdateDealExitPlan()
const dealOps = useDealOps()

const { data } = useData({
  estimatedExitMethods: props.deal.estimatedExitMethods ?? [],
  estimatedExitTime: dealOps.estimatedExitTimeAsYmd(props.deal),
  estimatedROI: props.deal.estimatedROI ?? null,
  estimatedIRR: props.deal.estimatedIRR ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  estimatedExitTime: { ymd: ymd(['y', 'm']) },
  estimatedROI: { decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  estimatedIRR: { decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) }
})

const exitMethodOptions = dealOps.exitMethodTextEntries().map(([value, label]) => {
  return { label, value }
})

async function update() {
  if (await validateAndNotify()) {
    await execute(props.deal.id, {
      estimatedExitMethods: data.value.estimatedExitMethods,
      estimatedExitTime: yearMonthFromYmdOrNull(data.value.estimatedExitTime),
      estimatedROI: data.value.estimatedROI,
      estimatedIRR: data.value.estimatedIRR
    })

    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update exit plan</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="3">
        <SInputDropdown
          label="Exit methods"
          placeholder="Select exit methods"
          :options="exitMethodOptions"
          nullable
          v-model="data.estimatedExitMethods"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputYMD
          label="Exit time"
          no-date
          v-model="data.estimatedExitTime"
          :validation="validation.estimatedExitTime"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          label="Est. ROI"
          placeholder="17.6"
          v-model="data.estimatedROI"
          :validation="validation.estimatedROI"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          label="Est. IRR"
          placeholder="22.5"
          v-model="data.estimatedIRR"
          :validation="validation.estimatedIRR"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
