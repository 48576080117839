import { type Mutation, useMutation } from 'sefirot/composables/Api'
import { type OpportunityWithIdFrag } from '@/graphql'
import { OpportunityRequest } from '@/requests/OpportunityRequest'

export type LinkOportunitySurvey = Mutation<OpportunityWithIdFrag, [id: number, surveyId: number | null]>

export function useUpdateOportunitySurvey(): LinkOportunitySurvey {
  return useMutation(async (_, id, surveyId) => {
    const res = await new OpportunityRequest().updateSurvey(id, surveyId)

    return res.data.opportunity
  })
}
