import { type Day, type Input, day as dayjs } from 'sefirot/support/Day'

export type { Day }
export { dayjs as day }

type Constructor<T extends Model> = new (...args: any[]) => T

export abstract class Model {
  day(input: Input): Day
  day(input: undefined | null): null
  day(input: Input | null | undefined): Day | null {
    return input == null ? null : dayjs(input)
  }

  hasOne<T extends Model>(model: Constructor<T>): null
  hasOne<T extends Model, D extends Record<string, any>>(model: Constructor<T>, data: D): T
  hasOne<T extends Model>(model: Constructor<T>, data: null): null
  hasOne<T extends Model, D extends Record<string, any>>(
    model: Constructor<T>,
    data?: D | null
  ): T | null {
    return data ? new model(data) : null
  }

  hasMany<T extends Model>(
    model: Constructor<T>,
    data?: Record<string, any>[]
  ): T[] {
    return data ? data?.map((d) => new model(d)) : []
  }
}
