<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { DealReportStatus } from '@/graphql'
import { type PolicyResponseRaw } from '@/composables/Policy'

const props = defineProps<{
  reportStatus: DealReportStatus
  canSave: PolicyResponseRaw<any>
  canComplete: PolicyResponseRaw<any>
  canIncomplete: PolicyResponseRaw<any>
  saving: boolean
  exporting: boolean
  completing: boolean
  incompleting: boolean
}>()

defineEmits<{
  (e: 'save'): void
  (e: 'export'): void
  (e: 'complete'): void
  (e: 'incomplete'): void
}>()

const { t } = useTrans({
  en: {
    save: 'Save draft',
    export: 'Export Word',
    complete: 'Complete',
    incomplete: 'Convert to draft'
  },
  ja: {
    save: '下書き保存',
    export: 'Wordを出力',
    complete: 'コンプリート',
    incomplete: 'ドラフトに戻す'
  }
})

const connecting = computed(() => {
  return (
    props.saving
    || props.exporting
    || props.completing
  )
})
</script>

<template>
  <DCardFooter v-if="!canSave.is('no-auth')">
    <DCardFooterActions>
      <DCardFooterAction
        :label="t.save"
        :loading="saving"
        :disabled="!canSave.ok || (!saving && connecting)"
        :tooltip="{ text: canSave.message }"
        @click="$emit('save')"
      />
      <DCardFooterAction
        :label="t.export"
        :loading="exporting"
        :disabled="!exporting && connecting"
        @click="$emit('export')"
      />
      <DCardFooterAction
        v-if="reportStatus === DealReportStatus.Draft"
        mode="success"
        :label="t.complete"
        :loading="completing"
        :disabled="!canComplete.ok || (!completing && connecting)"
        :tooltip="{ text: canComplete.message }"
        @click="$emit('complete')"
      />
      <DCardFooterAction
        v-else
        :label="t.incomplete"
        :loading="incompleting"
        :disabled="!canIncomplete.ok"
        :tooltip="{ text: canIncomplete.message }"
        @click="$emit('incomplete')"
      />
    </DCardFooterActions>
  </DCardFooter>
</template>
