import { orderBy } from 'lodash-es'
import { type Get, type Query as SQuery, useGet, useQuery as useSQuery } from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type UpdateUserFundAssignmentsInput,
  type UserActivityCondition,
  type UserActivityOverview,
  type UserAndRolesAndTagsFrag,
  type UserAndRolesFrag,
  type UserAndTagsFrag,
  type UserFrag,
  type UserFundAssignmentFrag,
  UserSettingsLang,
  UserSettingsTheme,
  UserStatus
} from '@/graphql'
import { Me } from '@/models/User'
import { UserProfileRequest, UserRequest, UserSettingsRequest, UserTagRequest } from '@/requests/UserRequest'
import { useCache } from '@/stores/Cache'
import { type Mutation, type Query, type UseQueryOptions, useMutation, useQuery } from '../Api'

export type MeItem = Query<Me, []>

export type UserItem = Query<UserFrag, []>
export type UserList = Query<UserAndRolesAndTagsFrag[], []>
export type ActiveUserList = SQuery<UserAndTagsFrag[]>
export type GetActiveUserList = Get<UserAndTagsFrag[]>

export type UserFundAssignmentList = SQuery<UserFundAssignmentFrag[]>
export type GetUserFundAssignmentList = Get<UserFundAssignmentFrag[]>

export type UpdateUserSettingsLang = Mutation<void, [UserSettingsLangInput]>
export type UpdateUserSettingsTheme = Mutation<void, [UserSettingsThemeInput]>
export type UpdateUserRoles = Mutation<void, [id: number, roleIds: number[]]>
export type UpdateUserFundAssignments = Mutation<void, [id: number, inputs: UpdateUserFundAssignmentsInput[]]>
export type RetireUser = Mutation<void, [id: number]>

export type UserSettingsLangInput = 'en' | 'ja'
export type UserSettingsThemeInput = 'light' | 'dark'

export type UserActivityOverviewItem = SQuery<UserActivityOverview>

export function useMeItem(options?: UseQueryOptions): MeItem {
  return useQuery(async () => {
    const res = await new UserRequest().whoami()

    return res.data.user ? new Me(res.data.user) : null
  }, options)
}

export function useUserItem(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): UserItem {
  const query = useQuery<UserFrag, []>(async () => {
    return (await new UserRequest().fetch(toValue(id))).data.user
  }, options)

  watch(() => toValue(id), () => query.execute())

  return query
}

export function useUserAndRoles(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
) {
  return useQuery<UserAndRolesFrag>(async () => {
    const res = await new UserRequest().andRoles(toValue(id))

    return res.data.user
  }, options)
}

export function useUserListCache(): UserList {
  const cache = useCache()
  return useQuery(async () => {
    return cache.remember('repo-user-user-list', () => fetchUserList())
  })
}

export function useActiveUserList(): ActiveUserList {
  return useSQuery(() => fetchActiveUserList())
}

export function useActiveUserListCache(): UserList {
  const cache = useCache()
  return useQuery(async () => {
    return cache.remember('repo-user-active-user-list', () => fetchActiveUserList() as any)
  })
}

export function useGetActiveUserList(): GetActiveUserList {
  return useGet(() => fetchActiveUserList())
}

export function useUserListForCatalog() {
  return useQuery(async () => {
    const res = await new UserRequest().fetchForCatalog()
    return res.data.users
  })
}

async function fetchUserList(): Promise<UserAndRolesAndTagsFrag[]> {
  return new UserRequest().fetchAll().then((res) => {
    const users = res.data.users
    return orderBy(users, ['firstNameEn', 'lastNameEn'])
  })
}

async function fetchActiveUserList(): Promise<UserAndRolesAndTagsFrag[]> {
  return new UserRequest().fetchAll().then((res) => {
    const users = res.data.users

    const filteredUsers = users.filter((u) => u.status === UserStatus.Active)

    return orderBy(filteredUsers, ['firstNameEn', 'lastNameEn'])
  })
}

export function useUserTagList() {
  return useQuery(async () => {
    const res = await new UserTagRequest().fetchAll()
    return res.data.tags
  })
}

export function useUserFundAssignmentList(id: MaybeRefOrGetter<number>): UserFundAssignmentList {
  return useSQuery(() => fetchUserFundAssignmentList(toValue(id)))
}

export function useGetUserFundAssignmentList(id: MaybeRefOrGetter<number>): GetUserFundAssignmentList {
  return useGet(() => fetchUserFundAssignmentList(toValue(id)))
}

async function fetchUserFundAssignmentList(id: number): Promise<UserFundAssignmentFrag[]> {
  const res = await new UserRequest().fetchFundAssignments(toValue(id))
  return orderBy(res.data.fundAssignments, (fa) => fa.fund.rank)
}

export function useUpdateUserSettingsLang(): UpdateUserSettingsLang {
  return useMutation<void, [UserSettingsLangInput]>(async (lang) => {
    await new UserSettingsRequest().updateLang(
      lang === 'ja' ? UserSettingsLang.Ja : UserSettingsLang.En
    )
  })
}

export function useUpdateUserSettingsTheme(): UpdateUserSettingsTheme {
  return useMutation<void, [UserSettingsThemeInput]>(async (theme) => {
    await new UserSettingsRequest().updateTheme(
      theme === 'dark' ? UserSettingsTheme.Dark : UserSettingsTheme.Light
    )
  })
}

export function useUpdateUserRoles(): UpdateUserRoles {
  return useMutation<void, [id: number, roleIds: number[]]>(async (id, roleIds) => {
    await new UserRequest().updateRoles(id, roleIds)
  })
}

export function useUpdateUserFundAssignments(): UpdateUserFundAssignments {
  return useMutation<void, [id: number, input: UpdateUserFundAssignmentsInput[]]>(async (id, inputs) => {
    await new UserSettingsRequest().updateFundAssignments(id, inputs)
  })
}

export function useRetireUser(): RetireUser {
  return useMutation<void, [id: number]>(async (id) => {
    await new UserSettingsRequest().retire(id)
  })
}

export function useUserActivityOverviewItem(userId: number, condition: UserActivityCondition): UserActivityOverviewItem {
  return useSQuery(async () => {
    return (await (new UserProfileRequest().fetchActivityOverviewItem(userId, {
      from: condition.from,
      to: condition.to
    }))).data.userActivityOverview
  })
}
