<template>
  <div class="DCardGrid">
    <slot />
    <div class="curtain" />
  </div>
</template>

<style scoped lang="postcss">
.DCardGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 24px;
  padding: 24px;
}

.curtain {
  position: absolute;
  top: 0;
  bottom: 4px;
  left: 0;
  width: 8px;
  background-color: var(--c-bg-elv-up);
}
</style>
