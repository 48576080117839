<script setup lang="ts">
import SPill from 'sefirot/components/SPill.vue'
import SState from 'sefirot/components/SState.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type CompanyBusinessReportFrag } from '@/graphql'
import { useCompanyBusinessReportOps } from '@/composables/ops/CompanyBusinessReportOps'

defineProps<{
  companyBusinessReport: CompanyBusinessReportFrag
}>()

const { t } = useTrans({
  en: {
    title: (name: string) => `Company Business Report: ${name}`,
    target: (fund: string) => `Reporting to ${fund}`
  },
  ja: {
    title: (name: string) => `業務報告書: ${name}`,
    target: (fund: string) => `報告先: ${fund}`
  }
})

const companyBusinessReportOps = useCompanyBusinessReportOps()
</script>

<template>
  <SCardBlock class="CompanyBusinessReportTitle">
    <div class="title">
      {{ t.title(companyBusinessReportOps.name(companyBusinessReport)) }}
    </div>
    <div class="list">
      <SState
        :mode="companyBusinessReportOps.statusMode(companyBusinessReport)"
        :label="companyBusinessReportOps.statusText(companyBusinessReport)"
      />
      <SPill
        mode="mute"
        :label="t.target(companyBusinessReport.report.fund.nameAbbreviated)"
      />
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid var(--c-gutter);
  padding: 24px 24px 23px;
}

.title {
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
