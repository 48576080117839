<script setup lang="ts">
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useVNotification, useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { type ExtractedOpportunity } from '@/graph/Opportunity'
import {
  type ActionNoteFrag,
  type CompanyAndActionNotesAndPermissionFrag,
  OpportunityInitialEvaluation
} from '@/graphql'
import { useExtractedOpportunityOps, useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCreateOpportunity } from '@/composables/repos/OpportunityRepo'
import OpportunitySourceFieldsetAsync from '../opportunity-source/OpportunitySourceFieldsetAsync.vue'
import RoundFieldsetAsync from '../round/RoundFieldsetAsync.vue'
import OpportunityFormCreateFillByAi from './OpportunityFormCreateFillByAi.vue'
import OpportunityInputBusinessModel from './input/OpportunityInputBusinessModel.vue'
import OpportunityInputBusinessPlans from './input/OpportunityInputBusinessPlans.vue'
import OpportunityInputCompetitiveAdvantages from './input/OpportunityInputCompetitiveAdvantages.vue'
import OpportunityInputFinancingPlan from './input/OpportunityInputFinancingPlan.vue'
import OpportunityInputInitialEvaluation from './input/OpportunityInputInitialEvaluation.vue'
import OpportunityInputPastFinancings from './input/OpportunityInputPastFinancings.vue'
import OpportunityInputReviewStatus from './input/OpportunityInputReviewStatus.vue'
import OpportunityInputServiceDescription from './input/OpportunityInputServiceDescription.vue'
import OpportunityInputTrackRecord from './input/OpportunityInputTrackRecord.vue'

const props = defineProps<{
  company: CompanyAndActionNotesAndPermissionFrag
}>()

defineEmits<{
  'action-note-created': [actionNote: ActionNoteFrag]
  'action-note-updated': [actionNote: ActionNoteFrag]
  'action-note-deleted': [actionNote: ActionNoteFrag]
}>()

const { t } = useTrans({
  en: {
    i_has_round_text: 'No round information',
    i_has_round_help: 'You may skip entering round information by checking this option.',
    a_create_opportunity: 'Create opportunity'
  },
  ja: {
    i_has_round_text: 'ラウンド情報なし',
    i_has_round_help: 'このオプションをチェックするとラウンド情報の入力をスキップできます。',
    a_create_opportunity: 'Opportunityを作成'
  }
})

const router = useRouter()

const createOpportunityMutation = reactive(useCreateOpportunity())

const oppoOps = useOpportunityOps()
const exOppoOps = useExtractedOpportunityOps()

const { data } = useData({
  initialEvaluation: OpportunityInitialEvaluation.Proceed,
  reviewStatus: null as string | null,
  financingPlan: null as string | null,
  serviceDescription: null as string | null,
  trackRecord: null as string | null,
  businessPlans: null as string | null,
  competitiveAdvantages: null as string | null,
  businessModel: null as string | null,
  pastFinancings: null as string | null,

  source: {
    date: null as Day | null,
    promoter: null as number | null,
    promotedSource: 0,
    inboundOrOutbound: 0,
    investmentRequest: 0,
    contactBackground: null as string | null
  },

  noRound: false,
  round: {
    roundName: null as number | null,
    nameSuffix: null as string | null,
    schedule: null as string | null,
    currency: null as number | null,
    totalAmount: null as string | null,
    preValuation: null as string | null,
    postValuation: null as string | null,
    preIssuedShares: null as string | null,
    participatingInvestors: null as string | null,
    notes: null as string | null
  }
})

const oppoScope = 'oppo'
const sourceScope = 'source'
const roundScope = 'round'

const { validate: validateOppo } = useValidation({}, {}, { $scope: oppoScope })
const { validate: validateSource } = useValidation({}, {}, { $scope: sourceScope })
const { validate: validateRound } = useValidation({}, {}, { $scope: roundScope })
const { notify } = useVNotification()

async function create() {
  if (await validateAndNotify()) {
    const oppo = await createOpportunityMutation.execute(props.company.id, {
      initialEvaluation: data.value.initialEvaluation,
      reviewStatus: data.value.reviewStatus!,
      financingPlan: data.value.financingPlan!,
      serviceDescription: data.value.serviceDescription!,
      trackRecord: data.value.trackRecord,
      businessPlans: data.value.businessPlans,
      competitiveAdvantages: data.value.competitiveAdvantages,
      businessModel: data.value.businessModel,
      pastFinancings: data.value.pastFinancings,
      source: createSourceInput(),
      round: createRoundInput()
    })

    router.push(oppoOps.path(oppo, '?initialized=1'))
  }
}

async function validate() {
  return (await Promise.all([
    validateOppo(),
    validateSource(),
    ...(data.value.noRound ? [] : [validateRound()])
  ])).every((r) => r)
}

async function validateAndNotify() {
  if (await validate()) {
    return true
  }
  notify()
  return false
}

function createSourceInput() {
  return {
    date: data.value.source.date!.format('YYYY-MM-DD'),
    promoterId: data.value.source.promoter,
    promotedSourceId: data.value.source.promotedSource!,
    inboundOrOutboundId: data.value.source.inboundOrOutbound!,
    contactBackground: data.value.source.contactBackground!,
    investmentRequestId: data.value.source.investmentRequest!
  }
}

function createRoundInput() {
  if (data.value.noRound) {
    return null
  }

  return {
    name: data.value.round.roundName!,
    nameSuffix: data.value.round.nameSuffix,
    schedule: data.value.round.schedule!,
    totalAmount: createMoneyInput(data.value.round.totalAmount)!,
    preValuation: createMoneyInput(data.value.round.preValuation),
    postValuation: createMoneyInput(data.value.round.postValuation),
    preIssuedShares: data.value.round.preIssuedShares!,
    participatingInvestors: data.value.round.participatingInvestors,
    notes: data.value.round.notes
  }
}

function createMoneyInput(value: string | null) {
  return value ? { currencyId: data.value.round.currency!, value } : null
}

function fillByAi(oppo: ExtractedOpportunity) {
  if (oppo.financingPlan !== null) { data.value.financingPlan = oppo.financingPlan }
  if (oppo.serviceDescription !== null) { data.value.serviceDescription = oppo.serviceDescription }
  if (oppo.trackRecord !== null) { data.value.trackRecord = oppo.trackRecord }
  if (oppo.businessPlans !== null) { data.value.businessPlans = oppo.businessPlans }
  if (oppo.competitiveAdvantages !== null) { data.value.competitiveAdvantages = oppo.competitiveAdvantages }
  if (oppo.businessModel !== null) { data.value.businessModel = oppo.businessModel }
  if (oppo.pastFinancings !== null) { data.value.pastFinancings = oppo.pastFinancings }

  if (oppo.sourceDate !== null) { data.value.source.date = exOppoOps.sourceDate(oppo) }
  if (oppo.sourcePromoterId !== null) { data.value.source.promoter = oppo.sourcePromoterId }
  if (oppo.sourcePromotedSourceId !== null) { data.value.source.promotedSource = oppo.sourcePromotedSourceId }
  if (oppo.sourceInboundOrOutboundId !== null) { data.value.source.inboundOrOutbound = oppo.sourceInboundOrOutboundId }
  if (oppo.sourceInvestmentRequestedId !== null) { data.value.source.investmentRequest = oppo.sourceInvestmentRequestedId }
  if (oppo.sourceContactBackground !== null) { data.value.source.contactBackground = oppo.sourceContactBackground }

  if (oppo.roundNameId !== null) { data.value.round.roundName = oppo.roundNameId }
  if (oppo.currencyId !== null) { data.value.round.currency = oppo.currencyId }
  if (oppo.schedule !== null) { data.value.round.schedule = oppo.schedule }
  if (oppo.totalAmount !== null) { data.value.round.totalAmount = String(oppo.totalAmount) }
  if (oppo.preValuation !== null) { data.value.round.preValuation = String(oppo.preValuation) }
  if (oppo.postValuation !== null) { data.value.round.postValuation = String(oppo.postValuation) }
  if (oppo.preIssuedShares !== null) { data.value.round.preIssuedShares = oppo.preIssuedShares }
  if (oppo.participatingInvestors !== null) { data.value.round.participatingInvestors = oppo.participatingInvestors }
  if (oppo.notes !== null) { data.value.round.notes = oppo.notes }
}
</script>

<template>
  <Suspense>
    <SCard class="OpportunityFormCreate">
      <SCardBlock class="s-p-48">
        <SDoc>
          <SDocSection class="header">
            <SContent>
              <STrans lang="en">
                <h1>Create a new opportunity</h1>
              </STrans>
              <STrans lang="ja">
                <h1>Opportunityを作成する</h1>
              </STrans>
            </SContent>
            <OpportunityFormCreateFillByAi
              :company="company"
              @executed="fillByAi"
              @action-note-created="$emit('action-note-created', $event)"
              @action-note-updated="$emit('action-note-updated', $event)"
              @action-note-deleted="$emit('action-note-deleted', $event)"
            />
          </SDocSection>
          <SDivider />
          <SDocSection class="evaluation">
            <SContent>
              <STrans lang="en">
                <h2>Evaluation status</h2>
              </STrans>
              <STrans lang="ja">
                <h2>検討状況</h2>
              </STrans>
            </SContent>
            <OpportunityInputInitialEvaluation
              v-model="data.initialEvaluation"
              :scope="oppoScope"
            />
            <OpportunityInputReviewStatus
              v-model="data.reviewStatus"
              :scope="oppoScope"
            />
          </SDocSection>
          <SDivider />
          <SDocSection class="Sourcing">
            <SContent>
              <STrans lang="en">
                <h2>Sourcing information</h2>
              </STrans>
              <STrans lang="ja">
                <h2>ソーシング情報</h2>
              </STrans>
            </SContent>
            <OpportunitySourceFieldsetAsync
              v-model="data.source"
              :scope="sourceScope"
            />
          </SDocSection>
          <SDivider />
          <SDocSection class="evaluation">
            <SContent>
              <STrans lang="en">
                <h2>Business information</h2>
              </STrans>
              <STrans lang="ja">
                <h2>事業情報</h2>
              </STrans>
            </SContent>
            <OpportunityInputFinancingPlan
              v-model="data.financingPlan"
              :scope="oppoScope"
            />
            <OpportunityInputServiceDescription
              v-model="data.serviceDescription"
              :scope="oppoScope"
            />
            <OpportunityInputTrackRecord
              v-model="data.trackRecord"
              :scope="oppoScope"
            />
            <OpportunityInputBusinessPlans
              v-model="data.businessPlans"
              :scope="oppoScope"
            />
            <OpportunityInputCompetitiveAdvantages
              v-model="data.competitiveAdvantages"
              :scope="oppoScope"
            />
            <OpportunityInputBusinessModel
              v-model="data.businessModel"
              :scope="oppoScope"
            />
            <OpportunityInputPastFinancings
              v-model="data.pastFinancings"
              :scope="oppoScope"
            />
          </SDocSection>
          <SDivider />
          <SDocSection class="round">
            <SContent>
              <STrans lang="en">
                <h2>Round information</h2>
              </STrans>
              <STrans lang="ja">
                <h2>ラウンド情報</h2>
              </STrans>
            </SContent>
            <SInputCheckbox
              :text="t.i_has_round_text"
              :help="t.i_has_round_help"
              v-model="data.noRound"
            />
            <RoundFieldsetAsync
              v-show="!data.noRound"
              v-model="data.round"
              :scope="roundScope"
            />
          </SDocSection>
        </SDoc>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-48">
        <SControl>
          <SControlRight>
            <SControlButton
              mode="info"
              :label="t.a_create_opportunity"
              :loading="createOpportunityMutation.loading"
              @click="create"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </Suspense>
</template>

<style scoped lang="postcss">
.OpportunityFormCreate :deep(.vc-popover-content-wrapper) {
  z-index: 1000;
}
</style>
