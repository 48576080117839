import { useLang } from 'sefirot/composables/Lang'
import { type FundStats } from '@/graph/FundStats'
import { abbreviate } from '@/support/Num'
import { defineOps } from './Ops'

export const useFundStatsOps = defineOps(() => {
  const lang = useLang()

  function investedAmountJpyAbbr(stats: FundStats, fraction: number = 1): string {
    return abbreviate(Number(stats.investedAmountJpy), fraction, lang)
  }

  function investedDealsPageLink(stats: FundStats): string {
    return `/deals/?condition[fund][]=${stats.fundId}&condition[status][0]=Invested`
  }

  function inProgressDealsPageLink(stats: FundStats): string {
    const statuses = [
      'condition[status][0]=InitialReview',
      'condition[status][1]=InvestmentCommittee',
      'condition[status][2]=Contract',
      'condition[status][3]=Remittance',
      'condition[status][4]=Finalize',
      'condition[status][5]=Report',
      'condition[status][6]=Closing'
    ].join('&')
    return `/deals/?condition[fund][]=${stats.fundId}&${statuses}`
  }

  function portfolioPageLink(stats: FundStats): string {
    const conditions = [
      'condition[portfolioStatus][0]=Portfolio',
      'condition[portfolioStatus][1]=ExPortfolio'
    ].join('&')
    return `/companies/?condition[portfolioOf][portfolioOfIds][0]=${stats.fundId}&${conditions}`
  }

  function activePortfolioPageLink(stats: FundStats): string {
    const conditions = [
      'condition[portfolioStatus][0]=Portfolio'
    ].join('&')
    return `/companies/?condition[portfolioOf][portfolioOfIds][0]=${stats.fundId}&${conditions}`
  }

  function exPortfolioPageLink(stats: FundStats): string {
    const conditions = [
      'condition[portfolioStatus][0]=ExPortfolio'
    ].join('&')
    return `/companies/?condition[portfolioOf][portfolioOfIds][0]=${stats.fundId}&${conditions}`
  }

  return {
    investedAmountJpyAbbr,
    investedDealsPageLink,
    inProgressDealsPageLink,
    portfolioPageLink,
    activePortfolioPageLink,
    exPortfolioPageLink
  }
})
