<script setup lang="ts">
import { computed } from 'vue'
import type * as Field from '@/support/Field'
import DFields from '@/components/DFields.vue'
import { type DataYesOrNo, type ExtraInfo } from './DealFormUpdateReport.vue'

const props = defineProps<{
  fields: Field.Field[]
  strict: DataYesOrNo
  editable: DataYesOrNo
  data: ExtraInfo
}>()

const emit = defineEmits<{
  'update:data': [ExtraInfo]
}>()

const fieldData = computed(() => ({
  strict: props.strict,
  editable: props.editable,
  ...props.data
}))

function emitDataChange(data: Field.Data) {
  const _data = { ...data }

  delete _data.strict
  delete _data.editable

  emit('update:data', _data as ExtraInfo)
}
</script>

<template>
  <DFormSection>
    <STrans lang="en">
      <DFormTitle>Report description</DFormTitle>
      <DFormLead>Enter a summary of this investment, including how the funding will be used.</DFormLead>
    </STrans>
    <STrans lang="ja">
      <DFormTitle>基本情報</DFormTitle>
      <DFormLead>資金調達の使途など、本投資の概要を入力してください。</DFormLead>
    </STrans>

    <DFields
      :fields="fields"
      :model-value="fieldData"
      @update:model-value="emitDataChange"
    />
  </DFormSection>
</template>
