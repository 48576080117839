<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCompanyShowPage } from '@/composables/pages/CompanyPage'
import CompanySupportLogListPage from '@/components/company/CompanySupportLogListPage.vue'

const { company } = useCompanyShowPage()

const companyOps = useCompanyOps()

const title = computedWhen(company, (c) => {
  return `${companyOps.name(c)} - Support Logs`
})
</script>

<template>
  <DPage class="CompaniesIdSupportLogs" :title="title">
    <CompanySupportLogListPage
      v-if="company"
      :company="company"
    />
  </DPage>
</template>
