import { FundGroupStatus } from '@/graphql'
import { type Field } from '@/support/Field'
import { Model } from './Model'

export { FundGroupStatus }

export class FundGroup extends Model {
  id?: number
  name?: string
  lp?: string
  status?: FundGroupStatus
  rank?: number
  proposalCustomFields?: Field[]

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.status = data.status
    this.name = data.name
    this.lp = data.lp
    this.rank = data.rank
    this.proposalCustomFields = data.proposalCustomFields
  }

  isActive(): boolean {
    return this.status === FundGroupStatus.Active
  }

  isInactive(): boolean {
    return this.status === FundGroupStatus.Inactive
  }
}
