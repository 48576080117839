<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsPreReviewCommitteeRequest } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { UserTag } from '@/models/User'
import { useUserOps } from '@/composables/ops/UserOps'
import { useRequestPrc } from '@/composables/repos/DealEntryRepo'
import { useUserActiveCollection } from '@/composables/user/UserData'
import DInputMoney from '../DInputMoney.vue'
import DealEntryPrcRequestComplete from './DealEntryPrcRequestComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsPreReviewCommitteeRequest
}>()

const { t } = useTrans({
  en: {
    i_date_from_label: 'From *',
    i_date_to_label: 'Until *',
    i_gp_in_charge_label: 'General Partner in charge *',
    i_deadline_label: 'Deadline *',
    i_lead_or_follow_label: 'Lead or Follow *',
    i_investment_amount_label: 'Investment amount *',
    i_investment_amount_ratio_label: 'Investment amount ratio *',
    i_pre_valuation_label: 'Pre valuation *',
    i_notes_label: 'Notes',
    i_notes_ph: 'Fill in any additional information.',
    submit: 'Submit request'
  },
  ja: {
    i_date_from_label: 'この日から *',
    i_date_to_label: 'この日までに *',
    i_gp_in_charge_label: '担当GP *',
    i_deadline_label: '着金目標日 *',
    i_lead_or_follow_label: 'リード / フォロー *',
    i_investment_amount_label: '出資金額 *',
    i_investment_amount_ratio_label: '出資比率 *',
    i_pre_valuation_label: 'プレ時価総額 *',
    i_notes_label: '備考',
    i_notes_ph: '特筆すべきことがあれば入力ください。',
    submit: '送信する'
  }
})

const router = useRouter()

const { data: gps } = useUserActiveCollection({
  tags: [UserTag.GeneralPartner]
})

const { execute: requestPrc, loading } = useRequestPrc(() => props.deal.id!, () => ({
  dateFrom: data.value.dateFrom!.format('YYYY-MM-DD'),
  dateTo: data.value.dateTo!.format('YYYY-MM-DD'),
  gpInCharge: data.value.gpInCharge!,
  deadline: data.value.deadline!.format('YYYY-MM-DD'),
  lead: data.value.lead!,
  investmentAmount: data.value.investmentAmount!,
  investmentAmountRatio: data.value.investmentAmountRatio!,
  preValuation: data.value.preValuation!,
  notes: data.value.notes
}))

const userOps = useUserOps()

const completeDialog = usePower()

const { data } = useData({
  dateFrom: null as Day | null,
  dateTo: null as Day | null,
  gpInCharge: null as number | null,
  deadline: props.deal.deadline,
  lead: props.deal.lead ?? true,
  investmentAmount: props.deal.investmentAmount?.value ?? null,
  investmentAmountRatio: null,
  preValuation: props.deal.round?.preValuation?.value ?? null,
  notes: null
})

const { validation, validateAndNotify } = useValidation(data, {
  dateFrom: { required: required() },
  dateTo: { required: required() },
  gpInCharge: { required: required() },
  deadline: { required: required() },
  lead: { required: required() },
  investmentAmount: { required: required(), decimal: decimal(), maxValue: maxValue(10000000000000) },
  investmentAmountRatio: { required: required() },
  preValuation: { required: required(), decimal: decimal(), maxValue: maxValue(10000000000000) },
  notes: { maxLength: maxLength(1000) }
})

const gpOptions = computedWhen(gps, (gps) => {
  return gps.map((gp) => ({
    label: userOps.name(gp as any),
    value: gp.id!
  }))
}, [])

async function submit() {
  if (await validateAndNotify()) {
    await requestPrc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryPrcRequest">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SDocSection class="intro">
              <SContent>
                <STrans lang="en">
                  <h1>Request Pre Review Committee</h1>
                  <p>Pre Review Committeethe is where the GP decides whether the company is worthy of full-scale investment consideration, mainly from a business perspective. Each case will take 30 minutes and a maximum of two cases per day will be considered. <SLink href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/edit#heading=h.m2nrsc4zpux0">Refer to this link</SLink> for more details about Pre Review Committee.</p>
                </STrans>
                <STrans lang="ja">
                  <h1>社内プレ検討会 開催依頼</h1>
                  <p>主に事業面を中心に、本格投資検討に値するかをGPが判断するための会議体です。毎週水曜日午後開催。1件あたり30分、1日最大2件の案件を検討します。社内プレ検討会の<SLink href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/edit#heading=h.m2nrsc4zpux0">詳細についてはこちら</SLink>をご確認ください。</p>
                </STrans>
              </SContent>
            </SDocSection>
            <SDivider />
            <SDocSection class="dates">
              <SContent>
                <STrans lang="en">
                  <h2>Desired date</h2>
                  <p>Specify the time frame during which you would like to hold the Pre Review Committee. Fund Management team will try to schedule the meeting within this period. If you already have specific date and time options in mind, specify the time frame here and note those details in the "Notes" section.</p>
                  <SAlert v-if="settings.datesAlertInfoEn" mode="info">
                    <p>{{ settings.datesAlertInfoEn }}</p>
                  </SAlert>
                </STrans>
                <STrans lang="ja">
                  <h2>希望日程</h2>
                  <p>いつからいつまでの間に社内プレ検討会の開催を希望するか指定してください。この期間の中から日程を調整します。具体的な日時の候補が存在する場合、ここで期間を指定した上で、「備考」にその旨を記載してください。</p>
                  <SAlert v-if="settings.datesAlertInfoJa" mode="info">
                    <p>{{ settings.datesAlertInfoJa }}</p>
                  </SAlert>
                </STrans>
              </SContent>
              <div class="period">
                <SInputDate
                  :label="t.i_date_from_label"
                  v-model="data.dateFrom"
                  :validation="validation.dateFrom"
                />
                <div class="period-divider">–</div>
                <SInputDate
                  :label="t.i_date_to_label"
                  v-model="data.dateTo"
                  :validation="validation.dateTo"
                />
              </div>
            </SDocSection>
            <SDivider />
            <SDocSection class="gp">
              <SContent>
                <STrans lang="en">
                  <h2>General Partner in Charge</h2>
                  <p>Select which General Partner is in charge of this Pre Review Committee.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>担当GP</h2>
                  <p>本社内プレ検討会の担当GPを選択してください。</p>
                </STrans>
              </SContent>
              <SInputRadios
                :label="t.i_gp_in_charge_label"
                :options="gpOptions"
                v-model="data.gpInCharge"
                :validation="validation.gpInCharge"
              />
            </SDocSection>
            <SDivider />
            <SDocSection class="deal">
              <SContent>
                <STrans lang="en">
                  <h2>Deal details</h2>
                  <p>Fill in the information related to the deal. Note that these field is going to update the corresponding deal and round data.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>ディール情報</h2>
                  <p>ディールに関する情報を入力してください。これらの情報は、フォーム送信後、Dealページ上のラウンド情報該当箇所が自動更新されます。</p>
                </STrans>
              </SContent>
              <SGrid cols="2" gap="24">
                <SGridItem span="2">
                  <SInputDate
                    :label="t.i_deadline_label"
                    v-model="data.deadline"
                    :validation="validation.deadline"
                  />
                </SGridItem>
                <SGridItem span="2">
                  <SInputRadios
                    :label="t.i_lead_or_follow_label"
                    :options="[
                      { label: 'Lead', value: true },
                      { label: 'Follow', value: false }
                    ]"
                    v-model="data.lead"
                    :validation="validation.lead"
                  />
                </SGridItem>
                <SGridItem span="1">
                  <DInputMoney
                    :label="t.i_investment_amount_label"
                    placeholder="100,000,000"
                    :currency="deal.round!.currency"
                    v-model="data.investmentAmount"
                    :validation="validation.investmentAmount"
                  />
                </SGridItem>
                <SGridItem span="1">
                  <SInputText
                    :label="t.i_investment_amount_ratio_label"
                    placeholder="30%"
                    v-model="data.investmentAmountRatio"
                    :validation="validation.investmentAmountRatio"
                  />
                </SGridItem>
                <SGridItem span="1">
                  <DInputMoney
                    :label="t.i_pre_valuation_label"
                    placeholder="100,000,000"
                    :currency="deal.round!.currency"
                    v-model="data.preValuation"
                    :validation="validation.preValuation"
                  />
                </SGridItem>
              </SGrid>
            </SDocSection>
            <SDivider />
            <SDocSection class="notes">
              <SContent>
                <STrans lang="en">
                  <h2>Notes</h2>
                  <p>Provide any additional information related to the Pre Review Committee. If you have specific date and time options for the meeting, include them here.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>備考</h2>
                  <p>その他本社内プレ検討会に関連する情報があれば記載してください。開催日時の候補がある場合にはこちらに記載してください。</p>
                </STrans>
              </SContent>
              <SInputTextarea
                :label="t.i_notes_label"
                :placeholder="t.i_notes_ph"
                :rows="5"
                v-model="data.notes"
                :validation="validation.notes"
              />
            </SDocSection>
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryPrcRequestComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryPrcRequest {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.period {
  display: flex;
  align-items: center;
  gap: 16px;
}

.period-divider {
  padding-top: 26px;
  color: var(--c-text-3);
}
</style>
