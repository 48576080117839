import {
  type ActionNoteCondition,
  type ActionNoteOrder,
  ActionNoteOrderField,
  ActionNotePageDocument,
  type BasicPaginationInput,
  DeleteActionNoteDocument,
  UpdateActionNoteDocument,
  type UpdateActionNoteInput
} from '@/graphql'
import { Request } from './Request'

export type { ActionNoteCondition, ActionNoteOrder }
export { ActionNoteOrderField }

export class ActionNoteRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: ActionNoteCondition,
    orderBy: ActionNoteOrder
  ) {
    return this.request({
      query: ActionNotePageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchPageForSearch(
    page: BasicPaginationInput,
    condition: ActionNoteCondition,
    orderBy: ActionNoteOrder
  ) {
    return this.request({
      query: ActionNotePageDocument,
      variables: { page, condition, orderBy }
    })
  }

  update(id: number, input: UpdateActionNoteInput) {
    return this.request({
      query: UpdateActionNoteDocument,
      variables: { id, input }
    })
  }

  delete(id: number) {
    return this.request({
      query: DeleteActionNoteDocument,
      variables: { id }
    })
  }
}
