<script setup lang="ts">
import { computed } from 'vue'
import {
  type DealProgress,
  type DealWithIdFrag,
  type DealWithStatusFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithStatusFrag & WithPermissionFrag
}>()

const dealOps = useDealOps()

const progress = computed(() => {
  return props.deal.progress.map((item) => ({
    value: dealOps.statusText(item.name),
    state: getProgressState(item)
  }))
})

function getProgressState(item: DealProgress) {
  if (dealOps.isDropped(props.deal) && !item.completedAt) {
    return 'dropped'
  } else if (item.completedAt) {
    return 'completed'
  } else {
    return 'open'
  }
}
</script>

<template>
  <DCard :collapsable="false">
    <div class="box">
      <p class="status">
        <span class="status-label">Status: </span>
        <span class="status-value">{{ dealOps.statusText(deal) }}</span>
      </p>

      <div v-if="progress.length" class="steps">
        <div
          v-for="(item, index) in progress"
          :key="index"
          class="step"
          :class="[item.state]"
        >
          <div class="step-bar" />
          <p class="step-text">{{ item.value }}</p>
        </div>
      </div>
    </div>
  </DCard>
</template>

<style scoped lang="postcss">
.box {
  padding: 20px 24px;
}

.status {
  font-size: 14px;
}

.status-label {
  font-weight: 500;
  color: var(--c-text-2);
}

.status-value {
  font-weight: 500;
}

.steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 8px;
  padding-top: 12px;
}

.step-bar {
  border-radius: 2px;
  width: 100%;
  height: 4px;

  .step.open &      { background-color: var(--c-gray-light-3); }
  .step.completed & { background-color: var(--c-success-bg); }
  .step.dropped &   { background-color: var(--c-danger-bg); }

  .dark .step.open & { background-color: var(--c-gray-dark-3); }
}

.step-text {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .step.completed & {
    color: var(--c-success-text);
  }
}
</style>
