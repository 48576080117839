<script setup lang="ts">
import { type ActionNoteFrag } from '@/graphql'
import { useDeleteActionNote } from '@/composables/repos/ActionNoteRepo'

const props = defineProps<{
  actionNote: ActionNoteFrag
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
  unmounted: []
}>()

const mutation = useDeleteActionNote()

async function destroy() {
  await mutation.execute(props.actionNote.id)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <h2>Delete this action note</h2>
          <p>This action cannot be undone.</p>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="mutation.loading.value"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="danger"
            label="Delete action note"
            :loading="mutation.loading.value"
            @click="destroy"
          />
        </SControlRight>
      </scontrol>
    </SCardBlock>
  </SCard>
</template>
