<script setup lang="ts">
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

defineProps<{
  removable: boolean
}>()

const name = defineModel<string | null>('name', { required: true })
const title = defineModel<string | null>('title', { required: true })
const email = defineModel<string | null>('email', { required: true })
const recipientType = defineModel<'To' | 'Cc' | 'Bcc'>('recipientType', { required: true, default: 'To' })
const isAdmin = defineModel<boolean>('isAdmin', { required: true, default: false })

defineEmits<{
  remove: []
}>()

const { t } = useTrans({
  en: {
    i_name_ph: 'John Doe',
    i_title_ph: 'CEO',
    i_email_ph: 'john.doe@example.com',
    i_is_admin_text: 'Is Admin',
    a_cancel: 'Cancel',
    a_update: 'Update Email addresses'
  },
  ja: {
    i_name_ph: '山田 太郎',
    i_title_ph: 'CEO',
    i_email_ph: 'taro.yamada@example.com',
    i_is_admin_text: '管理者',
    a_cancel: 'キャンセル',
    a_update: '送付先を更新'
  }
})

const { validation } = useValidation(() => ({
  name: name.value,
  title: title.value,
  email: email.value,
  recipientType: recipientType.value
}), {
  name: {
    required: required(),
    maxLength: maxLength(255)
  },
  title: {
    required: required(),
    maxLength: maxLength(255)
  },
  email: {
    required: required(),
    maxLength: maxLength(255)
  }
})

const recipientTypeOptions = [
  { label: 'To', value: 'To' },
  { label: 'Cc', value: 'Cc' },
  { label: 'Bcc', value: 'Bcc' }
]
</script>

<template>
  <div class="CompanyPortfolioDataFormUpdateBalanceConfirmationEmailDestination">
    <SInputText
      :placeholder="t.i_name_ph"
      v-model="name"
      :validation="validation.name"
      hide-error
    />
    <SInputText
      :placeholder="t.i_title_ph"
      v-model="title"
      :validation="validation.title"
      hide-error
    />
    <SInputText
      type="email"
      :placeholder="t.i_email_ph"
      v-model="email"
      :validation="validation.email"
      hide-error
    />
    <SInputSelect
      :options="recipientTypeOptions"
      v-model="recipientType"
      :validation="validation.recipientType"
      hide-error
    />
    <div class="checkbox">
      <SInputCheckbox
        :text="t.i_is_admin_text"
        v-model="isAdmin"
        hide-error
      />
    </div>
    <SButton
      type="text"
      mode="mute"
      :icon="IconTrash"
      :disabled="!removable"
      @click="$emit('remove')"
    />
  </div>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataFormUpdateBalanceConfirmationEmailDestination {
  display: grid;
  grid-template-columns: minmax(128px, 192px) minmax(128px, 192px) minmax(128px, 1fr) 128px 128px 40px;
  gap: 8px;
  padding: 0 8px;
}

.checkbox {
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid var(--c-border-mute-1);
  border-radius: 6px;
  padding-left: 10px;
}
</style>
