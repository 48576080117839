import { type Day, day } from 'sefirot/support/Day'
import {
  FundReportType,
  type FundReportWithDateAndFundNameAbbreviatedFrag,
  type FundReportWithDateFrag,
  type FundReportWithIdFrag,
  type FundReportWithTitleFrag,
  type FundReportWithTypeFrag
} from '@/graphql'
import { type ValueOf } from '@/support/Types'
import { defineOps } from './Ops'

export const useFundReportOps = defineOps(() => {
  const FundReportTypes = {
    [FundReportType.CompanyBusinessReport]: '業務報告書'
  } as const

  function name(fr: FundReportWithDateAndFundNameAbbreviatedFrag): string {
    return `${day(fr.date).format('YYYYMMDD')}_${fr.fund.nameAbbreviated}`
  }

  function typeText(fr: FundReportWithTypeFrag): ValueOf<typeof FundReportTypes> {
    return FundReportTypes[fr.type]
  }

  function title(fr: FundReportWithTitleFrag): string {
    return `${typeText(fr)} ${name(fr)}`
  }

  function path(fr: FundReportWithIdFrag): string {
    return `/company-business-reports-by-fund-report-id/${fr.id}`
  }

  function date(fr: FundReportWithDateFrag): Day {
    return day(fr.date)
  }

  return {
    name,
    typeText,
    title,
    path,
    date
  }
})
