<template>
  <div class="DFormMedium">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormMedium :deep(p) {
  font-size: 14px;

  & + p {
    margin-top: 16px;
  }
}

.DFormMedium :deep(strong) {
  font-weight: 600;
}

.DFormMedium :deep(ul),
.DFormMedium :deep(ol) {
  margin: 16px 0;
}

.DFormMedium :deep(ul:last-child) {
  margin-bottom: 0;
}

.DFormMedium :deep(ol) {
  counter-reset: item;
}

.DFormMedium :deep(ul > li),
.DFormMedium :deep(ol > li) {
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
}

.DFormMedium :deep(ul > li:before) {
  position: absolute;
  top: 9px;
  left: 2px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: var(--c-text-3);
  content: "";
}

.DFormMedium :deep(ol > li:before) {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14.5px;
  font-family: var(--font-family-number);
  color: var(--c-text-2);
  counter-increment: item;
  content: counter(item)".";
}

.DFormMedium :deep(li + li) {
  margin-top: 6px;
}

.DFormMedium :deep(li > ul) {
  margin: 6px 0 0;
}
</style>
