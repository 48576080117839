<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type BalanceConfirmationEmail } from '@/graph/BalanceConfirmation'

const props = defineProps<{
  balanceConfirmationEmail: BalanceConfirmationEmail
}>()

const { t } = useTrans({
  en: {
    destinations: 'Email addresses',
    destinations_name: 'Name',
    destinations_title: 'Title',
    destinations_email: 'Email',
    destinations_recipient_type: 'Recipient type',
    destinations_is_admin: 'Admin',
    notes: 'Notes'
  },
  ja: {
    destinations: '送付先',
    destinations_name: '氏名',
    destinations_title: '役職',
    destinations_email: 'Email',
    destinations_recipient_type: '送付方式',
    destinations_is_admin: '管理者',
    notes: '備考'
  }
})

const table = useTable({
  records: computed(() => props.balanceConfirmationEmail.destinations),
  borderless: true,
  orders: [
    'name',
    'title',
    'email',
    'recipientType',
    'isAdmin',
    'empty'
  ],
  columns: {
    name: {
      label: t.destinations_name,
      grow: true,
      cell: (_, d) => ({
        type: 'text',
        value: d.name
      })
    },
    title: {
      label: t.destinations_title,
      cell: (_, d) => ({
        type: 'text',
        value: d.title
      })
    },
    email: {
      label: t.destinations_email,
      cell: (_, d) => ({
        type: 'text',
        value: d.email
      })
    },
    recipientType: {
      label: t.destinations_recipient_type,
      cell: (_, d) => ({
        type: 'text',
        value: d.recipientType
      })
    },
    isAdmin: {
      cell: (_, d) => ({
        type: 'text',
        value: d.isAdmin ? t.destinations_is_admin : ''
      })
    },
    empty: {
      cell: {
        type: 'empty'
      }
    }
  }
})
</script>

<template>
  <SCardBlock class="CompanyPortfolioDataDescBalanceConfirmationEmailContent">
    <SDesc gap="16">
      <SDescItem :style="{ borderBottom: 0 }">
        <SDescLabel>{{ t.destinations }}</SDescLabel>
        <SCard v-if="balanceConfirmationEmail.destinations.length" class="destinations">
          <SCardBlock>
            <STable :options="table" />
          </SCardBlock>
        </SCard>
        <SDescEmpty v-else />
      </SDescItem>
      <SDescItem>
        <SDescLabel>{{ t.notes }}</SDescLabel>
        <SDescText>{{ balanceConfirmationEmail.notes }}</SDescText>
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataDescBalanceConfirmationEmailContent {
  padding: 16px;
}

.destinations {
  margin-top: 4px;
  overflow: hidden;
}

.destinations :deep(.col-name)          { --table-col-width: 144px; }
.destinations :deep(.col-title)         { --table-col-width: 144px; }
.destinations :deep(.col-email)         { --table-col-width: 192px; }
.destinations :deep(.col-recipientType) { --table-col-width: 128px; }
.destinations :deep(.col-isAdmin)       { --table-col-width: 128px; }
.destinations :deep(.col-empty)         { --table-col-width: 40px; }
</style>
