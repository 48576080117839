<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Schedule *',
    info: 'Estimated schedule of the round. It does not have to be precise.',
    ph: 'e.g. Until end of May, Closing at 1st of June but negotiable, etc.'
  },
  ja: {
    label: 'Schedule *',
    info: 'ラウンドのおおよそのスケジュール。ある程度ざっくりで構わない。',
    ph: '例：5月末まで、6月1日にクローズ予定だが交渉可能、など'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required(),
    maxLength: maxLength(40)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputText
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    v-model="model"
    :validation="validation.model"
  />
</template>
