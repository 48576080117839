<script setup lang="ts">
import { type Day, day } from 'sefirot/support/Day'
import { isString } from 'sefirot/support/Utils'
import { computed } from 'vue'

const props = defineProps<{
  date?: Day | string | null
  format?: string
}>()

const dateText = computed(() => {
  if (!props.date) {
    return null
  }

  const f = props.format ?? 'YYYY-MM-DD'
  const d = isString(props.date) ? day(props.date) : props.date

  return d.format(f)
})
</script>

<template>
  <p class="DGridDate">
    <span v-if="dateText">{{ dateText }}</span>
    <span class="empty" v-else>—</span>
  </p>
</template>

<style scoped lang="postcss">
.DGridDate {
  padding: 2px 0;
  line-height: 20px;
  font-size: 14px;
  font-feature-settings: "tnum";
}

.empty {
  color: var(--c-text-3);
}
</style>
