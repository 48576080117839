<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedArray } from 'sefirot/composables/Utils'
import { type DealWithAssigneesFrag, type DealWithIdFrag, type WithPermissionFrag } from '@/graphql'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealFormUpdateAssignees from './DealFormUpdateAssignees.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithAssigneesFrag & WithPermissionFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(props.deal)

const updateForm = usePower()

const actions = computedArray((items) => {
  if (canUpdate.value) {
    items.push({ icon: 'edit', onClick: updateForm.on })
  }
})
</script>

<template>
  <DCard title="Assignees" :actions="actions">
    <DCardInfo>
      <DCardInfoItem>
        <DCardInfoLabel text="Partner in-charge" />
        <DCardInfoUsers :users="deal.partnerInCharge" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Primary in-charge" />
        <DCardInfoUsers :users="deal.primaryInCharge" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Deputy in-charge" />
        <DCardInfoUsers :users="deal.deputyInCharge" />
      </DCardInfoItem>
      <DCardInfoItem>
        <DCardInfoLabel text="Participants" />
        <DCardInfoUsers :users="deal.participants" />
      </DCardInfoItem>
    </DCardInfo>

    <SModal :open="updateForm.state.value">
      <DealFormUpdateAssignees
        :deal="deal"
        @updated="updateForm.off(() => $emit('updated'))"
        @cancel="updateForm.off"
      />
    </SModal>
  </DCard>
</template>
