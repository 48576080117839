<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type DealForReportsFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealSecurityOps } from '@/composables/ops/DealSecurityOps'
import DealPageReportsSummaryItem from './DealPageReportsSummaryItem.vue'

const props = defineProps<{
  deal: DealForReportsFrag
}>()

const { t } = useTrans({
  en: {
    title: 'Investment summary',
    total: 'Total'
  },
  ja: {
    title: '投資概況',
    total: '合計金額'
  }
})

const dealOps = useDealOps()
const securityOps = useDealSecurityOps()

const securities = computed(() => {
  return props.deal.securities.map((security) => {
    return {
      type: 'data',
      name: security.name,
      est: securityOps.plannedTotalPriceWithRoundCurrency(props.deal.round, security),
      acq: securityOps.purchasedTotalPriceWithRoundCurrency(props.deal.round, security),
      fmc: securityOps.purchasedTotalPriceFmc(security)
    } as const
  })
})

const summary = computed(() => {
  return {
    type: 'summary',
    name: t.total,
    est: dealOps.investmentAmount(props.deal),
    acq: dealOps.investedAmount(props.deal),
    fmc: dealOps.investedAmountFmc(props.deal)
  } as const
})
</script>

<template>
  <div class="DealPageReportsSummary">
    <div class="header">
      <div class="title">{{ t.title }}</div>
      <div class="currency">{{ dealOps.currency(deal).name }}</div>
    </div>

    <div class="items">
      <div v-for="security, index in securities" :key="index" class="item">
        <DealPageReportsSummaryItem
          :type="security.type"
          :name="security.name"
          :est="security.est"
          :acq="security.acq"
          :fmc="security.fmc"
        />
      </div>
      <div class="item">
        <DealPageReportsSummaryItem
          :type="summary.type"
          :name="summary.name"
          :est="summary.est"
          :acq="summary.acq"
          :fmc="summary.fmc"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsSummary {
  padding: 32px 32px 128px;
  background-color: var(--c-bg-elv-2);
}

.header {
  display: grid;
  grid-template-columns: 1fr min-content;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}

.items {
  margin-top: 16px;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  overflow: hidden;
}

.item + .item {
  border-top: 1px solid var(--c-divider-2);
}
</style>
