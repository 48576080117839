<script setup lang="ts">
import IconArrowSquareOut from '~icons/ph/arrow-square-out-bold'
import IconCopy from '~icons/ph/copy-bold'
import IconDownload from '~icons/ph/download-simple-bold'
import IconTrash from '~icons/ph/trash-bold'
import { orderBy } from 'lodash-es'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Snackbar, useSnackbars } from 'sefirot/stores/Snackbars'
import { day } from 'sefirot/support/Day'
import { type UnwrapRef, computed, ref } from 'vue'
import { RELEASE_DATE_OF_UNIFIED_FINANCIAL_FIGURES } from '@/constants/FinancialFigures'
import {
  type CompanyBusinessReportFinanceTransitionRecordFrag,
  type CompanyBusinessReportFinanceTransitionRecordRowFrag,
  type CompanyBusinessReportFinancialFiguresAnnotation,
  type CompanyBusinessReportFinancialFiguresUnit,
  type CompanyBusinessReportFinancialResultInput,
  type CompanyBusinessReportFrag,
  type CompanyBusinessReportPageFrag,
  type CurrencyFrag,
  type FundReportRecordStatus,
  type PreviousCompanyBusinessReportFrag,
  type UpdateCompanyBusinessReportInput
} from '@/graphql'
import { type Currency } from '@/models/Currency'
import { type Ymd, yearMonthFromYmdOrNull, ymdFromYearMonth } from '@/support/Day'
import { toNumberOrNull } from '@/support/Num'
import { toStringOrNull } from '@/support/Str'
import { hasProp } from '@/support/Utils'
import { useCompanyBusinessReportOps } from '@/composables/ops/CompanyBusinessReportOps'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useFundReportOps } from '@/composables/ops/FundReportOps'
import { useCanDeleteCompanyBusinessReport, useCanSaveCompanyBusinessReport } from '@/composables/policies/CompanyBusinessReportPolicy'
import {
  useCompleteCompanyBusinessReport,
  useDownloadCompanyBusinessReport,
  useIncompleteCompanyBusinessReport,
  useRequestChangesOnCompanyBusinessReport,
  useRequestReviewOnCompanyBusinessReport,
  useStartReviewOnCompanyBusinessReport,
  useUpdateCompanyBusinessReport,
  useWithdrawReviewOnCompanyBusinessReport
} from '@/composables/repos/CompanyBusinessReportRepo'
import { useCompanyPortfolioDataItemForCompanyBusinessReportCopy } from '@/composables/repos/CompanyPortfolioDataRepo'
import CompanyBusinessReportFormUpdateControl, { type CompanyBusinessReportFormUpdateControlItem } from '@/components/company-business-report/CompanyBusinessReportFormUpdateControl.vue'
import CompanyBusinessReportFormUpdateInfo from '@/components/company-business-report/CompanyBusinessReportFormUpdateInfo.vue'
import CompanyBusinessReportFormUpdateMain from '@/components/company-business-report/CompanyBusinessReportFormUpdateMain.vue'
import CopmanyBusinessReportCopyFromCompany, { type NewDataFromCompany } from '@/components/company-business-report/CopmanyBusinessReportCopyFromCompany.vue'
import CopmanyBusinessReportCopyFromOther, { type NewDataFromCompanyBusinessReport } from '@/components/company-business-report/CopmanyBusinessReportCopyFromOther.vue'
import CopmanyBusinessReportSelectOther from '@/components/company-business-report/CopmanyBusinessReportSelectOther.vue'
import CopmanyBusinessReportUpdateCompanyPrompt from '@/components/company-business-report/CopmanyBusinessReportUpdateCompanyPrompt.vue'
import { type Share } from '../DInputShares.vue'
import { type FinancialResultData } from './CompanyBusinessReportInputFinancialResults.vue'
import CompanyBusinessReportStatusControl from './CompanyBusinessReportStatusControl.vue'
import CompanyBusinessReportTitle from './CompanyBusinessReportTitle.vue'

const props = defineProps<{
  companyBusinessReport: CompanyBusinessReportFrag
  previousCompanyBusinessReport?: PreviousCompanyBusinessReportFrag | null
  copyableCompanyBusinessReports?: CompanyBusinessReportPageFrag['items'] | null
  units: CompanyBusinessReportFinancialFiguresUnit[]
  annotations: CompanyBusinessReportFinancialFiguresAnnotation[]
  currencies: CurrencyFrag[]
}>()

const emit = defineEmits<{
  updated: [status: FundReportRecordStatus, updatedAt: string]
}>()

const { t } = useTrans({
  en: {
    notification_download: 'The file will be sent to you via Slack shortly. If the file doesn\'t arrive after a while, please get in touch with a system administrator for help.',
    notification_copy_completed: 'Data has been copied successfully.',
    notification_save_completed: 'The business report has been saved successfully.',
    notification_review_requested: 'Review on the business report has been requested successfully.',
    notification_review_started: 'Review on the business report has been started successfully.',
    notification_review_withdrawn: 'Review on on the business report has been withdrawn successfully.',
    notification_change_requested: 'Change on on the business report has been requested successfully.',
    notification_report_completed: 'The business report has been completed successfully.',
    notification_report_incompleted: 'The business report has been completed successfully.',
    notification_unsupported_currency: (companyName: string, currencyNames: string[]) => `Financial figures couldn't be merged because the following unsupported currencies are used in ${companyName}'s data (${currencyNames.join(', ')}). Contact system administrator to make them available in company business report.`,
    open_company: 'Open company',
    open_previous_report: 'Open previous report',
    fill_in_from_company_data: 'Fill in from company data',
    fill_in_from_previous_report: 'Fill in from previous report',
    fill_in_from_other_report: 'Fill in from other report',
    export_as_word_file: 'Export as Word file',
    delete_this_report: 'Delete this report'
  },
  ja: {
    notification_download: 'まもなくファイルがSlackに送信されます。しばらく待ってもファイルが届かない場合は、システム管理者に問い合わせてください。',
    notification_copy_completed: 'データがコピーされました。',
    notification_save_completed: '報告書が保存されました。',
    notification_review_requested: '報告書へのレビューが依頼されました。',
    notification_review_started: '報告書のレビューが開始されました。',
    notification_review_withdrawn: '報告書へのレビュー依頼が取り下げられました。',
    notification_change_requested: '報告書への変更が依頼されました。',
    notification_report_completed: '報告書が完了されました。',
    notification_report_incompleted: '報告書の完了が取り消されました。',
    notification_unsupported_currency: (companyName: string, currencyNames: string[]) => `${companyName} のデータで次のサポートされていない通貨が使用されているため、財務数値のコピーができません（${currencyNames}）。この通貨を報告書で使用できるようにするためには、システム管理者に連絡してください。`,
    open_company: '会社ページをひらく',
    open_previous_report: '前回の報告書をひらく',
    fill_in_from_company_data: '会社情報からコピーする',
    fill_in_from_previous_report: '前回の報告書からコピーする',
    fill_in_from_other_report: '他の報告書からコピーする',
    export_as_word_file: 'Wordファイルとしてエクスポートする',
    delete_this_report: '報告書を削除する'
  }
})

const snackbars = useSnackbars()

const companyBusinessReportOps = useCompanyBusinessReportOps()
const companyOps = useCompanyOps()
const fundReportOps = useFundReportOps()

const { execute: executeSave, loading: loadingSave } = useUpdateCompanyBusinessReport()
const { execute: executeRequestReview, loading: loadingRequestReview } = useRequestReviewOnCompanyBusinessReport()
const { execute: executeStartReview, loading: loadingStartReview } = useStartReviewOnCompanyBusinessReport()
const { execute: executeWithdrawReview, loading: loadingWithdrawReview } = useWithdrawReviewOnCompanyBusinessReport()
const { execute: executeRequestChanges, loading: loadingRequestChanges } = useRequestChangesOnCompanyBusinessReport()
const { execute: executeComplete, loading: loadingComplete } = useCompleteCompanyBusinessReport()
const { execute: executeIncomplete, loading: loadingIncomplete } = useIncompleteCompanyBusinessReport()
const { execute: executeDownload, loading: loadingDownload } = useDownloadCompanyBusinessReport()
const { data: portfolioData, execute: executeFinancialFigures } = useCompanyPortfolioDataItemForCompanyBusinessReportCopy(() => props.companyBusinessReport.company.id)

const financialFiguresNotes = computed(() => portfolioData.value?.financialFigures.notes ?? null)
const financialFiguresNotesUpdatedAt = computed(() => portfolioData.value?.financialFigures.notesUpdatedAt ? day(portfolioData.value.financialFigures.notesUpdatedAt) : null)

const isOldFinancialFigures = computed(() => {
  return fundReportOps.date(props.companyBusinessReport.report).isBefore(day(RELEASE_DATE_OF_UNIFIED_FINANCIAL_FIGURES)) ?? false
})

const reportOperationState = computed(() => ({
  save: {
    loading: loadingSave.value,
    disabled: loadingRequestReview.value || loadingStartReview.value || loadingWithdrawReview.value || loadingRequestChanges.value || loadingComplete.value || loadingIncomplete.value || loadingDownload.value
  },
  requestReview: {
    loading: loadingRequestReview.value,
    disabled: loadingSave.value || loadingStartReview.value || loadingWithdrawReview.value || loadingRequestChanges.value || loadingComplete.value || loadingIncomplete.value || loadingDownload.value
  },
  startReview: {
    loading: loadingStartReview.value,
    disabled: loadingSave.value || loadingRequestReview.value || loadingWithdrawReview.value || loadingRequestChanges.value || loadingComplete.value || loadingIncomplete.value || loadingDownload.value
  },
  withdrawReview: {
    loading: loadingWithdrawReview.value,
    disabled: loadingSave.value || loadingRequestReview.value || loadingStartReview.value || loadingRequestChanges.value || loadingComplete.value || loadingIncomplete.value || loadingDownload.value
  },
  requestChanges: {
    loading: loadingRequestChanges.value,
    disabled: loadingSave.value || loadingRequestReview.value || loadingStartReview.value || loadingWithdrawReview.value || loadingComplete.value || loadingIncomplete.value || loadingDownload.value
  },
  complete: {
    loading: loadingComplete.value,
    disabled: loadingSave.value || loadingRequestReview.value || loadingStartReview.value || loadingWithdrawReview.value || loadingRequestChanges.value || loadingIncomplete.value || loadingDownload.value
  },
  incomplete: {
    loading: loadingIncomplete.value,
    disabled: loadingSave.value || loadingRequestReview.value || loadingStartReview.value || loadingWithdrawReview.value || loadingRequestChanges.value || loadingComplete.value || loadingDownload.value
  }
}))

export type ReportOperationState = UnwrapRef<typeof reportOperationState>

function createUpdateData(): UpdateCompanyBusinessReportInput {
  return {
    address: data.value.address,
    capital: (data.value.capitalCurrency && data.value.capitalAmount)
      ? { currencyId: data.value.capitalCurrency!.id!, value: data.value.capitalAmount! }
      : null,
    capitalNotes: data.value.capitalNotes,
    description: data.value.description,
    financeTransition: data.value.financeTransition,
    financeTransitionRecords: data.value.financeTransitionRecords,
    financialFiguresAnnotationId: data.value.financialFiguresAnnotationId,
    financialResultNotes: data.value.financialResultNotes,
    financialResults: data.value.financialResults.reduce<CompanyBusinessReportFinancialResultInput[]>((carry, result) => {
      if (isFinancialResultEmpty(result)) {
        return carry
      }

      carry.push({
        period: yearMonthFromYmdOrNull({ year: result.period!.year!, month: result.period!.month!, date: null }),
        unitId: toNumberOrNull(result.unitId),
        netSales: toStringOrNull(result.netSales),
        ordinaryIncome: toStringOrNull(result.ordinaryIncome),
        netIncome: toStringOrNull(result.netIncome),
        totalAssets: toStringOrNull(result.totalAssets),
        capital: toStringOrNull(result.capital),
        netAssets: toStringOrNull(result.netAssets),
        updatedBy: result.updatedBy?.id ?? null,
        updatedAt: result.updatedAt
      })
      return carry
    }, []),
    foundedYearMonth: yearMonthFromYmdOrNull(data.value.foundedYearMonth ?? { year: null, month: null, date: null }),
    issuedShares: data.value.issuedShares.map(({ name, count }) => ({ name: name!, count: toStringOrNull(count)! })),
    issuedSharesNotes: data.value.issuedSharesNotes,
    officialName: data.value.officialName,
    otherNotes: data.value.otherNotes,
    overviewAndPerspective: data.value.overviewAndPerspective,
    pastNames: data.value.pastNames,
    potentialDilutiveSharesNotes: data.value.potentialDilutiveSharesNotes,
    representativeName: data.value.representativeName,
    status: props.companyBusinessReport.status!,
    totalPotentialDilutiveShares: data.value.totalPotentialDilutiveShares
  }

  function isFinancialResultEmpty(result: FinancialResultData): boolean {
    return [
      result.period?.year ?? null,
      result.period?.month ?? null,
      result.unitId,
      result.netSales,
      result.ordinaryIncome,
      result.netIncome,
      result.totalAssets,
      result.capital,
      result.netAssets
    ].every((figure) => figure === null)
  }
}

async function executeAndNotify(executeFn: () => Promise<any>, snackbar: Snackbar | Snackbar['text'], shouldBeStrict = true) {
  strict.value = shouldBeStrict

  if (await validateAndNotify()) {
    await executeFn()
    snackbars.push({ mode: 'info', ...(typeof snackbar === 'string' ? { text: snackbar } : snackbar) })
  }
}

async function save() {
  const updated = await executeSave(props.companyBusinessReport.id, createUpdateData())
  emit('updated', updated.status, updated.updatedAt)
}

async function saveAndShowCompanyPrompt() {
  await save()
  copyToCompanyPromptModalOn()
}

async function saveAndRequestReview() {
  await save()
  const updated = await executeRequestReview(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function saveAndStartReview() {
  await save()
  const updated = await executeStartReview(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function saveAndWithdrawReview() {
  await save()
  const updated = await executeWithdrawReview(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function saveAndRequestChanges() {
  await save()
  const updated = await executeRequestChanges(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function saveAndComplete() {
  await save()
  const updated = await executeComplete(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function incomplete() {
  const updated = await executeIncomplete(props.companyBusinessReport.id)
  emit('updated', updated.status, updated.updatedAt)
}

async function downloadReport() {
  executeAndNotify(() => executeDownload(props.companyBusinessReport.id), t.notification_download, false)
}

const { data } = useData({
  officialName: props.companyBusinessReport.officialName ?? null as string | null,
  pastNames: props.companyBusinessReport.pastNames ?? null as string | null,
  representativeName: props.companyBusinessReport.representativeName ?? null as string | null,
  address: props.companyBusinessReport.address ?? null as string | null,
  foundedYearMonth: companyBusinessReportOps.foundedYearMonthAsYmd(props.companyBusinessReport) ?? undefined as Ymd | undefined,
  description: props.companyBusinessReport.description ?? null as string | null,
  issuedShares: (props.companyBusinessReport.issuedShares.map(({ name, count }) => ({ name, count: toNumberOrNull(count) })) ?? []) as Share[],
  issuedSharesNotes: props.companyBusinessReport.issuedSharesNotes ?? null as string | null,
  totalPotentialDilutiveShares: props.companyBusinessReport.totalPotentialDilutiveShares ?? null as number | null,
  potentialDilutiveSharesNotes: props.companyBusinessReport.potentialDilutiveSharesNotes ?? null as string | null,
  capitalAmount: props.companyBusinessReport.capital?.value ?? null as string | null,
  capitalCurrency: getDefaultCurrency() as CurrencyFrag | Currency,
  capitalNotes: props.companyBusinessReport.capitalNotes ?? null as string | null,
  otherNotes: props.companyBusinessReport.otherNotes ?? null as string | null,
  financialResults: createFinancialResults(props.companyBusinessReport.financialResults),
  financialFiguresAnnotationId: props.companyBusinessReport.financialFiguresAnnotationId ?? null as | null,
  financialResultNotes: props.companyBusinessReport.financialResultNotes ?? null as string | null,
  financeTransition: props.companyBusinessReport.financeTransition ?? null as string | null,
  financeTransitionRecords: props.companyBusinessReport.financeTransitionRecords ?? null as CompanyBusinessReportFinanceTransitionRecordFrag[] | null,
  overviewAndPerspective: props.companyBusinessReport.overviewAndPerspective ?? null as string | null
})

export type Data = UnwrapRef<typeof data>

const { validateAndNotify } = useValidation()

const strict = ref(false)

const canSaveCompanyBusinessReport = useCanSaveCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canDeleteCompanyBusinessReport = useCanDeleteCompanyBusinessReport()

const { state: copyCompanyModal, on: copyCompanyModalOn, off: copyCompanyModalOff } = usePower()
const { state: copyPreviousReportModal, on: copyPreviousReportModalOn, off: copyPreviousReportModalOff } = usePower()
const { state: copyOtherReportModal, on: copyOtherReportModalOn, off: copyOtherReportModalOff } = usePower()
const { state: copyToCompanyPromptModal, on: copyToCompanyPromptModalOn, off: copyToCompanyPromptModalOff } = usePower()

const controlItems = computed<CompanyBusinessReportFormUpdateControlItem[]>(() => [
  {
    icon: IconArrowSquareOut,
    text: t.open_company,
    href: `/companies/${props.companyBusinessReport.company.id}`,
    hidden: !props.companyBusinessReport.company.id,
    external: true
  },
  {
    icon: IconArrowSquareOut,
    text: t.open_previous_report,
    href: `/company-business-reports/${props.previousCompanyBusinessReport?.id}`,
    hidden: !props.previousCompanyBusinessReport?.id,
    external: true
  },
  {
    icon: IconCopy,
    text: t.fill_in_from_company_data,
    hidden: !canSaveCompanyBusinessReport.value.ok,
    onClick: copyCompanyModalOn
  },
  {
    icon: IconCopy,
    text: t.fill_in_from_previous_report,
    hidden: !props.previousCompanyBusinessReport?.id || !canSaveCompanyBusinessReport.value.ok,
    onClick: copyPreviousReportModalOn
  },
  {
    icon: IconCopy,
    text: t.fill_in_from_other_report,
    hidden: !props.copyableCompanyBusinessReports?.length || !canSaveCompanyBusinessReport.value.ok,
    onClick: copyOtherReportModalOn
  },
  {
    icon: IconDownload,
    text: t.export_as_word_file,
    onClick: downloadReport
  },
  {
    icon: IconTrash,
    text: t.delete_this_report,
    mode: 'danger',
    hidden: !canDeleteCompanyBusinessReport.value.ok,
    onClick: () => {}
  }
])

function getDefaultCurrency() {
  if (props.companyBusinessReport.capital) {
    return props.companyBusinessReport.capital.currency
  }

  return props.currencies.find((c) => c.name === 'JPY')!
}

function copyDataFromCompany(newData: NewDataFromCompany) {
  Object.keys(data.value).forEach((key) => {
    if (hasProp(newData, key)) {
      (data.value as any)[key] = newData[key]
    }
  })
}

const financeTransitionRecordPlaceholders = ref<CompanyBusinessReportFinanceTransitionRecordRowFrag[][]>(Array(data.value.financeTransitionRecords?.length ?? 0).fill([]))

async function refreshFinancialFigures() {
  const portfolioData = await executeFinancialFigures()
  const unsupportedCurrencies: CurrencyFrag[] = []
  const unsupportedUnitError = new Error('unit unsupported')

  try {
    if (portfolioData?.financialFigures) {
      const reportDate = day(props.companyBusinessReport.report.date).endOf('month')
      const results = orderBy(portfolioData.financialFigures.results, ['period'])
        .filter((result) => {
          const resultDate = day(result.period!).endOf('month')
          return resultDate.isSame(reportDate) || resultDate.isBefore(reportDate)
        })
        .slice(-4)
        .map((result) => {
          const unit = props.units.find(({ currencyId }) => currencyId === result.currency?.id)

          if (unit) {
            return {
              period: result.period,
              unitId: unit.id as number | null,
              netSales: result.netSales,
              ordinaryIncome: result.ordinaryIncome,
              netIncome: result.netIncome,
              totalAssets: result.totalAssets,
              capital: result.capital,
              netAssets: result.netAssets,
              updatedBy: result.updatedBy,
              updatedAt: result.updatedAt
            }
          }

          unsupportedCurrencies.push(result.currency!)

          return {} as CompanyBusinessReportFrag['financialResults'][number]
        })

      if (unsupportedCurrencies.length) {
        throw unsupportedUnitError
      }

      data.value.financialResults = createFinancialResults(results)
    }
  } catch (e) {
    if (e === unsupportedUnitError) {
      const companyName = companyOps.name(props.companyBusinessReport.company)
      const currencyNames = unsupportedCurrencies.map(({ name }) => name)
      snackbars.push({ mode: 'danger', text: t.notification_unsupported_currency(companyName, currencyNames) })
      return
    }
    throw e
  }
}

function createFinancialResults(results: CompanyBusinessReportFrag['financialResults']) {
  return [0, 1, 2, 3].map<FinancialResultData>((index) => {
    const result = results[index]

    return {
      period: ymdFromYearMonth(result?.period ?? null),
      unitId: toNumberOrNull(result?.unitId),
      netSales: toNumberOrNull(result?.netSales),
      ordinaryIncome: toNumberOrNull(result?.ordinaryIncome),
      netIncome: toNumberOrNull(result?.netIncome),
      totalAssets: toNumberOrNull(result?.totalAssets),
      capital: toNumberOrNull(result?.capital),
      netAssets: toNumberOrNull(result?.netAssets),
      updatedBy: result?.updatedBy,
      updatedAt: result?.updatedAt
    }
  })
}

function copyDataFromCompanyBusinessReport(newData: NewDataFromCompanyBusinessReport) {
  Object.keys(data.value).forEach((key) => {
    if (hasProp(newData, key)) {
      (data.value as any)[key] = newData[key]
    }
  })
}

function notifyCopyComplete() {
  snackbars.push({ mode: 'info', text: t.notification_copy_completed })
}
</script>

<template>
  <SCard class="CompanyBusinessReportFormUpdate">
    <div class="container">
      <CompanyBusinessReportTitle :company-business-report="companyBusinessReport" />
      <CompanyBusinessReportStatusControl
        :company-business-report="companyBusinessReport"
        :operation-state="reportOperationState"
        @save="executeAndNotify(save, t.notification_save_completed, false)"
        @request-review="executeAndNotify(saveAndShowCompanyPrompt, t.notification_save_completed)"
        @start-review="executeAndNotify(saveAndStartReview, t.notification_review_started)"
        @withdraw-review="executeAndNotify(saveAndWithdrawReview, t.notification_review_withdrawn)"
        @request-changes="executeAndNotify(saveAndRequestChanges, t.notification_change_requested, false)"
        @complete="executeAndNotify(saveAndComplete, t.notification_report_completed)"
        @incomplete="executeAndNotify(incomplete, t.notification_report_incompleted)"
      />
      <SCardBlock class="grid">
        <div class="grid-main">
          <CompanyBusinessReportFormUpdateMain
            :form-data="data"
            :strict="strict"
            :annotations="annotations"
            :currencies="currencies"
            :units="units"
            :company-business-report="companyBusinessReport"
            :is-old-financial-figures="isOldFinancialFigures"
            :financial-figures-notes="financialFiguresNotes"
            :financial-figures-notes-updated-at="financialFiguresNotesUpdatedAt"
            :finance-transition-record-placeholders="financeTransitionRecordPlaceholders"
            @update:official-name="data.officialName = $event"
            @update:past-names="data.pastNames = $event"
            @update:representative-name="data.representativeName = $event"
            @update:founded-year-month="data.foundedYearMonth = $event"
            @update:address="data.address = $event"
            @update:description="data.description = $event"
            @update:issued-shares="data.issuedShares = $event"
            @update:issued-shares-notes="data.issuedSharesNotes = $event"
            @update:total-potential-dilutive-shares="data.totalPotentialDilutiveShares = $event"
            @update:potential-dilutive-shares-notes="data.potentialDilutiveSharesNotes = $event"
            @update:capital-amount="data.capitalAmount = $event"
            @update:capital-currency="data.capitalCurrency = $event"
            @update:capital-notes="data.capitalNotes = $event"
            @update:other-notes="data.otherNotes = $event"
            @update:financial-results="data.financialResults = $event"
            @update:financial-figures-annotation-id="data.financialFiguresAnnotationId = $event"
            @update:financial-result-notes="data.financialResultNotes = $event"
            @update:finance-transition="data.financeTransition = $event"
            @update:finance-transition-records="data.financeTransitionRecords = $event"
            @update:overview-and-perspective="data.overviewAndPerspective = $event"
            @refresh-financial-figures="refreshFinancialFigures"
          />
        </div>
        <div class="grid-sidebar">
          <CompanyBusinessReportFormUpdateInfo
            :status="companyBusinessReport.status"
            :updated-by="companyBusinessReport.updatedBy"
            :updated-at="companyBusinessReportOps.updatedAt(companyBusinessReport)"
            :company="companyBusinessReport.company"
          />
          <CompanyBusinessReportFormUpdateControl :items="controlItems" />
          <SModal :open="copyCompanyModal">
            <CopmanyBusinessReportCopyFromCompany
              :current-data="data"
              :company-id="companyBusinessReport.company.id"
              :report-date="fundReportOps.date(companyBusinessReport.report)"
              :copy-data="copyDataFromCompany"
              :default-currency="getDefaultCurrency()"
              @cancel="copyCompanyModalOff"
              @copied="copyCompanyModalOff(() => notifyCopyComplete())"
            />
          </SModal>
          <SModal :open="copyPreviousReportModal">
            <CopmanyBusinessReportCopyFromOther
              v-if="previousCompanyBusinessReport"
              :is-old-financial-figures="isOldFinancialFigures"
              :current-data="data"
              :company-business-report="previousCompanyBusinessReport"
              :copy-data="copyDataFromCompanyBusinessReport"
              :annotations="annotations"
              @cancel="copyPreviousReportModalOff"
              @copied="copyPreviousReportModalOff(() => notifyCopyComplete())"
            />
          </SModal>
          <SModal :open="copyOtherReportModal">
            <CopmanyBusinessReportSelectOther
              v-if="copyableCompanyBusinessReports?.length"
              :current-data="data"
              :copyable-company-business-reports="copyableCompanyBusinessReports"
              :copy-data="copyDataFromCompanyBusinessReport"
              :annotations="annotations"
              @cancel="copyOtherReportModalOff"
              @copied="copyOtherReportModalOff(() => notifyCopyComplete())"
            />
          </SModal>
          <SModal :open="copyToCompanyPromptModal">
            <CopmanyBusinessReportUpdateCompanyPrompt
              :report-data="data"
              :company-id="companyBusinessReport.company.id"
              :units="units"
              @cancel="copyToCompanyPromptModalOff"
              @request-review="copyToCompanyPromptModalOff(() => executeAndNotify(saveAndRequestReview, t.notification_review_requested))"
            />
          </SModal>
        </div>
      </SCardBlock>
    </div>
  </SCard>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportFormUpdate {
  margin: 0 auto;
  max-width: 1216px;
}

.grid {
  display: grid;
  gap: 1px;
  grid-template-columns: minmax(664px, 1fr) minmax(344px, 384px);
  background-color: var(--c-gutter);
}

.grid-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
</style>
