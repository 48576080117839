<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type Fund } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import DealEntryListItem from './DealEntryListItem.vue'

const props = defineProps<{
  deal: Deal
  fund: Fund
}>()

const { t } = useTrans({
  en: {
    prc_title: 'Pre Review Committee',
    prc_req_title: 'Request Pre Review Committee',
    prc_req_desc: 'Make a request to hold a Pre Review Committee.',
    prc_doc_title: 'Send Docs for Pre Review Committee',
    prc_doc_desc: 'Send docs required to hold Pre Review Committee.',
    dd_title: 'DD Kickoff',
    dd_req_full_title: 'Request DD Kickoff MTG (New Deal)',
    dd_req_full_desc: 'Make a request to kick off due diligence for a new deal.',
    dd_req_min_title: 'Request DD Kickoff MTG (Follow-up Deal)',
    dd_req_min_desc: 'Make a request to kick off due diligence for a follow up deal.',
    ep_title: 'Executive Presentation',
    ep_req_title: 'Request Executive Presentation',
    ep_req_desc: 'Make a request to hold a Executive Presentation meeting.',
    ep_doc_title: 'Send Docs for Executive Presentation',
    ep_doc_desc: 'Send Docs for Executive Presentation meeting.',
    ic_title: 'Investment Committee',
    ic_req_title: 'Request Investment Committee',
    ic_req_desc: 'Make a request to hold a Investment Committee.',
    ic_doc_title: 'Send Docs for Investment Committee',
    ic_doc_desc: 'Send docs required to hold Investment committee.'
  },
  ja: {
    prc_title: '社内プレ検討会',
    prc_req_title: '社内プレ検討会 開催依頼',
    prc_req_desc: '社内プレ検討会を開催するためのエントリーです。',
    prc_doc_title: '社内プレ検討会 資料送付',
    prc_doc_desc: '社内プレ検討会に必要な資料を送付するためのエントリーです。',
    dd_title: 'DD Kickoff',
    dd_req_full_title: 'DD Kickoff MTG 開催依頼（新規）',
    dd_req_full_desc: '新規ディールのDD Kickoffを開催するためのエントリーです。',
    dd_req_min_title: 'DD Kickoff MTG 開催依頼（追加）',
    dd_req_min_desc: '追加出資ディールのDD Kickoffを開催するためのエントリーです。',
    ep_title: '経営者プレゼン',
    ep_req_title: '経営者プレゼン 開催依頼',
    ep_req_desc: '経営者プレゼンを開催するためのエントリーです。',
    ep_doc_title: '経営者プレゼン 資料送付',
    ep_doc_desc: '経営者プレゼンに必要な資料を送付するためのエントリーです。',
    ic_title: '投資委員会',
    ic_req_title: '投資委員会 開催依頼',
    ic_req_desc: '投資委員会を開催するためのエントリーです。',
    ic_doc_title: '投資委員会 資料確認依頼',
    ic_doc_desc: '投資委員会に必要な資料を送付するためのエントリーです。'
  }
})

const settings = computed(() => props.fund.dealEntrySettings)
</script>

<template>
  <div class="DealEntryList">
    <div class="desc">
      <STrans lang="en">
        <div class="heading">Make entries</div>
        <div class="text">Here you can make entries to various events that deals must go through. Select the type of entry and fill in the form. Learn more about GB’s investment process at “<SLink href="https://drive.google.com/drive/u/0/folders/1yWiBcdPwG4UEAZNGe2yahnb-RHYYJlKu">GB Resources: Investment process</SLink>”.</div>
      </STrans>
      <STrans lang="ja">
        <div class="heading">会議体へのエントリー</div>
        <div class="text">ここでは、ディールを進行させるために必要な様々な会議体へエントリーできます。会議体の種別を選択し、フォームを入力してください。GBの投資プロセスについての詳細は、「<SLink href="https://drive.google.com/drive/u/0/folders/1yWiBcdPwG4UEAZNGe2yahnb-RHYYJlKu">GB Resources: Investment process</SLink>」で確認することができます。</div>
      </STrans>
    </div>
    <div class="list">
      <div
        v-if="settings.preReviewCommitteeRequest || settings.preReviewCommitteeSubmitDoc"
        class="section"
      >
        <div class="title">{{ t.prc_title }}</div>
        <div class="items">
          <div class="item">
            <DealEntryListItem
              v-if="settings.preReviewCommitteeRequest"
              type="request"
              :title="t.prc_req_title"
              :desc="t.prc_req_desc"
              :link="`${deal.path}/entries/request-prc`"
            />
          </div>
          <div class="item">
            <DealEntryListItem
              v-if="settings.preReviewCommitteeSubmitDoc"
              type="docs"
              :title="t.prc_doc_title"
              :desc="t.prc_doc_desc"
              :link="`${deal.path}/entries/submit-docs-for-prc`"
            />
          </div>
        </div>
      </div>

      <div
        v-if="settings.ddKickOffFullRequest || settings.ddKickOffMinimalRequest"
        class="section"
      >
        <div class="title">{{ t.dd_title }}</div>
        <div class="items">
          <div class="item">
            <DealEntryListItem
              v-if="settings.ddKickOffFullRequest"
              type="request"
              :title="t.dd_req_full_title"
              :desc="t.dd_req_full_desc"
              :link="`${deal.path}/entries/request-full-dd`"
            />
          </div>
          <div class="item">
            <DealEntryListItem
              v-if="settings.ddKickOffMinimalRequest"
              type="request"
              :title="t.dd_req_min_title"
              :desc="t.dd_req_min_desc"
              :link="`${deal.path}/entries/request-minimal-dd`"
            />
          </div>
        </div>
      </div>

      <div
        v-if="settings.executivePresentationRequest || settings.executivePresentationSubmitDoc"
        class="section"
      >
        <div class="title">{{ t.ep_title }}</div>
        <div class="items">
          <div class="item">
            <DealEntryListItem
              v-if="settings.executivePresentationRequest"
              type="request"
              :title="t.ep_req_title"
              :desc="t.ep_req_desc"
              :link="`${deal.path}/entries/request-ep`"
            />
          </div>
          <div class="item">
            <DealEntryListItem
              v-if="settings.executivePresentationSubmitDoc"
              type="docs"
              :title="t.ep_doc_title"
              :desc="t.ep_doc_desc"
              :link="`${deal.path}/entries/submit-docs-for-ep`"
            />
          </div>
        </div>
      </div>

      <div
        v-if="settings.investmentCommitteeRequest || settings.investmentCommitteeSubmitDoc"
        class="section"
      >
        <div class="title">{{ t.ic_title }}</div>
        <div class="items">
          <div class="item">
            <DealEntryListItem
              v-if="settings.investmentCommitteeRequest"
              type="request"
              :title="t.ic_req_title"
              :desc="t.ic_req_desc"
              :link="`${deal.path}/entries/request-ic`"
            />
          </div>
          <div class="item">
            <DealEntryListItem
              v-if="settings.investmentCommitteeSubmitDoc"
              type="docs"
              :title="t.ic_doc_title"
              :desc="t.ic_doc_desc"
              :link="`${deal.path}/entries/submit-docs-for-ic`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryList {
  display: grid;
  gap: 32px;
}

.desc {
  border-bottom: 1px solid var(--c-divider);
  padding-bottom: 28px;
}

.heading {
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
  color: var(--c-text-1);
}

.text {
  padding-top: 8px;
  max-width: 592px;
  font-size: 14px;
  color: var(--c-text-2);

  & .link {
    color: var(--c-text-info-1);
    transition: color 0.25s;
  }

  & .link:hover {
    color: var(--c-text-info-2);
  }
}

.list {
  display: grid;
  gap: 32px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--c-text-2);
}

.items {
  display: grid;
  gap: 12px;
  padding-top: 12px;
}
</style>
