<script setup lang="ts">
import SInputNumber, { type TextColor } from 'sefirot/components/SInputNumber.vue'
import SInputSelect, { type Option as SInputSelectOption } from 'sefirot/components/SInputSelect.vue'
import SInputYMD, { type Value as SInputYMDValue } from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, required, requiredYmd, ymd } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { toStringOrNull } from '@/support/Str'
import { useCreateCompanyFinancialResult } from '@/composables/repos/CompanyPortfolioDataRepo'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  cancel: []
  created: []
}>()

const { t } = useTrans({
  en: {
    title: 'Add financial result',
    lead: 'The values to be entered should be those of the financial, statements approved at the general meeting of shareholders, not those of the trial balance.',
    period: 'Period',
    currency: 'Currency',
    net_sales: 'Net sales',
    ordinary_income: 'Ordinary income',
    net_income: 'Net income',
    total_assets: 'Total assets',
    capital: 'Capital',
    net_assets: 'Net assets',
    i_currency_ph: 'Select currency',
    cancel: 'Cancel',
    add: 'Add'
  },
  ja: {
    title: '財務数値の追加',
    lead: '試算表の値ではなく、ステークホルダーが出席する会議で承認された財務諸表の値を入力してください。',
    period: '決算期',
    currency: '通貨',
    net_sales: '純売上',
    ordinary_income: '経常利益',
    net_income: '純利益',
    total_assets: '総資産',
    capital: '資本金',
    net_assets: '純資産',
    i_currency_ph: '通貨を選択',
    cancel: 'キャンセル',
    add: '追加'
  }
})

const { data: currencies } = useCurrencyList()
const currencyOptions = computed(() => (currencies.value ?? []).map<SInputSelectOption>(({ symbol, name, id, enabled }) => ({
  label: `${symbol} ${name}`,
  value: id,
  disabled: !enabled
})))

const { data } = useData({
  period: {
    year: null,
    month: null,
    date: null
  } as SInputYMDValue,
  currencyId: null as number | null,
  netSales: null as number | null,
  ordinaryIncome: null as number | null,
  netIncome: null as number | null,
  totalAssets: null as number | null,
  capital: null as number | null,
  netAssets: null as number | null
})

const { validation, validateAndNotify } = useValidation(data, {
  period: {
    required: requiredYmd(['y', 'm']),
    ymd: ymd(['y', 'm'])
  },
  currencyId: {
    required: required()
  },
  netSales: {
    required: required(),
    decimal: decimal()
  },
  ordinaryIncome: {
    required: required(),
    decimal: decimal()
  },
  netIncome: {
    required: required(),
    decimal: decimal()
  },
  totalAssets: {
    required: required(),
    decimal: decimal()
  },
  capital: {
    required: required(),
    decimal: decimal()
  },
  netAssets: {
    required: required(),
    decimal: decimal()
  }
})

function getTextColor(value: number | null): TextColor {
  const _value = value == null ? 0 : +value

  if (_value > 0) {
    return 'success'
  }

  if (_value < 0) {
    return 'danger'
  }

  return 'neutral'
}

const { execute, loading } = useCreateCompanyFinancialResult()

async function create() {
  if (await validateAndNotify()) {
    await execute(props.companyId, {
      period: `${data.value.period.year}-${String(data.value.period.month).padStart(2, '0')}`,
      currencyId: data.value.currencyId!,
      netSales: toStringOrNull(data.value.netSales),
      ordinaryIncome: toStringOrNull(data.value.ordinaryIncome),
      netIncome: toStringOrNull(data.value.netIncome),
      totalAssets: toStringOrNull(data.value.totalAssets),
      capital: toStringOrNull(data.value.capital),
      netAssets: toStringOrNull(data.value.netAssets)
    })
    emit('created')
  }
}
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-16">
      <SDoc>
        <SContent>
          <div class="s-font-20 s-font-w-500">{{ t.title }}</div>
          <p class="lead">{{ t.lead }}</p>
        </SContent>
      </SDoc>
      <div class="container">
        <ul class="table">
          <li class="row">
            <p class="cell header bg">{{ t.period }}</p>
            <div class="cell data">
              <SInputYMD
                size="mini"
                no-date
                block
                v-model="data.period"
                :validation="validation.period"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.currency }}</p>
            <div class="cell data">
              <SInputSelect
                size="mini"
                v-model="data.currencyId"
                :options="currencyOptions"
                :placeholder="t.i_currency_ph"
                :validation="validation.currencyId"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.net_sales }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.netSales"
                :validation="validation.netSales"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.ordinary_income }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.ordinaryIncome"
                :validation="validation.ordinaryIncome"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.net_income }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.netIncome"
                :validation="validation.netIncome"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.total_assets }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.totalAssets"
                :validation="validation.totalAssets"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.capital }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.capital"
                :validation="validation.capital"
                hide-error
              />
            </div>
          </li>
          <li class="row">
            <p class="cell header bg">{{ t.net_assets }}</p>
            <div class="cell data">
              <SInputNumber
                size="mini"
                separator
                align="right"
                :text-color="getTextColor"
                placeholder="1,000,000"
                v-model="data.netAssets"
                :validation="validation.netAssets"
                hide-error
              />
            </div>
          </li>
        </ul>
      </div>
    </SCardBlock>
    <SCardBlock size="medium" class="s-pl-8 s-px-16">
      <SControl size="medium">
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.add"
            :loading="loading"
            @click="create"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.container {
  padding: 8px 0 0;
}

.table {
  border: 1px solid var(--c-divider-light);
  border-radius: 6px;
  overflow: hidden;
}

.row {
  display: flex;

  & + & {
    border-top: 1px solid var(--c-divider-light);
  }
}

.cell {
  padding: 8px;

  &.header {
    display: flex;
    align-items: center;
    width: 128px;
    font-size: 12px;
    font-weight: 500;
    color: var(--c-text-2);
    padding-left: 12px;
    border-right: 1px solid var(--c-divider-light);
  }

  &.data {
    flex-grow: 1;
  }

  &.bg {
    background-color: var(--c-soft);
  }
}
</style>
