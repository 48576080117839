<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCompanyAndNameItem } from '@/composables/repos/CompanyRepo'
import ActionNoteCatalog from '@/components/action-note/ActionNoteCatalog.vue'

const route = useRoute()
const companyId = Number(route.params.id)

const { data: company } = useCompanyAndNameItem(companyId)

const companyOps = useCompanyOps()

const title = computed(() => {
  return company.value
    ? `${companyOps.name(company.value)} - Action Notes`
    : undefined
})
</script>

<template>
  <DPage class="CompaniesIdActionNotes" :title="title">
    <ActionNoteCatalog
      v-if="company"
      :company-id="company.id"
    />
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdActionNotes {
  padding: 32px 32px 128px;
}
</style>
