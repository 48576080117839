<script setup lang="ts">
import IconPlusCircle from '~icons/ph/plus-circle'
import SButton from 'sefirot/components/SButton.vue'
import SInputBase from 'sefirot/components/SInputBase.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type BalanceConfirmationMail, type BalanceConfirmationMailDestination } from '@/graph/BalanceConfirmation'
import {
  type UpdateCompanyPortfolioDataBalanceConfirmationMailInputDestination,
  useUpdateCompanyPortfolioDataBalanceConfirmationMail
} from '@/composables/repos/CompanyPortfolioDataRepo'
import CompanyPortfolioDataFormUpdateBalanceConfirmationMailDestination from './CompanyPortfolioDataFormUpdateBalanceConfirmationMailDestination.vue'

const props = defineProps<{
  companyId: number
  zipCode: string | null
  address: string | null
  addressHouseNumber: string | null
  notes: string | null
  destinations: BalanceConfirmationMailDestination[]
}>()

const emit = defineEmits<{
  cancel: []
  updated: [balanceConfirmationMail: BalanceConfirmationMail]
}>()

const { t } = useTrans({
  en: {
    i_zip_code_label: 'ZIP Code *',
    i_zip_code_ph: '12345-6789',
    i_address_label: 'Address *',
    i_address_ph: '1-2-3 Example Town, Example City, Example Prefecture',
    i_address_house_number_label: 'House number',
    i_address_house_number_ph: 'Example Building 101',
    i_destinations_label: 'Recipients',
    i_notes_label: 'Notes',
    i_notes_ph: 'Add additional notes about the address if needed.',
    a_add_destination: 'Add recipient',
    a_cancel: 'Cancel',
    a_update: 'Update physical addresses'
  },
  ja: {
    i_zip_code_label: '郵便番号 *',
    i_zip_code_ph: '123-6789',
    i_address_label: '住所 *',
    i_address_ph: '東京都渋谷区渋谷二丁目17番1号',
    i_address_house_number_label: '建物名',
    i_address_house_number_ph: '渋谷アクシュ 7F',
    i_destinations_label: '宛先',
    i_notes_label: '備考',
    i_notes_ph: '送付先に関する留意点などあれば入力してください。',
    a_add_destination: '送付先を追加',
    a_cancel: 'キャンセル',
    a_update: '送付先を更新'
  }
})

const mutation = useUpdateCompanyPortfolioDataBalanceConfirmationMail()

const { data } = useData({
  zipCode: props.zipCode,
  address: props.address,
  addressHouseNumber: props.addressHouseNumber,
  destinations: props.destinations?.length ? props.destinations : [createEmptyDestinationData()],
  notes: props.notes
})

const { validation, validateAndNotify } = useValidation(data, {
  zipCode: {
    required: required(),
    maxLength: maxLength(255)
  },
  address: {
    required: required(),
    maxLength: maxLength(255)
  },
  addressHouseNumber: {
    maxLength: maxLength(255)
  },
  destinations: {
    required: required()
  },
  notes: {
    maxLength: maxLength(4000)
  }
})

function createEmptyDestinationData() {
  return {
    name: null as string | null,
    title: null as string | null
  }
}

function addDestination() {
  data.value.destinations.push(createEmptyDestinationData())
}

async function update() {
  if (await validateAndNotify()) {
    const res = await mutation.execute(props.companyId, {
      zipCode: data.value.zipCode!,
      address: data.value.address!,
      addressHouseNumber: data.value.addressHouseNumber,
      notes: data.value.notes,
      destinations: data.value.destinations as UpdateCompanyPortfolioDataBalanceConfirmationMailInputDestination[]
    })
    emit('updated', res)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Update Email balance confirmation addresses</STrans>
            <STrans lang="ja">Emailでの残高確認送付先を更新する</STrans>
          </h2>
        </SContent>
        <SInputText
          :label="t.i_zip_code_label"
          :placeholder="t.i_zip_code_ph"
          v-model="data.zipCode"
          :validation="validation.zipCode"
        />
        <SInputText
          :label="t.i_address_label"
          :placeholder="t.i_address_ph"
          v-model="data.address"
          :validation="validation.address"
        />
        <SInputText
          :label="t.i_address_house_number_label"
          :placeholder="t.i_address_house_number_ph"
          v-model="data.addressHouseNumber"
          :validation="validation.addressHouseNumber"
        />
        <SInputBase class="small" :label="t.i_destinations_label">
          <SCard class="destinations">
            <SCardBlock class="destinations-header">
              <STrans lang="en">
                <div class="destinations-header-item">Name</div>
                <div class="destinations-header-item">Title</div>
              </STrans>
              <STrans lang="ja">
                <div class="destinations-header-item">氏名</div>
                <div class="destinations-header-item">役職</div>
              </STrans>
            </SCardBlock>
            <SCardBlock class="destinations-body">
              <div v-for="_, i in data.destinations" :key="i" class="destinations-item">
                <CompanyPortfolioDataFormUpdateBalanceConfirmationMailDestination
                  :removable="data.destinations.length > 1"
                  v-model:name="data.destinations[i].name"
                  v-model:title="data.destinations[i].title"
                  @remove="data.destinations.splice(i, 1)"
                />
              </div>
              <div class="destinations-action">
                <SButton
                  type="text"
                  size="small"
                  mode="info"
                  :icon="IconPlusCircle"
                  :label="t.a_add_destination"
                  @click="addDestination"
                />
              </div>
            </SCardBlock>
          </SCard>
        </SInputBase>
        <SInputTextarea
          :label="t.i_notes_label"
          :placeholder="t.i_notes_ph"
          :rows="3"
          v-model="data.notes"
          :validation="validation.notes"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.destinations {
  overflow: hidden;
}

.destinations-header {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 8px;
  padding: 8px;
}

.destinations-header-item {
  padding: 0 4px;
  font-size: 12px;
  color: var(--c-text-2);
}

.destinations-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.destinations-action {
  padding: 0 8px;
}
</style>
