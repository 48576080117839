import { type Mutation, type Query, type UseQueryOptions, useMutation, useQuery } from 'sefirot/composables/Api'
import { type CompanyVecAndPermissionFrag, type UpdateCompanyVECInput } from '@/graphql'
import { CompanyRequest } from '@/requests/CompanyRequest'

export type CompanyVecItem = Query<CompanyVecAndPermissionFrag | null>
export type UpdateCompanyVec = Mutation<void, [companyId: number, input: UpdateCompanyVECInput]>

export function useCompanyVecItem(companyId: number, options?: UseQueryOptions): CompanyVecItem {
  return useQuery(async () => {
    return (await new CompanyRequest().fetchCompanyVec(companyId)).data.companyVEC
  }, options)
}

export function useUpdateCompanyVec(): UpdateCompanyVec {
  return useMutation(async (_, companyId, input) => {
    await new CompanyRequest().updateCompanyVec(companyId, input)
  })
}
