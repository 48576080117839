<script setup lang="ts">
import { type CompanyName } from '@/models/CompanyName'
import { useCompanyDeleteName } from '@/composables/company/CompanyData'

const props = defineProps<{
  name: CompanyName
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'deleted'): void
}>()

const { loading, execute } = useCompanyDeleteName(props.name.id!)

async function destroy() {
  await execute()
  emit('deleted')
}
</script>

<template>
  <DForm size="small" mode="danger">
    <DFormTitle>Delete company name</DFormTitle>

    <DFormMedium>
      <p>You’re about delete company name <strong>“{{ name.displayName }}”</strong>. This action can’t be undone. Are you absolutely sure you want to delete this action note?</p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="danger" label="Delete" :loading="loading" @click="destroy" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
