import { orderBy } from 'lodash-es'
import { type CompanyBusinessReportFinancialFiguresAnnotation } from '@/graphql'
import CompanyBusinessReportFinancialFiguresAnnotationRequest from '@/requests/CompanyBusinessReportFinancialFiguresAnnotationRequest'
import { type Query, useQuery } from '../Api'

export type CompanyBusinessReportFinancialFiguresAnnotationList = Query<CompanyBusinessReportFinancialFiguresAnnotation[], []>

export function useCompanyBusinessReportFinancialFiguresAnnotationList(): CompanyBusinessReportFinancialFiguresAnnotationList {
  return useQuery(async () => {
    const { data: { companyBusinessReportFinancialFiguresAnnotations } } = await new CompanyBusinessReportFinancialFiguresAnnotationRequest().fetchAll()

    return orderBy(companyBusinessReportFinancialFiguresAnnotations, (c) => c.rank)
  })
}
