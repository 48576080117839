<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type OpportunitySourceFrag } from '@/graphql'
import { useOpportunitySourceOps } from '@/composables/ops/OpportunityOps'
import { useDeleteOpportunitySource } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  source: OpportunitySourceFrag
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    delete: 'Delete sourcing information'
  },
  ja: {
    cancel: 'キャンセル',
    delete: 'ソーシング情報を削除'
  }
})

const { loading, execute } = useDeleteOpportunitySource()

const opportunitySourceOps = useOpportunitySourceOps()

async function destroy() {
  await execute(props.source.id)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Delete this sourcing information</h2>
          <p>You are about delete sourcing information <strong>“{{ opportunitySourceOps.title(source) }}”</strong>. This action cannot be undone. Are you absolutely sure you want to delete this sourcing information?</p>
        </STrans>
        <STrans lang="ja">
          <h2>ソーシング情報を削除する</h2>
          <p>ソーシング情報「<strong>{{ opportunitySourceOps.title(source) }}</strong>」を削除します。この操作は取り消せません。本当によろしいですか？</p>
        </STrans>
        <STrans lang="ja">ソーシング情報を削除する</STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.delete"
            :loading="loading"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
