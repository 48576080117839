<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { type CurrencyFrag } from '@/graphql'
import { useGetCurrencyList } from '@/composables/repos/CurrencyRepo'

const props = defineProps<{
  // If a list of currencies is provided, it will use it to create options.
  // Otherwise, it will fetch the list of currencies by it self.
  currencies?: CurrencyFrag[]

  scope?: string
}>()

const model = defineModel<number | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Currency *',
    info: 'The currency used for this round.',
    ph: 'Select a currency'
  },
  ja: {
    label: 'Currency *',
    info: '本ラウンドで使用される通貨。',
    ph: '通貨を選択してください。'
  }
})

const { execute: getCurrencyList } = useGetCurrencyList()

const { validation } = useValidation({
  model
}, {
  model: {
    required: required()
  }
}, {
  $scope: props.scope
})

const currencies = props.currencies ?? await getCurrencyList()

const options: Option[] = currencies.map((c) => {
  return { label: c.name, value: c.id, disabled: !c.enabled }
})
</script>

<template>
  <SInputDropdown
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :options="options"
    v-model="model"
    :validation="validation.model"
  />
</template>
