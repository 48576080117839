import { useDark } from '@vueuse/core'
import { type WritableComputedRef } from 'vue'
import { useUpdateUserSettingsTheme } from './repos/UserRepo'

export interface Appearance {
  isDark: WritableComputedRef<boolean>
  set(value: 'light' | 'dark'): void
  toggle(): void
}

export function useAppearance(): Appearance {
  const updateTheme = useUpdateUserSettingsTheme()

  const isDark = useDark({
    storageKey: 'deus-theme'
  })

  function set(value: 'light' | 'dark'): void {
    isDark.value = value === 'dark'
  }

  function toggle(): void {
    isDark.value = !isDark.value
    updateTheme.execute(isDark.value ? 'dark' : 'light')
  }

  return {
    isDark,
    set,
    toggle
  }
}
