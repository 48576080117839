<script setup lang="ts">
const props = defineProps<{
  title: string
}>()
</script>

<template>
  <div class="UsersProfileSection">
    <div class="header">
      <p class="title">{{ props.title }}</p>
      <slot name="actions" v-if="$slots.actions" />
    </div>
    <div class="content">
      <slot name="default" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.UsersProfileSection {
  & + & {
    margin-top: 24px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid var(--c-divider-light);
}
.title {
  letter-spacing: 0;
  font-size: 20px;
  font-weight: 500;
}
.content {
  padding-top: 16px;
}
</style>
