<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useFundReportOps } from '@/composables/ops/FundReportOps'
import { useCompanyBusinessReportItemForLocalNav } from '@/composables/repos/CompanyBusinessReportRepo'

const { t } = useTrans({
  en: {
    title: 'Reports'
  },
  ja: {
    title: '業務報告書'
  }
})

const route = useRoute()

const companyOps = useCompanyOps()
const fundRerportOps = useFundReportOps()

const { data: companyBusinessReport } = useCompanyBusinessReportItemForLocalNav(() => Number(route.params.id))

const title = computed(() => [
  { text: t.title, link: '/fund-reports' },
  ...companyBusinessReport.value
    ? [
        {
          text: fundRerportOps.title(companyBusinessReport.value.report),
          link: `/company-business-reports-by-fund-report-id/${companyBusinessReport.value?.report.id}`
        },
        { text: companyOps.name(companyBusinessReport.value.company) }
      ]
    : []
])
</script>

<template>
  <DView class="CompanyBusinessReportsId" :title="title" />
</template>
