<script setup lang="ts">
import { useCanViewAny } from '@/composables/policies/UserPolicy'

const canViewAny = useCanViewAny()
</script>

<template>
  <DView
    class="Members"
    title="Members"
    :hide="!canViewAny.ok"
  />
</template>
