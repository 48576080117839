<script setup lang="ts">
import SInputAddon from 'sefirot/components/SInputAddon.vue'
import { type Size } from 'sefirot/components/SInputBase.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { type DropdownSectionFilterOption, createDropdown } from 'sefirot/composables/Dropdown'
import { type Validatable } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { type CurrencyFrag } from '@/graphql'
import { format, toNumberOrNull } from '@/support/Num'

const props = withDefaults(defineProps<{
  size?: Size
  type?: string
  name?: string
  label?: string
  info?: string
  note?: string
  help?: string
  placeholder?: string
  unitBefore?: string
  unitAfter?: string
  disabled?: boolean
  currency?: any
  modelValue: string | null
  hideError?: boolean
  validation?: Validatable
  currencies?: CurrencyFrag[] | null
}>(), {
  currencies: null
})

const emit = defineEmits<{
  (e: 'update:model-value', value: string | null): void
  (e: 'update:currency', value: CurrencyFrag | null): void
}>()

const unitBefore = computed(() => {
  if (props.currencies) {
    return null
  }

  return props.unitBefore
    ? props.unitBefore
    : props.currency?.symbol ?? null
})

const unitAfter = computed(() => {
  return props.unitAfter
    ? props.unitAfter
    : props.currency?.name ?? null
})

const displayValue = computed(() => {
  if (props.modelValue === null) {
    return null
  }

  const value = props.modelValue.split('.')
  const numberValue = toNumberOrNull(value[0])

  value[0] = numberValue ? format(numberValue) : value[0]

  return value.join('.')
})

const dropdown = computed(() => createDropdown([
  {
    type: 'filter',
    selected: props.currency?.id ?? null,
    options: (props.currencies ?? []).map<DropdownSectionFilterOption>(({ symbol, name, id }) => ({
      label: `${symbol} ${name}`,
      value: id!,
      onClick: (value: number) => {
        const selected = props.currencies?.find(({ id }) => id === value)

        emit('update:currency', selected ?? null)
      }
    }))
  }
]))

function onUpdate(value: string | null) {
  const numberValue = toNumberOrNull(value)
  emit('update:model-value', numberValue !== null ? String(numberValue) : null)
}
</script>

<template>
  <SInputText
    class="DInputMoney"
    :size="props.size"
    :type="props.type"
    :name="props.name"
    :label="props.label"
    :note="props.note"
    :info="props.info"
    :help="props.help"
    :unit-before="unitBefore"
    :unit-after="unitAfter"
    :placeholder="placeholder"
    :disabled="props.disabled"
    :hide-error="props.hideError"
    :validation="props.validation"
    :display-value="displayValue"
    :model-value="props.modelValue"
    @update:model-value="onUpdate"
  >
    <template #addon-before>
      <SInputAddon v-if="props.currencies" :label="props.currency?.symbol" :dropdown="dropdown" />
    </template>
  </SInputText>
</template>
