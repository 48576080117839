<script setup lang="ts">
import IconArrowClockwise from '~icons/ph/arrow-clockwise'
import SAlert from 'sefirot/components/SAlert.vue'
import SInputFileUpload, { type FileObject } from 'sefirot/components/SInputFileUpload.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { isFetchError } from 'sefirot/http/Http'
import { maxFileSize } from 'sefirot/validation/rules'
import { ref, watch } from 'vue'
import { type OpportunityFile } from '@/graph/Opportunity'
import { useUploadOpportunityFile } from '@/composables/repos/OpportunityFileRepo'

const props = defineProps<{
  opportunityId: number
  initFiles?: File[] | null
}>()

const emit = defineEmits<{
  cancel: []
  uploaded: [opportunityFiles: OpportunityFile[]]
}>()

const { t } = useTrans({
  en: {
    ph: '50MB max per file.',
    error: 'An error occurred while uploading the file.',
    retry: 'Retry',
    cancel: 'Cancel',
    upload: 'Upload files'
  },
  ja: {
    ph: '1ファイルあたり最大50MB。',
    error: 'アップロード中にエラーが発生しました。',
    retry: 'リトライ',
    cancel: 'キャンセル',
    upload: 'ファイルをアップロード'
  }
})

const mutation = useUploadOpportunityFile()

const { data } = useData({
  files: createData()
})

const { validateAndNotify } = useValidation()

const rules = {
  maxFileSize: maxFileSize('50mb')
}

const isUploading = ref(false)
const uploadedFiles = ref<OpportunityFile[]>([])

// When uploaded files are not empty, and matches the `data.files` size, emit
// the `uploaded` event. This happens when user first uploads files, and some
// of them failed to upload, then user removes the failed files instead of
// retrying them. I guess it is OK to treat this situation as upload completed.
watch(() => uploadedFiles.value, (fs) => {
  if (fs.length > 0 && fs.length === data.value.files.length) {
    emit('uploaded', fs)
  }
}, { deep: true })

function createData() {
  return (props.initFiles?.map((file) => ({ file })) ?? []) as FileObject[]
}

async function upload() {
  if (await validateAndNotify()) {
    // Upload files in parallel. Uploaded files are stored in the
    // `uploadedFiles` ref.
    isUploading.value = true
    await Promise.all(data.value.files.map(uploadFile))
    isUploading.value = false

    // Check if `uploadedFiles` size is same as `data.files` size. If not,
    // it means that some files failed to upload so return here. User may
    // retry uploading the failed files, or remove them.
    if (uploadedFiles.value.length !== data.value.files.length) {
      return
    }

    // Else, all files were uploaded successfully so emit the `uploaded` event.
    emit('uploaded', uploadedFiles.value)
  }
}

async function uploadFile(file: FileObject) {
  // If the file is already uploaded, skip it.
  if (file.indicatorState === 'completed') {
    return
  }

  // Else, let's upload the file.
  file.indicatorState = 'running'
  file.canRemove = false
  file.errorMessage = null
  file.action = undefined

  try {
    const newFile = await mutation.execute(props.opportunityId, file.file)
    uploadedFiles.value.push(newFile)
    file.indicatorState = 'completed'
  } catch (e) {
    // If it is not a fetch error, something unexpected happen. Let's just
    // crush the app.
    if (!isFetchError(e)) {
      throw e
    }

    // Else set the error state and give user a chance to retry.
    file.indicatorState = 'failed'
    file.canRemove = true
    file.errorMessage = t.error

    file.action = {
      icon: IconArrowClockwise,
      mode: 'info',
      label: t.retry,
      onClick: () => uploadFile(file)
    }
  }
}
</script>

<template>
  <SCard class="OpportunityFormUploadFiles" size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Upload pitch decks</h2>
            <p>Uploaded files can be viewed by the company assignees and the capitalists in charge of the fund that disclosed the Opportunity.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Pitch Deckをアップロードする</h2>
            <p>アップロードしたファイルは企業の担当者、およびOpportunityを開示したファンドの担当キャピタリストが閲覧・ダウンロードができます。</p>
          </STrans>
        </SContent>
        <SAlert mode="warning">
          <p>
            <STrans lang="en">Attached files may be shared with the LPs of the fund that disclosed the Opportunity without prior notice. Ensure that you only attach files that can be shared without any issues.</STrans>
            <STrans lang="ja">添付したファイルは、予告なくOpportunityを開示したファンドのLPへ共有される可能性があります。共有されても問題のないファイルのみ添付するようにしてください。</STrans>
          </p>
        </SAlert>
        <SInputFileUpload
          :placeholder="t.ph"
          droppable
          model-type="object"
          v-model="data.files"
          :rules="rules"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock class="s-px-32" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="isUploading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.upload"
            :loading="isUploading"
            @click="upload"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
