<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import {
  type DealSecurityFrag,
  type DealWithIdFrag,
  type DealWithRoundCurrencyFrag,
  type DealWithSecuritiesFrag,
  SecurityKind,
  type WithPermissionFrag
} from '@/graphql'
import { useDealSecurityOps } from '@/composables/ops/DealSecurityOps'
import { useMoneyOps } from '@/composables/ops/MoneyOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealFormDeleteSecurity from './DealFormDeleteSecurity.vue'
import DealFormUpdateSecurity from './DealFormUpdateSecurity.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithRoundCurrencyFrag & DealWithSecuritiesFrag & WithPermissionFrag
  security: DealSecurityFrag
}>()

defineEmits<{
  (e: 'close'): void
  (e: 'updated'): void
  (e: 'deleted'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)
const canDelete = computed(() => canUpdate.value && props.deal.securities.length > 1)

const securityOps = useDealSecurityOps()
const moneyOps = useMoneyOps()

const updateModal = usePower(false)
const deleteModal = usePower(false)

const actions = computedArrayWhen(canUpdate, (arr) => {
  arr.push({ icon: 'edit', onClick: () => updateModal.on() })

  if (canDelete.value) {
    arr.push({ icon: 'delete', onClick: () => deleteModal.on() })
  }
})

const isPreferredStock = computed(() => {
  return props.security.kind === SecurityKind.ClassifiedStock
})

const isSecurity = computed(() => {
  return props.security.kind !== SecurityKind.CommonStock
         && props.security.kind !== SecurityKind.ClassifiedStock
})

const plannedTotalPrice = computed(() => {
  const money = securityOps.plannedTotalPriceWithRoundCurrency(props.deal.round, props.security)
  return money ? moneyOps.format(money) : null
})

const purchasedTotalPrice = computed(() => {
  const money = securityOps.purchasedTotalPriceWithRoundCurrency(props.deal.round, props.security)
  return money ? moneyOps.format(money) : null
})

const purchasedQuantity = computed(() => {
  return securityOps.purchasedQuantity(props.security)
})

const purchasedTotalPriceFmc = computed(() => {
  const money = securityOps.purchasedTotalPriceFmc(props.security)
  return money ? moneyOps.format(money) : null
})

const valuationCap = computed(() => {
  const money = securityOps.valuationCapWithRoundCurrency(props.deal.round, props.security)
  return money ? moneyOps.format(money) : null
})

const eligibleFinancingAmount = computed(() => {
  const money = securityOps.eligibleFinancingAmountWithRoundCurrency(props.deal.round, props.security)
  return money ? moneyOps.format(money) : null
})
</script>

<template>
  <DCard
    title="Security details"
    :collapsable="false"
    closable
    :actions="actions"
    @close="$emit('close')"
  >
    <DCardBlock space="compact">
      <DGrid>
        <DGridItem span="1">
          <DGridLabel text="Security type" />
          <DGridText :text="securityOps.kindText(security)" />
        </DGridItem>
        <DGridItem span="1">
          <DGridLabel text="Security name" />
          <DGridText :text="security.name" />
        </DGridItem>
        <DGridItem span="2" />
        <DGridItem span="2">
          <DGridLabel text="Est. total amount" />
          <DGridText :text="plannedTotalPrice" />
        </DGridItem>
        <DGridItem span="2" />
        <DGridItem span="1">
          <DGridLabel text="Acq. total amount" />
          <DGridText :text="purchasedTotalPrice" />
        </DGridItem>
        <DGridItem span="1">
          <DGridLabel text="Acq. quantity" />
          <DGridText :text="purchasedQuantity" />
        </DGridItem>
        <DGridItem span="2" />
        <DGridItem span="2">
          <DGridLabel text="Acq. total amount (JPY)" />
          <DGridText :text="purchasedTotalPriceFmc" />
        </DGridItem>
        <DGridItem span="2" />

        <template v-if="isPreferredStock">
          <DGridItem span="2">
            <DGridLabel text="Liquidation preference (multiple)" />
            <DGridText :text="security.liquidationPreferenceMultiple" />
          </DGridItem>
          <DGridItem span="2">
            <DGridLabel text="Liquidation preference (pattern)" />
            <DGridText :text="security.liquidationPreferencePattern?.name" />
          </DGridItem>
          <DGridItem span="2">
            <DGridLabel text="Anti dilution provision" />
            <DGridText :text="security.antiDilutionProvision?.name" />
          </DGridItem>
          <DGridItem span="2" />
        </template>

        <template v-if="isSecurity">
          <DGridItem span="1">
            <DGridLabel text="Annual interest" />
            <DGridText :text="security.annualInterest ? `${security.annualInterest}%` : null" />
          </DGridItem>
          <DGridItem span="1">
            <DGridLabel text="Repayment right" />
            <DGridText :text="security.repaymentRight ? 'Yes' : 'No'" />
          </DGridItem>
          <DGridItem span="1">
            <DGridLabel text="Repayment date" />
            <DGridText :text="securityOps.repaymentDate(security)?.format('YYYY/MM/DD')" />
          </DGridItem>
          <DGridItem span="1" />
          <DGridItem span="4">
            <DGridLabel text="Discount" />
            <DGridText :text="security.discount ? `${security.discount}%` : null" />
          </DGridItem>
          <DGridItem span="2">
            <DGridLabel text="Valuation cap" />
            <DGridText :text="valuationCap" />
          </DGridItem>
          <DGridItem span="2">
            <DGridLabel text="Eligible financing amount" />
            <DGridText :text="eligibleFinancingAmount" />
          </DGridItem>
        </template>

        <DGridItem span="2">
          <DGridLabel text="Created at" />
          <DGridText :text="securityOps.createdAtText(security)" />
        </DGridItem>
        <DGridItem span="2">
          <DGridLabel text="Updated at" />
          <DGridText :text="securityOps.updatedAtText(security)" />
        </DGridItem>
      </DGrid>
    </DCardBlock>

    <DModal size="medium" :open="updateModal.state.value">
      <DealFormUpdateSecurity
        :deal="deal"
        :security="security"
        @cancel="() => updateModal.off()"
        @updated="updateModal.off(() => $emit('updated'))"
      />
    </DModal>

    <DModal size="small" :open="deleteModal.state.value">
      <DealFormDeleteSecurity
        :deal="deal"
        :security="security"
        @cancel="() => deleteModal.off()"
        @deleted="deleteModal.off(() => $emit('deleted'))"
      />
    </DModal>
  </DCard>
</template>
