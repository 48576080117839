<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCanViewCompanySettings } from '@/composables/policies/CompanyPolicy'
import { useCompanyForSettingsItem } from '@/composables/repos/CompanyRepo'
import CompanySettingsPage from '@/components/company/CompanySettingsPage.vue'

const route = useRoute()
const router = useRouter()

const { data: company } = useCompanyForSettingsItem(Number(route.params.id))

const canViewSettings = useCanViewCompanySettings(company)

const companyOps = useCompanyOps()

const title = computedWhen(company, (c) => {
  return `${companyOps.name(c)} - Settings`
})

const authorize = computed(() => {
  // Fetched.
  if (company.value === undefined) {
    return null
  }

  // Not found.
  if (company.value === null) {
    return false
  }

  // Fetched and exists, now policy can be applied.
  return canViewSettings.value.ok
})

async function onUpdated() {
  router.push(companyOps.path(company.value!, 'profile'))
}
</script>

<template>
  <DPage class="CompaniesIdSettings" :title="title" :authorize="authorize">
    <CompanySettingsPage
      v-if="company"
      :company="company"
      @updated="onUpdated"
    />
  </DPage>
</template>
