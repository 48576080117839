<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useFundReportOps } from '@/composables/ops/FundReportOps'
import { useFundReportItem } from '@/composables/repos/FundReportRepo'

const { t } = useTrans({
  en: {
    title: 'Reports'
  },
  ja: {
    title: '業務報告書'
  }
})

const route = useRoute()

const fundReportId = Number(route.params.id)

const fundReportOps = useFundReportOps()

const { data: fundReport } = useFundReportItem(fundReportId)

const title = computed(() => [
  { text: t.title, link: '/fund-reports' },
  { text: fundReport.value ? fundReportOps.title(fundReport.value) : '' }
])
</script>

<template>
  <DView class="CompanyBusinessReportsByFundReportIdId" :title="title" />
</template>
