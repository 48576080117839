<template>
  <div class="DFormFooterActions">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormFooterActions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
</style>
