/**
 * Ensures that the given value is an array.
 */
export function toArray<T>(arr: T | T[] | undefined | null): T[] {
  if (!arr) {
    return []
  }

  return Array.isArray(arr) ? arr : [arr]
}
