<script setup lang="ts">
import IconTrash from '~icons/ph/trash-bold'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Share, type ShareRules } from './DInputShares.vue'

const props = defineProps<{
  id: number
  name: Share['name']
  count: Share['count']
  rules?: ShareRules
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'remove', id: number): void
  (e: 'update', id: number, value: Share): void
}>()

const { t } = useTrans({
  en: {
    name_placeholder: 'Share name',
    count_placeholder: 'Count'
  },
  ja: {
    name_placeholder: '株式名',
    count_placeholder: '数量'
  }
})

const { validation } = useValidation(() => ({
  name: props.name,
  count: props.count
}), () => ({
  name: props.rules?.name,
  count: props.rules?.count
}))

function updateName(value: string) {
  emit('update', props.id, {
    name: value,
    count: props.count
  })
}

function updateCount(value: number | null) {
  emit('update', props.id, {
    name: props.name,
    count: value
  })
}
</script>

<template>
  <div class="DInputSharesItem">
    <div class="name">
      <SInputText
        :placeholder="t.name_placeholder"
        size="mini"
        hide-error
        :model-value="name"
        :validation="validation?.name"
        :disabled="disabled"
        @input="(v: string | null) => updateName(v!)"
      />
    </div>
    <div class="count">
      <SInputNumber
        :placeholder="t.count_placeholder"
        size="mini"
        align="right"
        separator
        hide-error
        :model-value="count"
        :validation="validation?.count"
        :disabled="disabled"
        @input="(v) => updateCount(v)"
      />
    </div>
    <div class="remove">
      <button class="action" @click="$emit('remove', id)" :disabled="disabled">
        <IconTrash class="icon-svg" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DInputSharesItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-divider-2);
  padding: 8px 8px 7px;
}

.name {
  flex-grow: 1;
}

.count {
  padding-left: 8px;
  width: 192px;
}

.remove {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  transition: color 0.25s, background-color 0.25s;
  color: var(--c-text-2);

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    color: var(--c-text-1);
    background-color: var(--c-mute);
  }
}
</style>
