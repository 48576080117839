import { type Day, Model } from './Model'

export class ViewResult extends Model {
  id?: number
  body?: (string | number)[][]
  ids?: Record<string, number>[]
  createdAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.body = JSON.parse(data.body) as (string | number)[][]
    this.ids = JSON.parse(data.ids) as Record<string, number>[]
    this.createdAt = this.day(data.createdAt)
  }
}
