<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { type Company } from '@/graph/Company'
import { useCompanyOps } from '@/composables/ops/CompanyOps'

const props = defineProps<{
  type: 'fill-by-ai' | 'create'
  companies: Company[]
}>()

defineEmits<{
  cancel: []
  proceed: []
}>()

const { t } = useTrans({
  en: {
    c_display_id: 'ID',
    c_display_name: 'Display name',
    c_official_name_local: 'Official name',
    c_official_name_en: 'English name',
    c_website: 'Website',
    a_cancel: 'Cancel',
    a_create: props.type === 'fill-by-ai' ? 'Ignore and proceed AI auto fill' : 'Ignore and create company'
  },
  ja: {
    c_display_id: 'ID',
    c_display_name: 'Display name',
    c_official_name_local: 'Official name',
    c_official_name_en: 'English name',
    c_website: 'ウェブサイト',
    a_cancel: 'キャンセル',
    a_create: props.type === 'fill-by-ai' ? '無視してAI自動入力を実行する' : '無視してCompanyを作成'
  }
})

const companyOps = useCompanyOps()

const table = useTable({
  records: props.companies,
  orders: [
    'displayId',
    'displayName',
    'officialNameLocal',
    'officialNameEn',
    'website'
  ],
  columns: {
    displayId: {
      label: t.c_display_id,
      cell: (_, company) => ({
        type: 'text',
        value: companyOps.displayId(company),
        link: companyOps.path(company),
        color: 'info'
      })
    },
    displayName: {
      label: t.c_display_name,
      cell: (_, company) => ({
        type: 'text',
        value: companyOps.name(company)
      })
    },
    officialNameLocal: {
      label: t.c_official_name_local,
      cell: (_, company) => ({
        type: 'text',
        value: companyOps.officialNameLocal(company)
      })
    },
    officialNameEn: {
      label: t.c_official_name_en,
      cell: (_, company) => ({
        type: 'text',
        value: companyOps.officialNameEn(company)
      })
    },
    website: {
      grow: true,
      label: t.c_website,
      cell: (_, company) => ({
        type: 'text',
        value: company.website,
        link: company.website,
        color: 'info'
      })
    }
  }
})
</script>

<template>
  <SCard class="CompanyFormCreateDuplicate" size="xxlarge" mode="warning">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>The company duplication has been detected</h2>
            <p v-if="type === 'fill-by-ai'">The company with a similar URL has been detected. Check the list below before proceeding with the auto-fill by AI.</p>
            <p v-else>The company with a similar name and URL has been detected. Check the list below before creating a new company.</p>
          </STrans>
          <STrans lang="ja">
            <h2>重複を検知しました</h2>
            <p v-if="type === 'fill-by-ai'">入力されたものと似たURLを持つ企業を検知しました。AIでの自動入力を進める前に、以下の一覧を確認してください。</p>
            <p v-else>入力された企業名とURLから似た企業を検知しました。企業を作成する前に、以下の一覧を確認してください。</p>
          </STrans>
        </SContent>
        <div class="list">
          <STable :options="table" />
        </div>
      </SDoc>
    </SCardBlock>
    <SCardBlock class="s-px-32" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="warning"
            :label="t.a_create"
            @click="$emit('proceed')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.list :deep(.col-displayId) {
  font-feature-settings: "tnum";
}

.list :deep(.col-displayId)         { --table-col-width: 144px; }
.list :deep(.col-displayName)       { --table-col-width: 192px; }
.list :deep(.col-officialNameLocal) { --table-col-width: 192px; }
.list :deep(.col-officialNameEn)    { --table-col-width: 192px; }
.list :deep(.col-website)           { --table-col-width: 192px; }
</style>
