<script setup lang="ts">
import { reactive, ref } from 'vue'
import * as ActionNoteRepo from '@/composables/repos/ActionNoteRepo'
import * as CompanyRepo from '@/composables/repos/CompanyRepo'
import * as DealRepo from '@/composables/repos/DealRepo'
import * as OpportunityRepo from '@/composables/repos/OpportunityRepo'
import { OrderDirection } from '@/composables/repos/Repo'
import { useSearch } from '@/composables/search/Search'
import SearchResultContentActionNote from '@/components/search/SearchResultContentActionNote.vue'
import SearchResultContentCompany from '@/components/search/SearchResultContentCompany.vue'
import SearchResultContentDeal from '@/components/search/SearchResultContentDeal.vue'
import SearchResultContentOpportunity from '@/components/search/SearchResultContentOpportunity.vue'
import SearchSideMenu from '@/components/search/SearchSideMenu.vue'

const { type, query, initialize } = useSearch()

initialize()

const companyOptions = reactive({
  page: { page: 0, perPage: 50 },
  condition: {
    query,
    unassigned: false
  },
  orderBy: ref<CompanyRepo.CompanyOrder>({
    field: CompanyRepo.CompanyOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  })
})

const companyPage = reactive(
  CompanyRepo.useCompanyForSearchPage(companyOptions)
)

const actionNoteOptions = reactive({
  page: { page: 0, perPage: 50 },
  condition: { query },
  orderBy: ref<ActionNoteRepo.ActionNoteOrder>({
    field: ActionNoteRepo.ActionNoteOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  })
})

const actionNotePage = reactive(
  ActionNoteRepo.useActionNoteForSearchPage(actionNoteOptions)
)

const oppoOptions = reactive({
  page: { page: 0, perPage: 50 },
  condition: { query },
  orderBy: ref<OpportunityRepo.OpportunityOrder>({
    field: OpportunityRepo.OpportunityOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  })
})

const oppoPage = reactive(
  OpportunityRepo.useOpportunityForSearchPage(oppoOptions)
)

const dealOptions = reactive({
  page: { page: 0, perPage: 50 },
  condition: { query },
  orderBy: ref<DealRepo.DealOrder>({
    field: DealRepo.DealOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  })
})

const dealPage = reactive(
  DealRepo.useDealForSearchPage(dealOptions)
)
</script>

<template>
  <DPage class="Search" title="Search">
    <div class="container">
      <div class="menu">
        <SearchSideMenu
          :company-total="companyPage.data?.page.total ?? null"
          :action-note-total="actionNotePage.data?.page.total ?? null"
          :opportunity-total="oppoPage.data?.page.total ?? null"
          :deal-total="dealPage.data?.page.total ?? null"
        />
      </div>
      <div class="results">
        <SearchResultContentCompany
          v-if="type === 'companies'"
          :query="query"
          :loading="companyPage.loading"
          :total="companyPage.data?.page.total"
          :companies="companyPage.data?.items"
        />
        <SearchResultContentActionNote
          v-else-if="type === 'action-notes'"
          :query="query"
          :loading="actionNotePage.loading"
          :total="actionNotePage.data?.page.total"
          :action-notes="actionNotePage.data?.items"
          @updated="actionNotePage.execute()"
          @deleted="actionNotePage.execute()"
        />
        <SearchResultContentOpportunity
          v-else-if="type === 'opportunities'"
          :query="query"
          :loading="oppoPage.loading"
          :total="oppoPage.data?.page.total"
          :opportunities="oppoPage.data?.items"
        />
        <SearchResultContentDeal
          v-else-if="type === 'deals'"
          :query="query"
          :loading="dealPage.loading"
          :total="dealPage.data?.page.total"
          :deals="dealPage.data?.items"
        />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.Search {
  padding: 32px 32px 128px 48px;
  width: 100%;
}

.container {
  display: flex;
  gap: 48px;
  width: 100%;
}

.menu {
  flex-shrink: 0;
  width: 192px;
}

.results {
  flex-shrink: 0;
  width: calc(100% - 192px - 48px);
}
</style>
