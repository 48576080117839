<script setup lang="ts">
import IconFileText from '~icons/ph/file-text-bold'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, reactive, ref } from 'vue'
import { type ActionNoteFrag, ActionNoteOrderField, OrderDirection } from '@/graphql'
import { useActionNoteOps } from '@/composables/ops/ActionNoteOps'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useActionNotePage } from '@/composables/repos/ActionNoteRepo'
import ActionNoteCard from './ActionNoteCard.vue'

const props = defineProps<{
  // When defined, it will only fetch the action notes of the company.
  companyId?: number
}>()

const { t } = useTrans({
  en: {
    i_search_ph: 'Search action notes',
    a_reset: 'Reset filters',
    c_title: 'Title',
    c_company: 'Company',
    c_created_by: 'Created by',
    c_created_at: 'Created at',
    c_updated_by: 'Updated by',
    c_updated_at: 'Updated at',
    f_sort_asc: 'Sort ascending (A...Z)',
    f_sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    i_search_ph: 'Action Noteを検索',
    a_reset: 'フィルターをリセット',
    c_title: 'タイトル',
    c_company: 'Company',
    c_created_by: '作成者',
    c_created_at: '作成日時',
    c_updated_by: '更新者',
    c_updated_at: '更新日時',
    f_sort_asc: 'ソート: 昇順 (A...Z)',
    f_sort_desc: 'ソート: 降順 (Z...A)'
  }
})

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    query: null as string | null,
    companyId: props.companyId ?? null
  },
  orderBy: {
    field: ActionNoteOrderField.CreatedAt,
    direction: OrderDirection.Desc
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': Number,
    'condition.query': (v) => (v === '' || v === null) ? null : v
  },
  exclude: ['page.perPage']
})

const { data, loading, execute } = useActionNotePage(options)

const userOps = useUserOps()
const companyOps = useCompanyOps()
const actionNoteOps = useActionNoteOps()

const dialog = reactive(usePower())

const selectedActionNoteId = ref<number | null>()

const selectedActionNote = computed(() => {
  return selectedActionNoteId.value === null
    ? null
    : data.value?.items.find(({ id }) => id === selectedActionNoteId.value)
})

const isDeleted = ref(false)

const table = useTable({
  records: computed(() => data.value?.items ?? []),
  loading,
  borderless: true,
  orders: [
    'name',
    'company',
    'createdBy',
    'createdAt',
    'updatedBy',
    'updatedAt',
    'spacer'
  ],
  columns: {
    name: {
      label: t.c_title,
      grow: true,
      cell: (_, an) => ({
        type: 'text',
        icon: IconFileText,
        value: an.title,
        iconColor: 'soft',
        onClick: () => { openDialog(an.id) }
      })
    },
    company: {
      label: t.c_company,
      cell: (_, an) => ({
        type: 'text',
        value: companyOps.name(an.company),
        link: companyOps.path(an.company)
      })
    },
    createdBy: {
      label: t.c_created_by,
      cell: (_, an) => ({
        type: 'avatar',
        image: userOps.avatarPath(an.createdBy),
        name: userOps.name(an.createdBy)
      })
    },
    createdAt: {
      label: t.c_created_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions(ActionNoteOrderField.CreatedAt)
        }
      ]),
      cell: (_, an) => ({
        type: 'day',
        value: actionNoteOps.createdAt(an),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedBy: {
      label: t.c_updated_by,
      cell: (_, an) => ({
        type: 'avatar',
        image: userOps.avatarPath(an.updatedBy),
        name: userOps.name(an.updatedBy)
      })
    },
    updatedAt: {
      label: t.c_updated_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions(ActionNoteOrderField.UpdatedAt)
        }
      ]),
      cell: (_, an) => ({
        type: 'day',
        value: actionNoteOps.updatedAt(an),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function createSortOptions(field: ActionNoteOrderField) {
  return [
    { label: t.f_sort_asc, onClick: () => { updateOrder(field, OrderDirection.Asc) } },
    { label: t.f_sort_desc, onClick: () => { updateOrder(field, OrderDirection.Desc) } }
  ]
}

function updateQuery(value: string | null) {
  options.value.page.page = 0
  options.value.condition.query = value === '' ? null : value
}

function updateOrder(field: ActionNoteOrderField, direction: OrderDirection) {
  options.value.page.page = 0
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}

function openDialog(actionNoteId: number) {
  selectedActionNoteId.value = actionNoteId
  dialog.on()
}

async function onUpdated(actionNote: ActionNoteFrag) {
  const i = data.value?.items.findIndex(({ id }) => id === actionNote.id)
  if (i !== undefined && i !== -1) {
    data.value!.items[i] = {
      ...actionNote,
      company: data.value!.items[i].company
    }
  }
}

function onDeleted() {
  isDeleted.value = true
  dialog.off()
}

function onDialogUnmounted() {
  if (isDeleted.value) {
    execute()
    isDeleted.value = false
  }
}
</script>

<template>
  <div class="ActionNoteCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <SControlInputSearch
                :placeholder="t.i_search_ph"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              :label="t.a_reset"
              @click="reset"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="data?.pageInfo.total ?? 0"
              :page="data ? data.pageInfo.page + 1 : 0"
              :per-page="data?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
    <SModal :open="dialog.state">
      <ActionNoteCard
        :action-note="selectedActionNote!"
        :company="selectedActionNote!.company"
        @close="dialog.off"
        @updated="onUpdated"
        @deleted="onDeleted"
        @vue:unmounted="onDialogUnmounted"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)      { --table-col-width: 512px; }
.list :deep(.col-company)   { --table-col-width: 256px; }
.list :deep(.col-createdBy) { --table-col-width: 192px; }
.list :deep(.col-createdAt) { --table-col-width: 192px; }
.list :deep(.col-updatedBy) { --table-col-width: 192px; }
.list :deep(.col-updatedAt) { --table-col-width: 192px; }
.list :deep(.col-spacer)    { --table-col-width: 40px; }
</style>
