<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import { useDealForReportDetail } from '@/composables/repos/DealRepo'
import DealFormUpdateReport from '@/components/deal/DealFormUpdateReport.vue'

const route = useRoute()

const { data: deal, execute } = useDealForReportDetail(() => Number(route.params.id))

const { canView } = useDealPolicy(deal)

const dealOps = useDealOps()

const report = computed(() => {
  if (deal.value === undefined) {
    return undefined
  }

  return deal.value?.reports.find((report) => {
    return report.id === Number(route.params.reportId)
  }) ?? null
})

const authorized = computed(() => {
  return (report.value === undefined) ? null : report.value !== null
})

const title = computedWhen(deal, (deal) => {
  return `${dealOps.fullTitle(deal)} - ${report.value?.name ?? 'Untitled report'}}`
})
</script>

<template>
  <DPage class="DealsIdReportsReportId" :title="title" :authorize="canView && authorized">
    <DContainer size="xlarge" position="center">
      <DealFormUpdateReport
        :deal="deal!"
        :report="report!"
        @updated="execute"
      />
    </DContainer>
  </DPage>
</template>

<style scoped lang="postcss">
.DealsIdReportsReportId {
  padding: 32px 32px 128px;
}
</style>
