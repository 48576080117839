<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useOpportunityInitialReviewMeetingOps, useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCanInitiateInitialReviewMeetingRequest } from '@/composables/policies/OpportunityPolicy'
import OpportunityDescInitialReviewMeetingContent from './OpportunityDescInitialReviewMeetingContent.vue'
import OpportunityDescInitialReviewMeetingEmpty from './OpportunityDescInitialReviewMeetingEmpty.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const canInitiateIrm = useCanInitiateInitialReviewMeetingRequest(() => props.opportunity)

const oppoOps = useOpportunityOps()
const irmOps = useOpportunityInitialReviewMeetingOps()

const requestLink = computed(() => irmOps.requestInitiationUrl(props.opportunity))

const latestInitialReviewMeeting = computed(() => oppoOps.latestInitialReviewMeeting(props.opportunity))
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Initial Review Meeting
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canInitiateIrm.ok && !!latestInitialReviewMeeting"
              :icon="IconPlus"
              :href="requestLink"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunityDescInitialReviewMeetingContent
      v-if="latestInitialReviewMeeting"
      :initial-review-meeting="latestInitialReviewMeeting"
    />
    <OpportunityDescInitialReviewMeetingEmpty
      v-else
      :opportunity="opportunity"
    />
  </SCard>
</template>
