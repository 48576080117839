<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Contact background *',
    info: 'Details about how this opportunity was sourced.',
    ph: 'e.g.\n- Introduced by a friend of the founder.\n- Met at a startup event XXX and exchanged business cards.'
  },
  ja: {
    label: 'Contact background *',
    info: 'ソーシング経路に関する詳細情報。',
    ph: '例：\n- 創業者の友人から紹介された。\n- スタートアップイベント「XXX」で出会い、名刺を交換した。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required(),
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
