<script setup lang="ts">
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

defineProps<{
  removable: boolean
}>()

const name = defineModel<string | null>('name', { required: true })
const title = defineModel<string | null>('title', { required: true })

defineEmits<{
  remove: []
}>()

const { t } = useTrans({
  en: {
    i_name_ph: 'John Doe',
    i_title_ph: 'CEO'
  },
  ja: {
    i_name_ph: '山田 太郎',
    i_title_ph: 'CEO'
  }
})

const { validation } = useValidation(() => ({
  name: name.value,
  title: title.value
}), {
  name: {
    required: required(),
    maxLength: maxLength(255)
  },
  title: {
    required: required(),
    maxLength: maxLength(255)
  }
})
</script>

<template>
  <div class="CompanyPortfolioDataFormUpdateBalanceConfirmationMailDestination">
    <SInputText
      :placeholder="t.i_name_ph"
      v-model="name"
      :validation="validation.name"
      hide-error
    />
    <SInputText
      :placeholder="t.i_title_ph"
      v-model="title"
      :validation="validation.title"
      hide-error
    />
    <SButton
      type="text"
      mode="mute"
      :icon="IconTrash"
      :disabled="!removable"
      @click="$emit('remove')"
    />
  </div>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataFormUpdateBalanceConfirmationMailDestination {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 8px;
  padding: 0 8px;
}
</style>
