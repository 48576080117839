import { type RoleFrag } from '@/graphql'
import { defineOps } from './Ops'

export const RoleTextDict = {
  God: 'System Admin',
  Capitalist: 'Capitalist',
  FundManager: 'Fund Manager',
  ReadOnly: 'Read Only',
  Legal: 'Legal',
  Analyst: 'Analyst',
  Supporter: 'Supporter',
  GeneralPartner: 'General Partner',
  IR: 'IR',
  InitialReviewMeetingMember: 'Initial Review Meeting Member'
} as const

export const useRoleOps = defineOps(() => {
  function nameText(role: RoleFrag): string {
    return RoleTextDict[role.name as keyof typeof RoleTextDict]
  }

  return {
    nameText
  }
})
