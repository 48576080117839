<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Notes',
    info: 'Any additional notes or memo for this round.',
    ph: 'Add any additional notes or memo if there are any.'
  },
  ja: {
    label: 'Notes',
    info: '本ラウンドに関する追加のメモや備考。',
    ph: '追加のメモや備考があれば記述してください。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
