import { Model } from './Model'

export interface StatInvestmentSummaryInput {
  amount: string
  deals: number
  companies: number
}

export class StatInvestmentSummary extends Model {
  amount: string
  deals: number
  companies: number

  constructor(input: StatInvestmentSummaryInput) {
    super()
    this.amount = input.amount
    this.deals = input.deals
    this.companies = input.companies
  }
}
