<script setup lang="ts">
import IconCheckCircle from '~icons/ph/check-circle-bold'
import IconClipboardText from '~icons/ph/clipboard-text-bold'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { type TableCellAvatarsOption, useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { CompanyBusinessReportOrderField, type FundAndOperatorsFrag, type FundReportWithIdFrag, OrderDirection } from '@/graphql'
import { useMe } from '@/composables/Auth'
import { useCompanyBusinessReportOps } from '@/composables/ops/CompanyBusinessReportOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanExportAllCompanyBusinessReport } from '@/composables/policies/CompanyBusinessReportPolicy'
import { useCompanyBusinessReportPage, useCompleteCompanyBusinessReport } from '@/composables/repos/CompanyBusinessReportRepo'
import CompanyBusinessReportExportAllDialog from './CompanyBusinessReportExportAllDialog.vue'

const props = defineProps<{
  fund: FundAndOperatorsFrag
  report: FundReportWithIdFrag
}>()

const { t } = useTrans({
  en: {
    reset: 'Reset filters',
    export_all: 'Export all reports',
    c_name: 'Company Name',
    c_status: 'Status',
    c_assignee: 'Assignee',
    c_first_invested_date: 'First Invested Date',
    c_updated_by: 'Updated by',
    c_updated_at: 'Updated at',
    complete: 'Complete',
    sort_asc: 'Sort ascending (A...Z)',
    sort_desc: 'Sort descending (Z...A)',
    e_cant_complete: 'Only in-review report can be completed.'
  },
  ja: {
    reset: 'フィルターをリセット',
    export_all: '全てエクスポート',
    c_name: '会社名',
    c_status: 'ステータス',
    c_assignee: '担当者',
    c_first_invested_date: '初回投資日',
    c_updated_by: '更新者',
    c_updated_at: '更新日時',
    complete: '完了する',
    sort_asc: 'ソート: 昇順 (A...Z)',
    sort_desc: 'ソート: 降順 (Z...A)',
    e_cant_complete: 'レビュー状態のレポートだけが完了状態にできます。'
  }
})

const { user } = useMe()

const route = useRoute()

const canExportAll = useCanExportAllCompanyBusinessReport(() => props.fund)

const showCompleteAction = computed(() => {
  return (
    userOps.hasRole(user as any, 'God')
    || props.fund.operators.some((o) => o.userID === user.id)
  )
})

const userOps = useUserOps()
const cbrOps = useCompanyBusinessReportOps()

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    fundReport: [Number(route.params.id)],
    fundId: props.fund.id
  },
  orderBy: {
    field: CompanyBusinessReportOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': Number
  },
  exclude: ['page.perPage']
})

const { data: cbrs, loading: loadingCbrs } = useCompanyBusinessReportPage(options)
const { execute: executeCompleteCompanyBusinessReport } = useCompleteCompanyBusinessReport()

const exportDialog = usePower()

const table = useTable({
  records: computed(() => cbrs.value?.items ?? []),
  loading: loadingCbrs,
  borderless: true,
  orders: [
    'name',
    'status',
    'assignee',
    'firstInvestedDate',
    'updatedBy',
    'updatedAt',
    'spacer',
    'actions'
  ],
  columns: {
    name: {
      label: t.c_name,
      grow: true,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createOrderOptions(CompanyBusinessReportOrderField.CompanyName)
        }
      ]),
      cell: (_, cbr) => ({
        type: 'text',
        icon: IconClipboardText,
        value: cbrOps.name(cbr),
        link: cbrOps.path(cbr),
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    status: {
      label: t.c_status,
      cell: (_, cbr) => ({
        type: 'state',
        label: cbrOps.statusText(cbr),
        mode: cbrOps.statusMode(cbr)
      })
    },
    assignee: {
      label: t.c_assignee,
      cell: (_, cbr) => ({
        type: 'avatars',
        avatars: cbrOps.assignees(cbr).map<TableCellAvatarsOption>((user) => ({
          image: userOps.avatarPath(user),
          name: userOps.name1st(user)
        })),
        color: 'soft'
      })
    },
    firstInvestedDate: {
      label: t.c_first_invested_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createOrderOptions(CompanyBusinessReportOrderField.FirstInvestedDate)
        }
      ]),
      cell: (_, cbr) => ({
        type: 'day',
        value: cbrOps.firstInvestedDate(cbr),
        format: 'YYYY-MM-DD'
      })
    },
    updatedBy: {
      label: t.c_updated_by,
      cell: (_, cbr) => ({
        type: 'avatar',
        image: userOps.avatarPath(cbr.updatedBy),
        name: userOps.name(cbr.updatedBy)
      })
    },
    updatedAt: {
      label: t.c_updated_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createOrderOptions(CompanyBusinessReportOrderField.FirstInvestedDate)
        }
      ]),
      cell: (_, cbr) => ({
        type: 'day',
        value: cbrOps.updatedAt(cbr),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    },
    actions: {
      show: showCompleteAction.value,
      resizable: false,
      cell: {
        type: 'actions',
        actions: [
          {
            show: (cbr) => cbrOps.isStatusInReview(cbr),
            icon: IconCheckCircle,
            mode: 'success',
            label: t.complete,
            onClick: (cbr) => completeReport(cbr.id)
          }
        ]
      }
    }
  }
})

function createOrderOptions(field: CompanyBusinessReportOrderField) {
  return [
    { label: t.sort_asc, onClick: () => { updateOrder(field, OrderDirection.Asc) } },
    { label: t.sort_desc, onClick: () => { updateOrder(field, OrderDirection.Desc) } }
  ]
}

function updateOrder(field: CompanyBusinessReportOrderField, direction: OrderDirection) {
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}

async function completeReport(id: number) {
  const newCbr = await executeCompleteCompanyBusinessReport(id)

  const cbr = cbrs.value?.items.find((cbr) => cbr.id === id)

  if (cbr) {
    cbr.status = newCbr.status
    cbr.updatedAt = newCbr.updatedAt
    cbr.updatedBy = newCbr.updatedBy
    cbr.updatedAt = newCbr.updatedAt
  }
}
</script>

<template>
  <div class="CompanyBusinessReportCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <SControlButton
              type="outline"
              mode="mute"
              :label="t.reset"
              @click="reset"
            />
          </SControlLeft>
          <SControlRight>
            <SControlButton
              v-if="canExportAll.ok"
              :label="t.export_all"
              :disabled="!cbrs?.pageInfo.total"
              @click="exportDialog.on"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="cbrs?.pageInfo.total ?? 0"
              :page="cbrs ? cbrs.pageInfo.page + 1 : 0"
              :per-page="cbrs?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
    <SModal :open="exportDialog.state.value" @close="exportDialog.off">
      <CompanyBusinessReportExportAllDialog
        :report="report"
        @cancel="exportDialog.off"
        @exported="exportDialog.off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-actions) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
  --table-col-left: auto;
  --table-col-right: 0;
  --table-col-border-left: 1px;
}

.list :deep(.col-name)              { --table-col-width: 320px; }
.list :deep(.col-status)            { --table-col-width: 192px; }
.list :deep(.col-assignee)          { --table-col-width: 280px; }
.list :deep(.col-firstInvestedDate) { --table-col-width: 168px; }
.list :deep(.col-updatedBy)         { --table-col-width: 192px; }
.list :deep(.col-updatedAt)         { --table-col-width: 192px; }
.list :deep(.col-spacer)            { --table-col-width: 40px; }
</style>
