<script setup lang="ts">
import IconCheckCircle from '~icons/ph/check-circle-bold'
import IconCircle from '~icons/ph/circle-bold'
import IconMinusCircle from '~icons/ph/minus-circle-bold'
import { orderBy } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import SPill from 'sefirot/components/SPill.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import {
  type DealEventAndBallotFrag,
  type DealEventFrag,
  type DealEventTemplateAndBallotFrag,
  type DealEventTemplateFrag,
  type DealWithIdFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useBroadcast } from '@/composables/Channel'
import { useDealEventOps } from '@/composables/ops/DealEventOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealCardEventList from './DealCardEventList.vue'

const props = defineProps<{
  deal: DealWithIdFrag & WithPermissionFrag
  template: DealEventTemplateFrag | DealEventTemplateAndBallotFrag
  events: DealEventFrag[] | DealEventAndBallotFrag[]
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate, canEditEventWithBallot } = useDealPolicy(props.deal)

const { broadcast } = useBroadcast('deal-status-updated')

const dealEventOps = useDealEventOps()

const modal = usePower()

const latestEvent = computed(() => orderBy(props.events, 'date').slice(-1)[0])

const status = computed(() => {
  if (!latestEvent.value) {
    return { value: 'todo', label: 'Todo' }
  } else if (dealEventOps.isSkipped(latestEvent.value)) {
    return { value: 'skipped', label: 'Skipped' }
  } else if (dealEventOps.isApproved(latestEvent.value)) {
    return { value: 'success', label: 'Approved' }
  } else if (dealEventOps.isConditionallyApproved(latestEvent.value)) {
    return { value: 'success', label: 'Cond. Approved' }
  } else if (dealEventOps.isRejected(latestEvent.value)) {
    return { value: 'rejected', label: 'Rejected' }
  } else {
    return { value: 'success', label: 'Executed' }
  }
})

const showAddButton = computed(() => {
  if (status.value.value !== 'todo') {
    return false
  }

  return props.template.event.hasBallot
    ? canEditEventWithBallot.value
    : canUpdate.value
})

function onAdded() {
  modal.off()
  emit('updated')
  broadcast()
}
</script>

<template>
  <div class="DealCardActionsItem" :class="[status.value]">
    <div class="name" role="button" @click="() => modal.on()">
      <span class="name-icon">
        <IconCircle v-if="status.value === 'todo'" class="name-icon-svg" />
        <IconMinusCircle v-else-if="status.value === 'skipped'" class="name-icon-svg" />
        <IconMinusCircle v-else-if="status.value === 'rejected'" class="name-icon-svg" />
        <IconCheckCircle v-else-if="status.value === 'success'" class="name-icon-svg" />
      </span>
      <span class="name-text">{{ template.event.name }}</span>
    </div>
    <div class="status">
      <SPill
        as="p"
        type="dimm"
        :mode="status.value === 'success' ? 'success' : status.value === 'rejected' ? 'danger' : 'neutral'"
        :label="status.label"
      />
    </div>
    <div class="date">
      {{ latestEvent && `at ${dealEventOps.dateText(latestEvent)}` }}
    </div>
    <div class="actions">
      <SButton
        v-if="showAddButton"
        size="mini"
        mode="info"
        label="Add result"
        @click="modal.on"
      />
    </div>

    <DModal :open="modal.state.value" @close="modal.off">
      <DealCardEventList
        :deal="deal"
        :template="template"
        :events="events"
        @close="modal.off"
        @added="onAdded"
        @updated="$emit('updated')"
      />
    </DModal>
  </div>
</template>

<style scoped lang="postcss">
.DealCardActionsItem {
  display: grid;
  grid-template-columns: 320px 144px 1fr;
  height: 48px;

  @media (min-width: 1280px) {
    grid-template-columns: 320px 144px 128px 1fr;
  }
}

.name {
  display: flex;
  align-items: center;
  padding: 12px 0 12px 24px;
  line-height: 24px;
  font-size: 14px;
}

.name-icon-svg {
  width: 18px;
  height: 18px;

  .DealCardActionsItem.todo &     { color: var(--c-text-3); }
  .DealCardActionsItem.skipped &  { color: var(--c-text-3); }
  .DealCardActionsItem.success &  { color: var(--c-success-text); }
  .DealCardActionsItem.rejected & { color: var(--c-danger-text); }

  .DealCardActionsItem.skipped &  { transform: rotate(-45deg); }
  .DealCardActionsItem.rejected & { transform: rotate(-45deg); }
}

.name-text {
  display: inline-block;
  margin-left: 8px;
  transition: color 0.2s;

  .DealCardActionsItem.todo &     { color: var(--c-text-1); }
  .DealCardActionsItem.skipped &  { color: var(--c-text-2); }
  .DealCardActionsItem.success &  { color: var(--c-success-text); }
  .DealCardActionsItem.rejected & { color: var(--c-danger-text); }

  .DealCardActionsItem.todo .name:hover &     { color: var(--c-info-text); }
  .DealCardActionsItem.skipped .name:hover &  { color: var(--c-info-text); }
  .DealCardActionsItem.success .name:hover &  { color: var(--c-success-text-dark); }
  .DealCardActionsItem.rejected .name:hover & { color: var(--c-danger-text-dark); }
}

.status {
  display: flex;
  padding: 12px 0;
}

.date {
  display: none;
  align-items: center;
  font-size: 12px;
  color: var(--c-text-2);

  @media (min-width: 1280px) {
    display: flex;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}
</style>
