<script setup lang="ts">
import SLoginPage, { type Action } from 'sefirot/components/SLoginPage.vue'
import { useLogin } from '@/composables/Auth'

const login = useLogin()

const cover = '/img/login/golden-gate-bridge-rita-morais.jpg'

const coverTitle = {
  text: 'Golden Gate Bridge',
  link: 'https://unsplash.com/photos/LjE32XEW01g'
}

const coverPhotographer = {
  text: 'Keegan Houser',
  link: 'https://unsplash.com/@khouser01'
}

const actions: Action[] = [
  { type: 'google', onClick: login }
]
</script>

<template>
  <div class="Login">
    <SLoginPage
      :cover="cover"
      :cover-title="coverTitle"
      :cover-photographer="coverPhotographer"
      :actions="actions"
    />
  </div>
</template>
