<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useFundReportOps } from '@/composables/ops/FundReportOps'
import { useFundItem } from '@/composables/repos/FundRepo'
import { useFundReportItem } from '@/composables/repos/FundReportRepo'
import CompanyBusinessReportCatalog from '@/components/company-business-report/CompanyBusinessReportCatalog.vue'

const route = useRoute()

const { data: fundReport } = useFundReportItem(Number(route.params.id))
const { data: fund } = useFundItem(() => fundReport.value?.fund.id)

const fundReportOps = useFundReportOps()

const title = computed(() => fundReport.value ? fundReportOps.title(fundReport.value) : undefined)
</script>

<template>
  <DPage class="CompanyBusinessReportsByFundReportIdIdIndex" :title="title">
    <CompanyBusinessReportCatalog
      v-if="fundReport && fund"
      :fund="fund"
      :report="fundReport"
    />
  </DPage>
</template>
