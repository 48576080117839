import { Company } from './Company'
import { FundClosingCheckList } from './FundClosingCheckList'
import { type Day, Model } from './Model'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export interface FundClosingCheckListRecordInput {
  permittedActions: AccessControlled['permittedActions']

  id: number
  fundClosingCheckList: object
  company: object
  business?: string | null
  personnelStructure?: string | null
  capitalTies?: string | null
  financialSituation?: string | null
  other?: string | null
  createdAt: string
  createdBy: object
  updatedAt: string
  updatedBy: object
}

export class FundClosingCheckListRecord extends Model {
  subject = AccessResource.fundClosingCheckListRecord
  permittedActions: AccessControlled['permittedActions']

  id: number
  fundClosingCheckList: FundClosingCheckList
  company: Company
  business: string | null
  personnelStructure: string | null
  capitalTies: string | null
  financialSituation: string | null
  other: string | null
  createdBy: User
  createdAt: Day
  updatedBy: User
  updatedAt: Day

  constructor(input: FundClosingCheckListRecordInput) {
    super()
    this.permittedActions = input.permittedActions ?? []

    this.id = input.id
    this.fundClosingCheckList = this.hasOne(FundClosingCheckList, input.fundClosingCheckList)
    this.company = this.hasOne(Company, input.company)
    this.business = input.business ?? null
    this.personnelStructure = input.personnelStructure ?? null
    this.capitalTies = input.capitalTies ?? null
    this.financialSituation = input.financialSituation ?? null
    this.other = input.other ?? null
    this.createdBy = this.hasOne(User, input.createdBy)
    this.createdAt = this.day(input.createdAt)
    this.updatedBy = this.hasOne(User, input.updatedBy)
    this.updatedAt = this.day(input.updatedAt)
  }

  title() {
    return this.company!.name
  }

  path() {
    return `/fund-closing-check-list-records/${this.id}`
  }
}
