<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type OpportunityFile, type OpportunityFileDownload } from '@/graph/Opportunity'
import { useOpportunityFileDownloadOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  fileDownloads: OpportunityFileDownload[]
}>()

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: {
    title: 'File download history',
    file_name: 'File name',
    file_name_value: (v: OpportunityFile | null) => v?.name ?? 'Deleted file',
    downloaded_by: 'Downloaded by',
    downloaded_at: 'Downloaded at'
  },
  ja: {
    title: 'ファイルダウンロード履歴',
    file_name: 'ファイル名',
    file_name_value: (v: OpportunityFile | null) => v?.name ?? '削除済みファイル',
    downloaded_by: 'ダウンロード者',
    downloaded_at: 'ダウンロード日時'
  }
})

const userOps = useUserOps()
const fdOps = useOpportunityFileDownloadOps()

const table = useTable({
  records: computed(() => props.fileDownloads),
  borderless: true,
  orders: [
    'fileName',
    'downloadedBy',
    'downloadedAt'
  ],
  columns: {
    fileName: {
      label: t.file_name,
      grow: true,
      cell: (_, fd) => ({
        type: 'text',
        value: t.file_name_value(fd.opportunityFile),
        color: fd.opportunityFile ? 'neutral' : 'mute'
      })
    },
    downloadedBy: {
      label: t.downloaded_by,
      cell: (_, fd) => ({
        type: 'avatar',
        image: userOps.avatarPath(fd.downloadedBy),
        name: userOps.name(fd.downloadedBy)
      })
    },
    downloadedAt: {
      label: t.downloaded_at,
      cell: (_, fd) => ({
        type: 'day',
        value: fdOps.createdAt(fd),
        format: 'YYYY-MM-DD HH:mm'
      })
    }
  }
})
</script>

<template>
  <SCard class="OpportunityDescFileDownloadHistory" size="large">
    <SCardBlock class="s-pl-16 s-pr-8" size="small">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">{{ t.title }}</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarClose @click="$emit('close')" />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="list">
      <STable :options="table" />
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.list :deep(.col-fileName)     { --table-col-width: 192px; }
.list :deep(.col-downloadedBy) { --table-col-width: 192px; }
.list :deep(.col-downloadedAt) { --table-col-width: 192px; }
</style>
