<script setup lang="ts">
import IconCaretLeft from '~icons/ph/caret-left-bold'
import IconCaretRight from '~icons/ph/caret-right-bold'
import SButton from 'sefirot/components/SButton.vue'
import SButtonGroup from 'sefirot/components/SButtonGroup.vue'
import { useData } from 'sefirot/composables/Data'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { type UnwrapRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { type DurationType, useDuration } from '@/composables/Duration'
import { useUserItem } from '@/composables/repos/UserRepo'
import UsersProfilePerformanceDealDroppedTable from './UsersProfilePerformanceDealDroppedTable.vue'
import UsersProfilePerformanceDealInvestedTable from './UsersProfilePerformanceDealInvestedTable.vue'
import UsersProfilePerformanceDealOpenTable from './UsersProfilePerformanceDealOpenTable.vue'
import UsersProfilePerformanceOverview from './UsersProfilePerformanceOverview.vue'
import UsersProfileSection from './UsersProfileSection.vue'

const route = useRoute()

const { data: user } = useUserItem(Number(route.params.id))

const durationTypes = [
  { label: 'Half', value: 'half' as DurationType },
  { label: 'Quarter', value: 'quarter' as DurationType }
]

const { type, from, to, hasNext, label: durationLabel, prev, next, set } = useDuration('half')

const { data: options } = useData({
  condition: {
    from: from.value.toISOString(),
    to: to.value.toISOString()
  }
})

export type UserProfilePerformanceOptions = UnwrapRef<typeof options>

watch([type, () => from.value.toISOString()], () => {
  options.value.condition.from = from.value.toISOString()
  options.value.condition.to = to.value.toISOString()
}, { immediate: true })

watch([() => options.value.condition.from, () => options.value.condition.to], () => {
  set(options.value.condition.from, options.value.condition.to)
}, { immediate: true })

useUrlQuerySync(options, {
  casts: {
    'condition.from': (v) => v,
    'condition.to': (v) => v
  }
})
</script>

<template>
  <UsersProfileSection
    class="UsersProfilePerformanceCatalog"
    title="Performance overview"
  >
    <template #actions>
      <div class="actions">
        <SButtonGroup>
          <SButton
            v-for="{ label, value } in durationTypes"
            :key="value"
            size="small"
            :type="type === value ? 'fill' : 'outline'"
            :mode="type === value ? 'info' : 'mute'"
            :label-mode="type === value ? 'info' : 'mute'"
            :label="label"
            @click="type = value"
          />
        </SButtonGroup>
        <SButtonGroup class="duration-button-group">
          <SButton
            size="small"
            type="outline"
            mode="mute"
            label-mode="info"
            :label="durationLabel"
          />
          <SButton
            size="small"
            type="outline"
            mode="mute"
            :icon="IconCaretLeft"
            @click="prev"
          />
          <SButton
            size="small"
            type="outline"
            mode="mute"
            :icon="IconCaretRight"
            :disabled="!hasNext"
            @click="hasNext && next()"
          />
        </SButtonGroup>
      </div>
    </template>
    <template v-if="user">
      <UsersProfilePerformanceOverview :user="user" :options="options" />
      <UsersProfilePerformanceDealOpenTable :user="user" :options="options" />
      <UsersProfilePerformanceDealInvestedTable :user="user" :options="options" />
      <UsersProfilePerformanceDealDroppedTable :user="user" :options="options" />
    </template>
  </UsersProfileSection>
</template>

<style scoped lang="postcss">
.actions {
  display: flex;
  gap: 8px;
}

.duration-button-group {
  display: flex;

  :deep(.SButton) {
    &.has-label {
      width: 96px;
      --button-hover-bg-color: var(--button-bg-color);
      --button-active-bg-color: var(--button-bg-color);
      --button-disabled-text-color: var(--button-outline-mute-text-color);
      cursor: default;
    }

    &.has-lead-icon:not(.disabled) .icon {
      color: var(--button-outline-info-text-color);
    }
  }
}

.DCard {

  & + & {
    margin-top: 16px;
  }
}
</style>
