<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { useUserActivityOverviewItem } from '@/composables/repos/UserRepo'
import UsersProfileCard from './UsersProfileCard.vue'
import { type UserProfilePerformanceOptions } from './UsersProfilePerformanceCatalog.vue'
import UsersProfilePerformanceOverviewFieldGuide from './UsersProfilePerformanceOverviewFieldGuide.vue'

const props = defineProps<{
  user: UserFrag
  options: UserProfilePerformanceOptions
}>()

const { data, loading } = useUserActivityOverviewItem(props.user.id, props.options.condition)

const items = computed(() => [
  { label: 'Created companies', text: String(data.value?.createdCompanies ?? 0) },
  { label: 'New approaches', text: String(data.value?.newApproaches ?? 0) },
  { label: 'Re approaches', text: String(data.value?.reApproaches ?? 0) },
  { label: 'Total approaches', text: String(data.value?.totalApproaches ?? 0) },
  { label: 'Created deals', text: String(data.value?.createdDeals ?? 0) },
  { label: 'Invested deals', text: String(data.value?.investedDeals ?? 0) },
  { label: 'Dropped deals', text: String(data.value?.droppedDeals ?? 0) },
  { label: 'Created action notes', text: String(data.value?.createdActionNotes ?? 0) }
])

const { state: modal, on, off } = usePower()
</script>

<template>
  <UsersProfileCard
    class="UsersProfilePerformanceOverview"
    title="Activity overview"
    show-help-icon
    @click-help-icon="() => on()"
  >
    <DCardGrid v-if="!loading">
      <DCardGridItem v-for="item in items" :key="item.label" span="1">
        <DCardGridLabel :text="item.label" />
        <DCardGridText :text="item.text" :class="{ zero: item.text === '0' }" />
      </DCardGridItem>
    </DCardGrid>
    <SModal :open="modal">
      <UsersProfilePerformanceOverviewFieldGuide @close="off" />
    </SModal>
  </UsersProfileCard>
</template>

<style scoped lang="postcss">
.DCardGridText {
  font-size: 20px;

  &.zero {
    color: var(--c-text-2);
  }
}
</style>
