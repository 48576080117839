<template>
  <div class="DFormSectionLead">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormSectionLead {
  max-width: 592px;
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);

  & + & {
    padding-top: 8px;
  }
}
</style>
