import { RoundNamesDocument } from '@/graphql'
import { Request } from './Request'

export class RoundNameRequest extends Request {
  fetchAll() {
    return this.request({
      query: RoundNamesDocument
    })
  }
}
