import { Fund } from './Fund'
import { type Day, Model } from './Model'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export interface FundClosingCheckListInput {
  permittedActions: any
  id: number
  date: string
  funds: object[]
  createdAt: string
  createdBy: object
  updatedAt: string
  updatedBy: object
  __typename: string
}

export class FundClosingCheckList extends Model {
  subject = AccessResource.fundClosingCheckList
  permittedActions: AccessControlled['permittedActions']

  id: number
  date: Day
  funds: Fund[]
  createdAt: Day
  createdBy: User
  updatedAt: Day
  updatedBy: User
  __typename: string

  constructor(input: FundClosingCheckListInput) {
    super()
    this.permittedActions = input.permittedActions ?? []

    this.id = input.id
    this.date = this.day(input.date)
    this.funds = this.hasMany(Fund, input.funds)
    this.createdAt = this.day(input.createdAt)
    this.createdBy = this.hasOne(User, input.createdBy)
    this.updatedAt = this.day(input.updatedAt)
    this.updatedBy = this.hasOne(User, input.updatedBy)
    this.__typename = input.__typename
  }

  title() {
    return `ファンド決算チェックリスト_${this.date?.format('YYYYMMDD')}`
  }

  path() {
    return `/fund-closing-check-lists/${this.id}`
  }
}
