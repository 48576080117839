<script setup lang="ts">
import IconTrash from '~icons/ph/trash-bold'
import IconX from '~icons/ph/x-bold'
import SActionList from 'sefirot/components/SActionList.vue'
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { ref } from 'vue'
import { type ActionNoteFrag, type CompanyAndNameAndPermissionFrag } from '@/graphql'
import { useActionNoteOps } from '@/composables/ops/ActionNoteOps'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanDeleteActionNote, useCanUpdateActionNote } from '@/composables/policies/CompanyPolicy'
import DMarkdownLink from '../DMarkdownLink.vue'
import ActionNoteFormDelete from './ActionNoteFormDelete.vue'
import ActionNoteFormUpdate from './ActionNoteFormUpdate.vue'

const props = defineProps<{
  actionNote: ActionNoteFrag
  company: CompanyAndNameAndPermissionFrag
}>()

const emit = defineEmits<{
  close: []
  updated: [actionNote: ActionNoteFrag]
  deleted: []
}>()

const { t } = useTrans({
  en: {
    a_edit: 'Edit action note',
    a_delete: 'Delete action note',
    d_company: 'Company',
    d_created_by: 'Created by',
    d_created_at: 'Created at',
    d_updated_by: 'Updated by',
    d_updated_at: 'Updated at'
  },
  ja: {
    a_edit: 'Action Noteを編集',
    a_delete: 'Action Noteを削除',
    d_company: 'Company',
    d_created_by: '作成者',
    d_created_at: '作成日時',
    d_updated_by: '更新者',
    d_updated_at: '更新日時'
  }
})

const canUpdate = useCanUpdateActionNote(() => props.company)
const canDelete = useCanDeleteActionNote(() => props.company)

const userOps = useUserOps()
const companyOps = useCompanyOps()
const actionNoteOps = useActionNoteOps()

const updateDialog = usePower()
const deleteDialog = usePower()

const isDeleted = ref(false)

const actions = [
  { leadIcon: IconTrash, label: t.a_delete, onClick: () => { deleteDialog.on() } }
]

function onUpdated(actionNote: ActionNoteFrag) {
  updateDialog.off()
  emit('updated', actionNote)
}

function onDeleted() {
  isDeleted.value = true
  deleteDialog.off()
}

function onDeleteDialogUnmounted() {
  if (isDeleted.value) {
    emit('deleted')
    isDeleted.value = false
  }
}
</script>

<template>
  <SCard size="xlarge">
    <SCardBlock class="block">
      <div class="header">
        <div class="title">
          <div class="title-text">{{ actionNote.title }}</div>
          <div class="actions">
            <SButton
              v-if="canUpdate.ok"
              size="small"
              :label="t.a_edit"
              @click="updateDialog.on"
            />
            <SButton
              size="small"
              type="text"
              mode="mute"
              :icon="IconX"
              @click="emit('close')"
            />
          </div>
        </div>
      </div>
    </SCardBlock>
    <div class="grid">
      <SCardBlock class="body">
        <DMarkdownLink
          tag="div"
          class="content"
          :content="actionNote.body"
        />
      </SCardBlock>
      <div class="meta">
        <SCardBlock class="info">
          <SDesc dir="row" gap="16" :label-width="96" :divider="false">
            <SDescItem>
              <SDescLabel :value="t.d_company" />
              <SDescLink :value="companyOps.name(company)" :href="companyOps.path(company)" />
            </SDescItem>
            <SDescItem>
              <SDescLabel :value="t.d_created_by" />
              <SDescAvatar
                :avatar="{
                  avatar: userOps.avatarPath(actionNote.createdBy),
                  name: userOps.name(actionNote.createdBy)
                }"
              />
            </SDescItem>
            <SDescItem>
              <SDescLabel :value="t.d_created_at" />
              <SDescDay :value="actionNoteOps.createdAt(actionNote)" format="YYYY-MM-DD HH:mm" />
            </SDescItem>
            <SDescItem>
              <SDescLabel :value="t.d_updated_by" />
              <SDescAvatar
                :avatar="{
                  avatar: userOps.avatarPath(actionNote.updatedBy),
                  name: userOps.name(actionNote.updatedBy)
                }"
              />
            </SDescItem>
            <SDescItem>
              <SDescLabel :value="t.d_updated_at" />
              <SDescDay :value="actionNoteOps.updatedAt(actionNote)" format="YYYY-MM-DD HH:mm" />
            </SDescItem>
          </SDesc>
        </SCardBlock>
        <SCardBlock class="commands">
          <SActionList
            v-if="canDelete.ok"
            :list="actions"
          />
        </SCardBlock>
      </div>
    </div>
    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <ActionNoteFormUpdate
        :action-note="actionNote"
        @cancel="updateDialog.off"
        @updated="onUpdated"
      />
    </SModal>
    <SModal :open="deleteDialog.state.value" @close="deleteDialog.off">
      <ActionNoteFormDelete
        :action-note="actionNote"
        @cancel="deleteDialog.off"
        @deleted="onDeleted"
        @vue:unmounted="onDeleteDialogUnmounted"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.block {
  padding: 24px;
}

.title {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.title-text {
  flex-grow: 1;
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 288px;
  gap: 1px;
}

.body {
  padding: 24px;
  font-size: 14px;
  white-space: pre-wrap;

  :deep(a) {
    color: var(--c-text-info-1);
    transition: color 0.25s;

    &:hover {
      color: var(--c-text-info-2);
    }
  }
}

.info {
  padding: 24px;
}

.meta {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.commands {
  flex-grow: 1;
  padding: 12px;
}
</style>
