<script setup lang="ts">
import IconPlusCircle from '~icons/ph/plus-circle'
import IconTrash from '~icons/ph/trash-bold'
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type CompanyBusinessReportFinanceTransitionRecordFrag } from '@/graphql'
import CompanyBusinessReportInputFinanceTransitionRecordsRow from './CompanyBusinessReportInputFinanceTransitionRecordsRow.vue'

const props = defineProps<{
  index: number
  strict: boolean
  disabled: boolean
}>()

const model = defineModel<CompanyBusinessReportFinanceTransitionRecordFrag>({ required: true })

defineEmits<{
  delete: []
}>()

const { t } = useTrans({
  en: {
    delete_section: 'Remove section',
    title: () => `Section ${props.index + 1}`,
    add_row: 'Add row'
  },
  ja: {
    delete_section: 'セクションを削除',
    title: () => `セクション ${props.index + 1}`,
    add_row: '行を追加'
  }
})

function addRow() {
  model.value.rows.push({ key: null, value: null })
}

function deleteRow(row: number) {
  model.value.rows.splice(row, 1)
}
</script>

<template>
  <SCard class="CompanyBusinessReportInputFinanceTransitionRecordsItem">
    <SCardBlock size="small" class="s-pl-16 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            {{ t.title() }}
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlButton
            v-if="!disabled"
            type="text"
            mode="mute"
            :icon="IconTrash"
            :label="t.delete_section"
            :disabled="disabled"
            @click="$emit('delete')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-8">
      <div class="body">
        <CompanyBusinessReportInputFinanceTransitionRecordsRow
          v-for="(_, i) in model.rows"
          :key="i"
          :strict="strict"
          :deletable="model.rows.length > 1"
          :disabled="disabled"
          v-model="model.rows[i]"
          @delete="deleteRow(i)"
        />
        <div v-if="!disabled" class="add">
          <SButton
            type="text"
            mode="mute"
            :icon="IconPlusCircle"
            :label="t.add_row"
            @click="addRow"
          />
        </div>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
