<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { useDeleteCompanyPortfolioDataBalanceConfirmationMail } from '@/composables/repos/CompanyPortfolioDataRepo'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_delete: 'Delete physical addresses'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_delete: '送付先を削除'
  }
})

const mutation = useDeleteCompanyPortfolioDataBalanceConfirmationMail()

async function destroy() {
  await mutation.execute(props.companyId)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Delete this physical addresses</h2>
          <p>You are about to delete physical addresses for the balance confirmation. This action cannot be undone.</p>
        </STrans>
        <STrans lang="ja">
          <h2>郵送での残高確認送付先を削除する</h2>
          <p>郵送での残高確認送付先を削除します。この操作は取り消すことができません。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.a_delete"
            :loading="mutation.loading.value"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
