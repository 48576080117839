<script setup lang="ts">
import { watchEffect } from 'vue'
import { type Company } from '@/graph/Company'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useGetKadomonyNewsPage } from '@/composables/repos/KadomonyRepo'
import CompanyNewsListItem from './CompanyNewsListItem.vue'

const props = defineProps<{
  company: Company
}>()

const companyOps = useCompanyOps()

// Only execute the request when the companyUrl is available. Otherwise, we can
// keep the data `undefined` so that it gets displayed as items not found.
const { data: news, execute: getKadomonyNewsPage } = useGetKadomonyNewsPage()

watchEffect(() => {
  if (props.company.website) {
    getKadomonyNewsPage({
      page: 1,
      perPage: 5,
      conditions: {
        companyUrl: props.company.website
      }
    })
  } else {
    news.value = undefined
  }
})
</script>

<template>
  <DCard title="News">
    <template v-if="news?.data.length">
      <div class="list">
        <div v-for="n in news.data" :key="n.title" class="item">
          <CompanyNewsListItem :news="n" />
        </div>
        <div class="item">
          <SLink class="more" :href="companyOps.path(company, 'news')">
            View all news
          </SLink>
        </div>
      </div>
    </template>
    <template v-else>
      <DCardListEmpty>No items found.</DCardListEmpty>
    </template>
  </DCard>
</template>

<style scoped lang="postcss">
.list {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--c-divider);
}

.item {
  background-color: var(--c-bg-elv-3);
}

.more {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  font-size: 14px;
  color: var(--c-text-info-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-2);
  }
}
</style>
