<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { reactive } from 'vue'
import { type CompanyForSettingsFrag } from '@/graphql'
import CompanyFormClose from './CompanyFormClose.vue'

defineProps<{
  company: CompanyForSettingsFrag
}>()

defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    b_label: 'Close company'
  },
  ja: {
    b_label: 'Companyをクローズ'
  }
})

const closeDialog = reactive(usePower())
</script>

<template>
  <div class="CompanySettingsPage">
    <div class="container">
      <SCard mode="danger">
        <SCardBlock class="s-p-32">
          <SDoc>
            <SContent>
              <STrans lang="en">
                <h2>Close this company</h2>
                <p>When a Company is closed, it is marked as "unapproachable." This action should be taken when it is determined that there is no longer a need or it is inappropriate to approach the company, such as when the company has gone bankrupt or has made an exit.</p>
                <p>Additionally, closing a Company will result in all currently assigned representatives being unassigned.</p>
              </STrans>
              <STrans lang="ja">
                <h2>Companyをクローズする</h2>
                <p>Companyをクローズすると、Companyを「アプローチ不可」に指定することになります。企業が倒産したり、Exitしたなどの理由でこれ以上アプローチする必要がない、あるいはすべきではないと判断した場合にクローズ処理を行ってください。</p>
                <p>また、Companyをクローズすると、現在設定されている担当者は全て担当を解除されます。</p>
              </STrans>
            </SContent>
            <div class="action">
              <SButton
                mode="danger"
                :label="t.b_label"
                @click="closeDialog.on"
              />
            </div>
          </SDoc>
        </SCardBlock>
      </SCard>
    </div>
    <SModal :open="closeDialog.state" @close="closeDialog.off">
      <CompanyFormClose
        :company="company"
        @cancel="closeDialog.off"
        @closed="closeDialog.off(() => $emit('updated'))"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanySettingsPage {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
