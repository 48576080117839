<script setup lang="ts">
import IconSliders from '~icons/ph/sliders'
import { cloneDeep, isEqual } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, reactive, ref } from 'vue'
import { OrderDirection, type UserFrag, type ViewPageOrder, ViewPageOrderField } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'
import { type ViewPageCategory, useViewCollection } from '@/composables/view/ViewData'
import { useViewPolicy } from '@/composables/view/ViewPolicy'
import ViewFormCreate from '@/components/view/ViewFormCreate.vue'
import DInputSearch from '../DInputSearch.vue'
import DTabs, { type Tab } from '../DTabs.vue'

const { state: modal, on, off } = usePower(false)
const userOps = useUserOps()

const category = ref<ViewPageCategory>('all')
const tabs: Tab[] = [
  {
    label: 'All',
    value: 'all',
    color: 'neutral',
    onClick: () => { category.value = 'all' }
  },
  {
    label: 'Admin',
    value: 'admin',
    color: 'neutral',
    onClick: () => { category.value = 'admin' }
  }
]

const { canCreate, canAdminExport } = useViewPolicy()

const query = ref<string | null>(null)
const orderBy = ref<ViewPageOrder>({
  field: ViewPageOrderField.UpdatedAt,
  direction: OrderDirection.Desc
})

const options = reactive({
  page: { page: 0, perPage: 50 },
  condition: {
    query
  },
  orderBy,
  category
})
const defaultOptions = cloneDeep(options)

useUrlQuerySync({
  state: options,
  casts: {
    'page.page': Number,
    'condition.query': (v: string | null) => (v === '' || v === null) ? null : v
  },
  exclude: ['page.perPage']
})

const { loading: loadingViewCollection, data: viewCollection } = useViewCollection(options)

const table = useTable({
  orders: [
    'name',
    'sharedWith',
    'createdAt',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: (_, view) => ({
        type: 'text',
        icon: IconSliders,
        link: view.path,
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    sharedWith: {
      label: 'Shared with',
      cell: (users) => ({
        type: 'avatars',
        avatars: users.map((user) => ({
          image: userOps.avatarPath(user as UserFrag),
          name: user.firstNameEn
        })),
        color: 'soft'
      })
    },
    createdAt: {
      label: 'Created at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(ViewPageOrderField.CreatedAt, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(ViewPageOrderField.CreatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  },
  records: computed(() => viewCollection.value?.views.map((view) => ({
    name: view.name,
    path: view.path,
    sharedWith: view.sharedUsers,
    createdAt: view.createdAt?.format('YYYY/MM/DD')
  }))),
  total: computed(() => viewCollection.value?.page.total ?? 0),
  page: computed(() => (viewCollection.value?.page.page ?? 0) + 1),
  perPage: computed(() => viewCollection.value?.page.perPage),
  loading: loadingViewCollection,
  reset: computed(() => !isEqual(options, defaultOptions)),
  onPrev: () => updateCurrentPage(options.page.page - 1),
  onNext: () => updateCurrentPage(options.page.page + 1),
  onReset: resetFiltersAndOrders
})

function resetFiltersAndOrders() {
  options.page.page = 0
  query.value = null
  orderBy.value = defaultOptions.orderBy
  category.value = defaultOptions.category
}

function updateQuery(value: string | null) {
  query.value = value === '' ? null : value
  options.page.page = 0
}

function updateOrder(field: ViewPageOrderField, direction: OrderDirection) {
  orderBy.value.field = field
  orderBy.value.direction = direction
}

function updateCurrentPage(nextPage: number) {
  options.page.page = nextPage
}
</script>

<template>
  <div class="ViewCatalog">
    <div class="header">
      <DTabs v-if="canAdminExport" class="tabs" :selected="category" :tabs="tabs" />
      <DInputSearch
        class="search"
        placeholder="Search views"
        :model-value="query"
        @enter="updateQuery"
      />
      <SButton
        v-if="canCreate"
        class="action"
        mode="info"
        label="New view"
        @click="on"
      />
    </div>
    <STable class="list" :options="table" />
    <SModal :open="modal">
      <ViewFormCreate
        @cancel="off"
        @added="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.ViewCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.header {
  display: flex;
  gap: 16px
}

.tabs {
  flex-shrink: 0;
}

.search {
  flex-grow: 1;
}

.action {
  flex-shrink: 0;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)       { --table-col-width: 704px; }
.list :deep(.col-sharedWith) { --table-col-width: 244px; }
.list :deep(.col-createdAt)  { --table-col-width: 200px; }
.list :deep(.col-spacer)     { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
