import { type MaybeRefOrGetter, toValue } from 'vue'
import { type FundAndOperatorsFrag, FundStatus } from '@/graphql'
import { type PolicyResponse, allow, deny, pending, usePolicyResponse } from '../Policy'

export function useCanAddFundReport(
  funds: MaybeRefOrGetter<FundAndOperatorsFrag[] | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const fs = toValue(funds)

    if (!fs) {
      return pending()
    }

    const ok = fs.some((f) => {
      if (
        f.status !== FundStatus.Active
        && f.status !== FundStatus.ActiveOnlyFollowOn
      ) {
        return false
      }

      // If the user has God role, he should be able to see all funds
      // so we'll stop here and make everything pass.
      if (user.hasRoleGod()) {
        return true
      }

      // Else check if the user is assigned as an operator of at least a fund.
      return f.operators.some((o) => o.userID === user.id)
    })

    return ok ? allow() : deny()
  })
}
