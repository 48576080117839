<script setup lang="ts">
import SSpinner from 'sefirot/components/SSpinner.vue'
import { useApp } from '@/composables/App'

const { status, layout } = useApp()
</script>

<template>
  <div class="App">
    <template v-if="status === 'ready'">
      <Component :is="layout" />
    </template>
    <div v-else class="loading">
      <SSpinner class="loading-spinner" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.App {
  min-width: 100vw;
  min-height: 100vh;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  width: 48px;
  height: 48px;
}
</style>
