import { type Day, day } from 'sefirot/support/Day'
import { type ActionNoteAndCompanyWithNameFrag, type ActionNoteFrag } from '@/graphql'
import { useCompanyOps } from './CompanyOps'
import { defineOps } from './Ops'

export const useActionNoteOps = defineOps(() => {
  const companyOps = useCompanyOps()

  function fullTitle(an: ActionNoteAndCompanyWithNameFrag): string {
    return `${companyOps.name(an.company)} / ${an.title}`
  }

  function createdAt(an: ActionNoteFrag): Day {
    return day(an.createdAt)
  }

  function updatedAt(an: ActionNoteFrag): Day {
    return day(an.updatedAt)
  }

  return {
    fullTitle,
    createdAt,
    updatedAt
  }
})
