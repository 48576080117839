import {
  CompleteDealReportDocument,
  CompletedDealReportCountByCompanyAndFundDocument,
  CompletedDealReportCountByCompanyDocument,
  DeleteDealReportDocument,
  IncompleteDealReportDocument,
  StartExportingDealReportDocument,
  UpdateDealReportDocument,
  type UpdateDealReportInput
} from '@/graphql'
import { Request } from './Request'

export type { UpdateDealReportInput }

export class DealReportRequest extends Request {
  completedDealReportCountByCompany(companyId: number) {
    return this.request({
      query: CompletedDealReportCountByCompanyDocument,
      variables: {
        companyId
      }
    })
  }

  completedDealReportCountByCompanyAndFund(companyId: number, fundId: number) {
    return this.request({
      query: CompletedDealReportCountByCompanyAndFundDocument,
      variables: {
        companyId,
        fundId
      }
    })
  }

  update(id: number, input: UpdateDealReportInput) {
    return this.request({
      query: UpdateDealReportDocument,
      variables: {
        id,
        input
      }
    })
  }

  export(id: number) {
    return this.request({
      query: StartExportingDealReportDocument,
      variables: {
        id
      }
    })
  }

  complete(id: number) {
    return this.request({
      query: CompleteDealReportDocument,
      variables: {
        id
      }
    })
  }

  incomplete(id: number) {
    return this.request({
      query: IncompleteDealReportDocument,
      variables: {
        id
      }
    })
  }

  delete(id: number) {
    return this.request({
      query: DeleteDealReportDocument,
      variables: {
        id
      }
    })
  }
}
