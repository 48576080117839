import { maxLength, required, requiredIf } from 'sefirot/validation/rules'
import { type Ref } from 'vue'

export type Field =
  | FieldInputText
  | FieldInputTextarea
  | FieldInputNumber
  | FieldInputSelect

export interface FieldBase {
  __typename: string
  name: string
  label: string
  labelJa?: string
  placeholder: string | null
  placeholderJa?: string | null
  nullable: boolean
  editability: FieldEditability
  visibility: FieldVisibility
  validations: FieldValidation[]
  col: number
  width: number
}

export interface FieldInputText extends FieldBase {
  __typename: 'TextInputField'
  type: string
}

export interface FieldInputTextarea extends FieldBase {
  __typename: 'TextareaInputField'
  rows: number
}

export interface FieldInputNumber extends FieldBase {
  __typename: 'NumberInputField'
}

export interface FieldInputSelect extends FieldBase {
  __typename: 'SelectInputField'
  options: FieldOption[]
}

export interface FieldOption {
  label: string
  value: string
  enabled: boolean
}

export type FieldEditability = FieldEditabilityAlways | FieldEditabilityConditional

export const FieldEditabilityType = {
  Always: 'AlwaysFieldEditability',
  Conditional: 'ConditionalFieldEditability'
} as const

export interface FieldEditabilityBase {
  __typename: string
}

export interface FieldEditabilityAlways extends FieldEditabilityBase {
  __typename: 'AlwaysFieldEditability'
  none: boolean
}

export interface FieldEditabilityConditional extends FieldEditabilityBase, Conditional {
  __typename: 'ConditionalFieldEditability'
}

export type FieldVisibility = FieldVisibilityAlways | FieldVisibilityConditional

export const FieldVisibilityType = {
  Always: 'AlwaysFieldVisibility',
  Conditional: 'ConditionalFieldVisibility'
} as const

export interface FieldVisibilityBase {
  __typename: string
}

export interface FieldVisibilityAlways extends FieldVisibilityBase {
  __typename: 'AlwaysFieldVisibility'
  none: boolean
}

export interface FieldVisibilityConditional extends FieldVisibilityBase, Conditional {
  __typename: 'ConditionalFieldVisibility'
}

export type FieldValidation = RequiredFieldValidation | RequiredIfFieldValidation | MaxLengthFieldValidation

export const FieldValidationType = {
  Required: 'RequiredFieldValidation',
  RequiredIf: 'RequiredIfFieldValidation',
  MaxLength: 'MaxLengthFieldValidation'
} as const

export interface FieldValidationBase {
  __typename: string
}

export interface RequiredFieldValidation extends FieldValidationBase {
  __typename: 'RequiredFieldValidation'
  none: boolean
}

export interface RequiredIfFieldValidation extends FieldValidationBase, Conditional {
  __typename: 'RequiredIfFieldValidation'
}

export interface MaxLengthFieldValidation extends FieldValidationBase {
  __typename: 'MaxLengthFieldValidation'
  maxLength: number
}

export type Data = Record<string, any>

export interface Conditional {
  where: string
  operator: string
  value: string
}

export function isEditable(field: Field, data: Data): boolean {
  switch (field.editability.__typename) {
    case FieldEditabilityType.Always:
      return true
    case FieldEditabilityType.Conditional:
      return checkCondition(field.editability, data)
  }
}

export function isVisible(field: Field, data: Data): boolean {
  switch (field.visibility.__typename) {
    case FieldVisibilityType.Always:
      return true
    case FieldVisibilityType.Conditional:
      return checkCondition(field.visibility, data)
  }
}

export function createData(fields: Field[], data: Record<string, any>): Record<string, any> {
  return fields.reduce<Record<string, any>>((carry, field) => {
    carry[field.name] = data[field.name] ?? null
    return carry
  }, {})
}

export function createRules(fields: Field[], data: Ref<Data>): Record<string, any> {
  return fields.reduce<Record<string, any>>((rules, field) => {
    if (field.validations.length) {
      rules[field.name] = {}

      field.validations.forEach((validation) => {
        assignRule(rules[field.name], validation, data)
      })
    }

    return rules
  }, {})
}

function assignRule(
  rule: Record<string, any>,
  validation: FieldValidation,
  data: Ref<Data>
): void {
  switch (validation.__typename) {
    case FieldValidationType.Required:
      !validation.none && (rule.required = required())
      return

    case FieldValidationType.RequiredIf:
      rule.requiredIf = requiredIf(() => checkCondition(validation, data.value))
      return

    case FieldValidationType.MaxLength:
      rule.maxLength = maxLength(validation.maxLength)
  }
}

export function checkCondition(
  condition: Conditional,
  data: Record<string, any>
): boolean {
  const { where, operator, value } = condition

  return compare(data[where], value, operator)
}

function compare(left: string, right: string, operator: string): boolean {
  switch (operator) {
    case '=':
    case '==':
    case '===':
      return left === right
    case '!=':
    case '!==':
      return left !== right
    case '>':
      return left > right
    case '>=':
      return left >= right
    case '<':
      return left < right
    case '<=':
      return left <= right
    default:
      return false
  }
}
