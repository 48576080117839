<script setup lang="ts">
import { computed, useSlots } from 'vue'

const props = defineProps<{
  span?: string
}>()

const slots = useSlots()

const classes = computed(() => [
  `span-${props.span ?? '1'}`,
  { 'no-item': !slots.default }
])
</script>

<template>
  <div class="DCardGridItem" :class="classes">
    <slot />
    <div class="line" />
  </div>
</template>

<style scoped lang="postcss">
.DCardGridItem {
  &.span-1 { grid-column: span 1; }
  &.span-2 { grid-column: span 2; }
  &.span-3 { grid-column: span 3; }
  &.span-4 { grid-column: span 4; }
  &.span-5 { grid-column: span 5; }
  &.span-6 { grid-column: span 6; }
  &.span-7 { grid-column: span 7; }
  &.span-8 { grid-column: span 8; }

  &.no-item {
    border-bottom-color: transparent;
  }
}

.line {
  margin-top: 7px;
  border-top: 1px dashed var(--c-divider-2);
  width: 100%;
}
</style>
