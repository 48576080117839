<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type CompanyFinancialResultFrag } from '@/graphql'
import { useDeleteCompanyFinancialResult } from '@/composables/repos/CompanyPortfolioDataRepo'

const props = defineProps<{
  financialResult: CompanyFinancialResultFrag
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    title: 'Delete financial result',
    lead: 'You\'re about to delete the financial result, and this action can\'t be undone. Are you absolutely sure?',
    cancel: 'Cancel',
    delete: 'Delete'
  },
  ja: {
    title: '財務数値の削除',
    lead: '財務数値を削除しようとしています。この操作は取り消すことができません。本当によろしいですか？',
    cancel: 'キャンセル',
    delete: '削除'
  }
})

const { execute, loading } = useDeleteCompanyFinancialResult()

async function destroy() {
  await execute(props.financialResult.id)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-16">
      <SDoc>
        <SContent>
          <div class="s-font-20 s-font-w-500">{{ t.title }}</div>
          <p class="lead">{{ t.lead }}</p>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="medium" class="s-pl-8 s-px-16">
      <SControl size="medium">
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.delete"
            :loading="loading"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
