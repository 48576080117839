<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { reactive } from 'vue'
import { type Company, type CompanySupportLogWithMembers } from '@/graph/Company'
import { useCompanySupportLogList } from '@/composables/repos/CompanyRepo'
import CompanySupportLogDesc from '../company-support-log/CompanySupportLogDesc.vue'
import CompanySupportLogFormCreate from '../company-support-log/CompanySupportLogFormCreate.vue'
import CompanySupportLogListPageEmpty from './CompanySupportLogListPageEmpty.vue'

const props = defineProps<{
  company: Company
}>()

const { t } = useTrans({
  en: {
    b_add: 'New support log'
  },
  ja: {
    b_add: '支援実績を登録'
  }
})

const { data } = useCompanySupportLogList(props.company.id)

const addDialog = reactive(usePower())

function onAdded(supportLog: CompanySupportLogWithMembers) {
  data.value?.push(supportLog)
  addDialog.off()
}

function onUpdated(supportLog: CompanySupportLogWithMembers) {
  const index = data.value?.findIndex((log) => log.id === supportLog.id)
  if (index !== undefined && index !== -1) {
    data.value?.splice(index, 1, supportLog)
  }
}

function onDeleted(supportLog: CompanySupportLogWithMembers) {
  const index = data.value?.findIndex((log) => log.id === supportLog.id)
  if (index !== undefined && index !== -1) {
    data.value?.splice(index, 1)
  }
}
</script>

<template>
  <div class="CompanySupportLogPage">
    <div class="container">
      <template v-if="data?.length">
        <div class="main">
          <SControl>
            <SControlRight>
              <SControlButton :label="t.b_add" mode="info" @click="addDialog.on" />
            </SControlRight>
          </SControl>
          <div class="list">
            <div v-for="log in data" :key="log.id" class="item">
              <CompanySupportLogDesc
                :support-log="log"
                @updated="onUpdated"
                @deleted="onDeleted"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="data">
        <CompanySupportLogListPageEmpty @click="addDialog.on" />
      </template>
    </div>

    <SModal :open="addDialog.state" @close="addDialog.off">
      <CompanySupportLogFormCreate
        :company-id="company.id"
        @cancel="addDialog.off"
        @added="onAdded"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanySupportLogPage {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
