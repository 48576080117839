<script setup lang="ts">
import SPill from 'sefirot/components/SPill.vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { abbreviate } from '@/support/Num'

type TotalCount = number | null

const props = withDefaults(defineProps<{
  companyTotal: TotalCount
  actionNoteTotal: TotalCount
  opportunityTotal: TotalCount
  dealTotal: TotalCount
}>(), {
  companyTotal: null,
  actionNoteTotal: null,
  opportunityTotal: null,
  dealTotal: null
})

const router = useRouter()
const route = useRoute()

const items = computed(() => [
  createItem('Companies', 'companies', props.companyTotal),
  createItem('Action notes', 'action-notes', props.actionNoteTotal),
  createItem('Opportunities', 'opportunities', props.opportunityTotal),
  createItem('Deals', 'deals', props.dealTotal)
])

function createItem(text: string, type: string, total: TotalCount) {
  return {
    text,
    active: type === route.query.type,
    total: typeof total === 'number' ? total : 0,
    pill: typeof total === 'number' ? abbreviate(total) : null,
    onClick: () => router.push({ path: route.path, query: { ...route.query, type } })
  }
}
</script>

<template>
  <div class="SearchSideMenu">
    <ul class="list">
      <li v-for="item in items" :key="item.text" class="item">
        <button
          class="link"
          :class="{ active: item.active }"
          @click="() => item.onClick()"
        >
          <p class="text">
            {{ item.text }}
          </p>
          <SPill
            v-if="item.pill"
            class="pills"
            size="mini"
            :mode="item.total > 0 ? 'neutral' : 'mute'"
            :label="item.pill"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="postcss">
.SearchSideMenu {
  position: sticky;
  top: 92px;
  left: 0;
}

.list {

}

.item {
  border-bottom: 1px solid var(--c-divider-2);
}

.link {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0 8px 2px;
  width: 100%;
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
  transition: color 0.25s;

  &:hover,
  &.active {
    color: var(--c-text-1);
  }
}

.text {
  flex-grow: 1;
}
</style>
