<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useDealItem } from '@/composables/deal/DealData'
import { useDealPolicy } from '@/composables/deal/DealPolicy'
import { useRFundItem } from '@/composables/repos/FundRepo'
import DealEntryList from '@/components/deal-entry/DealEntryList.vue'

const route = useRoute()

const id = Number(route.params.id)
const fundId = ref<number | undefined>()

const { data: deal } = useDealItem(id, { catch: [401, 404] })
const { data: fund } = useRFundItem(fundId)

const { canView } = useDealPolicy(deal)

const title = computedWhen(deal, (d) => {
  const company = d.companyThroughRound()!
  const oppo = d.opportunityThroughRound()!
  return `${company.name} / ${oppo.title} / ${d.title} - Entries`
})

watch(deal, (d) => {
  if (d) {
    fundId.value = d.fundId
  }
})
</script>

<template>
  <DPage class="DealsIdEntries" :title="title" :authorize="canView">
    <div class="container">
      <DealEntryList
        v-if="deal && fund"
        :deal="deal"
        :fund="fund"
      />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.DealsIdEntries {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
