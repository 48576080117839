<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useOpportunityInitialReviewMeetingOps } from '@/composables/ops/OpportunityOps'
import { useCanInitiateInitialReviewMeetingRequest } from '@/composables/policies/OpportunityPolicy'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const canInitiateIrm = useCanInitiateInitialReviewMeetingRequest(() => props.opportunity)

const irmOps = useOpportunityInitialReviewMeetingOps()

const requestLink = computed(() => irmOps.requestInitiationUrl(props.opportunity))
</script>

<template>
  <SCardBlock class="s-p-24">
    <div v-if="!canInitiateIrm.ok" class="body">
      <div class="text mute">
        No initial review meeting has been held for this opportunity.
      </div>
    </div>
    <div v-else class="body">
      <div class="text">
        No initial review meeting has been held for this opportunity. It should most likely be held before opening this opportunity to funds, but with exception. <SLink class="link" href="https://docs.google.com/document/d/1vji8DikhpWqglGvMuPfqHaruK0YrJnepky56I1dc96A/edit#heading=h.ms5b6ul9jlgw">Learn more</SLink>.
      </div>
      <div class="action">
        <SButton
          size="small"
          mode="info"
          label="Make request"
          :href="requestLink"
        />
      </div>
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  align-items: center;
  gap: 48px;
}

.text {
  flex-grow: 1;
  max-width: 640px;
  font-size: 14px;
}

.text.mute {
  color: var(--c-text-3);
}

.link {
  color: var(--c-text-info-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-2);
  }
}
</style>
