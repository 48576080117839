import SMount from 'sefirot/components/SMount.vue'
import { mixin as mixinFandamental } from 'sefirot/mixins/Fundamental'
import { type Plugin } from 'vue'
import DActionList from '@/components/DActionList.vue'
import DActionListItem from '@/components/DActionListItem.vue'
import DAvatar from '@/components/DAvatar.vue'
import DCard from '@/components/DCard.vue'
import DCardBlock from '@/components/DCardBlock.vue'
import DCardBody from '@/components/DCardBody.vue'
import DCardClose from '@/components/DCardClose.vue'
import DCardFooter from '@/components/DCardFooter.vue'
import DCardFooterAction from '@/components/DCardFooterAction.vue'
import DCardFooterActions from '@/components/DCardFooterActions.vue'
import DCardGrid from '@/components/DCardGrid.vue'
import DCardGridItem from '@/components/DCardGridItem.vue'
import DCardGridLabel from '@/components/DCardGridLabel.vue'
import DCardGridLink from '@/components/DCardGridLink.vue'
import DCardGridMoney from '@/components/DCardGridMoney.vue'
import DCardGridPills from '@/components/DCardGridPills.vue'
import DCardGridRecord from '@/components/DCardGridRecord.vue'
import DCardGridText from '@/components/DCardGridText.vue'
import DCardInfo from '@/components/DCardInfo.vue'
import DCardInfoItem from '@/components/DCardInfoItem.vue'
import DCardInfoLabel from '@/components/DCardInfoLabel.vue'
import DCardInfoText from '@/components/DCardInfoText.vue'
import DCardInfoUsers from '@/components/DCardInfoUsers.vue'
import DCardList from '@/components/DCardList.vue'
import DCardListEmpty from '@/components/DCardListEmpty.vue'
import DCardListItem from '@/components/DCardListItem.vue'
import DCardView from '@/components/DCardView.vue'
import DContainer from '@/components/DContainer.vue'
import DDescUser from '@/components/DDescUser.vue'
import DForm from '@/components/DForm.vue'
import DFormAlert from '@/components/DFormAlert.vue'
import DFormFooter from '@/components/DFormFooter.vue'
import DFormFooterAction from '@/components/DFormFooterAction.vue'
import DFormFooterActions from '@/components/DFormFooterActions.vue'
import DFormGrid from '@/components/DFormGrid.vue'
import DFormGridItem from '@/components/DFormGridItem.vue'
import DFormLead from '@/components/DFormLead.vue'
import DFormMedium from '@/components/DFormMedium.vue'
import DFormSection from '@/components/DFormSection.vue'
import DFormSectionLead from '@/components/DFormSectionLead.vue'
import DFormSectionTitle from '@/components/DFormSectionTitle.vue'
import DFormTitle from '@/components/DFormTitle.vue'
import DGrid from '@/components/DGrid.vue'
import DGridDate from '@/components/DGridDate.vue'
import DGridItem from '@/components/DGridItem.vue'
import DGridLabel from '@/components/DGridLabel.vue'
import DGridPills from '@/components/DGridPills.vue'
import DGridState from '@/components/DGridState.vue'
import DGridText from '@/components/DGridText.vue'
import DModal from '@/components/DModal.vue'
import DPage from '@/components/DPage.vue'
import DView from '@/components/DView.vue'

export const components: Plugin = {
  install(app) {
    mixinFandamental(app)

    app.mixin({
      components: {
        SMount,
        DPage,
        DView,
        DModal,
        DAvatar,
        DActionList,
        DActionListItem,
        DGrid,
        DGridItem,
        DGridLabel,
        DGridDate,
        DGridPills,
        DGridState,
        DGridText,
        DCard,
        DCardClose,
        DCardView,
        DCardBody,
        DCardBlock,
        DCardFooter,
        DCardFooterAction,
        DCardFooterActions,
        DCardGrid,
        DCardGridItem,
        DCardGridLabel,
        DCardGridText,
        DCardGridLink,
        DCardGridMoney,
        DCardGridRecord,
        DCardGridPills,
        DCardInfo,
        DCardInfoItem,
        DCardInfoLabel,
        DCardInfoUsers,
        DCardInfoText,
        DCardList,
        DCardListEmpty,
        DCardListItem,
        DContainer,
        DDescUser,
        DForm,
        DFormTitle,
        DFormLead,
        DFormSection,
        DFormSectionTitle,
        DFormSectionLead,
        DFormMedium,
        DFormGrid,
        DFormGridItem,
        DFormAlert,
        DFormFooter,
        DFormFooterActions,
        DFormFooterAction
      }
    })
  }
}

declare module 'vue' {
  export interface GlobalComponents {
    DDescUser: typeof DDescUser
  }
}
