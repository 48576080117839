<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<Day | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Date *',
    info: 'The date when this opportunity was sourced.'
  },
  ja: {
    label: 'Date *',
    info: 'いつ案件をソーシングしたか。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required()
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputDate
    :label="t.label"
    :info="t.info"
    block
    v-model="model"
    :validation="validation.model"
  />
</template>
