import { type Day, day } from 'sefirot/support/Day'
import {
  BallotResultType,
  type DealEventFrag,
  DealEventType
} from '@/graphql'
import { defineOps } from './Ops'

export const useDealEventOps = defineOps(({ isJa }) => {
  function date(event: DealEventFrag): Day {
    return day(event.date)
  }

  function dateText(event: DealEventFrag): string {
    return isJa
      ? day(event.date).format('YYYY/MM/DD')
      : day(event.date).format('MMM D, YYYY')
  }

  function isExecuted(event: DealEventFrag): boolean {
    return event.type === DealEventType.Executed
  }

  function isReExecuted(event: DealEventFrag): boolean {
    return event.type === DealEventType.ReExecuted
  }

  function isDone(event: DealEventFrag): boolean {
    return isExecuted(event) || isReExecuted(event)
  }

  function isSkipped(event: DealEventFrag): boolean {
    return event.type === DealEventType.Skipped
  }

  function isApproved(event: DealEventFrag): boolean {
    return event.result === BallotResultType.Approved
  }

  function isConditionallyApproved(event: DealEventFrag): boolean {
    return event.result === BallotResultType.ConditionallyApproved
  }

  function isSuccess(event: DealEventFrag): boolean {
    return isApproved(event) || isConditionallyApproved(event)
  }

  function isRejected(event: DealEventFrag): boolean {
    return event.result === BallotResultType.Rejected
  }

  return {
    date,
    dateText,
    isExecuted,
    isReExecuted,
    isDone,
    isSkipped,
    isApproved,
    isConditionallyApproved,
    isSuccess,
    isRejected
  }
})
