<template>
  <div class="DCardView">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DCardView {
  display: grid;
}

.DCardView:has(> .DCardBlock + .DCardBlock.small) {
  grid-template-columns: minmax(672px, 832px) minmax(286px, 382px);
}

.DCardView :deep(> .DCardBlock + .DCardBlock) {
  border-top: 0;
  border-left: 1px solid var(--c-gutter);
  border-radius: 0 6px 6px 0;
}
</style>
