<script setup lang="ts">
import { groupBy, orderBy } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { day } from 'sefirot/support/Day'
import { computed } from 'vue'
import { type CompanyParticipationAuthority } from '@/graph/Company'
import { type CompanyParticipationFrag } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  companyParticipations: CompanyParticipationFrag[]
}>()

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: {
    c_name: 'Name',
    c_since: 'Since',
    c_until: 'Until',
    c_assignedBy: 'Assigned by',
    c_unassignedBy: 'Unassigned by',
    pic: 'Primary in-charge',
    dic: 'Deputy in-charge',
    participants: 'Participants',
    legal: 'Legal assignees'
  },
  ja: {
    c_name: '名前',
    c_since: '開始日',
    c_until: '終了日',
    c_assignedBy: '担当追加者',
    c_unassignedBy: '担当解除者',
    pic: '主担当',
    dic: '副担当',
    participants: '関係者',
    legal: '法務担当'
  }
})

const userOps = useUserOps()

const sections = computed(() => {
  const titleDict = {
    PrimaryInCharge: t.pic,
    DeputyInCharge: t.dic,
    Participant: t.participants
  }
  const ordered = orderBy(props.companyParticipations, ['until', 'since'], ['asc', 'desc'])
  const filtered = ordered.filter((p) => p.authority as CompanyParticipationAuthority !== 'LegalAssignee')
  const grouped = groupBy(filtered, 'authority')
  const dict = Object.fromEntries(Object.keys(titleDict).map((key) => [key, grouped[key]]))

  return Object.entries(dict).map(([key, records]) => ({
    title: (titleDict as any)[key],
    table: useTable({
      records: records ?? [],
      borderless: true,
      orders: [
        'name',
        'since',
        'until',
        'assignedBy',
        'unassignedBy'
      ],
      columns: {
        name: {
          label: t.c_name,
          grow: true,
          cell: (_, p) => ({
            type: 'avatar',
            image: userOps.avatarPath(p.user),
            name: userOps.name(p.user)
          })
        },
        since: {
          label: t.c_since,
          cell: (_, p) => ({
            type: 'day',
            value: p.since ? day(p.since) : null,
            format: 'YYYY-MM-DD'
          })
        },
        until: {
          label: t.c_until,
          cell: (_, p) => ({
            type: 'text',
            value: p.until ? day(p.until).format('YYYY-MM-DD') : 'Present'
          })
        },
        assignedBy: {
          label: t.c_assignedBy,
          cell: (_, p) => ({
            type: 'text',
            value: p.assignedBy ? userOps.name(p.assignedBy) : null
          })
        },
        unassignedBy: {
          label: t.c_unassignedBy,
          cell: (_, p) => ({
            type: 'text',
            value: p.unassignedBy ? userOps.name(p.unassignedBy) : null
          })
        }
      }
    })
  }))
})
</script>

<template>
  <SCard size="xlarge">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            <STrans lang="en">Participations history</STrans>
            <STrans lang="ja">担当者履歴</STrans>
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarClose @click="$emit('close')" />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <div class="grid">
      <SCard v-for="{ title, table } in sections" :key="title!">
        <SCardBlock size="small" class="s-px-16">
          <SDoc>
            <SContent>
              <div class="s-font-14 s-font-w-500">{{ title }}</div>
            </SContent>
          </SDoc>
        </SCardBlock>
        <SCardBlock class="container">
          <STable :options="table" class="list" />
        </SCardBlock>
      </SCard>
    </div>
  </SCard>
</template>

<style scoped lang="postcss">
.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.container {
  overflow: hidden;
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-until) {
  font-feature-settings: "tnum";
}

.list :deep(.col-name)         { --table-col-width: 192px; }
.list :deep(.col-since)        { --table-col-width: 144px; }
.list :deep(.col-until)        { --table-col-width: 144px; }
.list :deep(.col-assignedBy)   { --table-col-width: 192px; }
.list :deep(.col-unassignedBy) { --table-col-width: 192px; }
</style>
