import { createSharedComposable } from '@vueuse/core'
import { type FirebaseApp, initializeApp } from 'firebase/app'
import { type ComputedRef, type Ref, computed, shallowRef } from 'vue'

export interface Firebase {
  app: Ref<FirebaseApp | undefined>
  ready: ComputedRef<boolean>
}

export const useFirebaseApp = createSharedComposable((): Firebase => {
  const app = shallowRef<FirebaseApp>()
  const ready = computed(() => !!app.value)

  loadConfig()

  async function loadConfig(): Promise<void> {
    const { data } = (await fetch(`${import.meta.env.API_BASE_URL}/api/auth/config`)
      .then((res) => res.json())) as { data: { apiKey: string; appId: string; projectId: string } }

    app.value = initializeApp({
      apiKey: data.apiKey,
      appId: data.appId,
      projectId: data.projectId,
      authDomain: `${data.projectId}.firebaseapp.com`
    })
  }

  return {
    app,
    ready
  }
})
