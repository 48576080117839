<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { type Pill } from 'sefirot/components/SDescPill.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag, type ProposalFrag } from '@/graphql'
import * as Field from '@/support/Field'
import { useOpportunityProposalOps } from '@/composables/ops/OpportunityOps'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityFormUpdateProposal from './OpportunityFormUpdateProposal.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  proposal: ProposalFrag
  disabled: boolean
}>()

defineEmits<{
  updated: []
  close: []
}>()

const opportunityProposalOps = useOpportunityProposalOps()

const canUpdateOpportunityPolicy = useCanUpdateOpportunityPolicy(() => props.opportunity)

const { state: updateModal, on: openUpdateModal, off: closeUpdateModal } = usePower(false)

const status = computed(() => ({
  label: opportunityProposalOps.statusText(props.proposal),
  mode: opportunityProposalOps.statusMode(props.proposal)
}))

const sourcingInformation = computed(() => opportunityProposalOps.sourcingInformation(props.proposal))
const openedAt = computed(() => opportunityProposalOps.createdAt(props.proposal)?.format('YYYY-MM-DD HH:mm'))
const statusUpdatedAt = computed(() => opportunityProposalOps.statusUpdatedAt(props.proposal)?.format('YYYY-MM-DD HH:mm'))

const showEditButton = computed(() => {
  return (
    !!canUpdateOpportunityPolicy.value.ok
    && !props.disabled
  )
})

function isVisible(field: Field.Field) {
  return Field.isVisible(field, props.proposal)
}

function isText(field: Field.Field): field is Field.FieldInputText | Field.FieldInputTextarea | Field.FieldInputNumber {
  return field.__typename === 'TextInputField' || field.__typename === 'TextareaInputField' || field.__typename === 'NumberInputField'
}

function isSelect(field: Field.Field): field is Field.FieldInputSelect {
  return field.__typename === 'SelectInputField'
}

function getPill(field: Field.FieldInputSelect, value: string) {
  const option = field.options.find((f) => f.value === value)

  return { label: option ? option.label : null } as Pill
}
</script>

<template>
  <SCard size="medium" class="OpportunityCardProposalsItem">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            {{ proposal.fundGroup.name }} {{ proposal.fundGroup.lp }}
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="showEditButton"
              :icon="IconNotePencil"
              @click="openUpdateModal"
            />
            <SControlActionBarClose @click="$emit('close')" />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel value="Status" />
          <SDescState :state="status" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Sourcing information" />
          <SDescText :value="sourcingInformation" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Opened at" />
          <SDescText :value="openedAt" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Closed at" />
          <SDescText :value="statusUpdatedAt" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel value="Closed reason" />
          <SDescText :value="proposal.closedReason" />
        </SDescItem>
        <template v-for="field in proposal.fundGroup.proposalCustomFields">
          <SDescItem v-if="isVisible(field as Field.Field)" :key="field.name" span="2">
            <SDescLabel :value="field.label" />
            <SDescText v-if="isText(field as Field.Field)" :value="proposal.data[field.name]" />
            <SDescPill v-else-if="isSelect(field as Field.Field)" :pill="getPill(field as Field.FieldInputSelect, proposal.data[field.name])" />
          </SDescItem>
        </template>
      </SDesc>
    </SCardBlock>
    <SModal :open="updateModal">
      <OpportunityFormUpdateProposal
        :proposal="proposal"
        :sources="opportunity.sources"
        @updated="closeUpdateModal(() => $emit('updated'))"
        @close="closeUpdateModal"
      />
    </SModal>
  </SCard>
</template>
