<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxValue } from 'sefirot/validation/rules'
import { type CurrencyFrag } from '@/graphql'
import DInputMoney from '../../DInputMoney.vue'

const props = defineProps<{
  // Display curreny along with value when it is provided.
  currency?: CurrencyFrag | null

  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Post valuation',
    info: 'Post valuation of the round.',
    ph: '2,500,000,000'
  },
  ja: {
    label: 'Post valuation',
    info: '本ラウンドにおけるポスト時価総額。',
    ph: '2,500,000,000'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <DInputMoney
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :currency="currency"
    v-model="model"
    :validation="validation.model"
  />
</template>
