<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import IconTrash from '~icons/ph/trash-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computed, reactive } from 'vue'
import { type OpportunityForDetailsFrag, type ProposalViewExportStatusFrag } from '@/graphql'
import { useProposalViewExportStatusOps } from '@/composables/ops/ViewOps'
import { useCanAddOpportunityProposalPolicy, useCanDeleteAllOpportunityProposalPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityProposalAddWizard from '../opportunity-proposal/OpportunityProposalAddWizard.vue'
import OpportunityProposalsDeleteAllDialog from '../opportunity-proposal/OpportunityProposalsDeleteAllDialog.vue'
import OpportunityCardProposalsEmpty from './OpportunityCardProposalsEmpty.vue'
import OpportunityCardProposalsItems from './OpportunityCardProposalsItems.vue'
import OpportunityFormDisableProposalsDialog from './OpportunityFormDisableProposalsDialog.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  proposalViewExportStatuses: ProposalViewExportStatusFrag[]
}>()

defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    e_cannot_add_proposals: 'Cannot add proposals to closed opportunity.',
    e_cannot_delete_proposals: 'Cannot delete proposals from closed opportunity.'
  },
  ja: {
    e_cannot_add_proposals: 'ClosedなOpportunityにはProposalを追加できません。',
    e_cannot_delete_proposals: 'ClosedなOpportunityのProposalsは削除できません。'
  }
})

const canAddProposal = useCanAddOpportunityProposalPolicy(() => props.opportunity)
const canDeleteAllProposal = useCanDeleteAllOpportunityProposalPolicy(() => props.opportunity, () => props.proposalViewExportStatuses)

const pvesOps = useProposalViewExportStatusOps()

const addProposalsWizard = reactive(usePower(false))
const deleteAllProposalDialog = reactive(usePower())
const disableProposalsDialog = reactive(usePower())

const exporetdProposals = computed(() => {
  return pvesOps.exportedProposals(
    props.proposalViewExportStatuses,
    props.opportunity.proposals
  )
})

const showAddProposalButton = computed(() => {
  return (
    !!props.opportunity.proposals.length
    && (
      canAddProposal.value.is('undefined')
      || canDeleteAllProposal.value.is('opportunity-closed')
    )
    && !props.opportunity.isProposalsDisabled
  )
})

const showDeleteProposalsButton = computed(() => {
  return (
    (
      canDeleteAllProposal.value.is('undefined')
      || canDeleteAllProposal.value.is('opportunity-closed')
    )
    && !props.opportunity.isProposalsDisabled
  )
})

const addProposalsTooltip = computed(() => {
  return canDeleteAllProposal.value.is('opportunity-closed')
    ? { text: t.e_cannot_add_proposals }
    : undefined
})

const deleteProposalsTooltip = computed(() => {
  return canDeleteAllProposal.value.is('opportunity-closed')
    ? { text: t.e_cannot_delete_proposals }
    : undefined
})

function onDestroy() {
  exporetdProposals.value.length
    ? disableProposalsDialog.on()
    : deleteAllProposalDialog.on()
}
</script>

<template>
  <SCard
    class="OpportunityCardProposals"
    :class="[opportunity.isProposalsDisabled ? 'disabled' : '']"
    :mode="opportunity.isProposalsDisabled ? 'warning' : 'neutral'"
  >
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="title">
            <div class="title-text">Fund proposals</div>
            <div v-if="opportunity.isProposalsDisabled" class="title-disabled">(Disabled)</div>
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="showAddProposalButton"
              :icon="IconPlus"
              :disabled="canDeleteAllProposal.is('opportunity-closed')"
              :tooltip="addProposalsTooltip"
              @click="addProposalsWizard.on"
            />
            <SControlActionBarButton
              v-if="showDeleteProposalsButton"
              :icon="IconTrash"
              :disabled="canDeleteAllProposal.is('opportunity-closed')"
              :tooltip="deleteProposalsTooltip"
              @click="onDestroy"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunityCardProposalsItems
      v-if="props.opportunity.proposals.length"
      :opportunity="opportunity"
      :proposal-view-export-statuses="proposalViewExportStatuses"
      :disabled="opportunity.isProposalsDisabled"
      @updated="$emit('updated')"
    />
    <OpportunityCardProposalsEmpty
      v-else
      :opportunity="opportunity"
      @added="$emit('updated')"
    />
    <SModal :open="addProposalsWizard.state" @close="addProposalsWizard.off">
      <Suspense>
        <OpportunityProposalAddWizard
          :opportunity="opportunity"
          @close="addProposalsWizard.off"
          @added="addProposalsWizard.off(() => $emit('updated'))"
        />
      </Suspense>
    </SModal>
    <SModal :open="deleteAllProposalDialog.state" @close="deleteAllProposalDialog.off">
      <OpportunityProposalsDeleteAllDialog
        :opportunity="opportunity"
        @close="deleteAllProposalDialog.off"
        @deleted="deleteAllProposalDialog.off(() => $emit('updated'))"
      />
    </SModal>
    <SModal :open="disableProposalsDialog.state" @close="disableProposalsDialog.off">
      <OpportunityFormDisableProposalsDialog
        :opportunity="opportunity"
        :proposals="exporetdProposals"
        @close="disableProposalsDialog.off"
        @disabled="disableProposalsDialog.off(() => $emit('updated'))"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.title {
  display: flex;
  gap: 8px;
}

.title-text {
  font-size: 14px;
  font-weight: 500;
}

.title-disabled {
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-warning-1);
}

.OpportunityCardProposals.disabled {
  .title-text {
    color: var(--c-text-warning-1);
  }
}
</style>
