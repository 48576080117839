<script setup lang="ts">
import SButton, { type Mode, type Tooltip, type Type } from 'sefirot/components/SButton.vue'
import SPill, { type Mode as SPillMode } from 'sefirot/components/SPill.vue'
import SSpinner from 'sefirot/components/SSpinner.vue'
import { isObject } from 'sefirot/support/Utils'
import { type Component, computed } from 'vue'

export type Title = string | TitleWithLink

export interface TitleWithLink {
  text: string
  link?: string
}

export interface Action {
  tag?: string
  type?: Type
  mode?: Mode
  icon?: Component
  leadIcon?: Component
  trailIcon?: Component
  iconMode?: Mode
  label?: string
  labelMode?: Mode
  href?: string
  loading?: boolean
  disabled?: boolean
  tooltip?: string | Tooltip
  onClick?(): void
}

export interface PillItem {
  label: string
  mode?: SPillMode
}

export interface TagItem {
  icon: Component
  label: string
  link?: string
  color?: 'mute' | 'info' | 'success'
}

export interface Steps {
  status: string
  items: Step[]
}

export interface Step {
  mode: 'mute' | 'success' | 'danger'
}

export interface MenuItem {
  icon: Component
  label: string
  link: string
  match?: 'exact' | 'nested'
}

const props = defineProps<{
  loading?: boolean
  title?: Title | Title[]
  pills?: PillItem[]
  actions?: Action[]
  tags?: TagItem[]
  steps?: Steps
  menu?: MenuItem[] | MenuItem[][]
}>()

const _title = computed<TitleWithLink[]>(() => {
  if (!props.title) {
    return []
  }

  if (!Array.isArray(props.title)) {
    return [{ text: props.title }] as TitleWithLink[]
  }

  return props.title.map((t) => {
    return isObject(t) ? t : { text: t }
  }) as TitleWithLink[]
})

const _menu = computed(() => {
  if (!props.menu || !props.menu.length) {
    return
  }

  return Array.isArray(props.menu[0]) ? props.menu : [props.menu]
})
</script>

<template>
  <div class="DLocalNav">
    <div v-if="loading" class="loader">
      <div class="loader-icon">
        <SSpinner class="loader-svg" />
      </div>
    </div>

    <div v-else class="content">
      <div v-if="_title.length || pills?.length || $slots.actions" class="upper">
        <div v-if="_title.length" class="title">
          <div class="title-main">
            <p class="title-text">
              <SLink v-for="(t, i) in _title" :key="i" class="title-text-item" :href="t.link">
                {{ t.text }}
              </SLink>
            </p>

            <div v-if="pills?.length" class="pills">
              <SPill
                v-for="pill in pills"
                :key="pill.label"
                tag="div"
                type="dimm"
                :mode="pill.mode"
                :label="pill.label"
              />
            </div>
          </div>

          <div v-if="actions?.length" class="actions">
            <div v-for="action, i in actions" :key="i" class="action">
              <SButton
                size="small"
                :type="action.type"
                :mode="action.mode"
                :icon="action.icon"
                :lead-icon="action.leadIcon"
                :trail-icon="action.trailIcon"
                :icon-mode="action.iconMode"
                :label="action.label"
                :label-mode="action.labelMode"
                :href="action.href"
                :loading="action.loading"
                :disabled="action.disabled"
                :tooltip="action.tooltip"
                @click="() => { action.onClick?.() }"
              />
            </div>
          </div>

          <div v-if="$slots.actions" class="actions">
            <slot name="actions" />
          </div>
        </div>

        <ul v-if="tags?.length" class="tags">
          <li v-for="tag in tags" :key="tag.label" class="tag">
            <SLink class="tag-text" :class="[tag.color ?? 'mute']" :href="tag.link">
              <component :is="tag.icon" class="tag-icon" />
              {{ tag.label }}
            </SLink>
          </li>
        </ul>

        <div v-if="steps" class="steps">
          <div class="steps-bars">
            <div
              v-for="(item, index) in steps.items"
              :key="index"
              class="steps-bar"
              :class="[item.mode]"
            />
          </div>
          <p class="steps-status">
            {{ steps.status }}
          </p>
        </div>
      </div>

      <div v-if="_menu?.length" class="menu">
        <div v-for="(group, index) in _menu" :key="index" class="menu-container">
          <div v-for="item in (group as MenuItem[])" :key="item.label" class="menu-item">
            <SLink class="menu-link" :href="item.link" :class="[item.match ?? 'fuzzy']">
              <component v-if="item.icon" :is="item.icon" class="menu-icon" />
              {{ item.label }}
            </SLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DLocalNav {
  padding: 0 32px;
  background-color: var(--c-bg-elv-2);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 104px;
}

.loader-svg {
  width: 48px;
  height: 48px;
}

.upper {
  padding: 22px 0;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title-main {
  display: flex;
  gap: 12px;
}

.title-text {
  display: flex;
  flex-shrink: 0;
}

.title-text-item {
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;

  &::before {
    display: inline-block;
    margin: 0 8px;
    color: var(--c-text-2);
    content: "/";
  }

  &:first-child::before {
    display: none;
  }

  &.link {
    color: var(--c-info-text);
    transition: color 0.25s;
  }

  &.link:hover {
    color: var(--c-info-text-dark);
  }
}

.actions {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
}

.pills {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tags {
  display: flex;
  gap: 16px;
  padding-top: 4px;
}

.tag-text {
  display: flex;
  align-items: center;
  max-width: 328px;
  line-height: 16px;
  font-size: 12px;
  color: var(--c-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s;

  &.mute    { color: var(--c-text-2); }
  &.info    { color: var(--c-info-text); }
  &.success { color: var(--c-success-text); }

  &.mute.link:hover {
    color: var(--c-info-text);
  }
}

.tag-icon {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.steps {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 4px;
}

.steps-bars {
  display: flex;
  gap: 8px;
}

.steps-bar {
  flex-shrink: 0;
  border-radius: 2px;
  width: 48px;
  height: 4px;

  &.mute    { background-color: var(--c-gray-light-3); }
  &.success { background-color: var(--c-success-bg); }
  &.danger  { background-color: var(--c-danger-bg); }

  .dark &.mute    { background-color: var(--c-gray-dark-3); }
  .dark &.success { background-color: var(--c-success-bg); }
  .dark &.danger  { background-color: var(--c-danger-bg); }
}

.steps-status {
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.menu {
  display: flex;

  .upper + & {
    margin: -12px -4px 0;
  }
}

.menu-container {
  position: relative;
  display: flex;
  gap: 16px;

  & + & {
    padding-left: 36px;
  }

  & + &::before {
    position: absolute;
    top: 8px;
    left: 18px;
    width: 1px;
    height: 24px;
    background-color: var(--c-divider-1);
    content: "";
  }
}

.menu-link {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid transparent;
  padding: 8px 4px 11px;
  font-size: 14px;
  color: var(--c-text-2);
  transition: border-color 0.25s, color 0.25s;

  &:hover {
    color: var(--c-text-1);
  }

  &.fuzzy.router-link-active,
  &.exact.router-link-exact-active {
    color: var(--c-text-1);
    border-bottom-color: var(--c-info-light);
  }
}

.menu-icon {
  width: 16px;
  height: 16px;
}
</style>
