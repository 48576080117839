<script setup lang="ts">
import SLocalNav from 'sefirot/components/SLocalNav.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useSurveyItem } from '@/composables/repos/SurveyRepo'

const route = useRoute()

const { data: survey } = useSurveyItem(() => Number(route.params.id))

const surveyOps = useSurveyOps()

const title = computed(() => [
  { text: 'Surveys', link: '/surveys' },
  { text: survey.value ? surveyOps.displayId(survey.value) : '...' }
])
</script>

<template>
  <div class="SurveysId">
    <SLocalNav :title="title" />

    <RouterView v-slot="{ Component, route: r }">
      <component :is="Component" :key="r.path" />
    </RouterView>
  </div>
</template>

<style scoped lang="postcss">
.SurveysId {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  background-color: var(--c-bg-elv-1);
}
</style>
