import { BallotResultType, BallotVoteChoice } from '@/graphql'
import { BallotVote } from './BallotVote'
import { Model } from './Model'

export { BallotResultType, BallotVoteChoice }

export class BallotResult extends Model {
  eventId?: number
  result?: BallotResultType
  votes: BallotVote[]

  constructor(data: Record<string, any>) {
    super()
    this.eventId = data.eventId
    this.votes = this.hasMany(BallotVote, data.votes)
  }
}
