<script setup lang="ts">
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import OpportunitySourceInputContactBackground from './input/OpportunitySourceInputContactBackground.vue'
import OpportunitySourceInputDate from './input/OpportunitySourceInputDate.vue'
import OpportunitySourceInputInboundOrOutboundAsync from './input/OpportunitySourceInputInboundOrOutboundAsync.vue'
import OpportunitySourceInputInvestmentRequestAsync from './input/OpportunitySourceInputInvestmentRequestAsync.vue'
import OpportunitySourceInputPromotedSourceAsync from './input/OpportunitySourceInputPromotedSourceAsync.vue'
import OpportunitySourceInputPromoter from './input/OpportunitySourceInputPromoter.vue'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<{
  date: Day | null
  promoter: number | null
  promotedSource: number
  inboundOrOutbound: number
  investmentRequest: number
  contactBackground: string | null
}>({ required: true })

useValidation({}, {}, { $scope: props.scope })
</script>

<template>
  <SGrid cols="2" gap="24">
    <SGridItem span="1">
      <OpportunitySourceInputDate
        v-model="model.date"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1" />
    <SGridItem span="1">
      <OpportunitySourceInputPromoter
        v-model="model.promoter"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <OpportunitySourceInputPromotedSourceAsync
        v-model="model.promotedSource"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <OpportunitySourceInputInboundOrOutboundAsync
        v-model="model.inboundOrOutbound"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <OpportunitySourceInputInvestmentRequestAsync
        v-model="model.investmentRequest"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="2">
      <OpportunitySourceInputContactBackground
        v-model="model.contactBackground"
        :scope="scope"
      />
    </SGridItem>
  </SGrid>
</template>
