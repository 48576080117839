<script setup lang="ts">
import IconCaretLeft from '~icons/ph/caret-left-bold'
import { omit } from 'lodash-es'
import SAlert from 'sefirot/components/SAlert.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import {
  type ProposableFundGroupFrag,
  ProposableFundGroupReason
} from '@/graphql'
import OpportunityProposalAddWizardSelectFundGroupTable from './OpportunityProposalAddWizardSelectFundGroupTable.vue'

const props = defineProps<{
  proposableFundGroups: ProposableFundGroupFrag[]
}>()

const emit = defineEmits<{
  back: []
  cancel: []
  selected: [pfgs: ProposableFundGroupFrag[]]
}>()

const { t } = useTrans({
  en: {
    a_back: 'Back',
    a_cancel: 'Cancel',
    a_select: 'Select funds'
  },
  ja: {
    a_back: '戻る',
    a_cancel: 'キャンセル',
    a_select: 'ファンドを選択'
  }
})

const records = computed(() => {
  return props.proposableFundGroups
    .filter((pfg) => {
      return (
        pfg.reason !== ProposableFundGroupReason.Invested
        && pfg.reason !== ProposableFundGroupReason.AlreadyOpen
      )
    })
    .map((pfg) => ({ ...pfg, id: pfg.fundGroup.id }))
})

const { data } = useData({
  fundGroupIds: [] as number[]
})

const { validation, validateAndNotify } = useValidation(data, {
  fundGroupIds: {
    required: required()
  }
})

async function onSelect() {
  if (await validateAndNotify()) {
    const selectedPfgs = records.value
      .filter((record) => data.value.fundGroupIds.includes(record.id))
      .map((pfg) => omit(pfg, 'id'))

    emit('selected', selectedPfgs)
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds not subject to conflict management regulations</h2>
            <p v-if="records.length">Select funds to open this opportunity to. Note that already opened funds are excluded from the list.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityを利益相反管理規定対象外ファンドへ開示する</h2>
            <p v-if="records.length">このOpportunityを開示したいファンドを選択してください。すでに開示されているファンドは除外されています。</p>
          </STrans>
        </SContent>
        <OpportunityProposalAddWizardSelectFundGroupTable
          v-if="records.length"
          :records="records"
          :data="data"
          :validation="validation"
          :disable-selection="() => false"
          @update:data="($event) => data.fundGroupIds = $event"
        />
        <SAlert v-else>
          <STrans lang="en">
            <p>Opportunity is already opened to all funds.</p>
          </STrans>
          <STrans lang="ja">
            <p>すでに全てのファンドへ公開されています。</p>
          </STrans>
        </SAlert>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlLeft>
          <SControlButton
            :icon="IconCaretLeft"
            :label="t.a_back"
            @click="$emit('back')"
          />
        </SControlLeft>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            v-if="records.length"
            mode="info"
            :label="t.a_select"
            @click="onSelect"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
