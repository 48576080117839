<script setup lang="ts">
import IconChatCircleText from '~icons/ph/chat-circle-text'
import IconClockCounterClockwise from '~icons/ph/clock-counter-clockwise'
import IconDownload from '~icons/ph/download-simple'
import IconPaperclip from '~icons/ph/paperclip'
import { useDropZone } from '@vueuse/core'
import { useTrans } from 'sefirot/composables/Lang'
import { computed, ref } from 'vue'
import { type Opportunity, type OpportunityFile, type OpportunityFileDownload, type OpportunityFileRequest } from '@/graph/Opportunity'
import { useDownloadOpportunityFile } from '@/composables/repos/OpportunityFileRepo'
import { useUpdateOpportunityFileFields } from '@/composables/repos/OpportunityRepo'
import IconAbort from '../icon/IconAbort.vue'
import OpportunitySideFilesContent from './OpportunitySideFilesContent.vue'
import OpportunitySideFilesEmpty from './OpportunitySideFilesEmpty.vue'

const props = defineProps<{
  opportunityId: number
  hasNoPlanToAddFiles: boolean
  fileComment: string | null
  files: OpportunityFile[]
  fileRequests: OpportunityFileRequest[]
  fileDownloads: OpportunityFileDownload[]
  canEdit: boolean
  canRequest: boolean
}>()

const emit = defineEmits<{
  'select-actions': []
  'select-file-request': []
  'upload-files': [files: File[]]
  'edit-file-comment': []
  'remove-file-comment': []
  'file-removed': [file: OpportunityFile]
  'aborted': [opportunity: Opportunity]
  'file-comment-updated': [opportunity: Opportunity]
  'show-download-history': []
}>()

const { t } = useTrans({
  en: {
    a_download: 'Download all pitch decks',
    a_upload: 'Upload pitch decks',
    a_abort: 'No scheduled receipt',
    a_abort_toggle: 'Undo no scheduled receipt',
    a_abort_file_exists: 'You must first delete all uploaded files before marking as no scheduled receipt.',
    a_comment_add: 'Add text',
    a_comment_edit: 'Edit text',
    a_download_history: 'Download history'
  },
  ja: {
    a_download: '全てのDeckをダウンロード',
    a_upload: 'Deckをアップロード',
    a_abort: '資料受領予定なし',
    a_abort_toggle: '資料受領予定なしを取り消し',
    a_abort_file_exists: '資料受領予定なしに設定するには、アップロードされたファイルをすべて削除する必要があります。',
    a_comment_add: 'テキストを追加',
    a_comment_edit: 'テキストを編集',
    a_download_history: 'ダウンロード履歴'
  }
})

const { execute: downloadOpportunityFile } = useDownloadOpportunityFile()
const updateOpportunityFileFields = useUpdateOpportunityFileFields()

const isDownloadingAll = ref(false)

const dropZoneEl = ref<HTMLDivElement | null>(null)

const { isOverDropZone } = useDropZone(dropZoneEl, {
  multiple: true,
  onDrop: (files) => {
    if (files != null && files.length > 0) {
      emit('upload-files', files)
    }
  }
})

const hasContent = computed(() => {
  return !!props.files.length || props.hasNoPlanToAddFiles || !!props.fileComment
})

const canSelectAbort = computed<[ok: boolean, tooltip: string]>(() => {
  if (props.files.length) {
    return [false, t.a_abort_file_exists]
  } else if (props.hasNoPlanToAddFiles) {
    return [true, t.a_abort_toggle]
  } else {
    return [true, t.a_abort]
  }
})

async function downloadAll() {
  isDownloadingAll.value = true
  await Promise.all(props.files.map((f) => downloadOpportunityFile(f.id)))
  isDownloadingAll.value = false
}

async function abort() {
  const oppo = await updateOpportunityFileFields.execute(props.opportunityId, {
    hasNoPlanToAddFiles: !props.hasNoPlanToAddFiles,
    fileComment: props.fileComment
  })
  emit('aborted', oppo)
}
</script>

<template>
  <SCard
    v-if="files"
    class="OpportunitySideFiles"
    :class="{ 'can-edit': props.canEdit, 'is-over-drop-zone': isOverDropZone }"
    :mode="canEdit && files.length && isOverDropZone ? 'info' : 'neutral'"
    ref="dropZoneEl"
  >
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">Pitch decks</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="files.length"
              :icon="IconDownload"
              :disabled="isDownloadingAll"
              :tooltip="t.a_download"
              @click="downloadAll"
            />
            <SControlActionBarButton
              v-if="canEdit"
              :icon="IconPaperclip"
              :tooltip="t.a_upload"
              @click="$emit('upload-files', [])"
            />
            <SControlActionBarButton
              v-if="canEdit"
              :icon="IconAbort"
              :tooltip="canSelectAbort[1]"
              :disabled="!canSelectAbort[0] || updateOpportunityFileFields.loading.value"
              @click="abort"
            />
            <SControlActionBarButton
              v-if="canEdit"
              :icon="IconChatCircleText"
              :tooltip="fileComment ? t.a_comment_edit : t.a_comment_add"
              @click="$emit('edit-file-comment')"
            />
            <SControlActionBarButton
              v-if="canEdit"
              :icon="IconClockCounterClockwise"
              :tooltip="t.a_download_history"
              @click="$emit('show-download-history')"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunitySideFilesContent
      v-if="hasContent"
      :opportunity-id
      :has-no-plan-to-add-files
      :file-comment
      :files
      :is-downloading-all
      :can-edit
      @edit-file-comment="$emit('edit-file-comment')"
      @remove-file-comment="$emit('remove-file-comment')"
      @file-removed="$emit('file-removed', $event)"
    />
    <OpportunitySideFilesEmpty
      v-else
      :can-edit
      :can-request
      :file-requests="fileRequests"
      @select="$emit('select-actions')"
      @request="$emit('select-file-request')"
    />
  </SCard>
</template>

<style scoped lang="postcss">
.OpportunitySideFiles.can-edit.is-over-drop-zone {
  border-color: var(--c-border-info-1);
  transition: border-color 0s;
}
</style>
