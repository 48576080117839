<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { type Company } from '@/models/Company'
import { type Opportunity } from '@/models/Opportunity'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import IconTelescope from '../icon/IconTelescope.vue'

const props = defineProps<{
  opportunities: Opportunity[]
  company: Company
}>()

const { canAddOpportunity } = useCompanyPolicy(() => props.company)

const actions = computedWhen(canAddOpportunity, () => {
  return [{ icon: 'add', link: props.company.createOpportunityPath }]
}, [])

const items = computed(() => {
  return props.opportunities.map((oppo) => ({
    icon: IconTelescope,
    title: oppo.title,
    link: oppo.path,
    tags: [
      { text: oppo.statusValue, color: oppo.isClosed() ? 'mute' : 'info' },
      { text: `Created at ${oppo.createdAt!.format('YYYY/MM/DD')}` }
    ]
  }))
})
</script>

<template>
  <DCard title="Opportunities" :actions="actions">
    <DCardList>
      <template v-if="items.length">
        <DCardListItem
          v-for="(item, index) in items"
          :key="index"
          :icon="item.icon"
          :title="item.title"
          :link="item.link"
          :tags="item.tags"
        />
      </template>

      <template v-else>
        <DCardListEmpty>No items found.</DCardListEmpty>
      </template>
    </DCardList>
  </DCard>
</template>
