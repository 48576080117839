<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import SModal from 'sefirot/components/SModal.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { type Company } from '@/models/Company'
import { useCompanyClaim } from '@/composables/company/CompanyData'

const { t } = useTrans({
  en: {
    title: 'You may assign the company to yourself',
    lead: 'The company has no assignees. You may assign the company to yourself to continue editing it. If you do so, you will be assigned as the primary in-charge of the company.',
    claim: 'Assign the company to yourself',
    modal_title: 'The company is assigned to you',
    modal_lead: 'You are now the primary in-charge of this company. You may continue editing the company data from here on.',
    modal_close: 'Close'
  },
  ja: {
    title: 'この企業の担当者になる',
    lead: 'この企業は現在担当者が登録されていません。自身を担当者として設定することで、企業情報を編集することが可能になります。自身を担当者として設定した場合、Primary in-chargeとして設定されます。',
    claim: 'この企業の担当者になる',
    modal_title: 'この会社の担当者になりました',
    modal_lead: 'この会社の主担当になりました。以後、会社情報を編集できます。',
    modal_close: '閉じる'
  }
})

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  updated: []
  close: []
}>()

const { execute, loading } = useCompanyClaim(props.company.id!)

const { state, on, off } = usePower()

async function claim() {
  await execute()

  on()

  emit('updated')
}
</script>

<template>
  <SCard mode="info" class="CompanyClaimCard">
    <SCardBlock>
      <div class="text">
        <p class="title">{{ t.title }}</p>
        <p class="lead">{{ t.lead }}</p>
      </div>
      <SButton
        class="button"
        mode="info"
        :label="t.claim"
        :loading="loading"
        @click="claim"
      />
    </SCardBlock>

    <SModal :open="state">
      <SCard size="small">
        <SCardBlock class="s-p-24">
          <SContent>
            <h2>{{ t.modal_title }}</h2>
            <p>{{ t.modal_lead }}</p>
          </SContent>/
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-24">
          <SControl>
            <SControlRight>
              <SControlButton
                :label="t.modal_close"
                @click="off(() => emit('close'))"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </SModal>
  </SCard>
</template>

<style scoped>
.SCardBlock {
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 16px 24px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
}

.title {
  max-width: 640px;
  font-size: 16px;
  font-weight: 600;
}

.lead {
  max-width: 640px;
  font-size: 14px;
  font-weight: 400;
}

.button {
  width: 244px;
  flex: 1;
}
</style>
