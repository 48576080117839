<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

const { t } = useTrans({
  en: {
    title: 'Reports'
  },
  ja: {
    title: '報告書'
  }
})
</script>

<template>
  <DView
    class="Reports"
    :title="t.title"
  />
</template>
