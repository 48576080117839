<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()

const canUpdateOpportunity = useCanUpdateOpportunityPolicy(() => props.opportunity)
</script>

<template>
  <SCardBlock class="s-p-24">
    <div v-if="!canUpdateOpportunity.ok" class="body">
      <div class="text mute">
        No survey is linked to this opportunity.
      </div>
    </div>
    <div v-else class="body">
      <div class="text">
        No survey is linked to this opportunity. The survey must be linked if you are planning to hide this opportunity to some funds, with some exception. <SLink class="link" href="https://docs.google.com/document/d/1qZcl-IQFtcqTca2f0B1d-F-PrT94uP4hnsDBiZJ1Lmg/">Learn more</SLink>.
      </div>
      <div class="action">
        <SButton
          size="small"
          mode="info"
          label="Link survey"
          @click="emit('click')"
        />
      </div>
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  align-items: center;
  gap: 48px;
}

.text {
  flex-grow: 1;
  max-width: 640px;
  font-size: 14px;
}

.text.mute {
  color: var(--c-text-3);
}

.link {
  color: var(--c-text-info-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-2);
  }
}
</style>
