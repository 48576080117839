import { useLang } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import { type FundPortfolioStats } from '@/graph/FundPortfolioStats'
import { abbreviateByMOrOku } from '@/support/Num'
import { defineOps } from './Ops'

export const useFundPortfolioStatsOps = defineOps(() => {
  const lang = useLang()

  function investedAmountAbbr(stats: FundPortfolioStats, fraction: number = 2): string {
    return abbreviateByMOrOku(Number(stats.investedAmount), fraction, lang)
  }

  function initialInvestedDate(stats: FundPortfolioStats): Day {
    return day(stats.initialInvestedDate)
  }

  return {
    investedAmountAbbr,
    initialInvestedDate
  }
})
