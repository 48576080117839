import {
  type BasicPaginationInput,
  type StatUserActivitiesCondition,
  StatUserActivitiesDocument,
  type StatUserActivitiesOrder
} from '@/graphql'
import { Request } from './Request'

export class StatUserActivitiesRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: StatUserActivitiesCondition,
    orderBy: StatUserActivitiesOrder
  ) {
    return this.request({
      query: StatUserActivitiesDocument,
      variables: { page, condition, orderBy }
    })
  }
}
