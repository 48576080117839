<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength } from 'sefirot/validation/rules'
import { type CompanyPortfolioDataWithFinancialFiguresFrag } from '@/graphql'
import { useCompanyFinancialFiguresOps } from '@/composables/ops/CompanyOps'
import { useCanUpdateCompanyPortfolioData } from '@/composables/policies/CompanyPolicy'
import { useUpdateCompanyFinancialFigures } from '@/composables/repos/CompanyPortfolioDataRepo'

const props = defineProps<{
  portfolioData: CompanyPortfolioDataWithFinancialFiguresFrag
}>()

const emit = defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    title: 'Notes',
    updated_at: (date?: Day | null) => date ? `Updated at ${date.format('YYYY-MM-DD HH:mm')}` : '',
    save: 'Update notes'
  },
  ja: {
    title: 'メモ',
    updated_at: (date?: Day | null) => date ? `更新日時: ${date.format('YYYY-MM-DD HH:mm')}` : '',
    save: 'メモを更新'
  }
})

const canUpdate = useCanUpdateCompanyPortfolioData(props.portfolioData)

const financialFiguresOps = useCompanyFinancialFiguresOps()

const { data } = useData({
  notes: props.portfolioData.financialFigures?.notes ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  notes: {
    maxLength: maxLength(4000)
  }
})

const { execute, loading } = useUpdateCompanyFinancialFigures()

async function update() {
  if (await validateAndNotify()) {
    await execute(props.portfolioData.financialFigures.companyId, {
      notes: data.value.notes
    })
    emit('updated')
  }
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-16">
      <div class="header">
        <span class="title">{{ t.title }}</span>
        <span class="updated-at">{{ t.updated_at(financialFiguresOps.notesUpdatedAt(props.portfolioData.financialFigures)) }}</span>
      </div>
    </SCardBlock>
    <SCardBlock class="body">
      <div class="input">
        <SInputTextarea
          v-model="data.notes"
          :rows="5"
          :disabled="!canUpdate.ok"
          :validation="validation.notes"
        />
      </div>
    </SCardBlock>
    <SCardBlock size="medium" class="s-px-16">
      <SControl>
        <SControlLeft>
          <SControlButton
            mode="info"
            :label="t.save"
            :loading="loading"
            :disabled="!canUpdate.ok"
            @click="update"
          />
        </SControlLeft>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.title {
  font-size: 14px;
  font-weight: 500;
}

.updated-at {
  display: inline-block;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--c-text-2);
}

.body {
  padding: 16px;
}

.input {
  max-width: 640px;
}
</style>
