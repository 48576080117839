import { OpportunityPromotedSourcesDocument } from '@/graphql'
import { Request } from './Request'

export class OpportunityPromotedSourceRequest extends Request {
  fetchAll() {
    return this.request({
      query: OpportunityPromotedSourcesDocument
    })
  }
}
