<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type Company } from '@/models/Company'

defineProps<{
  company: Company
}>()

const { t } = useTrans({
  en: {
    title: 'System information',
    l_created_by: 'Created by',
    l_created_at: 'Created at',
    l_updated_by: 'Updated by',
    l_updated_at: 'Updated at'
  },
  ja: {
    title: 'システム情報',
    l_created_by: '作成者',
    l_created_at: '作成日時',
    l_updated_by: '更新者',
    l_updated_at: '更新日時'
  }
})
</script>

<template>
  <SCard class="CompanyDescSystem">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">{{ t.title }}</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel :value="t.l_created_by" />
          <DDescUser :user="company.createdBy" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel :value="t.l_created_at" />
          <SDescDay :value="company.createdAt" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel :value="t.l_updated_by" />
          <DDescUser :user="company.updatedBy" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel :value="t.l_updated_at" />
          <SDescDay :value="company.updatedAt" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
      </SDesc>
    </SCardBlock>
  </SCard>
</template>
