<script setup lang="ts">
import { computedArray } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import {
  type DealEventAndBallotFrag,
  type DealEventFrag,
  type DealEventTemplateAndBallotFrag,
  type DealEventTemplateFrag,
  type DealWithIdFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealEventOps } from '@/composables/ops/DealEventOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealCardEventListDescVote from './DealCardEventListDescVote.vue'

const props = defineProps<{
  deal: DealWithIdFrag & WithPermissionFrag
  template: DealEventTemplateFrag | DealEventTemplateAndBallotFrag
  event: DealEventFrag | DealEventAndBallotFrag
}>()

const emit = defineEmits<{
  (e: 'edit'): void
  (e: 'delete'): void
}>()

const { canUpdate, canEditEventWithBallot } = useDealPolicy(props.deal)

const dealEventOps = useDealEventOps()

const canEdit = computed(() => {
  return props.template.event.hasBallot
    ? canEditEventWithBallot.value
    : canUpdate.value
})

const title = computed(() => {
  return `${statusForTitle.value} on ${dealEventOps.dateText(props.event)}`
})

const statusForTitle = computed(() => {
  if (dealEventOps.isSkipped(props.event)) {
    return 'Skipped'
  } else if (dealEventOps.isApproved(props.event)) {
    return 'Approved'
  } else if (dealEventOps.isConditionallyApproved(props.event)) {
    return 'Conditionally approved'
  } else if (dealEventOps.isRejected(props.event)) {
    return 'Rejected'
  } else {
    return 'Executed'
  }
})

const statusPill = computed(() => {
  if (!props.event) {
    return { mode: 'mute', label: 'Todo' }
  } else if (dealEventOps.isSkipped(props.event)) {
    return { mode: 'mute', label: 'Skipped' }
  } else if (dealEventOps.isApproved(props.event)) {
    return { mode: 'success', label: 'Approved' }
  } else if (dealEventOps.isConditionallyApproved(props.event)) {
    return { mode: 'success', label: 'Cond. Approved' }
  } else if (dealEventOps.isRejected(props.event)) {
    return { mode: 'rejected', label: 'Rejected' }
  } else {
    return { mode: 'success', label: 'Executed' }
  }
})

const actions = computedArray((arr) => {
  if (canEdit.value) {
    arr.push(
      { icon: 'edit', onClick: () => emit('edit') },
      { icon: 'delete', onClick: () => emit('delete') }
    )
  }
})
</script>

<template>
  <DCard :title="title" :actions="actions">
    <DCardBody>
      <div class="container">
        <DGrid>
          <DGridItem span="1">
            <DGridLabel text="Result" />
            <DGridPills :items="[statusPill]" />
          </DGridItem>
          <DGridItem span="1">
            <DGridLabel text="Date" />
            <DGridText :text="dealEventOps.dateText(event)" />
          </DGridItem>
          <DGridItem span="2" />
          <DGridItem v-if="dealEventOps.isSkipped(props.event)" span="4">
            <DGridLabel text="Skipped reason" />
            <DGridText :text="event.skipReason" />
          </DGridItem>
        </DGrid>

        <div v-if="(event as DealEventAndBallotFrag).ballot" class="votes">
          <div v-for="vote in (event as any).ballot.votes" :key="vote.voterId" class="vote">
            <DealCardEventListDescVote
              :template="template as DealEventTemplateAndBallotFrag"
              :vote="vote"
            />
          </div>
        </div>
      </div>
    </DCardBody>
  </DCard>
</template>

<style scoped lang="postcss">
.container {
  display: grid;
  gap: 24px;
}

.votes {
  display: grid;
  gap: 12px;
}

.vote {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 24px;
}
</style>
