import { type MaybeRef } from '@vueuse/core'
import { type ComputedRef, type Ref, computed, unref } from 'vue'
import { type User } from '@/models/User'
import { useMe } from './Auth'

export interface Policy {
  define(fn: (user: User) => AuthorizedRaw): Authorized
  defineWhen<T>(
    resource?: Resource<T>,
    fn?: (user: User, resource: T) => AuthorizedRaw
  ): Authorized
}

export type PolicyResponse<Code extends string = 'undefined'> = Ref<PolicyResponseRaw<Code>>

export interface PolicyResponseRaw<Code extends string = 'undefined'> {
  ok: boolean | null
  code: PolicyResponseCode<Code>
  message?: string
  is(code: PolicyResponseCode<Code>): boolean
}

export type PolicyResponseCode<T extends string> = 'undefined' | T

export type Authorized = ComputedRef<AuthorizedRaw>
export type AuthorizedRaw = boolean | null

export type Resource<T> = MaybeRef<T | null | undefined> | (() => T | null | undefined)

export function usePolicy(): Policy {
  const { user } = useMe()

  function define(fn: (user: User) => AuthorizedRaw): Authorized {
    return computed(() => fn(user))
  }

  function defineWhen<T>(
    resource?: Resource<T>,
    fn: (user: User, resource: T) => AuthorizedRaw = () => false
  ): Authorized {
    const r = computed(() => {
      return typeof resource === 'function' ? (resource as any)() : unref(resource)
    })

    return define((u) => {
      if (r.value === undefined) {
        return null
      }

      if (r.value === null) {
        return false
      }

      return fn(u, r.value)
    })
  }

  return {
    define,
    defineWhen
  }
}

export function usePolicyResponse<Code extends string>(
  fn: (user: User) => PolicyResponseRaw<Code>
): PolicyResponse<Code> {
  const { user } = useMe()

  return computed(() => fn(user))
}

export function pending(): PolicyResponseRaw<'undefined'> {
  return response(null)
}

export function allow<Code extends string>(
  code?: Code,
  message?: string
): PolicyResponseRaw<Code> {
  return response(true, code, message)
}

export function deny<Code extends string>(
  code?: Code,
  message?: string
): PolicyResponseRaw<Code> {
  return response(false, code, message)
}

export function response<Code extends string>(
  ok: boolean | null,
  code: Code = 'undefined' as Code,
  message?: string
): PolicyResponseRaw<Code> {
  function is(c: Code): boolean {
    return code === c
  }

  return {
    ok,
    code,
    message,
    is
  }
}
