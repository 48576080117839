export function toNumberOrNull(value?: string | number | null): number | null {
  if (value == null) {
    return null
  }

  if (typeof value === 'number') {
    return value
  }

  // Remove commas from the string and convert it to a number so that users can
  // paste in numbers like "1,000,000".
  return Number(value.replace(/,/g, ''))
}

export function format(value: number): string {
  return value.toLocaleString('en-US', { maximumFractionDigits: 20 })
}

/**
 * Convert given number to abbreviated format such as `1K` or `1M`.
 */
export function abbreviate(num: number, fraction: number = 1, lang: 'en' | 'ja' = 'en'): string {
  return lang === 'ja' ? abbreviateJa(num, fraction) : abbreviateEn(num, fraction)
}

export function abbreviateByMOrOku(num: number, fraction: number = 1, lang: 'en' | 'ja' = 'en'): string {
  return lang === 'ja' ? abbreviateJaByOku(num, fraction) : abbreviateEnByM(num, fraction)
}

export function abbreviateEn(num: number, fraction: number = 1): string {
  const K = 1e3
  const M = 1e6
  const B = 1e9

  if (num >= K && num < M) {
    return `${(num / K).toFixed(fraction)}K`
  }

  if (num >= M && num < B) {
    return `${(num / M).toFixed(fraction)}M`
  }

  if (num >= B) {
    return `${(num / B).toFixed(fraction)}B`
  }

  return String(num)
}

export function abbreviateEnByM(num: number, fraction: number = 1): string {
  const M = 1e6
  return `${(num / M).toFixed(fraction)}M`
}

export function abbreviateJa(num: number, fraction: number = 1): string {
  const MAN = 1e4 // 万 (10,000)
  const OKU = 1e8 // 億 (100,000,000)
  const CHOU = 1e12 // 兆 (1,000,000,000,000)

  if (num >= CHOU) {
    return `${(num / CHOU).toFixed(fraction)}兆`
  }

  if (num >= OKU) {
    return `${(num / OKU).toFixed(fraction)}億`
  }

  if (num >= MAN) {
    return `${(num / MAN).toFixed(fraction)}万`
  }

  return String(num)
}

export function abbreviateJaByOku(num: number, fraction: number = 1): string {
  const OKU = 1e8 // 億 (100,000,000)
  return `${(num / OKU).toFixed(fraction)}億`
}
