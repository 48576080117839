<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useUpdateOportunitySurvey } from '@/composables/repos/OportunitySurveyRepo'

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    unlink: 'Unlink survey'
  },
  ja: {
    cancel: 'キャンセル',
    unlink: '紐づけ解除'
  }
})

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { loading, execute } = useUpdateOportunitySurvey()

async function unlink() {
  await execute(props.opportunity.id, null)
  emit('updated')
}
</script>

<template>
  <SCard class="OpportunityFormUnlinkSurvey" mode="danger" size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Unlink this survey</h2>
            <p>You are about to unlink survey. This action cannot be undone. Are you absolutely sure you want to unlink the survey?</p>
          </STrans>
          <STrans lang="ja">
            <h2>Surveyの紐づけを解除する</h2>
            <p>Surveyの紐づけを解除します。この操作は取り消すことができません。本当によろしいですか？</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loading"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.unlink"
            :loading="loading"
            @click="unlink"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
