import { type Day, day } from 'sefirot/support/Day'
import { format } from 'sefirot/support/Num'
import { isString } from 'sefirot/support/Utils'
import {
  type DealSecurityFrag,
  type MoneyFrag,
  type MoneyWithoutIdFrag,
  type RoundWithCurrencyFrag,
  SecurityKind
} from '@/graphql'
import { toDayOrNull, toTimestampText } from '@/support/Day'
import { type Entries, type ValueOf } from '@/support/Types'
import { useMoneyOps } from './MoneyOps'
import { defineOps } from './Ops'
import { useRoundOps } from './RoundOps'

export const DealSecurityKindTextDict = {
  [SecurityKind.CommonStock]: 'Common Stock',
  [SecurityKind.ClassifiedStock]: 'Preferred Stock',
  [SecurityKind.Safe]: 'SAFE',
  [SecurityKind.JKiss]: 'J-KISS',
  [SecurityKind.Warrant]: 'Warrant',
  [SecurityKind.ConvertibleBond]: 'Convertible Bond',
  [SecurityKind.ConvertibleNote]: 'Convertible Note',
  [SecurityKind.ConvertibleLoan]: 'Convertible Loan'
} as const

export function kindTextEntries(): Entries<typeof DealSecurityKindTextDict> {
  return Object.entries(DealSecurityKindTextDict)
}

export const useDealSecurityOps = defineOps(({ locale }) => {
  const roundOps = useRoundOps()
  const moneyOps = useMoneyOps()

  function kindText(security: DealSecurityFrag | SecurityKind): ValueOf<typeof DealSecurityKindTextDict> | 'TBD' {
    return isString(security)
      ? DealSecurityKindTextDict[security]
      : security.kind ? DealSecurityKindTextDict[security.kind] : 'TBD'
  }

  function plannedTotalPriceWithRoundCurrency(
    round: RoundWithCurrencyFrag,
    dealSecurity: DealSecurityFrag
  ): MoneyFrag | null {
    return dealSecurity.plannedTotalPrice
      ? moneyOps.create(roundOps.currency(round), dealSecurity.plannedTotalPrice)
      : null
  }

  function purchasedQuantity(
    dealSecurity: DealSecurityFrag
  ): string | null {
    return dealSecurity.purchasedQuantity
      ? format(dealSecurity.purchasedQuantity)
      : null
  }

  function purchasedTotalPriceWithRoundCurrency(
    round: RoundWithCurrencyFrag,
    dealSecurity: DealSecurityFrag
  ): MoneyFrag | null {
    return dealSecurity.purchasedTotalPrice
      ? moneyOps.create(roundOps.currency(round), dealSecurity.purchasedTotalPrice)
      : null
  }

  function purchasedTotalPriceFmc(
    dealSecurity: DealSecurityFrag
  ): MoneyWithoutIdFrag | null {
    return dealSecurity.purchasedTotalPriceFmc
      ? moneyOps.createJpy(dealSecurity.purchasedTotalPriceFmc)
      : null
  }

  function repaymentDate(security: DealSecurityFrag): Day | null {
    return toDayOrNull(security.repaymentDate)
  }

  function valuationCapWithRoundCurrency(
    round: RoundWithCurrencyFrag,
    dealSecurity: DealSecurityFrag
  ): MoneyFrag | null {
    return dealSecurity.valuationCap
      ? moneyOps.create(roundOps.currency(round), dealSecurity.valuationCap)
      : null
  }

  function eligibleFinancingAmountWithRoundCurrency(
    round: RoundWithCurrencyFrag,
    dealSecurity: DealSecurityFrag
  ): MoneyFrag | null {
    return dealSecurity.eligibleFinancingAmount
      ? moneyOps.create(roundOps.currency(round), dealSecurity.eligibleFinancingAmount)
      : null
  }

  function createdAt(deal: DealSecurityFrag): Day {
    return day(deal.createdAt)
  }

  function createdAtText(deal: DealSecurityFrag): string {
    return toTimestampText(deal.createdAt, locale)
  }

  function updatedAt(deal: DealSecurityFrag): Day {
    return day(deal.updatedAt)
  }

  function updatedAtText(deal: DealSecurityFrag): string {
    return toTimestampText(deal.createdAt, locale)
  }

  return {
    kindTextEntries,
    kindText,
    plannedTotalPriceWithRoundCurrency,
    purchasedQuantity,
    purchasedTotalPriceWithRoundCurrency,
    purchasedTotalPriceFmc,
    repaymentDate,
    valuationCapWithRoundCurrency,
    eligibleFinancingAmountWithRoundCurrency,
    createdAt,
    createdAtText,
    updatedAt,
    updatedAtText
  }
})
