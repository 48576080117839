import { type DealEventWithIdFrag } from '@/graphql'
import { DealEventRequest, type UpdateDealEventInput } from '@/requests/DealEventRequest'
import { type Mutation, useMutation } from '../Api'

export type UpdateDealEvent = Mutation<DealEventWithIdFrag, [number, UpdateDealEventInput]>
export type DeleteDealEvent = Mutation<void, [number]>

export function useUpdateDealEvent(): UpdateDealEvent {
  return useMutation<
    DealEventWithIdFrag,
    [number, UpdateDealEventInput]
  >(async (id, input) => {
    return (await new DealEventRequest().update(id, input)).data.dealEvent
  })
}

export function useDeleteDealEvent(): DeleteDealEvent {
  return useMutation<void, [number]>(async (id) => {
    await new DealEventRequest().delete(id)
  })
}
