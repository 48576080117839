<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useSurveyItem } from '@/composables/repos/SurveyRepo'
import SurveyCard from '@/components/survey/SurveyCard.vue'

const route = useRoute()

const { data: survey } = useSurveyItem(() => Number(route.params.id))

const surveyOps = useSurveyOps()

const title = computed(() => {
  return survey.value ? `Surveys - ${surveyOps.displayId(survey.value)}` : undefined
})
</script>

<template>
  <DPage :title="title" class="SurveysIdIndex">
    <div v-if="survey" class="s-mx-auto s-max-w-xl">
      <SurveyCard :survey="survey" />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.SurveysIdIndex {
  padding: 32px 32px 128px;
}
</style>
