<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { type DealWithIdFrag, type DealWithRightsFrag, type WithPermissionFrag } from '@/graphql'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DToggleCheck from '../DToggleCheck.vue'
import DealFormUpdateRights from './DealFormUpdateRights.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithRightsFrag & WithPermissionFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)
const { state: modal, on, off } = usePower(false)

const actions = computedArrayWhen(canUpdate, (actions) => {
  actions.push({ icon: 'edit', onClick: on })
})
</script>

<template>
  <DCard title="Rights" :actions="actions">
    <div class="container">
      <div class="grid">
        <DToggleCheck text="Information right" :model-value="deal.informationRight" />
        <DToggleCheck text="Board observe right" :model-value="deal.boardObserverRight" />
        <DToggleCheck text="Right to appoint director" :model-value="deal.rightToAppointDirector" />
        <DToggleCheck text="Right for first offer" :model-value="deal.rightForFirstOffer" />
        <DToggleCheck text="Pre-emptive right" :model-value="deal.preemptiveRight" />
        <DToggleCheck text="Keyman clause" :model-value="deal.keymanClause" />
        <DToggleCheck text="Co-sale right" :model-value="deal.cosaleRight" />
        <DToggleCheck text="Drag along right" :model-value="deal.dragAlongRight" />
      </div>

      <div class="other" :class="{ empty: !deal.otherRights }">
        <div class="other-box">
          <p class="other-label">Other rights</p>
          <p class="other-body">{{ deal.otherRights ?? '—' }}</p>
        </div>
      </div>
    </div>

    <SModal :open="modal">
      <DealFormUpdateRights
        :deal="deal"
        @cancel="off"
        @updated="off(() => $emit('updated'))"
      />
    </SModal>
  </DCard>
</template>

<style scoped lang="postcss">
.container {
  padding: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - (12px * 2)) / 3));
  gap: 12px;
}

.other {
  padding-top: 12px;
}

.other-box {
  padding: 8px 12px;
  border: 1px solid var(--c-success);
  border-radius: 6px;

  .other.empty & {
    border-color: var(--c-divider);
  }
}

.other-label {
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.other-body {
  font-size: 14px;
  font-weight: 500;

  .other.empty & { color: var(--c-text-3); }
}
</style>
