<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type FundStats } from '@/graph/FundStats'
import { useFundStatsOps } from '@/composables/ops/FundStatsOps'
import StatBlock from '../stat/StatBlock.vue'
import StatBlockDl from '../stat/StatBlockDl.vue'
import StatBlockHeader from '../stat/StatBlockHeader.vue'

const props = defineProps<{
  stats: FundStats
}>()

const { t } = useTrans({
  en: {
    title: 'Deals',
    invested_label: 'Invested',
    in_progress_label: 'In progress',
    dropped_label: 'Dropped'
  },
  ja: {
    title: 'ディール',
    invested_label: '投資済み',
    in_progress_label: '進行中',
    dropped_label: 'ドロップ済み'
  }
})

const statsOps = useFundStatsOps()

const items = computed(() => {
  const s = props.stats
  return [
    {
      label: t.invested_label,
      value: s.dealsInvestedCount,
      link: statsOps.investedDealsPageLink(s),
      mode: s.dealsInvestedCount === 0 ? 'mute' : 'default'
    } as const,
    {
      label: t.in_progress_label,
      value: s.dealsInProgressCount,
      link: statsOps.inProgressDealsPageLink(s),
      mode: s.dealsInProgressCount === 0 ? 'mute' : 'default'
    } as const
  ]
})
</script>

<template>
  <StatBlock>
    <StatBlockHeader :title="t.title" />
    <StatBlockDl :items />
  </StatBlock>
</template>
