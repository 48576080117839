import { type Dayjs } from 'dayjs'
import { orderBy } from 'lodash-es'
import { type Ref, watch } from 'vue'
import { StatInvestedDeal } from '@/models/StatInvestedDeal'
import StatInvestedDealsRequest from '@/requests/StatInvestedDealsRequest'
import { type UseQueryOptions, useQuery } from '../Api'

export function useItem(
  from: Ref<Dayjs>,
  to: Ref<Dayjs>,
  options?: UseQueryOptions
) {
  const query = useQuery(async () => {
    const res = await new StatInvestedDealsRequest().fetch(from.value.toISOString(), to.value.toISOString())

    return orderBy(res.data.statInvestedDeals.map((s) => new StatInvestedDeal(s)), ['investedDate'], ['desc'])
  }, options)

  watch([from, to], query.execute)

  return query
}
