<script setup lang="ts">
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { format, toNumberOrNull } from '@/support/Num'
import { useState } from '@/composables/State'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import CompanyPortfolioDataFormUpdateIssuedShares from './CompanyPortfolioDataFormUpdateIssuedShares.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { canUpdate } = useCompanyPortfolioDataPolicy(props.portfolioData)

const { data: modal, set, clear } = useState<'update'>()

const actions = computedArrayWhen(canUpdate, (items) => {
  items.push({ icon: 'edit', onClick: () => set('update') })
})
</script>

<template>
  <DCard title="Outstanding shares" :actions="actions">
    <div class="shares">
      <div v-for="share in portfolioData.issuedShares" :key="share.name" class="share">
        <div class="share-name">{{ share.name }}</div>
        <div class="share-count">{{ format(toNumberOrNull(share.count) ?? 0) }}</div>
      </div>
      <div class="share summary">
        <div class="share-name">Total</div>
        <div class="share-count">{{ format(toNumberOrNull(portfolioData.totalIssuedShares) ?? 0) }}</div>
      </div>
      <div v-if="portfolioData.issuedSharesNotes" class="note">
        <div class="note-label">Notes</div>
        <div class="note-text">{{ portfolioData.issuedSharesNotes }}</div>
      </div>
    </div>

    <DModal size="medium" :open="modal === 'update'">
      <CompanyPortfolioDataFormUpdateIssuedShares
        :portfolio-data="portfolioData"
        @updated="clear(() => emit('updated'))"
        @cancel="clear(() => emit('cancel'))"
      />
    </DModal>
  </DCard>
</template>

<style scoped lang="postcss">
.shares {
  border-radius: 6px;
  overflow: hidden;
}

.share {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 24px;
  line-height: 40px;
  font-size: 14px;

  & + & {
    border-top: 1px solid var(--c-divider-2);
  }

  &.summary {
    font-weight: 600;
    background-color: var(--c-soft);
  }

  &.summary .share-name {
    color: var(--c-text-2);
  }
}

.share-count {
  font-feature-settings: "tnum";
}

.note {
  border-top: 1px solid var(--c-divider-2);
  padding: 16px 24px;
  background-color: var(--c-soft);
}

.note-label {
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.note-text {
  font-size: 14px;
}
</style>
