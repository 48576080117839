<script setup lang="ts">
import { groupBy } from 'lodash-es'
import SPill from 'sefirot/components/SPill.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type ProposableFundGroupFrag } from '@/graphql'

const props = defineProps<{
  proposableFundGroups: ProposableFundGroupFrag[]
}>()

defineEmits<{
  cancel: []
  selected: [ProposableFundGroupFrag[]]
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel'
  },
  ja: {
    a_cancel: 'キャンセル'
  }
})

const pfgsByType = computed(() => {
  return groupBy(props.proposableFundGroups, (pfg) => {
    if (pfg.fundGroup.status === 'Active' && pfg.fundGroup.isSurveyTarget) {
      return 'surveyTarget'
    } else if (pfg.fundGroup.status === 'ActiveOnlyFollowOn' && pfg.fundGroup.isSurveyTarget) {
      return 'activeOnlyFollowOn'
    } else {
      return 'excluded'
    }
  })
})
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds</h2>
            <p>Select fund type you want to open this opportunity. Each group has slightly different rules when making proposals.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityをファンドへ開示する</h2>
            <p>開示したいファンドの種別を選択してください。ファンドの種別によって、開示までに必要なルールが異なります。</p>
          </STrans>
        </SContent>
        <SCard>
          <SCardBlock>
            <button class="item" @click="$emit('selected', pfgsByType.surveyTarget)">
              <STrans lang="en">
                <div class="title">Survey target funds</div>
                <div class="text">Require survey from the startup if planning to hide from any funds.</div>
              </STrans>
              <STrans lang="ja">
                <div class="title">Survey対象ファンド</div>
                <div class="text">非開示にしたいファンドがある場合に、Surveyの取得が必要となるファンドです。</div>
              </STrans>
              <div class="pills">
                <SPill
                  v-for="pfg in pfgsByType.surveyTarget"
                  :key="pfg.fundGroup.id"
                  :label="pfg.fundGroup.name"
                  size="mini"
                />
              </div>
            </button>
          </SCardBlock>
          <SCardBlock>
            <button class="item" @click="$emit('selected', pfgsByType.activeOnlyFollowOn)">
              <STrans lang="en">
                <div class="title">Funds excluded from new investments</div>
                <div class="text">Funds that have completed new investments or were established for follow-on investments.</div>
              </STrans>
              <STrans lang="ja">
                <div class="title">新規投資対象外ファンド</div>
                <div class="text">新規投資が終了した、またはフォロー投資のために創設されたファンドです。</div>
              </STrans>
              <div class="pills">
                <SPill
                  v-for="pfg in pfgsByType.activeOnlyFollowOn"
                  :key="pfg.fundGroup.id"
                  :label="pfg.fundGroup.name"
                  size="mini"
                />
              </div>
            </button>
          </SCardBlock>
          <SCardBlock>
            <button class="item" @click="$emit('selected', pfgsByType.excluded)">
              <STrans lang="en">
                <div class="title">Funds not subject to conflict management regulations</div>
                <div class="text">Funds that do not require survey from the startup.</div>
              </STrans>
              <STrans lang="ja">
                <div class="title">利益相反管理規定対象外ファンド</div>
                <div class="text">開示条件にSurveyの有無が影響しないファンドです。</div>
              </STrans>
              <div class="pills">
                <SPill
                  v-for="pfg in pfgsByType.excluded"
                  :key="pfg.fundGroup.id"
                  :label="pfg.fundGroup.name"
                  size="mini"
                />
              </div>
            </button>
          </SCardBlock>
        </SCard>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            @click="$emit('cancel')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.item {
  display: block;
  padding: 16px;
  width: 100%;
  text-align: left;

  &:hover .title {
    color: var(--c-text-info-2);
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--c-text-info-1);
  transition: color 0.25s;
}

.text {
  font-size: 14px;
  color: var(--c-text-2);
}

.pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 12px;
}
</style>
