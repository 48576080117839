<script setup lang="ts">
import { Links } from '@/constants/Links'
import { type View } from '@/models/View'
import { useViewExport } from '@/composables/view/ViewData'

const props = defineProps<{
  view?: View | null
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'exported'): void
}>()

const { execute, loading } = useViewExport(props.view!.id!)

async function download() {
  await execute()
  emit('exported')
}
</script>

<template>
  <DForm>
    <DFormTitle>Export the view as an Excel file</DFormTitle>

    <DFormMedium>
      <p>Once you initiate the export, the Excel file will be sent to you via Slack when it's ready.</p>
      <p>When you export the view, you must wait for an hour to export again. The interval for the next export is applied to whole members who see this view.</p>
      <p>It means if you export this view now, everybody else who has access to this view also has to wait for an hour to export the view.</p>
      <p>You may <SLink class="link" :href="Links.ViewExport">learn more at GB Base Manual</SLink>.</p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Export" :loading="loading" @click="download" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>

<style scoped lang="postcss">
.link {
  font-weight: 500;
  color: var(--c-info);
  transition: color .25s;

  &:hover {
    color: var(--c-info-dark);
  }
}
</style>
