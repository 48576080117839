<script setup lang="ts">
import IconClock from '~icons/ph/clock'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useItem } from '@/composables/repos/FundClosingCheckListRecordRepo'

const route = useRoute()

const { data: fundClosingCheckListRecord } = useItem(Number(route.params.id))
const fundClosingCheckList = computed(() => fundClosingCheckListRecord.value?.fundClosingCheckList)

const tags = computed(() => {
  const updatedAt = fundClosingCheckListRecord.value?.updatedAt?.format('YYYY/MM/DD HH:mm') ?? ''
  const updatedBy = fundClosingCheckListRecord.value?.updatedBy?.name ?? ''

  return [
    { icon: IconClock, label: `Updated at ${updatedAt} by ${updatedBy}` }
  ]
})

const title = computed(() => [
  { text: 'ファンド決算チェックリスト', link: '/fund-closing-check-lists' },
  { text: fundClosingCheckList.value?.date?.format('YYYYMMDD'), link: fundClosingCheckList.value?.path() },
  { text: fundClosingCheckListRecord.value?.company.name ?? '' }
])
</script>

<template>
  <DView
    class="FundClosingCheckListRecordsId"
    :tags="tags"
    :title="title"
  />
</template>
