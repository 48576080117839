<script setup lang="ts">
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { computed, watchEffect } from 'vue'
import {
  type AntiDilutionProvisionFrag,
  type CurrencyWithoutIdFrag,
  type LiquidationPreferencePatternFrag,
  SecurityKind
} from '@/graphql'
import DInputMoney from '../DInputMoney.vue'

export interface Data {
  kind: SecurityKind | null
  name: string | null
  plannedTotalPrice: string | null
  liquidationPreferenceMultiple: number | null
  liquidationPreferencePatternId: number | null
  antiDilutionProvisionId: number | null
  annualInterest: number | null
  repaymentDate: Day | null
  repaymentRight: boolean | null
  discount: number | null
  valuationCap: string | null
  eligibleFinancingAmount: string | null
}

const props = defineProps<{
  index: number
  currency: CurrencyWithoutIdFrag
  security: Data
  liquidationPreferencePatterns: LiquidationPreferencePatternFrag[]
  antiDilutionProvisions: AntiDilutionProvisionFrag[]
  removable: boolean
}>()

const emit = defineEmits<{
  (e: 'update', value: Data): void
  (e: 'remove'): void
}>()

const isPreferredStock = computed(() => {
  return props.security.kind === SecurityKind.ClassifiedStock
})

const isSecurity = computed(() => {
  return (
    props.security.kind !== null
    && props.security.kind !== SecurityKind.CommonStock
    && props.security.kind !== SecurityKind.ClassifiedStock
  )
})

const { validation } = useValidation(
  () => props.security,
  () => createDealSecurityRules()
)

const securityOptions = [
  { label: 'Common Stock', value: SecurityKind.CommonStock },
  { label: 'Preferred Stock', value: SecurityKind.ClassifiedStock },
  { label: 'SAFE', value: SecurityKind.Safe },
  { label: 'J-KISS', value: SecurityKind.JKiss },
  { label: 'Warrant', value: SecurityKind.Warrant },
  { label: 'Convertible Bond', value: SecurityKind.ConvertibleBond },
  { label: 'Convertible Note', value: SecurityKind.ConvertibleNote },
  { label: 'Convertible Load', value: SecurityKind.ConvertibleLoan }
]

const liquidationPreferencePatternOptions = computed(() => {
  return props.liquidationPreferencePatterns.map((pattern) => ({
    label: pattern.name,
    value: pattern.id,
    disabled: !pattern.enabled
  }))
})

const antiDilutionProvisionOptions = computed(() => {
  return props.antiDilutionProvisions.map((provision) => ({
    label: provision.name,
    value: provision.id,
    disabled: !provision.enabled
  }))
})

const repaymentRightOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

function createDealSecurityRules() {
  return {
    ...createDealSecurityRulesBase(),
    ...(isPreferredStock.value ? createDealSecurityRulesForPreferredStock() : {}),
    ...(isSecurity.value ? createDealSecurityRulesForSecurity() : {})
  }
}

function createDealSecurityRulesBase() {
  return {
    kind: {
      required: required()
    },
    name: {
      required: required(),
      maxLength: maxLength(255)
    },
    plannedTotalPrice: {
      required: required(),
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
  }
}

function createDealSecurityRulesForPreferredStock() {
  return {
    liquidationPreferenceMultiple: {
      required: required(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    },
    liquidationPreferencePatternId: {
      required: required()
    },
    antiDilutionProvisionId: {
      required: required()
    }
  }
}

function createDealSecurityRulesForSecurity() {
  return {
    annualInterest: {
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    },
    discount: {
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    },
    valuationCap: {
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    },
    eligibleFinancingAmount: {
      decimal: decimal(),
      maxValue: maxValue(Number.MAX_SAFE_INTEGER)
    }
  }
}

function update<K extends keyof Data>(key: K, value: Data[K]) {
  emit('update', { ...props.security, [key]: value })
}

watchEffect(() => {
  validation.value.$validate()
})
</script>

<template>
  <div class="DealFormCreateSecuritiesItem">
    <div class="header">
      <div class="header-title">
        Security #{{ index }}
      </div>
      <div v-if="removable" class="actions">
        <SButton
          type="text"
          size="mini"
          mode="danger"
          :icon="IconTrash"
          label="Remove"
          @click="$emit('remove')"
        />
      </div>
    </div>

    <div class="form">
      <div class="input span-2">
        <SInputSelect
          label="Security kind *"
          placeholder="Select security kind"
          :options="securityOptions"
          :value="security.kind"
          :validation="validation.kind"
          @change="v => update('kind', v as SecurityKind)"
        />
      </div>
      <div class="input span-4">
        <SInputText
          label="Security name *"
          info="Please enter the name of the securities as stated in the contract."
          placeholder="Enter the actual security name"
          :model-value="security.name"
          :validation="validation.name"
          @update:model-value="v => update('name', v)"
        />
      </div>
      <div class="input span-6">
        <DInputMoney
          label="Total amount *"
          placeholder="10,000,000"
          info="The currency is linked to the currency defined in the round details."
          :currency="currency"
          :model-value="security.plannedTotalPrice"
          :validation="validation.plannedTotalPrice"
          @update:model-value="v => update('plannedTotalPrice', v)"
        />
      </div>

      <template v-if="isPreferredStock">
        <div class="input span-3">
          <SInputNumber
            label="Liquidation preference (multiple) *"
            placeholder="2"
            :model-value="security.liquidationPreferenceMultiple"
            :validation="validation.liquidationPreferenceMultiple"
            @input="v => update('liquidationPreferenceMultiple', v)"
          />
        </div>
        <div class="input span-3">
          <SInputSelect
            label="Liquidation preference (pattern) *"
            placeholder="Select pattern"
            :options="liquidationPreferencePatternOptions"
            :value="security.liquidationPreferencePatternId"
            :validation="validation.liquidationPreferencePatternId"
            @change="v => update('liquidationPreferencePatternId', v as number)"
          />
        </div>
        <div class="input span-3">
          <SInputSelect
            label="Anti-dilution *"
            placeholder="Select provision"
            :options="antiDilutionProvisionOptions"
            :value="security.antiDilutionProvisionId"
            :validation="validation.antiDilutionProvisionId"
            @change="v => update('antiDilutionProvisionId', v as number)"
          />
        </div>
      </template>

      <template v-if="isSecurity">
        <div class="input span-2">
          <SInputNumber
            label="Annual interest"
            placeholder="0.45"
            unit-after="%"
            :value="security.annualInterest"
            @input="v => update('annualInterest', v)"
          />
        </div>
        <div class="input span-2">
          <SInputSelect
            label="Repayment right"
            placeholder="N/A"
            nullable
            :options="repaymentRightOptions"
            :value="security.repaymentRight"
            @change="v => update('repaymentRight', v as boolean)"
          />
        </div>
        <div class="input span-2">
          <SInputDate
            label="Repayment date"
            block
            :model-value="security.repaymentDate"
            @update:model-value="v => update('repaymentDate', v)"
          />
        </div>
        <div class="input span-2">
          <SInputNumber
            label="Discount"
            placeholder="10"
            unit-after="%"
            :value="security.discount"
            :validation="validation.discount"
            @input="v => update('discount', v)"
          />
        </div>
        <div class="input span-4" />
        <div class="input span-3">
          <DInputMoney
            label="Valuation cap"
            placeholder="10,000,000"
            :currency="currency"
            :model-value="security.valuationCap"
            :validation="validation.valuationCap"
            @update:model-value="v => update('valuationCap', v)"
          />
        </div>
        <div class="input span-3">
          <DInputMoney
            label="Eligible financing amount"
            placeholder="10,000,000"
            :currency="currency"
            :model-value="security.eligibleFinancingAmount "
            :validation="validation.eligibleFinancingAmount"
            @update:model-value="v => update('eligibleFinancingAmount', v)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealFormCreateSecuritiesItem {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.header-title {
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}

.actions {
  margin-right: -8px;
}

.form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.input.span-2 { grid-column: span 2; }
.input.span-3 { grid-column: span 3; }
.input.span-4 { grid-column: span 4; }
.input.span-6 { grid-column: span 6; }
</style>
