<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, requiredIf } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type CompanyPortfolioDataExitStatus, type CompanyPortfolioData as CompanyPortfolioDataGraph } from '@/graph/CompanyPortfolioData'
import { type CompanyPortfolioData, CompanyPortfolioDataExitStatuses } from '@/models/CompanyPortfolioData'
import { useUpdateCompanyPortfolioDataExitStatus } from '@/composables/repos/CompanyPortfolioDataRepo'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  cancel: []
  updated: [companyPortfolioData: CompanyPortfolioDataGraph]
}>()

const { t } = useTrans({
  en: {
    i_exist_status_label: 'Exit status *',
    i_exist_status_ph: 'Select exit status',
    i_is_active_portfolio_label: 'Is active portfolio *',
    i_is_active_portfolio_ph: 'Select status',
    i_exit_date_label: 'Exit date *',
    i_lead_underwriter_label: 'Lead underwriter *',
    i_lead_underwriter_ph: 'e.g. Example Securities *',
    i_listing_market_label: 'Listing market *',
    i_listing_market_ph: 'e.g. NYSE, NASDAQ',
    i_details_label: 'Details *',
    i_details_ph: 'Fill in details about the exit...',
    a_cancel: 'Cancel',
    a_update: 'Update exit status'
  },
  ja: {
    i_exist_status_label: 'Exit Status *',
    i_exist_status_ph: 'Exit Statusを選択してください',
    i_is_active_portfolio_label: 'Exit後のステータス *',
    i_is_active_portfolio_ph: 'ステータスを選択してください',
    i_exit_date_label: 'Exit Date *',
    i_lead_underwriter_label: 'Lead Underwriter *',
    i_lead_underwriter_ph: 'e.g. XX証券',
    i_listing_market_label: 'Listing Market *',
    i_listing_market_ph: 'e.g. 東証一部、東証グロース市場',
    a_cancel: 'キャンセル',
    a_update: 'Exit Statusを更新'
  }
})

const updateMutation = useUpdateCompanyPortfolioDataExitStatus()

const { data } = useData({
  exitStatus: props.portfolioData.exitStatus ?? '__active__',
  exitDate: props.portfolioData.exitDate,
  exitDetails: props.portfolioData.exitDetails,
  ipoLeadUnderwriter: props.portfolioData.ipoLeadUnderwriter,
  ipoListingMarket: props.portfolioData.ipoListingMarket,
  isActivePortfolio: props.portfolioData.isActivePortfolio
})

const { validation, validateAndNotify } = useValidation(data, {
  exitDate: {
    required: requiredIf(() => data.value.exitStatus !== '__active__')
  },
  exitDetails: {
    required: requiredIf(() => data.value.exitStatus !== '__active__'),
    maxLength: maxLength(4000)
  },
  ipoLeadUnderwriter: {
    required: requiredIf(() => data.value.exitStatus === 'Ipo'),
    maxLength: maxLength(255)
  },
  ipoListingMarket: {
    required: requiredIf(() => data.value.exitStatus === 'Ipo'),
    maxLength: maxLength(255)
  },
  isActivePortfolio: {
    required: requiredIf(() => data.value.exitStatus !== '__active__' && data.value.exitStatus !== 'Liquidation')
  }
})

const exitStatusOptions = computed(() => {
  const options = Object.entries(CompanyPortfolioDataExitStatuses).map((status) => ({
    label: status[1] as string,
    value: status[0]
  }))
  options.unshift({ label: 'Active', value: '__active__' })
  return options
})

const isActivePortfolioOptions = computed(() => [
  { label: 'Active portfolio', value: true },
  { label: 'Ex-portfolio', value: false }
])

const isActive = computed(() => data.value.exitStatus === '__active__')
const isIpo = computed(() => data.value.exitStatus === 'Ipo')

async function update() {
  if (await validateAndNotify()) {
    const cpd = await updateMutation.execute(props.portfolioData.companyId!, {
      exitStatus: createExitStatusInput(),
      exitDate: isActive.value ? null : data.value.exitDate!.format('YYYY-MM-DD'),
      exitDetails: isActive.value ? null : data.value.exitDetails,
      ipoLeadUnderwriter: isIpo.value ? data.value.ipoLeadUnderwriter : null,
      ipoListingMarket: isIpo.value ? data.value.ipoListingMarket : null,
      isActivePortfolio: createIsActivePortfolioInput()
    })
    emit('updated', cpd)
  }
}

function createExitStatusInput() {
  return data.value.exitStatus === '__active__' ? null : data.value.exitStatus as CompanyPortfolioDataExitStatus
}

function createIsActivePortfolioInput() {
  if (data.value.exitStatus === '__active__') {
    return true
  }

  return data.value.exitStatus === 'Liquidation' ? false : data.value.isActivePortfolio!
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update exit status</h2>
          </STrans>
          <STrans lang="ja">
            <h2>Exit Statusを更新する</h2>
          </STrans>
        </SContent>
        <SGrid cols="6" gap="24">
          <SGridItem span="2">
            <SInputSelect
              :label="t.i_exist_status_label"
              :placeholder="t.i_exist_status_ph"
              :options="exitStatusOptions"
              v-model="data.exitStatus"
            />
          </SGridItem>
          <SGridItem v-if="data.exitStatus !== '__active__' && data.exitStatus !== 'Liquidation'" span="2">
            <SInputSelect
              :label="t.i_is_active_portfolio_label"
              :placeholder="t.i_is_active_portfolio_ph"
              v-model="data.isActivePortfolio"
              :options="isActivePortfolioOptions"
              :validation="validation.isActivePortfolio"
            />
          </SGridItem>
          <SGridItem v-if="data.exitStatus !== '__active__'" span="2">
            <SInputDate
              :label="t.i_exit_date_label"
              block
              v-model="data.exitDate"
              :validation="validation.exitDate"
            />
          </SGridItem>
          <template v-if="isIpo">
            <SGridItem span="3">
              <SInputText
                :label="t.i_lead_underwriter_label"
                :placeholder="t.i_lead_underwriter_ph"
                v-model="data.ipoLeadUnderwriter"
                :validation="validation.ipoLeadUnderwriter"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputText
                :label="t.i_listing_market_label"
                :placeholder="t.i_listing_market_ph"
                v-model="data.ipoListingMarket"
                :validation="validation.ipoListingMarket"
              />
            </SGridItem>
          </template>
          <SGridItem v-if="data.exitStatus !== '__active__'" span="6">
            <SInputTextarea
              :label="t.i_details_label"
              :placeholder="t.i_details_ph"
              :rows="3"
              v-model="data.exitDetails"
              :validation="validation.exitDetails"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>

    <SCardBlock class="s-px-32" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="updateMutation.loading.value"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            :loading="updateMutation.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
