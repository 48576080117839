<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { useUserActivityDealOpenedData } from '@/composables/user/UserData'
import UsersProfileCard from './UsersProfileCard.vue'
import { type UserProfilePerformanceOptions } from './UsersProfilePerformanceCatalog.vue'

const props = defineProps<{
  user: UserFrag
  options: UserProfilePerformanceOptions
}>()

const { data: deals, loading } = useUserActivityDealOpenedData(props.user.id, props.options.condition)

const table = useTable({
  orders: [
    'company',
    'round',
    'fund',
    'assignedAs',
    'investmentAmount',
    'openedAt'
  ],
  columns: {
    company: {
      label: 'Company',
      cell: (_, record) => ({
        type: 'text',
        link: record.path
      })
    },
    round: {
      label: 'Round',
      cell: {
        type: 'text'
      }
    },
    fund: {
      label: 'Fund',
      cell: {
        type: 'text'
      }
    },
    assignedAs: {
      label: 'Assigned as',
      cell: {
        type: 'text'
      }
    },
    investmentAmount: {
      label: 'Investment amount',
      cell: {
        type: 'text'
      }
    },
    openedAt: {
      label: 'Opened at',
      cell: {
        type: 'text'
      }
    }
  },
  records: computed(() => deals.value?.map((d) => ({
    id: d.id,
    path: `/companies/${d.round?.company?.id}`,
    company: d.round?.company?.name,
    round: d.round?.name?.name,
    fund: d.fund?.nameAbbreviated,
    assignedAs: d.assignedAs,
    investmentAmount: d.investmentAmount?.format(),
    openedAt: d.openedDate?.format('YYYY/MM/DD')
  }))),
  borderless: true,
  loading
})
</script>

<template>
  <UsersProfileCard class="UsersProfilePerformanceDealOpenTable" title="Open deals">
    <STable class="list" :options="table" />
  </UsersProfileCard>
</template>

<style scoped lang="postcss">
.list :deep(.col-company)          { --table-col-width: 240px; }
.list :deep(.col-round)            { --table-col-width: 140px; }
.list :deep(.col-fund)             { --table-col-width: 100px; }
.list :deep(.col-assignedAs)       { --table-col-width: 160px; }
.list :deep(.col-investmentAmount) { --table-col-width: 160px; }
.list :deep(.col-openedAt)         { --table-col-width: 108px; }
</style>
