<script setup lang="ts">
import SInputDropdown, { type ArrayValue, type Option, type PrimitiveValue, type Size } from 'sefirot/components/SInputDropdown.vue'
import { type Validatable } from 'sefirot/composables/Validation'

defineProps<{
  size?: Size
  label?: string
  note?: string
  help?: string
  placeholder?: string
  nullable?: boolean
  closeOnClick?: boolean
  disabled?: boolean
  modelValue: PrimitiveValue | ArrayValue
  validation?: Validatable
}>()

defineEmits<{
  (e: 'update:modelValue', value: PrimitiveValue | ArrayValue): void
}>()

const options = [
  { type: 'text', label: 'January', value: 1 },
  { type: 'text', label: 'February', value: 2 },
  { type: 'text', label: 'March', value: 3 },
  { type: 'text', label: 'April', value: 4 },
  { type: 'text', label: 'May', value: 5 },
  { type: 'text', label: 'June', value: 6 },
  { type: 'text', label: 'July', value: 7 },
  { type: 'text', label: 'August', value: 8 },
  { type: 'text', label: 'September', value: 9 },
  { type: 'text', label: 'October', value: 10 },
  { type: 'text', label: 'November', value: 11 },
  { type: 'text', label: 'December', value: 12 }
] as Option[]
</script>

<template>
  <SInputDropdown
    class="SInputDropdown"
    :size="size"
    :label="label"
    :note="note"
    :help="help"
    :placeholder="placeholder"
    :close-on-click="closeOnClick"
    :disabled="disabled"
    :no-search="true"
    :options="options"
    :nullable="nullable"
    :model-value="modelValue"
    :validation="validation"
    @update:model-value="(v) => $emit('update:modelValue', v)"
  />
</template>
