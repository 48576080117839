<script setup lang="ts">
import IconCaretRight from '~icons/ph/caret-right-bold'
import SButton from 'sefirot/components/SButton.vue'
import SIndicator, { type State } from 'sefirot/components/SIndicator.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type CompanyBusinessReportFrag, FundReportRecordStatus } from '@/graphql'
import {
  useCanCompleteCompanyBusinessReport,
  useCanIncompleteCompanyBusinessReport,
  useCanRequestChangesOnCompanyBusinessReport,
  useCanRequestReviewOnCompanyBusinessReport,
  useCanSaveCompanyBusinessReport,
  useCanStartReviewOnCompanyBusinessReport,
  useCanWithdrawReviewOnCompanyBusinessReport
} from '@/composables/policies/CompanyBusinessReportPolicy'
import { type ReportOperationState } from '@/components/company-business-report/CompanyBusinessReportFormUpdate.vue'

const props = defineProps<{
  companyBusinessReport: CompanyBusinessReportFrag
  operationState: ReportOperationState
}>()

const emit = defineEmits<{
  'save': []
  'request-review': []
  'start-review': []
  'withdraw-review': []
  'request-changes': []
  'complete': []
  'incomplete': []
}>()

const { t } = useTrans({
  en: {
    step_draft: 'Draft report',
    step_start_review: 'Start reivew',
    step_complete_review: 'Complete review',
    save_report: 'Save report',
    request_review: 'Request review',
    start_review: 'Start review',
    withdraw_review: 'Withdraw review',
    request_changes: 'Request changes',
    complete: 'Complete report',
    incomplete: 'Incomplete report'
  },
  ja: {
    step_draft: '下書き',
    step_start_review: 'レビュー開始',
    step_complete_review: 'レビュー完了',
    save_report: '保存する',
    request_review: 'レビュー依頼',
    start_review: 'レビュー開始',
    withdraw_review: 'レビュー取り下げ',
    request_changes: '変更依頼',
    complete: '完了する',
    incomplete: '完了取り下げ'
  }
})

const canSaveCompanyBusinessReport = useCanSaveCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canRequestReviewOnCompanyBusinessReport = useCanRequestReviewOnCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canStartReviewOnCompanyBusinessReport = useCanStartReviewOnCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canWithdrawReviewOnCompanyBusinessReport = useCanWithdrawReviewOnCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canRequestChangesOnCompanyBusinessReport = useCanRequestChangesOnCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canCompleteCompanyBusinessReport = useCanCompleteCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)
const canIncompleteCompanyBusinessReport = useCanIncompleteCompanyBusinessReport(computed(() => props.companyBusinessReport), props.companyBusinessReport.report.fund)

const canTakeActions = computed(() => {
  return (
    canSaveCompanyBusinessReport.value.ok
    || canRequestReviewOnCompanyBusinessReport.value.ok
    || canStartReviewOnCompanyBusinessReport.value.ok
    || canWithdrawReviewOnCompanyBusinessReport.value.ok
    || canRequestChangesOnCompanyBusinessReport.value.ok
    || canCompleteCompanyBusinessReport.value.ok
    || canIncompleteCompanyBusinessReport.value.ok
  )
})

const steps = computed<{ label: string; state: State }[]>(() => {
  switch (props.companyBusinessReport.status) {
    case FundReportRecordStatus.ReviewRequested: {
      return [
        { label: t.step_draft, state: 'completed' },
        { label: t.step_start_review, state: 'ready' },
        { label: t.step_complete_review, state: 'pending' }
      ]
    }
    case FundReportRecordStatus.InReview: {
      return [
        { label: t.step_draft, state: 'completed' },
        { label: t.step_start_review, state: 'completed' },
        { label: t.step_complete_review, state: 'ready' }
      ]
    }
    case FundReportRecordStatus.Completed: {
      return [
        { label: t.step_draft, state: 'completed' },
        { label: t.step_start_review, state: 'completed' },
        { label: t.step_complete_review, state: 'completed' }
      ]
    }
    // FundReportRecordStatus.Draft
    // FundReportRecordStatus.ChangeRequested
    default: {
      return [
        { label: t.step_draft, state: 'ready' },
        { label: t.step_start_review, state: 'pending' },
        { label: t.step_complete_review, state: 'pending' }
      ]
    }
  }
})
</script>

<template>
  <SCardBlock v-if="canTakeActions" class="CompanyBusinessReportStatusControl">
    <SControl>
      <SControlLeft>
        <div class="steps">
          <div v-for="(step, index) in steps" :key="step.label" class="step" :class="step.state">
            <div class="step-indicator">
              <IconCaretRight v-if="index > 0" />
              <SIndicator size="nano" :state="step.state" />
            </div>
            <p class="step-label">{{ step.label }}</p>
          </div>
        </div>
      </SControlLeft>
      <SControlRight>
        <SButton
          v-if="canSaveCompanyBusinessReport.ok"
          size="small"
          :label="t.save_report"
          :loading="operationState.save.loading"
          :disabled="operationState.save.disabled"
          @click="emit('save')"
        />
        <SButton
          v-if="canRequestReviewOnCompanyBusinessReport.ok"
          size="small"
          mode="info"
          :label="t.request_review"
          :loading="operationState.requestReview.loading"
          :disabled="operationState.requestReview.disabled"
          @click="emit('request-review')"
        />
        <SButton
          v-if="canWithdrawReviewOnCompanyBusinessReport.ok"
          size="small"
          mode="danger"
          :label="t.withdraw_review"
          :loading="operationState.withdrawReview.loading"
          :disabled="operationState.withdrawReview.disabled"
          @click="emit('withdraw-review')"
        />
        <SButton
          v-if="canStartReviewOnCompanyBusinessReport.ok"
          size="small"
          mode="info"
          :label="t.start_review"
          :loading="operationState.startReview.loading"
          :disabled="operationState.startReview.disabled"
          @click="emit('start-review')"
        />
        <SButton
          v-if="canRequestChangesOnCompanyBusinessReport.ok"
          size="small"
          mode="danger"
          :label="t.request_changes"
          :loading="operationState.requestChanges.loading"
          :disabled="operationState.requestChanges.disabled"
          @click="emit('request-changes')"
        />
        <SButton
          v-if="canCompleteCompanyBusinessReport.ok"
          size="small"
          mode="success"
          :label="t.complete"
          :loading="operationState.complete.loading"
          :disabled="operationState.complete.disabled"
          @click="emit('complete')"
        />
        <SButton
          v-if="canIncompleteCompanyBusinessReport.ok"
          size="small"
          mode="mute"
          :label="t.incomplete"
          :loading="operationState.incomplete.loading"
          :disabled="operationState.incomplete.disabled"
          @click="emit('incomplete')"
        />
      </SControlRight>
    </SControl>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportStatusControl {
  position: sticky;
  top: var(--header-height, auto);
  z-index: 100;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--c-gutter);
  padding: 0 24px;
  height: 64px;
}

.steps {
  display: flex;
  gap: 12px;
  align-items: center;
}

.step {
  display: flex;
  gap: 8px;
  align-items: center;

  &.pending {
    --caret-color: var(--c-fg-gray-1);
    --label-color: var(--c-text-2);
  }

  &.ready {
    --caret-color: var(--c-fg-info-1);
    --label-color: var(--c-text-1);
  }

  &.completed {
    --caret-color: var(--c-fg-success-1);
    --label-color: var(--c-text-1);
  }
}

.step-indicator {
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--caret-color);
}

.step-label {
  font-size: 14px;
  color: var(--label-color);
}
</style>
