<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type SurveyFrag } from '@/graphql'
import { SurveyAlreadyLinkedError } from '@/requests/errors/SurveyAlreadyLinkedError'
import { type SurveyMissingOpenProposalsError } from '@/requests/errors/SurveyMissingOpenProposalsError'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'

type ExpectedError = SurveyAlreadyLinkedError | SurveyMissingOpenProposalsError

const props = defineProps<{
  error: ExpectedError
  survey: SurveyFrag
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})

const oppoOps = useOpportunityOps()

const link = computed(() => ({
  label: props.survey.opportunity?.company ? oppoOps.titleWithCompany(props.survey.opportunity) : '',
  href: props.survey.opportunity?.id ? oppoOps.path(props.survey.opportunity) : ''
}))
</script>

<template>
  <SCard class="OpportunityFormLinkSurveyError" mode="danger" size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent v-if="error instanceof SurveyAlreadyLinkedError">
          <STrans lang="en">
            <h2>Cannot link this survey</h2>
            <p>You cannot link this survey because it is already linked to <SLink :href="link.href" external>{{ link.label }}</SLink>.</p>
            <p>If you think the survey is linked incrretly, contact the opportunity PIC and ask for the survey to be unlinked. If that is not possible, contact the system administrator.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Surveyを紐づけできません</h2>
            <p>選択したSurveyはすでに「<SLink :href="link.href" external>{{ link.label }}</SLink>」に紐付いられているため選択できません。</p>
            <p>もし既存の紐づけが間違っている場合は、Opportunityの主担当者に紐づけを解除してもらってください。それができない場合は、システム管理者に問い合わせてください。</p>
          </STrans>
        </SContent>

        <SContent v-else>
          <STrans lang="en">
            <h2>Cannot link this survey</h2>
            <p>The selected survey does not contain already opened funds, or funds previously invested in the company.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Surveyを紐づけできません</h2>
            <p>選択したSurveyに、すでに開示されているファンド、または過去に出資済みのファンドが含まれていません。</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.close"
            @click="emit('close')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
