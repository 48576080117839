<script setup lang="ts">
import SInputDropdown, { type Option as DropdownOption } from 'sefirot/components/SInputDropdown.vue'
import SInputSelect, { type Option as SelectOption } from 'sefirot/components/SInputSelect.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { FundOperatorRole } from '@/graphql'

defineProps<{
  fundOptions: DropdownOption[]
  operatorRoleOptions: SelectOption[]
}>()

const emit = defineEmits<{
  'add': [fundId: number, role: FundOperatorRole]
  'cancel': []
}>()

const { t } = useTrans({
  en: {
    i_fund_label: 'Fund *',
    i_fund_placeholder: 'Select fund',
    i_operator_role_label: 'Role',
    cancel: 'Cancel',
    add: 'Add fund assignment'
  },
  ja: {
    i_fund_label: 'ファンド *',
    i_fund_placeholder: 'ファンドを選択してください。',
    i_operator_role_label: 'ロール',
    cancel: 'キャンセル',
    add: '担当ファンドを追加'
  }
})

const { data } = useData({
  fundId: null as number | null,
  role: FundOperatorRole.Administrator
})

const { validation, validateAndNotify } = useValidation(data, {
  fundId: {
    required: required()
  }
})

async function add() {
  if (await validateAndNotify()) {
    emit('add', data.value.fundId!, data.value.role)
  }
}
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Add a new assignment fund</STrans>
            <STrans lang="ja">担当ファンドを追加する</STrans>
          </h2>
        </SContent>
        <SInputDropdown
          :label="t.i_fund_label"
          :options="fundOptions"
          :placeholder="t.i_fund_placeholder"
          v-model="data.fundId"
          :validation="validation.fundId"
        />
        <SInputSelect
          :label="t.i_operator_role_label"
          :options="operatorRoleOptions"
          v-model="data.role"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.add"
            @click="add"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
