<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type User } from '@/graph/User'
import { useUserOps } from '@/composables/ops/UserOps'
import { useUpdateLatestCompanyLegalAssignees } from '@/composables/repos/CompanyRepo'
import { useActiveUserListCache } from '@/composables/repos/UserRepo'

const props = defineProps<{
  companyId: number
  assignees: User[]
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const { t } = useTrans({
  en: {
    legal_assignees_label: 'Legal assignees',
    legal_assignees_ph: 'Select legal assignees',
    cancel: 'Cancel',
    update: 'Update legal assignees'
  },
  ja: {
    legal_assignees_label: '法務担当者',
    legal_assignees_ph: '法務担当者を選択',
    cancel: 'キャンセル',
    update: '法務担当者を更新'
  }
})

const { data: users } = useActiveUserListCache()

const mutation = useUpdateLatestCompanyLegalAssignees()

const userOps = useUserOps()

const { data } = useData({
  legalAssignees: props.assignees.map((user) => user.id)
})

const legalUserOptions = computed<Option[]>(() => {
  return users.value?.filter((user) => {
    return userOps.hasRole(user, 'Legal')
  }).map((user) => ({
    type: 'avatar',
    image: userOps.avatarPath(user),
    label: userOps.name(user),
    value: user.id!
  })) ?? []
})

async function update() {
  await mutation.execute(props.companyId, {
    legalAssigneeIds: data.value.legalAssignees
  })
  emit('updated')
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update legal assignees</h2>
            <p>If this is a portfolio company, the legal assignee will be invited to the existing Google Drive portfolio folder.</p>
          </STrans>
          <STrans lang="ja">
            <h2>法務担当者を更新する</h2>
            <p>この企業が既存先の場合、法務担当者はGoogle Driveの既存先フォルダに招待されます。</p>
          </STrans>
        </SContent>

        <SGrid gap="24" cols="6">
          <SGridItem span="6">
            <SInputDropdown
              :label="t.legal_assignees_label"
              :placeholder="t.legal_assignees_ph"
              nullable
              :options="legalUserOptions"
              v-model="data.legalAssignees"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.update"
            :loading="mutation.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
