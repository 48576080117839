<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type Day } from '@/models/Model'
import { FundReportType, useFundReportCreate } from '@/composables/fund-report/FundReportData'
import { useMyOngoingFundList } from '@/composables/repos/FundRepo'

defineEmits<{
  (e: 'cancel'): void
}>()

const { t } = useTrans({
  en: {
    title: 'Create fund report',
    i_fund_label: 'Fund',
    i_fund_ph: 'Select fund',
    i_date_label: 'Report date',
    cancel: 'Cancel',
    create: 'Create report'
  },
  ja: {
    title: '業務報告書を作成する',
    i_fund_label: 'ファンド',
    i_fund_ph: 'ファンドを選択',
    i_date_label: '報告日',
    cancel: 'キャンセル',
    create: '業務報告書を作成'
  }
})

const router = useRouter()

const { data: funds } = useMyOngoingFundList()

const { data } = useData({
  fundId: null as number | null,
  date: null as Day | null
})

const { validation, validateAndNotify } = useValidation(data, {
  fundId: { required: required() },
  date: { required: required() }
})

const fundOptions = computed(() => {
  return funds.value?.map((fund) => {
    return { label: fund.nameAbbreviated!, value: fund.id! }
  }) ?? []
})

const { execute, loading } = useFundReportCreate(() => data.value.fundId!, () => ({
  date: data.value.date!.format('YYYY-MM-DD'),
  type: FundReportType.CompanyBusinessReport
}))

async function create() {
  if (await validateAndNotify()) {
    const result = await execute()

    result && router.push(result.path)
  }
}
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <h2>{{ t.title }}</h2>
        </SContent>
        <SInputSelect
          :label="t.i_fund_label"
          :placeholder="t.i_fund_ph"
          :options="fundOptions"
          v-model="data.fundId"
          :validation="validation.fundId"
        />
        <SInputDate
          :label="t.i_date_label"
          placeholder="YYYY-MM-DD"
          block
          v-model="data.date"
          :validation="validation.date"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.cancel" @click="$emit('cancel')" />
          <SControlButton mode="info" :label="t.create" :loading="loading" @click="create" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
