import { until, useDark } from '@vueuse/core'
import { type Component, type Ref, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth as useAuthStore } from '@/stores/Auth'
import LayoutBlank from '@/components/layout/LayoutBlank.vue'
import LayoutMain from '@/components/layout/LayoutMain.vue'
import { useAuth } from './Auth'

export interface App {
  status: Ref<'pending' | 'ready' | 'error'>
  layout: Component
}

export function useApp(): App {
  const route = useRoute()
  const { ready } = useAuth()
  const readyPromise = until(ready).toBe(true)
  const authStore = useAuthStore()
  const isDark = useDark()

  const status = ref<'pending' | 'ready' | 'error'>('pending')

  const layout = computed(() => {
    if (!authStore.user) {
      return LayoutBlank
    }

    return route.path.startsWith('/login') ? LayoutBlank : LayoutMain
  })

  init()

  async function init(): Promise<void> {
    await readyPromise
    status.value = 'ready'
    if (!authStore.user) {
      return
    }
    isDark.value = authStore.user.settings.theme === 'DARK'
  }

  return {
    status,
    layout
  }
}
