import { type Day, Model } from './Model'
import { Money } from './Money'

export interface StatInvestedDealInput {
  id: number
  name: string
  investedDate: string
  investmentAmount: object
  sector: string
}

export class StatInvestedDeal extends Model {
  id: number
  name: string
  investedDate: Day
  investmentAmount: Money
  sector: string

  constructor(input: StatInvestedDealInput) {
    super()
    this.id = input.id
    this.name = input.name
    this.investedDate = this.day(input.investedDate)
    this.investmentAmount = this.hasOne(Money, input.investmentAmount)
    this.sector = input.sector
  }

  path() {
    return `/deals/${this.id}`
  }
}
