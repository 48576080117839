<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { usePower } from 'sefirot/composables/Power'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import CompanyPortfolioDataDescEmergencyContactsEmpty from './CompanyPortfolioDataDescEmergencyContactsEmpty.vue'
import CompanyPortfolioDataDescEmergencyContactsTable from './CompanyPortfolioDataDescEmergencyContactsTable.vue'
import CompanyPortfolioDataFormUpdateEmergencyContacts from './CompanyPortfolioDataFormUpdateEmergencyContacts.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

defineEmits<{
  updated: []
}>()

const { canUpdate } = useCompanyPortfolioDataPolicy(() => props.portfolioData)

const editDialog = usePower()
</script>

<template>
  <SCard class="CompanyPortfolioDataDescEmergencyContacts">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">Emergency contacts</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate"
              :icon="IconNotePencil"
              @click="editDialog.on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock>
      <CompanyPortfolioDataDescEmergencyContactsTable
        v-if="props.portfolioData.emergencyContacts.length"
        :portfolio-data="portfolioData"
      />
      <CompanyPortfolioDataDescEmergencyContactsEmpty
        v-else
        :show-add-button="!!canUpdate"
        @add="editDialog.on"
      />
    </SCardBlock>

    <SModal :open="editDialog.state.value" @close="editDialog.off">
      <CompanyPortfolioDataFormUpdateEmergencyContacts
        :portfolio-data="portfolioData"
        @cancel="editDialog.off"
        @updated="editDialog.off(() => $emit('updated'))"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataDescEmergencyContacts > * {
  min-width: 0;
}
</style>
