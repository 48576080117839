import { type State as DescState } from 'sefirot/components/SDescState.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import {
  type ExtractedOpportunity,
  type Opportunity,
  type OpportunityFile,
  type OpportunityFileDownload,
  type OpportunityFileRequest,
  type OpportunityIdFrag,
  type OpportunityInitialReviewMeeting,
  type OpportunityStatusFrag
} from '@/graph/Opportunity'
import {
  type OpportunityConflictManagementCommitteeFrag,
  OpportunityConflictManagementCommitteeMeetingFormat,
  OpportunityConflictManagementCommitteeResult,
  type OpportunityForDetailsFrag,
  OpportunityInitialEvaluation,
  type OpportunityInitialReviewMeetingFrag,
  OpportunityInitialReviewMeetingResult,
  type OpportunityRoundNamableFrag,
  type OpportunitySourceFrag,
  OpportunityStatus,
  type OpportunityTitleableFrag,
  type OpportunityWithCompanyFrag,
  type OpportunityWithExpiresAtFrag,
  type OpportunityWithIdFrag,
  type OpportunityWithStatusFrag,
  type OpportunityWithTimestampFrag,
  type ProposalFrag,
  ProposalStatus
} from '@/graphql'
import { type ValueOf } from '@/support/Types'
import { useCompanyOps } from './CompanyOps'
import { defineOps } from './Ops'
import { useRoundOps } from './RoundOps'

export const OpportunityStatusDict = {
  [OpportunityStatus.Open]: 'Open',
  [OpportunityStatus.InProgress]: 'In Progress',
  [OpportunityStatus.Closed]: 'Closed'
} as const

export const OpportunityStatusModeDict = {
  [OpportunityStatus.Open]: 'info',
  [OpportunityStatus.InProgress]: 'success',
  [OpportunityStatus.Closed]: 'mute'
} as const

export const OpportunityInitialEvaluationDict = {
  [OpportunityInitialEvaluation.Proceed]: 'Considering investment',
  [OpportunityInitialEvaluation.NotToProceed]: 'Not considering investment',
  [OpportunityInitialEvaluation.Na]: ''
} as const

export const OpportunityInitialReviewMeetingResultTextDict = {
  [OpportunityInitialReviewMeetingResult.Pending]: 'In Progress',
  [OpportunityInitialReviewMeetingResult.ShouldProceed]: 'Return expectation: High',
  [OpportunityInitialReviewMeetingResult.OkToContinue]: 'Return expectation: Uncertain',
  [OpportunityInitialReviewMeetingResult.NotRecommended]: 'Return expectation: Low',
  [OpportunityInitialReviewMeetingResult.Knockout]: 'Knockout',
  [OpportunityInitialReviewMeetingResult.Aborted]: 'Aborted'
} as const

export const OpportunityInitialReviewMeetingResultModeDict = {
  [OpportunityInitialReviewMeetingResult.Pending]: 'info',
  [OpportunityInitialReviewMeetingResult.ShouldProceed]: 'success',
  [OpportunityInitialReviewMeetingResult.OkToContinue]: 'success',
  [OpportunityInitialReviewMeetingResult.NotRecommended]: 'warning',
  [OpportunityInitialReviewMeetingResult.Knockout]: 'danger',
  [OpportunityInitialReviewMeetingResult.Aborted]: 'mute'
} as const

export const OpportunityConflictManagementCommitteeResultTextDict = {
  [OpportunityConflictManagementCommitteeResult.Pending]: 'In progress',
  [OpportunityConflictManagementCommitteeResult.Approved]: 'Approved',
  [OpportunityConflictManagementCommitteeResult.Rejected]: 'Rejected',
  [OpportunityConflictManagementCommitteeResult.Aborted]: 'Aborted'
} as const

export const OpportunityConflictManagementCommitteeResultModeDict = {
  [OpportunityConflictManagementCommitteeResult.Pending]: 'info',
  [OpportunityConflictManagementCommitteeResult.Approved]: 'success',
  [OpportunityConflictManagementCommitteeResult.Rejected]: 'danger',
  [OpportunityConflictManagementCommitteeResult.Aborted]: 'mute'
} as const

export const useOpportunityOps = defineOps(() => {
  const companyOps = useCompanyOps()
  const roundOps = useRoundOps()

  function displayId(oppo: OpportunityIdFrag): string {
    return `OPP-${String(oppo.id).padStart(6, '0')}`
  }

  function url(oppo: OpportunityWithIdFrag): string {
    return `${import.meta.env.APP_BASE_URL}${path(oppo)}`
  }

  function path(oppo: OpportunityWithIdFrag, path?: string): string {
    path = path ? `/${path}` : ''
    return `/opportunities/${oppo.id}${path}`
  }

  function title(oppo: OpportunityTitleableFrag): string {
    const r = roundName(oppo)

    // Slice 'YYYY-MM-DD' from 'YYYY-MM-DDTHH:MM:SSZ' string.
    const c = oppo.createdAt.slice(0, 10)

    return `${r} ${c}`
  }

  function titleWithCompany(oppo: OpportunityWithCompanyFrag & OpportunityTitleableFrag): string {
    const c = companyOps.name(oppo.company)

    return `${c} / ${title(oppo)}`
  }

  function statusText(oppo: OpportunityStatusFrag): string {
    return OpportunityStatusDict[oppo.status]
  }

  function statusMode(oppo: OpportunityStatusFrag): 'info' | 'success' | 'mute' {
    return OpportunityStatusModeDict[oppo.status]
  }

  function isClosed(oppo: OpportunityWithStatusFrag): boolean {
    return oppo.status === OpportunityStatus.Closed
  }

  function initialEvaluationText(oppo: OpportunityWithStatusFrag): string {
    return OpportunityInitialEvaluationDict[oppo.initialEvaluation]
  }

  function initialEvaluationTextByValue(value: OpportunityInitialEvaluation): string {
    return OpportunityInitialEvaluationDict[value]
  }

  function isInitialEvaluationProceed(oppo: OpportunityWithStatusFrag): boolean {
    return oppo.initialEvaluation === OpportunityInitialEvaluation.Proceed
  }

  function isInitialEvaluationNotToProceed(oppo: OpportunityWithStatusFrag): boolean {
    return oppo.initialEvaluation === OpportunityInitialEvaluation.NotToProceed
  }

  function isInitialEvaluationNa(oppo: OpportunityWithStatusFrag): boolean {
    return oppo.initialEvaluation === OpportunityInitialEvaluation.Na
  }

  function expiresAt(oppo: OpportunityWithExpiresAtFrag): Day | null {
    return oppo.expiresAt ? day(oppo.expiresAt) : null
  }

  function expiresAtReadableText(oppo: OpportunityWithExpiresAtFrag): string | null {
    const e = expiresAt(oppo)

    if (!e) {
      return null
    }

    const diff = e.diff(day(), 'day')

    if (diff > 13) {
      return e.format('YYYY-MM-DD')
    }

    if (diff > 0) {
      return e.from(day())
    }

    return 'Expired'
  }

  function expiresAtMode(oppo: OpportunityWithExpiresAtFrag): 'soft' | 'warning' | 'danger' {
    const e = expiresAt(oppo)

    if (!e) {
      return 'soft'
    }

    const diff = e.diff(day(), 'day')

    if (diff > 13) {
      return 'soft'
    }

    if (diff > 0) {
      return 'warning'
    }

    return 'danger'
  }

  function expiresAtModeForTitle(oppo: OpportunityWithExpiresAtFrag): 'neutral' | 'warning' | 'danger' {
    const mode = expiresAtMode(oppo)
    return mode === 'soft' ? 'neutral' : mode
  }

  function roundName(oppo: OpportunityRoundNamableFrag): string {
    return oppo.round ? roundOps.fullName(oppo.round) : 'No round'
  }

  function latestInitialReviewMeeting(oppo: Opportunity | OpportunityForDetailsFrag): OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag | null {
    return oppo.initialReviewMeetings.at(-1) ?? null
  }

  function latestConflictManagementCommittee(oppo: OpportunityForDetailsFrag): OpportunityConflictManagementCommitteeFrag | null {
    return oppo.conflictManagementCommittees.at(-1) ?? null
  }

  function createdAt(oppo: Opportunity | OpportunityWithTimestampFrag): Day {
    return day(oppo.createdAt)
  }

  function updatedAt(oppo: Opportunity | OpportunityWithTimestampFrag): Day {
    return day(oppo.updatedAt)
  }

  return {
    displayId,
    url,
    path,
    title,
    titleWithCompany,
    statusText,
    statusMode,
    isClosed,
    initialEvaluationText,
    initialEvaluationTextByValue,
    isInitialEvaluationProceed,
    isInitialEvaluationNotToProceed,
    isInitialEvaluationNa,
    roundName,
    expiresAt,
    expiresAtReadableText,
    expiresAtMode,
    expiresAtModeForTitle,
    latestInitialReviewMeeting,
    latestConflictManagementCommittee,
    createdAt,
    updatedAt
  }
})

export const useOpportunitySourceOps = defineOps(() => {
  function title(source: OpportunitySourceFrag): string {
    return `${day(source.date).format('YYYY-MM-DD')}: ${source.promotedSource.name}`
  }

  function date(source: OpportunitySourceFrag): Day {
    return day(source.date)
  }

  function isUsedInProposals(source: OpportunitySourceFrag, propos: ProposalFrag[]): boolean {
    return propos.some((propo) => propo.source?.id === source.id)
  }

  return {
    title,
    date,
    isUsedInProposals
  }
})

export const useOpportunityFileOps = defineOps(() => {
  function createdAt(file: OpportunityFile): Day {
    return day(file.createdAt)
  }

  return {
    createdAt
  }
})

export const useOpportunityInitialReviewMeetingOps = defineOps(() => {
  const { t } = useTrans({
    en: {
      request_initiation_form_title: (company: string) => `Initial Review Metting request for ${company}`
    },
    ja: {
      request_initiation_form_title: (company: string) => `${company}の案件初期検討会開催依頼`
    }
  })

  const companyOps = useCompanyOps()
  const oppoOps = useOpportunityOps()

  function isResultPending(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): boolean {
    return irm.result === OpportunityInitialReviewMeetingResult.Pending
  }

  function isResultAborted(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): boolean {
    return irm.result === OpportunityInitialReviewMeetingResult.Aborted
  }

  function isResultCompleted(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): boolean {
    return !isResultPending(irm) && !isResultAborted(irm)
  }

  function resultText(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): string {
    return OpportunityInitialReviewMeetingResultTextDict[irm.result]
  }

  function resultMode(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): ValueOf<typeof OpportunityInitialReviewMeetingResultModeDict> {
    return OpportunityInitialReviewMeetingResultModeDict[irm.result]
  }

  function resultState(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): DescState {
    return { label: resultText(irm), mode: resultMode(irm) }
  }

  function resultDescState(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): DescState | null {
    return isResultPending(irm) ? null : resultState(irm)
  }

  function date(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): Day | null {
    return irm.date ? day(irm.date) : null
  }

  function requestIdText(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): string {
    return `REQ-${String(irm.requestId).padStart(6, '0')}`
  }

  function requestUrl(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): string {
    return `${import.meta.env.FATIMA_INITIAL_REVIEW_MEETING_BASE_URL}/runs/${String(irm.requestId)}`
  }

  function requestInitiationUrl(oppo: OpportunityForDetailsFrag): string {
    const base = `${import.meta.env.FATIMA_INITIAL_REVIEW_MEETING_BASE_URL}/initiate`

    const company = oppo.company
    const companyName = companyOps.name(company)

    const query: string[] = []

    query.push(`title=${encodeURIComponent(t.request_initiation_form_title(companyName))}`)
    query.push(`company_name=${encodeURIComponent(companyName)}`)

    if (company.website) {
      query.push(`website=${encodeURIComponent(company.website)}`)
    }

    query.push(`oppo_url=${encodeURIComponent(oppoOps.url(oppo))}`)

    return `${base}?${query.join('&')}`
  }

  function createdAt(irm: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag): Day {
    return day(irm.createdAt)
  }

  return {
    isResultPending,
    isResultAborted,
    isResultCompleted,
    resultText,
    resultMode,
    resultState,
    resultDescState,
    date,
    requestIdText,
    requestUrl,
    requestInitiationUrl,
    createdAt
  }
})

export const useOpportunityConflictManagementCommitteeOps = defineOps(() => {
  const { t } = useTrans({
    en: {
      request_initiation_form_title: (company: string) => `Conflict Management Committee request for ${company}`
    },
    ja: {
      request_initiation_form_title: (company: string) => `${company}の利益相反管理委員会開催依頼`
    }
  })

  const { t: meetingFormatTextDict } = useTrans({
    en: {
      [OpportunityConflictManagementCommitteeMeetingFormat.Committee]: 'Committee',
      [OpportunityConflictManagementCommitteeMeetingFormat.Secretariat]: 'Secretariat'
    },
    ja: {
      [OpportunityConflictManagementCommitteeMeetingFormat.Committee]: '委員会開催',
      [OpportunityConflictManagementCommitteeMeetingFormat.Secretariat]: '事務局開催'
    }
  })

  const companyOps = useCompanyOps()
  const oppoOps = useOpportunityOps()

  function meetingFormatText(cmc: OpportunityConflictManagementCommitteeFrag): string | null {
    return cmc.meetingFormat ? meetingFormatTextDict[cmc.meetingFormat] : null
  }

  function isResultPending(cmc: OpportunityConflictManagementCommitteeFrag): boolean {
    return cmc.result === OpportunityConflictManagementCommitteeResult.Pending
  }

  function resultText(cmc: OpportunityConflictManagementCommitteeFrag): string {
    return OpportunityConflictManagementCommitteeResultTextDict[cmc.result]
  }

  function resultMode(cmc: OpportunityConflictManagementCommitteeFrag): ValueOf<typeof OpportunityConflictManagementCommitteeResultModeDict> {
    return OpportunityConflictManagementCommitteeResultModeDict[cmc.result]
  }

  function resultDescState(cmc: OpportunityConflictManagementCommitteeFrag): DescState | null {
    return isResultPending(cmc)
      ? null
      : { label: resultText(cmc), mode: resultMode(cmc) }
  }

  function date(cmc: OpportunityConflictManagementCommitteeFrag): Day | null {
    return cmc.date ? day(cmc.date) : null
  }

  function requestIdText(cmc: OpportunityConflictManagementCommitteeFrag): string {
    return `REQ-${String(cmc.requestId).padStart(6, '0')}`
  }

  function requestInitiationUrl(oppo: OpportunityForDetailsFrag): string {
    const base = `${import.meta.env.FATIMA_CONFLICT_MANAGEMENT_COMMITTEE_BASE_URL}/initiate`

    const company = oppo.company
    const companyName = companyOps.name(company)

    const query: string[] = []

    query.push(`title=${encodeURIComponent(t.request_initiation_form_title(companyName))}`)
    query.push(`company_name=${encodeURIComponent(companyName)}`)
    query.push(`oppo_url=${encodeURIComponent(oppoOps.url(oppo))}`)

    const investedFunds = [
      ...company.portfolioOf,
      ...company.exPortfolioOf
    ]

    if (investedFunds.length) {
      query.push(`new_or_add=${encodeURIComponent('add')}`)

      investedFunds.forEach((fund) => {
        query.push(`invested_funds[]=${encodeURIComponent(fund.nameSlack)}`)
      })
    } else {
      query.push(`new_or_add=${encodeURIComponent('new')}`)
    }

    if (oppo.round) {
      const { value, currency } = oppo.round.totalAmount
      query.push(`investment_amount=${encodeURIComponent(`${currency.name} ${value}`)}`)
    }

    const collaborators = companyOps.assigneesExceptMe(company)

    if (collaborators.length) {
      collaborators.forEach((collaborator) => {
        query.push(`collaborators[]=${encodeURIComponent(collaborator.email)}`)
      })
    }

    return `${base}?${query.join('&')}`
  }

  function requestUrl(cmc: OpportunityConflictManagementCommitteeFrag): string {
    return `${import.meta.env.FATIMA_CONFLICT_MANAGEMENT_COMMITTEE_BASE_URL}/runs/${String(cmc.requestId)}`
  }

  return {
    meetingFormatText,
    isResultPending,
    resultText,
    resultMode,
    resultDescState,
    date,
    requestIdText,
    requestInitiationUrl,
    requestUrl
  }
})

export const useOpportunityProposalOps = defineOps(() => {
  const opprtunitunitySourceOps = useOpportunitySourceOps()

  function statusText(propo: ProposalFrag): string {
    switch (propo.status) {
      case ProposalStatus.Open: return 'Open'
      case ProposalStatus.Hidden: return 'Hidden'
      case ProposalStatus.Closed: return 'Closed'
    }
  }

  function statusMode(propo: ProposalFrag): 'success' | 'mute' {
    switch (propo.status) {
      case ProposalStatus.Open: return 'success'
      case ProposalStatus.Hidden: return 'mute'
      case ProposalStatus.Closed: return 'mute'
    }
  }

  function sourcingInformation(propo: ProposalFrag): string | null {
    if (propo.source) {
      return opprtunitunitySourceOps.title(propo.source as OpportunitySourceFrag)
    }
    return null
  }

  function createdAt(propo: ProposalFrag): Day | null {
    return propo.createdAt ? day(propo.createdAt) : null
  }

  function closedAt(propo: ProposalFrag): Day | null {
    return propo.closedAt ? day(propo.closedAt) : null
  }

  function statusUpdatedAt(propo: ProposalFrag): Day | null {
    return propo.statusUpdatedAt ? day(propo.statusUpdatedAt) : null
  }

  return {
    statusText,
    statusMode,
    sourcingInformation,
    createdAt,
    closedAt,
    statusUpdatedAt
  }
})

export const useExtractedOpportunityOps = defineOps(() => {
  function sourceDate(oppo: ExtractedOpportunity): Day | null {
    return oppo.sourceDate ? day(oppo.sourceDate) : null
  }

  return {
    sourceDate
  }
})

export const useOpportunityFileRequestOps = defineOps(() => {
  function createdAt(request: OpportunityFileRequest): Day {
    return day(request.createdAt)
  }

  return {
    createdAt
  }
})

export const useOpportunityFileDownloadOps = defineOps(() => {
  function createdAt(request: OpportunityFileDownload): Day {
    return day(request.createdAt)
  }

  return {
    createdAt
  }
})
