import { useTable } from 'sefirot/composables/Table'
import { day } from 'sefirot/support/Day'
import { type Ref, computed } from 'vue'
import { type ViewEntity, ViewEntityFieldType } from '@/graphql'
import { type View } from '@/models/View'
import { type ViewResult } from '@/models/ViewResult'

const COMPANY_PATH_FIELD_KEY = 'company_path'
const OPPORTUNITY_PATH_FIELD_KEY = 'opportunity_path'
const DEAL_PATH_FIELD_KEY = 'deal_path'
const INITIAL_REVIEW_MEETING_PATH_FIELD_KEY = 'initial_review_meeting_path'

export function useViewResultTable(
  view: Ref<View | null | undefined>,
  viewResult: Ref<ViewResult | null | undefined>,
  loading: Ref<boolean | undefined>
) {
  const entities = computed(() => {
    const entityMap = view?.value?.viewSchema.entities.reduce<Record<string, any>>((map, entity) => {
      map[entity.id] = entity
      return map
    }, {}) ?? {}

    const _entities = view?.value?.entityIds.map((id) => entityMap[id]) ?? []

    _entities.push({ id: 'spacer' })

    return _entities
  })

  const orders = computed(() => entities.value?.map((entity) => entity.id) ?? [])

  const columns = computed(() => {
    return entities.value.reduce((obj, entity) => {
      obj[entity.id] = createColumn(entity)
      return obj
    }, {})
  })

  const records = computed(() => {
    return viewResult?.value?.body?.map((record, recordIndex) => {
      return entities.value.reduce((obj, entity, index) => {
        if (isLinkableEntityId(entity.id)) {
          Object.entries(viewResult?.value?.ids?.[recordIndex] ?? {}).forEach(([key, value]) => {
            if (key === 'companyId') {
              obj[COMPANY_PATH_FIELD_KEY] = `/companies/${value}/profile`
            }
            if (key === 'opportunityId') {
              obj[OPPORTUNITY_PATH_FIELD_KEY] = `/opportunities/${value}/details`
            }
            if (key === 'dealId') {
              obj[DEAL_PATH_FIELD_KEY] = `/deals/${value}/details`
            }
            if (key === 'fatimaRequestId') {
              obj[INITIAL_REVIEW_MEETING_PATH_FIELD_KEY] = `${import.meta.env.FATIMA_INITIAL_REVIEW_MEETING_BASE_URL}/runs/${String(value)}`
            }
          })
        }

        obj[entity.id] = record[index]

        return obj
      }, {})
    })
  })

  return useTable({
    orders,
    columns,
    records,
    total: computed(() => viewResult?.value?.body?.length ?? 0),
    loading,
    borderless: true
  })
}

function createColumn(
  entity: ViewEntity
) {
  if (isLinkableEntityId(entity.id)) {
    return {
      label: entity.label,
      cell: (_: any, record: any) => ({
        type: 'text',
        link: record[getPathFieldKey(entity.id)],
        color: 'info'
      })
    }
  }

  switch (entity.field) {
    case ViewEntityFieldType.String:
    case ViewEntityFieldType.Money:
    case ViewEntityFieldType.Enum:
    case ViewEntityFieldType.Id:
    case ViewEntityFieldType.Integer:
    case ViewEntityFieldType.Date:
      return {
        label: entity.label,
        cell: {
          type: 'text'
        }
      }
    case ViewEntityFieldType.DateTime:
      return {
        label: entity.label,
        cell: (v: any, _: any) => ({
          type: 'day',
          value: day(v),
          format: 'YYYY-MM-DD HH:mm'
        })
      }
    case ViewEntityFieldType.Boolean:
      return {
        label: entity.label,
        cell: (v: any, _: any) => ({
          type: 'text',
          value: typeof v === 'boolean' ? v ? 'Yes' : 'No' : ''
        })
      }
    case ViewEntityFieldType.Url:
      return {
        label: entity.label,
        cell: (v: any, _: any) => ({
          type: 'text',
          link: v,
          color: 'info'
        })
      }
    default:
      return {
        label: entity.label,
        cell: {
          type: 'text'
        }
      }
  }
}

function isLinkableEntityId(entityId: string) {
  const linkableEntityIds = [
    'company_names.display_name',
    'company_names.official_name_local',
    'company_names.official_name_en',
    'companies.id',
    'opportunities.id',
    'deals.id',
    'opportunities.initial_review_meeting.request_id'
  ]

  return linkableEntityIds.includes(entityId)
}

function getPathFieldKey(entityId: string) {
  const linkableEntityIdsForCompany = [
    'companies.id',
    'company_names.display_name',
    'company_names.official_name_local',
    'company_names.official_name_en'
  ]
  const linkableEntityIdsForOpportunity = [
    'opportunities.id'
  ]
  const linkableEntityIdsForDeal = [
    'deals.id'
  ]
  const linkableEntityIdsForInitialReviewMeeting = [
    'opportunities.initial_review_meeting.request_id'
  ]

  if (linkableEntityIdsForCompany.includes(entityId)) {
    return COMPANY_PATH_FIELD_KEY
  }
  if (linkableEntityIdsForOpportunity.includes(entityId)) {
    return OPPORTUNITY_PATH_FIELD_KEY
  }
  if (linkableEntityIdsForDeal.includes(entityId)) {
    return DEAL_PATH_FIELD_KEY
  }
  if (linkableEntityIdsForInitialReviewMeeting.includes(entityId)) {
    return INITIAL_REVIEW_MEETING_PATH_FIELD_KEY
  }

  return ''
}
