<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxValue, required } from 'sefirot/validation/rules'
import { type DealWithExpenseFrag, type DealWithIdFrag } from '@/graphql'
import { useCurrencyOps } from '@/composables/ops/CurrencyOps'
import { useUpdateDealExpense } from '@/composables/repos/DealRepo'
import DInputMoney from '../DInputMoney.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithExpenseFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { execute, loading } = useUpdateDealExpense()
const currencyOps = useCurrencyOps()

const { data } = useData({
  ddExpense: props.deal.ddExpense ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  ddExpense: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) }
})

async function update() {
  if (await validateAndNotify()) {
    await execute(props.deal.id, {
      ddExpense: data.value.ddExpense!
    })

    emit('updated')
  }
}
</script>

<template>
  <DForm size="small">
    <DFormTitle>Update expense</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <DInputMoney
          label="DD expense"
          placeholder="3,000,000"
          :currency="currencyOps.createJpy()"
          v-model="data.ddExpense"
          :validation="validation.ddExpense"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
