import { type Dayjs } from 'dayjs'
import { type Ref, watch } from 'vue'
import { StatInvestmentSummary } from '@/models/StatInvestmentSummary'
import StatInvestmentSummaryRequest from '@/requests/StatInvestmentSummaryRequest'
import { type UseQueryOptions, useQuery } from '../Api'

export function useItem(
  from: Ref<Dayjs>,
  to: Ref<Dayjs>,
  options?: UseQueryOptions
) {
  const query = useQuery(async () => {
    const res = await new StatInvestmentSummaryRequest().fetch(from.value.toISOString(), to.value.toISOString())

    return new StatInvestmentSummary(res.data.statInvestmentSummary)
  }, options)

  watch([from, to], query.execute)

  return query
}
