<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { email, maxLength, requiredIf } from 'sefirot/validation/rules'

export interface Contact {
  name: string | null
  title: string | null
  email: string | null
}

const props = defineProps<{
  contact: Contact
}>()

const emit = defineEmits<{
  (e: 'update', contact: Contact): void
}>()

const { t } = useTrans({
  en: {
    i_name_ph: 'John Doe',
    i_title_ph: 'CEO',
    i_email_ph: 'john.doe@example.com'
  },
  ja: {
    i_name_ph: '山田 太郎',
    i_title_ph: 'CEO',
    i_email_ph: 'john.doe@example.com'
  }
})

const { validation } = useValidation(() => props.contact, {
  name: {
    requiredIf: requiredIf(() => othersExist('name')),
    maxLength: maxLength(100)
  },
  title: {
    requiredIf: requiredIf(() => othersExist('title')),
    maxLength: maxLength(255)
  },
  email: {
    requiredIf: requiredIf(() => othersExist('email')),
    email: email(),
    maxLength: maxLength(255)
  }
})

function othersExist(key: keyof Contact) {
  return Object.keys(props.contact)
    .some((k) => k !== key && props.contact[k as keyof Contact] !== null)
}

function update(key: keyof Contact, value: string | null) {
  emit('update', {
    ...props.contact,
    [key]: value
  })
}
</script>

<template>
  <SGrid>
    <SGrid cols="3" gap="8">
      <SGridItem>
        <SInputText
          :placeholder="t.i_name_ph"
          :model-value="contact.name"
          :validation="validation.name"
          hide-error
          @input="update('name', $event)"
        />
      </SGridItem>
      <SGridItem>
        <SInputText
          :placeholder="t.i_title_ph"
          :model-value="contact.title"
          :validation="validation.title"
          hide-error
          @input="update('title', $event)"
        />
      </SGridItem>
      <SGridItem>
        <SInputText
          :placeholder="t.i_email_ph"
          :model-value="contact.email"
          :validation="validation.email"
          hide-error
          @input="update('email', $event)"
        />
      </SGridItem>
    </SGrid>
  </SGrid>
</template>
