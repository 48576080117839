<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { usePower } from 'sefirot/composables/Power'
import { reactive } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useCanAddOpportunityProposalPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityProposalAddWizard from '../opportunity-proposal/OpportunityProposalAddWizard.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

defineEmits<{
  added: []
}>()

const canAddProposal = useCanAddOpportunityProposalPolicy(() => props.opportunity)

const addProposalsWizard = reactive(usePower(false))
</script>

<template>
  <SCardBlock class="s-p-24">
    <div v-if="!canAddProposal.ok" class="body">
      <div class="text mute">
        This opportunity is not open to any funds.
      </div>
    </div>
    <div v-else class="body">
      <div class="text">
        This opportunity is not opened to any funds. Add proposals to apprise funds and initiate the deal process for this opportunity.
      </div>
      <div class="action">
        <SButton
          size="small"
          mode="info"
          label="New proposals"
          @click="addProposalsWizard.on"
        />
      </div>
    </div>
    <SModal :open="addProposalsWizard.state" @close="addProposalsWizard.off">
      <Suspense>
        <OpportunityProposalAddWizard
          :opportunity="opportunity"
          @close="addProposalsWizard.off"
          @added="addProposalsWizard.off(() => $emit('added'))"
        />
      </Suspense>
    </SModal>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  align-items: center;
  gap: 48px;
}

.text {
  flex-grow: 1;
  max-width: 640px;
  font-size: 14px;
}

.text.mute {
  color: var(--c-text-3);
}

.link {
  color: var(--c-text-info-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-2);
  }
}
</style>
