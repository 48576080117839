<script setup lang="ts">
import { type KadomonyNews } from '@/graph/Kadomony'
import { useKadomonyNewsOps } from '@/composables/ops/KadomonyOps'

defineProps<{
  news: KadomonyNews
}>()

const newsOps = useKadomonyNewsOps()
</script>

<template>
  <div class="CompanyNewsListItem">
    <div class="title">
      <SLink class="title-text" :href="news.url">
        {{ news.title }}
      </SLink>
    </div>
    <div class="meta">
      <div class="meta-item">
        <div class="meta-value">
          {{ newsOps.publishedAt(news).format('YYYY-MM-DD HH:mm') }}
        </div>
      </div>
      <div class="meta-item">
        <div class="meta-value mute">•</div>
      </div>
      <div class="meta-item">
        <div class="meta-value">
          {{ newsOps.typeText(news) }}
        </div>
      </div>
      <div class="meta-item">
        <div class="meta-value mute">•</div>
      </div>
      <div class="meta-item">
        <div class="meta-value">
          {{ newsOps.sourceText(news) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.CompanyNewsListItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 24px;
}

.title-text {
  display: block;
  line-height: 24px;
  font-size: 14px;
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-1);
  }
}

.meta {
  display: flex;
  gap: 8px;
}

.meta-value {
  font-size: 12px;
  color: var(--c-text-2);
}

.meta-value.mute {
  color: var(--c-text-3);
}

.meta-value.warning {
  color: var(--c-text-warning-1);
}
</style>
