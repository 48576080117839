<script setup lang="ts">
import { orderBy, xor } from 'lodash-es'
import SActionMenu from 'sefirot/components/SActionMenu.vue'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, ref } from 'vue'
import {
  OrderDirection,
  type UserAndRolesFrag,
  type UserFrag,
  UserStatus,
  type UserWithDeactivatedAtFrag,
  type UserWithTagsFrag,
  type UserWithTimestampFrag
} from '@/graphql'
import { useRoleOps } from '@/composables/ops/RoleOps'
import { UserTagTextDict, useUserOps } from '@/composables/ops/UserOps'
import { useRoleList } from '@/composables/repos/RoleRepo'
import { useUserListForCatalog, useUserTagList } from '@/composables/repos/UserRepo'
import DInputSearch from '../DInputSearch.vue'

enum UserOrderField {
  FirstNameEn = 'firstNameEn',
  LastNameEn = 'lastNameEn',
  FirstNameJa = 'firstNameJa',
  LastNameJa = 'lastNameJa',
  Email = 'email',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeactivatedAt = 'deactivatedAt'
}

const { t } = useTrans({
  en: {
    c_options: 'Options',
    c_reset: 'Reset filters',
    member: 'Member',
    name1_en: 'Name 1st EN',
    name2_en: 'Name 2nd EN',
    name1_ja: 'Name 1st JA',
    name2_ja: 'Name 2nd JA',
    status: 'Status',
    email: 'Email',
    role: 'Roles',
    tags: 'Tags',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    deactivatedAt: 'DeactivatedA at',
    search: 'Search members',
    sort_asc: 'Sort ascending (A...Z)',
    sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    c_options: 'オプション',
    c_reset: 'フィルターをリセット',
    member: 'メンバー',
    name1_en: 'Name 1st EN',
    name2_en: 'Name 2nd EN',
    name1_ja: 'Name 1st JA',
    name2_ja: 'Name 2nd JA',
    status: 'ステータス',
    email: 'Email',
    role: 'ロール',
    tags: 'タグ',
    createdAt: '作成日時',
    updatedAt: '更新日時',
    deactivatedAt: '無効化日時',
    search: 'メンバーを検索',
    sort_asc: 'ソート: 昇順',
    sort_desc: 'ソート: 降順'
  }
})

const { data: options, init: reset } = useData({
  condition: {
    query: null as string | null,
    statuses: [] as UserStatus[],
    roles: [] as number[],
    tags: [] as string[]
  },
  orderBy: {
    field: UserOrderField.FirstNameEn,
    direction: OrderDirection.Asc
  }
})

const filteredUsers = computed(() => {
  return users.value
    ?.filter(filterByQuery)
    .filter(filterByRole)
    .filter(filterByTag)
    .filter(filterByStatus)
})

function filterByQuery(user: UserFrag) {
  if (!options.value.condition.query) {
    return true
  }

  return !!(userOps.nameEn(user).toLowerCase().includes(options.value.condition.query.toLowerCase())
    || userOps.nameJa(user).toLowerCase().includes(options.value.condition.query.toLowerCase()))
}

function filterByRole(user: UserAndRolesFrag) {
  if (options.value.condition.roles.length === 0) {
    return true
  }

  return user.roles.some((role) => options.value.condition.roles.includes(role.id))
}

function filterByTag(user: UserWithTagsFrag) {
  if (options.value.condition.tags.length === 0) {
    return true
  }

  return user.tags.some((tag) => options.value.condition.tags.includes(tag))
}

function filterByStatus(user: UserFrag) {
  if (options.value.condition.statuses.length) {
    if (shouldShowStatusColumn.value) {
      return options.value.condition.statuses.includes(user.status)
    }

    return true
  }

  return shouldShowStatusColumn.value || userOps.isActive(user)
}

const orderedUsers = computed(() => {
  const { field, direction } = options.value.orderBy
  const _directioin = direction === OrderDirection.Asc ? 'asc' : 'desc'
  return orderBy(filteredUsers.value, [field], [_directioin])
})

useUrlQuerySync(options, {
  casts: {
    'condition.query': (v) => (v === '' || v === null) ? null : v
  }
})

const { loading, data: users } = useUserListForCatalog()

const { data: roles } = useRoleList()
const { data: tags } = useUserTagList()

const userOps = useUserOps()
const roleOps = useRoleOps()

const optionsSelected = ref<string[]>(
  options.value.condition.statuses.length || options.value.orderBy.field === 'deactivatedAt'
    ? ['show-inactive-members']
    : []
)
const shouldShowStatusColumn = computed(() => optionsSelected.value.includes('show-inactive-members'))

const statusDropdown = createDropdown([
  {
    type: 'filter',
    selected: optionsSelected,
    options: [
      { label: 'Show inactive members', value: 'show-inactive-members', onClick: updateOptions }
    ]
  }
])

const table = useTable({
  records: orderedUsers,
  loading,
  borderless: true,
  orders: [
    'member',
    'status',
    'name1st_en',
    'name2nd_en',
    'name1st_ja',
    'name2nd_ja',
    'email',
    'roles',
    'tags',
    'createdAt',
    'updatedAt',
    'deactivatedAt',
    'spacer'
  ],
  columns: {
    member: {
      label: t.member,
      cell: (_, user) => ({
        type: 'avatar',
        image: userOps.avatarPath(user),
        name: userOps.slug(user),
        link: userOps.path(user)
      })
    },
    status: {
      label: t.status,
      show: computed(() => shouldShowStatusColumn.value),
      dropdown: createDropdown([
        {
          type: 'filter',
          selected: computed(() => options.value.condition.statuses),
          options: computed(() => [
            { label: 'Active', value: UserStatus.Active },
            { label: 'Inactive', value: UserStatus.Inactive }
          ]),
          onClick(value: UserStatus) {
            options.value.condition.statuses = xor(options.value.condition.statuses, [value])
          }
        }
      ]),
      cell: (_value, record) => ({
        type: 'state',
        label: record.status,
        mode: record.status === UserStatus.Active
          ? 'success'
          : 'mute'
      })
    },
    name1st_en: {
      label: t.name1_en,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.FirstNameEn, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.FirstNameEn, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, user) => ({
        type: 'text',
        value: user.firstNameEn
      })
    },
    name2nd_en: {
      label: t.name2_en,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.LastNameEn, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.LastNameEn, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, user) => ({
        type: 'text',
        value: user.lastNameEn
      })
    },
    name1st_ja: {
      label: t.name1_ja,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.LastNameJa, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.LastNameJa, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, user) => ({
        type: 'text',
        value: user.lastNameJa
      })
    },
    name2nd_ja: {
      label: t.name2_ja,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.LastNameEn, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.LastNameEn, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, user) => ({
        type: 'text',
        value: user.firstNameJa
      })
    },
    email: {
      label: t.email,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.Email, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.Email, OrderDirection.Desc)
            }
          ]
        }
      ])
    },
    roles: {
      label: t.role,
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.roles),
          options: computed(() => roles.value?.map((role) => ({
            label: roleOps.nameText(role),
            value: role.id
          })) ?? []),
          onClick(value: number) {
            options.value.condition.roles = xor(options.value.condition.roles, [value])
          }
        }
      ]),
      cell: (_, user) => ({
        type: 'pills',
        pills: user.roles.map((role) => ({
          label: roleOps.nameText(role)
        }))
      })
    },
    tags: {
      label: t.tags,
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.tags),
          options: computed(() => tags.value?.map((tag) => ({
            label: UserTagTextDict[tag],
            value: tag
          })) ?? []),
          onClick(value: string) {
            options.value.condition.tags = xor(options.value.condition.tags, [value])
          }
        }
      ]),
      cell: (_, user) => ({
        type: 'pills',
        pills: user.tags.map((tag) => ({
          label: UserTagTextDict[tag]
        }))
      })
    },
    createdAt: {
      label: t.createdAt,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.CreatedAt, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.CreatedAt, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: userOps.createdAt(record as unknown as UserWithTimestampFrag),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: t.updatedAt,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.UpdatedAt, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.UpdatedAt, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: userOps.updatedAt(record as unknown as UserWithTimestampFrag),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    deactivatedAt: {
      label: t.deactivatedAt,
      show: computed(() => shouldShowStatusColumn.value),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: t.sort_asc,
              onClick: () => updateOrder(UserOrderField.DeactivatedAt, OrderDirection.Asc)
            },
            {
              label: t.sort_desc,
              onClick: () => updateOrder(UserOrderField.DeactivatedAt, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: userOps.deactivatedAt(record as unknown as UserWithDeactivatedAtFrag),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: {
        type: 'empty'
      }
    }
  }
})

function updateOptions(value: string) {
  optionsSelected.value = xor(optionsSelected.value, [value])
}

function updateQuery(value: string | null) {
  options.value.condition.query = value === '' ? null : value
}

function updateOrder(field: UserOrderField, direction: OrderDirection) {
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}
</script>

<template>
  <div class="UserCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                :placeholder="t.search"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SActionMenu
              size="small"
              type="outline"
              mode="mute"
              :label="t.c_options"
              :options="statusDropdown"
            />
            <SButton
              size="small"
              type="outline"
              mode="mute"
              :label="t.c_reset"
              @click="reset"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable
          class="list"
          :options="table"
        />
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.UserCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-member) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-member)        { --table-col-width: 192px; }
.list :deep(.col-name1st_en)    { --table-col-width: 144px; }
.list :deep(.col-name2nd_en)    { --table-col-width: 144px; }
.list :deep(.col-name1st_ja)    { --table-col-width: 144px; }
.list :deep(.col-name2nd_ja)    { --table-col-width: 144px; }
.list :deep(.col-status)        { --table-col-width: 144px; }
.list :deep(.col-email)         { --table-col-width: 280px; }
.list :deep(.col-roles)         { --table-col-width: 192px; }
.list :deep(.col-tags)          { --table-col-width: 192px; }
.list :deep(.col-createdAt)     { --table-col-width: 180px; }
.list :deep(.col-updatedAt)     { --table-col-width: 180px; }
.list :deep(.col-deactivatedAt) { --table-col-width: 180px; }
.list :deep(.col-spacer)        { --table-col-width: 40px; }
</style>
