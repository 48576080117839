import { type Mutation, useMutation } from 'sefirot/composables/Api'
import { type CompanyCautionNote } from '@/graph/Company'

export type CreateComapnyCautionNote = Mutation<CompanyCautionNote, [input: CreateCompanyCautionNoteInput]>
export type UpdateComapnyCautionNote = Mutation<CompanyCautionNote, [companyId: number, input: UpdateCompanyCautionNoteInput]>
export type DeleteComapnyCautionNote = Mutation<void, [companyId: number]>

export interface CreateCompanyCautionNoteInput {
  companyId: number
  body: string
}

export interface UpdateCompanyCautionNoteInput {
  body: string
}

export function useCreateCompanyCautionNote(): CreateComapnyCautionNote {
  return useMutation(async (http, input) => {
    return http.post<CompanyCautionNote>('/api/company-caution-notes', input)
  })
}

export function useUpdateCompanyCautionNote(): UpdateComapnyCautionNote {
  return useMutation(async (http, companyId, input) => {
    return http.put<CompanyCautionNote>(`/api/company-caution-notes/${companyId}`, input)
  })
}

export function useDeleteCompanyCautionNote(): DeleteComapnyCautionNote {
  return useMutation(async (http, companyId) => {
    await http.delete<void>(`/api/company-caution-notes/${companyId}`)
  })
}
