<script setup lang="ts">
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { type Company } from '@/models/Company'
import { type CompanyName } from '@/models/CompanyName'
import { useState } from '@/composables/State'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import CompanyFormDeleteName from './CompanyFormDeleteName.vue'
import CompanyFormUpdateName from './CompanyFormUpdateName.vue'

const props = defineProps<{
  name: CompanyName
  company: Company
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useCompanyPolicy(() => props.company)
const { data: modal, set, clear } = useState<'update' | 'delete'>()

const actions = computedArrayWhen(canUpdate, (items) => {
  items.push({ icon: 'edit', onClick: () => set('update') })

  if (props.name.until) {
    items.push({ icon: 'delete', onClick: () => set('delete') })
  }
})

function onDeleted() {
  clear()
  setTimeout(() => emit('updated'), 500)
}
</script>

<template>
  <DCard
    :title="name.until ? `Until ${name.until.format('YYYY/MM/DD')}` : 'Present name'"
    :collapsable="false"
    :actions="actions"
  >
    <DCardBody>
      <DGrid>
        <DGridItem span="2">
          <DGridLabel text="Display name" />
          <DGridText :text="name.displayName" />
        </DGridItem>
        <DGridItem span="2" />
        <DGridItem span="2">
          <DGridLabel text="Official name" />
          <DGridText :text="name.officialNameLocal" />
        </DGridItem>
        <DGridItem span="2">
          <DGridLabel text="English name" />
          <DGridText :text="name.officialNameEn" />
        </DGridItem>
      </DGrid>
    </DCardBody>

    <SModal :open="modal === 'update'" @close="clear">
      <CompanyFormUpdateName
        :name="name"
        @updated="clear(() => $emit('updated'))"
        @cancel="clear"
      />
    </SModal>

    <SModal :open="modal === 'delete'" @close="clear">
      <CompanyFormDeleteName
        :name="name"
        @deleted="onDeleted"
        @cancel="clear"
      />
    </SModal>
  </DCard>
</template>
