<script setup lang="ts">
import IconMagnifyingGlass from '~icons/ph/magnifying-glass'
import SInputText from 'sefirot/components/SInputText.vue'
import { computed, ref } from 'vue'
import { type View } from '@/models/View'
import ViewHeaderFormSharedUserRecord from './ViewHeaderFormSharedUserRecord.vue'

const props = defineProps<{
  view?: View | null
}>()

defineEmits<{
  (e: 'added'): void
  (e: 'deleted'): void
}>()

const searchText = ref('')

const filteredUsers = computed(() => {
  return (props.view?.sharedUsers ?? []).filter((user) => {
    return !searchText.value || user.name.toLowerCase().includes(searchText.value.toLowerCase())
  })
})
</script>

<template>
  <DForm>
    <DFormTitle>Share view</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputText
          v-model="searchText"
          :icon="IconMagnifyingGlass"
          placeholder="Search users"
        />
      </DFormGridItem>

      <DFormGridItem span="6">
        <ViewHeaderFormSharedUserRecord
          :view="view"
          :filtered-shared-users="filteredUsers"
          @added="$emit('added')"
          @deleted="$emit('deleted')"
        />
      </DFormGridItem>
    </DFormGrid>
  </DForm>
</template>
