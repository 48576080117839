import {
  type Mutation,
  type Query as SQuery,
  useMutation,
  useQuery as useSQuery
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type ActionNoteCondition,
  type ActionNoteFrag,
  type ActionNoteOrder,
  ActionNoteOrderField,
  type ActionNotePageFrag,
  type BasicPaginationInput,
  type UpdateActionNoteInput
} from '@/graphql'
import { ActionNote } from '@/models/ActionNote'
import { ActionNoteRequest } from '@/requests/ActionNoteRequest'
import { CompanyRequest } from '@/requests/CompanyRequest'
import { type Page, type Query, createPage, useQuery } from '../Api'

export type { ActionNoteCondition, ActionNoteOrder }
export { ActionNoteOrderField }

export type ActionNotePage = SQuery<ActionNotePageFrag>
export type ActionNoteForSearchPage = Query<Page<ActionNote>, []>

export interface ActionNotePageOptions {
  page: BasicPaginationInput
  condition: ActionNoteCondition
  orderBy: ActionNoteOrder
}

export type CreateActionNote = Mutation<ActionNoteFrag, [input: CreateActionNoteInput]>

export interface CreateActionNoteInput {
  companyId: number
  title: string
  body: string
}

export type UpdateActionNote = Mutation<ActionNoteFrag, [id: number, input: UpdateActionNoteInput]>

export type DeleteActionNote = Mutation<void, [id: number]>

export function useActionNotePage(
  options: MaybeRefOrGetter<ActionNotePageOptions>
): ActionNotePage {
  return useSQuery(async () => {
    const o = toValue(options)
    return (await new ActionNoteRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )).data.actionNotes
  }, {
    watch: () => toValue(options)
  })
}

export function useActionNoteForSearchPage(
  options: ActionNotePageOptions
): ActionNoteForSearchPage {
  const query = useQuery(async () => {
    const res = await new ActionNoteRequest().fetchPageForSearch(
      options.page,
      options.condition,
      options.orderBy
    )

    return createPage(
      res.data.actionNotes.pageInfo,
      res.data.actionNotes.items.map((c) => new ActionNote(c))
    )
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useCreateActionNote(): CreateActionNote {
  return useMutation(async (_, input) => {
    const res = await new CompanyRequest().addActionNote(input.companyId, {
      title: input.title,
      body: input.body
    })
    return res.data.actionNote
  })
}

export function useUpdateActionNote(): UpdateActionNote {
  return useMutation(async (_, id, input) => {
    const res = await new ActionNoteRequest().update(id, input)
    return res.data.actionNote
  })
}

export function useDeleteActionNote(): DeleteActionNote {
  return useMutation(async (_, id) => {
    await new ActionNoteRequest().delete(id)
  })
}
