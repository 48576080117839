import { CompanyFinancialResult } from './CompanyFinancialResult'
import { Currency } from './Currency'
import { type Day, Model } from './Model'
import { User } from './User'

export class CompanyFinancialFigures extends Model {
  companyId?: number
  currencyId?: number
  currency: Currency | null
  results: CompanyFinancialResult[] | null
  notes: string | null
  notesUpdatedAt: Day | null
  updatedBy: User | null
  updatedAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.companyId = data.companyId
    this.currencyId = data.currencyId
    this.currency = this.hasOne(Currency, data.currency)
    this.results = this.hasMany(CompanyFinancialResult, data.results)
    this.notes = data.notes ?? null
    this.notesUpdatedAt = this.day(data.notesUpdatedAt)
    this.updatedBy = this.hasOne(User, data.updatedBy)
    this.updatedAt = this.day(data.updatedAt)
  }
}
