import {
  AdminViewPageDocument,
  type BasicPaginationInput,
  CreateViewDocument,
  DeleteViewDocument,
  type DeleteViewMutation,
  type DeleteViewMutationVariables,
  ExecuteViewDocument,
  type ExecuteViewMutation,
  type ExecuteViewMutationVariables,
  ProposalViewExportStatusesDocument,
  ShareViewDocument,
  type ShareViewMutation,
  type ShareViewMutationVariables,
  StartExportingViewDocument,
  type StartExportingViewMutation,
  type StartExportingViewMutationVariables,
  UnshareViewDocument,
  type UnshareViewMutation,
  type UnshareViewMutationVariables,
  ViewDocument,
  type ViewPageCondition,
  ViewPageDocument,
  type ViewPageOrder,
  type ViewQuery,
  type ViewQueryVariables,
  ViewSchemasDocument,
  type ViewSchemasQuery,
  type ViewSchemasQueryVariables,
  type ViewShareInput
} from '@/graphql'
import { Request } from './Request'

export class ViewRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: ViewPageCondition,
    orderBy: ViewPageOrder
  ) {
    return this.request({
      query: ViewPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchAdminPage(
    page: BasicPaginationInput,
    condition: ViewPageCondition,
    orderBy: ViewPageOrder
  ) {
    return this.request({
      query: AdminViewPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchProposalViewExportStatusAll() {
    return this.request({
      query: ProposalViewExportStatusesDocument
    })
  }

  fetch(id: number) {
    return this.request<ViewQuery, ViewQueryVariables>({
      query: ViewDocument,
      variables: { id }
    })
  }

  fetchViewSchemas() {
    return this.request<ViewSchemasQuery, ViewSchemasQueryVariables>({
      query: ViewSchemasDocument
    })
  }

  executeView(id: number, limit: number) {
    return this.request<ExecuteViewMutation, ExecuteViewMutationVariables>({
      query: ExecuteViewDocument,
      variables: { id, limit }
    })
  }

  create(viewSchemaId: number, name: string) {
    return this.request({
      query: CreateViewDocument,
      variables: { viewSchemaId, name }
    })
  }

  share(id: number, input: ViewShareInput) {
    return this.request<ShareViewMutation, ShareViewMutationVariables>({
      query: ShareViewDocument,
      variables: { id, input }
    })
  }

  unshare(id: number, input: ViewShareInput) {
    return this.request<UnshareViewMutation, UnshareViewMutationVariables>({
      query: UnshareViewDocument,
      variables: { id, input }
    })
  }

  startExport(id: number) {
    return this.request<StartExportingViewMutation, StartExportingViewMutationVariables>({
      query: StartExportingViewDocument,
      variables: { id }
    })
  }

  delete(id: number) {
    return this.request<DeleteViewMutation, DeleteViewMutationVariables>({
      query: DeleteViewDocument,
      variables: { id }
    })
  }
}
