<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SPill from 'sefirot/components/SPill.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { useSnackbars } from 'sefirot/stores/Snackbars'
import { maxLength, required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type FundClosingCheckListRecord } from '@/models/FundClosingCheckListRecord'
import { useUpdate } from '@/composables/repos/FundClosingCheckListRecordRepo'

interface Data {
  business: string | null
  personnelStructure: string | null
  capitalTies: string | null
  financialSituation: string | null
  other: string | null
}

const props = defineProps<{
  fundClosingCheckListRecord: FundClosingCheckListRecord
}>()

const snackbars = useSnackbars()

const title = computed(() => props.fundClosingCheckListRecord.company.name)
const funds = computed(() => props.fundClosingCheckListRecord.fundClosingCheckList.funds)

const { data } = useData<Data>({
  business: props.fundClosingCheckListRecord.business,
  personnelStructure: props.fundClosingCheckListRecord.personnelStructure,
  capitalTies: props.fundClosingCheckListRecord.capitalTies,
  financialSituation: props.fundClosingCheckListRecord.financialSituation,
  other: props.fundClosingCheckListRecord.other
})

const { validation, validateAndNotify } = useValidation(data, {
  business: { required: required(), maxLength: maxLength(4000) },
  personnelStructure: { required: required(), maxLength: maxLength(4000) },
  capitalTies: { required: required(), maxLength: maxLength(4000) },
  financialSituation: { required: required(), maxLength: maxLength(4000) },
  other: { required: required(), maxLength: maxLength(4000) }
})

const { execute, loading } = useUpdate(props.fundClosingCheckListRecord.id, createDataForSave)

function createDataForSave() {
  return {
    business: data.value.business,
    personnelStructure: data.value.personnelStructure,
    capitalTies: data.value.capitalTies,
    financialSituation: data.value.financialSituation,
    other: data.value.other
  }
}

async function save() {
  if (await validateAndNotify()) {
    await execute()

    snackbars.push({
      mode: 'info',
      text: 'The Fund Closing Checklist has been saved successfully.'
    })
  }
}
</script>

<template>
  <div class="FundClosingCheckListRecordFormUpdate">
    <div class="container">
      <DForm>
        <DFormTitle class="title">{{ title }}</DFormTitle>
        <div class="funds">
          <SPill
            v-for="fund in funds"
            :key="fund.id"
            class="pill"
            as="div"
            type="dimm"
            mode="info"
            :label="fund.nameAbbreviated"
          />
        </div>
        <DFormSection>
          <DFormSectionTitle>事業面</DFormSectionTitle>
          <DFormSectionLead>
            Describe the continuity of the main business (whether the main business has been discontinued, suspended, transferred, etc.) and the performance momentum of the main business (whether there have been significant changes in KPIs as well as in terms of sales and profits).
          </DFormSectionLead>

          <DFormGrid>
            <DFormGridItem span="6">
              <SInputTextarea
                placeholder="Describe how business is going on."
                :rows="5"
                v-model="data.business"
                :validation="validation.business"
              />
            </DFormGridItem>
          </DFormGrid>
        </DFormSection>

        <DFormSection>
          <DFormSectionTitle>人的体制</DFormSectionTitle>
          <DFormSectionLead>
            Describe whetherthere have been any changes in the executives or employees who are the main promoters of the business (i.e., whether business activities have been hindered by the resignation of key executives, etc.).
          </DFormSectionLead>

          <DFormGrid>
            <DFormGridItem span="6">
              <SInputTextarea
                placeholder="Describe personnel structure."
                :rows="5"
                v-model="data.personnelStructure"
                :validation="validation.personnelStructure"
              />
            </DFormGridItem>
          </DFormGrid>
        </DFormSection>

        <DFormSection>
          <DFormSectionTitle>資本関係</DFormSectionTitle>
          <DFormSectionLead>
            Describe whether there’s any impact on business performance due to significant changes in the capital structure (i.e., whether or not business activities have changed significantly due to capital tie-ups, etc., or whether there’re any changes that would result in deterioration of business performance).
          </DFormSectionLead>

          <DFormGrid>
            <DFormGridItem span="6">
              <SInputTextarea
                placeholder="Describe capital structure."
                :rows="5"
                v-model="data.capitalTies"
                :validation="validation.capitalTies"
              />
            </DFormGridItem>
          </DFormGrid>
        </DFormSection>

        <DFormSection>
          <DFormSectionTitle>資金面</DFormSectionTitle>
          <DFormSectionLead>
            Describe the cash flow confirmation (whether it’s planned in terms of both short and long term) and financing schedule (if any).
          </DFormSectionLead>

          <DFormGrid>
            <DFormGridItem span="6">
              <SInputTextarea
                placeholder="Describe financial situation."
                :rows="5"
                v-model="data.financialSituation"
                :validation="validation.financialSituation"
              />
            </DFormGridItem>
          </DFormGrid>
        </DFormSection>

        <DFormSection>
          <DFormSectionTitle>その他</DFormSectionTitle>
          <DFormSectionLead>
            Describe whether the company have concluded any important contracts, etc. (that may affects the business performance significantly), IPO-related news, or if there have been any changes in the business and fund-raising environment due to COVID-19 and how company is responding to them.
          </DFormSectionLead>
          <DFormSectionLead>
            Even if there's nothign to add, please enter so. e.g. N/A.
          </DFormSectionLead>

          <DFormGrid>
            <DFormGridItem span="6">
              <SInputTextarea
                placeholder="Add any additional information."
                :rows="5"
                v-model="data.other"
                :validation="validation.other"
              />
            </DFormGridItem>
          </DFormGrid>
        </DFormSection>

        <DFormFooter>
          <DFormFooterActions>
            <DFormFooterAction
              mode="info"
              label="Save"
              :loading="loading"
              @click="save"
            />
          </DFormFooterActions>
        </DFormFooter>
      </DForm>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.container {
  max-width: 768px;
}

.funds {
  padding-top: 8px;
}

.pill + .pill {
  margin-left: 4px;
}
</style>
