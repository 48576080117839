<script setup lang="ts">
import IconBuilding from '~icons/ph/buildings'
import { xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { CompanyActionStatus, CompanyOrderField, CompanyPortfolioStatus, OrderDirection } from '@/graphql'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import { useFundCollection } from '@/composables/fund/FundData'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCompanyPage } from '@/composables/repos/CompanyRepo'
import { useCompanySectorList } from '@/composables/repos/CompanySectorRepo'
import { useCountryListCache } from '@/composables/repos/CountryRepo'
import { useUserActiveCollection } from '@/composables/user/UserData'
import DInputSearch from '../DInputSearch.vue'

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    query: null as string | null,
    actionStatus: [] as CompanyActionStatus[],
    portfolioStatus: [] as CompanyPortfolioStatus[],
    portfolioOf: {
      portfolioOfIds: [] as number[],
      includeExPortfolio: false
    },
    assignee: [] as (string | number)[],
    sector: [] as number[],
    country: [] as number[]
  },
  orderBy: {
    field: CompanyOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': Number,
    'condition.query': (v) => (v === '' || v === null) ? null : v,
    'condition.portfolioOf.portfolioOfIds': (v) => v.map(Number),
    'condition.portfolioOf.includeExPortfolio': (v) => Boolean(v),
    'condition.assignee': (v) => v.map((v: string | number) => v !== '__UNASSIGNED__' ? Number(v) : v),
    'condition.sector': (v) => v.map(Number),
    'condition.country': (v) => v.map(Number)
  },
  exclude: ['page.perPage']
})

const queryOptions = computed(() => ({
  page: {
    page: options.value.page.page,
    perPage: options.value.page.perPage
  },
  condition: {
    query: options.value.condition.query,
    actionStatus: options.value.condition.actionStatus,
    portfolioStatus: options.value.condition.portfolioStatus,
    portfolioOf: {
      portfolioOfIds: options.value.condition.portfolioOf.portfolioOfIds,
      includeExPortfolio: options.value.condition.portfolioOf.includeExPortfolio
    },
    assignee: options.value.condition.assignee.filter((id) => id !== '__UNASSIGNED__') as number[],
    unassigned: options.value.condition.assignee.includes('__UNASSIGNED__'),
    sector: options.value.condition.sector,
    country: options.value.condition.country
  },
  orderBy: {
    field: options.value.orderBy.field,
    direction: options.value.orderBy.direction
  }
}))

const { data: companies, loading: loadingCompanies } = useCompanyPage(queryOptions)
const { data: users } = useUserActiveCollection()
const { data: funds } = useFundCollection()
const { data: sectors } = useCompanySectorList()
const { data: countries } = useCountryListCache()

const { canCreate } = useCompanyPolicy()

const userOps = useUserOps()
const companyOps = useCompanyOps()

const dropdownName = createDropdown([
  {
    type: 'menu',
    options: [
      { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(CompanyOrderField.Name, OrderDirection.Asc) } },
      { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(CompanyOrderField.Name, OrderDirection.Desc) } }
    ]
  }
])

const dropdownActionStatus = createDropdown([
  {
    type: 'filter',
    search: false,
    selected: computed(() => options.value.condition.actionStatus),
    options: [
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.Approachable), value: CompanyActionStatus.Approachable },
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.KeepInTouch), value: CompanyActionStatus.KeepInTouch },
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.InProgress), value: CompanyActionStatus.InProgress },
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.Monitoring), value: CompanyActionStatus.Monitoring },
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.Done), value: CompanyActionStatus.Done },
      { label: companyOps.actionStatusTextByValue(CompanyActionStatus.Unapproachable), value: CompanyActionStatus.Unapproachable }
    ],
    onClick: (value) => {
      options.value.condition.actionStatus = xor(options.value.condition.actionStatus, [value])
    }
  }
])

const dropdownPortfolioStatus = createDropdown([
  {
    type: 'filter',
    search: false,
    selected: computed(() => options.value.condition.portfolioStatus),
    options: [
      { label: companyOps.portfolioStatusTextByValue(CompanyPortfolioStatus.Portfolio), value: CompanyPortfolioStatus.Portfolio },
      { label: companyOps.portfolioStatusTextByValue(CompanyPortfolioStatus.ExPortfolio), value: CompanyPortfolioStatus.ExPortfolio },
      { label: companyOps.portfolioStatusTextByValue(CompanyPortfolioStatus.NotPortfolio), value: CompanyPortfolioStatus.NotPortfolio }
    ],
    onClick: (value) => {
      options.value.condition.portfolioStatus = xor(options.value.condition.portfolioStatus, [value])
    }
  }
])

const dropdownPortfolioOf = createDropdown([
  {
    type: 'filter',
    search: false,
    selected: computed(() => {
      return options.value.condition.portfolioOf.includeExPortfolio ? [true] : [false]
    }),
    options: [
      { label: 'Include Ex-portfolio', value: true }
    ],
    onClick: () => {
      options.value.condition.portfolioOf.includeExPortfolio = !options.value.condition.portfolioOf.includeExPortfolio
    }
  },
  {
    type: 'filter',
    search: true,
    selected: computed(() => options.value.condition.portfolioOf.portfolioOfIds),
    options: computed(() => {
      const fixedOptions = options.value.condition.portfolioOf.portfolioOfIds.length > 0
        ? { label: 'Unselect all', value: 'UNSELECT_ALL' }
        : { label: 'Select all', value: 'SELECT_ALL' }

      return [
        fixedOptions,
        ...funds.value?.map((fund) => ({ label: fund.nameAbbreviated + (fund.group?.lp ? ` (${fund.group?.lp})` : ''), value: fund.id! })) ?? []
      ]
    }),
    onClick(value) {
      updateFilter(() => {
        if (value === 'SELECT_ALL') {
          options.value.condition.portfolioOf.portfolioOfIds = funds.value?.map((fund) => fund.id!) ?? []
        } else if (value === 'UNSELECT_ALL') {
          options.value.condition.portfolioOf.portfolioOfIds = []
        } else {
          options.value.condition.portfolioOf.portfolioOfIds = xor(options.value.condition.portfolioOf.portfolioOfIds, [value])
        }
      })
    }
  }
])

const dropdownAssignee = createDropdown([
  {
    type: 'filter',
    search: true,
    selected: computed(() => options.value.condition.assignee),
    options: computed(() => {
      const ua = { label: 'Unassigned', value: '__UNASSIGNED__' }
      const us = users.value?.map((user) => ({ label: user.name, value: user.id! }))
      return [ua, ...us ?? []]
    }),
    onClick(value) {
      updateFilter(() => {
        options.value.condition.assignee = xor(options.value.condition.assignee, [value])
      })
    }
  }
])

const dropdownSector = createDropdown([
  {
    type: 'menu',
    options: [
      { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(CompanyOrderField.SectorName, OrderDirection.Asc) } },
      { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(CompanyOrderField.SectorName, OrderDirection.Desc) } }
    ]
  },
  {
    type: 'filter',
    search: true,
    selected: computed(() => options.value.condition.sector),
    options: computed(() => sectors.value?.map((sector) => {
      return { label: sector.name, value: sector.id! }
    }) ?? []),
    onClick(value) {
      updateFilter(() => {
        options.value.condition.sector = xor(options.value.condition.sector, [value])
      })
    }
  }
])

const dropdownCountry = createDropdown([
  {
    type: 'menu',
    options: [
      { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(CompanyOrderField.CountryName, OrderDirection.Asc) } },
      { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(CompanyOrderField.CountryName, OrderDirection.Desc) } }
    ]
  },
  {
    type: 'filter',
    search: true,
    selected: computed(() => options.value.condition.country),
    options: computed(() => countries.value?.map((country) => {
      return { label: country.name, value: country.id! }
    }) ?? []),
    onClick(value) {
      updateFilter(() => {
        options.value.condition.country = xor(options.value.condition.country, [value])
      })
    }
  }
])

const dropdownUpdatedAt = createDropdown([
  {
    type: 'menu',
    options: [
      { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(CompanyOrderField.UpdatedAt, OrderDirection.Asc) } },
      { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(CompanyOrderField.UpdatedAt, OrderDirection.Desc) } }
    ]
  }
])

const dropdownCreatedAt = createDropdown([
  {
    type: 'menu',
    options: [
      { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(CompanyOrderField.CreatedAt, OrderDirection.Asc) } },
      { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(CompanyOrderField.CreatedAt, OrderDirection.Desc) } }
    ]
  }
])

const table = useTable({
  records: computed(() => companies.value?.items ?? []),
  loading: loadingCompanies,
  borderless: true,
  orders: [
    'name',
    'actionStatus',
    'portfolioStatus',
    'portfolioOf',
    'exit',
    'assignee',
    'sector',
    'country',
    'createdAt',
    'updatedAt',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Name',
      dropdown: dropdownName,
      cell: (_, c) => ({
        type: 'text',
        icon: IconBuilding,
        value: companyOps.name(c),
        link: companyOps.path(c)
      })
    },
    actionStatus: {
      label: 'Status',
      dropdown: dropdownActionStatus,
      cell: (_, c) => ({
        type: 'state',
        mode: companyOps.actionStatusMode(c),
        label: companyOps.actionStatusText(c)
      })
    },
    portfolioStatus: {
      label: 'Is portfolio',
      dropdown: dropdownPortfolioStatus,
      cell(_, c) {
        return companyOps.isNotPortofolio(c)
          ? { type: 'empty' }
          : { type: 'state', mode: companyOps.portfolioStatusMode(c), label: companyOps.portfolioStatusText(c) }
      }
    },
    portfolioOf: {
      label: 'Portfolio of',
      dropdown: dropdownPortfolioOf,
      cell: (_, c) => ({
        type: 'pills',
        pills: companyOps.allPortfolioOf(c).map((f) => ({
          label: f.nameAbbreviated
        }))
      })
    },
    exit: {
      label: 'Exit',
      cell: (_, c) => ({
        type: 'pill',
        color: 'success',
        value: c.ipo ? 'IPO' : ''
      })
    },
    assignee: {
      label: 'Assignee',
      dropdown: dropdownAssignee,
      cell: (_, c) => ({
        type: 'avatars',
        avatars: companyOps.assignees(c).map((u) => ({
          image: userOps.avatarPath(u),
          name: userOps.name1st(u)
        }))
      })
    },
    sector: {
      label: 'Sector',
      dropdown: dropdownSector,
      cell: (_, c) => ({
        type: 'text',
        value: c.primarySector.name
      })
    },
    country: {
      label: 'Country',
      dropdown: dropdownCountry,
      cell: (_, c) => ({
        type: 'text',
        value: c.country.name
      })
    },
    updatedAt: {
      label: 'Updated at',
      dropdown: dropdownUpdatedAt,
      cell: (_, c) => ({
        type: 'day',
        value: companyOps.updatedAt(c),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    createdAt: {
      label: 'Created at',
      dropdown: dropdownCreatedAt,
      cell: (_, c) => ({
        type: 'day',
        value: companyOps.createdAt(c),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function updateQuery(value: string | null) {
  options.value.page.page = 0
  options.value.condition.query = value === '' ? null : value
}

function updateFilter(fn: () => void) {
  options.value.page.page = 0
  fn()
}

function updateOrder(field: CompanyOrderField, direction: OrderDirection) {
  options.value.page.page = 0
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}
</script>

<template>
  <div class="CompanyCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                placeholder="Search companies"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              label="Reset filters"
              @click="reset"
            />
          </SControlLeft>
          <SControlRight>
            <SControlButton
              v-if="canCreate"
              mode="info"
              label="New company"
              href="/companies/create"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="companies?.pageInfo.total ?? 0"
              :page="companies ? companies.pageInfo.page + 1 : 0"
              :per-page="companies?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.CompanyCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)            { --table-col-width: 288px; }
.list :deep(.col-actionStatus)    { --table-col-width: 160px; }
.list :deep(.col-portfolioStatus) { --table-col-width: 144px; }
.list :deep(.col-portfolioOf)     { --table-col-width: 128px; }
.list :deep(.col-exit)            { --table-col-width: 96px; }
.list :deep(.col-assignee)        { --table-col-width: 192px; }
.list :deep(.col-sector)          { --table-col-width: 160px; }
.list :deep(.col-country)         { --table-col-width: 128px; }
.list :deep(.col-createdAt)       { --table-col-width: 192px; }
.list :deep(.col-updatedAt)       { --table-col-width: 192px; }
.list :deep(.col-spacer)          { --table-col-width: 40px; }
</style>
