import { orderBy } from 'lodash-es'
import { type CompanyVECSector } from '@/graphql'
import { CompanyVecSectorRequest } from '@/requests/CompanyVecSectorRequest'
import { type Query, useQuery } from '../Api'

export type CompanyVecSectorList = Query<CompanyVECSector[], []>

export function useCompanyVecSectorList(): CompanyVecSectorList {
  return useQuery(async () => {
    const { data: { companyVECSectors } } = await new CompanyVecSectorRequest().fetchAll()

    return orderBy(companyVECSectors, (c) => c.rank)
  })
}
