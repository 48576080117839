<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import { useDealForReports } from '@/composables/repos/DealRepo'
import DealPageReports from '@/components/deal/DealPageReports.vue'

const route = useRoute()
const id = Number(route.params.id)

const { data: deal, execute } = useDealForReports(id)

const { canView } = useDealPolicy(deal)

const dealOps = useDealOps()

const title = computedWhen(deal, (deal) => {
  return `${dealOps.fullTitle(deal)} - Reports`
})
</script>

<template>
  <DPage class="DealsIdReports" :title="title" :authorize="canView">
    <DealPageReports :deal="deal!" @updated="execute" />
  </DPage>
</template>
