<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type User } from '@/models/User'
import { type View } from '@/models/View'
import { plural } from '@/support/Str'
import ViewHeaderFormSharedUserAdd from './ViewHeaderFormSharedUserAdd.vue'

const props = defineProps<{
  view?: View | null
  filteredSharedUsers: User[]
}>()

defineEmits<{
  (e: 'added'): void
}>()

const { state: modal, on, off } = usePower()

const total = computed(() => {
  return `${plural(['user is', 'users are'], props.filteredSharedUsers.length)} shared with this view.`
})
</script>

<template>
  <div class="ViewHeaderFormSharedUserRecordHeader">
    <div class="title">
      <p class="total">{{ total }}</p>
    </div>

    <div class="actions">
      <button
        class="action"
        @click="() => on()"
      >
        <IconPlus class="icon-svg" />
      </button>
    </div>
    <SModal :open="modal" @close="off">
      <ViewHeaderFormSharedUserAdd
        :view="view"
        @added="off(() => $emit('added'))"
        @cancel="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.ViewHeaderFormSharedUserRecordHeader {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 12px 0 11px;
  border-bottom: 1px solid var(--c-divider-light);
  border-radius: 8px 8px 0 0;
  background-color: var(--c-bg-elv);
  transition: background-color .5s;
}

.title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 16px;
  min-height: 24px;
}

.total {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.actions {
  padding: 0 8px;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  transition: color 0.25s, background-color 0.25s;
  color: var(--c-text-2);

  &:hover {
    color: var(--c-text-1);
    background-color: var(--c-bg-elv-down);
  }
}
</style>
