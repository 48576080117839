<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { computed, reactive } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'
import { useAddOpportunityRound } from '@/composables/repos/OpportunityRepo'
import { useRoundNameList } from '@/composables/repos/RoundNameRepo'
import DInputMoney from '../DInputMoney.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'added'): void
}>()

const { data: rounds } = useRoundNameList()
const { data: currencies } = useCurrencyList()

const addRoundMutation = reactive(useAddOpportunityRound())

const { data } = useData({
  roundName: null as number | null,
  nameSuffix: null as string | null,
  schedule: null as string | null,
  currency: null as number | null,
  totalAmount: null as string | null,
  preValuation: null as string | null,
  postValuation: null as string | null,
  preIssuedShares: null as string | null,
  participatingInvestors: null as string | null,
  notes: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  roundName: { required: required() },
  nameSuffix: { maxLength: maxLength(10) },
  schedule: { required: required(), maxLength: maxLength(40) },
  currency: { required: required() },
  totalAmount: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  preValuation: { decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  postValuation: { decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  preIssuedShares: { maxLength: maxLength(500) },
  participatingInvestors: { maxLength: maxLength(500) },
  notes: { maxLength: maxLength(500) }
})

const currencyModel = computed(() => {
  return currencies.value?.find((c) => c.id === data.value.currency) ?? null
})

const roundOptions = computed<Option[]>(() => {
  return rounds.value?.map((r) => {
    return { label: r.name!, value: r.id!, disabled: !r.enabled }
  }) ?? []
})

const currencyOptions = computed<Option[]>(() => {
  return currencies.value?.map((c) => {
    return { label: c.name, value: c.id }
  }) ?? []
})

async function add() {
  if (await validateAndNotify()) {
    await addRoundMutation.execute(props.opportunity.id, {
      name: data.value.roundName!,
      nameSuffix: data.value.nameSuffix,
      schedule: data.value.schedule!,
      totalAmount: createMoneyInput(data.value.totalAmount)!,
      preValuation: createMoneyInput(data.value.preValuation),
      postValuation: createMoneyInput(data.value.postValuation),
      preIssuedShares: data.value.preIssuedShares!,
      participatingInvestors: data.value.participatingInvestors,
      notes: data.value.notes
    })
    emit('added')
  }
}

function createMoneyInput(value: string | null) {
  return value ? { currencyId: data.value.currency!, value } : null
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Add a round to this opportunity</h2>
        </SContent>
        <SGrid cols="6" gap="24">
          <SGridItem span="2">
            <SInputDropdown
              label="Round name *"
              placeholder="Select round name"
              :options="roundOptions"
              v-model="data.roundName"
              :validation="validation.roundName"
            />
          </SGridItem>
          <SGridItem span="2">
            <SInputText
              label="Name suffix"
              placeholder="’ / 1 / 2nd"
              v-model="data.nameSuffix"
              :validation="validation.nameSuffix"
            />
          </SGridItem>
          <SGridItem span="2" />
          <SGridItem span="6">
            <SInputText
              label="Schedule *"
              placeholder="Enter start/end schedule of the round"
              v-model="data.schedule"
              :validation="validation.schedule"
            />
          </SGridItem>
          <SGridItem span="2">
            <SInputDropdown
              label="Currency *"
              placeholder="Select a currency"
              :options="currencyOptions"
              v-model="data.currency"
              :validation="validation.currency"
            />
          </SGridItem>
          <SGridItem span="4" />
          <SGridItem span="3">
            <DInputMoney
              label="Total amount *"
              placeholder="500,000,000"
              :currency="currencyModel"
              v-model="data.totalAmount"
              :validation="validation.totalAmount"
            />
          </SGridItem>
          <SGridItem span="3" />
          <SGridItem span="3">
            <DInputMoney
              label="Pre valuation"
              placeholder="2,000,000,000"
              :currency="currencyModel"
              v-model="data.preValuation"
              :validation="validation.preValuation"
            />
          </SGridItem>
          <SGridItem span="3">
            <DInputMoney
              label="Post valuation"
              placeholder="2,500,000,000"
              :currency="currencyModel"
              v-model="data.postValuation"
              :validation="validation.postValuation"
            />
          </SGridItem>
          <SGridItem span="6">
            <SInputTextarea
              label="Pre issued shares"
              :placeholder="'Common 10,000\nSeries Seed Preferred 2,234\nSeries Seed 2 Preferred 9,000\nSeries A Preferred 5,000'"
              :rows="5"
              v-model="data.preIssuedShares"
              :validation="validation.preIssuedShares"
            />
          </SGridItem>
          <SGridItem span="6">
            <SInputTextarea
              label="Participating investors"
              placeholder="Which investors are involved in this round?"
              :rows="5"
              v-model="data.participatingInvestors"
              :validation="validation.participatingInvestors"
            />
          </SGridItem>
          <SGridItem span="6">
            <SInputTextarea
              label="Notes"
              placeholder="Add any comments for this round there’s any."
              :rows="5"
              v-model="data.notes"
              :validation="validation.notes"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="addRoundMutation.loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            label="Add round"
            :loading="addRoundMutation.loading"
            @click="add"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
