<script setup lang="ts">
import { getAuth } from 'firebase/auth'
import SSnackbars from 'sefirot/components/SSnackbars.vue'
import { provideLang } from 'sefirot/composables/Lang'
import { Http } from 'sefirot/http/Http'
import { useError } from 'sefirot/stores/Error'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useMe } from '@/composables/Auth'
import ErrorPage from '../error/ErrorPage.vue'
import LayoutMainHeader from './LayoutMainHeader.vue'

const route = useRoute()
const error = useError()

const { user } = useMe()

Http.config({
  baseUrl: import.meta.env.API_BASE_URL,
  xsrfUrl: false,
  headers: async () => ({
    Authorization: `Bearer ${(await getAuth().currentUser?.getIdToken()) || ''}`
  }),
  stringifyOptions: { arrayFormat: 'brackets' }
})

watch(() => route.path, () => error.clear())

provideLang(user.settings.isLangJa() ? 'ja' : 'en')
</script>

<template>
  <div class="LayoutMain">
    <LayoutMainHeader />
    <div class="container">
      <ErrorPage v-if="error.data" :error="error.data" />
      <RouterView v-else v-slot="{ Component }">
        <Suspense>
          <div class="main">
            <component :is="Component" />
          </div>
        </Suspense>
      </RouterView>
    </div>
    <SSnackbars />
  </div>
</template>

<style lang="postcss" scoped>
.LayoutMain {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
}

.container,
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 100vw;
  min-height: 100vh;
}
</style>
