<script setup lang="ts">
import IconActivity from '~icons/ph/activity'
import IconNotebook from '~icons/ph/notebook'
import IconDrive from '~icons/ri/drive-fill'
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { type Opportunity } from '@/models/Opportunity'
import { useListener } from '@/composables/Channel'
import { useOpportunityItemForLocalNav } from '@/composables/opportunity/OpportunityData'
import { useOpportunityPolicy } from '@/composables/opportunity/OpportunityPolicy'

const route = useRoute()

const { data: oppo, execute } = useOpportunityItemForLocalNav(Number(route.params.id))

useListener('round-name-updated', execute)

const { canView } = useOpportunityPolicy(oppo)

const title = computedArrayWhen(oppo, (items, o) => {
  items.push(
    { text: o.company!.name, link: o.company!.path },
    o.title
  )
})

const actions = computedArrayWhen(oppo, (items, o) => {
  if (o.company!.driveId) {
    items.push({ icon: IconDrive, label: 'Google Drive', href: `https://drive.google.com/drive/folders/${o.company!.driveId}` })
  }
})

const tags = computedArrayWhen(oppo, (items, o) => {
  items.push(
    {
      icon: IconActivity,
      label: o.statusValue,
      color: getStatusColor(o)
    }
  )
})

const menu = computedArrayWhen(oppo, (items, o) => {
  items.push(
    { icon: IconNotebook, label: 'Details', link: `${o.path}/details` }
  )
})

function getStatusColor(oppo: Opportunity) {
  if (oppo.isOpen()) {
    return 'info'
  } else if (oppo.isInProgress()) {
    return 'success'
  } else {
    return 'mute'
  }
}
</script>

<template>
  <DView
    class="OpportunitiesId"
    :loading="!oppo"
    :hide="!canView"
    :title="title"
    :actions="actions"
    :tags="tags"
    :menu="menu"
  />
</template>
