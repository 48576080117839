<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SInputYMD from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { createYmd } from 'sefirot/support/Day'
import { maxLength, maxValue, required, rule, url, ymd } from 'sefirot/validation/rules'
import { computed, ref } from 'vue'
import { type Company } from '@/graph/Company'
import { type GeneratedCreateCompanyInput } from '@/graphql'
import { useMe } from '@/composables/Auth'
import { useCompanyMarketingModelList } from '@/composables/repos/CompanyMarketingModelRepo'
import { useCheckDuplicateCompanies, useCreateCompany } from '@/composables/repos/CompanyRepo'
import { useCompanySectorList } from '@/composables/repos/CompanySectorRepo'
import { useGetGeneratedCreateCompanyInputItem } from '@/composables/repos/GeneratedCreateCompanyInputRepo'
import DInputCountry from '../DInputCountry.vue'
import DInputDropdownUsers from '../DInputDropdownUsers.vue'
import DInputMonth from '../DInputMonth.vue'
import CompanyFormCreateAiError from './CompanyFormCreateAiError.vue'
import CompanyFormCreateAiLoading from './CompanyFormCreateAiLoading.vue'
import CompanyFormCreateDuplicate from './CompanyFormCreateDuplicate.vue'
import CompanyFormCreateFillByAi from './CompanyFormCreateFillByAi.vue'

const emit = defineEmits<{
  created: [companyId: number]
}>()

const { t } = useTrans({
  en: {
    i_display_name_label: 'Display name *',
    i_display_name_ph: 'Example Company',
    i_official_name_local_label: 'Official name *',
    i_official_name_local_ph: 'Example Company, Inc.',
    i_official_name_en_label: 'English name *',
    i_official_name_en_ph: 'Example Company, Inc.',

    i_pic_label: 'Primary in-charge *',
    i_dic_label: 'Deputy in-charge',
    i_participants_label: 'Participants',

    i_website_label: 'Website',
    i_website_ph: 'https://example.com',
    i_founded_at_label: 'Founded at',
    i_fiscal_month_label: 'Fiscal month',
    i_fiscal_month_ph: 'Select a month',
    i_country_label: 'Country *',
    i_country_ph: 'Select a country',
    i_zip_label: 'ZIP Code',
    i_zip_ph: '1500031',
    i_address_label: 'Address',
    i_address_ph: '10-11 Sakuragaoka-cho, Shibuya-ku, Tokyo',
    i_house_number_label: 'House number',
    i_house_number_ph: 'SHIBUYA AXSH 7F',

    i_active_countries_label: 'Active countries',
    i_active_countries_ph: 'Select countries',
    i_active_countries_help: 'Select countries where the company is actively doing business.',
    i_num_employees_label: 'Number of employees',
    i_num_employees_ph: '100',
    i_marketing_model_label: 'Marketing model *',
    i_marketing_model_ph: 'Select a marketing model',
    i_primary_sector_label: 'Primary sector *',
    i_primary_sector_ph: 'Select a sector',
    i_sub_sectors_label: 'Sub sectors',
    i_sub_sectors_ph: 'Select sectors',
    i_description_label: 'Description *',
    i_description_ph: 'e.g. Global Brain is an independent venture capital firm based in Tokyo, Japan. It supports startups globally through hands-on support, a global ecosystem, and open innovation between startups and major corporations. The firm specializes in seed, early, and later-stage investments.',

    i_representative_name_label: 'Representative name *',
    i_representative_name_ph: 'John Doe',
    i_representative_title_label: 'Representative title',
    i_representative_title_ph: 'CEO',
    i_representative_profiles_label: 'Representative profiles',
    i_representative_profiles_ph: 'e.g. John Doe is the Founder and CEO of Example Company, leading the firm in its mission to support startups and foster innovation globally.',
    i_key_personnel_notes_label: 'Key personnel notes',
    i_key_personnel_notes_ph: [
      'e.g.',
      'Person A: Serial entrepreneur with a background in technology and finance.',
      'Person B: Seasoned executive with experience in leading global teams.'
    ].join('\n'),

    e_no_legal_suffix: 'Legal suffix is not allowed.',
    e_same_as_pic: 'Already assigned as Primary in-charge.',
    e_same_as_dic: 'Already assigned as Deputy in-charge.',
    e_same_as_primary_sector: 'Already assigned as Primary sector.',

    w_maybe_legal_suffix: (v: string) => `“${v}” may be a legal suffix. Enter the company name without a legal suffix in the Display name. If this is not a legal suffix but the company name, you can create the company as is.`,

    a_create: 'Create company'
  },
  ja: {
    i_display_name_label: 'Display name *',
    i_display_name_ph: 'Example Company',
    i_official_name_local_label: 'Official name *',
    i_official_name_local_ph: '株式会社Example Company',
    i_official_name_en_label: 'English name *',
    i_official_name_en_ph: 'Example Company, Inc.',

    i_pic_label: 'Primary in-charge *',
    i_dic_label: 'Deputy in-charge',
    i_participants_label: 'Participants',

    i_website_label: 'ウェブサイト',
    i_website_ph: 'https://example.com',
    i_founded_at_label: '創業年月',
    i_fiscal_month_label: '決算月',
    i_fiscal_month_ph: '月を選択してください',
    i_country_label: '所在国 *',
    i_country_ph: '国を選択してください',
    i_zip_label: '郵便番号',
    i_zip_ph: '150-0031',
    i_address_label: '住所',
    i_address_ph: '東京都渋谷区桜丘町10-11',
    i_house_number_label: '建物名',
    i_house_number_ph: '渋谷アクシュ 7F',

    i_active_countries_label: '事業を展開している国',
    i_active_countries_ph: '国を選択してください',
    i_active_countries_help: '当社の所在国に関わらず、実際に事業展開している国を選択してください。',
    i_num_employees_label: '従業員数',
    i_num_employees_ph: '100',
    i_marketing_model_label: 'マーケティングモデル *',
    i_marketing_model_ph: 'モデルを選択してください',
    i_primary_sector_label: 'Primary sector *',
    i_primary_sector_ph: 'セクターを選択してください',
    i_sub_sectors_label: 'Sub sectors',
    i_sub_sectors_ph: 'セクターを選択してください',
    i_description_label: '企業概要 *',
    i_description_ph: '例: Global Brainは、東京を拠点とする独立系ベンチャーキャピタルファームです。グローバルなエコシステム、スタートアップと大手企業のオープンイノベーション、ハンズオンサポートを通じて、世界中のスタートアップを支援しています。同社は、シード、アーリー、レーターステージの投資を専門としています。',

    i_representative_name_label: '代表者名 *',
    i_representative_name_ph: '山田 太郎',
    i_representative_title_label: '代表者役職',
    i_representative_title_ph: '代表取締役社長',
    i_representative_profiles_label: '代表者プロフィール',
    i_representative_profiles_ph: '例: 山田 太郎は、Example Companyの創業者兼CEOであり、スタートアップを支援し、グローバルにイノベーションを促進しています。',
    i_key_personnel_notes_label: 'キーパーソンのメモ',
    i_key_personnel_notes_ph: [
      '例:',
      'Aさん: テクノロジーとファイナンスのバックグラウンドを持つシリアルアントレプレナー。',
      'Bさん: グローバルチームを率いる経験豊富な幹部。'
    ].join('\n'),

    e_no_legal_suffix: '株式会社、Inc.などの法人種別は使用できません。',
    e_same_as_pic: 'すでにPrimary in-chargeとして設定されています。',
    e_same_as_dic: 'すでにDeputy in-chargeとして設定されています。',
    e_same_as_primary_sector: 'すでにPrimary sectorとして設定されています。',

    w_maybe_legal_suffix: (v: string) => `「${v}」は法人種別の可能性があります。Display nameでは法人種別を省いた社名を入力してください。これが法人種別ではなく社名の場合はこのまま企業を作成して問題ありません。`,

    a_create: 'Companyを作成'
  }
})

const { user: me } = useMe()
const { data: marketingModels } = useCompanyMarketingModelList()
const { data: sectors } = useCompanySectorList()

const { execute: getGeneratedInput } = useGetGeneratedCreateCompanyInputItem()
const checkDuplicateCompanies = useCheckDuplicateCompanies()

const createCompany = useCreateCompany()

const aiLoadingDialog = usePower()
const aiErrorDialog = usePower()
const duplicateDialog = usePower()

const isDialogOpen = computed(() => {
  return aiLoadingDialog.state.value || aiErrorDialog.state.value || duplicateDialog.state.value
})

const isCreating = computed(() => {
  return createCompany.loading.value || checkDuplicateCompanies.loading.value
})

const currentStep = ref<'fill-by-ai' | 'create'>('fill-by-ai')

const detectedDuplicates = ref<Company[]>([])

const displayNameBlockListJa = ['株式会社', '合同会社', '合資会社']
const displayNameBlockListEn = [', Co.', ', Ltd.', ', Co. Ltd.', ', Co., Ltd.', ', Inc.', ', Corp.']
const displayNameWarningList = ['co', 'ltd', 'inc', 'corp', 'corporation', 'limited']

const { data } = useData({
  displayName: null as string | null,
  officialName: null as string | null,
  englishName: null as string | null,
  primaryInChargeId: me.id!,
  deputyInChargeId: null as number | null,
  participantIds: [] as number[],
  website: null as string | null,
  founded: createYmd(),
  fiscalMonth: null as number | null,
  countryId: null as number | null,
  zipcode: null as string | null,
  address: null as string | null,
  addressHouseNumber: null as string | null,
  activeCountryIds: [] as number[],
  numEmployees: null as number | null,
  marketingModelId: null as number | null,
  primarySectorId: null as number | null,
  subSectorIds: [] as number[],
  description: null as string | null,
  representativeName: null as string | null,
  representativeTitle: null as string | null,
  representativeProfiles: null as string | null,
  keyPersonnelNotes: null as string | null
})

const { data: fillByAiData } = useData({
  url: null as string | null,
  countryId: null as number | null
})

const { validation, validateAndNotify } = useValidation(data, {
  displayName: {
    required: required(),
    maxLength: maxLength(100),
    noLegalSuffix: rule((value) => {
      const v = value as string
      return (
        displayNameBlockListJa.every((n) => !v.includes(n))
        && displayNameBlockListEn.every((n) => !v.endsWith(n))
      )
    }, t.e_no_legal_suffix)
  },
  officialName: {
    required: required(),
    maxLength: maxLength(100)
  },
  englishName: {
    required: required(),
    maxLength: maxLength(100)
  },
  deputyInChargeId: {
    notSameAs: rule((value) => value !== data.value.primaryInChargeId, t.e_same_as_pic)
  },
  participantIds: {
    notSameAsPic: rule((value) => !((value as unknown[]).includes(data.value.primaryInChargeId)), t.e_same_as_pic),
    notSameAsDic: rule((value) => data.value.deputyInChargeId ? !((value as unknown[]).includes(data.value.deputyInChargeId)) : true, t.e_same_as_dic)
  },
  website: {
    url: url(),
    maxLength: maxLength(255)
  },
  founded: {
    ymd: ymd(['y', 'm'])
  },
  countryId: {
    required: required()
  },
  zipcode: {
    maxLength: maxLength(10)
  },
  address: {
    maxLength: maxLength(255)
  },
  addressHouseNumber: {
    maxLength: maxLength(255)
  },
  numEmployees: {
    maxValue: maxValue(1000000)
  },
  marketingModelId: {
    required: required()
  },
  primarySectorId: {
    required: required()
  },
  subSectorIds: {
    notSameAs: rule(
      (value) => data.value.primarySectorId ? !((value as unknown[]).includes(data.value.primarySectorId)) : true,
      t.e_same_as_primary_sector
    )
  },
  description: {
    required: required(),
    maxLength: maxLength(3000)
  },
  representativeName: {
    required: required(),
    maxLength: maxLength(70)
  },
  representativeTitle: {
    maxLength: maxLength(100)
  },
  representativeProfiles: {
    maxLength: maxLength(500)
  },
  keyPersonnelNotes: {
    maxLength: maxLength(500)
  }
})

const maybeDisplayNameContainsLegalSuffix = computed(() => {
  if (!data.value.displayName) {
    return undefined
  }
  const regex = new RegExp(`(${displayNameWarningList.join('|')})$`, 'i')
  const match = data.value.displayName.match(regex)
  return match ? t.w_maybe_legal_suffix(match[1]) : undefined
})

const marketingModelOptions = computed<Option[]>(() => {
  return marketingModels.value?.map((m) => {
    return { type: 'text', label: m.name!, value: m.id!, disabled: !m.enabled }
  }) ?? []
})

const sectorOptions = computed<Option[]>(() => {
  return sectors.value?.map((s) => {
    return { type: 'text', label: s.name!, value: s.id!, disabled: !s.enabled }
  }) ?? []
})

async function onFillByAi() {
  currentStep.value = 'fill-by-ai'

  aiLoadingDialog.on()

  detectedDuplicates.value = await checkDuplicateCompanies.execute({
    displayName: null,
    officialNameLocal: null,
    officialNameEn: null,
    website: fillByAiData.value.url
  })

  if (detectedDuplicates.value.length > 0) {
    aiLoadingDialog.off()
    duplicateDialog.on()
    return
  }

  doFillByAi()
}

async function doFillByAi() {
  try {
    aiLoadingDialog.on()
    const res = await getGeneratedInput(fillByAiData.value.url!, fillByAiData.value.countryId!)
    assignValuesToData(fillByAiData.value.url!, res)
    aiLoadingDialog.off()
  } catch (e) {
    aiLoadingDialog.off()
    aiErrorDialog.on()
  }
}

function assignValuesToData(url: string, values: GeneratedCreateCompanyInput) {
  data.value.displayName = values.displayName
  data.value.officialName = values.officialNameLocal
  data.value.englishName = values.officialNameEn
  data.value.website = url
  data.value.founded = { year: values.foundedYear, month: values.foundedMonth, date: null }
  data.value.fiscalMonth = values.fiscalMonth
  data.value.countryId = values.countryId
  data.value.zipcode = values.zipcode
  data.value.address = values.address
  data.value.addressHouseNumber = values.addressHouseNumber
  data.value.activeCountryIds = values.activeCountryIds
  data.value.numEmployees = values.numEmployees
  data.value.marketingModelId = values.marketingModelId
  data.value.primarySectorId = values.primarySectorId
  data.value.subSectorIds = values.subSectorIds
  data.value.description = values.description
  data.value.representativeName = values.representativeName
  data.value.representativeTitle = values.representativeTitle
  data.value.representativeProfiles = values.representativeProfiles
  data.value.keyPersonnelNotes = values.keyPersonnelNotes
}

async function onCreate() {
  if (await validateAndNotify()) {
    currentStep.value = 'create'

    detectedDuplicates.value = await checkDuplicateCompanies.execute({
      displayName: data.value.displayName,
      officialNameLocal: data.value.officialName,
      officialNameEn: data.value.englishName,
      website: data.value.website
    })

    if (detectedDuplicates.value.length > 0) {
      duplicateDialog.on()
      return
    }

    doCreate()
  }
}

async function doCreate() {
  const company = await createCompany.execute({
    name: {
      displayName: data.value.displayName!,
      officialNameLocal: data.value.officialName!,
      officialNameEn: data.value.englishName!
    },
    countryId: data.value.countryId!,
    activeCountryIds: data.value.activeCountryIds,
    primaryInChargeId: data.value.primaryInChargeId,
    deputyInChargeId: data.value.deputyInChargeId,
    participantIds: data.value.participantIds,
    marketingModelId: data.value.marketingModelId!,
    primarySectorId: data.value.primarySectorId!,
    subSectorIds: data.value.subSectorIds,
    description: data.value.description!,
    numEmployees: data.value.numEmployees,
    representativeName: data.value.representativeName!,
    representativeTitle: data.value.representativeTitle,
    representativeProfiles: data.value.representativeProfiles,
    keyPersonnelNotes: data.value.keyPersonnelNotes,
    foundedYear: data.value.founded.year,
    foundedMonth: data.value.founded.month,
    fiscalMonth: data.value.fiscalMonth,
    website: data.value.website,
    zipcode: data.value.zipcode,
    address: data.value.address,
    addressHouseNumber: data.value.addressHouseNumber
  })
  emit('created', company.id)
}

function onModalClose() {
  if (!aiLoadingDialog.state.value || !duplicateDialog.state.value) {
    aiErrorDialog.off()
  }
}

function onDuplicateCheckProceed() {
  duplicateDialog.off()
  currentStep.value === 'fill-by-ai' ? doFillByAi() : doCreate()
}
</script>

<template>
  <SCard class="CompanyFormCreate">
    <SCardBlock class="s-p-48">
      <SDoc>
        <SDocSection class="header">
          <SContent>
            <STrans lang="en">
              <h1>Create a company</h1>
            </STrans>
            <STrans lang="ja">
              <h1>Companyを作成する</h1>
            </STrans>
          </SContent>
          <CompanyFormCreateFillByAi
            v-model:url="fillByAiData.url"
            v-model:country-id="fillByAiData.countryId"
            @execute="onFillByAi"
          />
        </SDocSection>
        <SDivider />
        <SDocSection class="company-name">
          <SContent>
            <STrans lang="en">
              <h2>Company name</h2>
            </STrans>
            <STrans lang="ja">
              <h2>企業名</h2>
            </STrans>
          </SContent>
          <SInputText
            :label="t.i_display_name_label"
            :placeholder="t.i_display_name_ph"
            v-model="data.displayName"
            :validation="validation.displayName"
            :warning="maybeDisplayNameContainsLegalSuffix"
          />
          <SInputText
            :label="t.i_official_name_local_label"
            :placeholder="t.i_official_name_local_ph"
            v-model="data.officialName"
            :validation="validation.officialName"
          />
          <SInputText
            :label="t.i_official_name_en_label"
            :placeholder="t.i_official_name_en_ph"
            v-model="data.englishName"
            :validation="validation.englishName"
          />
        </SDocSection>
        <SDivider />
        <SDocSection class="assignees">
          <SContent>
            <STrans lang="en">
              <h2>Assignees</h2>
            </STrans>
            <STrans lang="ja">
              <h2>担当者</h2>
            </STrans>
          </SContent>
          <SGrid cols="2" gap="24">
            <SGridItem span="1">
              <DInputDropdownUsers
                :label="t.i_pic_label"
                v-model="data.primaryInChargeId"
              />
            </SGridItem>
            <SGridItem span="1">
              <DInputDropdownUsers
                :label="t.i_dic_label"
                nullable
                v-model="data.deputyInChargeId"
                :validation="validation.deputyInChargeId"
              />
            </SGridItem>
            <SGridItem span="2">
              <DInputDropdownUsers
                :label="t.i_participants_label"
                nullable
                v-model="data.participantIds"
                :validation="validation.participantIds"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="info">
          <SContent>
            <STrans lang="en">
              <h2>Company information</h2>
            </STrans>
            <STrans lang="ja">
              <h2>企業情報</h2>
            </STrans>
          </SContent>
          <SGrid cols="3" gap="24">
            <SGridItem span="3">
              <SInputText
                :label="t.i_website_label"
                :placeholder="t.i_website_ph"
                v-model="data.website"
                :validation="validation.website"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputYMD
                :label="t.i_founded_at_label"
                no-date
                block
                v-model="data.founded"
                :validation="validation.founded"
              />
            </SGridItem>
            <SGridItem span="1">
              <DInputMonth
                :label="t.i_fiscal_month_label"
                :placeholder="t.i_fiscal_month_ph"
                nullable
                v-model="data.fiscalMonth"
              />
            </SGridItem>
            <SGridItem span="1" />
            <SGridItem span="1">
              <DInputCountry
                :label="t.i_country_label"
                :placeholder="t.i_country_ph"
                v-model="data.countryId"
                :validation="validation.countryId"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputText
                :label="t.i_zip_label"
                :placeholder="t.i_zip_ph"
                v-model="data.zipcode"
                :validation="validation.zipcode"
              />
            </SGridItem>
            <SGridItem span="1" />
            <SGridItem span="3">
              <SInputText
                :label="t.i_address_label"
                :placeholder="t.i_address_ph"
                v-model="data.address"
                :validation="validation.address"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputText
                :label="t.i_house_number_label"
                :placeholder="t.i_house_number_ph"
                v-model="data.addressHouseNumber"
                :validation="validation.addressHouseNumber"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="business">
          <SContent>
            <STrans lang="en">
              <h2>Business information</h2>
            </STrans>
            <STrans lang="ja">
              <h2>事業情報</h2>
            </STrans>
          </SContent>
          <SGrid cols="3" gap="24">
            <SGridItem span="3">
              <DInputCountry
                :label="t.i_active_countries_label"
                :placeholder="t.i_active_countries_ph"
                nullable
                v-model="data.activeCountryIds"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputNumber
                :label="t.i_num_employees_label"
                :placeholder="t.i_num_employees_ph"
                separator
                v-model="data.numEmployees"
                :validation="validation.numEmployees"
              />
            </SGridItem>
            <SGridItem span="2" />
            <SGridItem span="2">
              <SInputDropdown
                :label="t.i_marketing_model_label"
                :placeholder="t.i_marketing_model_ph"
                :options="marketingModelOptions"
                v-model="data.marketingModelId"
                :validation="validation.marketingModelId"
              />
            </SGridItem>
            <SGridItem span="2">
              <SInputDropdown
                :label="t.i_primary_sector_label"
                :placeholder="t.i_primary_sector_ph"
                :options="sectorOptions"
                v-model="data.primarySectorId"
                :validation="validation.primarySectorId"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputDropdown
                :label="t.i_sub_sectors_label"
                :placeholder="t.i_sub_sectors_ph"
                :options="sectorOptions"
                nullable
                v-model="data.subSectorIds"
                :validation="validation.subSectorIds"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputTextarea
                :label="t.i_description_label"
                :placeholder="t.i_description_ph"
                :rows="8"
                v-model="data.description"
                :validation="validation.description"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="executive">
          <SContent>
            <STrans lang="en">
              <h2>Executive information</h2>
            </STrans>
            <STrans lang="ja">
              <h2>経営陣情報</h2>
            </STrans>
          </SContent>
          <SGrid cols="2" gap="24">
            <SGridItem span="1">
              <SInputText
                :label="t.i_representative_name_label"
                :placeholder="t.i_representative_name_ph"
                v-model="data.representativeName"
                :validation="validation.representativeName"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputText
                :label="t.i_representative_title_label"
                :placeholder="t.i_representative_title_ph"
                v-model="data.representativeTitle"
                :validation="validation.representativeTitle"
              />
            </SGridItem>
            <SGridItem span="2">
              <SInputTextarea
                :label="t.i_representative_profiles_label"
                :placeholder="t.i_representative_profiles_ph"
                v-model="data.representativeProfiles"
                :validation="validation.representativeProfiles"
              />
            </SGridItem>
            <SGridItem span="2">
              <SInputTextarea
                :label="t.i_key_personnel_notes_label"
                :placeholder="t.i_key_personnel_notes_ph"
                v-model="data.keyPersonnelNotes"
                :validation="validation.keyPersonnelNotes"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton
            mode="info"
            :label="t.a_create"
            :loading="isCreating"
            @click="onCreate"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>

    <SModal :open="isDialogOpen" @close="onModalClose">
      <Transition name="fade" mode="out-in">
        <CompanyFormCreateAiLoading
          v-if="aiLoadingDialog.state.value"
        />
        <CompanyFormCreateAiError
          v-else-if="aiErrorDialog.state.value"
          @close="aiErrorDialog.off"
        />
        <CompanyFormCreateDuplicate
          v-else-if="duplicateDialog.state.value"
          :type="currentStep"
          :companies="detectedDuplicates"
          @cancel="duplicateDialog.off"
          @proceed="onDuplicateCheckProceed"
        />
      </Transition>
    </SModal>
  </SCard>
</template>
