<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useCanCreateCompany } from '@/composables/policies/CompanyPolicy'
import CompanyFormCreate from '@/components/company/CompanyFormCreate.vue'

const router = useRouter()

const canCreateCompany = useCanCreateCompany()

function onCreated(companyId: number) {
  router.push(`/companies/${companyId}`)
}
</script>

<template>
  <DPage class="CompaniesCreate" title="Companies - Create" :authorize="canCreateCompany">
    <div class="container">
      <CompanyFormCreate @created="onCreated" />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesCreate {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
