<script setup lang="ts">
import { computed, useSlots } from 'vue'

const props = defineProps<{
  span?: string
  noLine?: boolean
}>()

const slots = useSlots()

const classes = computed(() => [
  `span-${props.span ?? '1'}`,
  { line: !props.noLine },
  { 'no-item': !slots.default }
])
</script>

<template>
  <div class="DGridItem" :class="classes">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DGridItem {
  &.span-1 { grid-column: span 1; }
  &.span-2 { grid-column: span 2; }
  &.span-3 { grid-column: span 3; }
  &.span-4 { grid-column: span 4; }
  &.span-5 { grid-column: span 5; }
  &.span-6 { grid-column: span 6; }
  &.span-7 { grid-column: span 7; }
  &.span-8 { grid-column: span 8; }

  &.line {
    padding: 0 0 8px 0;
    border-bottom: 1px dashed var(--c-divider-2);
  }

  &.no-item {
    border-bottom-color: transparent;
  }
}
</style>
