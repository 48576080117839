<script setup lang="ts">
import { type CurrencyFrag, type DealSecurityFrag } from '@/graphql'
import { type DataYesOrNo } from './DealFormUpdateReport.vue'
import DealFormUpdateReportSecuritiesItem from './DealFormUpdateReportSecuritiesItem.vue'

export interface Transaction {
  security: DealSecurityFrag
  currency: CurrencyFrag
  quantity: number | null
  unitPrice: string | null
  totalPrice: string | null
  totalPriceFmc: string | null
}

const props = defineProps<{
  strict: DataYesOrNo
  editable: DataYesOrNo
  transactions: Transaction[]
}>()

const emit = defineEmits<{
  (e: 'update:transactions', value: Transaction[]): void
}>()

function update(
  security: DealSecurityFrag,
  key: keyof Transaction,
  value: Transaction[keyof Transaction]
) {
  const transactions = props.transactions.map((transaction) => {
    return (transaction.security.id === security.id)
      ? { ...transaction, [key]: value }
      : transaction
  })

  emit('update:transactions', transactions)
}
</script>

<template>
  <DFormSection>
    <STrans lang="en">
      <DFormTitle>Securities information</DFormTitle>
      <DFormLead>Fill in the type of securities acquired in this investment, as well as the quantity and price. Enter explicitly "0" for all securities not acquired in this investment.</DFormLead>
    </STrans>
    <STrans lang="ja">
      <DFormTitle>有価証券情報</DFormTitle>
      <DFormLead>本投資で取得した有価証券の種類、および数量、価格を入力してください。本投資では取得しなかった有価証券については、全て明示的に"0"を入力してください。</DFormLead>
    </STrans>

    <DFormGrid>
      <DFormGridItem span="6">
        <div class="securities">
          <div
            v-for="transaction in transactions"
            :key="transaction.security.id"
            class="security"
          >
            <DealFormUpdateReportSecuritiesItem
              :strict="strict"
              :editable="editable"
              :security="transaction.security"
              :currency="transaction.currency"
              :quantity="transaction.quantity"
              :unit-price="transaction.unitPrice"
              :total-price="transaction.totalPrice"
              :total-price-fmc="transaction.totalPriceFmc"
              @update:quantity="(v) => update(transaction.security, 'quantity', v)"
              @update:unit-price="(v) => update(transaction.security, 'unitPrice', v)"
              @update:total-price="(v) => update(transaction.security, 'totalPrice', v)"
              @update:total-price-fmc="(v) => update(transaction.security, 'totalPriceFmc', v)"
            />
          </div>
        </div>
      </DFormGridItem>
    </DFormGrid>
  </DFormSection>
</template>

<style scoped lang="postcss">
.securities {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  overflow: hidden;
}

.security + .security {
  border-top: 1px solid var(--c-divider-2);
}
</style>
