<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type FundReportWithIdFrag } from '@/graphql'
import { useExportingAllCompanyBusinessReport } from '@/composables/repos/CompanyBusinessReportRepo'

const props = defineProps<{
  report: FundReportWithIdFrag
}>()

const emit = defineEmits<{
  cancel: []
  exported: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    export: 'Export all reports'
  },
  ja: {
    cancel: 'キャンセル',
    export: '全てエクスポート'
  }
})

const {
  execute: doExportReports,
  loading: exportingReports
} = useExportingAllCompanyBusinessReport()

async function exportReports() {
  await doExportReports(props.report.id)
  emit('exported')
}
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <div class="s-font-20 s-font-w-500">Export all reports</div>
            <p>You are about to export all reports. The exported document will be sent you via Slack. It might take time so be patient. If you do not receive the document after a while, contact system administrator.</p>
          </STrans>
          <STrans lang="ja">
            <div class="s-font-20 s-font-w-500">全ての業務報告書のエクスポートする</div>
            <p>全ての業務報告書をエクスポートします。エクスポートされた文書はSlackで送付され、これには少し時間がかかる可能性があります。しばらくしても文書が届かない場合、システム管理者まで連絡してください。</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl size="medium">
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.export"
            :loading="exportingReports"
            @click="exportReports"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
