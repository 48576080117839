<script setup lang="ts">
import SInputRadio from 'sefirot/components/SInputRadio.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { ref } from 'vue'
import { type CompanyBusinessReportFinancialFiguresAnnotation, type CompanyBusinessReportPageFrag } from '@/graphql'
import { useFundReportOps } from '@/composables/ops/FundReportOps'
import { useCompanyBusinessReportItem } from '@/composables/repos/CompanyBusinessReportRepo'
import { type Data } from './CompanyBusinessReportFormUpdate.vue'
import CopmanyBusinessReportCopyFromOther, { type NewDataFromCompanyBusinessReport } from './CopmanyBusinessReportCopyFromOther.vue'

const props = defineProps<{
  currentData: Data
  copyableCompanyBusinessReports: CompanyBusinessReportPageFrag['items']
  copyData(data: NewDataFromCompanyBusinessReport): void
  annotations: CompanyBusinessReportFinancialFiguresAnnotation[]
}>()

const emit = defineEmits<{
  cancel: []
  copied: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    copy_data: 'Copy data'
  },
  ja: {
    cancel: 'キャンセル',
    copy_data: 'コピーする'
  }
})

const selectedId = ref<number | null>(null)

const { data: companyBusinessReport, execute } = useCompanyBusinessReportItem(() => selectedId.value!, {
  immediate: false
})

const fundReportOps = useFundReportOps()

const { state: modal, on, off } = usePower()

function isSelected(companyBusinessReport: CompanyBusinessReportPageFrag['items'][number]) {
  return selectedId.value === companyBusinessReport.id
}

function select(companyBusinessReport: CompanyBusinessReportPageFrag['items'][number]) {
  selectedId.value = companyBusinessReport.id!
}

async function openConfirmModal() {
  await execute()
  on()
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-16">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <div class="s-font-20 s-font-w-500">Copy other report</div>
          </STrans>
          <STrans lang="ja">
            <div class="s-font-20 s-font-w-500">他の報告書からコピーする</div>
          </STrans>
          <SGrid>
            <SGridItem class="items">
              <div
                v-for="report in props.copyableCompanyBusinessReports"
                :key="report.id"
                class="item"
                :class="{ active: isSelected(report) }"
                role="button"
                @click="select(report)"
              >
                <SInputRadio
                  :text="report ? fundReportOps.title(report.report) : ''"
                  :model-value="isSelected(report)"
                />
              </div>
            </SGridItem>
          </SGrid>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="medium" class="s-pl-8 s-px-16">
      <SControl size="medium">
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.copy_data"
            :disabled="selectedId === null"
            @click="openConfirmModal"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>

    <SModal :open="modal">
      <CopmanyBusinessReportCopyFromOther
        v-if="companyBusinessReport"
        :current-data="currentData"
        :company-business-report="companyBusinessReport"
        :copy-data="copyData"
        :annotations="annotations"
        @cancel="off"
        @copied="off(() => emit('copied'))"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.items {
  border: 1px solid var(--c-divider-light);
  border-radius: 4px;
  overflow: hidden;
}

.item {
  padding: 12px 16px;
  transition: background-color .25s;

  &:hover {
    background-color: var(--c-bg-lift-1);
  }

  &.active,
  &.active:hover {
    background-color: var(--c-info-dimm-1);
  }

  & + & {
    border-top: 1px solid var(--c-divider-light);
  }
}
</style>
