<script setup lang="ts">
import IconCheckCircle from '~icons/ph/check-circle-bold'
import IconCircle from '~icons/ph/circle-bold'
import { computed } from 'vue'

const props = defineProps<{
  text: string
  modelValue: boolean
  mutable?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()

const classes = computed(() => [
  props.modelValue ? 'on' : 'off',
  { mutable: props.mutable }
])

function emitChange() {
  if (props.mutable) {
    emit('update:model-value', !props.modelValue)
  }
}
</script>

<template>
  <div class="DToggleCheck" :class="classes" role="button" @click="emitChange">
    <div class="icon">
      <IconCheckCircle v-if="modelValue" class="icon-svg" />
      <IconCircle v-else class="icon-svg" />
    </div>
    <div class="text">
      <div class="text-value">{{ text }}</div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DToggleCheck {
  display: flex;
  align-items: center;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  width: 100%;
  text-align: left;
  transition: border-color 0.25s;
  cursor: default;

  &.mutable {
    cursor: pointer;
  }

  &.mutable:hover {
    border-color: var(--c-success);
  }

  &.on { border-color: var(--c-success); }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;

  .DToggleCheck.on &  { color: var(--c-success); }
  .DToggleCheck.off & { color: var(--c-text-3); }
}

.icon-svg {
  width: 18px;
  height: 18px;
}

.text {
  flex-grow: 1;
}

.text-value {
  font-size: 13px;
  font-weight: 500;

  .DToggleCheck.on &  { color: var(--c-text-1); }
  .DToggleCheck.off & { color: var(--c-text-2); }

  .DToggleCheck.mutable.on &,
  .DToggleCheck.mutable.off & {
    color: var(--c-text-1);
  }
}
</style>
