<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useSnackbars } from 'sefirot/stores/Snackbars'
import { type Ref, computed, ref } from 'vue'
import { type UserAndRolesFrag } from '@/graphql'
import { useRoleOps } from '@/composables/ops/RoleOps'
import { useCanUpdateUserRole } from '@/composables/policies/UserPolicy'
import { useRoleList } from '@/composables/repos/RoleRepo'
import { useUpdateUserRoles } from '@/composables/repos/UserRepo'

const props = defineProps<{
  user: UserAndRolesFrag
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    title: 'Roles',
    updated: 'Roles updated.',
    b_save: 'Update roles'
  },
  ja: {
    title: 'ロール',
    updated: 'ロールが更新されました.',
    b_save: 'ロールを更新'
  }
})

const snackbars = useSnackbars()
const canUpdateUserRole = useCanUpdateUserRole()

const roleIds = ref(props.user.roles.map((role) => role.id))
const roleOps = useRoleOps()
const { data: roles } = useRoleList()
const { execute, loading } = useUpdateUserRoles()

const roleOptions: Ref<Option[]> = computed(() => roles.value?.map((role) => ({
  label: roleOps.nameText(role),
  value: role.id
})) ?? [])

async function update() {
  await execute(props.user.id, roleIds.value)

  emit('updated')

  snackbars.push({
    mode: 'info',
    text: t.updated
  })
}
</script>

<template>
  <SCard>
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>{{ t.title }}</h2>
        </SContent>
        <SInputDropdown
          :options="roleOptions"
          v-model="roleIds"
          :disabled="!canUpdateUserRole.ok"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            mode="info"
            :label="t.b_save"
            :loading="loading"
            :disabled="!canUpdateUserRole.ok"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
