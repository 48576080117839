import { createSharedComposable, until, whenever } from '@vueuse/core'
import { type User as AuthUser, GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth'
import { type ComputedRef, type Ref, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { type Me as User } from '@/models/User'
import { useAuth as useAuthStore } from '@/stores/Auth'
import { assert } from '@/support/Error'
import { useFirebaseApp } from './Firebase'
import { useMeItem } from './repos/UserRepo'

export interface Auth {
  ready: Ref<boolean>
  authenticated: ComputedRef<boolean>
}

export interface Me {
  user: User
}

export const useAuth = createSharedComposable((): Auth => {
  const router = useRouter()
  const store = useAuthStore()
  const redirect = useAuthRedirect()
  const check = useAuthCheck()

  const { ready: firebaseReady } = useFirebaseApp()

  const ready = ref(false)
  const readyPromise = until(ready).toBe(true)

  whenever(firebaseReady, () => {
    onAuthStateChanged(getAuth(), async (user) => {
      await check(user)
      ready.value = true
    })
  })

  router.beforeEach(async (to) => {
    await readyPromise

    if (to.path.startsWith('/login') && store.authorized) {
      return redirect
    }

    if (!to.path.startsWith('/login') && !store.authorized) {
      if (!to.redirectedFrom || to.redirectedFrom.path !== '/') {
        redirect.path = to.fullPath
      }

      return '/login'
    }
  })

  return {
    ready,
    authenticated: computed(() => store.authenticated)
  }
})

export function useLogin(): () => Promise<void> {
  const store = useAuthStore()
  const redirect = useAuthRedirect()
  const router = useRouter()

  whenever(
    () => store.authorized,
    () => router.push(redirect)
  )

  return async () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })

    await signInWithPopup(getAuth(), provider)
  }
}

export function useLogout(): () => Promise<void> {
  const store = useAuthStore()
  const redirect = useAuthRedirect()
  const route = useRoute()

  let userInitiated = false

  whenever(() => !store.authorized, () => {
    if (!userInitiated) {
      redirect.path = route.fullPath
    }

    window.location.href = '/login'
  })

  return () => {
    userInitiated = true
    return signOut(getAuth())
  }
}

function useAuthRedirect(): { path: string } {
  const key = 'deus-intended' as const

  return {
    get path() {
      const path = sessionStorage.getItem(key) ?? '/'
      sessionStorage.removeItem(key)
      return path
    },
    set path(value) {
      value && sessionStorage.setItem(key, value)
    }
  }
}

function useAuthCheck(): (authUser: AuthUser | null) => Promise<User | null> {
  const store = useAuthStore()

  const { execute: session } = useMeItem({ immediate: false })

  return async (authUser) => {
    store.setAuth(!!authUser)

    const token = await authUser?.getIdToken()

    if (!authUser || !token) {
      return null
    }

    try {
      const user = (await session()) || null
      store.setUser(user)
      return user
    } catch {
      return null
    }
  }
}

export function useMe(): Me {
  const store = useAuthStore()

  assert(!!store.user, ['User not signed in.'])

  return {
    user: store.user
  }
}
