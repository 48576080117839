<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  open: boolean
  completing: boolean
}>()

defineEmits<{
  complete: []
  close: []
}>()

const { t } = useTrans({
  en: {
    complete: 'Complete deal report',
    cancel: 'Cancel'
  },
  ja: {
    complete: '投資報告書をコンプリート',
    cancel: 'キャンセル'
  }
})
</script>

<template>
  <SModal :open="open" @close="$emit('close')">
    <SCard size="small">
      <SCardBlock class="s-p-24">
        <SContent>
          <STrans lang="en">
            <h2>Complete deal report</h2>
            <p>You will not be able to edit this deal report unless you return it to draft status once you complete this report. Please make sure the information entered is correct.</p>
          </STrans>
          <STrans lang="ja">
            <h2>投資報告書をコンプリートする</h2>
            <p>投資報告書をコンプリートすると、ドラフト状態に戻さない限り、この投資報告書の編集はできなくなります。入力内容が正しいことを確認してください。</p>
          </STrans>
        </SContent>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-24">
        <SControl>
          <SControlRight>
            <SControlButton
              :label="t.cancel"
              :loading="completing"
              @click="$emit('close')"
            />
            <SControlButton
              mode="success"
              :label="t.complete"
              :loading="completing"
              @click="$emit('complete')"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </SModal>
</template>
