import { type RouteRecordRaw } from 'vue-router'
import SurveysIdIndex from '@/pages/surveys/[id]/index.vue'
import SurveysId from '@/pages/surveys/[id].vue'
import SurveysCreateComplete from '@/pages/surveys/create-complete.vue'
import SurveysCreate from '@/pages/surveys/create.vue'
import SurveysIndex from '@/pages/surveys/index.vue'
import Surveys from '@/pages/surveys.vue'

export const surveys: RouteRecordRaw[] = [
  {
    path: '/surveys',
    component: Surveys,
    children: [
      { path: '', component: SurveysIndex },
      { path: 'create', component: SurveysCreate },
      { path: 'create-complete', component: SurveysCreateComplete }
    ]
  },
  {
    path: '/surveys/:id',
    component: SurveysId,
    children: [
      { path: '', component: SurveysIdIndex }
    ]
  }
]
