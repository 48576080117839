<script setup lang="ts">
import IconCaretLeft from '~icons/ph/caret-left-bold'
import IconCaretRight from '~icons/ph/caret-right-bold'
import IconInfo from '~icons/ph/info'
import SButton from 'sefirot/components/SButton.vue'
import SButtonGroup from 'sefirot/components/SButtonGroup.vue'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, markRaw, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { OrderDirection, StatUserActivitiesOrderField } from '@/graphql'
import { type DurationType, useDuration } from '@/composables/Duration'
import { useStatUserActivitiesPage } from '@/composables/repos/StatUserActivitiesRepo'
import DInputSearch from '@/components/DInputSearch.vue'
import DTableCellUser from '../DTableCellUser.vue'
import DashboardCapitalistActivitiesFieldGuide from './DashboardCapitalistActivitiesFieldGuide.vue'

const route = useRoute()

const urlSearch = ref(location.search)

watch(() => route.query, () => {
  urlSearch.value = location.search
})

const durationTypes = [
  { label: 'Half', value: 'half' as DurationType },
  { label: 'Quarter', value: 'quarter' as DurationType }
]

const { type, from, to, hasNext, label: durationLabel, prev, next, set } = useDuration('half')

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    query: null as string | null,
    roles: [/* Capitalist Role ID */2],
    from: from.value.toISOString(),
    to: to.value.toISOString()
  },
  orderBy: {
    field: StatUserActivitiesOrderField.Name,
    direction: OrderDirection.Asc
  }
})

watch([type, () => from.value.toISOString()], () => {
  options.value.condition.from = from.value.toISOString()
  options.value.condition.to = to.value.toISOString()
}, { immediate: true })

watch([() => options.value.condition.from, () => options.value.condition.to], () => {
  set(options.value.condition.from, options.value.condition.to)
}, { immediate: true })

useUrlQuerySync(options, {
  casts: {
    'condition.query': (v) => (v === '' || v === null) ? null : v,
    'condition.from': (v) => v,
    'condition.to': (v) => v
  },
  exclude: ['page.page', 'page.perPage', 'condition.roles']
})

const { state: modal, on, off } = usePower()

const { data: activities, loading } = useStatUserActivitiesPage(options)

const table = useTable({
  orders: [
    'name',
    'createdCompanies',
    'newApproaches',
    'reApproaches',
    'totalApproaches',
    'createdDeals',
    'investedDeals',
    'droppedDeals',
    'createdActionNotes',
    'openOppos',
    'openDeals',
    'promotedCompanies',
    'assignedPortfoliosAsPic',
    'assignedPortfoliosAsDic',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: {
        type: 'component',
        component: markRaw(DTableCellUser),
        props: {
          urlSearch
        }
      },
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.Name, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.Name, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    createdCompanies: {
      label: 'Created companies',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedCompanies, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedCompanies, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    newApproaches: {
      label: 'New approaches',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.NewApproaches, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.NewApproaches, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    reApproaches: {
      label: 'Re approaches',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.ReApproaches, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.ReApproaches, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    totalApproaches: {
      label: 'Total approaches',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.TotalApproaches, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.TotalApproaches, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    createdDeals: {
      label: 'Created deals',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedDeals, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedDeals, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    investedDeals: {
      label: 'Invested deals',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.InvestedDeals, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.InvestedDeals, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    droppedDeals: {
      label: 'Dropped deals',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.DroppedDeals, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.DroppedDeals, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    createdActionNotes: {
      label: 'Created action notes',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedActionNotes, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.CreatedActionNotes, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    openOppos: {
      label: 'Open oppos',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.OpenOppos, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.OpenOppos, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    openDeals: {
      label: 'Open deals',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.OpenDeals, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.OpenDeals, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    promotedCompanies: {
      label: 'Promoted companies',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.PromotedCompanies, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.PromotedCompanies, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    assignedPortfoliosAsPic: {
      label: 'Assigned portfolios as PIC',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.AssignedPortfoliosAsPic, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.AssignedPortfoliosAsPic, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    assignedPortfoliosAsDic: {
      label: 'Assigned portfolios as DIC',
      cell: (value) => ({
        type: 'text',
        color: value === '0' ? 'mute' : 'neutral'
      }),
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.AssignedPortfoliosAsDic, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(StatUserActivitiesOrderField.AssignedPortfoliosAsDic, OrderDirection.Desc) }
            }
          ]
        }
      ])
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  },
  records: computed(() => activities.value?.items.map((r) => ({
    name: r.user,
    createdCompanies: r.createdCompanies === 0 ? '0' : r.createdCompanies,
    newApproaches: r.newApproaches === 0 ? '0' : r.newApproaches,
    reApproaches: r.reApproaches === 0 ? '0' : r.reApproaches,
    totalApproaches: r.totalApproaches === 0 ? '0' : r.totalApproaches,
    createdDeals: r.createdDeals === 0 ? '0' : r.createdDeals,
    investedDeals: r.investedDeals === 0 ? '0' : r.investedDeals,
    droppedDeals: r.droppedDeals === 0 ? '0' : r.droppedDeals,
    createdActionNotes: r.createdActionNotes === 0 ? '0' : r.createdActionNotes,
    openOppos: r.openOppos === 0 ? '0' : r.openOppos,
    openDeals: r.openDeals === 0 ? '0' : r.openDeals,
    promotedCompanies: r.promotedCompanies === 0 ? '0' : r.promotedCompanies,
    assignedPortfoliosAsPic: r.assignedPortfoliosAsPic === 0 ? '0' : r.assignedPortfoliosAsPic,
    assignedPortfoliosAsDic: r.assignedPortfoliosAsDic === 0 ? '0' : r.assignedPortfoliosAsDic
  }))),
  loading,
  borderless: true
})

function updateQuery(value: string | null) {
  options.value.page.page = 0
  options.value.condition.query = value === '' ? null : value
}

function updateOrder(field: StatUserActivitiesOrderField, direction: OrderDirection) {
  options.value.page.page = 0
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}
</script>

<template>
  <div class="DashboardCapitalistActivities">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                placeholder="Search members"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              label="Reset filters"
              @click="reset"
            />
          </SControlLeft>
          <SControlRight>
            <SControlButton
              label="See field guide"
              type="outline"
              mode="mute"
              :icon="IconInfo"
              @click="on"
            />
            <SButtonGroup>
              <SButton
                v-for="{ label, value } in durationTypes"
                :key="value"
                size="small"
                :type="type === value ? 'fill' : 'outline'"
                :mode="type === value ? 'info' : 'mute'"
                :label-mode="type === value ? 'info' : 'mute'"
                :label="label"
                @click="type = value"
              />
            </SButtonGroup>
            <SButtonGroup class="duration-button-group">
              <SButton
                size="small"
                type="outline"
                mode="mute"
                label-mode="info"
                :label="durationLabel"
              />
              <SButton
                size="small"
                type="outline"
                mode="mute"
                :icon="IconCaretLeft"
                @click="prev"
              />
              <SButton
                size="small"
                type="outline"
                mode="mute"
                :icon="IconCaretRight"
                :disabled="!hasNext"
                @click="hasNext && next()"
              />
            </SButtonGroup>
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="activities?.pageInfo.total ?? 0"
              :page="activities ? activities.pageInfo.page + 1 : 0"
              :per-page="activities?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SModal :open="modal" @close="off">
        <DashboardCapitalistActivitiesFieldGuide @close="off" />
      </SModal>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.DashboardCapitalistActivities {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.duration-button-group {
  display: flex;

  :deep(.SButton) {
    &.has-label {
      width: 96px;
      --button-hover-bg-color: var(--button-bg-color);
      --button-active-bg-color: var(--button-bg-color);
      --button-disabled-text-color: var(--button-outline-mute-text-color);
      cursor: default;
    }

    &.has-lead-icon:not(.disabled) .icon {
      color: var(--button-outline-info-text-color);
    }
  }
}

.list {
  padding-top: 16px;
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)                    { --table-col-width: 240px; }
.list :deep(.col-createdCompanies)        { --table-col-width: 164px; }
.list :deep(.col-newApproaches)           { --table-col-width: 164px; }
.list :deep(.col-reApproaches)            { --table-col-width: 164px; }
.list :deep(.col-totalApproaches)         { --table-col-width: 164px; }
.list :deep(.col-createdDeals)            { --table-col-width: 164px; }
.list :deep(.col-investedDeals)           { --table-col-width: 164px; }
.list :deep(.col-droppedDeals)            { --table-col-width: 164px; }
.list :deep(.col-createdActionNotes)      { --table-col-width: 164px; }
.list :deep(.col-openOppos)               { --table-col-width: 164px; }
.list :deep(.col-openDeals)               { --table-col-width: 164px; }
.list :deep(.col-promotedCompanies)       { --table-col-width: 164px; }
.list :deep(.col-assignedPortfoliosAsPic) { --table-col-width: 164px; }
.list :deep(.col-assignedPortfoliosAsDic) { --table-col-width: 164px; }
.list :deep(.col-spacer)                  { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
