import {
  AddCompanyActionNoteDocument,
  type AddCompanyActionNoteInput,
  AddCompanyFinancialResultDocument,
  AddCompanyNameDocument,
  type BasicPaginationInput,
  ClaimCompanyDocument,
  CloseCompanyDocument,
  type CloseCompanyInput,
  CompanyAndActionNotesAndPermissionDocument,
  CompanyAndNameDocument,
  type CompanyCondition,
  CompanyDocument,
  type CompanyExecutiveInformationInput,
  type CompanyFinancialResultInput,
  CompanyForCompanyBusinessReportCopyDocument,
  CompanyForDetailsDocument,
  CompanyForLocalNavDocument,
  CompanyForSettingsDocument,
  CompanyForWrapupDocument,
  type CompanyInformationInput,
  type CompanyLatestAssigneesInput,
  type CompanyNameInput,
  type CompanyOrder,
  CompanyOrderField,
  CompanyPageDocument,
  CompanyPageForSearchDocument,
  CompanyPortfolioDataDocument,
  CompanyPortfolioDataFinancialFiguresDocument,
  CompanyPortfolioDataFinancialFiguresForCompanyBusinessReportCopyDocument,
  CompanyVecDocument,
  CreateCompanyDocument,
  type CreateCompanyInput,
  DeleteCompanyNameDocument,
  RemoveCompanyFinancialResultDocument,
  SyncCompanyPortfolioDataEmergencyContactsDocument,
  type SyncCompanyPortfolioDataEmergencyContactsInput,
  UpdateCompanyExecutiveInformationDocument,
  UpdateCompanyFinancialFiguresDocument,
  type UpdateCompanyFinancialFiguresInput,
  UpdateCompanyFinancialResultDocument,
  UpdateCompanyInformationDocument,
  UpdateCompanyNameDocument,
  UpdateCompanyPortfolioDataCapitalDocument,
  type UpdateCompanyPortfolioDataCapitalInput,
  UpdateCompanyPortfolioDataIssuedSharesDocument,
  type UpdateCompanyPortfolioDataIssuedSharesInput,
  UpdateCompanyPortfolioDataPotentialDilutiveSharesDocument,
  type UpdateCompanyPortfolioDataPotentialDilutiveSharesInput,
  UpdateCompanyPortfolioDataPromoterDocument,
  type UpdateCompanyPortfolioDataPromoterInput,
  type UpdateCompanyVECInput,
  UpdateCompanyVecDocument,
  UpdateLatestCompanyAssigneesDocument
} from '@/graphql'
import { Request } from './Request'

export {
  type CompanyCondition,
  type CompanyOrder,
  CompanyOrderField
}

export class CompanyRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: CompanyCondition,
    orderBy: CompanyOrder
  ) {
    return this.request({
      query: CompanyPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchPageForSearch(
    page: BasicPaginationInput,
    condition: CompanyCondition,
    orderBy: CompanyOrder
  ) {
    return this.request({
      query: CompanyPageForSearchDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: CompanyDocument,
      variables: { id }
    })
  }

  fetchForDetails(id: number) {
    return this.request({
      query: CompanyForDetailsDocument,
      variables: { id }
    })
  }

  fetchForSettings(id: number) {
    return this.request({
      query: CompanyForSettingsDocument,
      variables: { id }
    })
  }

  fetchForCompanyBusinessReportCopy(id: number) {
    return this.request({
      query: CompanyForCompanyBusinessReportCopyDocument,
      variables: { id }
    })
  }

  fetchAndName(id: number) {
    return this.request({
      query: CompanyAndNameDocument,
      variables: { id }
    })
  }

  fetchAndActionNotesAndPermission(id: number) {
    return this.request({
      query: CompanyAndActionNotesAndPermissionDocument,
      variables: { id }
    })
  }

  fetchForLocalNav(id: number) {
    return this.request({
      query: CompanyForLocalNavDocument,
      variables: { id }
    })
  }

  forWrapup(id: number) {
    return this.request({
      query: CompanyForWrapupDocument,
      variables: { id }
    })
  }

  create(input: CreateCompanyInput) {
    return this.request({
      query: CreateCompanyDocument,
      variables: { input }
    })
  }

  updateInfo(id: number, input: CompanyInformationInput) {
    return this.request({
      query: UpdateCompanyInformationDocument,
      variables: { id, input }
    })
  }

  updateExecutive(id: number, input: CompanyExecutiveInformationInput) {
    return this.request({
      query: UpdateCompanyExecutiveInformationDocument,
      variables: { id, input }
    })
  }

  updateLatestAssignees(id: number, input: CompanyLatestAssigneesInput) {
    return this.request({
      query: UpdateLatestCompanyAssigneesDocument,
      variables: { id, input }
    })
  }

  claimCompany(id: number) {
    return this.request({
      query: ClaimCompanyDocument,
      variables: { id }
    })
  }

  closeCompany(id: number, input: CloseCompanyInput) {
    return this.request({
      query: CloseCompanyDocument,
      variables: { id, input }
    })
  }

  addName(id: number, input: CompanyNameInput) {
    return this.request({
      query: AddCompanyNameDocument,
      variables: { id, input }
    })
  }

  updateName(companyNameId: number, input: CompanyNameInput) {
    return this.request({
      query: UpdateCompanyNameDocument,
      variables: { companyNameId, input }
    })
  }

  deleteName(companyNameId: number) {
    return this.request({
      query: DeleteCompanyNameDocument,
      variables: { companyNameId }
    })
  }

  addActionNote(id: number, input: AddCompanyActionNoteInput) {
    return this.request({
      query: AddCompanyActionNoteDocument,
      variables: { id, input }
    })
  }

  fetchPortfolioData(companyId: number) {
    return this.request({
      query: CompanyPortfolioDataDocument,
      variables: {
        companyId
      }
    })
  }

  fetchPortfolioDataFinancialFigures(companyId: number) {
    return this.request({
      query: CompanyPortfolioDataFinancialFiguresDocument,
      variables: { companyId }
    })
  }

  fetchPortfolioDataForCompanyBusinessReportCopy(companyId: number) {
    return this.request({
      query: CompanyPortfolioDataFinancialFiguresForCompanyBusinessReportCopyDocument,
      variables: { companyId }
    })
  }

  updateCapital(companyId: number, input: UpdateCompanyPortfolioDataCapitalInput) {
    return this.request({
      query: UpdateCompanyPortfolioDataCapitalDocument,
      variables: { companyId, input }
    })
  }

  updateIssuedShares(companyId: number, input: UpdateCompanyPortfolioDataIssuedSharesInput) {
    return this.request({
      query: UpdateCompanyPortfolioDataIssuedSharesDocument,
      variables: { companyId, input }
    })
  }

  updatePotentialShares(companyId: number, input: UpdateCompanyPortfolioDataPotentialDilutiveSharesInput) {
    return this.request({
      query: UpdateCompanyPortfolioDataPotentialDilutiveSharesDocument,
      variables: { companyId, input }
    })
  }

  updatePromoter(companyId: number, input: UpdateCompanyPortfolioDataPromoterInput) {
    return this.request({
      query: UpdateCompanyPortfolioDataPromoterDocument,
      variables: { companyId, input }
    })
  }

  fetchCompanyVec(companyId: number) {
    return this.request({
      query: CompanyVecDocument,
      variables: {
        companyId
      }
    })
  }

  updateCompanyVec(companyId: number, input: UpdateCompanyVECInput) {
    return this.request({
      query: UpdateCompanyVecDocument,
      variables: { companyId, input }
    })
  }

  updateCompanyFinancialFigures(companyId: number, input: UpdateCompanyFinancialFiguresInput) {
    return this.request({
      query: UpdateCompanyFinancialFiguresDocument,
      variables: { companyId, input }
    })
  }

  createCompanyFinancialResult(companyId: number, input: CompanyFinancialResultInput) {
    return this.request({
      query: AddCompanyFinancialResultDocument,
      variables: { companyId, input }
    })
  }

  updateCompanyFinancialResult(id: number, input: CompanyFinancialResultInput) {
    return this.request({
      query: UpdateCompanyFinancialResultDocument,
      variables: { id, input }
    })
  }

  deleteCompanyFinancialResult(id: number) {
    return this.request({
      query: RemoveCompanyFinancialResultDocument,
      variables: { id }
    })
  }

  syncPortfolioDataEmergencyContacts(
    companyId: number,
    input: SyncCompanyPortfolioDataEmergencyContactsInput
  ) {
    return this.request({
      query: SyncCompanyPortfolioDataEmergencyContactsDocument,
      variables: { companyId, input }
    })
  }
}
