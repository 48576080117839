<script setup lang="ts">
import IconArrowsInLineVertical from '~icons/ph/arrows-in-line-vertical-bold'
import IconArrowsOutLineVertical from '~icons/ph/arrows-out-line-vertical-bold'
import IconInfo from '~icons/ph/info-bold'
import IconListBullets from '~icons/ph/list-bullets-bold'
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconPlus from '~icons/ph/plus-bold'
import IconTrash from '~icons/ph/trash-bold'
import IconX from '~icons/ph/x-bold'
import SPill, { type Mode as SPillMode } from 'sefirot/components/SPill.vue'
import { computed, ref } from 'vue'

export type Size = 'small' | 'medium' | 'large'
export type Mode = 'neutral' | 'danger'

export interface Pill {
  label: string
  mode?: SPillMode
}

export interface Action {
  icon: ActionIcon
  link?: string
  onClick?(): void
}

export type ActionIcon = 'add' | 'delete' | 'edit' | 'list'

const props = withDefaults(defineProps<{
  size?: Size
  mode?: Mode
  header?: boolean
  title?: string
  pills?: Pill[]
  actions?: Action[]
  collapsable?: boolean
  closable?: boolean
  showHelpIcon?: boolean
}>(), {
  mode: 'neutral',
  header: true,
  title: undefined,
  pills: () => [],
  actions: () => [],
  collapsable: true,
  closable: false,
  showHelpIcon: false
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'click-help-icon'): void
}>()

const showHeader = computed(() => {
  return (
    props.header
    && (
      props.title
      || props.collapsable
      || props.closable
      || props.actions.length
    )
  )
})

const isClose = ref(false)
</script>

<template>
  <SMount class="DCard" :class="[size, mode, { close: isClose }]">
    <div v-if="showHeader" class="header">
      <div class="title">
        <p class="title-text">{{ title }}</p>
        <SLink
          v-if="props.showHelpIcon"
          class="help-icon"
          @click="emit('click-help-icon')"
        >
          <IconInfo />
        </SLink>

        <div v-if="pills?.length" class="title-pills">
          <SPill
            v-for="pill in pills"
            :key="pill.label"
            as="p"
            type="dimm"
            :mode="pill.mode ?? 'mute'"
            :label="pill.label"
          />
        </div>
      </div>

      <div class="actions">
        <SLink
          v-for="(action, index) in actions"
          :key="index"
          class="action"
          :href="action.link"
          @click="() => action.onClick?.()"
        >
          <IconPlus v-if="action.icon === 'add'" class="action-icon" />
          <IconTrash v-else-if="action.icon === 'delete'" class="action-icon" />
          <IconNotePencil v-else-if="action.icon === 'edit'" class="action-icon" />
          <IconListBullets v-else-if="action.icon === 'list'" class="action-icon" />
        </SLink>

        <button v-if="collapsable" class="action" @click="isClose = !isClose">
          <IconArrowsInLineVertical v-if="!isClose" class="action-icon" />
          <IconArrowsOutLineVertical v-else class="action-icon" />
        </button>

        <button v-if="closable" class="action" @click="emit('close')">
          <IconX class="action-icon" />
        </button>
      </div>
    </div>

    <div class="content">
      <slot />
    </div>
  </SMount>
</template>

<style scoped lang="postcss">
.DCard {
  position: relative;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: var(--c-bg-elv-3);

  &.neutral { border-color: var(--c-divider-2); }
  &.danger  { border-color: var(--c-danger-light); }

  &.close {
    height: 48px;
    overflow: hidden;
  }
}

.DCard :deep(.DCardBlock + .DCardBlock) {
  border-top: 1px solid var(--c-gutter);
}

.SModal .DCard {
  margin: 48px auto;
  max-width: 768px;
  transition: opacity 0.5s, transform 0.5s;

  &.small  { max-width: 512px; }
  &.medium { max-width: 768px; }

  &.mount {
    opacity: 0;
    transform: translateY(8px);
  }
}

.SModal .DCard .DCard {
  margin: 0;
  transition: opacity 0.5s, transform 0.5s;

  &.small  { max-width: 100%; }
  &.medium { max-width: 100%; }

  &.mount {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-divider-2);

  .DCard.close & {
    border-bottom-color: transparent;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 24px;

  .DCard.neutral & { color: var(--c-text-1); }
  .DCard.danger &  { color: var(--c-danger-text); }
}

.title-text {
  line-height: 47px;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.25s;

  .DCard.close & {
    color: var(--c-text-2);
  }
}

.help-icon {
  cursor: pointer;
  transition: color .025s;

  &:hover {
    color: var(--c-info);
  }
}

.title-pills {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {
  display: flex;
  padding: 8px 8px 7px;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  color: var(--c-text-2);
  cursor: pointer;
  transition: color 0.25s, background-color 0.25s;

  &:hover {
    color: var(--c-text-1);
    background-color: var(--c-mute);
  }
}

.action-icon {
  width: 16px;
  height: 16px;
}
</style>
