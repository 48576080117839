<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type ActionNoteFrag } from '@/graphql'
import { useUpdateActionNote } from '@/composables/repos/ActionNoteRepo'

const props = defineProps<{
  actionNote: ActionNoteFrag
}>()

const emit = defineEmits<{
  cancel: []
  updated: [actionNote: ActionNoteFrag]
}>()

const mutation = useUpdateActionNote()

const { data } = useData({
  title: props.actionNote.title,
  body: props.actionNote.body
})

const { validation, validateAndNotify } = useValidation(data, {
  title: {
    required: required(),
    maxLength: maxLength(255)
  },
  body: {
    required: required(),
    maxLength: maxLength(3000)
  }
})

async function update() {
  if (await validateAndNotify()) {
    const res = await mutation.execute(props.actionNote.id, {
      title: data.value.title!,
      body: data.value.body!
    })
    emit('updated', res)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Update this action note</h2>
        </SContent>
        <SInputText
          label="Title *"
          placeholder="The title of the note"
          v-model="data.title"
          :validation="validation.title"
        />
        <SInputTextarea
          label="Body *"
          placeholder="The description of the note."
          :rows="10"
          v-model="data.body"
          :validation="validation.body"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="mutation.loading.value"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            label="Update action note"
            :loading="mutation.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
