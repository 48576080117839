<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { orderBy } from 'lodash-es'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useOpportunitySourceOps } from '@/composables/ops/OpportunityOps'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityCardSourcingItem from './OpportunityCardSourcingItem.vue'
import OpportunityFormAddSource from './OpportunityFormAddSource.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

defineEmits<{
  updated: []
}>()

const canUpdate = useCanUpdateOpportunityPolicy(() => props.opportunity)

const opportunitySourceOps = useOpportunitySourceOps()

const { state: modal, on, off } = usePower(false)

const sources = computed(() => orderBy(props.opportunity.sources, ['date']))
</script>

<template>
  <SCard class="s-overflow-hidden">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Sourcing
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok"
              :icon="IconPlus"
              @click="on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <div class="grid">
      <OpportunityCardSourcingItem
        v-for="source in sources"
        :key="source.id!"
        :opportunity="opportunity"
        :source="source"
        :updatable="!!canUpdate"
        :deletable="!!canUpdate && opportunity.sources.length > 1 && !opportunitySourceOps.isUsedInProposals(source, opportunity.proposals)"
        @updated="$emit('updated')"
      />
    </div>
    <SModal :open="modal">
      <OpportunityFormAddSource
        :opportunity="opportunity"
        @added="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: var(--c-bg-elv-2);
}
</style>
