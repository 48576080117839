<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: { close: 'Close' },
  ja: { close: '閉じる' }
})
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Request submitted</h2>
          <p>The personel responsible for adjusting the schedule will contact you soon on Slack for further discussion.</p>
          <p>The agenda for the DD Kickoff is as follows. Please be prepared before the Kickoff takes place.</p>
          <ol>
            <li>Management and business understanding (commercial channels, strengths, challenges, and business plans)</li>
            <li>Competitive environment (IP and development perspective)</li>
            <li>Round summary, timetable, special circumstances of the deal (if any)</li>
            <li>Organise DD issues</li>
            <li>Discuss how to proceed (scope, interviews location, etc.)</li>
          </ol>
          <p>The same agenda is also sent by Email. Please check your inbox as well.</p>
        </STrans>
        <STrans lang="ja">
          <h2>エントリーが完了しました</h2>
          <p>追って担当者よりSlackで連絡がありますので、これ以降のやりとりはSlack上で行ってください。</p>
          <p>DD Kickoffのアジェンダは以下の通りです。Kickoff開催までに準備をお願いします。</p>
          <ol>
            <li>経営者、ビジネス理解（商流、強み・課題、事業計画）</li>
            <li>競争環境（知財・開発観点）</li>
            <li>ラウンド概要、スケジュール、ディールの特殊事情（あれば）</li>
            <li>DD論点の整理</li>
            <li>進め方のすり合わせ（スコープ、ヒアリング/現地等）</li>
          </ol>
          <p>同内容のアジェンダをEmailでも連絡しています。合わせて確認してください。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.close" @click="$emit('close')" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
