<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import {
  type BallotVoteChoice,
  type BallotVoterFrag,
  type DealEventAndBallotFrag,
  type DealEventFrag,
  type DealEventTemplateAndBallotFrag,
  type DealEventTemplateFrag,
  DealEventType
} from '@/graphql'
import { useDealEventOps } from '@/composables/ops/DealEventOps'
import { useUpdateDealEvent } from '@/composables/repos/DealEventRepo'
import DealCardEventListInputs from './DealCardEventListInputs.vue'

export interface Data {
  type: DealEventType
  date: Day | null
  reExecutedReason: string | null
  skipReason: string | null
  votes: DataVote[]
}

export interface DataVote {
  voter: BallotVoterFrag
  choice: BallotVoteChoice
  condition: string | null
  comment: string | null
}

const props = defineProps<{
  template: DealEventTemplateFrag | DealEventTemplateAndBallotFrag
  event: DealEventFrag | DealEventAndBallotFrag
}>()

const emit = defineEmits<{
  (e: 'saved'): void
  (e: 'cancel'): void
}>()

const { loading, execute } = useUpdateDealEvent()

const dealEventOps = useDealEventOps()

const { data } = useData<Data>({
  type: props.event.type,
  date: dealEventOps.date(props.event),
  reExecutedReason: props.event.reExecutedReason,
  skipReason: props.event.skipReason,
  votes: createVotesData()
})

const { validateAndNotify } = useValidation()

function createVotesData() {
  return (props.template as DealEventTemplateAndBallotFrag).event.ballot?.voters.map((voter) => {
    const vote = (props.event as DealEventAndBallotFrag).ballot!.votes.find((vote) => vote.voterId === voter.id)!

    return {
      voter,
      choice: vote.choice,
      condition: vote.condition,
      comment: vote.comment
    }
  }) ?? []
}

function createInput() {
  switch (data.value.type) {
    case DealEventType.Executed: return createExecutedInput()
    case DealEventType.ReExecuted: return createReExecutedInput()
    case DealEventType.Skipped: return createSkippedInput()
  }
}

function createBaseInput() {
  return {
    type: data.value.type,
    date: data.value.date!.format('YYYY-MM-DD'),
    skipReason: null,
    reExecutedReason: null,
    votes: []
  }
}

function createExecutedInput() {
  return {
    ...createBaseInput(),
    votes: data.value.votes.map((vote) => ({
      voterId: vote.voter.id!,
      choice: vote.choice,
      condition: vote.condition,
      comment: vote.comment
    }))
  }
}

function createReExecutedInput() {
  return {
    ...createBaseInput(),
    reExecutedReason: data.value.reExecutedReason
  }
}

function createSkippedInput() {
  return {
    ...createBaseInput(),
    skipReason: data.value.skipReason
  }
}

async function add() {
  if (await validateAndNotify()) {
    await execute(props.event.id, createInput())
    emit('saved')
  }
}
</script>

<template>
  <div class="DealCardEventListAdd">
    <div class="body">
      <DealCardEventListInputs
        v-model:type="data.type"
        v-model:date="data.date"
        v-model:re-executed-reason="data.reExecutedReason"
        v-model:skip-reason="data.skipReason"
        v-model:votes="data.votes"
      />
    </div>

    <div class="footer">
      <SButton label="Cancel" @click="$emit('cancel')" />
      <SButton mode="info" label="Save" :loading="loading" @click="add" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealCardEventListAdd {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
}

.body {
  display: grid;
  gap: 16px;
  padding: 24px;
}

.header {
  max-width: 592px;
}

.header-title {
  font-size: 16px;
  font-weight: 600;
}

.header-lead {
  font-size: 14px;
  color: var(--c-text-2);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid var(--c-divider-2);
  border-radius: 0 0 6px 6px;
  padding: 16px 24px;
  background-color: var(--c-soft);
}
</style>
