import {
  type Policy,
  type PolicyResponse,
  type PolicyHelpers as SefirotPolicyHelpers,
  usePolicy as sefirotUsePolicy
} from 'sefirot/composables/Policy'
import { type Me } from '@/models/User'
import { useMe } from '../Auth'

export type { Policy, PolicyResponse } from 'sefirot/composables/Policy'

export interface PolicyHelpers<Code extends string | undefined = undefined>
  extends SefirotPolicyHelpers<Code> {
  user: Me
}

export function usePolicy<Code extends string | undefined = undefined>(
  fn: (helpers: PolicyHelpers<Code>) => PolicyResponse<Code>
): Policy<Code> {
  const { user } = useMe()

  return sefirotUsePolicy((helpers) => {
    return fn({ ...helpers, user })
  })
}
