<script setup lang="ts">
import SAvatar from 'sefirot/components/SAvatar.vue'
import { day } from 'sefirot/support/Day'
import { computed } from 'vue'
import { type CompanyBusinessReportFinancialFiguresUnit } from '@/graphql'
import { type Ymd } from '@/support/Day'
import { format } from '@/support/Num'
import { useUserOps } from '@/composables/ops/UserOps'
import { type FinancialResultData } from './CompanyBusinessReportInputFinancialResults.vue'

interface BodyCell {
  text: string | null
  avatar?: string
  class?: string
}

const props = defineProps<{
  result: FinancialResultData
  units: CompanyBusinessReportFinancialFiguresUnit[]
  view: 'raw' | 'scaled'
}>()

const userOps = useUserOps()

const unitDict = computed(() => {
  return props.units.reduce((map, unit) => {
    map[unit.id] = unit
    return map
  }, {} as Record<number, CompanyBusinessReportFinancialFiguresUnit>)
})

const bodyCells = computed<BodyCell[]>(() => {
  const {
    period,
    unitId,
    netSales,
    ordinaryIncome,
    netIncome,
    totalAssets,
    capital,
    netAssets,
    updatedBy,
    updatedAt
  } = props.result ?? {}

  const unit = typeof unitId === 'number'
    ? unitDict.value[unitId]
    : null

  const periodCell = createPeriodCell(period)
  const currencyCell = createCurrencyCell(unit)

  const figureCells = [
    netSales,
    ordinaryIncome,
    netIncome,
    totalAssets,
    capital,
    netAssets
  ].map((value) => ({
    text: value !== null ? formatValue(Number(value), unit) : null,
    class: (value === null)
      ? 'mute'
      : Number(value) === 0
        ? 'mute'
        : Number(value) > 0 ? 'success' : 'danger'
  }))

  const updatedByCell = {
    class: 'updatedBy',
    text: updatedBy ? userOps.name(updatedBy) : null,
    avatar: updatedBy ? userOps.avatarPath(updatedBy) : null
  }

  const updatedAtCell = {
    text: updatedAt ? day(updatedAt).format('YYYY-MM-DD HH:mm') : null
  }

  return [periodCell, currencyCell, ...figureCells, updatedByCell, updatedAtCell]
})

function createPeriodCell(period: Ymd | null) {
  return {
    text: (period?.year && period?.month) ? `${period.year} / ${period.month}` : null
  }
}

function createCurrencyCell(unit: CompanyBusinessReportFinancialFiguresUnit | null) {
  const text = props.view === 'scaled'
    ? unit?.name ?? null
    : `${unit?.currency?.name ?? ''}`.trim() || null

  return { text }
}

function formatValue(value: number | null, unit: CompanyBusinessReportFinancialFiguresUnit | null): string | null {
  if (props.view === 'raw') {
    return value === null ? null : format(value)
  }

  if (value === 0) {
    return '-'
  }

  if (value === null) {
    return null
  }

  const number = Math.trunc(Number(value) / (unit ? Number(unit.scale) : 1))

  // Checking if the value is 0 here because `Math.trunc` method might return
  // `-0`, which is equal to `0`. In such case, we would like to display
  // value as `0` instead of `-0`.
  return format(number === 0 ? 0 : number)
}
</script>

<template>
  <div class="CompanyBusinessReportInputFinancialResultsItem">
    <ul class="body">
      <li
        v-for="(bodyCell, index) in bodyCells"
        :key="`${bodyCell.text}${bodyCell.class}${index}`"
        class="body-text"
        :class="bodyCell.class"
      >
        <span class="avatar"><SAvatar v-if="bodyCell.avatar" size="mini" :avatar="bodyCell.avatar" /></span>
        <span class="text">{{ bodyCell.text }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="postcss" scoped>
.CompanyBusinessReportInputFinancialResultsItem {
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--c-gutter);
}

.body-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  min-width: 100%;
  min-height: 40px;
  text-align: right;
  font-size: 14px;
  background-color: var(--c-bg-elv-3);
  white-space: nowrap;

  &.left {
    justify-content: flex-start;
  }

  &.mute    { color: var(--c-text-3); }
  &.success { color: var(--c-text-success-1); }
  &.danger  { color: var(--c-text-danger-1); }
}

.updatedBy {
  .text {
    max-width: calc(152px - 32px - 24px - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.avatar {
  flex-shrink: 0;
}
</style>
