<script setup lang="ts">
import { useCanViewCapitalistActivities } from '@/composables/policies/DashboardPolicy'
import DashboardCapitalistActivities from '@/components/dashboard/DashboardCapitalistActivities.vue'

const canViewCapitalistActivities = useCanViewCapitalistActivities()
</script>

<template>
  <DPage
    class="DashboardCapitalistActivities"
    title="Dashboard - Overview"
    :authorize="canViewCapitalistActivities.ok"
  >
    <DashboardCapitalistActivities />
  </DPage>
</template>
