import {
  type Mutation,
  type Query,
  type UseQueryOptions,
  useMutation,
  useQuery
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  type BasicPaginationInput,
  type CompanyBusinessReportCondition,
  type CompanyBusinessReportForLocalNavFrag,
  type CompanyBusinessReportFrag,
  type CompanyBusinessReportOrder,
  CompanyBusinessReportOrderField,
  type CompanyBusinessReportPageFrag,
  type CompanyBusinessReportWithCompanyFrag,
  type CompanyBusinessReportWithIdFrag,
  type CompanyBusinessReportWithRecordsFrag,
  type CompanyBusinessReportWithStatusFrag,
  OrderDirection,
  type UpdateCompanyBusinessReportInput
} from '@/graphql'
import { CompanyBusinessReportRequest } from '@/requests/CompanyBusinessReportRequest'

export type CompanyBusinessReportPage = Query<CompanyBusinessReportPageFrag>
export type CompanyBusinessReportItem = Query<CompanyBusinessReportFrag | null>
export type CompanyBusinessReportForLocalNavItem = Query<CompanyBusinessReportForLocalNavFrag | null>
export type CompanyBusinessReportForCopyList = Query<CompanyBusinessReportPageFrag['items'] | null>
export type UpdateCompanyBusinessReport = Mutation<UpdateCompanyBusinessReportResponse, [number, UpdateCompanyBusinessReportInput]>
export type RequestReviewOnCompanyBusinessReport = Mutation<RequestReviewOnCompanyBusinessReportResponse, [number]>
export type StartReviewOnCompanyBusinessReport = Mutation<StartReviewOnCompanyBusinessReportResponse, [number]>
export type WithdrawReviewOnCompanyBusinessReport = Mutation<WithdrawReviewOnCompanyBusinessReportResponse, [number]>
export type RequestChangesOnCompanyBusinessReport = Mutation<RequestChangesOnCompanyBusinessReportResponse, [number]>
export type CompleteCompanyBusinessReport = Mutation<CompleteCompanyBusinessReportResponse, [number]>
export type IncompleteCompanyBusinessReport = Mutation<IncompleteCompanyBusinessReportResponse, [number]>
export type ExportingAllCompanyBusinessReport = Mutation<void, [number]>

export interface UseCompanyBusinessReportPageOptions {
  page: BasicPaginationInput
  condition: CompanyBusinessReportCondition
  orderBy: CompanyBusinessReportOrder
}

export type UpdateCompanyBusinessReportResponse =
  & CompanyBusinessReportWithIdFrag
  & CompanyBusinessReportWithStatusFrag
  & CompanyBusinessReportWithRecordsFrag
export type RequestReviewOnCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse
export type StartReviewOnCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse
export type WithdrawReviewOnCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse
export type RequestChangesOnCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse
export type CompleteCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse
export type IncompleteCompanyBusinessReportResponse = UpdateCompanyBusinessReportResponse

export function useCompanyBusinessReportPage(
  options: MaybeRefOrGetter<UseCompanyBusinessReportPageOptions>
): CompanyBusinessReportPage {
  return useQuery(async () => {
    const o = toValue(options)
    return (await new CompanyBusinessReportRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )).data.companyBusinessReports
  }, {
    watch: () => toValue(options)
  })
}

export function useCompanyBusinessReportItem(id: MaybeRefOrGetter<number>, options?: UseQueryOptions): CompanyBusinessReportItem {
  return useQuery(async () => {
    return (await new CompanyBusinessReportRequest().fetch(toValue(id))).data.companyBusinessReport
  }, {
    watch: () => toValue(id),
    ...options
  })
}

export function useCompanyBusinessReportItemForLocalNav(id: MaybeRefOrGetter<number>, options?: UseQueryOptions): CompanyBusinessReportForLocalNavItem {
  return useQuery(async () => {
    return (await new CompanyBusinessReportRequest().fetchForLocalNav(toValue(id))).data.companyBusinessReport
  }, {
    watch: () => toValue(id),
    ...options
  })
}

export function usePreviousCompanyBusinessReportItem(id: MaybeRefOrGetter<number>, options?: UseQueryOptions) {
  return useQuery(async () => {
    return (await new CompanyBusinessReportRequest().fetchPrevious(toValue(id))).data.companyBusinessReport
  }, {
    watch: () => toValue(id),
    ...options
  })
}

export function useCopiableCompanyBusinessReportList(
  report: MaybeRefOrGetter<CompanyBusinessReportWithIdFrag & CompanyBusinessReportWithCompanyFrag>,
  options?: UseQueryOptions
): CompanyBusinessReportForCopyList {
  return useQuery(async () => {
    const r = toValue(report)
    return (await new CompanyBusinessReportRequest().fetchPage(
      { page: 0, perPage: 50 },
      { company: [r.company.id] },
      { field: CompanyBusinessReportOrderField.CreatedAt, direction: OrderDirection.Desc }
    )).data.companyBusinessReports.items.filter((cbr) => cbr.id !== r.id)
  }, {
    watch: () => toValue(report),
    ...options
  })
}

export function useUpdateCompanyBusinessReport(): UpdateCompanyBusinessReport {
  return useMutation(async (_, id, input) => {
    return (await new CompanyBusinessReportRequest().update(id, input)).data.companyBusinessReport
  })
}

export function useRequestReviewOnCompanyBusinessReport(): RequestReviewOnCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().requestReview(id)).data.companyBusinessReport
  })
}

export function useStartReviewOnCompanyBusinessReport(): StartReviewOnCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().startReview(id)).data.companyBusinessReport
  })
}

export function useWithdrawReviewOnCompanyBusinessReport(): WithdrawReviewOnCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().withdrawReview(id)).data.companyBusinessReport
  })
}

export function useRequestChangesOnCompanyBusinessReport(): RequestChangesOnCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().requestChanges(id)).data.companyBusinessReport
  })
}

export function useCompleteCompanyBusinessReport(): CompleteCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().complete(id)).data.companyBusinessReport
  })
}

export function useIncompleteCompanyBusinessReport(): IncompleteCompanyBusinessReport {
  return useMutation(async (_, id) => {
    return (await new CompanyBusinessReportRequest().incomplete(id)).data.companyBusinessReport
  })
}

export function useExportingAllCompanyBusinessReport(): ExportingAllCompanyBusinessReport {
  return useMutation(async (_, fundReportId) => {
    await new CompanyBusinessReportRequest().exportAll(fundReportId)
  })
}

export function useDownloadCompanyBusinessReport() {
  return useMutation(async (_, id) => {
    await new CompanyBusinessReportRequest().export(id)
  })
}
