<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { usePower } from 'sefirot/composables/Power'
import { type Opportunity } from '@/models/Opportunity'
import { useOpportunityPolicy } from '@/composables/opportunity/OpportunityPolicy'
import OpportunityFormUpdateBusiness from './OpportunityFormUpdateBusiness.vue'

const props = defineProps<{
  opportunity: Opportunity
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useOpportunityPolicy(() => props.opportunity)
const { state: modal, on, off } = usePower(false)
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Business
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate"
              :icon="IconNotePencil"
              @click="on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="1" gap="24">
        <SDescItem>
          <SDescLabel value="Financing plan" />
          <SDescText :value="opportunity.financingPlan" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Service description" />
          <SDescText :value="opportunity.serviceDescription" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Track record" />
          <SDescText :value="opportunity.trackRecord" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Business plans" />
          <SDescText :value="opportunity.businessPlans" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Competitive advantage" />
          <SDescText :value="opportunity.competitiveAdvantages" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Business model" />
          <SDescText :value="opportunity.businessModel" pre-wrap />
        </SDescItem>
        <SDescItem>
          <SDescLabel value="Past financing" />
          <SDescText :value="opportunity.pastFinancings" pre-wrap />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="modal">
      <OpportunityFormUpdateBusiness
        :opportunity="opportunity"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </SCard>
</template>
