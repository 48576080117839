import {
  CreateFundClosingCheckListDocument,
  type CreateFundClosingCheckListInput,
  FundClosingCheckListDocument,
  type FundClosingCheckListOrder,
  FundClosingCheckListOrderField,
  FundClosingCheckListPageDocument,
  OrderDirection,
  StartExportingFundClosingCheckListDocument
} from '@/graphql'
import { type BasicPaginationInput, Request } from './Request'

export type { BasicPaginationInput, FundClosingCheckListOrder, CreateFundClosingCheckListInput }
export { FundClosingCheckListOrderField, OrderDirection }

export class FundClosingCheckListRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    orderBy: FundClosingCheckListOrder
  ) {
    return this.request({
      query: FundClosingCheckListPageDocument,
      variables: { page, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: FundClosingCheckListDocument,
      variables: { id }
    })
  }

  create(input: CreateFundClosingCheckListInput) {
    return this.request({
      query: CreateFundClosingCheckListDocument,
      variables: { input }
    })
  }

  export(id: number) {
    return this.request({
      query: StartExportingFundClosingCheckListDocument,
      variables: { id }
    })
  }
}
