<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxValue } from 'sefirot/validation/rules'
import { watch } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { Currency } from '@/models/Currency'
import { useCompanyPortfolioDataUpdateCapital } from '@/composables/company/CompanyData'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'
import DInputMoney from '@/components/DInputMoney.vue'

export interface Data {
  capitalCurrency: Currency | null
  capitalAmount: string | null
  capitalNotes: string | null
}

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data: currencies } = useCurrencyList()

const { data } = useData<Data>({
  capitalCurrency:
    props.portfolioData.capital?.currency
    ?? (currencies.value?.length ? new Currency(currencies.value[0]) : null),
  capitalAmount: props.portfolioData.capital?.value ?? null,
  capitalNotes: props.portfolioData.capitalNotes
})

const { validation, validateAndNotify } = useValidation(data, {
  capitalAmount: {
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  }
})

watch(currencies, () => {
  if (!data.value.capitalCurrency && currencies.value?.length) {
    data.value.capitalCurrency = new Currency(currencies.value[0])
  }
}, { once: true })

const { execute, loading } = useCompanyPortfolioDataUpdateCapital(props.portfolioData.companyId!, () => ({
  capital: typeof data.value.capitalAmount === 'string'
    ? { currencyId: data.value.capitalCurrency!.id!, value: data.value.capitalAmount }
    : null,
  capitalNotes: data.value.capitalNotes
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Capital</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <DInputMoney
          label="Capital"
          placeholder="10000"
          separator
          v-model="data.capitalAmount"
          v-model:currency="data.capitalCurrency"
          :currencies="(currencies as any)"
          :validation="validation.capitalAmount"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Notes"
          placeholder="Add any notes about the capital."
          :rows="8"
          v-model="data.capitalNotes"
          :validation="validation.capitalNotes"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
