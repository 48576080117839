<script setup lang="ts">
import { useValidation } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import * as Field from '@/support/Field'
import DFieldsInputSelect from './DFieldsInputSelect.vue'
import DFieldsInputText from './DFieldsInputText.vue'
import DFieldsInputTextarea from './DFieldsInputTextarea.vue'

const props = defineProps<{
  fields: Field.Field[]
  modelValue: Field.Data
}>()

const emit = defineEmits<{
  (e: 'update:model-value', data: Field.Data): void
}>()

const value = computed(() => props.modelValue)

const { validation } = useValidation(
  value,
  Field.createRules(props.fields, value)
)

function getSpan(field: Field.Field) {
  switch (field.col) {
    case 6: return '3'
    case 12: return '6'
  }
}

function isEditable(field: Field.Field) {
  return Field.isEditable(field, props.modelValue)
}

function isVisible(field: Field.Field) {
  return Field.isVisible(field, props.modelValue)
}

function update(field: Field.Field, value: any) {
  emit('update:model-value', {
    ...props.modelValue,
    [field.name]: value
  })
}
</script>

<template>
  <DFormGrid class="DFields">
    <template v-for="(field, index) in fields">
      <DFormGridItem v-if="isVisible(field)" :key="index" :span="getSpan(field)">
        <DFieldsInputText
          v-if="field.__typename === 'TextInputField'"
          :field="field"
          :model-value="modelValue[field.name] ?? null"
          :validation="validation[field.name]"
          :editable="isEditable(field)"
          @update:model-value="v => update(field, v)"
        />
        <DFieldsInputTextarea
          v-else-if="field.__typename === 'TextareaInputField'"
          :field="field"
          :model-value="modelValue[field.name] ?? null"
          :validation="validation[field.name]"
          :editable="isEditable(field)"
          @update:model-value="v => update(field, v)"
        />
        <DFieldsInputSelect
          v-else-if="field.__typename === 'SelectInputField'"
          :field="field"
          :model-value="modelValue[field.name] ?? null"
          :validation="validation[field.name]"
          :editable="isEditable(field)"
          @update:model-value="v => update(field, v)"
        />
      </DFormGridItem>
    </template>
  </DFormGrid>
</template>
