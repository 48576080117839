import { Model } from './Model'
import { User } from './User'

export class BallotVoter extends Model {
  id?: number
  user: User | null
  canReject?: boolean

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.user = this.hasOne(User, data.user)
    this.canReject = data.canReject
  }
}
