import { orderBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type OpportunityPromotedSource } from '@/graphql'
import { OpportunityPromotedSourceRequest } from '@/requests/OpportunityPromotedSourceRequest'
import { useCache } from '@/stores/Cache'

export type OpportunityPromotedSourceList = Query<OpportunityPromotedSource[]>
export type GetOpportunityPromotedSourceList = Get<OpportunityPromotedSource[]>

export function useOpportunityPromotedSourceList(): OpportunityPromotedSourceList {
  return useQuery(() => fetchOpportunityPromotedSourceList())
}

export function useOpportunityPromotedSourceListCache(): OpportunityPromotedSourceList {
  const cache = useCache()
  return useQuery(() => {
    return cache.remember('repo-opportunity-promoted-source-list', fetchOpportunityPromotedSourceList)
  })
}

export function useGetOpportunityPromotedSourceList(): GetOpportunityPromotedSourceList {
  return useGet(() => fetchOpportunityPromotedSourceList())
}

async function fetchOpportunityPromotedSourceList(): Promise<OpportunityPromotedSource[]> {
  const res = await new OpportunityPromotedSourceRequest().fetchAll()
  return orderBy(res.data.opportunityPromotedSources, (o) => o.rank)
}
