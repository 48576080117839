<script setup lang="ts">
import { type DealWithRecordsFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'

defineProps<{
  deal: DealWithRecordsFrag
}>()

const dealOps = useDealOps()
</script>

<template>
  <DCard title="System information">
    <DCardGrid>
      <DCardGridItem span="2">
        <DCardGridLabel text="Updated by" />
        <DCardGridRecord :by="deal.updatedBy" :date="dealOps.updatedAt(deal)" />
      </DCardGridItem>
      <DCardGridItem span="2">
        <DCardGridLabel text="Created by" />
        <DCardGridRecord :by="deal.createdBy" :date="dealOps.createdAt(deal)" />
      </DCardGridItem>
    </DCardGrid>
  </DCard>
</template>
