<script setup lang="ts">
import SAvatar from 'sefirot/components/SAvatar.vue'
import { computed } from 'vue'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  users?: any
}>()

const userOps = useUserOps()

const _users = computed(() => {
  return props.users
    ? Array.isArray(props.users) ? props.users : [props.users]
    : []
})

function getUserName(user: any) {
  if (user.name) {
    return user.name
  }

  return userOps.name(user)
}
</script>

<template>
  <div class="DCardInfoUsers">
    <div v-for="user in _users" :key="(user as any).id" class="user">
      <div class="avatar">
        <SAvatar size="mini" :avatar="userOps.avatarPath(user)" :name="getUserName(user)" />
      </div>
      <p class="name">
        {{ getUserName(user) }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DCardInfoUsers {
  flex-grow: 1;
  padding: 4px 0;
}

.user {
  display: flex;
  align-items: center;
  height: 32px;
}

.name {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
}
</style>
