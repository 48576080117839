import { CompanyParticipationAuthority } from '@/graphql'
import { type Day, Model } from './Model'
import { User } from './User'

export { CompanyParticipationAuthority }

export const CompanyParticipationAuthorities = {
  [CompanyParticipationAuthority.PrimaryInCharge]: 'Primary In-charge',
  [CompanyParticipationAuthority.DeputyInCharge]: 'Deputy In-charge',
  [CompanyParticipationAuthority.Participant]: 'Participant'
} as const

export const CompanyParticipationAuthoritiesAbbr = {
  [CompanyParticipationAuthority.PrimaryInCharge]: 'PIC',
  [CompanyParticipationAuthority.DeputyInCharge]: 'DIC',
  [CompanyParticipationAuthority.Participant]: 'Part'
} as const

export class CompanyParticipation extends Model {
  id?: number
  userId?: number
  user: User | null
  authority?: CompanyParticipationAuthority
  since: Day | null
  until: Day | null
  assignedBy: User | null
  unassignedBy: User | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.userId = data.userId
    this.user = this.hasOne(User, data.user)
    this.authority = data.authority
    this.since = this.day(data.since)
    this.until = this.day(data.until)
    this.assignedBy = this.hasOne(User, data.assignedBy)
    this.unassignedBy = this.hasOne(User, data.unassignedBy)
  }

  get authorityValue(): string {
    return CompanyParticipationAuthorities[this.authority!]
  }

  get authorityAbbr(): string {
    return CompanyParticipationAuthoritiesAbbr[this.authority!]
  }
}
