<script setup lang="ts">
import SInputCheckboxes from 'sefirot/components/SInputCheckboxes.vue'

export interface Options {
  label: string
  value: string
}

defineProps<{
  title: string
  options: Options[]
}>()

const model = defineModel<string[]>({ required: true })

defineEmits<{
  'change': [options: Options[]]
}>()
</script>

<template>
  <SCard>
    <SCardBlock class="header">
      <div class="title">
        {{ title }}
      </div>
    </SCardBlock>
    <SCardBlock class="options">
      <SInputCheckboxes
        :options="options"
        v-model="model"
      />
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 48px;
}

.title {
  font-size: 14px;
  font-weight: 500;
}

.options {
  padding: 8px 16px;
}
</style>
