<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { type BalanceConfirmationEmail, type BalanceConfirmationMail } from '@/graph/BalanceConfirmation'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import {
  useCompanyPortfolioDataBalanceConfirmationEmailItem,
  useCompanyPortfolioDataBalanceConfirmationMailItem
} from '@/composables/repos/CompanyPortfolioDataRepo'
import CompanyPortfolioDataDescBalanceConfirmationEmail from './CompanyPortfolioDataDescBalanceConfirmationEmail.vue'
import CompanyPortfolioDataDescBalanceConfirmationMail from './CompanyPortfolioDataDescBalanceConfirmationMail.vue'
import CompanyPortfolioDataFormDeleteBalanceConfirmationEmail from './CompanyPortfolioDataFormDeleteBalanceConfirmationEmail.vue'
import CompanyPortfolioDataFormDeleteBalanceConfirmationMail from './CompanyPortfolioDataFormDeleteBalanceConfirmationMail.vue'
import CompanyPortfolioDataFormUpdateBalanceConfirmationEmail from './CompanyPortfolioDataFormUpdateBalanceConfirmationEmail.vue'
import CompanyPortfolioDataFormUpdateBalanceConfirmationMail from './CompanyPortfolioDataFormUpdateBalanceConfirmationMail.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const { data: balanceConfirmationEmail } = useCompanyPortfolioDataBalanceConfirmationEmailItem(props.portfolioData.companyId!)
const { data: balanceConfirmationMail } = useCompanyPortfolioDataBalanceConfirmationMailItem(props.portfolioData.companyId!)

const upsertBalanceConfirmationEmailDialog = usePower()
const deleteBalanceConfirmationEmailDialog = usePower()

const upsertBalanceConfirmationMailDialog = usePower()
const deleteBalanceConfirmationMailDialog = usePower()

function onEmailUpdated(newBalanceConfirmationEmail: BalanceConfirmationEmail) {
  upsertBalanceConfirmationEmailDialog.off()
  balanceConfirmationEmail.value = newBalanceConfirmationEmail
}

function onEmailDeleted() {
  deleteBalanceConfirmationEmailDialog.off()
  balanceConfirmationEmail.value = null
}

function onMailUpdated(newBalanceConfirmationMail: BalanceConfirmationMail) {
  upsertBalanceConfirmationMailDialog.off()
  balanceConfirmationMail.value = newBalanceConfirmationMail
}

function onMailDeleted() {
  deleteBalanceConfirmationMailDialog.off()
  balanceConfirmationMail.value = null
}
</script>

<template>
  <SCard class="CompanyPortfolioDataDescBalanceConfirmationAddress">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">
            <STrans lang="en">Balance confirmation addresses</STrans>
            <STrans lang="ja">残高確認送付先</STrans>
          </SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <div class="grid">
      <CompanyPortfolioDataDescBalanceConfirmationEmail
        v-if="balanceConfirmationEmail !== undefined"
        :balance-confirmation-email="balanceConfirmationEmail"
        @add="upsertBalanceConfirmationEmailDialog.on"
        @update="upsertBalanceConfirmationEmailDialog.on"
        @delete="deleteBalanceConfirmationEmailDialog.on"
      />
      <CompanyPortfolioDataDescBalanceConfirmationMail
        v-if="balanceConfirmationMail !== undefined"
        :balance-confirmation-mail="balanceConfirmationMail"
        @add="upsertBalanceConfirmationMailDialog.on"
        @update="upsertBalanceConfirmationMailDialog.on"
        @delete="deleteBalanceConfirmationMailDialog.on"
      />
    </div>

    <SModal :open="upsertBalanceConfirmationEmailDialog.state.value" @close="upsertBalanceConfirmationEmailDialog.off">
      <CompanyPortfolioDataFormUpdateBalanceConfirmationEmail
        :company-id="props.portfolioData.companyId!"
        :notes="balanceConfirmationEmail?.notes ?? null"
        :destinations="balanceConfirmationEmail?.destinations ?? []"
        @cancel="upsertBalanceConfirmationEmailDialog.off"
        @updated="onEmailUpdated"
      />
    </SModal>

    <SModal :open="deleteBalanceConfirmationEmailDialog.state.value" @close="deleteBalanceConfirmationEmailDialog.off">
      <CompanyPortfolioDataFormDeleteBalanceConfirmationEmail
        :company-id="props.portfolioData.companyId!"
        @cancel="deleteBalanceConfirmationEmailDialog.off"
        @deleted="onEmailDeleted"
      />
    </SModal>

    <SModal :open="upsertBalanceConfirmationMailDialog.state.value" @close="upsertBalanceConfirmationMailDialog.off">
      <CompanyPortfolioDataFormUpdateBalanceConfirmationMail
        :company-id="props.portfolioData.companyId!"
        :zip-code="balanceConfirmationMail?.zipCode ?? null"
        :address="balanceConfirmationMail?.address ?? null"
        :address-house-number="balanceConfirmationMail?.addressHouseNumber ?? null"
        :notes="balanceConfirmationMail?.notes ?? null"
        :destinations="balanceConfirmationMail?.destinations ?? []"
        @cancel="upsertBalanceConfirmationMailDialog.off"
        @updated="onMailUpdated"
      />
    </SModal>

    <SModal :open="deleteBalanceConfirmationMailDialog.state.value" @close="deleteBalanceConfirmationMailDialog.off">
      <CompanyPortfolioDataFormDeleteBalanceConfirmationMail
        :company-id="props.portfolioData.companyId!"
        @cancel="deleteBalanceConfirmationMailDialog.off"
        @deleted="onMailDeleted"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataDescBalanceConfirmationAddress {
  overflow: hidden;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: var(--c-bg-elv-2);
}
</style>
