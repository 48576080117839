import { type Day, day } from 'sefirot/support/Day'
import {
  type DealReportWithDateFrag,
  type DealReportWithTimestampFrag
} from '@/graphql'
import { toDateTextOrNull, toDayOrNull, toTimestampText } from '@/support/Day'
import { defineOps } from './Ops'

export const useDealReportOps = defineOps(({ locale }) => {
  function investedDate(report: DealReportWithDateFrag): Day | null {
    return toDayOrNull(report.investedDate)
  }

  function investedDateText(report: DealReportWithDateFrag): string | null {
    return toDateTextOrNull(report.investedDate, locale)
  }

  function reportedDate(report: DealReportWithDateFrag): Day | null {
    return toDayOrNull(report.reportedDate)
  }

  function reportedDateText(report: DealReportWithDateFrag): string | null {
    return toDateTextOrNull(report.reportedDate)
  }

  function createdAt(report: DealReportWithTimestampFrag): Day | null {
    return day(report.createdAt)
  }

  function createdAtText(report: DealReportWithTimestampFrag): string | null {
    return toTimestampText(report.createdAt, locale)
  }

  function updatedAt(report: DealReportWithTimestampFrag): Day | null {
    return day(report.updatedAt)
  }

  function updatedAtText(report: DealReportWithTimestampFrag): string | null {
    return toTimestampText(report.updatedAt, locale)
  }

  return {
    investedDate,
    investedDateText,
    reportedDate,
    reportedDateText,
    createdAt,
    createdAtText,
    updatedAt,
    updatedAtText
  }
})
