<script setup lang="ts">
import { orderBy } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed, watch } from 'vue'
import {
  type DealEventAndBallotFrag,
  type DealEventFrag,
  type DealEventTemplateAndBallotFrag,
  type DealEventTemplateFrag,
  type DealWithIdFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealEventOps } from '@/composables/ops/DealEventOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealCardEventListAdd from './DealCardEventListAdd.vue'
import DealCardEventListView from './DealCardEventListView.vue'

const props = defineProps<{
  deal: DealWithIdFrag & WithPermissionFrag
  template: DealEventTemplateFrag | DealEventTemplateAndBallotFrag
  events: DealEventFrag[] | DealEventAndBallotFrag[]
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'added'): void
  (e: 'updated'): void
}>()

const { canUpdate, canEditEventWithBallot } = useDealPolicy(props.deal)

const dealEventOps = useDealEventOps()

const addForm = usePower()

const orderedEvents = computed(() => orderBy(props.events, ['date']))
const latestEvent = computed(() => orderedEvents.value.slice(-1)[0])

const status = computed(() => {
  if (!latestEvent.value) {
    return 'Todo'
  } else if (dealEventOps.isSkipped(latestEvent.value)) {
    return 'Skipped'
  } else if (dealEventOps.isApproved(latestEvent.value)) {
    return 'Approved'
  } else if (dealEventOps.isConditionallyApproved(latestEvent.value)) {
    return 'Cond. Approved'
  } else if (dealEventOps.isRejected(latestEvent.value)) {
    return 'Rejected'
  } else {
    return 'Executed'
  }
})

const completedAt = computed(() => {
  return latestEvent.value
    ? dealEventOps.date(latestEvent.value).format('YYYY/MM/DD')
    : '—'
})

const canEdit = computed(() => {
  return props.template.event.hasBallot
    ? canEditEventWithBallot.value
    : canUpdate.value
})

watch(() => props.events, (events) => {
  (canEdit.value && !events.length) ? addForm.on() : addForm.off()
}, { immediate: true })

function onAdded() {
  latestEvent.value ? emit('updated') : emit('added')
}
</script>

<template>
  <DCard title="Action results" :collapsable="false" closable @close="$emit('close')">
    <div class="container">
      <div class="overview">
        <div class="overview-box">
          <div class="overview-item">
            <div class="overview-label">Action</div>
            <div class="overview-value">{{ template.event.name }}</div>
          </div>
          <div class="overview-item">
            <div class="overview-label">Status</div>
            <div class="overview-value">{{ status }}</div>
          </div>
          <div class="overview-item">
            <div class="overview-label">Completed at</div>
            <div class="overview-value">{{ completedAt }}</div>
          </div>
        </div>
      </div>

      <div v-for="event in orderedEvents" :key="event.id" class="results">
        <div class="result">
          <DealCardEventListView
            :deal="deal"
            :template="template"
            :event="event"
            @saved="$emit('updated')"
          />
        </div>
      </div>

      <DealCardEventListAdd
        v-if="addForm.state.value"
        :deal="deal"
        :template="template"
        :cancelable="!!latestEvent"
        @cancel="addForm.off"
        @saved="onAdded"
      />

      <div v-if="canEdit && latestEvent && !addForm.state.value" class="more">
        <SButton
          size="small"
          mode="info"
          label="Add new result"
          @click="addForm.on"
        />
      </div>

      <div v-if="!canEdit && !latestEvent" class="empty">
        No result have been added yet.
      </div>
    </div>
  </DCard>
</template>

<style scoped lang="postcss">
.container {
  display: grid;
  gap: 16px;
  padding: 24px;
}

.overview-box {
  display: grid;
  grid-template-columns: 256px 144px 1fr;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 16px 24px;
}

.overview-label {
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: var(--c-text-2);
}

.overview-value {
  font-size: 14px;
  font-weight: 600;
}

.more {
  display: flex;
  justify-content: center;
}

.empty {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 16px 24px;
  font-size: 14px;
  color: var(--c-text-2);
  background-color: var(--c-soft);
}
</style>
