import { useErrorHandler } from 'sefirot/composables/Error'
import { type Plugin } from 'vue'
import { useAuth } from '@/stores/Auth'

export const sentry: Plugin = {
  install(app) {
    const auth = useAuth()

    app.config.errorHandler = useErrorHandler({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV || import.meta.env.VITE_APP_ENV || 'local',
      user: () => ({
        id: auth.user?.id,
        username: auth.user ? `${auth.user.firstNameEn} ${auth.user.lastNameEn}` : undefined,
        email: auth.user?.email
      })
    })
  }
}
