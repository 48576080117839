<script setup lang="ts">
import { xor } from 'lodash'
import SInputBase from 'sefirot/components/SInputBase.vue'
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { useGetSurveyTargetFundGroupList } from '@/composables/repos/FundRepo'

const props = defineProps<{
  modelValue: number[]
  ready?: boolean
}>()

const model = defineModel<number[]>({ required: true })

const { t } = useTrans({
  en: {
    i_funds_label: 'Funds to disclose the deal',
    select_all_empty: 'Select all funds',
    select_all_filled: (n: number) => n === 1 ? '1 fund selected' : `${n} funds selected`
  },
  ja: {
    i_funds_label: '案件を公開するファンド',
    select_all_empty: '全てのファンドを選択',
    select_all_filled: (n: number) => `${n}個のファンドを選択中`
  }
})

const { execute: getFunds } = useGetSurveyTargetFundGroupList()

const funds = await getFunds()

const isNoneSelected = computed(() => {
  return props.modelValue.length === 0
})

const isAllSelected = computed(() => {
  return props.modelValue.length === funds.length
})

const selectAllText = computed(() => {
  return isNoneSelected.value
    ? t.select_all_empty
    : t.select_all_filled(props.modelValue.length)
})

const selectAllValue = computed(() => {
  return isNoneSelected.value
    ? false
    : isAllSelected.value ? true : 'indeterminate'
})

function onSelectAll() {
  model.value = isAllSelected.value ? [] : funds.map((f) => f.id)
}

function onChange(value: number) {
  model.value = xor(props.modelValue, [value])
}
</script>

<template>
  <SInputBase class="small" :label="t.i_funds_label">
    <SCard>
      <SCardBlock bg="elv-4">
        <div class="input" role="button" @click="onSelectAll">
          <div class="checkbox">
            <SInputCheckbox :value="selectAllValue" />
          </div>
          <div class="text">
            {{ selectAllText }}
          </div>
        </div>
      </SCardBlock>
      <SCardBlock v-for="fund in funds" :key="fund.id">
        <div class="input" role="button" @click="onChange(fund.id)">
          <div class="checkbox">
            <SInputCheckbox :value="modelValue.includes(fund.id)" />
          </div>
          <div class="name">
            {{ fund.name }}
          </div>
          <div class="lp">
            {{ fund.lp }}
          </div>
        </div>
      </SCardBlock>
    </SCard>
  </SInputBase>
</template>

<style scoped lang="postcss">
.input {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--c-bg-elv-4);
  }
}

.checkbox {
  padding-right: 16px;
  flex-shrink: 0;
}

.text,
.name,
.lp {
  font-size: 14px;
  transition: color 0.25s;
}

.name {
  flex-shrink: 0;
  width: 128px;
}

.lp {
  flex-grow: 1;
  color: var(--c-text-2);
}
</style>
