import {
  type BasicPaginationInput,
  CreateCompleteSurveyDocument,
  type CreateCompleteSurveyInput,
  CreateSurveyDocument,
  type CreateSurveyInput,
  SurveyDocument,
  type SurveysCondition,
  SurveysDocument
} from '@/graphql'
import { Request } from './Request'

export class SurveyRequest extends Request {
  async fetchPage(page: BasicPaginationInput, condition: SurveysCondition) {
    return this.request({
      query: SurveysDocument,
      variables: {
        page,
        condition
      }
    })
  }

  async fetch(id: number) {
    return this.request({
      query: SurveyDocument,
      variables: {
        id
      }
    })
  }

  async create(input: CreateSurveyInput) {
    return this.request({
      query: CreateSurveyDocument,
      variables: {
        input
      }
    })
  }

  async createComplete(input: CreateCompleteSurveyInput) {
    return this.request({
      query: CreateCompleteSurveyDocument,
      variables: {
        input
      }
    })
  }
}
