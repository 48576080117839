<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCanViewCompanyVec } from '@/composables/policies/CompanyVecPolicy'
import { useCompanyAndNameItem } from '@/composables/repos/CompanyRepo'
import { useCompanyVecItem } from '@/composables/repos/CompanyVecRepo'
import CompanyVecDesc from '@/components/company/CompanyVecDesc.vue'

const route = useRoute()

const companyId = computed(() => Number(route.params.id))

const companyOps = useCompanyOps()

const { data: company, execute: executeCompanyItem } = useCompanyAndNameItem(companyId.value)

const { data: companyVec, execute: executeCompanyVecItem } = useCompanyVecItem(companyId.value)

const canView = useCanViewCompanyVec(companyVec)

// Wait until both `company` and `companyPortfolioData` are fetched otherwise
// some components might get undefined item.
const authorize = computed(() => {
  // Fetched.
  if (company.value === undefined || companyVec.value === undefined) {
    return null
  }

  // Not found.
  if (company.value === null || companyVec.value === null) {
    return false
  }

  // Fetched and exists, now policy can be applied.
  return canView.value.ok
})

const title = computed(() => {
  return company.value
    ? `${companyOps.name(company.value)} - VEC`
    : undefined
})

function execute() {
  executeCompanyItem()
  executeCompanyVecItem()
}
</script>

<template>
  <DPage class="CompaniesIdVec" :title="title" :authorize="authorize">
    <div v-if="company && companyVec" class="container">
      <CompanyVecDesc :company-vec="companyVec" @updated="execute" />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdVec {
  padding: 32px 32px 128px;
}

.container {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
</style>
