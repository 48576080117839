import { type RouteRecordRaw } from 'vue-router'
import DashboardCapitalistActivities from '@/pages/dashboard/capitalist-activities.vue'
import DashboardCmc from '@/pages/dashboard/cmc.vue'
import DashboardCompanyNews from '@/pages/dashboard/company-news.vue'
import DashboardDdTeam from '@/pages/dashboard/dd-team.vue'
import DashboardOverview from '@/pages/dashboard/overview.vue'
import Dashboard from '@/pages/dashboard.vue'

export const dashboard: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      { path: '', redirect: '/dashboard/overview' },
      { path: 'overview', component: DashboardOverview },
      { path: 'company-news', component: DashboardCompanyNews },
      { path: 'dd-team', component: DashboardDdTeam },
      { path: 'cmc', component: DashboardCmc },
      { path: 'capitalist-activities', component: DashboardCapitalistActivities }
    ]
  }
]
