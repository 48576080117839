<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { type CompanyCautionNote } from '@/graph/Company'
import CompanyCautionNoteFormCreate from '../company-caution-note/CompanyCautionNoteFormCreate.vue'

defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  added: [companyCautionNote: CompanyCautionNote]
}>()

const { t } = useTrans({
  en: {
    add: 'Add internal memo'
  },
  ja: {
    add: '社内メモを追加'
  }
})

const addCautionNoteDialog = usePower()

function onCreated(cautionNote: CompanyCautionNote) {
  addCautionNoteDialog.off()
  emit('added', cautionNote)
}
</script>

<template>
  <SCard class="CompanyCardAddCautionNote">
    <SCardBlock class="box">
      <div class="text">
        <STrans lang="en">You can record internal memo about this company. These memo will be prominently displayed at the top of this page.</STrans>
        <STrans lang="ja">この会社に関する社内向けのメモを記録することができます。このメモは当ページの最上部に目立つ形で表示されます。</STrans>
      </div>
      <SButton
        size="small"
        :label="t.add"
        block
        @click="addCautionNoteDialog.on"
      />
    </SCardBlock>

    <SModal :open="addCautionNoteDialog.state.value" @close="addCautionNoteDialog.off">
      <CompanyCautionNoteFormCreate
        :company-id="companyId"
        @cancel="addCautionNoteDialog.off"
        @created="onCreated"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.text {
  line-height: 20px;
  font-size: 12px;
  color: var(--c-text-2);
}
</style>
