import { StatInvestedDealsDocument } from '@/graphql'
import { Request } from './Request'

export default class StatInvestedDealsRequest extends Request {
  fetch(from: string, to: string) {
    return this.request({
      query: StatInvestedDealsDocument,
      variables: { from, to }
    })
  }
}
