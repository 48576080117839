<script setup lang="ts">
import SInputBase from 'sefirot/components/SInputBase.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputHMS from 'sefirot/components/SInputHMS.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day, createHms, day } from 'sefirot/support/Day'
import { hms, maxLength, required, requiredHms } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsExecutivePresentationSubmitDoc } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { useSubmitDocsForEp } from '@/composables/repos/DealEntryRepo'
import DealEntryEpDocsComplete from './DealEntryEpDocsComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsExecutivePresentationSubmitDoc
}>()

const { t } = useTrans({
  en: {
    i_datetime_label: 'Date of the presentation *',
    i_datetime_help: 'Specify the date and time in your local time zone.',
    i_offline_info_label: 'Offline info *',
    i_offline_info_ph: 'Shirakaba MTG Room (GP only)',
    i_offline_info_help: 'Describe how people can attend the presentation in offline.',
    i_online_info_label: 'Online info *',
    i_online_info_ph: 'Zoom\nhttps://globalbrains.zoom.us/...\nMeeting ID: xxx xxxx xxxx\nPasscode: xxxxxx',
    i_online_info_help: 'Describe how people can attend the presentation in online.',
    i_business_desc_label: 'Business description *',
    i_business_desc_ph: 'Short description of what the company is doing.',
    i_file_link_label: 'Link to files *',
    i_file_link_ph: 'https://upload.hdedrive.com/ui/...\nPassword: xxxxxxxx\nThis URL will expire on...',
    i_notes_label: 'Notes',
    i_notes_ph: 'Fill in any additional information.',
    submit: 'Submit request'
  },
  ja: {
    i_datetime_label: '経営者プレゼン開催日 *',
    i_datetime_help: 'あなたが現在いる地域の時刻で入力してください。',
    i_offline_info_label: 'オフライン情報 *',
    i_offline_info_ph: '会議室: Shirakaba (GPのみ)',
    i_offline_info_help: 'オフラインで参加するための情報を入力してください。',
    i_online_info_label: 'オンライン情報 *',
    i_online_info_ph: 'Zoom\nhttps://globalbrains.zoom.us/...\nミーティングID: xxx xxxx xxxx\nパスコード: xxxxxx',
    i_online_info_help: 'オンラインで参加するための情報を入力してください。',
    i_business_desc_label: '事業内容 *',
    i_business_desc_ph: '事業内容を一文程度で入力してください',
    i_file_link_label: '資料へのリンク',
    i_file_link_ph: 'https://upload.hdedrive.com/ui/...\nパスワード: xxxxxxxx\nURLの有効期限: ...',
    i_notes_label: '備考',
    i_notes_ph: '特筆すべきことがあれば入力ください。',
    submit: '送信する'
  }
})

const router = useRouter()

const { execute: submitDocsForPrc, loading } = useSubmitDocsForEp(() => props.deal.id!, () => ({
  datetime: createDatetimeInput(),
  offlineInfo: data.value.offlineInfo!,
  onlineInfo: data.value.onlineInfo!,
  businessDescription: data.value.businessDescription!,
  fileLink: data.value.fileLink!,
  notes: data.value.notes
}))

const { data } = useData({
  date: null as Day | null,
  time: createHms(),
  offlineInfo: null as string | null,
  onlineInfo: null as string | null,
  businessDescription: null as string | null,
  fileLink: null as string | null,
  notes: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  date: { required: required() },
  time: { requiredHms: requiredHms(['h', 'm']), hms: hms(['h', 'm']) },
  offlineInfo: { required: required(), maxLength: maxLength(2000) },
  onlineInfo: { required: required(), maxLength: maxLength(2000) },
  businessDescription: { required: required(), maxLength: maxLength(160) },
  fileLink: { required: required(), maxLength: maxLength(4000) },
  notes: { maxLength: maxLength(2000) }
})

const completeDialog = usePower()

function createDatetimeInput() {
  const date = data.value.date!.format('YYYY-MM-DD')
  const time = `${data.value.time!.hour}:${data.value.time!.minute}:00`
  return day(`${date} ${time}`).toISOString()
}

async function submit() {
  if (await validateAndNotify()) {
    await submitDocsForPrc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryEpDocs">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SContent>
              <STrans lang="en">
                <h1>Submit Docs for Executive Presentation</h1>
                <p>You may submit the docs required for the Executive Presentation from this form. The file must be sent using <SLink href="https://upload.hdedrive.com/ui/globalbrains.com">HENNGE Secure Transfer</SLink>. The deadline for sending the docs are during the business day before the presentation.</p>
              </STrans>
              <STrans lang="ja">
                <h1>経営者プレゼン 資料送付</h1>
                <p>経営者プレゼンに必要な資料を送付するためのエントリーです。資料は<SLink href="https://upload.hdedrive.com/ui/globalbrains.com">HENNGE Secure Transfer</SLink>を使って送付してください。送付期日は経営者プレゼン開催の前営業日中です。</p>
              </STrans>
            </SContent>
            <SInputBase class="small" :label="t.i_datetime_label" :help="t.i_datetime_help">
              <div class="datetime">
                <SInputDate
                  v-model="data.date"
                  :validation="validation.date"
                />
                <SInputHMS
                  v-model="data.time"
                  no-second
                  :validation="validation.time"
                />
              </div>
            </SInputBase>
            <SInputTextarea
              :label="t.i_offline_info_label"
              :placeholder="t.i_offline_info_ph"
              :help="t.i_offline_info_help"
              :rows="4"
              v-model="data.offlineInfo"
              :validation="validation.offlineInfo"
            />
            <SInputTextarea
              :label="t.i_online_info_label"
              :placeholder="t.i_online_info_ph"
              :help="t.i_online_info_help"
              :rows="4"
              v-model="data.onlineInfo"
              :validation="validation.onlineInfo"
            />
            <SInputTextarea
              :label="t.i_business_desc_label"
              :placeholder="t.i_business_desc_ph"
              v-model="data.businessDescription"
              :rows="4"
              :validation="validation.businessDescription"
            />
            <SInputTextarea
              :label="t.i_file_link_label"
              :placeholder="t.i_file_link_ph"
              v-model="data.fileLink"
              :rows="4"
              :validation="validation.fileLink"
            />
            <SInputTextarea
              :label="t.i_notes_label"
              :placeholder="t.i_notes_ph"
              v-model="data.notes"
              :rows="4"
              :validation="validation.notes"
            />
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryEpDocsComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryEpDocs {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.datetime {
  display: flex;
  gap: 8px;
}
</style>
