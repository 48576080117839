<script setup lang="ts">
import NotePencil from '~icons/ph/note-pencil'
import IconTrash from '~icons/ph/trash'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computed, reactive, ref } from 'vue'
import { type CompanySupportLogWithMembers } from '@/graph/Company'
import { useCompanySupportLogOps } from '@/composables/ops/CompanySupportLogOps'
import { useUserOps } from '@/composables/ops/UserOps'
import CompanySupportLogDescMembers from './CompanySupportLogDescMembers.vue'
import CompanySupportLogFormDelete from './CompanySupportLogFormDelete.vue'
import CompanySupportLogFormUpdate from './CompanySupportLogFormUpdate.vue'

const props = defineProps<{
  supportLog: CompanySupportLogWithMembers
}>()

const emit = defineEmits<{
  updated: [supportLog: CompanySupportLogWithMembers]
  deleted: [supportLog: CompanySupportLogWithMembers]
}>()

const { t } = useTrans({
  en: {
    title: 'Title',
    description: 'Description',
    created_by: 'Created by',
    created_at: 'Created at',
    updated_by: 'Updated by',
    updated_at: 'Updated at'
  },
  ja: {
    title: 'タイトル',
    description: '支援内容',
    created_by: '作成者',
    created_at: '作成日時',
    updated_by: '更新者',
    updated_at: '更新日時'
  }
})

const userOps = useUserOps()
const slOps = useCompanySupportLogOps()

const updateDialog = reactive(usePower())
const deleteDialog = reactive(usePower())

const updatedSupportLog = ref<CompanySupportLogWithMembers | null>(null)
const deletedSupportLog = ref<CompanySupportLogWithMembers | null>(null)

const createdBy = computed(() => ({ avatar: userOps.avatarPath(props.supportLog.createdBy), name: userOps.name(props.supportLog.createdBy) }))
const updatedBy = computed(() => ({ avatar: userOps.avatarPath(props.supportLog.updatedBy), name: userOps.name(props.supportLog.updatedBy) }))

function onUpdated(supportLog: CompanySupportLogWithMembers) {
  updatedSupportLog.value = supportLog
  updateDialog.off()
}

function onUpdateDialogUnmounted() {
  if (updatedSupportLog.value) {
    emit('updated', updatedSupportLog.value)
  }
}

function onDeleted(supportLog: CompanySupportLogWithMembers) {
  deletedSupportLog.value = supportLog
  deleteDialog.off()
}

function onDeleteDialogUnmounted() {
  if (deletedSupportLog.value) {
    emit('deleted', deletedSupportLog.value)
  }
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="title">
            <div class="s-font-14 s-font-w-500">
              {{ slOps.displayId(supportLog) }}
            </div>
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              :icon="NotePencil"
              @click="updateDialog.on"
            />
            <SControlActionBarButton
              :icon="IconTrash"
              @click="deleteDialog.on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="4" gap="24">
        <SDescItem span="4">
          <SDescLabel>{{ t.title }}</SDescLabel>
          <SDescText :value="supportLog.title" />
        </SDescItem>

        <CompanySupportLogDescMembers
          :members="supportLog.members"
        />

        <SDescItem span="4">
          <SDescLabel>{{ t.description }}</SDescLabel>
          <SDescText :value="supportLog.description" />
        </SDescItem>

        <SDescItem span="1">
          <SDescLabel>{{ t.created_by }}</SDescLabel>
          <SDescAvatar :avatar="createdBy" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.created_at }}</SDescLabel>
          <SDescDay :value="slOps.createdAt(supportLog)" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.updated_by }}</SDescLabel>
          <SDescAvatar :avatar="updatedBy" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel>{{ t.updated_at }}</SDescLabel>
          <SDescDay :value="slOps.updatedAt(supportLog)" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="updateDialog.state">
      <CompanySupportLogFormUpdate
        :support-log="supportLog"
        @updated="onUpdated"
        @cancel="updateDialog.off"
        @vue:unmounted="onUpdateDialogUnmounted"
      />
    </SModal>
    <SModal :open="deleteDialog.state">
      <CompanySupportLogFormDelete
        :support-log="supportLog"
        @cancel="deleteDialog.off"
        @deleted="onDeleted"
        @vue:unmounted="onDeleteDialogUnmounted"
      />
    </SModal>
  </SCard>
</template>
