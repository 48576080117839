<script setup lang="ts">
import IconClipboardText from '~icons/ph/clipboard-text-bold'
import { cloneDeep, isEqual } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { FundClosingCheckListRecordOrderField, type UserFrag } from '@/graphql'
import { OrderDirection } from '@/composables/fund-report/FundReportData'
import { useUserOps } from '@/composables/ops/UserOps'
import { useFundClosingCheckListPolicy } from '@/composables/policies/FundClosingCheckListPolicy'
import { type FundClosingCheckListRecordOrder, usePage } from '@/composables/repos/FundClosingCheckListRecordRepo'
import { useExport, useItem } from '@/composables/repos/FundClosingCheckListRepo'
import FundClosingCheckListRecordFormAdd from './FundClosingCheckListRecordFormAdd.vue'

const route = useRoute()
const { state: modal, on, off } = usePower()
const userOps = useUserOps()

const orderBy = ref<FundClosingCheckListRecordOrder>({
  field: FundClosingCheckListRecordOrderField.CompanyName,
  direction: OrderDirection.Desc
})

const options = reactive({
  page: { page: 0, perPage: 50 },
  orderBy
})

const initialOptions = cloneDeep(options)

const fundClosingCheckListId = Number(route.params.id)
const { data, loading: loadingPage } = usePage(fundClosingCheckListId, options)
const { data: fundClosingCheckList } = useItem(fundClosingCheckListId)
const { execute, loading: loadingExport } = useExport(fundClosingCheckListId)

const { canExport } = useFundClosingCheckListPolicy(fundClosingCheckList as any)

useUrlQuerySync({
  state: options,
  casts: {
    'page.page': Number
  },
  exclude: ['page.perPage']
})

const isDirty = computed(() => !isEqual(options, initialOptions))

const table = useTable({
  orders: [
    'name',
    'assignee',
    'updatedAt',
    'spacer'
  ],

  columns: {
    name: {
      label: 'Name',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(FundClosingCheckListRecordOrderField.CompanyName, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(FundClosingCheckListRecordOrderField.CompanyName, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'text',
        icon: IconClipboardText,
        link: record.path,
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    assignee: {
      label: 'Assignee',
      cell: (users) => ({
        type: 'avatars',
        avatars: users.map((user: any) => ({
          image: userOps.avatarPath(user as UserFrag),
          name: user.firstNameEn ?? ''
        })),
        color: 'soft'
      })
    },
    updatedAt: {
      label: 'Updated at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(FundClosingCheckListRecordOrderField.UpdatedAt, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(FundClosingCheckListRecordOrderField.UpdatedAt, OrderDirection.Desc) } }
          ]
        }
      ])
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  },

  records: computed(() => data.value?.items.map((f) => {
    return {
      name: f.title(),
      path: f.path(),
      assignee: [f.company.primaryInCharge, f.company.deputyInCharge, ...f.company.participants].filter(Boolean),
      updatedAt: f.updatedAt?.format('YYYY/MM/DD')
    }
  })),

  total: computed(() => data.value?.page.total ?? 0),
  page: computed(() => data.value && data.value?.page.page + 1),
  perPage: computed(() => data.value?.page.perPage),
  loading: loadingPage,
  reset: isDirty,
  onPrev: () => { options.page.page = options.page.page - 1 },
  onNext: () => { options.page.page = options.page.page + 1 },
  onReset: reset
})

function reset() {
  options.page.page = 0
  orderBy.value = { field: FundClosingCheckListRecordOrderField.CompanyName, direction: OrderDirection.Desc }
}

function updateOrder(field: FundClosingCheckListRecordOrderField, direction: OrderDirection) {
  orderBy.value.field = field
  orderBy.value.direction = direction
}

async function download() {
  await execute()
  on()
}
</script>

<template>
  <div class="FundClosingCheckListRecordCatalog">
    <div class="header">
      <p class="title">Fund Closing Checklist Records</p>
      <SButton
        v-if="canExport"
        label="Export"
        mode="info"
        :loading="loadingExport"
        @click="download"
      />
    </div>
    <STable class="list" :options="table" />
    <SModal :open="modal">
      <FundClosingCheckListRecordFormAdd
        @close="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.FundClosingCheckListRecordCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 600;
}

.list :deep(.col-name)      { --table-col-width: 640px; }
.list :deep(.col-assignee)  { --table-col-width: 280px; }
.list :deep(.col-updatedAt) { --table-col-width: 240px; }
.list :deep(.col-spacer)    { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
