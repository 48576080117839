<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import { useDealForDetails } from '@/composables/repos/DealRepo'
import DealCardActions from '@/components/deal/DealCardActions.vue'
import DealCardNextAction from '@/components/deal/DealCardNextAction.vue'
import DealCardProgress from '@/components/deal/DealCardProgress.vue'
import DealCardRights from '@/components/deal/DealCardRights.vue'
import DealCardSecurities from '@/components/deal/DealCardSecurities.vue'
import DealDescExitPlan from '@/components/deal/DealDescExitPlan.vue'
import DealDescExpense from '@/components/deal/DealDescExpense.vue'
import DealDescInvestment from '@/components/deal/DealDescInvestment.vue'
import DealDescRound from '@/components/deal/DealDescRound.vue'
import DealDescSystem from '@/components/deal/DealDescSystem.vue'
import DealInfoAssignees from '@/components/deal/DealInfoAssignees.vue'

const route = useRoute()
const id = Number(route.params.id)

const { data: deal, execute: doRefetch } = useDealForDetails(id)

const { canView } = useDealPolicy(deal)

const dealOps = useDealOps()

const title = computedWhen(deal, (deal) => {
  return dealOps.fullTitle(deal)
})

function refetch() {
  doRefetch()
}
</script>

<template>
  <DPage class="DealsIdDetails" :title="title" :authorize="canView">
    <div class="board">
      <DealCardNextAction :deal="deal!" />
      <div class="container">
        <div class="grid">
          <DealCardProgress :deal="deal!" />
          <!-- <DealDescDroppedReason :deal="deal!" /> -->
          <DealCardActions :deal="deal!" @updated="refetch" />
          <DealDescRound :deal="deal!" />
          <DealDescInvestment :deal="deal!" @updated="refetch" />
          <DealCardSecurities :deal="deal!" @updated="refetch" />
          <DealCardRights :deal="deal!" @updated="refetch" />
          <DealDescExpense :deal="deal!" @updated="refetch" />
          <DealDescExitPlan :deal="deal!" @updated="refetch" />
          <DealDescSystem :deal="deal!" />
        </div>
        <div class="grid">
          <DealInfoAssignees :deal="deal!" @updated="refetch" />
        </div>
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.DealsIdDetails {
  padding: 32px 32px 128px;
}

.board {
  display: grid;
  gap: 16px;
  margin: 0 auto;
  max-width: 1216px;
}

.container {
  display: grid;
  grid-template-columns: minmax(664px, 768px) minmax(344px, 1fr);
  gap: 16px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
