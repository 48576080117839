<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsInvestmentCommitteeRequest } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { useFundAvailableCollection } from '@/composables/fund/FundData'
import { useRequestIc } from '@/composables/repos/DealEntryRepo'
import DInputMoney from '../DInputMoney.vue'
import DealEntryIcRequestComplete from './DealEntryIcRequestComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsInvestmentCommitteeRequest
}>()

const { t } = useTrans({
  en: {
    i_date_from_label: 'From *',
    i_date_to_label: 'Until *',
    i_deadline_label: 'Deadline *',
    i_lead_or_follow_label: 'Lead or Follow *',
    i_investment_amount_label: 'Investment amount *',
    i_coordinated_funds_label: 'Coordinated funds *',
    i_coordinated_funds_o_sole: 'Sole investment',
    i_advisory_committee_label: 'Requires advisory committee *',
    i_advisory_committee_o_required: 'Requires',
    i_advisory_committee_o_not_required: 'Not required',
    i_notes_label: 'Notes',
    i_notes_ph: 'Fill in any additional information',
    i_negative_check_requested_at_label: 'Negative check requested date *',
    submit: 'Submit request'
  },
  ja: {
    i_date_from_label: 'この日から *',
    i_date_to_label: 'この日までに *',
    i_deadline_label: '着金目標日 *',
    i_lead_or_follow_label: 'リード / フォロー *',
    i_investment_amount_label: '出資金額 *',
    i_coordinated_funds_label: '協調ファンド *',
    i_coordinated_funds_o_sole: '単独出資',
    i_advisory_committee_label: 'アドバイザリーコミッティ対象有無 *',
    i_advisory_committee_o_required: '対象',
    i_advisory_committee_o_not_required: '対象外',
    i_notes_label: '備考',
    i_notes_ph: '特記事項があれば入力ください',
    i_negative_check_requested_at_label: '反社チェック申請日 *',
    submit: '送信する'
  }
})

const router = useRouter()

const { data: funds } = useFundAvailableCollection()

const { execute: requestIc, loading } = useRequestIc(() => props.deal.id!, () => ({
  dateFrom: data.value.dateFrom!.format('YYYY-MM-DD'),
  dateTo: data.value.dateTo!.format('YYYY-MM-DD'),
  deadline: data.value.deadline!.format('YYYY-MM-DD'),
  lead: data.value.lead!,
  investmentAmount: data.value.investmentAmount!,
  coordinatedFundIds: getCoordinatedFunds(data.value.coordinatedFundIds),
  requiresAdvisoryCommittee: data.value.requiresAdvisoryCommittee!,
  notes: data.value.notes!,
  negativeCheckRequestedAt: data.value.negativeCheckRequestedAt!.format('YYYY-MM-DD')
}))

const completeDialog = usePower()

const { data } = useData({
  dateFrom: null as Day | null,
  dateTo: null as Day | null,
  deadline: props.deal.deadline,
  lead: props.deal.lead!,
  investmentAmount: props.deal.investmentAmount?.value ?? null,
  coordinatedFundIds: [] as number[],
  requiresAdvisoryCommittee: false,
  notes: null as string | null,
  negativeCheckRequestedAt: null as Day | null
})

const { validation, validateAndNotify } = useValidation(data, {
  dateFrom: { required: required() },
  dateTo: { required: required() },
  deadline: { required: required() },
  lead: { required: required() },
  investmentAmount: { required: required(), decimal: decimal(), maxValue: maxValue(10000000000000) },
  coordinatedFundIds: { required: required() },
  notes: { maxLength: maxLength(1000) },
  negativeCheckRequestedAt: { required: required() }
})

const fundOptions = computedWhen(funds, (funds) => {
  const f = funds.map((fund) => ({ label: fund.nameAbbreviated + (fund.group?.lp ? ` (${fund.group?.lp})` : ''), value: fund.id! }))
  f.unshift({ label: t.i_coordinated_funds_o_sole, value: 0 })
  return f
}, [])

function getCoordinatedFunds(funds: number[]) {
  return funds.length === 1
    ? funds[0] === 0 ? [] : funds
    : funds.filter((f) => f !== 0)
}

async function submit() {
  if (await validateAndNotify()) {
    await requestIc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryIcRequest">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SDocSection class="intro">
              <SContent>
                <STrans lang="en">
                  <h1>Request Investment Committee</h1>
                  <p>It is a meeting body where the main person in charge explains the case and conducts question and answer sessions. The rules regarding the date of the event differ between flagship funds and CVC funds. <SLink href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.7c880jj8mrlh">Refer to this link</SLink> for more details about Investment Committee.</p>
                </STrans>
                <STrans lang="ja">
                  <h1>投資委員会 開催依頼</h1>
                  <p>案件を通す主体として主担当者が説明し、質疑応答を行うための会議体です。純投資ファンドとCVCファンドで開催日のルールが異なります。投資委員会の<SLink class="link" href="https://docs.google.com/document/d/1vPK_q6Z7MUiUHmwmc5_crxuZznFJZm7pxTB-9hrMj9I/#heading=h.7c880jj8mrlh">詳細についてはこちら</SLink>を確認してください。</p>
                </STrans>
              </SContent>
            </SDocSection>
            <SDivider />
            <SDocSection class="dates">
              <SContent>
                <STrans lang="en">
                  <h2>Desired date</h2>
                  <p>Specify the time frame during which you would like to hold the Investment Committee. Fund Management team will try to schedule the meeting within this period. If you already have specific date and time options in mind, specify the time frame here and note those details in the "Notes" section.</p>
                  <SAlert v-if="settings.datesAlertInfoEn" mode="info">
                    <p>{{ settings.datesAlertInfoEn }}</p>
                  </SAlert>
                </STrans>
                <STrans lang="ja">
                  <h2>希望日程</h2>
                  <p>いつからいつまでの間に投資委員会の開催を希望するか指定してください。この期間の中から日程を調整します。具体的な日時の候補が存在する場合、ここで期間を指定した上で、「備考」にその旨を記載してください。</p>
                  <SAlert v-if="settings.datesAlertInfoJa" mode="info">
                    <p>{{ settings.datesAlertInfoJa }}</p>
                  </SAlert>
                </STrans>
              </SContent>
              <div class="period">
                <SInputDate
                  :label="t.i_date_from_label"
                  v-model="data.dateFrom"
                  :validation="validation.dateFrom"
                />
                <div class="period-divider">–</div>
                <SInputDate
                  :label="t.i_date_to_label"
                  v-model="data.dateTo"
                  :validation="validation.dateTo"
                />
              </div>
            </SDocSection>
            <SDivider />
            <SDocSection>
              <SContent>
                <STrans lang="en">
                  <h2>Deal details</h2>
                  <p>Fill in the information related to the deal. Note that these field is going to update the corresponding deal data.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>ディール情報</h2>
                  <p>ディールに関する情報を入力してください。これらの情報は、フォーム送信後、Dealページ上のラウンド情報該当箇所が自動更新されます。</p>
                </STrans>
              </SContent>
              <SInputDate
                :label="t.i_deadline_label"
                v-model="data.deadline"
                :validation="validation.deadline"
              />
              <SInputRadios
                :label="t.i_lead_or_follow_label"
                :options="[
                  { label: 'Lead', value: true },
                  { label: 'Follow', value: false }
                ]"
                v-model="data.lead"
                :validation="validation.lead"
              />
              <DInputMoney
                :label="t.i_investment_amount_label"
                placeholder="100,000,000"
                :currency="deal.round!.currency"
                v-model="data.investmentAmount"
                :validation="validation.investmentAmount"
              />
            </SDocSection>
            <SDivider />
            <SDocSection>
              <SContent>
                <STrans lang="en">
                  <h2>Committee details</h2>
                  <p>Fill in the information related to the committee.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>会議体に関する情報</h2>
                  <p>投資委員会の会議体に関する情報を入力してください。</p>
                </STrans>
              </SContent>
              <SInputDropdown
                :label="t.i_coordinated_funds_label"
                placeholder="Select funds"
                :options="fundOptions"
                nullable
                v-model="data.coordinatedFundIds"
                :validation="validation.coordinatedFundIds"
              />
              <SInputRadios
                v-if="settings.requiresAdvisoryCommittee"
                :label="t.i_advisory_committee_label"
                :options="[
                  { label: t.i_advisory_committee_o_not_required, value: false },
                  { label: t.i_advisory_committee_o_required, value: true }
                ]"
                v-model="data.requiresAdvisoryCommittee"
              />
              <SInputTextarea
                :label="t.i_notes_label"
                :placeholder="t.i_notes_ph"
                :rows="8"
                v-model="data.notes"
                :validation="validation.notes"
              />
            </SDocSection>
            <SDivider />
            <SDocSection>
              <SContent>
                <STrans lang="en">
                  <h2>Negative check entry</h2>
                  <p>Enter the date you applied for the negative check workflow. You must apply for it prior to requesting the Investment Committee.</p>
                </STrans>
                <STrans lang="ja">
                  <h2>反社チェック申請</h2>
                  <p>反社チェックのワークフローを申請した日付を入力してください。投資委員会にエントリーするためには、事前に反社チェックを申請する必要があります。</p>
                </STrans>
              </SContent>
              <SInputDate
                :label="t.i_negative_check_requested_at_label"
                v-model="data.negativeCheckRequestedAt"
                :validation="validation.negativeCheckRequestedAt"
              />
            </SDocSection>
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryIcRequestComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryIcRequest {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.period {
  display: flex;
  align-items: center;
  gap: 16px;
}

.period-divider {
  padding-top: 26px;
  color: var(--c-text-3);
}
</style>
