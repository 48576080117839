<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { type Company } from '@/models/Company'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import CompanyFormUpdateInfo from './CompanyFormUpdateInfo.vue'

const props = defineProps<{
  company: Company
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useCompanyPolicy(props.company)
const { state: modal, on, off } = usePower(false)

const actions = computedWhen(canUpdate, () => {
  return [{ icon: 'edit', onClick: on }]
}, [])
</script>

<template>
  <DCard title="Company information" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="4">
        <DCardGridLabel text="Website" />
        <DCardGridLink :text="company.website" />
      </DCardGridItem>
      <DCardGridItem>
        <DCardGridLabel text="Founded" />
        <DCardGridText :text="company.foundedText()" />
      </DCardGridItem>
      <DCardGridItem>
        <DCardGridLabel text="Fiscal month" />
        <DCardGridText :text="company.fiscalMonth" />
      </DCardGridItem>
      <DCardGridItem>
        <DCardGridLabel text="Country" />
        <DCardGridText :text="company.country!.name" />
      </DCardGridItem>
      <DCardGridItem>
        <DCardGridLabel text="Zipcode" />
        <DCardGridText :text="company.zipcode" />
      </DCardGridItem>
      <DCardGridItem span="2">
        <DCardGridLabel text="Address" />
        <DCardGridText :text="company.address" />
      </DCardGridItem>
      <DCardGridItem span="2">
        <DCardGridLabel text="House number" />
        <DCardGridText :text="company.addressHouseNumber" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal">
      <CompanyFormUpdateInfo
        :company="company"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </DCard>
</template>
