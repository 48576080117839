<template>
  <div class="StatBlock">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.StatBlock {
  border: 1px solid var(--c-divider);
  border-radius: 6px;
}
</style>
