<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type SurveyAndAllFrag } from '@/graphql'
import { useSurveyOps } from '@/composables/ops/SurveyOps'

defineProps<{
  survey: SurveyAndAllFrag
}>()

const { t } = useTrans({
  en: {
    createdAt: 'Created at'
  },
  ja: {
    createdAt: '作成日時'
  }
})

const surveyOps = useSurveyOps()
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc gap="8" dir="row" label-width="96" :divider="false">
      <SDescItem>
        <SDescLabel>{{ t.createdAt }}</SDescLabel>
        <SDescDay :value="surveyOps.createdAt(survey)" format="YYYY-MM-DD HH:mm" />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.user {
  display: block;
}
</style>
