<script setup lang="ts">
import IconChatCircleText from '~icons/ph/chat-circle-text'
import IconPaperclip from '~icons/ph/paperclip'
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useUpdateOpportunityFileFields } from '@/composables/repos/OpportunityRepo'
import IconAbort from '../icon/IconAbort.vue'

const props = defineProps<{
  type: 'normal' | 'chain'
  opportunityId: number
}>()

const emit = defineEmits<{
  upload: []
  comment: []
  skip: []
  aborted: []
}>()

const { t } = useTrans({
  en: {
    upload: 'Upload pitch decks',
    abort: 'No scheduled receipt',
    comment: 'Add a text',
    skip: 'Skip this step for now'
  },
  ja: {
    upload: 'Deckをアップロード',
    abort: '資料受領予定なし',
    comment: 'テキストを追加',
    skip: 'このステップをスキップする'
  }
})

const updateOpportunityFileFields = useUpdateOpportunityFileFields()

async function abort() {
  await updateOpportunityFileFields.execute(props.opportunityId, {
    hasNoPlanToAddFiles: true,
    fileComment: null
  })
  emit('aborted')
}
</script>

<template>
  <SCard class="OpportunityFormSelectFileActions" size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent v-if="type === 'normal'">
          <STrans lang="en">
            <h2>Add pitch decks</h2>
            <p>You may attach the pitch deck to be shared with the CVC’s LP. Additionally, you can indicate if there is no scheduled receipt of the document or leave free text.</p>
          </STrans>
          <STrans lang="ja">
            <h2>ピッチデックを追加する</h2>
            <p>CVCのLPへ共有するPitch Deckを添付できます。また、資料の受領予定がない旨や、テキストを残すこともできます。</p>
          </STrans>
        </SContent>
        <SContent v-if="type === 'chain'">
          <STrans lang="en">
            <h2>Add pitch decks</h2>
            <p>You may attach the pitch deck to be shared with the CVC’s LP. Additionally, you can indicate if there is no scheduled receipt of the document or leave free text.</p>
            <p>You may skip this step for now and add decks later on.</p>
          </STrans>
          <STrans lang="ja">
            <h2>続けてピッチデックを追加する</h2>
            <p>CVCのLPへ共有するPitch Deckを添付できます。また、資料の受領予定がない旨や、テキストを残すこともできます。</p>
            <p>Pitch Deckは、後からいつでも追加することが可能です。</p>
          </STrans>
        </SContent>
        <div class="list">
          <div class="item">
            <div class="main">
              <div class="icon">
                <IconPaperclip class="icon-svg" />
              </div>
              <div class="body">
                <STrans lang="en">
                  <div class="title">Upload pitch decks</div>
                  <div class="text">Attach the pitch deck to Oppo so that the CVC representative who opened the proposal can download it.</div>
                </STrans>
                <STrans lang="ja">
                  <div class="title">Pitch Deckをアップロード</div>
                  <div class="text">Pitch DeckをOppoへ添付し、ProposalをOpenしたCVCの担当者がダウンロードできるようにします。</div>
                </STrans>
              </div>
            </div>
            <div class="action">
              <SButton
                size="small"
                :label="t.upload"
                :disabled="updateOpportunityFileFields.loading.value"
                @click="$emit('upload')"
              />
            </div>
          </div>
          <div class="item">
            <div class="main">
              <div class="icon">
                <IconAbort class="icon-svg" />
              </div>
              <div class="body">
                <STrans lang="en">
                  <div class="title">No scheduled receipt</div>
                  <div class="text">Indicate that there is no scheduled receipt of the attached document.</div>
                </STrans>
                <STrans lang="ja">
                  <div class="title">受領予定なし</div>
                  <div class="text">添付する資料を受領する予定がないことを示します。</div>
                </STrans>
              </div>
            </div>
            <div class="action">
              <SButton
                size="small"
                :label="t.abort"
                :loading="updateOpportunityFileFields.loading.value"
                @click="abort"
              />
            </div>
          </div>
          <div class="item">
            <div class="main">
              <div class="icon">
                <IconChatCircleText class="icon-svg" />
              </div>
              <div class="body">
                <STrans lang="en">
                  <div class="title">Add a text</div>
                  <div class="text">Leave a text regarding the document. If the received materials are provided via an external URL such as Google Drive or Box, you can use this feature to share the link.</div>
                </STrans>
                <STrans lang="ja">
                  <div class="title">テキストを追加する</div>
                  <div class="text">資料に関するテキストを記載します。受領資料がGoogle DriveやBoxなどの外部URLで提供されている場合、こちらを使ってリンクを共有できます。</div>
                </STrans>
              </div>
            </div>
            <div class="action">
              <SButton
                size="small"
                :label="t.comment"
                :disabled="updateOpportunityFileFields.loading.value"
                @click="$emit('comment')"
              />
            </div>
          </div>
        </div>
        <div v-if="type === 'chain'" class="skip">
          <SButton
            type="text"
            size="small"
            mode="info"
            :label="t.skip"
            @click="$emit('skip')"
          />
        </div>
      </SDoc>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item {
  display: flex;
  gap: 24px;
  align-items: center;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  padding: 16px;
}

.main {
  display: flex;
  gap: 14px;
  flex-grow: 1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 24px;
}

.icon-svg {
  width: 16px;
  height: 16px;
  color: var(--c-text-2);
}

.title {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}

.text {
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);
}

.skip {
  text-align: center;
}
</style>
