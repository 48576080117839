import { type FundWithPerformanceAdmins } from '@/graph/Fund'
import { type Policy, usePolicy } from './Policy'

export function useCanViewFundStats(fund: FundWithPerformanceAdmins): Policy {
  return usePolicy(({ user, allow, deny }) => {
    if (user.hasRoleGod()) {
      return allow()
    }
    return fund.performanceAdmins.some((fpa) => fpa.userId === user.id) ? allow() : deny()
  })
}
