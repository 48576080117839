<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { required, rule } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { type SecurityKind } from '@/models/Deal'
import { useMe } from '@/composables/Auth'
import { useCreate } from '@/composables/deal/DealData'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useFundOps } from '@/composables/ops/FundOps'
import { useOpportunityInitialReviewMeetingOps, useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useDealPartnerList } from '@/composables/repos/DealRepo'
import { useFundWithGroupList } from '@/composables/repos/FundRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'
import DealFormCreateFunds from './DealFormCreateFunds.vue'
import DealFormCreateSecurities from './DealFormCreateSecurities.vue'

interface Security {
  kind: SecurityKind | null
  name: string | null
  plannedTotalPrice: string | null
  liquidationPreferenceMultiple: null
  liquidationPreferencePatternId: null
  antiDilutionProvisionId: null
  annualInterest: null
  repaymentDate: null
  repaymentRight: null
  discount: null
  valuationCap: null
  eligibleFinancingAmount: null
}

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const { t } = useTrans({
  en: {
    i_fund_label: 'Fund *',
    i_partner_in_charge_label: 'Partner in-charge *',
    i_partner_in_charge_ph: 'Select partner',
    i_primary_in_charge_label: 'Primary in-charge *',
    i_primary_in_charge_ph: 'Select primary in-charge',
    i_deputy_in_charge_label: 'Deputy in-charge',
    i_deputy_in_charge_ph: 'Select deputy in-charge',
    i_participant_label: 'Participants',
    i_participant_ph: 'Select participants',
    i_deadline_label: 'Deadline *',
    i_deadline_help: 'The deadline refers to the expected date of payment to the startup.',
    i_lead_or_follow_label: 'Lead or Follow',
    i_create_deal: 'Create deal',
    e_deputy_in_charge_not_same_as: 'Already assigned as Primary in-charge.',
    e_participant_not_same_as_pic: 'Some member is already assigned as Primary in-charge.',
    e_participant_not_same_as_dic: 'Some member is already assigned as Deputy in-charge.'
  },
  ja: {
    i_fund_label: 'Fund *',
    i_partner_in_charge_label: 'Partner in-charge *',
    i_partner_in_charge_ph: '選択してください',
    i_primary_in_charge_label: 'Primary in-charge *',
    i_primary_in_charge_ph: '選択してください',
    i_deputy_in_charge_label: 'Deputy in-charge',
    i_deputy_in_charge_ph: '選択してください',
    i_participant_label: 'Participants',
    i_participant_ph: '選択してください',
    i_deadline_label: '着金期日 *',
    i_deadline_help: 'この期限はスタートアップへの着金予定日を指します。',
    i_lead_or_follow_label: 'Lead or Follow',
    i_create_deal: 'Dealを作成',
    e_deputy_in_charge_not_same_as: 'すでにPrimary in-chargeとして設定されています。',
    e_participant_not_same_as_pic: '選択されたユーザーがすでにPrimary in-chargeとして設定されています。',
    e_participant_not_same_as_dic: '選択されたユーザーがすでにDeputy in-chargeとして設定されています。'
  }
})

const router = useRouter()

const { user: me } = useMe()
const { data: partners } = useDealPartnerList()
const { data: users } = useActiveUserList()
const { data: funds } = useFundWithGroupList()

const userOps = useUserOps()
const fundOps = useFundOps()
const companyOps = useCompanyOps()
const oppoOps = useOpportunityOps()
const irmOps = useOpportunityInitialReviewMeetingOps()

const availableFunds = computed(() => {
  return funds.value?.filter((fund) => {
    return fundOps.isActive(fund) || fundOps.isActiveOnlyFollowOn(fund)
  }) ?? []
})

const isPortfolio = computed(() => {
  return companyOps.allPortfolioOf(props.opportunity.company).length > 0
})

const isIrmCompleted = computed(() => {
  const irm = oppoOps.latestInitialReviewMeeting(props.opportunity)
  return !!irm && irmOps.isResultCompleted(irm)
})

const { data } = useData({
  fundId: null as number | null,
  partnerInChargeId: null as number | null,
  primaryInChargeId: me.id!,
  deputyInChargeId: null as number | null,
  participantIds: [] as number[],
  deadline: null as Day | null,
  lead: null as boolean | null,
  securities: [createSecurity()] as Security[]
})

const { validation, validateAndNotify } = useValidation(data, {
  partnerInChargeId: {
    required: required()
  },
  deputyInChargeId: {
    notSameAs: rule(
      (value) => value !== data.value.primaryInChargeId,
      t.e_deputy_in_charge_not_same_as
    )
  },
  participantIds: {
    notSameAsPic: rule(
      (value) => !((value as unknown[]).includes(data.value.primaryInChargeId)),
      t.e_participant_not_same_as_pic
    ),
    notSameAsDic: rule(
      (value) => data.value.deputyInChargeId ? !((value as unknown[]).includes(data.value.deputyInChargeId)) : true,
      t.e_participant_not_same_as_dic
    )
  },
  deadline: {
    required: required()
  },
  securities: {
    required: required()
  }
})

const partnerOptions = computed<Option[]>(() => {
  return userOps.createDropdownOptions(partners.value)
})

const userOptions = computed<Option[]>(() => {
  return userOps.createDropdownOptions(users.value)
})

const leadOptions = [
  { label: 'Lead', value: true },
  { label: 'Follow', value: false }
]

const { execute: doCreate, loading } = useCreate()

function createSecurity() {
  return {
    kind: null,
    name: null,
    plannedTotalPrice: null,
    liquidationPreferenceMultiple: null,
    liquidationPreferencePatternId: null,
    antiDilutionProvisionId: null,
    annualInterest: null,
    repaymentDate: null,
    repaymentRight: null,
    discount: null,
    valuationCap: null,
    eligibleFinancingAmount: null
  }
}

function addSecurity() {
  data.value.securities.push(createSecurity())
}

async function create() {
  if (await validateAndNotify()) {
    const deal = await doCreate(props.opportunity.round!.id!, {
      fundId: data.value.fundId!,
      partnerInChargeId: data.value.partnerInChargeId,
      primaryInChargeId: data.value.primaryInChargeId,
      deputyInChargeId: data.value.deputyInChargeId,
      participantIds: data.value.participantIds,
      deadline: data.value.deadline!.format('YYYY-MM-DD'),
      lead: data.value.lead,
      securities: data.value.securities
    })

    router.push(deal.path)
  }
}
</script>

<template>
  <SCard v-if="funds">
    <SCardBlock class="s-p-48">
      <SDoc>
        <SDocSection class="header">
          <SContent>
            <STrans lang="en">
              <h1>Create a new deal</h1>
            </STrans>
            <STrans lang="ja">
              <h1>Dealを作成する</h1>
            </STrans>
          </SContent>
        </SDocSection>
        <SDivider />
        <SDocSection class="fund">
          <SContent>
            <STrans lang="en">
              <h2>Fund</h2>
              <p>Select a fund to make an investment in this deal. Be aware that you cannot change the selected fund after creating the deal.</p>
            </STrans>
            <STrans lang="ja">
              <h2>ファンド</h2>
              <p>このDealに投資するFundを選択してください。Dealを作成した後に選択したFundを変更することはできません。</p>
            </STrans>
          </SContent>
          <DealFormCreateFunds
            :is-portfolio="isPortfolio"
            :is-irm-completed="isIrmCompleted"
            :opportunity="props.opportunity"
            :funds="availableFunds"
            v-model="data.fundId"
          />
        </SDocSection>
        <SDivider />
        <SDocSection class="assignees">
          <SContent>
            <STrans lang="en">
              <h2>Assignees</h2>
              <p>Select assignees of the deal. Note that assignees of the deal and the assignees of the company are different, and do not have to be the same.</p>
            </STrans>
            <STrans lang="ja">
              <h2>担当者</h2>
              <p>Dealの担当者を選択してください。Dealの担当者と会社の担当者は異なり、同じである必要はありません。</p>
            </STrans>
          </SContent>
          <SGrid cols="3" gap="24">
            <SGridItem span="1">
              <SInputDropdown
                :label="t.i_partner_in_charge_label"
                :placeholder="t.i_partner_in_charge_ph"
                :options="partnerOptions"
                v-model="data.partnerInChargeId"
                :validation="validation.partnerInChargeId"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputDropdown
                :label="t.i_primary_in_charge_label"
                :placeholder="t.i_primary_in_charge_ph"
                :options="userOptions"
                v-model="data.primaryInChargeId"
              />
            </SGridItem>
            <SGridItem span="1">
              <SInputDropdown
                :label="t.i_deputy_in_charge_label"
                :placeholder="t.i_deputy_in_charge_ph"
                nullable
                :options="userOptions"
                v-model="data.deputyInChargeId"
                :validation="validation.deputyInChargeId"
              />
            </SGridItem>
            <SGridItem span="3">
              <SInputDropdown
                :label="t.i_participant_label"
                :placeholder="t.i_participant_ph"
                :options="userOptions"
                nullable
                v-model="data.participantIds"
                :validation="validation.participantIds"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
        <SDivider />
        <SDocSection class="info">
          <SContent>
            <STrans lang="en">
              <h2>Deal information</h2>
              <p>Fill in the information that is required to kick off the deal.</p>
            </STrans>
            <STrans lang="ja">
              <h2>Deal情報</h2>
              <p>Dealのキックオフに必要な情報を入力してください。</p>
            </STrans>
          </SContent>
          <SInputDate
            :label="t.i_deadline_label"
            :help="t.i_deadline_help"
            v-model="data.deadline"
            :validation="validation.deadline"
          />
          <SInputRadios
            :label="t.i_lead_or_follow_label"
            :options="leadOptions"
            nullable
            v-model="data.lead"
          />
        </SDocSection>
        <SDivider />
        <SDocSection class="info">
          <SContent>
            <STrans lang="en">
              <h2>Securities</h2>
              <p>Enter information about what type of securities the deal is intend to purchase and at what price. The deal’s total amount will be calculated by the sum of these prices.</p>
            </STrans>
            <STrans lang="ja">
              <h2>有価証券</h2>
              <p>Dealの対象となる有価証券の種類と合計金額を入力してください。Deal全体の合計金額はこれらの値で自動計算されます。</p>
            </STrans>
          </SContent>
          <DealFormCreateSecurities
            :currency="props.opportunity.round!.totalAmount.currency"
            v-model="data.securities"
            @add="addSecurity"
          />
        </SDocSection>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton
            mode="info"
            :label="t.i_create_deal"
            :loading="loading"
            @click="create"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
