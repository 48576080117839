import { orderBy } from 'lodash-es'
import { type CompanyVECJapanRegion } from '@/graphql'
import { CompanyVecJapanRegionRequest } from '@/requests/CompanyVecJapanRegionRequest'
import { type Query, useQuery } from '../Api'

export type CompanyVecJapanRegionList = Query<CompanyVECJapanRegion[], []>

export function useCompanyVecJapanRegionList(): CompanyVecJapanRegionList {
  return useQuery(async () => {
    const { data: { companyJapanRegions } } = await new CompanyVecJapanRegionRequest().fetchAll()

    return orderBy(companyJapanRegions, (c) => c.rank)
  })
}
