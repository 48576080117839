<script setup lang="ts">
import { day } from 'sefirot/support/Day'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { type CompanyPortfolioData } from '@/graph/CompanyPortfolioData'
import { useBroadcast } from '@/composables/Channel'
import { useCompanyItem, useCompanyPortfolioDataItem } from '@/composables/company/CompanyData'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import { useCanUpdatePortfolioDataExitStatus } from '@/composables/policies/CompanyPortfolioDataPolicy'
import CompanyInfoAssignees from '@/components/company/CompanyInfoAssignees.vue'
import CompanyPortfolioDataDescBalanceConfirmation from '@/components/company/CompanyPortfolioDataDescBalanceConfirmation.vue'
import CompanyPortfolioDataDescCapital from '@/components/company/CompanyPortfolioDataDescCapital.vue'
import CompanyPortfolioDataDescEmergencyContacts from '@/components/company/CompanyPortfolioDataDescEmergencyContacts.vue'
import CompanyPortfolioDataDescExitStatus from '@/components/company/CompanyPortfolioDataDescExitStatus.vue'
import CompanyPortfolioDataDescInvestment from '@/components/company/CompanyPortfolioDataDescInvestment.vue'
import CompanyPortfolioDataDescIssuedShares from '@/components/company/CompanyPortfolioDataDescIssuedShares.vue'
import CompanyPortfolioDataDescPotentialShares from '@/components/company/CompanyPortfolioDataDescPotentialShares.vue'
import CompanyPortfolioDataDescSystem from '@/components/company/CompanyPortfolioDataDescSystem.vue'
import CompanyPortfolioDataInfoPromoter from '@/components/company/CompanyPortfolioDataInfoPromoter.vue'

const route = useRoute()

const companyId = computed(() => Number(route.params.id))

const { data: company, execute: executeCompanyItem } = useCompanyItem(companyId.value, {
  catch: [401, 404]
})

const { data: portfolioData, execute: executeCompanyPortfolioDataItem } = useCompanyPortfolioDataItem(companyId.value, {
  catch: [401, 404]
})

const { canView } = useCompanyPortfolioDataPolicy(portfolioData)
const canUpdatePortfolioDataExitStatus = useCanUpdatePortfolioDataExitStatus()

// Wait until both `company` and `companyPortfolioData` are fetched otherwise
// some components might get undefined item.
const authorize = computed(() => {
  // Fetched.
  if (company.value === undefined || portfolioData.value === undefined) {
    return null
  }

  // Not found.
  if (company.value === null || portfolioData.value === null) {
    return false
  }

  // Fetched and exists, now policy can be applied.
  return canView.value
})

const { broadcast } = useBroadcast('company-exit-status-updated')

function execute() {
  executeCompanyItem()
  executeCompanyPortfolioDataItem()
}

function onExitStatusUpdated(cpd: CompanyPortfolioData) {
  portfolioData.value!.exitStatus = cpd.exitStatus as any
  portfolioData.value!.isActivePortfolio = cpd.isActivePortfolio
  portfolioData.value!.exitDate = cpd.exitDate ? day(cpd.exitDate) : null
  portfolioData.value!.ipoLeadUnderwriter = cpd.ipoLeadUnderwriter
  portfolioData.value!.ipoListingMarket = cpd.ipoListingMarket
  portfolioData.value!.exitDetails = cpd.exitDetails
  broadcast()
}
</script>

<template>
  <DPage class="CompaniesIdPortfolioData" :title="company?.name" :authorize="authorize">
    <div v-if="company && portfolioData" class="board">
      <div class="grid">
        <CompanyPortfolioDataDescInvestment :portfolio-data="portfolioData" />
        <CompanyPortfolioDataDescCapital :portfolio-data="portfolioData" @updated="execute" />
        <CompanyPortfolioDataDescIssuedShares :portfolio-data="portfolioData" @updated="execute" />
        <CompanyPortfolioDataDescPotentialShares :portfolio-data="portfolioData" @updated="execute" />
        <CompanyPortfolioDataDescBalanceConfirmation :portfolio-data="portfolioData" @updated="execute" />
        <CompanyPortfolioDataDescExitStatus :portfolio-data="portfolioData" :can-update="canUpdatePortfolioDataExitStatus.ok" @updated="onExitStatusUpdated" />
        <CompanyPortfolioDataDescEmergencyContacts :portfolio-data="portfolioData" @updated="execute" />
        <CompanyPortfolioDataDescSystem :portfolio-data="portfolioData" />
      </div>
      <div class="grid">
        <CompanyInfoAssignees :company="company" @updated="execute" />
        <CompanyPortfolioDataInfoPromoter :portfolio-data="portfolioData" @updated="execute" />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdPortfolioData {
  padding: 32px 32px 128px;
}

.board {
  display: grid;
  grid-template-columns: minmax(664px, 768px) minmax(344px, 1fr);
  gap: 16px;
  margin: 0 auto;
  max-width: 1216px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
