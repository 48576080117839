import { orderBy } from 'lodash-es'
import { FundGroup } from '@/models/FundGroup'
import { FundGroupRequest } from '@/requests/FundGroupRequest'
import { type Query, useQuery } from '../Api'

export type FundGroupCollection = Query<FundGroup[]>

export function useFundGroupCollection(): FundGroupCollection {
  return useQuery(async () => {
    const res = await new FundGroupRequest().fetchAll()

    const groups = res.data.fundGroups.map((f) => new FundGroup(f))

    return orderBy(groups, ['rank'])
  })
}

export function useFundGroupActiveCollection(): FundGroupCollection {
  return useQuery(async () => {
    const res = await new FundGroupRequest().fetchAll()

    const groups = res.data.fundGroups
      .map((f) => new FundGroup(f))
      .filter((f) => f.isActive())

    return orderBy(groups, ['rank'])
  })
}
