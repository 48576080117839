<script setup lang="ts">
import { AuthorizationError } from 'sefirot/errors'
import { useCanCreateSurvey } from '@/composables/policies/SurveyPolicy'
import SurveyFormCreate from '@/components/survey/SurveyFormCreate.vue'

const canCreateSurvey = useCanCreateSurvey()

if (!canCreateSurvey.value.ok) {
  throw new AuthorizationError()
}
</script>

<template>
  <DPage title="Surveys - Create" class="SurveysCreate">
    <div class="s-mx-auto s-max-w-md">
      <SurveyFormCreate />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.SurveysCreate {
  padding: 32px 32px 128px;
}
</style>
