import { orderBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { type ProposableFundGroupFrag } from '@/graphql'
import { ProposalRequest } from '@/requests/ProposalRequest'

export type ProposableFundGroupList = Query<ProposableFundGroupFrag[]>
export type GetProposableFundGroupList = Get<ProposableFundGroupFrag[]>

export function useProposableFundGroupList(
  opportunityId: MaybeRefOrGetter<number>
): ProposableFundGroupList {
  return useQuery(() => fetchProposableFundGroups(toValue(opportunityId)))
}

export function useGetProposableFundGroupList(
  opportunityId: MaybeRefOrGetter<number>
): GetProposableFundGroupList {
  return useGet(() => fetchProposableFundGroups(toValue(opportunityId)))
}

async function fetchProposableFundGroups(
  opportunityId: number
): Promise<ProposableFundGroupFrag[]> {
  const res = await new ProposalRequest().fetchProposableFundGroups(opportunityId)
  return orderBy(res.data.proposableFundGroups, (pfg) => pfg.fundGroup.rank)
}
