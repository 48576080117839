import { type Ref, type ShallowRef, ref, shallowRef } from 'vue'
import { RequestError } from '@/requests/errors/RequestError'
import { toArray } from '@/support/Array'

export interface Query<T = any, A extends any[] = any[]> {
  loading: Ref<boolean>
  data: Ref<T | null | undefined>
  error: ShallowRef<any>
  execute(...args: A): Promise<T | null | undefined>
}

export interface UseQueryOptions {
  immediate?: boolean
  catch?: number | number[]
  onError?(error: any): boolean | void
}

export interface Mutation<T = any, A extends any[] = any[]> {
  loading: Ref<boolean>
  data: Ref<T | undefined>
  execute(...args: A): Promise<T>
}

export interface Page<T> {
  page: PageInfo
  items: T[]
}

export interface PageInfo {
  fetched: number
  page: number
  perPage: number
  total: number
  hasNext: boolean
  hasPrevious: boolean
}

export function useQuery<T = any, A extends any[] = any[]>(
  req: (...args: A) => Promise<T | null | undefined>,
  options: UseQueryOptions = {}
): Query<T, A> {
  const loading = ref(false)
  const result = ref<T | null | undefined>()
  const error = shallowRef<any>(null)

  if (options.immediate !== false) {
    (execute as any)()
  }

  async function execute(...args: A): Promise<T | null | undefined> {
    let res: T | null | undefined = null

    loading.value = true

    try {
      res = await req(...args)
      result.value = res
      error.value = null
    } catch (e: any) {
      result.value = null
      error.value = e

      if (e instanceof RequestError && toArray(options.catch).includes(e.status)) {
        return res
      }

      if (options.onError?.(e) === false) {
        return res
      }

      throw e
    } finally {
      loading.value = false
    }

    return res
  }

  return {
    loading,
    data: result,
    error,
    execute
  }
}

export function useMutation<T = any, A extends any[] = any[]>(
  req: (...args: A) => Promise<T>
): Mutation<T> {
  const loading = ref(false)
  const result = ref<T | undefined>()

  async function execute(...args: A): Promise<T> {
    loading.value = true

    const res = await req(...args)

    result.value = res

    loading.value = false

    return res
  }

  return {
    loading,
    data: result,
    execute
  }
}

export function createPage<T>(page: PageInfo, items: T[]): Page<T> {
  return {
    page,
    items
  }
}
