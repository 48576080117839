import { format } from 'sefirot/support/Num'
import { type Round } from '@/graph/Round'
import {
  type CurrencyFrag,
  type RoundFrag,
  type RoundWithCurrencyFrag,
  type RoundWithNameFrag,
  type RoundWithTotalAmountFrag
} from '@/graphql'
import { defineOps } from './Ops'

export const useRoundOps = defineOps(() => {
  function name(round: Round | RoundWithNameFrag): string {
    return round.name.name
  }

  function fullName(round: Round | RoundWithNameFrag): string {
    return `${round.name.name}${round.nameSuffix ? ` ${round.nameSuffix}` : ''}`
  }

  function currency(round: RoundWithCurrencyFrag): CurrencyFrag {
    return round.totalAmount.currency
  }

  function totalAmountValueAsNumber(round: RoundWithTotalAmountFrag): number {
    return Number(round.totalAmount.value)
  }

  function totalAmountTextWithCurrency(round: RoundWithTotalAmountFrag): string {
    const c = round.totalAmount.currency.name
    const v = format(totalAmountValueAsNumber(round))
    return `${c} ${v}`
  }

  function gbTotalAmountValueAsNumber(round: RoundFrag): number | null {
    return round.gbTotalAmount ? Number(round.gbTotalAmount.value) : null
  }

  function preValuationValueAsNumber(round: RoundFrag): number | null {
    return round.preValuation ? Number(round.preValuation.value) : null
  }

  function postValuationValueAsNumber(round: RoundFrag): number | null {
    return round.postValuation ? Number(round.postValuation.value) : null
  }

  return {
    name,
    fullName,
    currency,
    totalAmountValueAsNumber,
    totalAmountTextWithCurrency,
    gbTotalAmountValueAsNumber,
    preValuationValueAsNumber,
    postValuationValueAsNumber
  }
})
