import { assert } from '@/support/Error'
import { Company } from './Company'
import { type Currency } from './Currency'
import { Deal } from './Deal'
import { Model } from './Model'
import { Money } from './Money'
import { Opportunity } from './Opportunity'
import { RoundName } from './RoundName'

export class Round extends Model {
  id?: number
  nameId?: number
  name?: RoundName | null
  nameSuffix?: string | null
  schedule?: string
  totalAmount: Money | null
  gbTotalAmount: Money | null
  preValuation: Money | null
  postValuation: Money | null
  preIssuedShares?: string | null
  participatingInvestors?: string | null
  notes?: string | null

  company: Company | null
  opportunity: Opportunity | null

  deals: Deal[]

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.nameId = data.nameId
    this.name = this.hasOne(RoundName, data.name)
    this.nameSuffix = data.nameSuffix
    this.schedule = data.schedule
    this.totalAmount = this.hasOne(Money, data.totalAmount)
    this.gbTotalAmount = this.hasOne(Money, data.gbTotalAmount)
    this.preValuation = this.hasOne(Money, data.preValuation)
    this.postValuation = this.hasOne(Money, data.postValuation)
    this.preIssuedShares = data.preIssuedShares
    this.participatingInvestors = data.participatingInvestors
    this.notes = data.notes

    this.company = this.hasOne(Company, data.company)
    this.opportunity = this.hasOne(Opportunity, data.opportunity)
    this.deals = this.hasMany(Deal, data.deals)
  }

  get fullName(): string {
    assert(!!this.name, [
      'Trying to access the "round name" but the `round.name` relation is not loaded.',
      'Check how you\'re querying the models.'
    ])

    return `${this.name.name} ${this.nameSuffix ?? ''}`
  }

  get currency(): Currency | null {
    return this.totalAmount?.currency ?? null
  }
}
