<script setup lang="ts">
import SInputSelect, { type Option as SelectOption } from 'sefirot/components/SInputSelect.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { reactive } from 'vue'
import { CloseCompanyInputType, type CompanyForSettingsFrag } from '@/graphql'
import { useBroadcast } from '@/composables/Channel'
import { useCloseCompany } from '@/composables/repos/CompanyRepo'

const props = defineProps<{
  company: CompanyForSettingsFrag
}>()

const emit = defineEmits<{
  cancel: []
  closed: []
}>()

const { t } = useTrans({
  en: {
    i_type_label: 'Close type',
    i_type_placeholder: 'Select a close type',
    i_note_label: 'Details',
    i_note_placeholder: 'Describe the details why you are closing the company.',
    cancel: 'Cancel',
    close: 'Close company'
  },
  ja: {
    i_type_label: 'クローズ種別',
    i_type_placeholder: '種別を選択してください',
    i_note_label: 'クローズ理由',
    i_note_placeholder: 'Companyをクローズする理由を入力してください。',
    cancel: 'キャンセル',
    close: '会社をクローズ'
  }
})

const { broadcast } = useBroadcast('company-approachable-status-updated')
const closeCompanyMutation = reactive(useCloseCompany())

const typeOptions = [
  { label: 'IPO', value: CloseCompanyInputType.Ipo },
  { label: 'M&A', value: CloseCompanyInputType.Ma },
  { label: 'Closed', value: CloseCompanyInputType.Closed },
  { label: 'Forbidden', value: CloseCompanyInputType.Forbidden }
] as SelectOption[]

const { data } = useData({
  type: null as CloseCompanyInputType | null,
  note: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  type: {
    required: required()
  },
  note: {
    required: required(),
    maxLength: maxLength(2000)
  }
})

async function close() {
  if (await validateAndNotify()) {
    await closeCompanyMutation.execute(props.company.id, {
      type: data.value.type!,
      note: data.value.note!
    })
    broadcast()
    emit('closed')
  }
}
</script>

<template>
  <SCard class="medium" mode="danger">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Close this company</h2>
            <p>Select a close type and describe the details.</p>
            <ul>
              <li><strong>IPO</strong> – The company has gone public.</li>
              <li><strong>M&A</strong> – The company has been acquired.</li>
              <li><strong>Closed</strong> – The company has been liquidated or bankrupted.</li>
              <li><strong>Forbidden</strong> – The company has been determined that it should not be approached, for example, it was found to have connections with anti-social forces, etc.</li>
            </ul>
            <p>This action cannot be undone.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Companyをクローズする</h2>
            <p>クローズ種別と詳細を入力してください。</p>
            <ul>
              <li><strong>IPO</strong> – 企業がIPOした。</li>
              <li><strong>M&A</strong> – 企業がM&Aされた。</li>
              <li><strong>Closed</strong> – 企業が清算・倒産した。</li>
              <li><strong>Forbidden</strong> – 反社との繋がりが発覚したなど、何らかの理由でアプローチすべきではないと判断された。</li>
            </ul>
            <p>この操作は取り消すことができません。</p>
          </STrans>
        </SContent>
        <SInputSelect
          :label="t.i_type_label"
          :placeholder="t.i_type_placeholder"
          :no-search="true"
          :options="typeOptions"
          v-model="data.type"
          :validation="validation.type"
        />
        <SInputTextarea
          :label="t.i_note_label"
          :placeholder="t.i_note_placeholder"
          v-model="data.note"
          :validation="validation.note"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="large" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.close"
            :loading="closeCompanyMutation.loading"
            @click="close"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
