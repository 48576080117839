import { type Day, Model } from './Model'
import { OpportunityInboundOrOutbound } from './OpportunityInboundOrOutbound'
import { OpportunityInvestmentRequest } from './OpportunityInvestmentRequest'
import { OpportunityPromotedSource } from './OpportunityPromotedSource'
import { User } from './User'

export class OpportunitySource extends Model {
  id?: number
  opportunityId?: number
  date: Day | null

  promoterId?: number
  promoter: User | null
  promotedSourceId?: number
  promotedSource: OpportunityPromotedSource | null

  inboundOrOutboundId?: number
  inboundOrOutbound: OpportunityInboundOrOutbound | null

  investmentRequestId?: number
  investmentRequest: OpportunityInvestmentRequest | null

  contactBackground?: string

  createdById?: number
  createdAt: Day | null
  updatedById?: number
  updatedAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.opportunityId = data.opportunityId
    this.date = this.day(data.date)

    this.promoterId = data.promoterId
    this.promoter = this.hasOne(User, data.promoter)
    this.promotedSourceId = data.promotedSourceId
    this.promotedSource = this.hasOne(OpportunityPromotedSource, data.promotedSource)

    this.inboundOrOutboundId = data.inboundOrOutboundId
    this.inboundOrOutbound = this.hasOne(OpportunityInboundOrOutbound, data.inboundOrOutbound)

    this.investmentRequestId = data.investmentRequestId
    this.investmentRequest = this.hasOne(OpportunityInvestmentRequest, data.investmentRequest)

    this.contactBackground = data.contactBackground

    this.createdById = data.createdById
    this.createdAt = this.day(data.createdAt)
    this.updatedById = data.updatedById
    this.updatedAt = this.day(data.updatedAt)
  }
}
