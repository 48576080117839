<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil'
import IconTrash from '~icons/ph/trash'
import SMarkdown from 'sefirot/components/SMarkdown.vue'
import { usePower } from 'sefirot/composables/Power'
import { type CompanyCautionNote } from '@/graph/Company'
import { useCompanyCautionNoteOps } from '@/composables/ops/CompanyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import DAvatar from '../DAvatar.vue'
import CompanyCautionNoteFormDelete from '../company-caution-note/CompanyCautionNoteFormDelete.vue'
import CompanyCautionNoteFormUpdate from '../company-caution-note/CompanyCautionNoteFormUpdate.vue'

defineProps<{
  companyCautionNote: CompanyCautionNote
  canEdit: boolean
}>()

defineEmits<{
  updated: [companyCautionNote: CompanyCautionNote]
  deleted: []
}>()

const userOps = useUserOps()
const noteOps = useCompanyCautionNoteOps()

const updateDialog = usePower()
const deleteDialog = usePower()
</script>

<template>
  <SCard class="CompanyDescCautionNote" mode="info">
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">
            <STrans lang="en">Internal memo</STrans>
            <STrans lang="ja">社内メモ</STrans>
          </SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton v-if="canEdit" :icon="IconNotePencil" @click="updateDialog.on" />
            <SControlActionBarButton v-if="canEdit" :icon="IconTrash" @click="deleteDialog.on" />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="body">
      <SContent>
        <p class="body-text"><SMarkdown inline :content="companyCautionNote.body" /></p>
      </SContent>
      <div class="meta">
        <STrans lang="en">
          <DAvatar size="nano" :user="companyCautionNote.createdBy" />
          <span>{{ userOps.name(companyCautionNote.createdBy) }} updated at {{ noteOps.updatedAt(companyCautionNote).format('MMM D, YYYY') }}.</span>
        </STrans>
        <STrans lang="ja">
          <DAvatar size="nano" :user="companyCautionNote.createdBy" />
          <span>{{ userOps.name(companyCautionNote.createdBy) }} が {{ noteOps.updatedAt(companyCautionNote).format('YYYY.MM.DD') }} に更新。</span>
        </STrans>
      </div>
    </SCardBlock>

    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <CompanyCautionNoteFormUpdate
        :company-caution-note="companyCautionNote"
        @cancel="updateDialog.off"
        @updated="updateDialog.off(() => $emit('updated', $event))"
      />
    </SModal>

    <SModal :open="deleteDialog.state.value" @close="deleteDialog.off">
      <CompanyCautionNoteFormDelete
        :company-caution-note="companyCautionNote"
        @cancel="deleteDialog.off"
        @deleted="deleteDialog.off(() => $emit('deleted'))"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.body-text {
  white-space: pre-wrap;
}

.meta {
  display: flex;
  gap: 8px;
  line-height: 20px;
  font-size: 12px;
  color: var(--c-text-2);
}
</style>
