<script setup lang="ts">
import SInputSelect, { type Option } from 'sefirot/components/SInputSelect.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useGetOpportunityPromotedSourceList } from '@/composables/repos/OportunityPromotedSourceRepo'

const model = defineModel<number>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Promoted source *',
    info: 'The type of how this opportunity was sourced.'
  },
  ja: {
    label: 'Promoted source *',
    info: 'ソーシングの種別。'
  }
})

const { execute: getOpportunityPromotedSourceList } = useGetOpportunityPromotedSourceList()

const promotedSources = await getOpportunityPromotedSourceList()

const options: Option[] = promotedSources.map((s) => {
  return { label: s.name!, value: s.id!, disabled: !s.enabled }
})

if (model.value === 0) {
  model.value = options[0].value
}
</script>

<template>
  <SInputSelect
    :label="t.label"
    :info="t.info"
    :options="options"
    v-model="model"
  />
</template>
