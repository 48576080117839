import { type Deal } from '@/models/Deal'
import { type Authorized, type Resource, usePolicy } from '../Policy'

export interface DealPolicy {
  canView: Authorized
  canUpdate: Authorized
  canEditEventWithBallot: Authorized
}

export function useDealPolicy(deal?: Resource<Deal>): DealPolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(deal, (u, d) => u.can('read', d))
  const canUpdate = defineWhen(deal, (u, d) => u.can('edit', d))

  const canEditEventWithBallot = defineWhen(deal, (u, d) => {
    return u.can('edit', 'dealEventBallot', d)
        && u.can('delete', 'dealEventBallot', d)
  })

  return {
    canView,
    canUpdate,
    canEditEventWithBallot
  }
}
