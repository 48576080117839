<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useCompanyItem } from '@/composables/company/CompanyData'
import CompanyNewsPage from '@/components/company-news/CompanyNewsPage.vue'

const route = useRoute()

const { data: company } = useCompanyItem(Number(route.params.id))
</script>

<template>
  <DPage class="CompaniesNews" :title="`${company?.name} - News`">
    <CompanyNewsPage
      v-if="company"
      :company-url="company.website"
    />
  </DPage>
</template>
