<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useItem } from '@/composables/repos/FundClosingCheckListRecordRepo'
import CompanyInfoAssignees from '@/components/company/CompanyInfoAssignees.vue'
import FundClosingCheckListRecordFormUpdate from '@/components/fund-closing-check-list-record/FundClosingCheckListRecordFormUpdate.vue'

const route = useRoute()

const { data: fundClosingCheckListRecord, loading } = useItem(Number(route.params.id))

const title = computed(() => {
  const name = fundClosingCheckListRecord.value?.company.name

  return name ? `Fund Closing Checklist - ${name}` : 'Loading...'
})
</script>

<template>
  <DPage class="FundClosingCheckListRecordsIdIndex" :title="title">
    <div class="board">
      <div class="grid">
        <FundClosingCheckListRecordFormUpdate
          v-if="!loading"
          :fund-closing-check-list-record="fundClosingCheckListRecord!"
        />
      </div>

      <div class="grid">
        <CompanyInfoAssignees
          v-if="!loading"
          :company="fundClosingCheckListRecord!.company!"
          title="Company assignees"
          :editable="false"
        />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportsIdIndex {
  display: flex;
}

.board {
  display: grid;
  grid-template-columns: minmax(664px, 768px) minmax(344px, 1fr);
  gap: 16px;
  padding: 32px 32px 96px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
