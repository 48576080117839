<script setup lang="ts">
import { computedArray } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { useCanViewUserSettings } from '@/composables/policies/UserPolicy'

const route = useRoute()

const canViewSettings = useCanViewUserSettings()

const menu = computedArray((items) => {
  const userId = Number(route.params.id)

  items.push(
    { label: 'Performance', link: `/users/${userId}/profile/performance` },
    { label: 'Companies', link: `/users/${userId}/profile/companies` },
    { label: 'Deals', link: `/users/${userId}/profile/deals` }
  )

  if (canViewSettings.value.ok) {
    items.push({ label: 'Settings', link: `/users/${userId}/settings` })
  }
})
</script>

<template>
  <DView
    class="UsersId"
    :menu="menu"
  />
</template>

<style scoped lang="postcss">
:deep(.DLocalNav) {
  padding-top: 30px;
  padding-left: 288px;
}
</style>
