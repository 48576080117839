import { type InjectionKey, type Ref, inject, provide } from 'vue'
import { type Company } from '@/graph/Company'

interface CompanyShowPage {
  company: Ref<Company | undefined>
}

const companyShowPageKey = Symbol('company-show-page') as InjectionKey<CompanyShowPage>

export function provideCompanyShowPage(
  company: Ref<Company | undefined>
): void {
  provide(companyShowPageKey, { company })
}

export function useCompanyShowPage(): CompanyShowPage {
  const companyShowPage = inject(companyShowPageKey)
  if (!companyShowPage) {
    throw new Error('`useCompanyShowPage` called without a provider.')
  }
  return companyShowPage
}
