import { OpportunityInboundOrOutboundsDocument } from '@/graphql'
import { Request } from './Request'

export class OpportunityInboundOrOutboundRequest extends Request {
  fetchAll() {
    return this.request({
      query: OpportunityInboundOrOutboundsDocument
    })
  }
}
