<script setup lang="ts">
import { type Component, computed } from 'vue'

export type Mode =
  | 'neutral'
  | 'neutral-danger'

const props = defineProps<{
  mode?: Mode
  text: string
  disabled?: boolean
  iconBefore?: Component
}>()

defineEmits<{
  (e: 'click'): void
}>()

const classes = computed(() => [
  props.mode ?? 'neutral',
  { disabled: props.disabled }
])
</script>

<template>
  <button class="DActionListItem" :class="classes" @click="!disabled && $emit('click')">
    <span v-if="iconBefore" class="icon">
      <component :is="iconBefore" class="icon-svg" />
    </span>
    <span class="text">
      {{ text }}
    </span>
  </button>
</template>

<style scoped lang="postcss">
.DActionListItem {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  padding: 0 8px;
  width: 100%;
  height: 32px;
  transition: background-color 0.25s;
}

.DActionListItem.neutral:hover {
  background-color: var(--c-mute);
}

.DActionListItem.neutral-danger:hover {
  background-color: var(--c-danger-dimm-1);

  .text,
  .icon {
    color: var(--c-danger-text);
  }
}

.DActionListItem.disabled,
.DActionListItem.disabled .text,
.DActionListItem.disabled .icon {
  color: var(--c-text-3);
  cursor: not-allowed;
}

.DActionListItem.disabled:hover {
  background-color: transparent;

  .text, .icon {
    color: var(--c-text-3);
  }
}

.text {
  padding: 4px 0;
  font-size: 14px;
  transition: color 0.25s;
}

.icon {
  color: var(--c-text-2);
  transition: color 0.25s;
}

.icon-svg {
  width: 16px;
  height: 16px;
}
</style>
