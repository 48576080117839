<script setup lang="ts">
import IconPlusCircle from '~icons/ph/plus-circle'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { sleep } from 'sefirot/support/Time'
import { computed, shallowRef } from 'vue'
import {
  type DealSecurityFrag,
  type DealWithIdFrag,
  type DealWithInvestmentInfoFrag,
  type DealWithRoundCurrencyFrag,
  type DealWithSecuritiesFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealSecurityOps } from '@/composables/ops/DealSecurityOps'
import { useMoneyOps } from '@/composables/ops/MoneyOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealDescSecurity from './DealDescSecurity.vue'
import DealFormAddSecurity from './DealFormAddSecurity.vue'

export type Deal =
  & DealWithIdFrag
  & DealWithInvestmentInfoFrag
  & DealWithRoundCurrencyFrag
  & DealWithSecuritiesFrag
  & WithPermissionFrag

const props = defineProps<{
  deal: Deal
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)

const dealOps = useDealOps()
const securityOps = useDealSecurityOps()
const moneyOps = useMoneyOps()

const securityDescModal = usePower(false)
const addSecurityModal = usePower(false)

const selectedSecurityId = shallowRef<number | null>(null)
const selectedSecurity = computed(() => {
  return props.deal.securities.find((s) => s.id === selectedSecurityId.value)!
})

const investmentAmount = computed(() => {
  const money = dealOps.investmentAmount(props.deal)
  return money ? moneyOps.format(money) : null
})

const investedAmount = computed(() => {
  const money = dealOps.investedAmount(props.deal)
  return money ? moneyOps.format(money) : null
})

const table = useTable({
  orders: [
    'name',
    'type',
    'plannedTotalPrice',
    'purchasedTotalPrice'
  ],

  columns: {
    name: {
      label: 'Name',
      cell: (_, security) => ({
        type: 'text',
        value: security.name ?? 'TBD',
        color: 'info',
        onClick() {
          selectedSecurityId.value = security.id
          securityDescModal.on()
        }
      }),
      summaryCell: {
        type: 'text'
      }
    },
    type: {
      label: 'Type',
      cell: (_, security) => ({
        type: 'text',
        value: securityOps.kindText(security),
        color: security.kind ? 'neutral' : 'soft'
      }),
      summaryCell: {
        type: 'empty'
      }
    },
    plannedTotalPrice: {
      label: 'Est. total amount',
      cell: (_, security) => ({
        type: 'text',
        value: plannedTotalPrice(security)
      }),
      summaryCell: {
        type: 'text'
      }
    },
    purchasedTotalPrice: {
      label: 'Acq. total amount',
      resizable: false,
      cell: (_, security) => ({
        type: 'text',
        value: purchasedTotalPrice(security)
      }),
      summaryCell: {
        type: 'text'
      }
    }
  },

  records: computed(() => props.deal.securities),

  summary: computed(() => ({
    name: 'Total',
    type: null,
    plannedTotalPrice: investmentAmount.value,
    purchasedTotalPrice: investedAmount.value
  }))
})

function plannedTotalPrice(security: DealSecurityFrag) {
  const money = securityOps.plannedTotalPriceWithRoundCurrency(props.deal.round, security)
  return money ? moneyOps.format(money) : null
}

function purchasedTotalPrice(security: DealSecurityFrag) {
  const money = securityOps.purchasedTotalPriceWithRoundCurrency(props.deal.round, security)
  return money ? moneyOps.format(money) : null
}

async function onDeleted() {
  securityDescModal.off()
  await sleep(500)
  emit('updated')
}
</script>

<template>
  <DCard title="Securities">
    <div class="container">
      <STable class="table" :options="table" />
      <div v-if="canUpdate" class="action">
        <SButton
          type="text"
          size="small"
          mode="info"
          :icon="IconPlusCircle"
          label="Add security"
          @click="() => addSecurityModal.on()"
        />
      </div>
    </div>

    <DModal size="medium" :open="securityDescModal.state.value">
      <DealDescSecurity
        :deal="deal"
        :security="selectedSecurity!"
        @close="() => securityDescModal.off()"
        @updated="addSecurityModal.off(() => $emit('updated'))"
        @deleted="() => onDeleted()"
      />
    </DModal>

    <DModal size="medium" :open="addSecurityModal.state.value">
      <DealFormAddSecurity
        :deal="deal"
        @cancel="() => addSecurityModal.off()"
        @added="addSecurityModal.off(() => $emit('updated'))"
      />
    </DModal>
  </DCard>
</template>

<style scoped lang="postcss">
.table {
  --table-border-top: 0;
  --table-border-right: 0;
  --table-border-bottom: 0;
  --table-border-left: 0;
  --table-border-radius: 0;
  --table-right-left: 8px;
  --table-padding-left: 8px;
}

.table :deep(.col-name)                { --table-col-width: 25%; }
.table :deep(.col-type)                { --table-col-width: 25%; }
.table :deep(.col-plannedTotalPrice)   { --table-col-width: 25%; }
.table :deep(.col-purchasedTotalPrice) { --table-col-width: 25%; }

.action {
  border-top: 1px solid var(--c-divider-2);
  padding: 8px 12px;
}
</style>
