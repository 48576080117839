import { orderBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type OpportunityInvestmentRequest } from '@/graphql'
import { OpportunityInvestmentRequestRequest } from '@/requests/OpportunityInvestmentRequestRequest'

export type OpportunityInvestmentRequestList = Query<OpportunityInvestmentRequest[]>
export type GetOpportunityInvestmentRequestList = Get<OpportunityInvestmentRequest[]>

export function useOpportunityInvestmentRequestList(): OpportunityInvestmentRequestList {
  return useQuery(() => fetchOpportunityInvestmentRequestList())
}

export function useGetOpportunityInvestmentRequestList(): GetOpportunityInvestmentRequestList {
  return useGet(() => fetchOpportunityInvestmentRequestList())
}

async function fetchOpportunityInvestmentRequestList(): Promise<OpportunityInvestmentRequest[]> {
  const res = await new OpportunityInvestmentRequestRequest().fetchAll()
  return orderBy(res.data.opportunityInvestmentRequests, (o) => o.rank)
}
