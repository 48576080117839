<script setup lang="ts">
import { useSearch } from '@/composables/search/Search'
import DInputSearch from '@/components/DInputSearch.vue'

const { query, search } = useSearch()

function searchIfQueryExists(value: string | null) {
  if (value) {
    search(value)
  }
}
</script>

<template>
  <div class="LayoutMainHeaderNavSearch">
    <DInputSearch
      size="mini"
      placeholder="Search everything"
      :model-value="query"
      @enter="searchIfQueryExists"
    />
  </div>
</template>

<style scoped lang="postcss">
.LayoutMainHeaderNavSearch {
  padding: 13px 0 11px;
}
</style>
