import { type BallotVoterFrag } from '@/graphql'
import { defineOps } from './Ops'

export const useBallotVoterOps = defineOps(({ isJa }) => {
  function name(voter: BallotVoterFrag): string | null {
    return isJa ? voter.nameJa : voter.nameEn
  }

  return {
    name
  }
})
