<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type MoneyWithoutIdFrag } from '@/graphql'
import { useMoneyOps } from '@/composables/ops/MoneyOps'

const props = defineProps<{
  type: 'data' | 'summary'
  name: string | null
  est: MoneyWithoutIdFrag | null
  acq: MoneyWithoutIdFrag | null
  fmc: MoneyWithoutIdFrag | null
}>()

const { t } = useTrans({
  en: {
    est: 'Est. amount',
    acq: 'Acq. amount',
    fmc: 'Acq. amount (JPY)'
  },
  ja: {
    est: '投資予定額',
    acq: '投資完了額',
    fmc: '取得完了額 (JPY)'
  }
})

const moneyOps = useMoneyOps()

const values = computed(() => {
  return {
    est: {
      zero: Number(props.est?.value ?? 0) === 0,
      amount: props.est ? moneyOps.formatWithCurrency(props.est) : '–'
    },
    acq: {
      zero: Number(props.acq?.value ?? 0) === 0,
      amount: props.acq ? moneyOps.formatWithCurrency(props.acq) : '–'
    },
    fmc: {
      zero: Number(props.fmc?.value ?? 0) === 0,
      amount: props.fmc ? moneyOps.formatWithCurrency(props.fmc) : '–'
    }
  }
})
</script>

<template>
  <div class="DealPageReportsSummaryItem" :class="[type]">
    <div class="name" :class="{ mute: !name }">
      {{ name ?? 'Unnamed security' }}
    </div>
    <div class="body">
      <div class="dl" :class="{ zero: values.est.zero }">
        <div class="dt">{{ t.est }}</div>
        <div class="dd">{{ values.est.amount }}</div>
      </div>
      <div class="dl acq" :class="{ zero: values.acq.zero }">
        <div class="dt">{{ t.acq }}</div>
        <div class="dd">{{ values.acq.amount }}</div>
      </div>
      <div class="dl acq" :class="{ zero: values.fmc.zero }">
        <div class="dt">{{ t.fmc }}</div>
        <div class="dd">{{ values.fmc.amount }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsSummaryItem {
  background-color: var(--c-bg-elv-3);

  &.summary {
    background-color: var(--c-soft);
  }
}

.name {
  border-bottom: 1px solid var(--c-divider-2);
  padding: 8px 16px 7px;
  font-size: 12px;
  font-weight: 500;

  &.mute {
    color: var(--c-text-3);
  }
}

.body {
  padding: 8px 16px;
}

.dl {
  display: grid;
  grid-template-columns: 128px 1fr;
  padding: 2px 0;
  font-size: 12px;
  color: var(--c-text-2);
}

.dd {
  letter-spacing: 0;
  text-align: right;
  font-feature-settings: 'tnum';
  color: var(--c-text-2);

  .dl.zero & {
    color: var(--c-text-3);
  }

  .DealPageReportsSummaryItem.summary & {
    color: var(--c-text-1);
  }

  .DealPageReportsSummaryItem.summary .dl.acq & {
    color: var(--c-success-text);
  }

  .DealPageReportsSummaryItem.summary .dl.zero & {
    color: var(--c-text-3);
  }
}
</style>
