<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type Company } from '@/models/Company'
import { useCompanyUpdateExecutive } from '@/composables/company/CompanyData'

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const { t } = useTrans({
  en: {
    i_representative_name_label: 'Representative name *',
    i_representative_name_ph: 'John Doe',
    i_representative_title_label: 'Representative title',
    i_representative_title_ph: 'CEO',
    i_representative_profiles_label: 'Representative profiles',
    i_representative_profiles_ph: 'e.g. John Doe is the Founder and CEO of Example Company, leading the firm in its mission to support startups and foster innovation globally.',
    i_key_personnel_notes_label: 'Key personnel notes',
    i_key_personnel_notes_ph: [
      'e.g.',
      'Person A: Serial entrepreneur with a background in technology and finance.',
      'Person B: Seasoned executive with experience in leading global teams.'
    ].join('\n'),
    a_cancel: 'Cancel',
    a_update: 'Update executive information'
  },
  ja: {
    i_representative_name_label: '代表者名 *',
    i_representative_name_ph: '山田 太郎',
    i_representative_title_label: '代表者役職',
    i_representative_title_ph: '代表取締役社長',
    i_representative_profiles_label: '代表者プロフィール',
    i_representative_profiles_ph: '例: 山田 太郎は、Example Companyの創業者兼CEOであり、スタートアップを支援し、グローバルにイノベーションを促進しています。',
    i_key_personnel_notes_label: 'キーパーソンのメモ',
    i_key_personnel_notes_ph: [
      '例:',
      'Aさん: テクノロジーとファイナンスのバックグラウンドを持つシリアルアントレプレナー。',
      'Bさん: グローバルチームを率いる経験豊富な幹部。'
    ].join('\n'),
    a_cancel: 'キャンセル',
    a_update: '経営陣情報を更新'
  }
})

const { data } = useData({
  representativeName: props.company.representativeName!,
  representativeTitle: props.company.representativeTitle ?? null,
  representativeProfiles: props.company.representativeProfiles ?? null,
  keyPersonnelNotes: props.company.keyPersonnelNotes ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  representativeName: {
    required: required(),
    maxLength: maxLength(70)
  },
  representativeTitle: {
    maxLength: maxLength(100)
  },
  representativeProfiles: {
    maxLength: maxLength(500)
  },
  keyPersonnelNotes: {
    maxLength: maxLength(500)
  }
})

const { loading, execute } = useCompanyUpdateExecutive(props.company.id!, () => ({
  representativeName: data.value.representativeName!,
  representativeTitle: data.value.representativeTitle,
  representativeProfiles: data.value.representativeProfiles,
  keyPersonnelNotes: data.value.keyPersonnelNotes
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update executive information</h2>
          </STrans>
          <STrans lang="ja">
            <h2>経営陣情報を更新する</h2>
          </STrans>
        </SContent>
        <SGrid cols="2" gap="24">
          <SGridItem span="1">
            <SInputText
              :label="t.i_representative_name_label"
              :placeholder="t.i_representative_name_ph"
              v-model="data.representativeName"
              :validation="validation.representativeName"
            />
          </SGridItem>
          <SGridItem span="1">
            <SInputText
              :label="t.i_representative_title_label"
              :placeholder="t.i_representative_title_ph"
              v-model="data.representativeTitle"
              :validation="validation.representativeTitle"
            />
          </SGridItem>
          <SGridItem span="2">
            <SInputTextarea
              :label="t.i_representative_profiles_label"
              :placeholder="t.i_representative_profiles_ph"
              v-model="data.representativeProfiles"
              :validation="validation.representativeProfiles"
            />
          </SGridItem>
          <SGridItem span="2">
            <SInputTextarea
              :label="t.i_key_personnel_notes_label"
              :placeholder="t.i_key_personnel_notes_ph"
              v-model="data.keyPersonnelNotes"
              :validation="validation.keyPersonnelNotes"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
