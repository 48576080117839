import { orderBy } from 'lodash-es'
import {
  type Mutation as SMutation,
  type Query as SQuery,
  useMutation as useSMutation,
  useQuery as useSQuery
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type DealDroppedReasonType,
  type DealEventWithIdFrag,
  type DealForDetailsAndEventBallotFrag,
  type DealForDetailsFrag,
  type DealForReportDetailFrag,
  type DealForReportsFrag,
  type DealForWrapupFrag,
  type DealPageFrag,
  type DealReportWithIdFrag,
  type DealSecurityWithIdFrag,
  type DealWithIdFrag,
  type DealWithRoundFrag,
  type DropDealInput,
  type UserFrag
} from '@/graphql'
import { Deal } from '@/models/Deal'
import {
  type AddDealEventInput,
  type AddDealSecurityInput,
  type DealCondition,
  type DealOrder,
  DealOrderField,
  DealRequest,
  type UpdateDealAssigneesInput,
  type UpdateDealExitPlanInput,
  type UpdateDealExpenseInput,
  type UpdateDealInvestmentInput,
  type UpdateDealRightsInput,
  type UpdateDealScheduleInput
} from '@/requests/DealRequest'
import { type BasicPaginationInput } from '@/requests/Request'
import * as DealPolicy from '@/composables/policies/DealPolicy'
import {
  type Mutation,
  type Page,
  type Query,
  type UseQueryOptions,
  createPage,
  useMutation,
  useQuery
} from '../Api'
import { useMe } from '../Auth'
import { type MoneyInput } from './MoneyRepo'

export {
  type DealCondition,
  type DealOrder,
  DealOrderField,
  type AddDealSecurityInput
}

export type DealPage = SQuery<DealPageFrag>

export type DealWithRound = Query<DealWithRoundFrag, []>
export type DealForSearchPage = Query<Page<Deal>, []>
export type DealForDetails = Query<DealForDetailsFrag | DealForDetailsAndEventBallotFrag, []>
export type DealForReports = Query<DealForReportsFrag, []>
export type DealForReportDetail = Query<DealForReportDetailFrag, []>
export type DealForWrapup = Query<DealForWrapupFrag, []>
export type DealPartnerList = Query<UserFrag[], []>
export type DealDroppedReasonTypeList = SQuery<DealDroppedReasonType[]>

export type CompleteDeal = Mutation<DealWithIdFrag, [number]>

export type UpdateDealSchedule = Mutation<DealWithIdFrag, [number, UpdateDealScheduleInput]>
export type UpdateDealInvestment = Mutation<DealWithIdFrag, [number, UpdateDealInvestmentInput]>
export type UpdateDealRights = Mutation<DealWithIdFrag, [number, UpdateDealRightsInput]>
export type UpdateDealExpense = Mutation<DealWithIdFrag, [number, UpdateDealExpenseInput]>
export type UpdateDealExitPlan = Mutation<DealWithIdFrag, [number, UpdateDealExitPlanInput]>
export type UpdateDealAssignees = Mutation<DealWithIdFrag, [number, UpdateDealAssigneesInput]>

export type AddDealEvent = Mutation<DealEventWithIdFrag, [number, AddDealEventInput]>
export type AddDealSecurity = Mutation<DealSecurityWithIdFrag, [number, AddDealSecurityInput]>
export type AddDealReport = Mutation<DealReportWithIdFrag, [number]>

export type WrapupCompany = SMutation<void, [id: number, input: WrapupCompanyInput]>
export type WrapupRound = Mutation<void, [id: number, input: WrapupRoundInput]>
export type WrapupDeal = SMutation<void, [id: number, input: WrapupDealInput]>

export type DropDeal = SMutation<DealWithIdFrag, [id: number, input: DropDealInput]>

export interface UseDealPageOptions {
  page: BasicPaginationInput
  condition: DealCondition
  orderBy: DealOrder
}

export interface DealForSearchPageOptions {
  page: BasicPaginationInput
  condition: DealCondition
  orderBy: DealOrder
}

export interface WrapupCompanyInput {
  numEmployees: number
  foundedYear: number
  foundedMonth: number
  fiscalMonth: number
  zipcode: string
  address: string
  addressHouseNumber: string | null
  representativeName: string
  representativeTitle: string
  representativeProfiles: string
}

export interface WrapupRoundInput {
  name: number
  nameSuffix: string | null
  preIssuedShares: string
  preValuation: MoneyInput
  postValuation: MoneyInput
  totalAmount: MoneyInput
  participatingInvestors: string
}

export interface WrapupDealInput {
  lead: boolean
  securities: WrapupDealInputSecurity[]
  informationRight: boolean
  boardObserverRight: boolean
  rightToAppointDirector: boolean
  rightForFirstOffer: boolean
  preemptiveRight: boolean
  keymanClause: boolean
  cosaleRight: boolean
  dragAlongRight: boolean
  otherRights: string | null
  estimatedExitMethods: string[]
  estimatedExitTime: string
  estimatedROI: string
  estimatedIRR: string
}

export interface WrapupDealInputSecurity {
  kind: string
  name: string
  plannedTotalPrice: string
  liquidationPreferenceMultiple: string | null
  liquidationPreferencePatternId: number | null
  antiDilutionProvisionId: number | null
  annualInterest: string | null
  repaymentDate: string | null
  repaymentRight: boolean | null
  discount: string | null
  valuationCap: string | null
  eligibleFinancingAmount: string | null
}

export function useDealPage(
  options: MaybeRefOrGetter<UseDealPageOptions>
): DealPage {
  return useSQuery(async () => {
    const o = toValue(options)
    return (await new DealRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )).data.deals
  }, {
    watch: () => toValue(options)
  })
}

export function useDealWithRound(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
) {
  return useQuery<DealWithRoundFrag, []>(async () => {
    return (await new DealRequest().withRound(toValue(id))).data.deal
  }, options)
}

export function useDealForSearchPage(
  options: DealForSearchPageOptions
): DealForSearchPage {
  const query = useQuery<Page<Deal>, []>(async () => {
    const res = await new DealRequest().fetchPageForSearch(
      options.page,
      options.condition,
      options.orderBy
    )

    return createPage(
      res.data.deals.pageInfo,
      res.data.deals.items.map((d) => new Deal(d))
    )
  })

  watch(options, query.execute, { deep: true })

  return query
}

export function useDealForLocalNav(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
) {
  return useQuery(async () => {
    return (await new DealRequest().fetchForLocalNav(toValue(id))).data.deal
  }, options)
}

export function useDealForDetails(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): DealForDetails {
  const { user } = useMe()

  let query: 'forDetails' | 'forDetailsAndEventBallot' | null = null

  return useQuery(async () => {
    const _id = toValue(id)

    if (!query) {
      const check = (await new DealRequest().forCheck(_id)).data.deal

      if (!check) {
        return null
      }

      query = DealPolicy.checkEditEventWithBallot(user, check)
        ? 'forDetailsAndEventBallot'
        : 'forDetails'
    }

    return (await new DealRequest()[query](_id)).data.deal
  }, options)
}

export function useDealForReports(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): DealForReports {
  return useQuery(async () => {
    return (await new DealRequest().forReports(toValue(id))).data.deal
  }, options)
}

export function useDealForReportDetail(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): DealForReportDetail {
  return useQuery<DealForReportDetailFrag, []>(async () => {
    return (await new DealRequest().forReportDetail(toValue(id))).data.deal
  }, options)
}

export function useDealForWrapup(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): DealForWrapup {
  return useQuery(async () => {
    return (await new DealRequest().forWrapup(toValue(id))).data.deal
  }, options)
}

export function useDealPartnerList(): DealPartnerList {
  return useQuery(async () => {
    const { data: { dealPartners } } = await new DealRequest().fetchPartners()

    return orderBy(dealPartners, (d) => d.rank).map(({ user }) => user)
  })
}

export function useDealDroppedReasonTypeList(): DealDroppedReasonTypeList {
  return useSQuery(async () => {
    const { data: { dealDroppedReasonTypes } } = await new DealRequest().fetchDroppedReasonTypes()

    return orderBy(dealDroppedReasonTypes, (d) => d.rank)
  })
}

export function useUpdateDealSchedule(): UpdateDealSchedule {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealScheduleInput]
  >(async (id, input) => {
    return (await new DealRequest().updateSchedule(id, input)).data.deal
  })
}

export function useUpdateDealInvestment(): UpdateDealInvestment {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealInvestmentInput]
  >(async (id, input) => {
    return (await new DealRequest().updateInvestment(id, input)).data.deal
  })
}

export function useUpdateDealRights(): UpdateDealRights {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealRightsInput]
  >(async (id, input) => {
    return (await new DealRequest().updateRights(id, input)).data.deal
  })
}

export function useUpdateDealExpense(): UpdateDealExpense {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealExpenseInput]
  >(async (id, input) => {
    return (await new DealRequest().updateExpense(id, input)).data.deal
  })
}

export function useUpdateDealExitPlan(): UpdateDealExitPlan {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealExitPlanInput]
  >(async (id, input) => {
    return (await new DealRequest().updateExitPlan(id, input)).data.deal
  })
}

export function useUpdateDealAssignees(): UpdateDealAssignees {
  return useMutation<
    DealWithIdFrag,
    [number, UpdateDealAssigneesInput]
  >(async (id, input) => {
    return (await new DealRequest().updateAssignees(id, input)).data.deal
  })
}

export function useCompleteDeal(): CompleteDeal {
  return useMutation<DealWithIdFrag, [number]>(async (id) => {
    return (await new DealRequest().complete(id)).data.deal
  })
}

export function useAddDealEvent(): AddDealEvent {
  return useMutation<
    DealEventWithIdFrag,
    [number, AddDealEventInput]
  >(async (id, input) => {
    return (await new DealRequest().addEvent(id, input)).data.dealEvent
  })
}

export function useAddDealSecurity(): AddDealSecurity {
  return useMutation<
    DealSecurityWithIdFrag,
    [number, AddDealSecurityInput]
  >(async (id, input) => {
    return (await new DealRequest().addSecurity(id, input)).data.dealSecurity
  })
}

export function useAddDealReport(): AddDealReport {
  return useMutation<DealReportWithIdFrag, [number]>(async (id) => {
    return (await new DealRequest().addReport(id)).data.dealReport
  })
}

export function useWrapupCompany(): WrapupCompany {
  return useSMutation(async (http, id, input) => {
    await http.put(`/api/deals/${id}/wrapup-company`, input)
  })
}

export function useWrapupRound(): WrapupRound {
  return useSMutation(async (http, id, input) => {
    await http.put(`/api/deals/${id}/wrapup-round`, input)
  })
}

export function useWrapupDeal(): WrapupDeal {
  return useSMutation(async (http, id, input) => {
    await http.put(`/api/deals/${id}/wrapup-deal`, input)
  })
}

export function useDropDeal(): DropDeal {
  return useSMutation(async (_, id, input) => {
    return (await new DealRequest().drop(id, input)).data.deal
  })
}
