<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { NotAuthenticatedError } from '@/requests/errors/NotAuthenticatedError'
import { RecordNotFoundError } from '@/requests/errors/RecordNotFoundError'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCanViewCompanyPortfolioData } from '@/composables/policies/CompanyPolicy'
import { useCompanyPortfolioDataItem } from '@/composables/repos/CompanyPortfolioDataRepo'
import { useCompanyAndNameItem } from '@/composables/repos/CompanyRepo'
import CompanyFinancialFiguresDesc from '@/components/company/CompanyFinancialFiguresDesc.vue'

const route = useRoute()

const companyId = computed(() => Number(route.params.id))

const companyOps = useCompanyOps()

const { data: company, execute: executeCompanyItem } = useCompanyAndNameItem(companyId.value, {
  immediate: false
})

const { data: portfolioData, execute: executeCompanyPortfolioDataFinancialFiguresItem } = useCompanyPortfolioDataItem(companyId.value, {
  immediate: false
})

try {
  await executeCompanyItem()
} catch (e: unknown) {
  if (!(e instanceof NotAuthenticatedError || e instanceof RecordNotFoundError)) { throw e }
}

try {
  await executeCompanyPortfolioDataFinancialFiguresItem()
} catch (e: unknown) {
  if (!(e instanceof NotAuthenticatedError || e instanceof RecordNotFoundError)) { throw e }
}

const canView = useCanViewCompanyPortfolioData(portfolioData)

const title = computed(() => company.value ? companyOps.name(company.value) : undefined)

// Wait until both `company` and `companyPortfolioData` are fetched otherwise
// some components might get undefined item.
const authorize = computed(() => {
  // Fetched.
  if (company.value === undefined || portfolioData.value === undefined) {
    return null
  }

  // Not found.
  if (company.value === null || portfolioData.value === null) {
    return false
  }

  // Fetched and exists, now policy can be applied.
  return canView.value.ok
})

function execute() {
  executeCompanyItem()
  executeCompanyPortfolioDataFinancialFiguresItem()
}
</script>

<template>
  <DPage class="CompaniesIdFinancialFigures" :title="title" :authorize="authorize">
    <div v-if="company && portfolioData" class="board">
      <div class="grid">
        <CompanyFinancialFiguresDesc
          :portfolio-data="portfolioData"
          @created="execute"
          @updated="execute"
          @deleted="execute"
        />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdFinancialFigures {
  padding: 32px 32px 128px;
}
</style>
