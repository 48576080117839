<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'
import { type DealWithIdFrag, type DealWithRightsFrag } from '@/graphql'
import { useUpdateDealRights } from '@/composables/repos/DealRepo'
import DToggleCheck from '../DToggleCheck.vue'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithRightsFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { execute, loading } = useUpdateDealRights()

const { data } = useData({
  informationRight: props.deal.informationRight,
  boardObserverRight: props.deal.boardObserverRight,
  rightToAppointDirector: props.deal.rightToAppointDirector,
  rightForFirstOffer: props.deal.rightForFirstOffer,
  preemptiveRight: props.deal.preemptiveRight,
  keymanClause: props.deal.keymanClause,
  cosaleRight: props.deal.cosaleRight,
  dragAlongRight: props.deal.dragAlongRight,
  otherRights: props.deal.otherRights
})

const { validation, validateAndNotify } = useValidation(data, {
  otherRights: {
    maxLength: maxLength(255)
  }
})

async function update() {
  if (await validateAndNotify()) {
    await execute(props.deal.id, {
      informationRight: data.value.informationRight,
      boardObserverRight: data.value.boardObserverRight,
      rightToAppointDirector: data.value.rightToAppointDirector,
      rightForFirstOffer: data.value.rightForFirstOffer,
      preemptiveRight: data.value.preemptiveRight,
      keymanClause: data.value.keymanClause,
      cosaleRight: data.value.cosaleRight,
      dragAlongRight: data.value.dragAlongRight,
      otherRights: data.value.otherRights
    })

    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update rights</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <div class="grid">
          <DToggleCheck text="Information right" v-model="data.informationRight" mutable />
          <DToggleCheck text="Board observe right" v-model="data.boardObserverRight" mutable />
          <DToggleCheck text="Right to appoint director" v-model="data.rightToAppointDirector" mutable />
          <DToggleCheck text="Right for first offer" v-model="data.rightForFirstOffer" mutable />
          <DToggleCheck text="Pre-emptive right" v-model="data.preemptiveRight" mutable />
          <DToggleCheck text="Keyman clause" v-model="data.keymanClause" mutable />
          <DToggleCheck text="Co-sale right" v-model="data.cosaleRight" mutable />
          <DToggleCheck text="Drag along right" v-model="data.dragAlongRight" mutable />
        </div>
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Other rights"
          placeholder="List other rights if there’re any"
          v-model="data.otherRights"
          :validation="validation.otherRights"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>

<style scoped lang="postcss">
.grid {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - (12px * 2)) / 3));
  gap: 12px;
}
</style>
