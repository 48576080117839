<script setup lang="ts">
import IconTrash from '~icons/ph/trash-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useRouter } from 'vue-router'
import { type DealForReportDetailFrag, type DealReportFrag } from '@/graphql'
import { useDealReportOps } from '@/composables/ops/DealReportOps'
import { useCanDeleteReport } from '@/composables/policies/DealPolicy'
import { useDeleteDealReport } from '@/composables/repos/DealReportRepo'
import DealDialogDeleteReport from './DealDialogDeleteReport.vue'

const props = defineProps<{
  deal: DealForReportDetailFrag
  report: DealReportFrag
}>()

const router = useRouter()

const { t } = useTrans({
  en: {
    status_label: 'Status',
    created_at_label: 'Created at',
    updated_at_label: 'Updated at',
    delete_this_report: 'Delete this report'
  },
  ja: {
    status_label: 'ステータス',
    created_at_label: '作成日',
    updated_at_label: '更新日',
    delete_this_report: '投資報告書を削除する'
  }
})

const { execute, loading } = useDeleteDealReport()
const reportOps = useDealReportOps()

const deleteDialog = usePower()

const canDelete = useCanDeleteReport(() => props.deal, () => props.report)

async function remove() {
  await execute(props.report.id)
  router.push(`/deals/${props.report.dealId}/reports`)
}
</script>

<template>
  <DCardBlock space="compact">
    <DGrid cols="1">
      <DGridItem span="1" no-line>
        <DGridLabel class="label" :text="t.status_label" />
        <DGridText :text="report.status" />
      </DGridItem>
      <DGridItem span="1" no-line>
        <DGridLabel class="label" :text="t.created_at_label" />
        <DGridText :text="reportOps.createdAtText(report)" />
      </DGridItem>
      <DGridItem span="1" no-line>
        <DGridLabel class="label" :text="t.updated_at_label" />
        <DGridText :text="reportOps.updatedAtText(report)" />
      </DGridItem>
    </DGrid>
  </DCardBlock>

  <DCardBlock space="compact">
    <DActionList>
      <DActionListItem
        v-if="canDelete.ok"
        mode="neutral-danger"
        :icon-before="IconTrash"
        :text="t.delete_this_report"
        @click="deleteDialog.on"
      />
    </DActionList>
  </DCardBlock>

  <DealDialogDeleteReport
    :open="deleteDialog.state.value"
    :report="report"
    :loading="loading"
    @close="deleteDialog.off"
    @cancel="deleteDialog.off"
    @delete="remove"
  />
</template>
