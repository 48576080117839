<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useOpportunityConflictManagementCommitteeOps, useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCanInitiateConflictManagementCommitteeRequest } from '@/composables/policies/OpportunityPolicy'
import OpportunityDescConflictManagementCommitteeContent from './OpportunityDescConflictManagementCommitteeContent.vue'
import OpportunityDescConflictManagementCommitteeEmpty from './OpportunityDescConflictManagementCommitteeEmpty.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const canInitiateCmc = useCanInitiateConflictManagementCommitteeRequest(() => props.opportunity)

const oppoOps = useOpportunityOps()
const cmcOps = useOpportunityConflictManagementCommitteeOps()

const requestLink = computed(() => cmcOps.requestInitiationUrl(props.opportunity))

const latestConflictManagementCommittee = computed(() => oppoOps.latestConflictManagementCommittee(props.opportunity))
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Conflict Management Committee
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canInitiateCmc.ok && !!latestConflictManagementCommittee"
              :icon="IconPlus"
              :href="requestLink"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunityDescConflictManagementCommitteeContent
      v-if="latestConflictManagementCommittee"
      :conflict-management-committee="latestConflictManagementCommittee"
    />
    <OpportunityDescConflictManagementCommitteeEmpty
      v-else
      :opportunity="opportunity"
    />
  </SCard>
</template>
