<script setup lang="ts">
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SInputYMD from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxLength, maxValue, required, requiredYmd, ymd } from 'sefirot/validation/rules'
import { type DealForWrapupFrag } from '@/graphql'
import { toDayOrNull, yearMonthFromYmdOrNull } from '@/support/Day'
import { toNumberOrNull } from '@/support/Num'
import { toStringOrNull } from '@/support/Str'
import { useDealOps } from '@/composables/ops/DealOps'
import { useWrapupDeal } from '@/composables/repos/DealRepo'
import DToggleCheck from '../DToggleCheck.vue'
import DealFormAddReportWrapupDealSecurities from './DealFormAddReportWrapupDealSecurities.vue'

const props = defineProps<{
  deal: DealForWrapupFrag
}>()

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'saved'): void
}>()

const { t } = useTrans({
  en: {
    back: 'Back',
    save: 'Save & Complete'
  },
  ja: {
    back: '戻る',
    save: '保存して完了'
  }
})

const dealOps = useDealOps()
const { execute, loading } = useWrapupDeal()

const { data } = useData({
  lead: props.deal.lead ?? null,
  informationRight: props.deal.informationRight,
  boardObserverRight: props.deal.boardObserverRight,
  rightToAppointDirector: props.deal.rightToAppointDirector,
  rightForFirstOffer: props.deal.rightForFirstOffer,
  preemptiveRight: props.deal.preemptiveRight,
  keymanClause: props.deal.keymanClause,
  cosaleRight: props.deal.cosaleRight,
  dragAlongRight: props.deal.dragAlongRight,
  otherRights: props.deal.otherRights,
  estimatedExitMethods: props.deal.estimatedExitMethods ?? [],
  estimatedExitTime: dealOps.estimatedExitTimeAsYmd(props.deal),
  estimatedROI: props.deal.estimatedROI ?? null,
  estimatedIRR: props.deal.estimatedIRR ?? null,
  securities: createSecuritiesData()
})

const { validation, validate, validateAndNotify } = useValidation(data, {
  lead: {
    required: required()
  },
  otherRights: {
    maxLength: maxLength(255)
  },
  estimatedExitMethods: {
    required: required()
  },
  estimatedExitTime: {
    requiredYmd: requiredYmd(['y', 'm']),
    ymd: ymd(['y', 'm'])
  },
  estimatedROI: {
    required: required(),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  },
  estimatedIRR: {
    required: required(),
    decimal: decimal(),
    maxValue: maxValue(Number.MAX_SAFE_INTEGER)
  }
})

const leadOptions = [
  { label: 'Lead', value: true },
  { label: 'Follow', value: false }
]

const exitMethodOptions = dealOps.exitMethodTextEntries().map(([value, label]) => {
  return { label, value }
})

function createSecuritiesData() {
  return props.deal.securities.map((security) => ({
    kind: security.kind ?? null,
    name: security.name ?? null,
    plannedTotalPrice: security.plannedTotalPrice ?? null,
    liquidationPreferenceMultiple: toNumberOrNull(security.liquidationPreferenceMultiple),
    liquidationPreferencePatternId: security.liquidationPreferencePatternId ?? null,
    antiDilutionProvisionId: security.antiDilutionProvisionId ?? null,
    annualInterest: toNumberOrNull(security.annualInterest),
    repaymentDate: toDayOrNull(security.repaymentDate),
    repaymentRight: security.repaymentRight ?? null,
    discount: toNumberOrNull(security.discount),
    valuationCap: security.valuationCap ?? null,
    eligibleFinancingAmount: security.eligibleFinancingAmount ?? null
  }))
}

function addSecurityData() {
  data.value.securities.push({
    kind: null,
    name: null,
    plannedTotalPrice: null,
    liquidationPreferenceMultiple: null,
    liquidationPreferencePatternId: null,
    antiDilutionProvisionId: null,
    annualInterest: null,
    repaymentDate: null,
    repaymentRight: null,
    discount: null,
    valuationCap: null,
    eligibleFinancingAmount: null
  })
}

async function save() {
  if (await validateAndNotify()) {
    await execute(props.deal.id, {
      boardObserverRight: data.value.boardObserverRight,
      cosaleRight: data.value.cosaleRight,
      dragAlongRight: data.value.dragAlongRight,
      estimatedExitMethods: data.value.estimatedExitMethods,
      estimatedExitTime: yearMonthFromYmdOrNull(data.value.estimatedExitTime)!,
      estimatedIRR: data.value.estimatedIRR!,
      estimatedROI: data.value.estimatedROI!,
      informationRight: data.value.informationRight,
      keymanClause: data.value.keymanClause,
      lead: data.value.lead!,
      otherRights: data.value.otherRights,
      preemptiveRight: data.value.preemptiveRight,
      rightForFirstOffer: data.value.rightForFirstOffer,
      rightToAppointDirector: data.value.rightToAppointDirector,
      securities: data.value.securities.map((s) => ({
        kind: s.kind!,
        name: s.name!,
        plannedTotalPrice: s.plannedTotalPrice!,
        liquidationPreferenceMultiple: toStringOrNull(s.liquidationPreferenceMultiple),
        liquidationPreferencePatternId: s.liquidationPreferencePatternId,
        antiDilutionProvisionId: s.antiDilutionProvisionId,
        annualInterest: toStringOrNull(s.annualInterest),
        repaymentDate: s.repaymentDate?.format('YYYY-MM-DD') ?? null,
        repaymentRight: s.repaymentRight,
        discount: toStringOrNull(s.discount),
        valuationCap: s.valuationCap,
        eligibleFinancingAmount: s.eligibleFinancingAmount
      }))
    })
    emit('saved')
  }
}

validate()
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-48">
      <SDoc>
        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Finalize deal information</h2>
              <p>A deal report requires the following deal information to be present. Fill in any missing information and also check if all fields are up to date.</p>
            </STrans>
            <STrans lang="ja">
              <h2>ディール情報のファイナライズ</h2>
              <p>投資報告書を作成するには以下のディール情報が必要です。不足している情報を入力し、すべての項目が最新であることを確認してください。</p>
            </STrans>
          </SContent>
        </SDocSection>
        <SDivider />
        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Base information</h2>
              <p>Fill in base deal information.</p>
            </STrans>
            <STrans lang="ja">
              <h2>基本情報</h2>
              <p>ディールの基本情報を入力してください。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <SInputRadios
                label="Lead or Follow"
                :options="leadOptions"
                v-model="data.lead"
                :validation="validation.lead"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>

        <SDivider />

        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Securities</h2>
              <p>List all securities to be acquired in the Deal. The total amount of securities specified here is calculated as the total investment amount in the Deal.</p>
            </STrans>
            <STrans lang="ja">
              <h2>有価証券情報</h2>
              <p>本ディールで取得する有価証券を全て列挙してください。ここで指定された有価証券の総額がディールの投資総額として計算されます。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <DealFormAddReportWrapupDealSecurities
                :currency="dealOps.currency(deal)"
                v-model="data.securities"
                @add="addSecurityData"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>

        <SDivider />

        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Rights information</h2>
              <p>Select all rights you intend to acquire in this deal.</p>
            </STrans>
            <STrans lang="ja">
              <h2>権利情報</h2>
              <p>本ディールで取得予定の権利を全て選択してください。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <div class="grid">
                <DToggleCheck text="Information right" v-model="data.informationRight" mutable />
                <DToggleCheck text="Borad observe right" v-model="data.boardObserverRight" mutable />
                <DToggleCheck text="Right to appoint director" v-model="data.rightToAppointDirector" mutable />
                <DToggleCheck text="Right for first offer" v-model="data.rightForFirstOffer" mutable />
                <DToggleCheck text="Pre-emptive right" v-model="data.preemptiveRight" mutable />
                <DToggleCheck text="Keyman clause" v-model="data.keymanClause" mutable />
                <DToggleCheck text="Co-sale right" v-model="data.cosaleRight" mutable />
                <DToggleCheck text="Drag along right" v-model="data.dragAlongRight" mutable />
              </div>
            </SGridItem>

            <SGridItem span="6">
              <SInputTextarea
                label="Other rights"
                placeholder="List other rights if there’re any"
                v-model="data.otherRights"
                :validation="validation.otherRights"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>

        <SDivider />

        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Exit information</h2>
              <p>Enter the Exit information for this Deal.</p>
            </STrans>
            <STrans lang="ja">
              <h2>Exit情報</h2>
              <p>本ディールのExit情報を入力してください。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="3">
              <SInputDropdown
                label="Exit methods *"
                placeholder="Select exit methods"
                :options="exitMethodOptions"
                v-model="data.estimatedExitMethods"
                :validation="validation.estimatedExitMethods"
              />
            </SGridItem>

            <SGridItem span="3">
              <SInputYMD
                label="Exit time *"
                no-date
                v-model="data.estimatedExitTime"
                :validation="validation.estimatedExitTime"
              />
            </SGridItem>

            <SGridItem span="3">
              <SInputText
                label="Est. ROI *"
                placeholder="17.6"
                v-model="data.estimatedROI"
                :validation="validation.estimatedROI"
              />
            </SGridItem>

            <SGridItem span="3">
              <SInputText
                label="Est. IRR *"
                placeholder="22.5"
                v-model="data.estimatedIRR"
                :validation="validation.estimatedIRR"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.back"
            @click="$emit('back')"
          />
          <SControlButton
            :label="t.save"
            mode="info"
            :loading="loading"
            @click="save"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.grid {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - (12px * 2)) / 3));
  gap: 12px;
}
</style>
