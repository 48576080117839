import {
  ProposableFundGroupsDocument,
  UpdateProposalDocument,
  type UpdateProposalInput
} from '@/graphql'
import { Request } from './Request'

export class ProposalRequest extends Request {
  fetchProposableFundGroups(opportunityId: number) {
    return this.request({
      query: ProposableFundGroupsDocument,
      variables: { opportunityId }
    })
  }

  update(id: number, input: UpdateProposalInput) {
    return this.request({
      query: UpdateProposalDocument,
      variables: { id, input }
    })
  }
}
