import { CompanyJapanRegionsDocument } from '@/graphql'
import { Request } from './Request'

export class CompanyVecJapanRegionRequest extends Request {
  fetchAll() {
    return this.request({
      query: CompanyJapanRegionsDocument
    })
  }
}
