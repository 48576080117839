<script setup lang="ts">
import { computed } from 'vue'
import { type SurveyFrag } from '@/graphql'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  survey: SurveyFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const userOps = useUserOps()
const surveyOps = useSurveyOps()

const status = computed(() => ({
  label: surveyOps.statusText(props.survey),
  mode: surveyOps.statusMode(props.survey)
}))

const acceptedFunds = computed(() => props.survey.fundGroups.map(({ name }) => ({ label: name })))

const avatar = computed(() => {
  return {
    name: userOps.name(props.survey.createdBy),
    avatar: userOps.avatarPath(props.survey.createdBy)
  }
})
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc cols="2" gap="24">
      <SDescItem span="1">
        <SDescLabel value="Status" />
        <SDescState :state="status" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Confirmed date" />
        <SDescDay :value="surveyOps.createdAt(survey)" format="YYYY-MM-DD" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Company" />
        <SDescText :value="survey.companyName" pre-wrap />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Email" />
        <SDescText :value="survey.responderEmail" pre-wrap />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Name" />
        <SDescText :value="survey.responderName" pre-wrap />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Title" />
        <SDescText :value="survey.responderTitle" pre-wrap />
      </SDescItem>
      <SDescItem span="2">
        <SDescLabel value="Accepted funds" />
        <SDescPill :pill="acceptedFunds" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Created by" />
        <SDescAvatar :avatar="avatar" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Survey ID" />
        <SDescLink
          :href="surveyOps.path(survey)"
          :value="surveyOps.displayId(survey)"
        />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>
