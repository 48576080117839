<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import SButton from 'sefirot/components/SButton.vue'
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { ref } from 'vue'
import {
  type CompanyBusinessReportFinanceTransitionRecordFrag,
  CompanyBusinessReportFinanceTransitionRecordTemplate
} from '@/graphql'
import { useCompanyBusinessReportFinanceTransitionRecordOps } from '@/composables/ops/CompanyBusinessReportOps'
import CompanyBusinessReportInputFinanceTransitionRecordsItem from './CompanyBusinessReportInputFinanceTransitionRecordsItem.vue'

defineProps<{
  strict: boolean
  disabled: boolean
}>()

const model = defineModel<CompanyBusinessReportFinanceTransitionRecordFrag[]>({ required: true })

const { t } = useTrans({
  en: {
    create_section: 'Create section',
    add_section: 'Add section'
  },
  ja: {
    create_section: 'セクションを作成',
    add_section: 'セクションを追加'
  }
})

const financeTransitionRecordOps = useCompanyBusinessReportFinanceTransitionRecordOps()

const template = ref<CompanyBusinessReportFinanceTransitionRecordTemplate>(CompanyBusinessReportFinanceTransitionRecordTemplate.ThirdPartyAllocation)

function addSection() {
  model.value.push(financeTransitionRecordOps.createData(template.value))
}

function deleteSection(section: number) {
  model.value.splice(section, 1)
}
</script>

<template>
  <SCard class="CompanyBusinessReportInputFinanceTransitionRecords">
    <template v-if="model.length">
      <SCardBlock class="grid">
        <CompanyBusinessReportInputFinanceTransitionRecordsItem
          v-for="(_, index) in model"
          :key="index"
          :index="index"
          :strict="strict"
          :disabled="disabled"
          v-model="model[index]"
          @delete="deleteSection(index)"
        />
      </SCardBlock>
      <SCardBlock v-if="!disabled" class="footer">
        <div class="footer-input">
          <SInputSelect
            :options="financeTransitionRecordOps.createTemplateSelectOptions()"
            v-model="template"
          />
        </div>
        <div class="footer-button">
          <SButton
            :icon="IconPlus"
            :label="t.add_section"
            @click="addSection"
          />
        </div>
      </SCardBlock>
    </template>
    <template v-else>
      <SCardBlock class="s-p-24">
        <div class="empty">
          <div class="empty-text">
            <template v-if="!disabled">
              <STrans lang="en">No financial transactions have been added yet. Select a template and create a section.</STrans>
              <STrans lang="ja">ファイナンスの推移が登録されていません。以下からテンプレートを選択して、セクションを作成してください。</STrans>
            </template>
            <template v-else>
              <STrans lang="en">No financial transactions found.</STrans>
              <STrans lang="ja">ファイナンスの推移が登録されていません。</STrans>
            </template>
          </div>
          <div v-if="!disabled" class="empty-form">
            <div class="empty-input">
              <SInputSelect
                :options="financeTransitionRecordOps.createTemplateSelectOptions()"
                v-model="template"
              />
            </div>
            <div class="empty-button">
              <SButton
                :icon="IconPlus"
                :label="t.add_section"
                @click="addSection"
              />
            </div>
          </div>
        </div>
      </SCardBlock>
    </template>
  </SCard>
</template>

<style scoped lang="postcss">
.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: var(--c-gutter);
}

.footer {
  display: flex;
  gap: 12px;
  padding: 16px;
}

.footer-input {
  flex-grow: 1;
  width: 100%;
}

.footer-button {
  flex-shrink: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.empty-text {
  max-width: 640px;
  font-size: 14px;
  color: var(--c-text-2);
}

.empty-form {
  display: flex;
  gap: 12px;
}

.empty-input {
  flex-grow: 1;
  width: 100%;
}

.empty-button {
  flex-shrink: 0;
}
</style>
