<script setup lang="ts">
import SAvatar from 'sefirot/components/SAvatar.vue'
import STooltip from 'sefirot/components/STooltip.vue'
import { type Position } from 'sefirot/composables/Tooltip'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { type User } from '@/models/User'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  users: User[]
  max?: number
  tooltip?: boolean
  tooltipPosition?: Position
}>()

const userOps = useUserOps()

const _max = computed(() => props.max ?? 5)

const displayUsers = computed(() => {
  return props.users?.slice(0, _max.value).reverse() ?? []
})

const count = computed(() => {
  if (!props.users) {
    return null
  }

  return displayUsers.value.length < props.users.length
    ? props.users.length - _max.value
    : ''
})

const component = computed(() => props.tooltip ? STooltip : 'div')
</script>

<template>
  <div class="DAvatars">
    <div class="avatars">
      <div v-for="(user, index) in displayUsers" :key="index" class="avatar">
        <component :is="component" class="tooltip" :text="user.name" :position="tooltipPosition">
          <SAvatar
            size="small"
            :avatar="userOps.avatarPath(user as UserFrag)"
            :name="user.name"
          />
        </component>
      </div>
    </div>

    <div v-if="count" class="count">
      +{{ count }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DAvatars {
  display: flex;
  align-items: center;
}

.avatars {
  display: flex;
  flex-direction: row-reverse;
}

.avatar {
  position: relative;
  width: 20px;
}

.count {
  padding-left: 14px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
