<script setup lang="ts">
import SInputSelect, { type Option } from 'sefirot/components/SInputSelect.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useGetOpportunityInvestmentRequestList } from '@/composables/repos/OpportunityInvestmentRequestRepo'

const model = defineModel<number>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Investment request *',
    info: 'Whether the startup is requesting to get investment.'
  },
  ja: {
    label: 'Investment request *',
    info: 'スタートアップが投資を求めているかどうか。'
  }
})

const { execute: getOpportunityInvestmentRequestList } = useGetOpportunityInvestmentRequestList()

const investmentRequests = await getOpportunityInvestmentRequestList()

const options: Option[] = investmentRequests.map((s) => {
  return { label: s.name!, value: s.id!, disabled: !s.enabled }
})

if (model.value === 0) {
  model.value = options[0].value
}
</script>

<template>
  <SInputSelect
    :label="t.label"
    :info="t.info"
    :options="options"
    v-model="model"
  />
</template>
