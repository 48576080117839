<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconTrash from '~icons/ph/trash-bold'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useState } from '@/composables/State'
import {
  useCanUnlinkSurveyFromOpportunity,
  useCanUpdateOpportunityPolicy
} from '@/composables/policies/OpportunityPolicy'
import OpportunityDescSurveyContent from './OpportunityDescSurveyContent.vue'
import OpportunityDescSurveyEmpty from './OpportunityDescSurveyEmpty.vue'
import OpportunityFormLinkSurvey from './OpportunityFormLinkSurvey.vue'
import OpportunityFormUnlinkSurvey from './OpportunityFormUnlinkSurvey.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  updated: []
}>()

const canUpdate = useCanUpdateOpportunityPolicy(() => props.opportunity)
const canUnlink = useCanUnlinkSurveyFromOpportunity(() => props.opportunity)

const { data: modal, set, clear } = useState<'update' | 'delete'>(null)
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Survey
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok && opportunity.survey"
              :icon="IconNotePencil"
              @click="set('update')"
            />
            <SControlActionBarButton
              v-if="canUnlink.ok && opportunity.survey"
              :icon="IconTrash"
              @click="set('delete')"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <OpportunityDescSurveyContent v-if="opportunity.survey" :survey="opportunity.survey" />
    <OpportunityDescSurveyEmpty v-else :opportunity="opportunity" @click="set('update')" />
    <SModal :open="modal === 'update'" @close="clear">
      <OpportunityFormLinkSurvey :opportunity="opportunity" @cancel="clear" @updated="clear(() => emit('updated'))" />
    </SModal>
    <SModal :open="modal === 'delete'" @close="clear">
      <OpportunityFormUnlinkSurvey :opportunity="opportunity" @cancel="clear" @updated="clear(() => emit('updated'))" />
    </SModal>
  </SCard>
</template>
