import { PageNotFoundError } from 'sefirot/errors'
import { type RouteRecordRaw } from 'vue-router'
import CompaniesIdActionNotes from '@/pages/companies/[id]/action-notes/index.vue'
import CompaniesIdFinancialFigures from '@/pages/companies/[id]/financial-figures/index.vue'
import CompaniesIdNews from '@/pages/companies/[id]/news/index.vue'
import CompaniesIdOpportunitiesCreate from '@/pages/companies/[id]/opportunities/create/index.vue'
import CompaniesIdPortfolioData from '@/pages/companies/[id]/portfolio-data/index.vue'
import CompaniesIdProfile from '@/pages/companies/[id]/profile/index.vue'
import CompaniesIdSettings from '@/pages/companies/[id]/settings/index.vue'
import CompaniesIdSupportLogs from '@/pages/companies/[id]/support-logs/index.vue'
import CompaniesIdVec from '@/pages/companies/[id]/vec/index.vue'
import CompaniesId from '@/pages/companies/[id].vue'
import CompaniesActionNotes from '@/pages/companies/action-notes/index.vue'
import CompaniesCreate from '@/pages/companies/create/index.vue'
import CompaniesIndex from '@/pages/companies/index.vue'
import CompaniesNews from '@/pages/companies/news/index.vue'
import Companies from '@/pages/companies.vue'
import CompanyBusinessReportsIdIndex from '@/pages/company-business-report/[id]/index.vue'
import CompanyBusinessReportsId from '@/pages/company-business-report/[id].vue'
import CompanyBusinessReportsByFundReportIdIdIndex from '@/pages/company-business-reports-by-fund-report-id/[id]/index.vue'
import CompanyBusinessReportsByFundReportIdId from '@/pages/company-business-reports-by-fund-report-id/[id].vue'
import DealsIdDetails from '@/pages/deals/[id]/details/index.vue'
import DealsIdEntries from '@/pages/deals/[id]/entries/index.vue'
import DealsIdEntriesRequestEp from '@/pages/deals/[id]/entries/request-ep/index.vue'
import DealsIdEntriesRequestFullDd from '@/pages/deals/[id]/entries/request-full-dd/index.vue'
import DealsIdEntriesRequestIc from '@/pages/deals/[id]/entries/request-ic/index.vue'
import DealsIdEntriesRequestMinimalDd from '@/pages/deals/[id]/entries/request-minimal-dd/index.vue'
import DealsIdEntriesRequestPrc from '@/pages/deals/[id]/entries/request-prc/index.vue'
import DealsIdEntriesSubmitDocsForEp from '@/pages/deals/[id]/entries/submit-docs-for-ep/index.vue'
import DealsIdEntriesSubmitDocsForIc from '@/pages/deals/[id]/entries/submit-docs-for-ic/index.vue'
import DealsIdEntriesSubmitDocsForPrc from '@/pages/deals/[id]/entries/submit-docs-for-prc/index.vue'
import DealsIdReportsReportId from '@/pages/deals/[id]/reports/[reportId]/index.vue'
import DealsIdReportsCreate from '@/pages/deals/[id]/reports/create/index.vue'
import DealsIdReports from '@/pages/deals/[id]/reports/index.vue'
import DealsIdSettings from '@/pages/deals/[id]/settings/index.vue'
import DealsId from '@/pages/deals/[id].vue'
import DealsIndex from '@/pages/deals/index.vue'
import Deals from '@/pages/deals.vue'
import FundClosingCheckListRecordsIdIndex from '@/pages/fund-closing-check-list-records/[id]/index.vue'
import FundClosingCheckListRecordsId from '@/pages/fund-closing-check-list-records/[id].vue'
import FundClosingCheckListsIdIndex from '@/pages/fund-closing-check-lists/[id]/index.vue'
import FundClosingCheckListsId from '@/pages/fund-closing-check-lists/[id].vue'
import FundClosingCheckListsIndex from '@/pages/fund-closing-check-lists/index.vue'
import FundClosingCheckLists from '@/pages/fund-closing-check-lists.vue'
import FundReportsIndex from '@/pages/fund-reports/index.vue'
import FundReports from '@/pages/fund-reports.vue'
import FundsIdDashboard from '@/pages/funds/[id]/dashboard.vue'
import FundsId from '@/pages/funds/[id].vue'
import FundsIndex from '@/pages/funds/index.vue'
import Login from '@/pages/login/index.vue'
import OpportunitiesIdDealsCreate from '@/pages/opportunities/[id]/deals/create/index.vue'
import OpportunitiesIdDetails from '@/pages/opportunities/[id]/details/index.vue'
import OpportunitiesId from '@/pages/opportunities/[id].vue'
import OpportunitiesAll from '@/pages/opportunities/all/index.vue'
import OpportunitiesByFundStatus from '@/pages/opportunities/by-fund-status/index.vue'
import Opportunities from '@/pages/opportunities.vue'
import ReportsIndex from '@/pages/reports/index.vue'
import Reports from '@/pages/reports.vue'
import Search from '@/pages/search/index.vue'
import UsersIdProfileCompanies from '@/pages/users/[id]/profile/companies/index.vue'
import UsersIdProfileDeals from '@/pages/users/[id]/profile/deals/index.vue'
import UsersIdProfilePerformance from '@/pages/users/[id]/profile/performance/index.vue'
import UsersIdProfile from '@/pages/users/[id]/profile.vue'
import UsersIdSettings from '@/pages/users/[id]/settings/index.vue'
import UsersId from '@/pages/users/[id].vue'
import UsersIndex from '@/pages/users/index.vue'
import Users from '@/pages/users.vue'
import ViewsId from '@/pages/views/[id]/index.vue'
import ViewsIndex from '@/pages/views/index.vue'
import Views from '@/pages/views.vue'
import { dashboard } from './dashboard'
import { surveys } from './surveys'

export const routes: RouteRecordRaw[] = [
  { path: '/', redirect: '/dashboard' },

  { path: '/login', component: Login },

  { path: '/search', component: Search },

  // Dashboard
  ...dashboard,

  // Companies
  {
    path: '/companies',
    component: Companies,
    children: [
      { path: '', component: CompaniesIndex },
      { path: 'create', component: CompaniesCreate },
      { path: 'action-notes', component: CompaniesActionNotes },
      { path: 'news', component: CompaniesNews }
    ]
  },
  {
    path: '/companies/:id',
    component: CompaniesId,
    children: [
      { path: '', redirect: (to) => ({ path: `/companies/${to.params.id}/profile` }) },
      { path: 'profile', component: CompaniesIdProfile },
      { path: 'action-notes', component: CompaniesIdActionNotes },
      { path: 'news', component: CompaniesIdNews },
      { path: 'portfolio-data', component: CompaniesIdPortfolioData },
      { path: 'financial-figures', component: CompaniesIdFinancialFigures },
      { path: 'vec', component: CompaniesIdVec },
      { path: 'support-logs', component: CompaniesIdSupportLogs },
      { path: 'opportunities', redirect: (to) => ({ path: `/companies/${to.params.id}/profile` }) },
      { path: 'opportunities/create', component: CompaniesIdOpportunitiesCreate },
      { path: 'settings', component: CompaniesIdSettings }
    ]
  },

  // Opportunities
  {
    path: '/opportunities',
    component: Opportunities,
    children: [
      { path: '', redirect: '/opportunities/all' },
      { path: 'all', component: OpportunitiesAll },
      { path: 'by-fund-status', component: OpportunitiesByFundStatus }
    ]
  },
  {
    path: '/opportunities/:id',
    component: OpportunitiesId,
    children: [
      { path: '', redirect: (to) => ({ path: `/opportunities/${to.params.id}/details` }) },
      { path: 'details', component: OpportunitiesIdDetails },
      { path: 'deals', redirect: (to) => ({ path: `/opportunities/${to.params.id}/details` }) },
      { path: 'deals/create', component: OpportunitiesIdDealsCreate }
    ]
  },

  // Deals
  {
    path: '/deals',
    component: Deals,
    children: [
      { path: '', component: DealsIndex }
    ]
  },
  {
    path: '/deals/:id',
    component: DealsId,
    children: [
      { path: '', redirect: (to) => ({ path: `/deals/${to.params.id}/details` }) },
      { path: 'details', component: DealsIdDetails },
      { path: 'reports', component: DealsIdReports },
      { path: 'reports/create', component: DealsIdReportsCreate },
      { path: 'reports/:reportId', component: DealsIdReportsReportId },
      { path: 'entries', component: DealsIdEntries },
      { path: 'entries/request-prc', component: DealsIdEntriesRequestPrc },
      { path: 'entries/submit-docs-for-prc', component: DealsIdEntriesSubmitDocsForPrc },
      { path: 'entries/request-full-dd', component: DealsIdEntriesRequestFullDd },
      { path: 'entries/request-minimal-dd', component: DealsIdEntriesRequestMinimalDd },
      { path: 'entries/request-ep', component: DealsIdEntriesRequestEp },
      { path: 'entries/submit-docs-for-ep', component: DealsIdEntriesSubmitDocsForEp },
      { path: 'entries/request-ic', component: DealsIdEntriesRequestIc },
      { path: 'entries/submit-docs-for-ic', component: DealsIdEntriesSubmitDocsForIc },
      { path: 'settings', component: DealsIdSettings }
    ]
  },

  // Funds
  { path: '/funds', component: FundsIndex },
  {
    path: '/funds/:id',
    component: FundsId,
    children: [
      { path: '', redirect: (to) => ({ path: `/funds/${to.params.id}/dashboard` }) },
      { path: 'dashboard', component: FundsIdDashboard }
    ]
  },

  // Surveys
  ...surveys,

  // Users
  {
    path: '/users',
    component: Users,
    children: [
      { path: '', component: UsersIndex }
    ]
  },
  {
    path: '/users/:id',
    component: UsersId,
    children: [
      { path: '', redirect: (to) => ({ path: `/users/${to.params.id}/profile` }) },
      {
        path: '/users/:id/profile',
        component: UsersIdProfile,
        children: [
          { path: '', redirect: (to) => ({ path: `/users/${to.params.id}/profile/performance` }) },
          { path: 'companies', component: UsersIdProfileCompanies },
          { path: 'deals', component: UsersIdProfileDeals },
          { path: 'performance', component: UsersIdProfilePerformance }
        ]
      },
      { path: 'settings', component: UsersIdSettings }
    ]
  },

  // Views
  {
    path: '/views',
    component: Views,
    children: [
      { path: '', component: ViewsIndex }
    ]
  },
  {
    path: '/views/:id',
    component: ViewsId
  },

  // Reports
  {
    path: '/reports',
    component: Reports,
    children: [
      { path: '', component: ReportsIndex }
    ]
  },

  // Reports
  {
    path: '/reports',
    component: Reports,
    children: [
      { path: '', component: ReportsIndex }
    ]
  },
  {
    path: '/fund-reports',
    component: FundReports,
    children: [
      { path: '', component: FundReportsIndex }
    ]
  },
  {
    path: '/company-business-reports-by-fund-report-id',
    redirect: () => ({ path: '/fund-reports' })
  },
  {
    path: '/company-business-reports-by-fund-report-id/:id',
    component: CompanyBusinessReportsByFundReportIdId,
    children: [
      { path: '', component: CompanyBusinessReportsByFundReportIdIdIndex }
    ]
  },
  {
    path: '/company-business-reports',
    redirect: () => ({ path: '/fund-reports' })
  },
  {
    path: '/company-business-reports/:id',
    component: CompanyBusinessReportsId,
    children: [
      { path: '', component: CompanyBusinessReportsIdIndex }
    ]
  },
  {
    path: '/fund-closing-check-lists',
    component: FundClosingCheckLists,
    children: [
      { path: '', component: FundClosingCheckListsIndex }
    ]
  },
  {
    path: '/fund-closing-check-lists/:id',
    component: FundClosingCheckListsId,
    children: [
      { path: '', component: FundClosingCheckListsIdIndex }
    ]
  },
  {
    path: '/fund-closing-check-list-records/:id',
    component: FundClosingCheckListRecordsId,
    children: [
      { path: '', component: FundClosingCheckListRecordsIdIndex }
    ]
  },

  // Route to catch all "not found" path.
  {
    path: '/:pathMatch(.*)*',
    component: {
      setup() {
        throw new PageNotFoundError()
      }
    }
  }
]
