import { type DealReportWithIdFrag } from '@/graphql'
import {
  DealReportRequest,
  type UpdateDealReportInput
} from '@/requests/DealReportRequest'
import { type Mutation, type Query, useMutation, useQuery } from '../Api'

export type CompletedDealReportCountByCompany = Query<number>
export type CompletedDealReportCountByCompanyAndFund = Query<number>

export type UpdateDealReport = Mutation<DealReportWithIdFrag, [id: number, input: UpdateDealReportInput]>
export type ExportDealReport = Mutation<void, [id: number]>
export type CompleteDealReport = Mutation<DealReportWithIdFrag, [id: number]>
export type IncompleteDealReport = Mutation<DealReportWithIdFrag, [id: number]>
export type DeleteDealReport = Mutation<void, [id: number]>

export function useCompletedDealReportCountByCompany(companyId: number): CompletedDealReportCountByCompany {
  return useQuery(async () => {
    return (await new DealReportRequest().completedDealReportCountByCompany(companyId)).data.completedDealReportCountByCompany
  })
}

export function useCompletedDealReportCountByCompanyAndFund(companyId: number, fundId: number): CompletedDealReportCountByCompanyAndFund {
  return useQuery(async () => {
    return (await new DealReportRequest().completedDealReportCountByCompanyAndFund(companyId, fundId)).data.completedDealReportCountByCompanyAndFund
  })
}

export function useUpdateDealReport(): UpdateDealReport {
  return useMutation<
    DealReportWithIdFrag,
    [id: number, input: UpdateDealReportInput]
  >(async (id, input) => {
    return (await new DealReportRequest().update(id, input)).data.dealReport
  })
}

export function useExportDealReport(): ExportDealReport {
  return useMutation<void, [id: number]>(async (id) => {
    await new DealReportRequest().export(id)
  })
}

export function useCompleteDealReport(): CompleteDealReport {
  return useMutation<DealReportWithIdFrag, [id: number]>(async (id) => {
    return (await new DealReportRequest().complete(id)).data.dealReport
  })
}

export function useIncompleteDealReport(): CompleteDealReport {
  return useMutation<DealReportWithIdFrag, [id: number]>(async (id) => {
    return (await new DealReportRequest().incomplete(id)).data.dealReport
  })
}

export function useDeleteDealReport(): DeleteDealReport {
  return useMutation<void, [id: number]>(async (id) => {
    await new DealReportRequest().delete(id)
  })
}
