<script setup lang="ts">
import { type DealEventFrag } from '@/graphql'
import { useBroadcast } from '@/composables/Channel'
import { useDeleteDealEvent } from '@/composables/repos/DealEventRepo'

const props = defineProps<{
  event: DealEventFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'deleted'): void
}>()

const { loading, execute } = useDeleteDealEvent()

const { broadcast } = useBroadcast('deal-status-updated')

async function destroy() {
  await execute(props.event.id)
  emit('deleted')
  broadcast()
}
</script>

<template>
  <DForm size="small" mode="danger">
    <DFormTitle>Delete the action result</DFormTitle>

    <DFormMedium>
      <p>You’re about delete the action result. This action can’t be undone. Are you absolutely sure you want to delete it?</p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="danger" label="Delete" :loading="loading" @click="destroy" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
