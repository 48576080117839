<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Track record',
    info: 'Track record of the startup such as sales, customer acquistion status, etc.',
    ph: '20% MoM growth in the last 6 months. 100% retention rate for the last 12 months. etc.'
  },
  ja: {
    label: 'Track record',
    info: '売上・実績・顧客獲得状況などのトラックレコード。',
    ph: '直近6ヶ月で月間成長率20%。直近12ヶ月で100%のリテンション率。など。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
