<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useRouter } from 'vue-router'
import { type DealForReportsFrag } from '@/graphql'
import { useBroadcast } from '@/composables/Channel'
import { useState } from '@/composables/State'
import { useCanAddReport, useCanComplete, useDealPolicy } from '@/composables/policies/DealPolicy'
import { useAddDealReport, useCompleteDeal } from '@/composables/repos/DealRepo'
import DealDialogConfirmComplete from './DealDialogConfirmComplete.vue'
import DealDialogDoneComplete from './DealDialogDoneComplete.vue'

const props = defineProps<{
  deal: DealForReportsFrag
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    title: 'Investment reports',
    complete_deal: 'Complete Deal',
    new_report: 'New report'
  },
  ja: {
    title: '投資報告書',
    complete_deal: 'ディールをコンプリートする',
    new_report: '報告書の新規作成'
  }
})

const router = useRouter()

const { canUpdate } = useDealPolicy(props.deal)
const canComplete = useCanComplete(() => props.deal)
const canAddReport = useCanAddReport(() => props.deal)

const addDealReportMutation = useAddDealReport()
const completeDealMutation = useCompleteDeal()

const dialog = useState<'confirm' | 'complete'>(null)
const { broadcast } = useBroadcast('deal-status-updated')

async function addReport() {
  if (!props.deal.reports.length) {
    return router.push(`/deals/${props.deal.id}/reports/create`)
  }

  const report = await addDealReportMutation.execute(props.deal.id)

  router.push(`/deals/${props.deal.id}/reports/${report.id}`)
}

async function completeDeal() {
  await completeDealMutation.execute(props.deal.id)

  emit('updated')
  broadcast()

  dialog.set('complete')
}
</script>

<template>
  <div class="DealPageReportsListControl">
    <div class="title">
      {{ t.title }}
    </div>
    <div class="actions">
      <div v-if="canUpdate" class="action">
        <SButton
          mode="info"
          :label="t.new_report"
          :disabled="!canAddReport.ok"
          :tooltip="{ text: canAddReport.message }"
          :loading="addDealReportMutation.loading.value"
          @click="addReport"
        />
      </div>
      <div v-if="!canComplete.is('no-auth')" class="action">
        <SButton
          mode="success"
          :label="t.complete_deal"
          :disabled="!canComplete.ok"
          :tooltip="{ text: canComplete.message }"
          :loading="completeDealMutation.loading.value"
          @click="dialog.set('confirm')"
        />
      </div>
    </div>

    <SModal :open="dialog.data.value === 'confirm'" @close="dialog.clear()">
      <DealDialogConfirmComplete
        :completing="completeDealMutation.loading.value"
        @complete="completeDeal"
        @close="dialog.clear()"
      />
    </SModal>

    <SModal :open="dialog.data.value === 'complete'" @close="dialog.clear()">
      <DealDialogDoneComplete @close="dialog.clear()" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsListControl {
  display: grid;
  grid-template-columns: 1fr auto;
}

.title {
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
}

.actions {
  display: flex;
  gap: 8px;
}
</style>
