<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type Day } from '@/models/Model'
import { useCreate } from '@/composables/repos/FundClosingCheckListRepo'
import { useMyOngoingFundList } from '@/composables/repos/FundRepo'

export interface Data {
  fundIds: number[]
  date: Day | null
}

defineEmits<{
  (e: 'cancel'): void
}>()

const { t } = useTrans({
  en: {
    title: 'Create fund closing checklist',
    i_funds_ph: 'Select funds',
    cancel: 'Cancel',
    create: 'Create'
  },
  ja: {
    title: 'ファンド決算チェックリスト作成',
    i_funds_ph: 'ファンドを選択',
    cancel: 'キャンセル',
    create: '作成する'
  }
})

const router = useRouter()

const { data: funds } = useMyOngoingFundList()

const { data } = useData<Data>({
  fundIds: [],
  date: null
})

const { validation, validateAndNotify } = useValidation(data, {
  fundIds: { required: required() },
  date: { required: required() }
})

const fundOptions = computed(() => {
  return funds.value?.map((fund) => {
    return { label: fund.nameAbbreviated!, value: fund.id! }
  }) ?? []
})

const { execute, loading } = useCreate(() => ({
  date: data.value.date!.format('YYYY-MM-DD'),
  funds: data.value.fundIds
}))

async function create() {
  if (await validateAndNotify()) {
    const result = await execute()

    result && router.push(result.path())
  }
}
</script>

<template>
  <SCard size="small">
    <SCardBlock>
      <div class="body">
        <DFormTitle>{{ t.title }}</DFormTitle>

        <DFormMedium>
          <p>
            <STrans lang="en">Choose a date to assign to this checklist. The selected date will serve as the checklist’s title and will indicate the publication date of this report.</STrans>
            <STrans lang="ja">チェックリストの公開日を選択してください。この日付はチェックリストのタイトルとして使用され、チェックリストがいつ公開されたものかを記録する目的で使われます。</STrans>
          </p>
        </DFormMedium>

        <DFormGrid>
          <DFormGridItem span="6">
            <SInputDate
              v-model="data.date"
              block
              :validation="validation.date"
            />
          </DFormGridItem>
        </DFormGrid>

        <DFormMedium>
          <p>
            <STrans lang="en">Select all the funds you would like to include in this checklist. Once the checklist is created, we will generate reports for all portfolio companies associated with the selected funds.</STrans>
            <STrans lang="ja">チェックリストに含めるファンドを全て選択してください。選択したファンドのポートフォリオ企業の報告書を作成します。</STrans>
          </p>
        </DFormMedium>

        <DFormGrid>
          <DFormGridItem span="6">
            <SInputDropdown
              :placeholder="t.i_funds_ph"
              :options="fundOptions"
              v-model="data.fundIds"
              :validation="validation.fundIds"
            />
          </DFormGridItem>
        </DFormGrid>
      </div>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.cancel" @click="$emit('cancel')" />
          <SControlButton mode="info" :label="t.create" :loading="loading" @click="create" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style lang="postcss" scoped>
.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
</style>
