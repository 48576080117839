import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { getAuth } from 'firebase/auth'
import { print } from 'graphql'
import { ClientError, GraphQLClient, type Variables } from 'graphql-request'
import { CantCompleteCbrError } from './errors/CantCompleteCbrError'
import { NotAuthenticatedError } from './errors/NotAuthenticatedError'
import { RecordNotFoundError } from './errors/RecordNotFoundError'
import { RequestError } from './errors/RequestError'
import { SurveyAlreadyLinkedError } from './errors/SurveyAlreadyLinkedError'
import { SurveyMissingOpenProposalsError } from './errors/SurveyMissingOpenProposalsError'

export type { BasicPageInfo, BasicPaginationInput } from '@/graphql'
export { OrderDirection } from '@/graphql'

export interface RequestOptions<D = any, V = Record<string, any>> {
  query: TypedDocumentNode<D, V>
  variables?: V
}

export interface Response<T> {
  data: T
  extensions?: Record<string, any>
  status: number
}

export class Request {
  async request<D = any, V extends Variables = Variables>({
    query,
    variables
  }: RequestOptions<D, V>): Promise<Response<D>> {
    const token = (await getAuth().currentUser?.getIdToken()) || ''

    const client = new GraphQLClient(`${import.meta.env.GRAPHQL_ENDPOINT}/graphql`, {
      headers: { authorization: `Bearer ${token}` }
    })

    try {
      return await client.rawRequest(print(query), variables)
    } catch (error: any) {
      if (error instanceof ClientError) {
        const requestError = this.createRequestError(error)
        throw requestError
      }

      throw error
    }
  }

  private createRequestError({ message, request, response }: ClientError): RequestError {
    if (response.errors?.length) {
      if (response.errors.some((e) => e.message.includes('record not found'))) {
        return new RecordNotFoundError(message, { request, response })
      }

      if (response.errors.some((e) => e.message.includes('permission denied'))) {
        return new NotAuthenticatedError(message, { request, response })
      }

      if (response.errors.some((e) => e.message.includes('Survey is already linked to another opportunity.'))) {
        return new SurveyAlreadyLinkedError(message, { request, response })
      }

      if (response.errors.some((e) => e.message.includes('Survey missing fund groups that are already open in the opportunity.'))) {
        return new SurveyMissingOpenProposalsError(message, { request, response })
      }

      if (
        response.errors.some((e) => e.message.includes('not yet review requested'))
        || response.errors.some((e) => e.message.includes('still changes requested'))
        || response.errors.some((e) => e.message.includes('already completed'))
      ) {
        return new CantCompleteCbrError(message, { request, response })
      }
    }

    return new RequestError(message, { request, response })
  }
}
