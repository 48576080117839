import { orderBy } from 'lodash-es'
import {
  type AntiDilutionProvisionFrag,
  type DealSecurityWithIdFrag,
  type LiquidationPreferencePatternFrag
} from '@/graphql'
import {
  DealSecurityRequest,
  type UpdateDealSecurityInput
} from '@/requests/DealSecurityRequest'
import { type Mutation, type Query, useMutation, useQuery } from '../Api'

export type {
  UpdateDealSecurityInput
}

export type UpdateDealSecurity = Mutation<DealSecurityWithIdFrag, [number, UpdateDealSecurityInput]>
export type DeleteDealSecurity = Mutation<void, [number]>

export type LiquidationPreferencePatternList = Query<LiquidationPreferencePatternFrag[], []>
export type AntiDilutionProvisionList = Query<AntiDilutionProvisionFrag[], []>

export function useLiquidationPreferencePatternList(): LiquidationPreferencePatternList {
  return useQuery<LiquidationPreferencePatternFrag[], []>(async () => {
    const { data: { liquidationPreferencePatterns } } = await new DealSecurityRequest().liquidationPreferencePatternList()

    return orderBy(liquidationPreferencePatterns, (l) => l.rank)
  })
}

export function useAntiDilutionProvisionList(): AntiDilutionProvisionList {
  return useQuery<AntiDilutionProvisionFrag[], []>(async () => {
    const { data: { antiDilutionProvisions } } = await new DealSecurityRequest().antiDilutionProvisionList()

    return orderBy(antiDilutionProvisions, (a) => a.rank)
  })
}

export function useUpdateDealSecurity(): UpdateDealSecurity {
  return useMutation<
    DealSecurityWithIdFrag,
    [number, UpdateDealSecurityInput]
  >(async (id, input) => {
    return (await new DealSecurityRequest().update(id, input)).data.dealSecurity
  })
}

export function useDeleteDealSecurity(): DeleteDealSecurity {
  return useMutation<void, [number]>(async (id) => {
    await new DealSecurityRequest().delete(id)
  })
}
