<script setup lang="ts">
import SChartBar from 'sefirot/components/SChartBar.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type FundInvestedDealCountPerYear } from '@/graph/FundInvestedDealCountPerYear'

const props = defineProps<{
  dealCountsPerYear: FundInvestedDealCountPerYear[]
}>()

const { t } = useTrans({
  en: {
    title: 'Invested deals per year'
  },
  ja: {
    title: '年ごとの投資件数'
  }
})

const data = computed(() => {
  return props.dealCountsPerYear.map((d) => {
    return {
      key: d.year.toString(),
      value: d.count
    }
  })
})
</script>

<template>
  <SCard class="FundInvestedDealCountPerYearChart">
    <SCardBlock class="box">
      <div class="header">
        <div class="title">{{ t.title }}</div>
      </div>
      <div v-if="data.length" class="chart">
        <SChartBar
          type="vertical"
          :data
          :ticks="5"
          :tooltip-format="(d) => `${d.value}`"
          :max-bandwidth="16"
          :margins="{
            top: 8,
            right: 24,
            bottom: 24,
            left: 48
          }"
        />
      </div>
      <div v-else class="empty">
        <div class="empty-text">
          <STrans lang="en">The fund has not made any investments yet.</STrans>
          <STrans lang="ja">投資実行したディールがありません。</STrans>
        </div>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.FundInvestedDealCountPerYearChart {
  grid-column: span 2;
  background-color: transparent;

  --c-bg-elv-3: var(--c-bg-1);
}

.box {
  height: 280px;
}

.header {
  padding: 24px;
}

.title {
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
}

.chart {
  width: 100%;
  height: 192px;
}

.empty {
  padding: 0 24px;
  height: 192px;
}

.empty-text {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--c-divider);
  border-radius: 6px;
  height: 100%;
  font-size: 14px;
  color: var(--c-text-3);
}
</style>
