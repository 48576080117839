<script setup lang="ts">
import IconInfo from '~icons/ph/info'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { format } from 'sefirot/support/Num'
import { computed } from 'vue'
import { useCompanyOps, useCompanyParticipationListOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useDashboardCmcUpcomingOpportunityPage } from '@/composables/repos/DashboardRepo'
import DashboardCmcUpcomingOpportunityCatalogAbout from './DashboardCmcUpcomingOpportunityCatalogAbout.vue'

const { t } = useTrans({
  en: {
    title: 'Upcoming opportunities',
    id: 'Oppo ID',
    status: 'Status',
    company: 'Company',
    portfolio_of: 'Portfolio of',
    company_assignees: 'Company assignees',
    total_invested: 'Total invested amount (JPY)',
    schedule: 'Round schedule',
    round_total_amount: 'Round total amount',
    createdAt: 'Created at',
    updatedAt: 'Updated at'
  },
  ja: {
    title: 'Upcoming opportunities',
    id: 'Oppo ID',
    status: 'Status',
    company: 'Company',
    portfolio_of: 'Portfolio of',
    company_assignees: 'Company assignees',
    total_invested: '合計出資済み金額（JPY）',
    schedule: 'Round schedule',
    round_total_amount: 'Round total amount',
    createdAt: '作成日時',
    updatedAt: '更新日時'
  }
})

const { data: options } = useData({
  page: 1,
  perPage: 50
})

useUrlQuerySync(options, {
  casts: { page: Number },
  exclude: ['perPage']
})

const { data, loading } = useDashboardCmcUpcomingOpportunityPage(options)

const companyOps = useCompanyOps()
const companyParticipationListOps = useCompanyParticipationListOps()
const oppoOps = useOpportunityOps()

const aboutDialog = usePower()

const table = useTable({
  records: computed(() => data.value?.data ?? []),
  loading,
  borderless: true,
  orders: [
    'id',
    'status',
    'companyName',
    'portfolioOf',
    'assignees',
    'totalInvested',
    'schedule',
    'roundTotalAmount',
    'createdAt',
    'updatedAt',
    'empty'
  ],
  columns: {
    id: {
      label: t.id,
      cell: (_, oppo) => ({
        type: 'text',
        value: oppoOps.displayId(oppo),
        link: oppoOps.path(oppo),
        color: 'info'
      })
    },
    status: {
      label: t.status,
      cell: (_, oppo) => ({
        type: 'state',
        mode: oppoOps.statusMode(oppo),
        label: oppoOps.statusText(oppo)
      })
    },
    companyName: {
      label: t.company,
      cell: (_, oppo) => ({
        type: 'text',
        value: oppo.companyName,
        link: companyOps.pathFromId(oppo.companyId)
      })
    },
    portfolioOf: {
      label: t.portfolio_of,
      cell: (_, oppo) => ({
        type: 'pills',
        pills: oppo.companyPortfolioOf.map((f) => ({
          label: f.nameAbbreviated
        }))
      })
    },
    assignees: {
      label: t.company_assignees,
      cell: (_, oppo) => ({
        type: 'avatars',
        avatars: companyParticipationListOps.createTableCellAvatarsOptions(oppo.companyParticipations),
        avatarCount: 10,
        nameCount: 0,
        tooltip: true
      })
    },
    schedule: {
      label: t.schedule,
      cell: (_, oppo) => ({
        type: 'text',
        value: oppo.roundSchedule
      })
    },
    totalInvested: {
      label: t.total_invested,
      cell: (_, oppo) => ({
        type: 'number',
        value: Number(oppo.companyTotalInvestedAmount),
        align: 'right',
        separator: true,
        color: Number(oppo.companyTotalInvestedAmount) === 0 ? 'mute' : 'neutral'
      })
    },
    roundTotalAmount: {
      label: t.round_total_amount,
      cell(_, oppo) {
        return oppo.roundTotalAmount
          ? { type: 'text', value: `${oppo.roundTotalAmount.currency.name} ${format(Number(oppo.roundTotalAmount.value))}` }
          : { type: 'empty' }
      }
    },
    createdAt: {
      label: t.createdAt,
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.createdAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: t.updatedAt,
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.updatedAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    empty: {
      cell: { type: 'empty' }
    }
  }
})
</script>

<template>
  <div class="DashboardCmcUpcomingOpportunityCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-pl-16 s-pr-12">
        <SControl>
          <SControlLeft>
            <SControlText>{{ t.title }}</SControlText>
          </SControlLeft>
          <SControlRight>
            <SControlButton
              type="outline"
              mode="mute"
              :icon="IconInfo"
              @click="aboutDialog.on"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
      <SCardBlock class="table">
        <STable :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="data?.meta.total ?? 0"
              :page="data?.meta.page ?? 0"
              :per-page="data?.meta.perPage ?? 0"
              @prev="options.page--"
              @next="options.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
    <SModal :open="aboutDialog.state.value" @close="aboutDialog.off">
      <DashboardCmcUpcomingOpportunityCatalogAbout />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.table :deep(.col-id) {
  font-feature-settings: "tnum";
}

.table :deep(.col-id)               { --table-col-width: 144px; }
.table :deep(.col-status)           { --table-col-width: 136px; }
.table :deep(.col-companyName)      { --table-col-width: 168px; }
.table :deep(.col-portfolioOf)      { --table-col-width: 128px; }
.table :deep(.col-assignees)        { --table-col-width: 192px; }
.table :deep(.col-totalInvested)    { --table-col-width: 208px; }
.table :deep(.col-schedule)         { --table-col-width: 168px; }
.table :deep(.col-roundTotalAmount) { --table-col-width: 192px; }
.table :deep(.col-createdAt)        { --table-col-width: 192px; }
.table :deep(.col-updatedAt)        { --table-col-width: 192px; }
.table :deep(.col-empty)            { --table-col-width: 40px; }
</style>
