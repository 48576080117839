<script setup lang="ts">
import { computedArrayWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { useState } from '@/composables/State'
import { useCompanyPortfolioDataPolicy } from '@/composables/company/CompanyPolicy'
import CompanyPortfolioDataFormUpdateCapital from './CompanyPortfolioDataFormUpdateCapital.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const capital = computed(() => props.portfolioData.capital)
const capitalNotes = computed(() => props.portfolioData.capitalNotes)

const { data: modal, set, clear } = useState<'update'>()
const { canUpdate } = useCompanyPortfolioDataPolicy(props.portfolioData)
const actions = computedArrayWhen(canUpdate, (items) => {
  items.push({ icon: 'edit', onClick: () => set('update') })
})
</script>

<template>
  <DCard title="Capital" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="4">
        <DCardGridLabel text="Capital" />
        <DCardGridMoney :money="capital" />
      </DCardGridItem>
      <DCardGridItem span="4">
        <DCardGridLabel text="Capital Notes" />
        <DCardGridText :text="capitalNotes" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal === 'update'">
      <CompanyPortfolioDataFormUpdateCapital
        :portfolio-data="portfolioData"
        @updated="clear(() => emit('updated'))"
        @cancel="clear(() => emit('cancel'))"
      />
    </SModal>
  </DCard>
</template>
