import { watch } from 'vue'
import { FundClosingCheckListRecord } from '@/models/FundClosingCheckListRecord'
import { type BasicPaginationInput, type FundClosingCheckListRecordOrder, FundClosingCheckListRecordRequest, type UpdateFundClosingCheckListRecordInput } from '@/requests/FundClosingCheckListRecordRequest'
import { type UseQueryOptions, createPage, useMutation, useQuery } from '../Api'

export type { FundClosingCheckListRecordOrder }

interface PageOptions {
  page: BasicPaginationInput
  orderBy: FundClosingCheckListRecordOrder
}

export function usePage(id: number, options: PageOptions) {
  const query = useQuery(async () => {
    const res = await new FundClosingCheckListRecordRequest().fetchPage(
      options.page,
      { fundClosingCheckListId: id },
      options.orderBy
    )

    return createPage(
      res.data.fundClosingChecklistRecords.pageInfo,
      res.data.fundClosingChecklistRecords.items.map((f) => new FundClosingCheckListRecord(f))
    )
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useItem(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new FundClosingCheckListRecordRequest().fetch(id)

    return new FundClosingCheckListRecord(res.data.fundClosingChecklistRecord!)
  }, options)
}

export function useUpdate(
  id: number,
  input: () => UpdateFundClosingCheckListRecordInput
) {
  return useMutation(async () => {
    await new FundClosingCheckListRecordRequest().update(id, input())
  })
}
