<template>
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1126_310)">
      <g clip-path="url(#clip1_1126_310)">
        <path d="M20.2426 11.7574C20.4302 11.9449 20.5355 12.1992 20.5355 12.4645C20.5355 12.7297 20.4302 12.984 20.2426 13.1716L13.1716 20.2426C12.984 20.4302 12.7297 20.5355 12.4645 20.5355C12.1992 20.5355 11.9449 20.4302 11.7574 20.2426C11.5698 20.0551 11.4645 19.8007 11.4645 19.5355C11.4645 19.2703 11.5698 19.016 11.7574 18.8284L18.8284 11.7574C19.016 11.5698 19.2703 11.4645 19.5355 11.4645C19.8007 11.4645 20.0551 11.5698 20.2426 11.7574ZM25.1924 6.80761C27.0105 8.62569 28.2486 10.9421 28.7502 13.4638C29.2518 15.9856 28.9944 18.5994 28.0104 20.9749C27.0265 23.3503 25.3603 25.3806 23.2224 26.8091C21.0846 28.2376 18.5712 29 16 29C13.4288 29 10.9154 28.2376 8.77759 26.8091C6.63975 25.3806 4.9735 23.3503 3.98957 20.9749C3.00563 18.5994 2.74818 15.9856 3.24979 13.4638C3.7514 10.9421 4.98953 8.62569 6.80761 6.80761C9.24737 4.373 12.5533 3.00568 16 3.00569C19.4467 3.00569 22.7526 4.373 25.1924 6.80761ZM23.7782 8.22183C22.2398 6.68345 20.2798 5.6358 18.146 5.21136C16.0122 4.78692 13.8005 5.00476 11.7905 5.83732C9.7805 6.66989 8.06253 8.07979 6.85383 9.88873C5.64514 11.6977 5 13.8244 5 16C5 18.1756 5.64514 20.3023 6.85383 22.1113C8.06253 23.9202 9.7805 25.3301 11.7905 26.1627C13.8005 26.9952 16.0122 27.2131 18.146 26.7886C20.2798 26.3642 22.2398 25.3166 23.7782 23.7782C25.838 21.7137 26.9948 18.9164 26.9948 16C26.9948 13.0836 25.838 10.2863 23.7782 8.22183Z" fill="currentColor" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1126_310">
        <rect width="32" height="32" fill="white" />
      </clipPath>
      <clipPath id="clip1_1126_310">
        <rect width="32" height="32" fill="white" transform="translate(-6.62742 16) rotate(-45)" />
      </clipPath>
    </defs>
  </svg>
</template>
