<script setup lang="ts">
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { useUserOps } from '@/composables/ops/UserOps'
import { useActiveUserList } from '@/composables/repos/UserRepo'

const model = defineModel<number | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Promoter',
    info: 'Who sourced this opportunity.',
    ph: 'Select a member'
  },
  ja: {
    label: 'Promoter',
    info: '誰がこの案件をソーシングしたか。',
    ph: 'メンバーを選択してください'
  }
})

const { data: users } = useActiveUserList()

const userOps = useUserOps()

const userOptions = computed(() => userOps.createDropdownOptions(users.value))
</script>

<template>
  <SInputDropdown
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :options="userOptions"
    nullable
    v-model="model"
  />
</template>
