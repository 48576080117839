<script setup lang="ts">
import { type BallotVoteChoice, type BallotVoterFrag } from '@/graphql'
import DealCardEventListInputsVotesItem from './DealCardEventListInputsVotesItem.vue'

export interface Vote {
  voter: BallotVoterFrag
  choice: BallotVoteChoice
  condition: string | null
  comment: string | null
}

const props = defineProps<{
  modelValue: Vote[]
}>()

const emit = defineEmits<{
  (e: 'update:model-value', value: Vote[]): void
}>()

function updateChoice(index: number, value: BallotVoteChoice) {
  emit('update:model-value', props.modelValue.map((vote, propIndex) => ({
    voter: vote.voter,
    choice: propIndex === index ? value : vote.choice,
    condition: vote.condition,
    comment: vote.comment
  })))
}

function updateCondition(index: number, value: string | null) {
  emit('update:model-value', props.modelValue.map((vote, propIndex) => ({
    voter: vote.voter,
    choice: vote.choice,
    condition: propIndex === index ? value : vote.condition,
    comment: vote.comment
  })))
}

function updateComment(index: number, value: string | null) {
  emit('update:model-value', props.modelValue.map((vote, propIndex) => ({
    voter: vote.voter,
    choice: vote.choice,
    condition: vote.condition,
    comment: propIndex === index ? value : vote.comment
  })))
}
</script>

<template>
  <div class="DealCardEventListInputsVotes">
    <div v-for="(vote, index) in modelValue" :key="index" class="item">
      <DealCardEventListInputsVotesItem
        :voter="vote.voter"
        :choice="vote.choice"
        :condition="vote.condition"
        :comment="vote.comment"
        @update:choice="(v) => updateChoice(index, v)"
        @update:condition="(v) => updateCondition(index, v)"
        @update:comment="(v) => updateComment(index, v)"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealCardEventListInputsVotes {
  display: grid;
  gap: 24px;
}
</style>
