import {
  type FundClosingCheckListRecordCondition,
  FundClosingCheckListRecordDocument,
  type FundClosingCheckListRecordOrder,
  FundClosingCheckListRecordPageDocument,
  UpdateFundClosingCheckListRecordDocument,
  type UpdateFundClosingCheckListRecordInput
} from '@/graphql'
import { type BasicPaginationInput, Request } from './Request'

export type { BasicPaginationInput, FundClosingCheckListRecordCondition, FundClosingCheckListRecordOrder, UpdateFundClosingCheckListRecordInput }

export class FundClosingCheckListRecordRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: FundClosingCheckListRecordCondition,
    orderBy: FundClosingCheckListRecordOrder
  ) {
    return this.request({
      query: FundClosingCheckListRecordPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: FundClosingCheckListRecordDocument,
      variables: { id }
    })
  }

  update(id: number, input: UpdateFundClosingCheckListRecordInput) {
    return this.request({
      query: UpdateFundClosingCheckListRecordDocument,
      variables: { id, input }
    })
  }
}
