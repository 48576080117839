import { orderBy, sortBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type RoundNameFrag } from '@/graphql'
import { RoundNameRequest } from '@/requests/RoundNameRequest'

export type RoundNameList = Query<RoundNameFrag[]>
export type GetRoundNameList = Get<RoundNameFrag[]>

export function useRoundNameList(): RoundNameList {
  return useQuery(() => fetchRoundNameList())
}

export function useGetRoundNameList(): GetRoundNameList {
  return useGet(() => fetchRoundNameList())
}

export function useEnabledRoundNameList(): RoundNameList {
  return useQuery(async () => {
    const res = await new RoundNameRequest().fetchAll()
    const enabledRoundNames = res.data.roundNames.filter((rn) => rn.enabled)
    const sortedRoundNames = sortBy(enabledRoundNames, (rn) => rn.rank)
    return sortedRoundNames
  })
}

async function fetchRoundNameList(): Promise<RoundNameFrag[]> {
  const res = await new RoundNameRequest().fetchAll()
  return orderBy(res.data.roundNames, (rn) => rn.rank)
}
