import { useTrans } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import { type KadomonyNews, KadomonyNewsSource, KadomonyNewsType } from '@/graph/Kadomony'
import { useCompanyOps } from './CompanyOps'
import { defineOps } from './Ops'

export const useKadomonyNewsOps = defineOps(() => {
  const companyOps = useCompanyOps()

  const { t: TypeTextDict } = useTrans({
    en: {
      [KadomonyNewsType.FundRaising]: 'Fund Raising',
      [KadomonyNewsType.ServiceRelease]: 'Service Release',
      [KadomonyNewsType.AddPortfolio]: 'Add Portfolio'
    },
    ja: {
      [KadomonyNewsType.FundRaising]: '資金調達',
      [KadomonyNewsType.ServiceRelease]: 'サービスリリース',
      [KadomonyNewsType.AddPortfolio]: 'ポートフォリオ追加'
    }
  })

  const SourceTextDict = {
    [KadomonyNewsSource.PrTimes]: 'PR Times',
    [KadomonyNewsSource.Accel]: 'Accel',
    [KadomonyNewsSource.AndreessenHorowitz]: 'Andreessen Horowitz',
    [KadomonyNewsSource.GoogleVentures]: 'Google Ventures',
    [KadomonyNewsSource.KleinerPerkins]: 'Kleiner Perkins',
    [KadomonyNewsSource.SequoiaCapital]: 'Sequoia Capital'
  }

  function companyName(news: KadomonyNews): string {
    return news.company?.name ?? news.companyName ?? 'Unkown Company'
  }

  function companyUrl(news: KadomonyNews): string | null {
    return news.company ? companyOps.path(news.company) : null
  }

  function typeText(news: KadomonyNews): string {
    return TypeTextDict[news.type]
  }

  function typeTextByValue(type: KadomonyNewsType): string {
    return TypeTextDict[type]
  }

  function sourceText(news: KadomonyNews): string {
    return SourceTextDict[news.source]
  }

  function sourceTextByValue(source: KadomonyNewsSource): string {
    return SourceTextDict[source]
  }

  function publishedAt(news: KadomonyNews): Day {
    return day(news.publishedAt)
  }

  return {
    companyName,
    companyUrl,
    typeText,
    typeTextByValue,
    sourceText,
    sourceTextByValue,
    publishedAt
  }
})
