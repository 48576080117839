import type * as Field from '@/support/Field'
import { defineOps } from './Ops'

export const useFieldOps = defineOps(({ isJa }) => {
  function label(field: Field.Field): string | undefined {
    return isJa && field.labelJa
      ? field.labelJa
      : field.label ?? undefined
  }

  function placeholder(field: Field.Field): string | undefined {
    return isJa && field.placeholderJa
      ? field.placeholderJa
      : field.placeholder ?? undefined
  }

  return {
    label,
    placeholder
  }
})
