<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type Day, day } from 'sefirot/support/Day'
import { computed } from 'vue'
import { type Fund } from '@/graph/Fund'
import { useFundOps } from '@/composables/ops/FundOps'
import StatBlock from '../stat/StatBlock.vue'
import StatBlockDl from '../stat/StatBlockDl.vue'
import StatBlockHeader from '../stat/StatBlockHeader.vue'

const props = defineProps<{
  fund: Fund
}>()

const { t } = useTrans({
  en: {
    title: 'Term',
    start_date_label: 'Start Date',
    end_date_label: 'End Date',
    remaining_label: 'Remaining',
    remaining_value: (y: number, m: number, d: number) => `${y}y ${m}m ${d}d`,
    format: 'MMM D, ’YY'
  },
  ja: {
    title: '運用期間',
    start_date_label: '開始日',
    end_date_label: '終了日',
    remaining_label: '残日数',
    remaining_value: (y: number, m: number, d: number) => `${y}y ${m}m ${d}d`,
    format: 'YYYY.M.D'
  }
})

const fundOps = useFundOps()

const items = computed(() => {
  const f = props.fund

  const startDate = fundOps.startDate(f)?.format(t.format) ?? '???'
  const endDate = fundOps.endDate(f)?.format(t.format) ?? '???'
  const remaining = getRemaining(fundOps.endDate(f))

  return [
    { label: t.start_date_label, value: startDate },
    { label: t.end_date_label, value: endDate },
    { label: t.remaining_label, value: remaining }
  ]
})

function getRemaining(endDate: Day | null) {
  if (!endDate) {
    return '???'
  }

  const now = day()
  let years = endDate.year() - now.year()
  let months = endDate.month() - now.month()
  let days = endDate.date() - now.date()

  if (days < 0) {
    months--
    const lastMonthDays = endDate.subtract(1, 'month').daysInMonth()
    days += lastMonthDays
  }

  if (months < 0) {
    years--
    months += 12
  }

  return t.remaining_value(years, months, days)
}
</script>

<template>
  <StatBlock>
    <StatBlockHeader :title="t.title" />
    <StatBlockDl :items />
  </StatBlock>
</template>
