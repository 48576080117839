<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type ActionNoteFrag, type CompanyWithIdFrag } from '@/graphql'
import { useCreateActionNote } from '@/composables/repos/ActionNoteRepo'

const props = defineProps<{
  company: CompanyWithIdFrag
}>()

const emit = defineEmits<{
  cancel: []
  created: [actionNote: ActionNoteFrag]
}>()

const mutation = useCreateActionNote()

const { data } = useData({
  title: null as string | null,
  body: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  title: {
    required: required(),
    maxLength: maxLength(255)
  },
  body: {
    required: required(),
    maxLength: maxLength(3000)
  }
})

async function create() {
  if (await validateAndNotify()) {
    const res = await mutation.execute({
      companyId: props.company.id!,
      title: data.value.title!,
      body: data.value.body!
    })
    emit('created', res)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Create a new action note</h2>
        </SContent>
        <SInputText
          label="Title *"
          placeholder="The title of the note"
          v-model="data.title"
          :validation="validation.title"
        />
        <SInputTextarea
          label="Body *"
          placeholder="The description of the note."
          :rows="10"
          v-model="data.body"
          :validation="validation.body"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="mutation.loading.value"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            label="Create action note"
            :loading="mutation.loading.value"
            @click="create"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
