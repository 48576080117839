<script setup lang="ts">
import { computed } from 'vue'
import { type OpportunityInitialReviewMeeting } from '@/graph/Opportunity'
import { type OpportunityInitialReviewMeetingFrag } from '@/graphql'
import { useOpportunityInitialReviewMeetingOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanViewOpportunityInitialReviewMeetingRequestLink } from '@/composables/policies/OpportunityPolicy'

const props = defineProps<{
  initialReviewMeeting: OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const canViewRequestLink = useCanViewOpportunityInitialReviewMeetingRequestLink(
  props.initialReviewMeeting
)

const userOps = useUserOps()
const irmOps = useOpportunityInitialReviewMeetingOps()

const result = computed(() => {
  return irmOps.resultDescState(props.initialReviewMeeting)
})

const avatar = computed(() => {
  return {
    name: userOps.name(props.initialReviewMeeting.initiatedBy),
    avatar: userOps.avatarPath(props.initialReviewMeeting.initiatedBy)
  }
})
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc cols="2" gap="24">
      <SDescItem span="1">
        <SDescLabel value="Result" />
        <SDescState :state="result" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Executed date" />
        <SDescDay :value="irmOps.date(initialReviewMeeting)" format="YYYY-MM-DD" />
      </SDescItem>
      <SDescItem span="2">
        <SDescLabel value="Comments" />
        <SDescText :value="initialReviewMeeting.comment" pre-wrap />
      </SDescItem>
      <SDescItem :span="canViewRequestLink.ok ? 1 : 2">
        <SDescLabel value="Initiated by" />
        <SDescAvatar :avatar="avatar" />
      </SDescItem>
      <SDescItem v-if="canViewRequestLink.ok" span="1">
        <SDescLabel value="Request ID" />
        <SDescLink
          :href="irmOps.requestUrl(initialReviewMeeting)"
          :value="irmOps.requestIdText(initialReviewMeeting)"
        />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>
