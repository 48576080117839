<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Description *',
    ph: 'Based on interviews and investigations, the actual business could not be confirmed, and there are doubts about the CEO’s background. Handle with caution...'
  },
  ja: {
    label: '詳細 *',
    ph: '面談と独自調査の結果、現状事業の実態が確認できず経営者の経歴にも疑義があるため取り扱い注意...'
  }
})

const { validation } = useValidation(() => ({
  model: model.value
}), {
  model: {
    required: required(),
    maxLength: maxLength(4000)
  }
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
