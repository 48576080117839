<script setup lang="ts">
import IconArrowRight from '~icons/ph/arrow-right-bold'
import SInputCheckbox from 'sefirot/components/SInputCheckbox.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type Day } from 'sefirot/support/Day'
import { reactive, ref, watch } from 'vue'
import { type CurrencyFrag } from '@/graphql'
import { type Company } from '@/models/Company'
import { type Currency } from '@/models/Currency'
import { ymdFromYearMonth } from '@/support/Day'
import { format, toNumberOrNull } from '@/support/Num'
import { hasProp } from '@/support/Utils'
import { useCompanyItemForCompanyBusinessReportCopy } from '@/composables/company/CompanyData'
import { type Share } from '../DInputShares.vue'
import { type Data } from './CompanyBusinessReportFormUpdate.vue'

interface Diff {
  basicCompanyInfo: DiffSection<{
    officialName: DiffDetailOfString
    pastNames: DiffDetailOfString
    representativeName: DiffDetailOfString
    foundedYearMonth: DiffDetailOfString
    address: DiffDetailOfString
    description: DiffDetailOfString
  }>
  issuedShares: DiffSection<{
    issuedShares: DiffDetailOfIssuedShare[]
    issuedSharesNotes: DiffDetailOfString
  }>
  potentialDilutiveShares: DiffSection<{
    totalPotentialDilutiveShares: DiffDetailOfNumber
    potentialDilutiveSharesNotes: DiffDetailOfString
  }>
  capital: DiffSection<{
    capitalCurrency: DiffDetailOfCapitalCurrency
    capitalAmount: DiffDetailOfString
    capitalNotes: DiffDetailOfString
  }>
}

interface DiffSection<Detail extends Record<string, DiffSectionDetail> = Record<string, DiffSectionDetail>> {
  title: string
  isSame: boolean
  isSelected?: boolean | 'indeterminate'
  details: Detail
}

type DiffSectionDetail = Exclude<DiffDetail, DiffDetailOfIssuedShare> | DiffDetailOfIssuedShare[]

type DiffType = 'only-in-company' | 'update-or-same' | 'only-in-report'

interface DiffDetailBase {
  title: string
  isSame: boolean
  isSelected: boolean
}

type DiffDetail = DiffDetailOfString | DiffDetailOfNumber | DiffDetailOfCapitalCurrency | DiffDetailOfIssuedShare

interface DiffDetailOfString extends DiffDetailBase {
  asIs: string | null
  asIsDisplayValue?: string | null
  toBe: string | null
  toBeDisplayValue?: string | null
}

interface DiffDetailOfNumber extends DiffDetailBase {
  asIs: number | null
  asIsDisplayValue?: number | string | null
  toBe: number | null
  toBeDisplayValue?: number | string | null
}

interface DiffDetailOfCapitalCurrency extends DiffDetailBase {
  asIs: CurrencyFrag | Currency | null
  asIsDisplayValue: string | null
  toBe: CurrencyFrag | Currency | null
  toBeDisplayValue: string | null
}

interface DiffDetailOfIssuedShare extends DiffDetailBase {
  type: DiffType
  asIs: number | null
  asIsDisplayValue: number | string | null
  toBe: number | null
  toBeDisplayValue: number | string | null
}

export type NewDataFromCompany = Partial<Pick<Data,
  'officialName'
  | 'pastNames'
  | 'representativeName'
  | 'foundedYearMonth'
  | 'address'
  | 'description'
  | 'issuedShares'
  | 'issuedSharesNotes'
  | 'totalPotentialDilutiveShares'
  | 'potentialDilutiveSharesNotes'
  | 'capitalAmount'
  | 'capitalCurrency'
  | 'capitalNotes'
>>

const props = defineProps<{
  currentData: Data
  companyId: Exclude<Company['id'], undefined>
  reportDate: Day
  copyData(newData: NewDataFromCompany): void
  defaultCurrency: CurrencyFrag | Currency
}>()

const emit = defineEmits<{
  cancel: []
  copied: []
}>()

const { t } = useTrans({
  en: {
    title: 'Copy from company data',
    lead: 'You are about to copy data from the company. Review below diffs and select changes to apply as needed.',
    asis: 'As-Is',
    tobe: 'To-Be',
    period: 'Period',
    unit: 'Currency',
    net_sales: 'Net sales',
    ordinary_income: 'Ordinary income',
    net_income: 'Net income',
    total_assets: 'Total assets',
    capital: 'Capital',
    net_assets: 'Net assets',
    cancel: 'Cancel',
    apply_data: 'Apply data',
    i_company_information_label: 'Basic company information',
    i_official_name_label: 'Official name',
    i_past_names_label: 'Past name',
    i_representative_name_label: 'Representative name',
    i_founded_at_label: 'Founded at',
    i_address_label: 'Address',
    i_description_label: 'Description',
    i_issued_shares_label: 'Issued shares',
    i_issued_shares_notes_label: 'Notes',
    i_potential_dilutive_shares_label: 'Potential dilutive shares',
    i_potential_dilutive_shares_amount_label: 'Amount',
    i_potential_dilutive_shares_notes_label: 'Notes',
    i_capital_label: 'Capital',
    i_capital_currency_label: 'Currency',
    i_capital_amount_label: 'Amount',
    i_capital_notes_label: 'Notes'
  },
  ja: {
    title: '会社情報からコピーする',
    lead: '会社情報をコピーします。以下を確認して適用する差分を選択してください。',
    asis: '現在',
    tobe: 'コピー後',
    period: '決算期',
    unit: '通貨',
    net_sales: '純売上',
    ordinary_income: '経常利益',
    net_income: '純利益',
    total_assets: '総資産',
    capital: '資本金',
    net_assets: '純資産',
    cancel: 'キャンセル',
    apply_data: 'コピーする',
    i_company_information_label: '会社情報',
    i_official_name_label: '会社名',
    i_past_names_label: '旧社名',
    i_representative_name_label: '代表者名',
    i_founded_at_label: '設立年月日',
    i_address_label: '所在地',
    i_description_label: '事業内容',
    i_issued_shares_label: '発行済株式数',
    i_issued_shares_notes_label: '補足事項',
    i_potential_dilutive_shares_label: '潜在株式数',
    i_potential_dilutive_shares_amount_label: '数量',
    i_potential_dilutive_shares_notes_label: '補足事項',
    i_capital_label: '資本金',
    i_capital_currency_label: '通貨',
    i_capital_amount_label: '数量',
    i_capital_notes_label: '補足事項'
  }
})

const issuedSharesCache = new Map<string, {
  index: number
  toBe: DiffDetailOfIssuedShare['toBe']
}>()

const onlyInReportShares: Share[] = []

const diff = reactive<Diff>({
  basicCompanyInfo: {
    title: t.i_company_information_label,
    isSame: false,
    isSelected: false,
    details: {
      officialName: {
        title: t.i_official_name_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.officialName || null,
        toBe: null
      },
      pastNames: {
        title: t.i_past_names_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.pastNames || null,
        toBe: null
      },
      representativeName: {
        title: t.i_representative_name_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.representativeName || null,
        toBe: null
      },
      foundedYearMonth: {
        title: t.i_founded_at_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.foundedYearMonth?.year && props.currentData.foundedYearMonth?.month
          ? `${props.currentData.foundedYearMonth.year}-${props.currentData.foundedYearMonth.month?.toString().padStart(2, '0')}`
          : props.currentData.foundedYearMonth?.year
            ? `${props.currentData.foundedYearMonth?.year}-MM`
            : props.currentData.foundedYearMonth?.month
              ? `YYYY-${props.currentData.foundedYearMonth?.month?.toString().padStart(2, '0')}`
              : null,
        toBe: null
      },
      address: {
        title: t.i_address_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.address || null,
        toBe: null
      },
      description: {
        title: t.i_description_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.description || null,
        toBe: null
      }
    }
  },
  issuedShares: {
    title: t.i_issued_shares_label,
    isSame: false,
    isSelected: false,
    details: {
      // Diff detail of issued shares can be deternimed
      // only after company's issued shares are fetched
      issuedShares: [],
      issuedSharesNotes: {
        title: t.i_issued_shares_notes_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.issuedSharesNotes || null,
        toBe: null
      }
    }
  },
  potentialDilutiveShares: {
    title: t.i_potential_dilutive_shares_label,
    isSame: false,
    isSelected: false,
    details: {
      totalPotentialDilutiveShares: {
        title: t.i_potential_dilutive_shares_amount_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.totalPotentialDilutiveShares,
        asIsDisplayValue: typeof props.currentData.totalPotentialDilutiveShares === 'number'
          ? format(props.currentData.totalPotentialDilutiveShares)
          : props.currentData.totalPotentialDilutiveShares,
        toBe: null,
        toBeDisplayValue: null
      },
      potentialDilutiveSharesNotes: {
        title: t.i_potential_dilutive_shares_notes_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.potentialDilutiveSharesNotes || null,
        toBe: null
      }
    }
  },
  capital: {
    title: t.i_capital_label,
    isSame: false,
    isSelected: false,
    details: {
      capitalCurrency: {
        title: t.i_capital_currency_label,
        isSame: true,
        isSelected: false,
        asIs: props.currentData.capitalCurrency,
        asIsDisplayValue: `${props.currentData.capitalCurrency.symbol} ${props.currentData.capitalCurrency.name}`,
        toBe: props.currentData.capitalCurrency,
        toBeDisplayValue: `${props.currentData.capitalCurrency.symbol} ${props.currentData.capitalCurrency.name}`
      },
      capitalAmount: {
        title: t.i_capital_amount_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.capitalAmount,
        asIsDisplayValue: typeof props.currentData.capitalAmount === 'string'
          ? format(Number(props.currentData.capitalAmount))
          : props.currentData.capitalAmount,
        toBe: null,
        toBeDisplayValue: null
      },
      capitalNotes: {
        title: t.i_capital_notes_label,
        isSame: false,
        isSelected: false,
        asIs: props.currentData.capitalNotes || null,
        toBe: null
      }
    }
  }
})

const isAllSame = ref(false)

const { data: company, loading: loadingCompany } = useCompanyItemForCompanyBusinessReportCopy(props.companyId)

watch(company, () => {
  const officialName = company.value?.names[0].officialNameLocal || null
  const pastNames = company.value?.names.slice(1).map((n) => n.officialNameLocal).join(', ') || null
  const representativeName = [
    company.value?.representativeTitle || '',
    company.value?.representativeName || ''
  ].join('　').trim() || null
  const foundedYearMonth = company.value?.foundedYearMonthAsYmd.year && company.value?.foundedYearMonthAsYmd.month
    ? `${company.value?.foundedYearMonthAsYmd.year}-${company.value?.foundedYearMonthAsYmd.month?.toString().padStart(2, '0')}`
    : null
  const address = company.value?.location || null
  const description = company.value?.description || null
  const issuedShares = company.value?.portfolioData?.issuedShares ?? []
  const issuedSharesNotes = company.value?.portfolioData?.issuedSharesNotes || null
  const totalPotentialDilutiveShares = company.value?.portfolioData?.totalPotentialDilutiveShares ?? null
  const potentialDilutiveSharesNotes = company.value?.portfolioData?.potentialDilutiveSharesNotes || null
  const capitalCurrency = (company.value?.portfolioData?.capital?.currency ?? null) as CurrencyFrag | null
  const capitalAmount = company.value?.portfolioData?.capital?.value ?? null
  const capitalNotes = company.value?.portfolioData?.capitalNotes || null

  diff.basicCompanyInfo.details.officialName.toBe = officialName
  diff.basicCompanyInfo.details.officialName.isSame = diff.basicCompanyInfo.details.officialName.asIs === officialName
  diff.basicCompanyInfo.details.officialName.isSelected = !diff.basicCompanyInfo.details.officialName.isSame
  diff.basicCompanyInfo.details.pastNames.toBe = pastNames
  diff.basicCompanyInfo.details.pastNames.isSame = diff.basicCompanyInfo.details.pastNames.asIs === pastNames
  diff.basicCompanyInfo.details.pastNames.isSelected = !diff.basicCompanyInfo.details.pastNames.isSame
  diff.basicCompanyInfo.details.representativeName.toBe = representativeName
  diff.basicCompanyInfo.details.representativeName.isSame = diff.basicCompanyInfo.details.representativeName.asIs === representativeName
  diff.basicCompanyInfo.details.representativeName.isSelected = !diff.basicCompanyInfo.details.representativeName.isSame
  diff.basicCompanyInfo.details.foundedYearMonth.toBe = foundedYearMonth
  diff.basicCompanyInfo.details.foundedYearMonth.isSame = diff.basicCompanyInfo.details.foundedYearMonth.asIs === foundedYearMonth
  diff.basicCompanyInfo.details.foundedYearMonth.isSelected = !diff.basicCompanyInfo.details.foundedYearMonth.isSame
  diff.basicCompanyInfo.details.address.toBe = address
  diff.basicCompanyInfo.details.address.isSame = diff.basicCompanyInfo.details.address.asIs === address
  diff.basicCompanyInfo.details.address.isSelected = !diff.basicCompanyInfo.details.address.isSame
  diff.basicCompanyInfo.details.description.toBe = description
  diff.basicCompanyInfo.details.description.isSame = diff.basicCompanyInfo.details.description.asIs === description
  diff.basicCompanyInfo.details.description.isSelected = !diff.basicCompanyInfo.details.description.isSame

  diff.basicCompanyInfo.isSame = Object.entries(diff.basicCompanyInfo.details).every(([_, { isSame }]) => isSame)
  diff.basicCompanyInfo.isSelected = diff.basicCompanyInfo.isSame ? false : diff.basicCompanyInfo.isSelected

  diff.issuedShares.details.issuedShares = issuedShares.map<DiffDetailOfIssuedShare>(({ name, count }, index) => {
    const countAsNumberOrNull = toNumberOrNull(count)

    issuedSharesCache.set(name!, { index, toBe: countAsNumberOrNull })

    return {
      title: name!,
      type: 'only-in-company',
      isSame: false,
      isSelected: true,
      asIs: null,
      asIsDisplayValue: null,
      toBe: countAsNumberOrNull,
      toBeDisplayValue: countAsNumberOrNull === null ? null : format(countAsNumberOrNull)
    }
  })

  props.currentData.issuedShares.forEach(({ name, count }) => {
    const countAsNumberOrNull = toNumberOrNull(count)

    if (name !== null && issuedSharesCache.has(name)) {
      const { index, toBe } = issuedSharesCache.get(name)!

      diff.issuedShares.details.issuedShares[index] = {
        ...diff.issuedShares.details.issuedShares[index],
        type: 'update-or-same',
        isSame: toBe === countAsNumberOrNull,
        isSelected: toBe !== countAsNumberOrNull,
        asIs: countAsNumberOrNull,
        asIsDisplayValue: countAsNumberOrNull === null ? null : format(countAsNumberOrNull)
      }
    } else {
      onlyInReportShares.push({ name, count: countAsNumberOrNull })
    }
  })
  diff.issuedShares.details.issuedShares = diff.issuedShares.details.issuedShares.filter(({ type }) => type !== 'only-in-report')

  diff.issuedShares.details.issuedSharesNotes.toBe = issuedSharesNotes
  diff.issuedShares.details.issuedSharesNotes.isSame = diff.issuedShares.details.issuedSharesNotes.asIs === issuedSharesNotes
  diff.issuedShares.details.issuedSharesNotes.isSelected = !diff.issuedShares.details.issuedSharesNotes.isSame

  diff.issuedShares.isSame = (
    diff.issuedShares.details.issuedShares.every(({ isSame }) => isSame)
    && diff.issuedShares.details.issuedSharesNotes.isSame
  )
  diff.issuedShares.isSelected = (
    diff.issuedShares.details.issuedShares.every(({ isSelected }) => isSelected)
    && diff.issuedShares.details.issuedSharesNotes.isSelected
  )

  diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.toBe = totalPotentialDilutiveShares
  diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.toBeDisplayValue = typeof totalPotentialDilutiveShares === 'number'
    ? format(totalPotentialDilutiveShares)
    : totalPotentialDilutiveShares
  diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.isSame = diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.asIs === totalPotentialDilutiveShares
  diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.isSelected = !diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.isSame
  diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.toBe = potentialDilutiveSharesNotes
  diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.isSame = diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.asIs === totalPotentialDilutiveShares
  diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.isSelected = !diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.isSame

  diff.potentialDilutiveShares.isSame = Object.entries(diff.potentialDilutiveShares.details).every(([_, { isSame }]) => isSame)
  diff.potentialDilutiveShares.isSelected = diff.potentialDilutiveShares.isSame ? false : diff.potentialDilutiveShares.isSelected

  if (capitalCurrency) {
    diff.capital.details.capitalCurrency.toBe = capitalCurrency
    diff.capital.details.capitalCurrency.toBeDisplayValue = `${capitalCurrency.symbol} ${capitalCurrency.name}`
    diff.capital.details.capitalCurrency.isSame = diff.capital.details.capitalCurrency.asIs?.id === capitalCurrency?.id
    diff.capital.details.capitalCurrency.isSelected = !diff.capital.details.capitalCurrency.isSame
  }
  diff.capital.details.capitalAmount.toBe = capitalAmount
  diff.capital.details.capitalAmount.toBeDisplayValue = typeof capitalAmount === 'string'
    ? format(Number(capitalAmount))
    : capitalAmount
  diff.capital.details.capitalAmount.isSame = diff.capital.details.capitalAmount.asIs === capitalAmount
  diff.capital.details.capitalAmount.isSelected = !diff.capital.details.capitalAmount.isSame
  diff.capital.details.capitalNotes.toBe = capitalNotes
  diff.capital.details.capitalNotes.isSame = diff.capital.details.capitalNotes.asIs === capitalNotes
  diff.capital.details.capitalNotes.isSelected = !diff.capital.details.capitalNotes.isSame

  diff.capital.isSame = Object.entries(diff.capital.details).every(([_, { isSame }]) => isSame)
  diff.capital.isSelected = diff.capital.isSame ? false : diff.capital.isSelected

  if (
    diff.basicCompanyInfo.isSame
    && diff.issuedShares.isSame
    && diff.capital.isSame
    && diff.potentialDilutiveShares.isSame
  ) {
    isAllSame.value = true
  }
}, { once: true })

const isAnySelected = ref(false)

for (const key in diff) {
  if (!hasProp(diff, key)) { continue }
  const d: DiffSection = diff[key]

  watch(() => d.isSelected, (isSelected) => {
    if (typeof isSelected !== 'boolean') { return }
    Object.values(d.details)
      .flatMap((detail) => (Array.isArray(detail) ? detail : [detail]))
      .forEach((detail) => {
        if (!detail.isSame) {
          detail.isSelected = isSelected
        }
      })
  })

  watch(() => d.details, () => {
    if (d.isSelected == null) { return }
    const selectableDetail = Object.values(d.details)
      .flatMap((detail) => (Array.isArray(detail) ? detail : [detail]))
      .filter((detail) => !detail.isSame)

    if (selectableDetail.length && selectableDetail.every((detail) => detail.isSelected)) {
      d.isSelected = true
    } else if (selectableDetail.some((detail) => detail.isSelected)) {
      d.isSelected = 'indeterminate'
    } else {
      d.isSelected = false
    }

    isAnySelected.value = Object.values(diff)
      .flatMap((section: DiffSection) => {
        const details = Object.values(section.details)
        return details.flatMap((detail) => (Array.isArray(detail) ? detail : [detail]))
      })
      .some((detail) => detail.isSelected)
  }, { deep: true })
}

const loadingCopy = ref(false)

function copyCompanyData() {
  loadingCopy.value = true

  const newData: NewDataFromCompany = {}

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.officialName.isSelected) {
    newData.officialName = diff.basicCompanyInfo.details.officialName.toBe
  }

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.pastNames.isSelected) {
    newData.pastNames = diff.basicCompanyInfo.details.pastNames.toBe
  }

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.representativeName.isSelected) {
    newData.representativeName = diff.basicCompanyInfo.details.representativeName.toBe
  }

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.foundedYearMonth.isSelected) {
    newData.foundedYearMonth = ymdFromYearMonth(diff.basicCompanyInfo.details.foundedYearMonth.toBe)
  }

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.address.isSelected) {
    newData.address = diff.basicCompanyInfo.details.address.toBe
  }

  if (diff.basicCompanyInfo.isSelected === true || diff.basicCompanyInfo.details.description.isSelected) {
    newData.description = diff.basicCompanyInfo.details.description.toBe
  }

  const selectedIssuedShares = diff.issuedShares.details.issuedShares.reduce((issuedShares, d) => {
    if (d.isSame || (diff.issuedShares.isSelected === true || d.isSelected)) {
      issuedShares.push({ name: d.title, count: d.toBe })
    }

    return issuedShares
  }, [] as Share[])

  newData.issuedShares = [...selectedIssuedShares, ...onlyInReportShares]

  if (diff.issuedShares.isSelected === true || diff.issuedShares.details.issuedSharesNotes.isSelected) {
    newData.issuedSharesNotes = diff.issuedShares.details.issuedSharesNotes.toBe
  }

  if (diff.potentialDilutiveShares.isSelected === true || diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.isSelected) {
    newData.totalPotentialDilutiveShares = diff.potentialDilutiveShares.details.totalPotentialDilutiveShares.toBe
  }

  if (diff.potentialDilutiveShares.isSelected === true || diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.isSelected) {
    newData.potentialDilutiveSharesNotes = diff.potentialDilutiveShares.details.potentialDilutiveSharesNotes.toBe
  }

  if (diff.capital.isSelected === true || diff.capital.details.capitalCurrency.isSelected) {
    if (diff.capital.details.capitalCurrency.toBe === null) {
      newData.capitalCurrency = props.defaultCurrency
    } else {
      newData.capitalCurrency = diff.capital.details.capitalCurrency.toBe
    }
  }

  if (diff.capital.isSelected === true || diff.capital.details.capitalAmount.isSelected) {
    newData.capitalAmount = diff.capital.details.capitalAmount.toBe
  }

  if (diff.capital.isSelected === true || diff.capital.details.capitalNotes.isSelected) {
    newData.capitalNotes = diff.capital.details.capitalNotes.toBe
  }

  props.copyData(newData)
  emit('copied')

  loadingCopy.value = false
}
</script>

<template>
  <SCard
    v-if="!loadingCompany"
    class="CopmanyBusinessReportCopyFromCompany"
    size="xxlarge"
  >
    <SCardBlock class="s-p-24">
      <SHead>
        <SHeadTitle>{{ t.title }}</SHeadTitle>
        <SHeadLead class="lead">{{ t.lead }}</SHeadLead>
      </SHead>
    </SCardBlock>
    <SCardBlock>
      <SGrid gap="1" class="diff-viewer">
        <SGrid
          cols="24"
          gap="1"
          class="diff-viewer-header"
          :class="{ 'no-change': diff.basicCompanyInfo.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="diff.basicCompanyInfo.isSelected"
              :text="diff.basicCompanyInfo.title"
              :disabled="diff.basicCompanyInfo.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.asis }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell" />
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.tobe }}
          </SGridItem>
        </SGrid>
        <SGrid
          v-for="detail in diff.basicCompanyInfo.details"
          :key="detail.title"
          cols="24"
          gap="1"
          class="diff-viewer-row"
          :class="{ 'no-change': detail.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox v-model="detail.isSelected" :text="detail.title" :disabled="detail.isSame" />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ detail.asIs }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
            <IconArrowRight />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ detail.toBe }}
          </SGridItem>
        </SGrid>
        <SGrid
          cols="24"
          gap="1"
          class="diff-viewer-header"
          :class="{ 'no-change': diff.issuedShares.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="diff.issuedShares.isSelected"
              :text="diff.issuedShares.title"
              :disabled="diff.issuedShares.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.asis }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell" />
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.tobe }}
          </SGridItem>
        </SGrid>
        <SGrid
          v-for="detail in diff.issuedShares.details.issuedShares"
          :key="detail.title"
          cols="24"
          gap="1"
          class="diff-viewer-row"
          :class="{ 'no-change': detail.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="detail.isSelected"
              :text="detail.title"
              :disabled="detail.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ detail.asIsDisplayValue }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
            <IconArrowRight />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ detail.toBeDisplayValue }}
          </SGridItem>
        </SGrid>
        <SGrid
          :key="diff.issuedShares.details.issuedSharesNotes.title"
          cols="24"
          gap="1"
          class="diff-viewer-row"
          :class="{ 'no-change': diff.issuedShares.details.issuedSharesNotes.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox v-model="diff.issuedShares.details.issuedSharesNotes.isSelected" :text="diff.issuedShares.details.issuedSharesNotes.title" :disabled="diff.issuedShares.details.issuedSharesNotes.isSame" />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ diff.issuedShares.details.issuedSharesNotes.asIs }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
            <IconArrowRight />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ diff.issuedShares.details.issuedSharesNotes.toBe }}
          </SGridItem>
        </SGrid>
        <SGrid
          cols="24"
          gap="1"
          class="diff-viewer-header"
          :class="{ 'no-change': diff.potentialDilutiveShares.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="diff.potentialDilutiveShares.isSelected"
              :text="diff.potentialDilutiveShares.title"
              :disabled="diff.potentialDilutiveShares.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.asis }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell" />
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.tobe }}
          </SGridItem>
        </SGrid>
        <SGrid
          v-for="detail in diff.potentialDilutiveShares.details"
          :key="detail.title"
          cols="24"
          gap="1"
          class="diff-viewer-row"
          :class="{ 'no-change': detail.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox v-model="detail.isSelected" :text="detail.title" :disabled="detail.isSame" />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ detail.asIsDisplayValue ?? detail.asIs }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
            <IconArrowRight />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ detail.toBeDisplayValue ?? detail.toBe }}
          </SGridItem>
        </SGrid>
        <SGrid
          cols="24"
          gap="1"
          class="diff-viewer-header"
          :class="{ 'no-change': diff.capital.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="diff.capital.isSelected"
              :text="diff.capital.title"
              :disabled="diff.capital.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.asis }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell" />
          <SGridItem span="8" class="diff-viwer-cell">
            {{ t.tobe }}
          </SGridItem>
        </SGrid>
        <SGrid
          v-for="detail in diff.capital.details"
          :key="detail.title"
          cols="24"
          gap="1"
          class="diff-viewer-row"
          :class="{ 'no-change': detail.isSame }"
        >
          <SGridItem span="7" class="diff-viwer-cell diff-viewer-cell-title">
            <SInputCheckbox
              v-model="detail.isSelected"
              :text="detail.title"
              :disabled="detail.isSame"
            />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ detail.asIsDisplayValue ?? detail.asIs }}
          </SGridItem>
          <SGridItem span="1" class="diff-viwer-cell diff-viwer-arrow">
            <IconArrowRight />
          </SGridItem>
          <SGridItem span="8" class="diff-viwer-cell content">
            {{ detail.toBeDisplayValue ?? detail.toBe }}
          </SGridItem>
        </SGrid>
      </SGrid>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loadingCompany || loadingCopy"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.apply_data"
            :loading="loadingCompany || loadingCopy"
            :disabled="isAllSame || !isAnySelected"
            @click="copyCompanyData"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.lead {
  max-width: 640px;
}

.diff-viewer {
  font-size: 14px;
  background-color: var(--c-gutter);

  &:first-child {
    border-top: 0;
  }
}

.no-change {
  &.diff-viewer-header,
  &.diff-viewer-row {
    color: var(--c-text-3);
  }
}

.no-change-only-values {
  & .diff-viwer-cell:not(.diff-viewer-cell-title) {
    color: var(--c-text-3);
  }
}

.diff-viwer-cell {
  padding: 12px 16px;
  font-size: 14px;
  background-color: var(--c-bg-elv-3);
  word-break: break-word;

  .diff-viewer-header & {
    background-color: var(--c-bg-elv-4);
  }

  &.content {
    white-space: pre-wrap;
  }

  &.diff-viwer-arrow {
    display: flex;
    justify-content: center;
    padding: 14px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.diff-viwer-cell-unsupported-currency {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.diff-viewer-cell-title {
  padding: 8px 16px 8px 24px;

  :deep(.diff-viewer-header & .text) {
    font-weight: 600;
  }
}
</style>
