<script setup lang="ts">
import IconPlus from '~icons/ph/plus'
import SButton from 'sefirot/components/SButton.vue'
import { computed } from 'vue'
import { type CurrencyFrag } from '@/graphql'
import { useMoneyOps } from '@/composables/ops/MoneyOps'
import {
  useAntiDilutionProvisionList,
  useLiquidationPreferencePatternList
} from '@/composables/repos/DealSecurityRepo'
import DMoney from '../DMoney.vue'
import DealFormAddReportWrapupDealSecuritiesItem, { type Data as SecurityData } from './DealFormAddReportWrapupDealSecuritiesItem.vue'

const props = defineProps<{
  currency: CurrencyFrag
  modelValue: SecurityData[]
}>()

const { data: liquidationPreferencePatterns } = useLiquidationPreferencePatternList()
const { data: antiDilutionProvisions } = useAntiDilutionProvisionList()

const moneyOps = useMoneyOps()

const totalAmount = computed(() => {
  const value = props.modelValue.reduce((sum, s) => {
    const v = s.plannedTotalPrice ?? '0'
    return String(Number(sum) + Number(v))
  }, '0')

  return moneyOps.create(props.currency, value)
})

const emit = defineEmits<{
  (e: 'update:model-value', value: SecurityData[]): void
  (e: 'add'): void
}>()

function update(index: number, value: SecurityData) {
  emit(
    'update:model-value',
    props.modelValue.map((s, i) => (i === index) ? value : s)
  )
}

function remove(index: number) {
  emit(
    'update:model-value',
    props.modelValue.filter((_, i) => i !== index)
  )
}
</script>

<template>
  <div class="DealFormAddReportWraoupDealSecurities">
    <div v-for="security, index in modelValue" :key="index" class="item">
      <DealFormAddReportWrapupDealSecuritiesItem
        :index="index + 1"
        :currency="currency"
        :security="security"
        :liquidation-preference-patterns="liquidationPreferencePatterns ?? []"
        :anti-dilution-provisions="antiDilutionProvisions ?? []"
        :removable="modelValue.length > 1"
        @update="v => update(index, v)"
        @remove="remove(index)"
      />
    </div>
    <div class="item">
      <div class="footer">
        <div class="add">
          <SButton
            size="small"
            type="text"
            mode="info"
            :icon="IconPlus"
            label="Add security"
            @click="$emit('add')"
          />
        </div>
        <div class="total">
          <span class="total-label">Total amount:</span>
          <span class="total-value">
            <DMoney :money="totalAmount" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealFormAddReportWraoupDealSecurities {
  display: grid;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  background-color: var(--c-soft);
}

.item {
  & + & {
    border-top: 1px solid var(--c-divider-2);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
}

.total {
  display: flex;
  gap: 8px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
}

.total-label {
  color: var(--c-text-2);
}
</style>
