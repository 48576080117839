import { watch } from 'vue'
import { type BasicPaginationInput, type ViewPageCondition, type ViewPageOrder, type ViewShareInput } from '@/graphql'
import { View } from '@/models/View'
import { ViewResult } from '@/models/ViewResult'
import { ViewSchema } from '@/models/ViewSchema'
import { ViewRequest } from '@/requests/ViewRequest'
import { useMutation, useQuery } from '../Api'

export type ViewPageCategory = 'all' | 'admin'

export interface UseViewCollectionOptions {
  page: BasicPaginationInput
  condition: ViewPageCondition
  orderBy: ViewPageOrder
  category: ViewPageCategory
}

export function useViewCollection(options: UseViewCollectionOptions) {
  const query = useQuery(async () => {
    const res = options.category === 'all'
      ? await new ViewRequest().fetchPage(
        options.page,
        options.condition,
        options.orderBy
      )
      : await new ViewRequest().fetchAdminPage(
        options.page,
        options.condition,
        options.orderBy
      )

    return {
      views: res.data.views.items.map((v) => new View(v)),
      page: res.data.views.pageInfo
    }
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useViewItem(id: number) {
  return useQuery(async () => {
    const res = await new ViewRequest().fetch(id)

    return res.data.view ? new View(res.data.view) : null
  })
}

export function useViewSchemaCollection() {
  return useQuery(async () => {
    const res = await new ViewRequest().fetchViewSchemas()

    return res.data.viewSchemas.map((v) => new ViewSchema(v))
  })
}

export function useViewCreate(
  input: () => {
    viewSchemaId: number
    name: string
  }
) {
  return useMutation(async () => {
    const { viewSchemaId, name } = input()
    const res = await new ViewRequest().create(viewSchemaId, name)

    return new View(res.data.view)
  })
}

export function useViewExecute(
  id: number
) {
  return useMutation(async () => {
    const res = await new ViewRequest().executeView(id, 500)

    return new ViewResult(res.data.viewResult)
  })
}

export function useViewShare(
  id: number,
  input: () => ViewShareInput
) {
  return useMutation(async () => {
    const res = await new ViewRequest().share(id, input())

    return new View(res.data.view)
  })
}

export function useViewExport(id: number) {
  return useMutation(async () => {
    await new ViewRequest().startExport(id)
  })
}

export function useViewUnshare(
  id: number,
  input: () => ViewShareInput
) {
  return useMutation(async () => {
    const res = await new ViewRequest().unshare(id, input())

    return new View(res.data.view)
  })
}

export function useViewDelete(id: number) {
  return useMutation(async () => {
    await new ViewRequest().delete(id)
  })
}
