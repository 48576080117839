<script setup lang="ts">
import { useLang, useTrans } from 'sefirot/composables/Lang'
import { useAppearance } from '@/composables/Appearance'
import { useLogout, useMe } from '@/composables/Auth'
import { useLang as useDLang } from '@/composables/Lang'
import IconMoon from '../icon/IconMoon.vue'
import IconSun from '../icon/IconSun.vue'

const lang = useLang()

const { t } = useTrans({
  en: {
    language: 'Language',
    appearance: 'Appearance',
    sign_out: 'Sign out'
  },
  ja: {
    language: '言語',
    appearance: 'テーマ',
    sign_out: 'ログアウト'
  }
})

const { user } = useMe()
const dLang = useDLang()
const appearance = useAppearance()
const logout = useLogout()

async function changeLocale(lang: 'en' | 'ja') {
  await dLang.update(lang)
  window.location.reload()
}
</script>

<template>
  <div class="LayoutMainHeaderAccountDialog">
    <ul class="list">
      <li class="item">
        <SLink class="link" :href="`/users/${user.id}/profile`">My profile</SLink>
      </li>
      <li class="item">
        <SLink class="link" :href="`/companies/?condition[assignee][0]=${user.id}`">My companies</SLink>
      </li>
      <li class="item">
        <SLink class="link" :href="`/opportunities/?condition[assignee][0]=${user.id}`">My oppos</SLink>
      </li>
      <li class="item">
        <SLink class="link" :href="`/deals/?condition[assignee][0]=${user.id}`">My deals</SLink>
      </li>
      <li class="item">
        <SLink class="link" :href="`/surveys/?condition[createdBy][0]=${user.id}`">My surveys</SLink>
      </li>
    </ul>

    <div class="lang">
      <p class="lang-text">{{ t.language }}</p>
      <div class="lang-switch">
        <button class="lang-item" :class="{ active: lang === 'en' }" @click="changeLocale('en')">
          EN
        </button>
        <div class="lang-divider">/</div>
        <button class="lang-item" :class="{ active: lang === 'ja' }" @click="changeLocale('ja')">
          JA
        </button>
      </div>
    </div>

    <div class="appearance" role="button" @click.stop="appearance.toggle">
      <p class="appearance-text">{{ t.appearance }}</p>
      <div class="appearance-switch">
        <div class="appearance-switch-check">
          <div class="appearance-switch-icon">
            <IconSun class="appearance-switch-svg is-sun" />
            <IconMoon class="appearance-switch-svg is-moon" />
          </div>
        </div>
      </div>
    </div>

    <ul class="list">
      <li class="item">
        <button class="link" @click="logout">{{ t.sign_out }}</button>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="postcss">
.LayoutMainHeaderAccountDialog {
  border: 1px solid var(--c-divider);
  border-radius: 12px;
  background-color: var(--c-bg-elv-3);
  width: 208px;
  box-shadow: var(--shadow-depth-3);
  transition: border-color 0.5s, background-color 0.5s;
}

.list {
  padding: 8px 0;
}

.item {
  padding: 0 8px;
}

.link {
  display: block;
  width: 100%;
  padding: 0 8px;
  text-align: left;
  line-height: 32px;
  font-size: 13px;
  border-radius: 6px;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--c-bg-mute-1);
  }
}

.lang {
  display: flex;
  align-items: center;
  padding: 8px 12px 7px 16px;
  border-top: 1px solid var(--c-gutter);
  transition: border-color 0.5s;
}

.lang-text {
  flex-grow: 1;
  line-height: 32px;
  font-size: 13px;
}

.lang-switch {
  display: flex;
  line-height: 32px;
  font-size: 13px;
}

.lang-item {
  border-radius: 6px;
  text-align: center;
  width: 32px;
  font-size: 13px;
  font-weight: 500;
  color: var(--c-text-2);
  transition: color 0.25s;

  &:hover,
  &.active {
    color: var(--c-text-1);
  }
}

.lang-divider {
  color: var(--c-text-3);
}

.appearance {
  display: flex;
  align-items: center;
  padding: 12px 16px 11px 16px;
  border-top: 1px solid var(--c-gutter);
  border-bottom: 1px solid var(--c-gutter);
  transition: border-color .5s;
}

.appearance-text {
  flex-grow: 1;
  font-size: 13px;
}

.appearance-switch {
  position: relative;
  border-radius: 11px;
  width: 40px;
  height: 22px;
  flex-shrink: 0;
  border: 1px solid var(--c-divider-2);
  background-color: var(--c-bg-mute-1);
  transition: border-color 0.25s, background-color 0.25s;
}

.appearance-switch:hover {
  border-color: var(--c-gray);
}

.appearance-switch-check {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--c-white);
  box-shadow: var(--vt-shadow-1);
  transition: background-color 0.25s, transform 0.25s;
}

.dark .appearance-switch-check {
  background-color: var(--c-black);
  transform: translateX(18px);
}

.appearance-switch-icon {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
}

.appearance-switch-svg {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  fill: var(--c-text-2);
}

.dark .appearance-switch-svg {
  fill: var(--c-text-1);
  transition: opacity 0.25s;
}

.appearance-switch-svg.is-sun  { opacity: 1; }
.appearance-switch-svg.is-moon { opacity: 0; }

.dark .appearance-switch-svg.is-sun  { opacity: 0; }
.dark .appearance-switch-svg.is-moon { opacity: 1; }
</style>
