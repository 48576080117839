<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { reactive } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useDeleteAllOpportunityProposals } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  close: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_delete: 'Delete proposals'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_delete: 'Proposalを削除'
  }
})

const deleteAllMutation = reactive(useDeleteAllOpportunityProposals())

async function destroy() {
  await deleteAllMutation.execute(props.opportunity.id)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Delete all proposals</h2>
          <p>You are about delete all fund proposals of this opportunity. This action cannot be undone. Are you absolutely sure you want to delete it?</p>
        </STrans>
        <STrans lang="ja">
          <h2>全てのProposalを削除する</h2>
          <p>このOpportunityの全てのProposalを削除します。この操作は取り消すことができません。本当によろしいですか？</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="deleteAllMutation.loading"
            @click="$emit('close')"
          />
          <SControlButton
            mode="danger"
            :label="t.a_delete"
            :loading="deleteAllMutation.loading"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
