import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  ApproachableStatus,
  type CompanyForDetailsFrag,
  type CompanyForSettingsFrag,
  type CompanyWithAllPortfolioOfsFrag,
  type CompanyWithApproachablesFrag,
  OpportunityStatus,
  type WithPermissionFrag
} from '@/graphql'
import { type User } from '@/models/User'
import { type PolicyResponse, type Resource, allow, deny, usePolicy, usePolicyResponse } from '../Policy'
import { type Policy as SPolicy, usePolicy as useSPolicy } from './Policy'

export function useCanCreateCompany(): SPolicy {
  return useSPolicy(({ user, allow, deny }) => {
    return user.allow('add', 'company') ? allow() : deny()
  })
}

export function useCanViewCompanyPortfolioData(portfolioData?: Resource<WithPermissionFrag>): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(portfolioData, (u, pd) => u.allow('read', pd))

  return usePolicyResponse(() => {
    return canView.value ? allow() : deny()
  })
}

export function useCanUpdateCompanyPortfolioData(portfolioData?: Resource<WithPermissionFrag>): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(portfolioData, (u, pd) => u.allow('edit', pd))

  return usePolicyResponse(() => {
    return canView.value ? allow() : deny()
  })
}

export function useCanViewCompanySettings(
  company: Resource<CompanyForDetailsFrag | CompanyForSettingsFrag>
): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canUpdate = defineWhen(company, checkUpdate)
  const isNotPortfolio = defineWhen(company, checkNotPortfolio)
  const isApproachable = defineWhen(company, checkApproachable)
  const hasNoOpenOpportunities = defineWhen(company, checkHasNoOpenOpportunities)

  return usePolicyResponse(() => {
    return canUpdate.value && isNotPortfolio.value && isApproachable.value && hasNoOpenOpportunities.value ? allow() : deny()
  })
}

export function checkUpdate(user: User, company: WithPermissionFrag): boolean {
  return user.allow('edit', company)
}

export function checkNotPortfolio(_user: User, company: CompanyWithAllPortfolioOfsFrag): boolean {
  return company.portfolioOf.length === 0 && company.exPortfolioOf.length === 0
}

export function checkApproachable(_user: User, company: CompanyWithApproachablesFrag): boolean {
  return company.approachableStatus === ApproachableStatus.Approachable
}

export function checkHasNoOpenOpportunities(_user: User, company: CompanyForDetailsFrag | CompanyForSettingsFrag): boolean {
  return company.opportunities.every((o) => o.status !== OpportunityStatus.Open)
}

export function useCanAddActionNote(
  company: MaybeRefOrGetter<WithPermissionFrag>
): SPolicy {
  return useSPolicy(({ user, pending, allow, deny }) => {
    const c = toValue(company)
    return c
      ? user.allow('edit', c) ? allow() : deny()
      : pending()
  })
}

export function useCanUpdateActionNote(
  company: MaybeRefOrGetter<WithPermissionFrag>
): SPolicy {
  return useSPolicy(({ user, pending, allow, deny }) => {
    const c = toValue(company)
    return c
      ? user.allow('edit', c) ? allow() : deny()
      : pending()
  })
}

export function useCanDeleteActionNote(
  company: MaybeRefOrGetter<WithPermissionFrag>
): SPolicy {
  return useSPolicy(({ user, pending, allow, deny }) => {
    const c = toValue(company)
    return c
      ? user.allow('edit', c) ? allow() : deny()
      : pending()
  })
}

export function useCanUpdateLegalAssignees(
  company: MaybeRefOrGetter<WithPermissionFrag>
): SPolicy {
  return useSPolicy(({ user, pending, allow, deny }) => {
    const c = toValue(company)
    return c
      ? user.allow('edit', 'companyLegalAssignee', c) ? allow() : deny()
      : pending()
  })
}
