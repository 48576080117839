import {
  type Get,
  type Mutation as SMutation,
  type Query as SQuery,
  type UseQueryOptions as UseSQueryOptions,
  useGet,
  useMutation as useSMutation,
  useQuery as useSQuery
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type CompanyCautionNote,
  type Company as CompanyGraph,
  type CompanyParticipation,
  type CompanySupportLogWithMembers
} from '@/graph/Company'
import {
  type BasicPaginationInput,
  type CloseCompanyInput,
  type CompanyAndActionNotesAndPermissionFrag,
  type CompanyAndNameFrag,
  type CompanyCondition,
  type CompanyForDetailsFrag,
  type CompanyForSettingsFrag,
  type CompanyForWrapupFrag,
  type CompanyOrder,
  CompanyOrderField,
  type CompanyPageFrag,
  type CompanyWithIdFrag,
  type CreateCompanyInput
} from '@/graphql'
import { Company } from '@/models/Company'
import { CompanyRequest } from '@/requests/CompanyRequest'
import {
  type Mutation,
  type Page,
  type Query,
  type UseQueryOptions,
  createPage,
  useMutation,
  useQuery
} from '../Api'

export type { CompanyCondition, CompanyOrder }
export { CompanyOrderField }

export type CompanyPage = SQuery<CompanyPageFrag>
export type CompanyForSearchPage = Query<Page<Company>, []>
export type CompanyForDetailsItem = SQuery<CompanyForDetailsFrag | null>
export type CompanyForSettingsItem = SQuery<CompanyForSettingsFrag | null>
export type CompanyAndNameItem = SQuery<CompanyAndNameFrag | null>
export type CompanyAndActionNotesAndPermissionItem = SQuery<CompanyAndActionNotesAndPermissionFrag | null>
export type CompanyForWrapup = Query<CompanyForWrapupFrag, []>

export type LatestComapnyParticipationList = SQuery<CompanyParticipation[]>

export type CloseCompany = Mutation<CompanyWithIdFrag, [id: number, input: CloseCompanyInput]>

export type CompanyCautionNoteItem = SQuery<CompanyCautionNote | null>
export type GetCompanyParticipantChangeNote = Get<string | null>

export type CompanySupportLogList = SQuery<CompanySupportLogWithMembers[]>

export type CreateCompany = SMutation<CompanyWithIdFrag, [input: CreateCompanyInput]>
export type CheckDuplicateCompanies = SMutation<CompanyGraph[], [input: CheckDuplicateCompaniesInput]>
export type UpdateCompanyParticipantChangeNote = SMutation<void, [id: number, note: string | null]>

export type UpdateLatestCompanyLegalAssignees = SMutation<void, [id: number, input: UpdateLatestCompanyLegalAssigneesInput]>

export interface CheckDuplicateCompaniesInput {
  displayName: string | null
  officialNameLocal: string | null
  officialNameEn: string | null
  website: string | null
}

export interface UpdateLatestCompanyLegalAssigneesInput {
  legalAssigneeIds: number[]
}

export interface UseCompanyPageOptions {
  page: BasicPaginationInput
  condition: CompanyCondition
  orderBy: CompanyOrder
}

export function useCompanyPage(
  options: MaybeRefOrGetter<UseCompanyPageOptions>
): CompanyPage {
  return useSQuery(async () => {
    const o = toValue(options)
    return (await new CompanyRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )).data.companies
  }, {
    watch: () => toValue(options)
  })
}

export function useCompanyForSearchPage(
  options: UseCompanyPageOptions
): CompanyForSearchPage {
  const query = useQuery(async () => {
    const res = await new CompanyRequest().fetchPageForSearch(
      options.page,
      options.condition,
      options.orderBy
    )

    return createPage(
      res.data.companies.pageInfo,
      res.data.companies.items.map((c) => new Company(c))
    )
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useCompanyForDetailsItem(
  id: MaybeRefOrGetter<number>
): CompanyForDetailsItem {
  return useSQuery(async () => {
    try {
      return (await new CompanyRequest().fetchForDetails(toValue(id))).data.company
    } catch (e) {
      return null
    }
  })
}

export function useCompanyForSettingsItem(
  id: MaybeRefOrGetter<number>
): CompanyForSettingsItem {
  return useSQuery(async () => {
    return (await new CompanyRequest().fetchForSettings(toValue(id))).data.company
  })
}

export function useCompanyAndNameItem(
  id: MaybeRefOrGetter<number>,
  options?: UseSQueryOptions
): CompanyAndNameItem {
  return useSQuery(async () => {
    return (await new CompanyRequest().fetchAndName(toValue(id))).data.company
  }, options)
}

export function useCompanyAndActionNotesAndPermissionItem(
  id: MaybeRefOrGetter<number>,
  options?: UseSQueryOptions
): CompanyAndActionNotesAndPermissionItem {
  return useSQuery(async () => {
    return (await new CompanyRequest().fetchAndActionNotesAndPermission(toValue(id))).data.company
  }, options)
}

export function useCompanyForWrapup(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): CompanyForWrapup {
  return useQuery<CompanyForWrapupFrag, []>(async () => {
    return (await new CompanyRequest().forWrapup(toValue(id))).data.company
  }, options)
}

export function useCompanyItemForCompanyBusinessReportCopy(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    return (await new CompanyRequest().fetchForCompanyBusinessReportCopy(id)).data.company
  }, options)
}

export function useLatestCompanyParticipationList(id: MaybeRefOrGetter<number>): LatestComapnyParticipationList {
  return useSQuery(async (http) => {
    return http.get(`/api/companies/${toValue(id)}/latest-participations`)
  })
}

export function useCloseCompany(): CloseCompany {
  return useMutation(async (id: number, input: CloseCompanyInput) => {
    return ((await new CompanyRequest().closeCompany(id, input)).data.company)
  })
}

export function useCompanyCautionNoteItem(id: MaybeRefOrGetter<number>): CompanyCautionNoteItem {
  return useSQuery(async (http) => {
    const res = await http.get<CompanyCautionNote | string>(`/api/companies/${toValue(id)}/caution-note`)
    return res === '' ? null : res as CompanyCautionNote
  })
}

export function useCompanySupportLogList(
  id: MaybeRefOrGetter<number>
): CompanySupportLogList {
  return useSQuery(async (http) => {
    return http.get<CompanySupportLogWithMembers[]>(`/api/companies/${toValue(id)}/support-logs`)
  })
}

export function useGetCompanyParticipantChangeNote(
  id: MaybeRefOrGetter<number>
): GetCompanyParticipantChangeNote {
  return useGet(async (http) => {
    return http.get<string>(`/api/companies/${toValue(id)}/participant-change-note`)
  })
}

export function useCreateCompany(): CreateCompany {
  return useSMutation(async (_, input) => {
    const res = await new CompanyRequest().create(input)
    return res.data.company as any
  })
}

export function useCheckDuplicateCompanies(): CheckDuplicateCompanies {
  return useSMutation(async (http, input) => {
    return http.post<CompanyGraph[]>('/api/companies/check-duplicate', input)
  })
}

export function useUpdateCompanyParticipantChangeNote(): UpdateCompanyParticipantChangeNote {
  return useSMutation(async (http, id, participantChangeNote) => {
    return http.put(`/api/companies/${id}/participant-change-note`, { participantChangeNote })
  })
}

export function useUpdateLatestCompanyLegalAssignees(): UpdateLatestCompanyLegalAssignees {
  return useSMutation(async (http, id, input) => {
    return http.put(`/api/companies/${id}/latest-legal-assignees`, input)
  })
}
