import {
  type Mutation,
  type Query as SQuery,
  type UseQueryOptions,
  useMutation,
  useQuery as useSQuery
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type ExtractedOpportunity,
  type OpportunityFile,
  type OpportunityFileDownload,
  type OpportunityFileRequest,
  type Opportunity as OpportunityGraph
} from '@/graph/Opportunity'
import {
  type AddOpportunityProposalsInput,
  type AddOpportunitySourceInput,
  type CreateOpportunityInput,
  type CreateOpportunityRoundInput,
  type OpportunityForDetailsFrag,
  type OpportunityPageForStatusFrag,
  type OpportunityPageFrag,
  type OpportunityWithIdFrag,
  type RoundWithIdFrag,
  type UpdateOpportunitySourceInput,
  type UpdateProposalInput,
  type UpdateProposalMutation
} from '@/graphql'
import { Opportunity } from '@/models/Opportunity'
import {
  type OpportunityCondition,
  type OpportunityOrder,
  OpportunityOrderField,
  OpportunityRequest
} from '@/requests/OpportunityRequest'
import { ProposalRequest } from '@/requests/ProposalRequest'
import { type BasicPaginationInput } from '@/requests/Request'
import { type Page, type Query, createPage, useQuery } from '../Api'

export type { OpportunityCondition, OpportunityOrder }
export { OpportunityOrderField }

export type OpportunityPage = SQuery<OpportunityPageFrag>
export type OpportunityPageForStatus = SQuery<OpportunityPageForStatusFrag>
export type OpportunityForSearchPage = Query<Page<Opportunity>, []>
export type OpportunityItem = Query<OpportunityForDetailsFrag>

export type CreateOpportunity = Mutation<OpportunityWithIdFrag, [companyId: number, input: CreateOpportunityInput]>

export type AddOpportunitySource = Mutation<OpportunityWithIdFrag, [id: number, input: AddOpportunitySourceInput]>
export type UpdateOpportunitySource = Mutation<OpportunityWithIdFrag, [sourceId: number, input: UpdateOpportunitySourceInput]>
export type DeleteOpportunitySource = Mutation<void, [sourceId: number]>

export type AddOpportunityRound = Mutation<RoundWithIdFrag, [id: number, input: CreateOpportunityRoundInput]>
export type UpdateOpportunityRound = Mutation<RoundWithIdFrag, [roundId: number, input: CreateOpportunityRoundInput]>
export type DeleteOpportunityRound = Mutation<void, [roundId: number]>

export type DisableOpportunityProposals = Mutation<OpportunityWithIdFrag, [id: number]>

export type OpportunityAddProposals = Mutation<void, [id: number, input: AddOpportunityProposalsInput]>
export type OpportunityUpdateProposal = Mutation<UpdateProposalMutation, [id: number, input: () => UpdateProposalInput]>
export type OpportunityDeleteAllProposals = Mutation<void, [id: number]>

export type OpportunityFileList = SQuery<OpportunityFile[]>

export type UpdateOpportunityFileFields = Mutation<OpportunityGraph, [id: number, input: UpdateOpportunityFileFieldsInput]>

export type OpportunityFileRequestList = SQuery<OpportunityFileRequest[]>
export type CreateOpportunityFileRequest = Mutation<OpportunityFileRequest, [id: number, input: CreateOpportunityFileRequestInput]>

export type OpportunityFileDownloadList = SQuery<OpportunityFileDownload[]>

export type ExtractActionNoteToOpportunity = Mutation<ExtractedOpportunity, [actionNoteId: number]>

export interface UseOpportunityPageOptions {
  page: BasicPaginationInput
  condition: OpportunityCondition
  orderBy: OpportunityOrder
}

export interface UpdateOpportunityFileFieldsInput {
  hasNoPlanToAddFiles: boolean
  fileComment: string | null
}

export interface CreateOpportunityFileRequestInput {
  comment: string | null
}

export function useOpportunityPage(
  options: MaybeRefOrGetter<UseOpportunityPageOptions>
): OpportunityPage {
  return useSQuery(async () => {
    const o = toValue(options)

    const res = await new OpportunityRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )

    return res.data.opportunities
  }, {
    watch: () => toValue(options)
  })
}

export function useOpportunityPageForStatus(
  options: MaybeRefOrGetter<UseOpportunityPageOptions>
): OpportunityPageForStatus {
  return useSQuery(async () => {
    const o = toValue(options)

    const res = await new OpportunityRequest().fetchPageForStatus(
      o.page,
      o.condition,
      o.orderBy
    )

    return res.data.opportunities
  }, {
    watch: () => toValue(options)
  })
}

export function useOpportunityForSearchPage(
  options: UseOpportunityPageOptions
): OpportunityForSearchPage {
  const query = useQuery(async () => {
    const res = await new OpportunityRequest().fetchPageForSearch(
      options.page,
      options.condition,
      options.orderBy
    )

    return createPage(
      res.data.opportunities.pageInfo,
      res.data.opportunities.items.map((o) => new Opportunity(o))
    )
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useOpportunityForDetails(id: number): OpportunityItem {
  return useQuery(async () => {
    return (await (new OpportunityRequest().fetchForDetails(id))).data.opportunity
  })
}

export function useCreateOpportunity(): CreateOpportunity {
  return useMutation(async (_, companyId, input) => {
    const res = await new OpportunityRequest().create(companyId, input)
    return res.data.opportunity
  })
}

export function useAddOpportunitySource(): AddOpportunitySource {
  return useMutation(async (_, id, input) => {
    const res = await new OpportunityRequest().addSource(id, input)
    return res.data.opportunity
  })
}

export function useUpdateOpportunitySource(): UpdateOpportunitySource {
  return useMutation(async (_, sourceId, input) => {
    const res = await new OpportunityRequest().updateSource(sourceId, input)
    return res.data.opportunity
  })
}

export function useDeleteOpportunitySource(): DeleteOpportunitySource {
  return useMutation(async (_, sourceId) => {
    await new OpportunityRequest().deleteSource(sourceId)
  })
}

export function useAddOpportunityRound(): AddOpportunityRound {
  return useMutation(async (_, id, input) => {
    const res = await new OpportunityRequest().addRound(id, input)
    return res.data.round
  })
}

export function useUpdateOpportunityRound(): UpdateOpportunityRound {
  return useMutation(async (_, roundId, input) => {
    const res = await new OpportunityRequest().updateRound(roundId, input)
    return res.data.round
  })
}

export function useDeleteOpportunityRound(): DeleteOpportunityRound {
  return useMutation(async (_, roundId) => {
    await new OpportunityRequest().deleteRound(roundId)
  })
}

export function useDisableOpportunityProposals(): DisableOpportunityProposals {
  return useMutation(async (_, id: number) => {
    const res = await new OpportunityRequest().updateIsProposalsDisabled(id, true)
    return res.data.opportunity
  })
}

export function useAddOpportunityProposals(): OpportunityAddProposals {
  return useMutation(async (_, id: number, input: AddOpportunityProposalsInput) => {
    await new OpportunityRequest().addProposals(id, input)
  })
}

export function useUpdateOpportunityProposal(): OpportunityUpdateProposal {
  return useMutation(async (_, id: number, input: () => UpdateProposalInput) => {
    return (await new ProposalRequest().update(id, input())).data
  })
}

export function useDeleteAllOpportunityProposals(): OpportunityDeleteAllProposals {
  return useMutation(async (_, id: number) => {
    await new OpportunityRequest().deleteAllProposals(id)
  })
}

export function useOpportunityFileList(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): OpportunityFileList {
  return useSQuery(async (http) => {
    return http.get<OpportunityFile[]>(`/api/opportunities/${toValue(id)}/files`)
  }, options)
}

export function useUpdateOpportunityFileFields(): UpdateOpportunityFileFields {
  return useMutation(async (http, id, input) => {
    return http.put<OpportunityGraph>(`/api/opportunities/${id}/file-fields`, input)
  })
}

export function useOpportunityFileRequestList(id: MaybeRefOrGetter<number>, options?: UseQueryOptions): OpportunityFileRequestList {
  return useSQuery(async (http) => {
    return http.get<OpportunityFileRequest[]>(`/api/opportunities/${toValue(id)}/file-requests`)
  }, options)
}

export function useCreateOpportunityFileRequest(): CreateOpportunityFileRequest {
  return useMutation(async (http, id, input) => {
    return http.post<OpportunityFileRequest>(`/api/opportunities/${id}/file-requests`, {
      comment: input.comment
    })
  })
}

export function useOpportunityFileDownloadList(id: MaybeRefOrGetter<number>, options?: UseQueryOptions): OpportunityFileDownloadList {
  return useSQuery(async (http) => {
    return http.get<OpportunityFileDownload[]>(`/api/opportunities/${toValue(id)}/file-downloads`)
  }, options)
}

export function useExtractActionNoteToOpportunity(): ExtractActionNoteToOpportunity {
  return useMutation(async (http, actionNoteId: number) => {
    return http.post<ExtractedOpportunity>('/api/opportunities/extract', {
      actionNoteId
    })
  })
}
