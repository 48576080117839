<template>
  <div class="DFormSectionTitle">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormSectionTitle {
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
}
</style>
