<script setup lang="ts">
import Fuse from 'fuse.js'
import SInputBase from 'sefirot/components/SInputBase.vue'
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed, ref } from 'vue'
import { type FundWithGroup } from '@/graph/Fund'
import { type OpportunityForDetailsFrag } from '@/graphql'

const props = defineProps<{
  isPortfolio: boolean
  isIrmCompleted: boolean
  opportunity: OpportunityForDetailsFrag
  funds: FundWithGroup[]
}>()

const model = defineModel<number | null>({ required: true })

const { t } = useTrans({
  en: {
    name: 'Name',
    irm_not_completed: 'Initial Review Meeting not completed.',
    proposal_not_open: 'Proposal not open.'
  },
  ja: {
    name: 'ファンド名',
    irm_not_completed: 'Initial Review Meetingが完了していません。',
    proposal_not_open: 'Proposalが作成されていません。'
  }
})

const search = ref(null)

const filteredFunds = computed(() => {
  if (!search.value) {
    return props.funds
  }

  const fuse = new Fuse(props.funds, {
    keys: ['nameAbbreviated', 'group.lp']
  })

  return fuse.search(search.value).map((result) => result.item)
})

const { validation } = useValidation(() => ({
  model: model.value
}), {
  model: {
    required: required()
  }
})

const table = useTable({
  records: filteredFunds,
  indexField: 'id',
  borderless: true,
  orders: [
    'name',
    'lp',
    'note'
  ],
  columns: {
    name: {
      label: t.name,
      cell: (_, fund) => ({
        type: 'text',
        value: fund.nameAbbreviated,
        color: canSelect(fund) ? 'default' : 'mute'
      })
    },
    lp: {
      label: 'LP',
      cell: (_, fund) => ({
        type: 'text',
        value: fund.group.lp,
        color: canSelect(fund) ? 'default' : 'mute'
      })
    },
    note: {
      cell: (_, fund) => {
        const [_ok, message] = canSelect(fund)
        return {
          type: 'text',
          value: message,
          color: 'mute'
        }
      }
    }
  },
  disableSelection(fund) {
    const [ok] = canSelect(fund)
    return !ok
  }
})

function canSelect(fund: FundWithGroup): [boolean, string | null] {
  const hasOpenProposal = props.opportunity.proposals.some((p) => {
    return (p.fundGroupId === fund.group.id) && (p.status === 'Open')
  })

  if (!hasOpenProposal) {
    return [false, t.proposal_not_open]
  }

  if (!fund.setting.requireInitialReviewMeeting) {
    return [true, null]
  }

  return props.isPortfolio || props.isIrmCompleted
    ? [true, null]
    : [false, t.irm_not_completed]
}
</script>

<template>
  <SInputBase :validation="validation.model">
    <SCard :mode="validation.$error ? 'danger' : undefined" class="s-overflow-hidden">
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <SControlInputSearch
              placeholder="Search funds"
              v-model="search"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable
          class="table"
          :options="table"
          v-model:selected="model"
        />
      </SCardBlock>
    </SCard>
  </SInputBase>
</template>

<style scoped lang="postcss">
.table {
  --table-max-height: 430px;
}

.table :deep(.col-name) { --table-col-width: 140px; }
.table :deep(.col-lp)   { --table-col-width: 160px; }
</style>
