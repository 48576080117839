import { type Company } from '@/models/Company'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { type CompanyVec } from '@/models/CompanyVec'
import { type Authorized, type Resource, usePolicy } from '../Policy'

export interface CompanyPolicy {
  canView: Authorized
  canCreate: Authorized
  canUpdate: Authorized
  canClaim: Authorized
  canUpdateParticipantChangeNote: Authorized
  canViewLegalAssignee: Authorized
  canUpdateLegalAssignee: Authorized
  canAddOpportunity: Authorized
}

export function useCompanyPolicy(company?: Resource<Company>): CompanyPolicy {
  const { define, defineWhen } = usePolicy()

  const canView = defineWhen(company, (u, c) => u.can('read', c))
  const canCreate = define((u) => u.can('add', 'company'))
  const canUpdate = defineWhen(company, (u, c) => u.can('edit', c))
  const canClaim = defineWhen(company, (u, c) => u.can('claim', c))
  const canUpdateParticipantChangeNote = defineWhen(company, (u, c) => u.can('edit', c))
  const canViewLegalAssignee = defineWhen(company, (u, c) => u.can('read', 'companyLegalAssignee', c))
  const canUpdateLegalAssignee = defineWhen(company, (u, c) => u.can('edit', 'companyLegalAssignee', c))

  const canAddOpportunity = defineWhen(company, (u, c) => u.can('add', 'opportunity', c))

  return {
    canView,
    canCreate,
    canUpdate,
    canClaim,
    canUpdateParticipantChangeNote,
    canViewLegalAssignee,
    canUpdateLegalAssignee,
    canAddOpportunity
  }
}

export interface CompanyPortfolioDataPolicy {
  canView: Authorized
  canUpdate: Authorized
  canUpdatePromoter: Authorized
}

export function useCompanyPortfolioDataPolicy(portfolioData?: Resource<CompanyPortfolioData>): CompanyPortfolioDataPolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(portfolioData, (u, pd) => u.can('read', pd))
  const canUpdate = defineWhen(portfolioData, (u, pd) => u.can('edit', pd))
  const canUpdatePromoter = defineWhen(portfolioData, (u, pd) => canUpdate.value && (u.hasRoleGod() || u.hasRoleGeneralPartner() || pd.promoters.length === 0))

  return {
    canView,
    canUpdate,
    canUpdatePromoter
  }
}

export interface CompanyVecPolicy {
  canView: Authorized
  canUpdate: Authorized
}

export function useCompanyVecPolicy(vec?: Resource<CompanyVec>): CompanyVecPolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(vec, (u, v) => u.can('read', v))
  const canUpdate = defineWhen(vec, (u, v) => u.can('edit', v))

  return {
    canView,
    canUpdate
  }
}

export interface CompanyFinancialFiguresPolicy {
  canView: Authorized
  canUpdate: Authorized
}
