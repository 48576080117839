<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type DealReportWithIdFrag } from '@/graphql'

defineProps<{
  report: DealReportWithIdFrag
  open: boolean
  loading: boolean
}>()

defineEmits<{
  (e: 'close'): void
  (e: 'cancel'): void
  (e: 'delete'): void
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    delete: 'Delete this report'
  },
  ja: {
    cancel: 'キャンセル',
    delete: '投資報告書を削除する'
  }
})
</script>

<template>
  <DModal size="small" :open="open" @close="$emit('close')">
    <DCard mode="danger" :header="false">
      <DCardClose @close="$emit('close')" />

      <DForm>
        <DFormSection>
          <STrans lang="en">
            <DFormTitle mode="danger">Delete deal report</DFormTitle>
            <DFormMedium>
              <p>You are about to delete the deal report. This action cannot be undone. Are you absolutely sure you want to do this?</p>
            </DFormMedium>
          </STrans>
          <STrans lang="ja">
            <DFormTitle mode="danger">投資報告書の削除</DFormTitle>
            <DFormMedium>
              <p>投資報告書を削除します。この操作は取り消すことができません。本当によろしいですか？</p>
            </DFormMedium>
          </STrans>
        </DFormSection>
      </DForm>

      <DCardFooter>
        <DCardFooterActions>
          <DCardFooterAction
            :label="t.cancel"
            @click="$emit('cancel')"
          />
          <DCardFooterAction
            :label="t.delete"
            mode="danger"
            :loading="loading"
            @click="$emit('delete')"
          />
        </DCardFooterActions>
      </DCardFooter>
    </DCard>
  </DModal>
</template>
