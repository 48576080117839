<script setup lang="ts">
import IconFileText from '~icons/ph/file-text'
import IconGear from '~icons/ph/gear'
import IconNotebook from '~icons/ph/notebook'
import IconTray from '~icons/ph/tray'
import IconDrive from '~icons/ri/drive-fill'
import { computedArrayWhen, computedWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { type DealProgressFrag, type DealWithStatusFrag } from '@/graphql'
import { useListener } from '@/composables/Channel'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import { useDealForLocalNav } from '@/composables/repos/DealRepo'

const route = useRoute()

const { data: deal, execute } = useDealForLocalNav(Number(route.params.id), {
  catch: [401, 404]
})

useListener('deal-status-updated', execute)

const { canView, canUpdate } = useDealPolicy(deal)

const dealOps = useDealOps()

const title = computedWhen(deal, (deal) => {
  return [
    { text: dealOps.companyName(deal), link: dealOps.companyPath(deal) },
    { text: dealOps.opportunityTitle(deal), link: dealOps.opportunityPath(deal) },
    dealOps.title(deal)
  ]
})

const actions = computedArrayWhen(deal, (items, d) => {
  if (d.round.company.driveId) {
    items.push({ icon: IconDrive, label: 'Google Drive', href: `https://drive.google.com/drive/folders/${d.round.company.driveId}` })
  }
})

const steps = computedWhen(deal, (deal) => {
  const status = dealOps.statusText(deal)

  const items = deal.progress.map((item) => ({
    mode: getProgressState(deal, item)
  }))

  return { status, items }
})

const menu = computedArrayWhen(deal, (items, deal) => {
  items.push([
    { icon: IconNotebook, label: 'Details', link: dealOps.path(deal, 'details') },
    { icon: IconFileText, label: 'Reports', link: dealOps.path(deal, 'reports') }
  ])

  if (canUpdate.value) {
    items.push([
      { icon: IconTray, label: 'Entries', link: dealOps.path(deal, 'entries') }
    ])
    items.push([
      { icon: IconGear, label: 'Settings', link: dealOps.path(deal, 'settings') }
    ])
  }
})

function getProgressState(deal: DealWithStatusFrag, progress: DealProgressFrag) {
  if (dealOps.isDropped(deal) && !progress.completedAt) {
    return 'danger'
  } else if (progress.completedAt) {
    return 'success'
  } else {
    return 'mute'
  }
}
</script>

<template>
  <DView
    class="DealsId"
    :loading="!deal"
    :hide="!canView"
    :title="title"
    :actions="actions"
    :steps="steps"
    :menu="menu"
  />
</template>
