<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { reactive } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useAddOpportunitySource } from '@/composables/repos/OpportunityRepo'
import OpportunitySourceFieldsetAsync from '../opportunity-source/OpportunitySourceFieldsetAsync.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  cancel: []
  added: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    add: 'Add sourcing information'
  },
  ja: {
    cancel: 'キャンセル',
    add: 'ソーシング情報を追加'
  }
})

const { data } = useData({
  date: null as Day | null,
  promoter: null as number | null,
  promotedSource: 0,
  inboundOrOutbound: 0,
  investmentRequest: 0,
  contactBackground: null as string | null
})

const { validateAndNotify } = useValidation()

const addOpportunitySourceMutation = reactive(useAddOpportunitySource())

async function add() {
  if (await validateAndNotify()) {
    await addOpportunitySourceMutation.execute(props.opportunity.id, {
      date: data.value.date!.format('YYYY-MM-DD'),
      promoterId: data.value.promoter,
      promotedSourceId: data.value.promotedSource,
      inboundOrOutboundId: data.value.inboundOrOutbound,
      investmentRequestId: data.value.investmentRequest!,
      contactBackground: data.value.contactBackground!
    })
    emit('added')
  }
}
</script>

<template>
  <Suspense>
    <SCard size="large">
      <SCardBlock class="s-p-32">
        <SDoc>
          <SContent>
            <h2>
              <STrans lang="en">Add a new sourcing information</STrans>
              <STrans lang="ja">ソーシング情報を追加する</STrans>
            </h2>
          </SContent>
          <OpportunitySourceFieldsetAsync v-model="data" />
        </SDoc>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-32">
        <SControl>
          <SControlRight>
            <SControlButton
              :label="t.cancel"
              :disabled="addOpportunitySourceMutation.loading"
              @click="$emit('cancel')"
            />
            <SControlButton
              mode="info"
              :label="t.add"
              :loading="addOpportunitySourceMutation.loading"
              @click="add"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </Suspense>
</template>
