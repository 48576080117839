import {
  type UpdateOpportunityBusinessInput,
  type UpdateOpportunityStatusInput
} from '@/graphql'
import { Opportunity } from '@/models/Opportunity'
import { OpportunityRequest } from '@/requests/OpportunityRequest'
import { type Mutation, type Query, useMutation, useQuery } from '../Api'

export type OpportunityItem = Query<Opportunity | null>
export type OpportunityUpdateStatus = Mutation<Opportunity>
export type OpportunityUpdateBusiness = Mutation<Opportunity>

export function useOpportunityItem(id: number): OpportunityItem {
  return useQuery(async () => {
    const res = await new OpportunityRequest().fetch(id)

    return res.data.opportunity ? new Opportunity(res.data.opportunity) : null
  })
}

export function useOpportunityItemForLocalNav(id: number): OpportunityItem {
  return useQuery(async () => {
    const res = await new OpportunityRequest().fetchForLocalNav(id)

    return res.data.opportunity ? new Opportunity(res.data.opportunity) : null
  })
}

export function useOpportunityUpdateStatus(
  id: number,
  input: () => UpdateOpportunityStatusInput
): OpportunityUpdateStatus {
  return useMutation(async () => {
    const res = await new OpportunityRequest().updateStatus(id, input())

    return new Opportunity(res.data.opportunity)
  })
}

export function useOpportunityUpdateBusiness(
  id: number,
  input: () => UpdateOpportunityBusinessInput
): OpportunityUpdateBusiness {
  return useMutation(async () => {
    const res = await new OpportunityRequest().updateBusiness(id, input())

    return new Opportunity(res.data.opportunity)
  })
}
