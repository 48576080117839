import { type Ymd, ymdFromYearMonth } from '@/support/Day'
import { ActionNote } from './ActionNote'
import { CompanyMarketingModel } from './CompanyMarketingModel'
import { CompanyName } from './CompanyName'
import { CompanyParticipation } from './CompanyParticipation'
import { CompanyPortfolioData } from './CompanyPortfolioData'
import { CompanySector } from './CompanySector'
import { Country } from './Country'
import { type Deal } from './Deal'
import { Fund } from './Fund'
import { type Day, Model } from './Model'
import { Opportunity } from './Opportunity'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export class Company extends Model implements AccessControlled {
  subject = AccessResource.company
  permittedActions: AccessControlled['permittedActions']

  id?: number
  driveId: string | null
  actionStatus: string
  names: CompanyName[]
  ipo?: boolean

  website?: string
  foundedYear?: number
  foundedMonth?: number
  fiscalMonth?: number
  numEmployees?: number
  country: Country | null
  zipcode?: string
  address?: string
  addressHouseNumber?: string

  activeCountries: Country[]
  marketingModel: CompanyMarketingModel | null
  primarySector: CompanySector | null
  subSectors: CompanySector[]
  description?: string

  representativeName?: string
  representativeTitle?: string
  representativeProfiles?: string
  keyPersonnelNotes?: string

  createdById?: number
  createdBy: User | null
  createdAt: Day | null
  updatedById?: number
  updatedBy: User | null
  updatedAt: Day | null

  primaryInCharge: User | null
  deputyInCharge: User | null
  participants: User[]
  participations: CompanyParticipation[]

  portfolioData: CompanyPortfolioData | null
  portfolioOf: Fund[]
  exPortfolioOf: Fund[]

  actionNotes: ActionNote[]
  opportunities: Opportunity[]

  constructor(data: Record<string, any>) {
    super()
    this.permittedActions = data.permittedActions ?? []

    this.id = data.id
    this.driveId = data.driveId ?? null
    this.actionStatus = data.actionStatus
    this.names = this.hasMany(CompanyName, data.names)
    this.ipo = data.ipo

    this.website = data.website
    this.foundedYear = data.foundedYear
    this.foundedMonth = data.foundedMonth
    this.fiscalMonth = data.fiscalMonth
    this.numEmployees = data.numEmployees
    this.country = this.hasOne(Country, data.country)
    this.zipcode = data.zipcode
    this.address = data.address
    this.addressHouseNumber = data.addressHouseNumber

    this.activeCountries = this.hasMany(Country, data.activeCountries)
    this.marketingModel = this.hasOne(CompanyMarketingModel, data.marketingModel)
    this.primarySector = this.hasOne(CompanySector, data.primarySector)
    this.subSectors = this.hasMany(CompanySector, data.subSectors)
    this.description = data.description

    this.representativeName = data.representativeName
    this.representativeTitle = data.representativeTitle
    this.representativeProfiles = data.representativeProfiles
    this.keyPersonnelNotes = data.keyPersonnelNotes

    this.createdById = data.createdById
    this.createdBy = this.hasOne(User, data.createdBy)
    this.createdAt = this.day(data.createdAt)
    this.updatedById = data.updatedById
    this.updatedBy = this.hasOne(User, data.updatedBy)
    this.updatedAt = this.day(data.updatedAt)

    this.primaryInCharge = this.hasOne(User, data.primaryInCharge)
    this.deputyInCharge = this.hasOne(User, data.deputyInCharge)
    this.participants = this.hasMany(User, data.participants)
    this.participations = this.hasMany(CompanyParticipation, data.participations)

    this.portfolioData = this.hasOne(CompanyPortfolioData, data.portfolioData)
    this.portfolioOf = this.hasMany(Fund, data.portfolioOf)
    this.exPortfolioOf = this.hasMany(Fund, data.exPortfolioOf)

    this.actionNotes = this.hasMany(ActionNote, data.actionNotes)
    this.opportunities = this.hasMany(Opportunity, data.opportunities)
  }

  get path(): string {
    return `/companies/${this.id}`
  }

  get createOpportunityPath(): string {
    return `${this.path}/opportunities/create`
  }

  get name(): string {
    return this.names.find((name) => name.until === null)?.displayName ?? ''
  }

  get officialName(): string {
    return this.names.find((name) => name.until === null)?.officialNameLocal ?? ''
  }

  get englishName(): string {
    return this.names.find((name) => name.until === null)?.officialNameEn ?? ''
  }

  get foundedYearMonthAsYmd(): Ymd {
    const foundedYearMonth = (this.foundedYear && this.foundedMonth) ? `${this.foundedYear}-${this.foundedMonth}` : ''

    return foundedYearMonth
      ? ymdFromYearMonth(foundedYearMonth)
      : { year: null, month: null, date: null }
  }

  get location(): string {
    return `${this.address}, ${this.country?.name}`
  }

  foundedText(): string {
    const year = this.foundedYear ?? null
    const month = this.foundedMonth ? ` / ${this.foundedMonth}` : null

    return (year && month) ? `${year}${month}` : ''
  }

  isPortfolio(): boolean {
    return this.isActivePortfolio() || this.isExPortfolio()
  }

  isActivePortfolio(): boolean {
    return this.portfolioOf.length > 0
  }

  isExPortfolio(): boolean {
    return this.exPortfolioOf.length > 0
  }

  dealsThroughOppo(): Deal[] {
    return this.opportunities.reduce<Deal[]>((deals, oppo) => {
      if (oppo.round) {
        oppo.round.deals.forEach((deal) => {
          deals.push(deal)
        })
      }

      return deals
    }, [])
  }
}
