import { orderBy } from 'lodash-es'
import { type Get, type Query, useGet, useQuery } from 'sefirot/composables/Api'
import { type CurrencyFrag } from '@/graphql'
import { CurrencyRequest } from '@/requests/CurrencyRequest'

export type CurrencyList = Query<CurrencyFrag[]>
export type GetCurrencyList = Get<CurrencyFrag[]>

export function useCurrencyList(): CurrencyList {
  return useQuery(() => fetchCurrencyList())
}

export function useGetCurrencyList(): GetCurrencyList {
  return useGet(() => fetchCurrencyList())
}

async function fetchCurrencyList(): Promise<CurrencyFrag[]> {
  const res = await new CurrencyRequest().fetchAll()
  return orderBy(res.data.currencies, (c) => c.rank)
}
