import { watch } from 'vue'
import {
  type AddDealEventInput,
  type BasicPaginationInput,
  type CreateDealInput,
  type DealCondition,
  type DealOrder,
  type UpdateDealAssigneesInput,
  type UpdateDealExitPlanInput,
  type UpdateDealExpenseInput,
  type UpdateDealRightsInput
} from '@/graphql'
import { Deal } from '@/models/Deal'
import { type CreateInput, DealRequest } from '@/requests/DealRequest'
import {
  type Mutation,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '../Api'

export type Create = Mutation<Deal, [number, CreateInput]>

export interface UseDealCollectionOptions {
  page: BasicPaginationInput
  condition: DealCondition
  orderBy: DealOrder
}

export function useDealCollection(options: UseDealCollectionOptions) {
  const query = useQuery(async () => {
    const res = await new DealRequest().fetchPage(
      options.page,
      options.condition,
      options.orderBy
    )

    return {
      data: res.data.deals.items.map((d) => new Deal(d)),
      page: res.data.deals.pageInfo
    }
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useDealItem(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new DealRequest().fetch(id)

    return new Deal(res.data.deal!)
  }, options)
}

export function useDealItemForLocalNav(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new DealRequest().fetchForLocalNav(id)

    return new Deal(res.data.deal!)
  }, options)
}

export function useDealItemForSettings(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new DealRequest().fetchForSettings(id)

    return new Deal(res.data.deal!)
  }, options)
}

export function useCreate(): Create {
  return useMutation<Deal, [number, CreateDealInput]>(async (roundId, input) => {
    const res = await new DealRequest().create(roundId, input)

    return new Deal(res.data.deal)
  })
}

export function useDealUpdateRights(
  id: number,
  input: () => UpdateDealRightsInput
) {
  return useMutation(async () => {
    const res = await new DealRequest().updateRights(id, input())

    return new Deal(res.data.deal)
  })
}

export function useDealUpdateExpense(
  id: number,
  input: () => UpdateDealExpenseInput
) {
  return useMutation(async () => {
    const res = await new DealRequest().updateExpense(id, input())

    return new Deal(res.data.deal)
  })
}

export function useDealUpdateExitPlan(
  id: number,
  input: () => UpdateDealExitPlanInput
) {
  return useMutation(async () => {
    const res = await new DealRequest().updateExitPlan(id, input())

    return new Deal(res.data.deal)
  })
}

export function useDealUpdateAssignees(
  id: number,
  input: () => UpdateDealAssigneesInput
) {
  return useMutation(async () => {
    const res = await new DealRequest().updateAssignees(id, input())

    return new Deal(res.data.deal)
  })
}

export function useDealAddEvent(
  id: number,
  input: () => AddDealEventInput
) {
  return useMutation(async () => {
    await new DealRequest().addEvent(id, input())
  })
}
