import { FundReportType } from '@/graphql'
import { Fund } from './Fund'
import { type Day, Model } from './Model'
import { User } from './User'

export const FundReportTypes = {
  [FundReportType.CompanyBusinessReport]: '業務報告書'
} as const

export class FundReport extends Model {
  id?: number
  fund: Fund | null
  type: FundReportType.CompanyBusinessReport
  date: Day | null
  createdAt: Day | null
  createdBy: User | null
  updatedAt: Day | null
  updatedBy: User | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.fund = this.hasOne(Fund, data.fund)
    this.type = data.type
    this.date = this.day(data.date)
    this.createdAt = this.day(data.createdAt)
    this.createdBy = this.hasOne(User, data.createdBy)
    this.updatedAt = this.day(data.updatedAt)
    this.updatedBy = this.hasOne(User, data.updatedBy)
  }

  get name(): string {
    return `${this.date?.format('YYYYMMDD')}_${this.fund?.nameAbbreviated}`
  }

  get title(): string {
    return `${this.typeValue} ${this.name}`
  }

  get path(): string {
    return `/company-business-reports-by-fund-report-id/${this.id}`
  }

  get typeValue(): string {
    return FundReportTypes[this.type]
  }
}
