<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import ReportList from '@/components/reports/ReportList.vue'

const { t } = useTrans({
  en: {
    title: 'Reports'
  },
  ja: {
    title: '報告書'
  }
})
</script>

<template>
  <DPage class="ReportsIndex" :title="t.title">
    <div class="container">
      <ReportList />
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 32px 32px 128px;
}
</style>
