<script setup lang="ts">
import { useCanViewAny as useCanViewAnyUser } from '@/composables/policies/UserPolicy'
import LayoutMainHeaderNavMenuMany from './LayoutMainHeaderNavMenuMany.vue'
import LayoutMainHeaderNavMenuMore from './LayoutMainHeaderNavMenuMore.vue'
import LayoutMainHeaderNavMenuOne from './LayoutMainHeaderNavMenuOne.vue'

export type MenuItem = MenuOne | MenuMany

export interface MenuOne {
  label: string
  link: string
}

export interface MenuMany {
  label: string
  items: MenuOne[]
}

const canViewAnyUser = useCanViewAnyUser()

const menu: MenuItem[] = [
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Companies', link: '/companies' },
  { label: 'Oppos', link: '/opportunities' },
  { label: 'Deals', link: '/deals' },
  { label: 'Surveys', link: '/surveys' },
  { label: 'Views', link: '/views' },
  { label: 'Reports', link: '/reports' },
  { label: 'Funds', link: '/funds' }
]

const moreOnSmall: MenuOne[] = [
  { label: 'Views', link: '/views' },
  { label: 'Reports', link: '/reports' },
  { label: 'Funds', link: '/funds' }
]

if (canViewAnyUser.value.ok) {
  menu.push({ label: 'Members', link: '/users' })
  moreOnSmall.push({ label: 'Members', link: '/users' })
}
</script>

<template>
  <nav class="LayoutMainHeaderNavMenu">
    <ul class="list">
      <li v-for="item in menu" :key="item.label" class="item">
        <LayoutMainHeaderNavMenuOne
          v-if="(item as MenuOne).link !== undefined"
          :label="(item as MenuOne).label"
          :link="(item as MenuOne).link"
        />
        <LayoutMainHeaderNavMenuMany
          v-else
          :label="(item as MenuMany).label"
          :items="(item as MenuMany).items"
        />
      </li>
      <li class="item more small">
        <LayoutMainHeaderNavMenuMore :items="moreOnSmall" />
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="postcss">
.list {
  display: flex;
  gap: 24px;
}

.item:nth-child(6) { display: none; }
.item:nth-child(7) { display: none; }
.item:nth-child(8) { display: none; }
.item:nth-child(9) { display: none; }

.more.small { display: block; }

@media (min-width: 1280px) {
  .item:nth-child(6) { display: block; }
  .item:nth-child(7) { display: block; }
  .item:nth-child(8) { display: block; }
  .item:nth-child(9) { display: block; }

  .more.small { display: none; }
}
</style>
