<script setup lang="ts">
import { orderBy } from 'lodash-es'
import { computed } from 'vue'
import { type DealForReportsFrag } from '@/graphql'
import DealPageReportsListControl from './DealPageReportsListControl.vue'
import DealPageReportsListEmpty from './DealPageReportsListEmpty.vue'
import DealPageReportsListItem from './DealPageReportsListItem.vue'

const props = defineProps<{
  deal: DealForReportsFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const reports = computed(() => orderBy(props.deal.reports))
</script>

<template>
  <div class="DealPageReportsList">
    <div class="container">
      <DealPageReportsListControl :deal="deal" @updated="$emit('updated')" />

      <DealPageReportsListEmpty
        v-if="deal.reports.length === 0"
      />

      <div class="list">
        <DealPageReportsListItem
          v-for="report in reports"
          :key="report.id"
          :deal="deal"
          :report="report"
          @deleted="$emit('updated')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsList {
  padding: 32px 32px 128px;
  background-color: var(--c-bg-elv-2);
}

.container {
  display: grid;
  gap: 16px;
  margin: 0 auto;
  max-width: 832px;
}

.list {
  display: grid;
  gap: 16px;
}
</style>
