import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  type FundAndOperatorsFrag,
  FundStatus,
  type WithPermissionFrag
} from '@/graphql'
import {
  type Authorized,
  type PolicyResponse,
  type Resource,
  allow,
  deny,
  pending,
  usePolicy,
  usePolicyResponse
} from '../Policy'

export interface FundClosingCheckListPolicy {
  canCreate: Authorized
  canExport: Authorized
}

export function useFundClosingCheckListPolicy(
  fundClosingCheckList?: Resource<WithPermissionFrag>
): FundClosingCheckListPolicy {
  const { define, defineWhen } = usePolicy()

  const canCreate = define((user) => user.hasRoleGod() || user.hasRoleFundManager())

  const canExport = defineWhen(fundClosingCheckList, (user, fundClosingCheckList) => {
    return user.allow('export', fundClosingCheckList) || user.hasRoleGod()
  })

  return {
    canCreate,
    canExport
  }
}

export function useCanAddFundClosingChecklist(
  funds: MaybeRefOrGetter<FundAndOperatorsFrag[] | null | undefined>
): PolicyResponse {
  return usePolicyResponse((user) => {
    const fs = toValue(funds)

    if (!fs) {
      return pending()
    }

    const ok = fs.some((f) => {
      if (
        f.status !== FundStatus.Active
        && f.status !== FundStatus.ActiveOnlyFollowOn
      ) {
        return false
      }

      // If the user has God role, he should be able to see all funds
      // so we'll stop here and make everything pass.
      if (user.hasRoleGod()) {
        return true
      }

      // Else check if the user is assigned as an operator of at least a fund.
      return f.operators.some((o) => o.userID === user.id)
    })

    return ok ? allow() : deny()
  })
}
