<script setup lang="ts">
import { type State } from 'sefirot/components/SDescState.vue'
import { computed } from 'vue'
import { type OpportunityConflictManagementCommitteeFrag } from '@/graphql'
import { useOpportunityConflictManagementCommitteeOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanViewOpportunityConflictManagementCommitteeRequestLink } from '@/composables/policies/OpportunityPolicy'

const props = defineProps<{
  conflictManagementCommittee: OpportunityConflictManagementCommitteeFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const canViewRequestLink = useCanViewOpportunityConflictManagementCommitteeRequestLink(
  props.conflictManagementCommittee
)

const userOps = useUserOps()
const cmcOps = useOpportunityConflictManagementCommitteeOps()

const result = computed<State | null>(() => {
  return cmcOps.resultDescState(props.conflictManagementCommittee)
})

const avatar = computed(() => {
  return {
    name: userOps.name(props.conflictManagementCommittee.initiatedBy),
    avatar: userOps.avatarPath(props.conflictManagementCommittee.initiatedBy)
  }
})
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc cols="3" gap="24">
      <SDescItem span="1">
        <SDescLabel value="Result" />
        <SDescState :state="result" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Meeting format" />
        <SDescText :value="cmcOps.meetingFormatText(conflictManagementCommittee)" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Date" />
        <SDescDay :value="cmcOps.date(conflictManagementCommittee)" format="YYYY-MM-DD" />
      </SDescItem>
      <SDescItem span="3">
        <SDescLabel value="Comments" />
        <SDescText :value="conflictManagementCommittee.comment" />
      </SDescItem>
      <SDescItem :span="canViewRequestLink.ok ? 2 : 3">
        <SDescLabel value="Initiated by" />
        <SDescAvatar :avatar="avatar" />
      </SDescItem>
      <SDescItem v-if="canViewRequestLink.ok" span="1">
        <SDescLabel value="Request ID" />
        <SDescLink
          :href="cmcOps.requestUrl(conflictManagementCommittee)"
          :value="cmcOps.requestIdText(conflictManagementCommittee)"
        />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>
