<script setup lang="ts">
import { abbreviate } from '@/support/Num'
import { useDuration } from '@/composables/Duration'
import { useItem } from '@/composables/repos/StatInvestmentSummaryRepo'
import DashboardOverviewCard from './DashboardOverviewCard.vue'

const { from, to, hasNext, label, prev, next } = useDuration('year')

const { data: stats, loading } = useItem(from, to)

function isZero(value: string | number) {
  return value === '0' || value === 0
}
</script>

<template>
  <DashboardOverviewCard
    title="Annual investment stats"
    :has-next="hasNext"
    :current="label"
    @prev="prev"
    @next="next"
    :loading="loading"
  >
    <div class="grid">
      <div class="item">
        <p class="value" :class="{ zero: isZero(stats!.amount) }">¥{{ abbreviate(Number(stats?.amount)) }}</p>
        <p class="label">amount</p>
      </div>
      <div class="item">
        <p class="value" :class="{ zero: isZero(stats!.deals) }">{{ stats?.deals ?? 0 }}</p>
        <p class="label">deals</p>
      </div>
      <div class="item">
        <p class="value" :class="{ zero: isZero(stats!.companies) }">{{ stats?.companies ?? 0 }}</p>
        <p class="label">companies</p>
      </div>
    </div>
  </DashboardOverviewCard>
</template>

<style scoped lang="postcss">
.grid {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.item {
  text-align: center;
  padding: 32px 16px 28px;
}

.item + .item {
  border-left: 1px solid var(--c-divider-light);
}

.value {
  font-size: 32px;
}

.zero {
  color: var(--c-text-2);
}

.label {
  padding-top: 4px;
  font-size: 14px;
  font-weight: 600;
  color: var(--c-text-2);
}
</style>
