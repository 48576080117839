<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type CompanyVecAndPermissionFrag } from '@/graphql'
import { useCanUpdateCompanyVec } from '@/composables/policies/CompanyVecPolicy'
import CompanyVecFormUpdate from './CompanyVecFormUpdate.vue'

const props = defineProps<{
  companyVec: CompanyVecAndPermissionFrag
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const sector = computed(() => props.companyVec.sector?.name)
const japanRegion = computed(() => props.companyVec.japanRegion?.name)
const formattedIsIoT = computed(() => props.companyVec.isIoT ? 'Yes' : 'No')

const { state, on, off } = usePower()

const canUpdate = useCanUpdateCompanyVec(props.companyVec)
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            VEC data
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok"
              :icon="IconNotePencil"
              @click="on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="1" gap="24">
        <SDescItem span="1">
          <SDescLabel value="VEC sector" />
          <SDescText :value="sector" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Japan region" />
          <SDescText :value="japanRegion" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="IoT related" />
          <SDescText :value="formattedIsIoT" />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="state">
      <CompanyVecFormUpdate
        :company-vec="companyVec"
        @updated="off(() => emit('updated'))"
        @cancel="off(() => emit('cancel'))"
      />
    </SModal>
  </SCard>
</template>
