<script setup lang="ts">
import { type ValidationRuleWithParams } from '@vuelidate/core'
import SInputBase from 'sefirot/components/SInputBase.vue'
import STable from 'sefirot/components/STable.vue'
import { type D } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { type V } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { type ProposableFundGroupFrag } from '@/graphql'

const props = defineProps<{
  records: (ProposableFundGroupFrag & { id: number })[]
  data: D<{ fundGroupIds: number[] }>['data']['value']
  validation: V<{ fundGroupIds: number[] }, { fundGroupIds: { required: ValidationRuleWithParams<object, any> } }>['validation']['value']
  disableSelection: (pfg: ProposableFundGroupFrag) => boolean
}>()

const emit = defineEmits<{
  'update:data': [number[]]
  'selected': [pfgs: ProposableFundGroupFrag[]]
}>()

const { t } = useTrans({
  en: {
    c_fund_name: 'Fund',
    c_lp_name: 'LP'
  },
  ja: {
    c_fund_name: 'ファンド',
    c_lp_name: 'LP'
  }
})

const table = useTable({
  records: computed(() => props.records),
  indexField: 'id',
  borderless: true,
  orders: [
    'name',
    'lp'
  ],
  columns: {
    name: {
      label: t.c_fund_name,
      cell: (_, pfg) => ({
        type: 'text',
        value: pfg.fundGroup.name
      })
    },
    lp: {
      label: t.c_lp_name,
      cell: (_, pfg) => ({
        type: 'text',
        value: pfg.fundGroup.lp
      })
    }
  },
  disableSelection(pfg) {
    return props.disableSelection(pfg)
  }
})
</script>

<template>
  <SInputBase :validation="validation.fundGroupIds">
    <SCard
      class="s-overflow-hidden"
      :mode="validation.fundGroupIds.$error ? 'danger' : 'neutral'"
    >
      <STable
        class="table"
        :options="table"
        :selected="props.data.fundGroupIds"
        @update:selected="emit('update:data', $event)"
      />
    </SCard>
  </SInputBase>
</template>

<style scoped lang="postcss">
.table :deep(.col-name) { --table-col-width: 128px; }
.table :deep(.col-lp)   { --table-col-width: 168px; }
</style>
