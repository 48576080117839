import { BallotVoter } from './BallotVoter'
import { Model } from './Model'

export class BallotSetting extends Model {
  id?: number
  voters: BallotVoter[]

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.voters = this.hasMany(BallotVoter, data.voters)
  }
}
