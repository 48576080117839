import { Company } from './Company'
import { type Day, Model } from './Model'
import { User } from './User'

export class ActionNote extends Model {
  id?: number | null
  title?: string
  body?: string
  createdBy?: User | null
  createdAt: Day | null
  updatedBy?: User | null
  updatedAt: Day | null

  companyId?: number
  company?: Company | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.title = data.title
    this.body = data.body
    this.createdBy = this.hasOne(User, data.createdBy)
    this.createdAt = this.day(data.createdAt)
    this.updatedBy = this.hasOne(User, data.updatedBy)
    this.updatedAt = this.day(data.updatedAt)

    this.companyId = data.companyId
    this.company = this.hasOne(Company, data.company)
  }
}
