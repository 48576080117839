<script setup lang="ts">
import { orderBy, xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type FundPortfolioStats } from '@/graph/FundPortfolioStats'
import { useFundPortfolioStatsOps } from '@/composables/ops/FundPortfolioStatsOps'
import { useCountryListCache } from '@/composables/repos/CountryRepo'
import { useOpportunityPromotedSourceListCache } from '@/composables/repos/OportunityPromotedSourceRepo'

export type SortableField = keyof typeof sortableFields

const props = defineProps<{
  portfolioStats: FundPortfolioStats[]
}>()

const { t } = useTrans({
  en: {
    title: 'Portfolio performance',
    c_name: 'Name',
    c_invested_amount: 'Invested (JPY)',
    c_initial_invested_date: 'Init. invested date',
    c_initial_promoted_source: 'Init. promoted source',
    c_country: 'Country',
    f_sort_asc: 'Sort ascending (A...Z)',
    f_sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    title: 'ポートフォリオのパフォーマンス',
    c_name: '企業名',
    c_invested_amount: '投資総額 (JPY)',
    c_initial_invested_date: '初回投資日',
    c_initial_promoted_source: '初回投資ソーシング経路',
    c_country: '国',
    f_sort_asc: 'ソート: 昇順 (A...Z)',
    f_sort_desc: 'ソート: 降順 (Z...A)'
  }
})

const sortableFields = {
  name: (fps: FundPortfolioStats) => fps.displayName,
  investedAmount: (fps: FundPortfolioStats) => fps.investedAmount,
  initialInvestedDate: (fps: FundPortfolioStats) => fps.initialInvestedDate,
  initialPromotedSource: (fps: FundPortfolioStats) => fps.initialPromotedSource,
  country: (fps: FundPortfolioStats) => fps.country.name
}

const { data: options } = useData({
  condition: {
    promotedSources: [] as string[],
    countries: [] as number[]
  },
  orderBy: {
    field: 'initialInvestedDate' as SortableField,
    direction: 'desc' as 'asc' | 'desc'
  }
})

const { data: promotedSources } = useOpportunityPromotedSourceListCache()
const { data: countries } = useCountryListCache()

const statsOps = useFundPortfolioStatsOps()

const processedPortfolioStats = computed(() => {
  const builder = (stats: FundPortfolioStats[]) => {
    let processed = stats
    return {
      promotedSourceFilter() {
        if (options.value.condition.promotedSources.length === 0) { return this }
        processed = processed.filter((stat) => stat.initialPromotedSource && options.value.condition.promotedSources.includes(stat.initialPromotedSource))
        return this
      },
      countryFilter() {
        if (options.value.condition.countries.length === 0) { return this }
        processed = processed.filter((stat) => stat.country.id && options.value.condition.countries.includes(stat.country.id))
        return this
      },
      sort() {
        processed = orderBy(processed, [sortableFields[options.value.orderBy.field]], [options.value.orderBy.direction])
        return this
      },
      build() {
        return processed
      }
    }
  }

  return builder(props.portfolioStats)
    .promotedSourceFilter()
    .countryFilter()
    .sort()
    .build()
})

const table = useTable({
  records: processedPortfolioStats,
  borderless: true,
  orders: [
    'name',
    'investedAmount',
    'initialInvestedDate',
    'initialPromotedSource',
    'country',
    'empty'
  ],
  columns: {
    name: {
      label: t.c_name,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('name')
        }
      ]),
      cell: (_, fps) => ({
        type: 'text',
        value: fps.displayName,
        link: `/companies/${fps.companyId}`
      })
    },
    investedAmount: {
      label: t.c_invested_amount,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('investedAmount')
        }
      ]),
      cell: (_, fps) => ({
        type: 'text',
        value: statsOps.investedAmountAbbr(fps),
        align: 'right'
      })
    },
    initialInvestedDate: {
      label: t.c_initial_invested_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('initialInvestedDate')
        }
      ]),
      cell: (_, fps) => ({
        type: 'day',
        value: statsOps.initialInvestedDate(fps),
        format: 'YYYY-MM-DD'
      })
    },
    initialPromotedSource: {
      label: t.c_initial_promoted_source,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('initialPromotedSource')
        },
        {
          type: 'filter',
          search: false,
          selected: computed(() => options.value.condition.promotedSources),
          options: computed(() => promotedSources.value?.map((promotedSource) => {
            return { label: promotedSource.name!, value: promotedSource.id!, disabled: !promotedSource.enabled }
          }) ?? []),
          onClick(value) {
            options.value.condition.promotedSources = xor(options.value.condition.promotedSources, [value])
          }
        }
      ]),
      cell: (_, fps) => ({
        type: 'text',
        value: fps.initialPromotedSource
      })
    },
    country: {
      label: t.c_country,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('country')
        },
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.countries),
          options: computed(() => countries.value?.map((country) => {
            return { label: country.name, value: country.id! }
          }) ?? []),
          onClick(value) {
            options.value.condition.countries = xor(options.value.condition.countries, [value])
          }
        }
      ]),
      cell: (_, fps) => ({
        type: 'text',
        value: fps.country.name
      })
    },
    empty: {
      cell: { type: 'empty' }
    }
  }
})

function createSortOptions(field: SortableField) {
  return [
    { label: t.f_sort_asc, onClick: () => { updateOrder(field, 'asc') } },
    { label: t.f_sort_desc, onClick: () => { updateOrder(field, 'desc') } }
  ]
}

function updateOrder(field: SortableField, direction: 'asc' | 'desc') {
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}
</script>

<template>
  <SCard>
    <SCardBlock class="FundStatPortfolioList" size="large">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">{{ t.title }}</SControlText>
        </SControlLeft>
      </SControl>
    </SCardBlock>
    <SCardBlock class="list">
      <STable :options="table" />
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.FundStatPortfolioList {
  padding-left: 24px;
}

.FundStatPortfolioList,
.list {
  --c-bg-elv-3: var(--c-bg-1);
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
  --table-padding-left: 8px;
}

.list :deep(.col-name)                  { --table-col-width: 256px; }
.list :deep(.col-investedAmount)        { --table-col-width: 144px; font-feature-settings: "tnum"; }
.list :deep(.col-initialInvestedDate)   { --table-col-width: 144px; }
.list :deep(.col-initialPromotedSource) { --table-col-width: 192px; }
.list :deep(.col-country)               { --table-col-width: 144px; }
</style>
