import { type Day, type Hms, day } from 'sefirot/support/Day'
import { assert } from '@/support/Error'

export interface Ymd {
  year: number | null
  month: number | null
  date: number | null
}

export function dateText(d: Day, lang: 'en' | 'ja' = 'en'): string {
  return d.format((lang === 'ja') ? 'YYYY/M/D' : 'MMM D, YYYY')
}

export function dateTextOrNull(d?: Day | null, lang?: 'en' | 'ja'): string | null {
  return d ? dateText(d, lang) : null
}

export function timestampText(d: Day, lang: 'en' | 'ja' = 'en'): string {
  return d.format((lang === 'ja') ? 'YYYY/M/D HH:mm' : 'MMM D, YYYY, hh:mm a')
}

export function timestampTextOrNull(d?: Day | null, lang?: 'en' | 'ja'): string | null {
  return d ? timestampText(d, lang) : null
}

export function toDayOrNull(value?: string | null): Day | null {
  return value ? day(value) : null
}

export function toDateText(value: string, lang?: 'en' | 'ja'): string {
  return dateText(day(value), lang)
}

export function toDateTextOrNull(value?: string | null, lang?: 'en' | 'ja'): string | null {
  return value ? dateTextOrNull(day(value), lang) : null
}

export function toTimestampText(value: string, lang?: 'en' | 'ja'): string {
  return timestampText(day(value), lang)
}

export function toTimestampTextOrNull(value?: string | null, lang?: 'en' | 'ja'): string | null {
  return value ? timestampTextOrNull(day(value), lang) : null
}

export function dayFromYearMonth(value: string): Day {
  const [year, month] = splitYearMonth(value)

  return day(`${year}-${month}-01`)
}

export function yearMonthFromYmd({ year, month }: Ymd): string {
  assert(year !== null && month !== null, [
    `Year and month must not be null. Given \`Year: ${year}\` and`,
    `\`Month: ${month}\`.`
  ])

  return `${String(year).padStart(4, '0')}-${String(month).padStart(2, '0')}`
}

export function yearMonthFromYmdOrNull({ year, month }: Ymd): string | null {
  if (year === null || month === null) {
    return null
  }

  return `${String(year).padStart(4, '0')}-${String(month).padStart(2, '0')}`
}

export function ymdFromYearMonth(value?: string | null): Ymd {
  if (!value) {
    return {
      year: null,
      month: null,
      date: null
    }
  }

  const [year, month] = splitYearMonth(value)

  return {
    year: Number(year),
    month: Number(month),
    date: null
  }
}

function splitYearMonth(value: string): [string, string] {
  const [year, month] = value.split('-')

  assert(!!year && !!month, [`Invalid Year Month input: \`${value}\``])

  return [year, month]
}

export function hourMinuteSecondFromHmsOrNull({ hour, minute, second }: Hms): string | null {
  if (hour === null || minute === null || second === null) {
    return null
  }

  return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`
}
