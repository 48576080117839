<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type PolicyResponseRaw } from '@/composables/Policy'

defineProps<{
  canSave: PolicyResponseRaw<any>
  saving: boolean
}>()

defineEmits<{
  (e: 'save'): void
}>()

const { t } = useTrans({
  en: {
    save: 'Save report'
  },
  ja: {
    save: '保存する'
  }
})
</script>

<template>
  <DCardFooter v-if="!canSave.is('no-auth')">
    <DCardFooterActions>
      <DCardFooterAction
        mode="info"
        :label="t.save"
        :loading="saving"
        :disabled="!canSave.ok && !saving"
        :tooltip="{ text: canSave.message }"
        @click="$emit('save')"
      />
    </DCardFooterActions>
  </DCardFooter>
</template>
