<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type OpportunityFile } from '@/graph/Opportunity'
import { useDeleteOpportunityFile } from '@/composables/repos/OpportunityFileRepo'

const props = defineProps<{
  opportunityFile: OpportunityFile
}>()

const emit = defineEmits<{
  cancel: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    delete: 'Delete file'
  },
  ja: {
    cancel: 'キャンセル',
    delete: 'ファイルを削除'
  }
})

const mutation = useDeleteOpportunityFile()

async function destroy() {
  await mutation.execute(props.opportunityFile.id)
  emit('deleted')
}
</script>

<template>
  <SCard class="OpportunityFileFormDelete" size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Delete this file</h2>
          <p>You are about to delete “{{ opportunityFile.name }}”. This action cannot be undone.</p>
        </STrans>
        <STrans lang="ja">
          <h2>このファイルを削除する</h2>
          <p>「{{ opportunityFile.name }}」を削除します。この操作は取り消せません。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock class="s-px-24" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.delete"
            :loading="mutation.loading.value"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
