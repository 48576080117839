<template>
  <SCard size="medium" class="DashboardDdTeamUpcomingOpportunityCatalogAbout">
    <SCardBlock class="s-p-32">
      <SContent>
        <STrans lang="en">
          <h2>About</h2>
          <p>This table lists the fields related to the Conflict Management Commitee (CMC) for Opportunities where the status is "Open" or "In progress," in order to quickly capture Opportunities that fall under the scope of the CMC. The entries are arranged in descending order of the Opportunity creation date.</p>
        </STrans>
        <STrans lang="ja">
          <h2>ヘルプ</h2>
          <p>本テーブルは、利益相反委員会（CMC）に該当するOpportunityをいち早くキャッチするため、ステータスが「Open」、または「In progress」のOpportunityの、利益相反委員会に関係するフィールドを一覧しています。並び順は、Opportunity作成日時の降順です。</p>
        </STrans>
      </SContent>
    </SCardBlock>
  </SCard>
</template>
