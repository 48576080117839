import { type Ref, ref } from 'vue'

export interface State<T> {
  data: Ref<T | null>
  set(value: T | null): void
  clear(fn?: () => void, delay?: number): void
}

export function useState<T>(initialValue: T | null = null): State<T | null> {
  const data = ref(initialValue) as Ref<T | null>

  function set(value: T | null): void {
    data.value = value
  }

  function clear(fn?: () => void, delay?: number): void {
    typeof fn === 'function' && fn()

    delay
      ? setTimeout(() => data.value = null, delay)
      : (data.value = null)
  }

  return {
    data,
    set,
    clear
  }
}
