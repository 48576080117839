<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { day } from 'sefirot/support/Day'
import { reactive } from 'vue'
import { type OpportunitySourceFrag } from '@/graphql'
import { useUpdateOpportunitySource } from '@/composables/repos/OpportunityRepo'
import OpportunitySourceFieldsetAsync from '../opportunity-source/OpportunitySourceFieldsetAsync.vue'

const props = defineProps<{
  source: OpportunitySourceFrag
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    update: 'Update sourcing information'
  },
  ja: {
    cancel: 'キャンセル',
    update: 'ソーシング情報を更新'
  }
})

const updateOpportunitySourceMutation = reactive(useUpdateOpportunitySource())

const { data } = useData({
  date: day(props.source.date),
  promotedSource: props.source.promotedSourceId,
  promoter: props.source.promoterId,
  inboundOrOutbound: props.source.inboundOrOutboundId,
  investmentRequest: props.source.investmentRequestId,
  contactBackground: props.source.contactBackground
})

const { validateAndNotify } = useValidation()

async function update() {
  if (await validateAndNotify()) {
    await updateOpportunitySourceMutation.execute(props.source.id, {
      date: data.value.date!.format('YYYY-MM-DD'),
      promoterId: data.value.promoter,
      promotedSourceId: data.value.promotedSource,
      inboundOrOutboundId: data.value.inboundOrOutbound,
      investmentRequestId: data.value.investmentRequest,
      contactBackground: data.value.contactBackground!
    })
    emit('updated')
  }
}
</script>

<template>
  <Suspense>
    <SCard size="large">
      <SCardBlock class="s-p-32">
        <SDoc>
          <SContent>
            <h2>
              <STrans lang="en">Update this sourcing information</STrans>
              <STrans lang="ja">ソーシング情報を更新する</STrans>
            </h2>
          </SContent>
          <OpportunitySourceFieldsetAsync v-model="data" />
        </SDoc>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-32">
        <SControl>
          <SControlRight>
            <SControlButton
              :label="t.cancel"
              :disabled="updateOpportunitySourceMutation.loading"
              @click="$emit('cancel')"
            />
            <SControlButton
              mode="info"
              :label="t.update"
              :loading="updateOpportunitySourceMutation.loading"
              @click="update"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </Suspense>
</template>
