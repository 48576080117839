<script setup lang="ts">
import { computed } from 'vue'
import { type KadomonyNews } from '@/graph/Kadomony'
import { useKadomonyNewsOps } from '@/composables/ops/KadomonyOps'

const props = defineProps<{
  news: KadomonyNews
}>()

const newsOps = useKadomonyNewsOps()

const companyName = computed(() => {
  return newsOps.companyName(props.news)
})

const companyLink = computed(() => {
  return newsOps.companyUrl(props.news)
})
</script>

<template>
  <SCard class="CompanyNewsPageListItem">
    <SCardBlock>
      <div class="container">
        <div class="main">
          <div class="company">
            <SLink v-if="companyLink" class="link" :href="companyLink">
              {{ companyName }}
            </SLink>
            <span class="not-on-gb-base" v-else>
              {{ companyName }}
              <STrans lang="en">(Not on GB Base)</STrans>
              <STrans lang="ja">(GB Base未登録)</STrans>
            </span>
          </div>
          <div class="title">
            <SLink class="title" :href="news.url">
              {{ news.title }}
            </SLink>
          </div>
        </div>
        <div class="meta">
          <div class="meta-item">
            <div class="meta-value">
              {{ newsOps.publishedAt(news).format('YYYY-MM-DD HH:mm') }}
            </div>
          </div>
          <div class="meta-item">
            <div class="meta-value mute">•</div>
          </div>
          <div class="meta-item">
            <div class="meta-value">
              {{ newsOps.typeText(news) }}
            </div>
          </div>
          <div class="meta-item">
            <div class="meta-value mute">•</div>
          </div>
          <div class="meta-item">
            <div class="meta-value">
              {{ newsOps.sourceText(news) }}
            </div>
          </div>
        </div>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.company,
.title {
  line-height: 28px;
  font-size: 16px;
  max-width: 640px;
}

.company {
  font-size: 14px;
  color: var(--c-text-2);
}

.link {
  color: var(--c-text-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-1);
  }
}

.meta {
  display: flex;
  gap: 8px;
}

.meta-value {
  font-size: 14px;
  color: var(--c-text-2);
}

.meta-value.mute {
  color: var(--c-text-3);
}

.meta-value.warning {
  color: var(--c-text-warning-1);
}
</style>
