<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Service description *',
    info: 'The business, service, or product that startup provide.',
    ph: 'A platform that connects local farmers with consumers. Currently operating in 3 cities. Working tightly with government. etc.'
  },
  ja: {
    label: 'Service description *',
    info: '提供している事業・サービス・プロダクトなどの詳細。',
    ph: '地元の農家と消費者をつなぐプラットフォーム。現在3都市で運営中。政府と緊密に連携している。など。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required(),
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
