<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type FundStats } from '@/graph/FundStats'
import { useFundStatsOps } from '@/composables/ops/FundStatsOps'
import StatBlock from '../stat/StatBlock.vue'
import StatBlockDl from '../stat/StatBlockDl.vue'
import StatBlockHeader from '../stat/StatBlockHeader.vue'

const props = defineProps<{
  stats: FundStats
}>()

const { t } = useTrans({
  en: {
    title: 'Portfolio',
    total_label: 'Total',
    current_label: 'Current',
    exited_label: 'Exited'
  },
  ja: {
    title: 'ポートフォリオ数',
    total_label: '合計',
    current_label: '進行中',
    exited_label: 'Exit済み'
  }
})

const statsOps = useFundStatsOps()

const items = computed(() => {
  const s = props.stats
  return [
    {
      label: t.total_label,
      value: s.portfolioCount,
      link: statsOps.portfolioPageLink(s),
      mode: s.portfolioCount === 0 ? 'mute' : 'default'
    } as const,
    {
      label: t.current_label,
      value: s.portfolioActiveCount,
      link: statsOps.activePortfolioPageLink(s),
      mode: s.portfolioActiveCount === 0 ? 'mute' : 'default'
    } as const,
    {
      label: t.exited_label,
      value: s.portfolioExCount,
      link: statsOps.exPortfolioPageLink(s),
      mode: s.portfolioExCount === 0 ? 'mute' : 'default'
    } as const
  ]
})
</script>

<template>
  <StatBlock>
    <StatBlockHeader :title="t.title" />
    <StatBlockDl :items />
  </StatBlock>
</template>
