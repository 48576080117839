<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type Opportunity } from '@/graph/Opportunity'
import { useUpdateOpportunityFileFields } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  opportunityId: number
  hasNoPlanToAddFiles: boolean
}>()

const emit = defineEmits<{
  cancel: []
  updated: [oppo: Opportunity]
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_update: 'Delete comment'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_update: 'コメントを削除'
  }
})

const updateOpportunityFileFields = useUpdateOpportunityFileFields()

async function update() {
  const oppo = await updateOpportunityFileFields.execute(props.opportunityId, {
    hasNoPlanToAddFiles: props.hasNoPlanToAddFiles,
    fileComment: null
  })
  emit('updated', oppo)
}
</script>

<template>
  <SCard class="OpportunityFormRemoveFileComment" size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Delete comment for pitch deck</h2>
            <p>Deleting the comment. This action cannot be undone.</p>
          </STrans>
          <STrans lang="ja">
            <h2>ピッチデックに関するコメントを削除する</h2>
            <p>コメントを削除します。この操作は取り消すことができません。</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock class="s-px-24" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="updateOpportunityFileFields.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.a_update"
            :loading="updateOpportunityFileFields.loading.value"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
