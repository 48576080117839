<template>
  <div class="DCardFooter">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DCardFooter {
  border-top: 1px solid var(--c-gutter);
  border-radius: 0 0 6px 6px;
  padding: 16px 24px;
}
</style>
