import {
  DeleteDealEventDocument,
  UpdateDealEventDocument,
  type UpdateDealEventInput
} from '@/graphql'
import { Request } from './Request'

export type { UpdateDealEventInput }

export class DealEventRequest extends Request {
  update(id: number, input: UpdateDealEventInput) {
    return this.request({
      query: UpdateDealEventDocument,
      variables: { id, input }
    })
  }

  delete(id: number) {
    return this.request({
      query: DeleteDealEventDocument,
      variables: { id }
    })
  }
}
