<script setup lang="ts">
import { computed } from 'vue'
import { type DealForReportsFrag } from '@/graphql'
import DealPageReportsList from './DealPageReportsList.vue'
import DealPageReportsMigrated from './DealPageReportsMigrated.vue'
import DealPageReportsSummary from './DealPageReportsSummary.vue'

const props = defineProps<{
  deal: DealForReportsFrag
}>()

defineEmits<{
  (e: 'updated'): void
}>()

// Check if the deal has been migrated from the old Salesforce system. If it
// has been migrated, we will present a special screen to prevent the user
// from accessing the report.
//
// The reason for hiding the report is that the deal's invested date is stored
// in the `DealReport.investedDate` field. However, migrated data may lack
// other essential fields such as company information or reported date,
// rendering the report unusable for action (e.g., exporting to Word will
// result in failure).
const isMigratedDeal = computed(() => {
  return props.deal.reports.some((report) => {
    return report.name === '__DEUS_MIGRATED__'
  })
})
</script>

<template>
  <div class="DealPageReports">
    <div v-if="!isMigratedDeal" class="grid">
      <DealPageReportsList :deal="deal" @updated="$emit('updated')" />
      <DealPageReportsSummary :deal="deal" />
    </div>
    <div v-else>
      <DealPageReportsMigrated />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReports {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 384px;
  gap: 4px;
  height: 100%;
  background-color: var(--c-bg-elv-1);
}
</style>
