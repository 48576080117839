<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useData } from 'sefirot/composables/Data'
import { useLang } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { rule } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type DealWithAssigneesFrag, type DealWithIdFrag } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'
import { useDealPartnerList, useUpdateDealAssignees } from '@/composables/repos/DealRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithAssigneesFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const lang = useLang()

const isEn = lang === 'en'

const { data: partners } = useDealPartnerList()
const { data: users } = useActiveUserList()
const { loading, execute: doUpdate } = useUpdateDealAssignees()

const userOps = useUserOps()

const { data } = useData({
  partnerInChargeId: props.deal.partnerInCharge?.id ?? null,
  primaryInChargeId: props.deal.primaryInCharge.id,
  deputyInChargeId: props.deal.deputyInCharge?.id ?? null,
  participantIds: props.deal.participants.map((u) => u.id)
})

const { validation, validateAndNotify } = useValidation(data, {
  deputyInChargeId: {
    notSameAs: rule(
      (value) => value !== data.value.primaryInChargeId,
      isEn ? 'Already assigned as Primary in-charge.' : 'すでにPrimary in-chargeとして設定されています。'
    )
  },
  participantIds: {
    notSameAsPic: rule(
      (value) => !((value as unknown[]).includes(data.value.primaryInChargeId)),
      isEn ? 'Some member is already assigned as Primary in-charge.' : '選択されたユーザーがすでにPrimary in-chargeとして設定されています。'
    ),
    notSameAsDic: rule(
      (value) => data.value.deputyInChargeId ? !((value as unknown[]).includes(data.value.deputyInChargeId)) : true,
      isEn ? 'Some member is already assigned as Deputy in-charge.' : '選択されたユーザーがすでにDeputy in-chargeとして設定されています。'
    )
  }
})

const partnerOptions = computed<Option[]>(() => {
  return partners.value?.map((partner) => {
    return { type: 'avatar', label: userOps.name(partner), value: partner.id }
  }) ?? []
})

const userOptions = computed<Option[]>(() => {
  return users.value?.map((user) => {
    return { type: 'avatar', label: userOps.name(user), value: user.id }
  }) ?? []
})

async function update() {
  if (await validateAndNotify()) {
    await doUpdate(props.deal.id, {
      partnerInChargeId: data.value.partnerInChargeId,
      primaryInChargeId: data.value.primaryInChargeId,
      deputyInChargeId: data.value.deputyInChargeId,
      participantIds: data.value.participantIds
    })

    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update assignees</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="2">
        <SInputDropdown
          label="Partner in-charge"
          placeholder="Select a partner"
          :options="partnerOptions"
          v-model="data.partnerInChargeId"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <SInputDropdown
          label="Primary in-charge"
          :options="userOptions"
          v-model="data.primaryInChargeId"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <SInputDropdown
          label="Deputy in-charge"
          placeholder="Select a member"
          nullable
          :options="userOptions"
          v-model="data.deputyInChargeId"
          :validation="validation.deputyInChargeId"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputDropdown
          label="Participants"
          placeholder="Select members"
          :options="userOptions"
          nullable
          v-model="data.participantIds"
          :validation="validation.participantIds"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
