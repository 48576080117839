<script setup lang="ts">
import { computed } from 'vue'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const updatedBy = computed(() => props.portfolioData.updatedBy)
const updatedAt = computed(() => props.portfolioData.updatedAt)
</script>

<template>
  <DCard title="System information">
    <DCardGrid>
      <DCardGridItem span="2">
        <DCardGridLabel text="Updated by" />
        <DCardGridRecord :by="updatedBy" :date="updatedAt" />
      </DCardGridItem>
    </DCardGrid>
  </DCard>
</template>
