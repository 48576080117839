<template>
  <div class="DFormFooter">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormFooter {
  margin: 48px -48px -48px;
  border-top: 1px solid var(--c-divider-2);
  border-radius: 0 0 6px 6px;
  padding: 24px;
  background-color: var(--c-soft);

  .SModal .DForm.small & {
    margin: 24px -24px -24px;
    padding: 16px 24px;
  }

  .small & {
    margin: 24px -24px -24px;
    padding: 16px 24px;
  }
}
</style>
