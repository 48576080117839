import { type CompanyVecWithPermissionFrag } from '@/graphql'
import { type PolicyResponse, type Resource, allow, deny, usePolicy, usePolicyResponse } from '../Policy'

export function useCanViewCompanyVec(companyVec?: Resource<CompanyVecWithPermissionFrag>): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(companyVec, (u, vec) => u.allow('read', vec))

  return usePolicyResponse(() => {
    return canView.value ? allow() : deny()
  })
}

export function useCanUpdateCompanyVec(companyVec?: Resource<CompanyVecWithPermissionFrag>): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canUpdate = defineWhen(companyVec, (u, vec) => u.allow('edit', vec))

  return usePolicyResponse(() => {
    return canUpdate.value ? allow() : deny()
  })
}
