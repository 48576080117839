<template>
  <div class="DCardListEmpty">
    <p class="text">
      <slot />
    </p>
  </div>
</template>

<style scoped lang="postcss">
.DCardListEmpty {
  padding: 20px 24px;
}

.text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
