<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import SInputAddon from 'sefirot/components/SInputAddon.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { ref } from 'vue'
import { type SurveyAndAllFrag } from '@/graphql'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useCanUpdateSurvey } from '@/composables/policies/SurveyPolicy'

const props = defineProps<{
  survey: SurveyAndAllFrag
}>()

const { t } = useTrans({
  en: { copy: 'Copy URL' },
  ja: { copy: 'URLをコピー' }
})

const canUpdateSurvey = useCanUpdateSurvey(() => props.survey)

const surveyOps = useSurveyOps()

const { copy, copied } = useClipboard({
  source: surveyOps.publicPath(props.survey),
  copiedDuring: 3000
})

const copyCount = ref(0)

function onCopy() {
  copy()
  copyCount.value++
}
</script>

<template>
  <SCard>
    <SCardBlock v-if="canUpdateSurvey.ok" class="block">
      <div class="text">
        <STrans lang="en">Waiting for the response from the startup. Share below URL in order to get confirmation.</STrans>
        <STrans lang="ja">スタートアップからの回答を待っています。このURLをスタートアップに共有して回答を受領してください。</STrans>
      </div>
      <div class="input">
        <SInputText
          :model-value="surveyOps.publicPath(survey)"
          disabled
        >
          <template #addon-after>
            <SInputAddon :label="t.copy" @click="onCopy" />
          </template>
        </SInputText>
        <div class="copied">
          <Transition name="fade">
            <div v-if="copied" :key="copyCount" class="copied-text">
              <STrans lang="en">Copied url to clipboard.</STrans>
              <STrans lang="ja">クリップボードにコピーしました。</STrans>
            </div>
          </Transition>
        </div>
      </div>
    </SCardBlock>
    <SCardBlock v-else class="s-p-16">
      <div class="text">
        <STrans lang="en">Waiting for the response from the startup. Only the creator of this survey can see the form URL.</STrans>
        <STrans lang="ja">スタートアップからの回答を待っています。このSurveyの作成者だけがフォームのURLを確認することができます。</STrans>
      </div>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 12px;
}

.text {
  font-size: 14px;
}

.copied {
  position: relative;
  height: 20px;
}

.copied-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 4px;
  line-height: 20px;
  text-align: right;
  font-size: 12px;
  color: var(--c-text-info-1);
  transition: opacity 0.25s, transform 0.25s;
}

.copied-text.fade-enter-from {
  opacity: 0;
  transform: translateY(-4px);
}

.copied-text.fade-leave-to {
  opacity: 0;
  transform: translateY(4px);
}
</style>
