<script setup lang="ts">
import { useRefHistory } from '@vueuse/core'
import { ref } from 'vue'
import {
  type AddOpportunityProposalsInputItem,
  type OpportunityForDetailsFrag,
  type ProposableFundGroupFrag,
  ProposableFundGroupReason
} from '@/graphql'
import { useAddOpportunityProposals } from '@/composables/repos/OpportunityRepo'
import { useGetProposableFundGroupList } from '@/composables/repos/ProposalRepo'
import OpportunityProposalAddWizardConfirm from './OpportunityProposalAddWizardConfirm.vue'
import OpportunityProposalAddWizardFillDetails from './OpportunityProposalAddWizardFillDetails.vue'
import OpportunityProposalAddWizardIrmAlert from './OpportunityProposalAddWizardIrmAlert.vue'
import OpportunityProposalAddWizardSelectExcludedFundGroups from './OpportunityProposalAddWizardSelectExcludedFundGroups.vue'
import OpportunityProposalAddWizardSelectFollowOnFundGroups from './OpportunityProposalAddWizardSelectFollowOnFundGroups.vue'
import OpportunityProposalAddWizardSelectFundGroupType from './OpportunityProposalAddWizardSelectFundGroupType.vue'
import OpportunityProposalAddWizardSelectInvestedFundGroups from './OpportunityProposalAddWizardSelectInvestedFundGroups.vue'
import OpportunityProposalAddWizardSelectSurveyTargetFundGroups from './OpportunityProposalAddWizardSelectSurveyTargetFundGroups.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  close: []
  added: []
}>()

enum Step {
  IrmNotCompleted,
  SelectFundGroupType,
  SelectInvestedFundGroups,
  SelectSurveyTargetFundGroups,
  SelectFollowOnFundGroups,
  SelectExcludedFundGroups,
  FillDetails,
  Confirm
}

const { execute: getProposableFundGroups } = useGetProposableFundGroupList(() => props.opportunity.id)
const { loading: addOpportunityProposalsLoading, execute: executeAddOpportunityProposals } = useAddOpportunityProposals()

const proposableFundGroups = await getProposableFundGroups()

const hasInvestedFundsToBeOpened = proposableFundGroups.some((pfg) => {
  return pfg.reason === ProposableFundGroupReason.Invested
})

const isIrmRequiredButNotCompleted = proposableFundGroups.some((pfg) => {
  return pfg.reason === ProposableFundGroupReason.InitialReviewMeetingNotCompleted
})

const currentStep = ref<Step>(getFirstStep())
const progress = useRefHistory(currentStep)
const transition = ref('init')

const selectedPfgs = ref<ProposableFundGroupFrag[]>([])
const selectedPfgsForFill = ref<ProposableFundGroupFrag[]>([])

const input = ref<AddOpportunityProposalsInputItem[]>([])

function getFirstStep(): Step {
  if (hasInvestedFundsToBeOpened) {
    return Step.SelectInvestedFundGroups
  }

  if (isIrmRequiredButNotCompleted) {
    return Step.IrmNotCompleted
  }

  return Step.SelectFundGroupType
}

function scrollToTop() {
  const el = document.getElementsByClassName('SModal')[0]
  el.scrollTo({ top: 0 })
}

function next(newStep: Step) {
  currentStep.value = newStep
  transition.value = 'next'
}

function prev() {
  progress.undo()
  transition.value = 'prev'
}

function onInvestingFundGroupSelected(pfgs: ProposableFundGroupFrag[]) {
  selectedPfgsForFill.value = pfgs
  next(Step.FillDetails)
}

function onFundGroupTypeSelected(pfgs: ProposableFundGroupFrag[]) {
  selectedPfgs.value = pfgs
  if (pfgs[0].fundGroup.status === 'Active' && pfgs[0].fundGroup.isSurveyTarget) {
    next(Step.SelectSurveyTargetFundGroups)
  } else if (pfgs[0].fundGroup.status === 'ActiveOnlyFollowOn' && pfgs[0].fundGroup.isSurveyTarget) {
    next(Step.SelectFollowOnFundGroups)
  } else {
    next(Step.SelectExcludedFundGroups)
  }
}

function onFundGroupsSelected(pfgs: ProposableFundGroupFrag[]) {
  selectedPfgsForFill.value = pfgs
  next(Step.FillDetails)
}

function onDetailsFilled(filledInput: AddOpportunityProposalsInputItem[]) {
  input.value = filledInput
  next(Step.Confirm)
}

async function onAdd() {
  await executeAddOpportunityProposals(
    props.opportunity.id,
    { items: input.value }
  )
  emit('added')
}
</script>

<template>
  <Transition :name="transition" mode="out-in" @after-leave="scrollToTop">
    <OpportunityProposalAddWizardSelectInvestedFundGroups
      v-if="currentStep === Step.SelectInvestedFundGroups"
      :key="Step.SelectInvestedFundGroups"
      :proposable-fund-groups="proposableFundGroups"
      class="card"
      @cancel="$emit('close')"
      @selected="onInvestingFundGroupSelected"
    />
    <OpportunityProposalAddWizardIrmAlert
      v-else-if="currentStep === Step.IrmNotCompleted"
      :key="Step.IrmNotCompleted"
      class="card"
      @close="$emit('close')"
    />
    <OpportunityProposalAddWizardSelectFundGroupType
      v-else-if="currentStep === Step.SelectFundGroupType"
      :key="Step.SelectFundGroupType"
      :proposable-fund-groups="proposableFundGroups"
      class="card"
      @cancel="$emit('close')"
      @selected="onFundGroupTypeSelected"
    />
    <OpportunityProposalAddWizardSelectSurveyTargetFundGroups
      v-else-if="currentStep === Step.SelectSurveyTargetFundGroups"
      :key="Step.SelectSurveyTargetFundGroups"
      :opportunity="opportunity"
      :proposable-fund-groups="selectedPfgs"
      class="card"
      @back="prev"
      @cancel="$emit('close')"
      @selected="onFundGroupsSelected"
    />
    <OpportunityProposalAddWizardSelectFollowOnFundGroups
      v-else-if="currentStep === Step.SelectFollowOnFundGroups"
      :key="Step.SelectFollowOnFundGroups"
      :proposable-fund-groups="selectedPfgs"
      class="card"
      @back="prev"
      @cancel="$emit('close')"
      @selected="onFundGroupsSelected"
    />
    <OpportunityProposalAddWizardSelectExcludedFundGroups
      v-else-if="currentStep === Step.SelectExcludedFundGroups"
      :key="Step.SelectExcludedFundGroups"
      :proposable-fund-groups="selectedPfgs"
      class="card"
      @back="prev"
      @cancel="$emit('close')"
      @selected="onFundGroupsSelected"
    />
    <OpportunityProposalAddWizardFillDetails
      v-else-if="currentStep === Step.FillDetails"
      :key="Step.FillDetails"
      :opportunity="opportunity"
      :proposable-fund-groups="selectedPfgsForFill"
      class="card"
      @back="prev"
      @cancel="$emit('close')"
      @filled="onDetailsFilled"
    />
    <OpportunityProposalAddWizardConfirm
      v-else-if="currentStep === Step.Confirm"
      :key="Step.Confirm"
      :proposable-fund-groups="selectedPfgsForFill"
      :loading="addOpportunityProposalsLoading"
      class="card"
      @back="prev"
      @cancel="$emit('close')"
      @add="onAdd"
    />
  </Transition>
</template>

<style scoped lang="postcss">
.card {
  transition: opacity 0.5s, transform 0.5s;
}

.card.next-enter-from {
  opacity: 0;
  transform: translateX(8px);
}

.card.next-leave-to {
  opacity: 0;
  transform: translateX(-8px);
}

.card.prev-enter-from {
  opacity: 0;
  transform: translateX(-8px);
}

.card.prev-leave-to {
  opacity: 0;
  transform: translateX(8px);
}
</style>
