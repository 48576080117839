<script setup lang="ts">
import SSpinner from 'sefirot/components/SSpinner.vue'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { type DealForWrapupFrag } from '@/graphql'
import { useAddDealReport } from '@/composables/repos/DealRepo'
import DealFormAddReportWrapupCompany from './DealFormAddReportWrapupCompany.vue'
import DealFormAddReportWrapupDeal from './DealFormAddReportWrapupDeal.vue'
import DealFormAddReportWrapupProgress, { type Step } from './DealFormAddReportWrapupProgress.vue'
import DealFormAddReportWrapupRound from './DealFormAddReportWrapupRound.vue'

const props = defineProps<{
  deal: DealForWrapupFrag
}>()

const router = useRouter()

const { execute: doAddReport } = useAddDealReport()

const step = ref(1)

const steps = computed<Step[]>(() => {
  return [
    {
      label: 'Finalize company info',
      state: getProgressState(step.value, 1)
    },
    {
      label: 'Finalize round info',
      state: getProgressState(step.value, 2)
    },
    {
      label: 'Finalize deal info',
      state: getProgressState(step.value, 3)
    }
  ]
})

function getProgressState(step: number, threshold: number) {
  return step === threshold
    ? 'in-progress'
    : step > threshold ? 'completed' : 'open'
}

async function addReport() {
  step.value = 4

  const report = await doAddReport(props.deal.id)

  router.push(`/deals/${props.deal.id}/reports/${report.id}`)
}

watch(step, () => {
  window.scrollTo(0, 0)
})
</script>

<template>
  <div class="DealFormAddReport">
    <div class="container">
      <DealFormAddReportWrapupProgress
        :steps="steps"
      />

      <DealFormAddReportWrapupCompany
        v-if="step === 1"
        :id="deal.id"
        :company-id="deal.round.companyId"
        @saved="step = 2"
      />
      <DealFormAddReportWrapupRound
        v-if="step === 2"
        :id="deal.id"
        @back="step = 1"
        @saved="step = 3"
      />
      <DealFormAddReportWrapupDeal
        v-if="step === 3"
        :deal="deal"
        @back="step = 2"
        @saved="addReport"
      />

      <DCard v-if="step === 4" :header="false">
        <div class="final">
          <div class="final-spinner">
            <SSpinner class="final-spinner-icon" />
          </div>
          <div class="final-text">
            <STrans lang="en">Creating deal report</STrans>
            <STrans lang="ja">投資報告書を作成しています</STrans>
          </div>
        </div>
      </Dcard>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.DealFormAddReport {
  padding: 32px 32px 128px;
}

.container {
  display: grid;
  margin: 0 auto;
  max-width: 768px;
  gap: 16px;
}

.final {
  padding: 48px 48px;
}

.final-text {
  padding-top: 4px;
  text-align: center;
  font-size: 14px;
  color: var(--c-text-2);
}

.final-spinner {
  display: flex;
  justify-content: center;
}

.final-spinner-icon {
  width: 48px;
  height: 48px;
}

.steps {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 4px;
}

.steps-bars {
  display: flex;
  gap: 8px;
}

.steps-bar {
  flex-shrink: 0;
  border-radius: 2px;
  width: 48px;
  height: 4px;

  &.mute    { background-color: var(--c-gray-light-3); }
  &.success { background-color: var(--c-success-bg); }
  &.danger  { background-color: var(--c-danger-bg); }

  .dark &.mute    { background-color: var(--c-gray-dark-3); }
  .dark &.success { background-color: var(--c-success-bg); }
  .dark &.danger  { background-color: var(--c-danger-bg); }
}

.steps-status {
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
