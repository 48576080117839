import {
  UserSettingsLang,
  type UserSettingsTheme,
  UserStatus,
  UserTag,
  type WithPermissionFrag
} from '@/graphql'
import { Model } from './Model'
import { Role, type RoleInput } from './Role'
import {
  AccessControl,
  type AccessControllable,
  type AccessControlled,
  AccessResource,
  type ActionType,
  type Permission,
  type ResourceType,
  hasPermittedAction,
  hasRolePermission
} from './contracts/AccessControl'

export { UserStatus, UserTag }

export interface UserInput {
  id: number
  email: string
  lastNameEn: string
  firstNameEn: string
  lastNameJa: string
  firstNameJa: string
  status: UserStatus
  roles: RoleInput[]
  roleBasedPermissions: Permission[]
}

export interface MeInput extends UserInput {
  settings: UserSettingsInput
}

export interface UserSettingsInput {
  lang: UserSettingsLang
  theme: UserSettingsTheme
}

export class User extends Model implements AccessControllable {
  id?: number
  lastNameEn?: string
  lastNameJa?: string
  firstNameEn?: string
  firstNameJa?: string
  status?: UserStatus
  email?: string
  roles: Role[]
  roleBasedPermissions: Permission[]

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.lastNameEn = data.lastNameEn
    this.lastNameJa = data.lastNameJa
    this.firstNameEn = data.firstNameEn
    this.firstNameJa = data.firstNameJa
    this.status = data.status
    this.email = data.email
    this.roles = this.hasMany(Role, data.roles)
    this.roleBasedPermissions = data.roleBasedPermissions ?? []
  }

  get name(): string {
    return `${this.firstNameEn} ${this.lastNameEn}`
  }

  path() {
    return `/users/${this.id}/profile`
  }

  isActive(): boolean {
    return this.status === UserStatus.Active
  }

  hasRoleGod(): boolean {
    return this.hasRole('God')
  }

  hasRoleGeneralPartner(): boolean {
    return this.hasRole('GeneralPartner')
  }

  hasRoleAnalyst(): boolean {
    return this.hasRole('Analyst')
  }

  hasRoleFundManager(): boolean {
    return this.hasRole('FundManager')
  }

  hasRole(role: string): boolean {
    return this.roles?.map((role) => role.name).includes(role) ?? false
  }

  can(action: ActionType, resource: ResourceType): boolean
  can<T extends AccessControlled>(action: ActionType, resource: T): boolean
  can<T extends AccessControlled>(action: ActionType, resource: ResourceType, subject: T): boolean
  can<T extends AccessControlled>(action: ActionType, resource: T | ResourceType, subject?: T): boolean {
    if (typeof resource === 'string') {
      return subject
        // If provided, the action is validated for the resource on the subject.
        // e.g. user.can('add', 'opportunity', company)
        ? hasPermittedAction(action, AccessResource[resource], subject)

        // Otherwise, the action and resource requires role-based permission.
        // e.g. user.can('edit', 'company')
        : hasRolePermission(action, AccessResource[resource], this)
    }

    // Determine whether the action is permitted on the resource.
    // e.g. user.can('delete', company)
    return hasPermittedAction(action, resource.subject, resource)
  }

  allow(action: ActionType, resource: ResourceType): boolean
  allow(action: ActionType, resource: WithPermissionFrag): boolean
  allow(action: ActionType, resource: ResourceType, subject: WithPermissionFrag): boolean
  allow(action: ActionType, resource: ResourceType | WithPermissionFrag, subject?: WithPermissionFrag): boolean {
    if (typeof resource === 'string') {
      if (subject) {
        // If provided, the action is validated for the resource on the subject.
        // e.g. user.can('add', 'opportunity', company)
        return subject.permittedActions.some((permission) => (
          permission.action === AccessControl[action]
          && permission.object === AccessResource[resource]
        ))
      }

      // Otherwise, the action and resource requires role-based permission.
      // e.g. user.can('edit', 'company')
      return hasRolePermission(action, AccessResource[resource], this)
    }
    // Determine whether the action is permitted on the resource.
    // e.g. user.can('delete', company)
    return resource.permittedActions.some((permission) => (
      permission.action === AccessControl[action]
      && (
        permission.object === resource.__typename
        || permission.object === (resource as any).subject
      )
    ))
  }

  nameEn(): string {
    return `${this.firstNameEn} ${this.lastNameEn}`
  }

  nameJa(): string {
    return `${this.lastNameJa} ${this.firstNameJa}`
  }

  static isUserModel(value: unknown): value is User {
    return value instanceof User
  }
}

export class Me extends User {
  settings: UserSettings

  constructor(data: MeInput) {
    super(data)
    this.settings = this.hasOne(UserSettings, data.settings)
  }
}

export class UserSettings extends Model {
  lang: UserSettingsLang
  theme: UserSettingsTheme

  constructor(data: UserSettingsInput) {
    super()
    this.lang = data.lang
    this.theme = data.theme
  }

  isLangEn(): boolean {
    return this.lang === UserSettingsLang.En
  }

  isLangJa(): boolean {
    return this.lang === UserSettingsLang.Ja
  }
}
