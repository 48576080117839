<script setup lang="ts">
import SInputBase from 'sefirot/components/SInputBase.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputHMS from 'sefirot/components/SInputHMS.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day, createHms, day } from 'sefirot/support/Day'
import { hms, maxLength, required, requiredHms } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsPreReviewCommitteeSubmitDoc } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { useSubmitDocsForPrc } from '@/composables/repos/DealEntryRepo'
import DealEntryPrcDocsComplete from './DealEntryPrcDocsComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsPreReviewCommitteeSubmitDoc
}>()

const { t } = useTrans({
  en: {
    i_datetime_label: 'Date of the presentation *',
    i_datetime_help: 'Specify the date and time in your local time zone.',
    i_business_desc_label: 'Business description *',
    i_business_desc_ph: 'Short description of what the company is doing.',
    i_file_link_label: 'Link to files *',
    i_file_link_ph: 'https://upload.hdedrive.com/ui/...\nPassword: xxxxxxxx\nThis URL will expire on...',
    submit: 'Submit request'
  },
  ja: {
    i_datetime_label: '社内プレ検討会開催日 *',
    i_datetime_help: 'あなたが現在いる地域の時刻で入力してください。',
    i_business_desc_label: '事業内容 *',
    i_business_desc_ph: '事業内容を一文程度で入力してください。',
    i_file_link_label: '資料へのリンク *',
    i_file_link_ph: 'https://upload.hdedrive.com/ui/...\nパスワード: xxxxxxxx\nURLの有効期限: ...',
    submit: '送信する'
  }
})

const router = useRouter()

const { execute: submitDocsForPrc, loading } = useSubmitDocsForPrc(() => props.deal.id!, () => ({
  date: createDatetimeInput(),
  businessDescription: data.value.businessDescription!,
  fileLink: data.value.fileLink!
}))

const { data } = useData({
  date: null as Day | null,
  time: createHms(),
  businessDescription: null as string | null,
  fileLink: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  date: { required: required() },
  time: { requiredHms: requiredHms(['h', 'm']), hms: hms(['h', 'm']) },
  businessDescription: { required: required(), maxLength: maxLength(160) },
  fileLink: { required: required(), maxLength: maxLength(4000) }
})

const completeDialog = usePower()

function createDatetimeInput() {
  const date = data.value.date!.format('YYYY-MM-DD')
  const time = `${data.value.time!.hour}:${data.value.time!.minute}:00`
  return day(`${date} ${time}`).toISOString()
}

async function submit() {
  if (await validateAndNotify()) {
    await submitDocsForPrc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryPrcDocs">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SContent>
              <STrans lang="en">
                <h1>Submit Docs for Pre Review Committee</h1>
                <p>You may submit the docs required for the Pre Review Committee from this form. The file must be sent using <SLink href="https://upload.hdedrive.com/ui/globalbrains.com">HENNGE Secure Transfer</SLink>. The deadline for sending the docs are during the business day before the committee.</p>
              </STrans>
              <STrans lang="ja">
                <h1>社内プレ検討会 資料送付</h1>
                <p>社内プレ検討会に必要な資料を送付するためのエントリーです。資料は<SLink href="https://upload.hdedrive.com/ui/globalbrains.com">HENNGE Secure Transfer</SLink>を使って送付してください。送付期日は社内プレ検開催の前営業日中です。</p>
              </STrans>
            </SContent>
            <SInputBase class="small" :label="t.i_datetime_label" :help="t.i_datetime_help">
              <div class="datetime">
                <SInputDate
                  v-model="data.date"
                  :validation="validation.date"
                />
                <SInputHMS
                  v-model="data.time"
                  no-second
                  :validation="validation.time"
                />
              </div>
            </SInputBase>
            <SInputTextarea
              :label="t.i_business_desc_label"
              :placeholder="t.i_business_desc_ph"
              v-model="data.businessDescription"
              :rows="3"
              :validation="validation.businessDescription"
            />
            <SInputTextarea
              :label="t.i_file_link_label"
              :placeholder="t.i_file_link_ph"
              v-model="data.fileLink"
              :rows="3"
              :validation="validation.fileLink"
            />
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryPrcDocsComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryPrcDocs {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.datetime {
  display: flex;
  gap: 8px;
}
</style>
