import { FundStatus } from '@/graphql'
import { FundGroup } from './FundGroup'
import { Model } from './Model'

export { FundStatus }

export const FundStatuses = {
  [FundStatus.NotReady]: 'Not Ready',
  [FundStatus.Active]: 'Active',
  [FundStatus.ActiveOnlyFollowOn]: 'ActiveOnlyFollowOn',
  [FundStatus.Inactive]: 'Inactive',
  [FundStatus.Liquidated]: 'Liquidated'
} as const

export interface FundOperator {
  id: number
  userID: number
}

export class Fund extends Model {
  static entity = 'funds'

  id?: number
  status?: FundStatus
  nameEn?: string
  nameJa?: string
  nameAbbreviated?: string
  rank?: number
  group?: FundGroup | null
  operators: FundOperator[]

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.status = data.status
    this.nameEn = data.nameEn
    this.nameJa = data.nameJa
    this.nameAbbreviated = data.nameAbbreviated
    this.rank = data.rank
    this.group = this.hasOne(FundGroup, data.group)
    this.operators = data.operators ?? []
  }

  isActive(): boolean {
    return this.status === FundStatus.Active
  }

  isActiveOnlyFollowOn(): boolean {
    return this.status === FundStatus.ActiveOnlyFollowOn
  }

  isAvailable(): boolean {
    return this.isActive() || this.isActiveOnlyFollowOn()
  }
}
