<script setup lang="ts">
import IconDownload from '~icons/ph/download-simple'
import IconFileText from '~icons/ph/file-text'
import IconNotePencil from '~icons/ph/note-pencil'
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SMarkdown from 'sefirot/components/SMarkdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { ref } from 'vue'
import { type OpportunityFile } from '@/graph/Opportunity'
import { useOpportunityFileOps } from '@/composables/ops/OpportunityOps'
import { useDownloadOpportunityFile } from '@/composables/repos/OpportunityFileRepo'
import OpportunityFileFormDelete from '@/components/opportunity-file/OpportunityFileFormDelete.vue'
import IconAbort from '../icon/IconAbort.vue'

defineProps<{
  opportunityId: number
  hasNoPlanToAddFiles: boolean
  fileComment: string | null
  files: OpportunityFile[]
  isDownloadingAll: boolean
  canEdit: boolean
}>()

const emit = defineEmits<{
  'file-removed': [opportunity: OpportunityFile]
  'edit-file-comment': []
  'remove-file-comment': []
}>()

const { t } = useTrans({
  en: {
    created_at: (f: OpportunityFile) => `Uploaded at ${fileOps.createdAt(f).format('MMM D, YYYY, h:mm a')}.`
  },
  ja: {
    created_at: (f: OpportunityFile) => `${fileOps.createdAt(f).format('YYYY.MM.DD HH:mm')}にアップロード。`
  }
})

const { execute: downloadOpportunityFile } = useDownloadOpportunityFile()

const fileOps = useOpportunityFileOps()

const deleteDialog = usePower()
const selectedDelete = ref<OpportunityFile | null>(null)

const isDownloading = ref<number[]>([])

async function download(file: OpportunityFile) {
  isDownloading.value.push(file.id)
  await downloadOpportunityFile(file.id)
  isDownloading.value = isDownloading.value.filter((id) => id !== file.id)
}

function onDelete(file: OpportunityFile) {
  selectedDelete.value = file
  deleteDialog.on()
}

function onDeleteCompleted() {
  emit('file-removed', selectedDelete.value!)
  deleteDialog.off()
  selectedDelete.value = null
}
</script>

<template>
  <SCardBlock class="OpportunitySideFilesContent">
    <div v-for="f in files" :key="f.id" class="file">
      <div class="file-label">
        <div class="file-icon">
          <IconFileText class="file-icon-svg" />
        </div>
        <div class="file-name">
          <div class="file-name-text" @click.stop="isDownloading.includes(f.id) || download(f)">{{ f.name }}</div>
          <div class="file-name-meta">{{ t.created_at(f) }}</div>
        </div>
      </div>
      <div class="file-actions">
        <SButton
          type="text"
          mode="mute"
          size="small"
          :icon="IconDownload"
          :disabled="isDownloadingAll"
          :loading="isDownloading.includes(f.id)"
          @click="download(f)"
        />
        <SButton
          v-if="canEdit"
          type="text"
          mode="mute"
          size="small"
          :icon="IconTrash"
          :disabled="isDownloading.includes(f.id) || isDownloadingAll"
          @click="onDelete(f)"
        />
      </div>
    </div>
    <div v-if="hasNoPlanToAddFiles" class="aborted">
      <STrans lang="en">
        <IconAbort class="aborted-icon" />
        <div class="aborted-text">No scheduled receipt</div>
      </STrans>
      <STrans lang="ja">
        <IconAbort class="aborted-icon" />
        <div class="aborted-text">資料受領予定なし</div>
      </STrans>
    </div>
    <div v-if="fileComment" class="comment">
      <div class="comment-header">
        <div class="comment-title">
          <STrans lang="en">Comment</STrans>
          <STrans lang="ja">コメント</STrans>
        </div>
        <div v-if="canEdit" class="comment-actions">
          <SButton
            size="small"
            type="text"
            mode="mute"
            :icon="IconNotePencil"
            @click="emit('edit-file-comment')"
          />
          <SButton
            size="small"
            type="text"
            mode="mute"
            :icon="IconTrash"
            @click="emit('remove-file-comment')"
          />
        </div>
      </div>
      <div class="comment-body">
        <SMarkdown inline :content="fileComment" />
      </div>
    </div>

    <SModal :open="deleteDialog.state.value" @close="deleteDialog.off">
      <OpportunityFileFormDelete
        :opportunity-file="selectedDelete!"
        @cancel="deleteDialog.off"
        @deleted="onDeleteCompleted"
      />
    </SModal>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.OpportunitySideFilesContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.file {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  padding: 0 8px 0 12px;
  height: 64px;
}

.file-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  width: 40px;
  height: 40px;
}

.file-icon-svg {
  width: 18px;
  height: 18px;
  color: var(--c-text-2);
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name-text {
  display: block;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-1);
  }
}

.file-name-meta {
  line-height: 20px;
  font-size: 12px;
  color: var(--c-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-actions {
  flex-shrink: 0;
}

.aborted {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  height: 64px;
}

.aborted-icon {
  width: 16px;
  height: 16px;
  color: var(--c-text-2);
}

.aborted-text {
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);
}

.comment {
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  padding: 8px 0 12px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 8px 0 12px;
}

.comment-title {
  flex-grow: 1;
  font-size: 14px;
  color: var(--c-text-2);
}

.comment-actions {
  display: flex;
  flex-shrink: 0;
}

.comment-body {
  padding: 0 12px;
  font-size: 14px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  color: var(--c-text-1);

  :deep(a) {
    color: var(--c-text-info-1);
    transition: color 0.25s;
  }

  :deep(a:hover) {
    color: var(--c-text-info-2);
  }
}
</style>
