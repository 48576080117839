<script setup lang="ts">
import { useCanViewAny } from '@/composables/policies/UserPolicy'
import UserCatalog from '@/components/users/UserCatalog.vue'

const canViewAny = useCanViewAny()
</script>

<template>
  <DPage class="UsersIndex" title="Members" :authorize="canViewAny.ok">
    <UserCatalog />
  </DPage>
</template>
