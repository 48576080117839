import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import { Company } from '@/models/Company'
import { Deal } from '@/models/Deal'
import { User } from '@/models/User'
import {
  type UserActivityCondition,
  UserProfileRequest,
  UserRequest,
  type UsersCondition
} from '@/requests/UserRequest'
import { type Query, useQuery } from '../Api'

export type UserCollection = Query<User[]>
export type UserItem = Query<User>
export type UserActivityOverviewDealOpenedCollection = Query<Deal[]>
export type UserActivityOverviewDealInvestedCollection = Query<Deal[]>
export type UserActivityOverviewDealDroppedCollection = Query<Deal[]>
export type CompanyAssignedCollection = Query<Company[]>
export type CompanyPromotedCollection = Query<Company[]>

export function useUserActiveCollection(
  condition?: MaybeRefOrGetter<UsersCondition>
): UserCollection {
  return useQuery(async () => {
    const res = await new UserRequest().fetchAll(
      toValue(condition)
    )

    return res.data.users.map((u) => new User(u)).filter((u) => u.isActive())
  })
}

export function useUserItem(id: number | (() => number)): UserItem {
  return useQuery(async () => {
    const _id = typeof id === 'function' ? id() : id
    const res = await new UserRequest().fetch(_id)

    return res.data.user ? new User(res.data.user) : null
  })
}

export function useUserActivityDealOpenedData(userId: number, condition: UserActivityCondition): UserActivityOverviewDealOpenedCollection {
  const query = useQuery(async () => {
    const res = await new UserProfileRequest().fetchActivityDealOpenedCollection(userId, {
      from: condition.from,
      to: condition.to
    })

    return res.data.userActivityDealOpened.map((d) => new Deal(d))
  })

  watch(() => condition, query.execute, { deep: true })

  return query
}

export function useUserActivityDealInvestedData(userId: number, condition: UserActivityCondition): UserActivityOverviewDealInvestedCollection {
  const query = useQuery(async () => {
    const res = await new UserProfileRequest().fetchActivityDealInvestedCollection(userId, {
      from: condition.from,
      to: condition.to
    })

    return res.data.userActivityDealInvested.map((d) => new Deal(d))
  })

  watch(() => condition, query.execute, { deep: true })

  return query
}

export function useUserActivityDealDroppedData(userId: number, condition: UserActivityCondition): UserActivityOverviewDealDroppedCollection {
  const query = useQuery(async () => {
    const res = await new UserProfileRequest().fetchActivityDealDroppedCollection(userId, {
      from: condition.from,
      to: condition.to
    })

    return res.data.userActivityDealDropped.map((d) => new Deal(d))
  })

  watch(() => condition, query.execute, { deep: true })

  return query
}

export function useUserCompanyAssignedCollection(userId: number): CompanyAssignedCollection {
  return useQuery(async () => {
    const res = await new UserProfileRequest().fetchCompanyAssignedCollection(userId)

    return res.data.companies.map((c) => new Company(c))
  })
}

export function useUserCompanyPromotedCollection(userId: number): CompanyPromotedCollection {
  return useQuery(async () => {
    const res = await new UserProfileRequest().fetchCompanyPromotedCollection(userId)

    return res.data.companies.map((c) => new Company(c))
  })
}

export function useUserDealOpenedCollection(userId: number) {
  return useQuery(async () => {
    const res = await new UserProfileRequest().fetchDealOpenedCollection(userId)

    return res.data.deals.map((d) => new Deal(d))
  })
}
