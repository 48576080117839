import {
  RequestDealEntryDDKickOffFullDocument,
  type RequestDealEntryDDKickOffFullInput,
  RequestDealEntryDDKickOffMinimalDocument,
  type RequestDealEntryDDKickOffMinimalInput,
  RequestDealEntryExecutivePresentationDocument,
  type RequestDealEntryExecutivePresentationInput,
  RequestDealEntryInvestmentCommitteeDocument,
  type RequestDealEntryInvestmentCommitteeInput,
  RequestDealEntryPreReviewCommitteeDocument,
  type RequestDealEntryPreReviewCommitteeInput,
  SubmitDocForDealEntryExecutivePresentationDocument,
  type SubmitDocForDealEntryExecutivePresentationInput,
  SubmitDocForDealEntryInvestmentCommitteeDocument,
  type SubmitDocForDealEntryInvestmentCommitteeInput,
  SubmitDocForDealEntryPreReviewCommitteeDocument,
  type SubmitDocForDealEntryPreReviewCommitteeInput
} from '@/graphql'
import { Request } from './Request'

export type RequestPrcInput = RequestDealEntryPreReviewCommitteeInput
export type SubmitDocsForPrcInput = SubmitDocForDealEntryPreReviewCommitteeInput
export type RequestDdFullInput = RequestDealEntryDDKickOffFullInput
export type RequestDdMinimalInput = RequestDealEntryDDKickOffMinimalInput
export type RequestEpInput = RequestDealEntryExecutivePresentationInput
export type SubmitDocsForEpInput = SubmitDocForDealEntryExecutivePresentationInput
export type RequestIcInput = RequestDealEntryInvestmentCommitteeInput
export type SubmitDocsForIcInput = SubmitDocForDealEntryInvestmentCommitteeInput

export class DealEntryRequest extends Request {
  requestPrc(dealId: number, input: RequestDealEntryPreReviewCommitteeInput) {
    return this.request({
      query: RequestDealEntryPreReviewCommitteeDocument,
      variables: { dealId, input }
    })
  }

  submitDocsForPrc(dealId: number, input: SubmitDocForDealEntryPreReviewCommitteeInput) {
    return this.request({
      query: SubmitDocForDealEntryPreReviewCommitteeDocument,
      variables: { dealId, input }
    })
  }

  requestDdFull(dealId: number, input: RequestDealEntryDDKickOffFullInput) {
    return this.request({
      query: RequestDealEntryDDKickOffFullDocument,
      variables: { dealId, input }
    })
  }

  requestDdMinimal(dealId: number, input: RequestDealEntryDDKickOffMinimalInput) {
    return this.request({
      query: RequestDealEntryDDKickOffMinimalDocument,
      variables: { dealId, input }
    })
  }

  requestEp(dealId: number, input: RequestDealEntryExecutivePresentationInput) {
    return this.request({
      query: RequestDealEntryExecutivePresentationDocument,
      variables: { dealId, input }
    })
  }

  submitDocsForEp(dealId: number, input: SubmitDocForDealEntryExecutivePresentationInput) {
    return this.request({
      query: SubmitDocForDealEntryExecutivePresentationDocument,
      variables: { dealId, input }
    })
  }

  requestIc(dealId: number, input: RequestDealEntryInvestmentCommitteeInput) {
    return this.request({
      query: RequestDealEntryInvestmentCommitteeDocument,
      variables: { dealId, input }
    })
  }

  submitDocsForIc(dealId: number, input: SubmitDocForDealEntryInvestmentCommitteeInput) {
    return this.request({
      query: SubmitDocForDealEntryInvestmentCommitteeDocument,
      variables: { dealId, input }
    })
  }
}
