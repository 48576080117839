import { Model } from './Model'

export interface RoleInput {
  id: number
  name: string
  label: string
}

export class Role extends Model {
  id?: number
  name?: string
  label?: string
  description?: string

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.name = data.name
    this.label = data.label
    this.description = data.description
  }
}
