<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Participating investors',
    info: 'Investers other than GB planning to join this round.',
    ph: 'Invester A\nInvester B\netc.'
  },
  ja: {
    label: 'Participating investors',
    info: 'GB以外で本ラウンドに参加予定の投資家。',
    ph: '投資家A\n投資家B\netc.'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
