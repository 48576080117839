<script setup lang="ts">
import IconTrash from '~icons/ph/trash-bold'
import SButton from 'sefirot/components/SButton.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useValidation } from 'sefirot/composables/Validation'
import { requiredIf } from 'sefirot/validation/rules'
import { type CompanyBusinessReportFinanceTransitionRecordRowFrag } from '@/graphql'

const props = defineProps<{
  strict: boolean
  deletable: boolean
  disabled: boolean
}>()

const model = defineModel<CompanyBusinessReportFinanceTransitionRecordRowFrag>({ required: true })

defineEmits<{
  delete: []
}>()

const { validation } = useValidation(model, {
  key: { required: requiredIf(() => props.strict) },
  value: { required: requiredIf(() => props.strict) }
})
</script>

<template>
  <div class="CompanyBusinessReportFinanceTransitionRecordRow">
    <div class="input key">
      <SInputText
        :disabled="disabled"
        v-model="model.key"
        :validation="validation.key"
        hide-error
      />
    </div>
    <div class="input value">
      <SInputTextarea
        :rows="1"
        auto-resize
        :disabled="disabled"
        v-model="model.value"
        :validation="validation.value"
        hide-error
      />
    </div>
    <div v-if="!disabled" class="delete">
      <SButton
        type="text"
        mode="mute"
        :icon="IconTrash"
        :disabled="!deletable"
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportFinanceTransitionRecordRow {
  display: flex;
  gap: 8px;
}

.input.key {
  min-width: 192px;
}

.input.value {
  flex-grow: 1;
}
</style>
