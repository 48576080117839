<script setup lang="ts">
import { useRouter } from 'vue-router'
import { type View } from '@/models/View'
import { useViewDelete } from '@/composables/view/ViewData'

const props = defineProps<{
  view?: View | null
}>()

defineEmits<{
  (e: 'cancel'): void
}>()

const router = useRouter()
const { execute, loading } = useViewDelete(props.view!.id!)

async function remove() {
  await execute()

  router.push('/views')
}
</script>

<template>
  <DForm>
    <DFormTitle>Delete this view</DFormTitle>

    <DFormMedium>
      <p>
        This action can't be undone.
        This will be permanently delete this view.
      </p>
    </DFormMedium>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="danger" label="Delete" :loading="loading" @click="remove" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
