<script setup lang="ts">
import { getAuth } from 'firebase/auth'
import SSnackbars from 'sefirot/components/SSnackbars.vue'
import { provideLang, useBrowserLang } from 'sefirot/composables/Lang'
import { Http } from 'sefirot/http/Http'
import { useError } from 'sefirot/stores/Error'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import ErrorPage from '../error/ErrorPage.vue'

const route = useRoute()
const browserLang = useBrowserLang()
const error = useError()

Http.config({
  baseUrl: import.meta.env.API_BASE_URL,
  xsrfUrl: false,
  lang: browserLang,
  headers: async () => ({
    Authorization: `Bearer ${(await getAuth().currentUser?.getIdToken()) || ''}`
  }),
  stringifyOptions: { arrayFormat: 'brackets' }
})

watch(() => route.path, () => error.clear())

provideLang(browserLang)
</script>

<template>
  <div class="LayoutBlank">
    <ErrorPage v-if="error.data" :error="error.data" />
    <RouterView v-else />
    <SSnackbars />
  </div>
</template>

<style lang="postcss" scoped>
.LayoutBlank {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}
</style>
