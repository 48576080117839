<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  open: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})
</script>

<template>
  <DModal size="small" :open="open" @close="$emit('close')">
    <DCard :header="false">
      <DCardClose @close="$emit('close')" />

      <DForm>
        <DFormSection>
          <STrans lang="en">
            <DFormTitle>Deal report completed</DFormTitle>
            <DFormMedium>
              <p>Next, please complete the deal. If additional deal reports are required, please create a new report.</p>
            </DFormMedium>
          </STrans>
          <STrans lang="ja">
            <DFormTitle>投資報告書をコンプリートしました</DFormTitle>
            <DFormMedium>
              <p>続いてディールをコンプリートしてください。追加で投資報告書が必要な場合は新たな投資報告書を作成してください。</p>
            </DFormMedium>
          </STrans>
        </DFormSection>
      </DForm>

      <DCardFooter>
        <DCardFooterActions>
          <DCardFooterAction
            :label="t.close"
            @click="$emit('close')"
          />
        </DCardFooterActions>
      </DCardFooter>
    </DCard>
  </DModal>
</template>
