<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: {
    a_close: 'Close'
  },
  ja: {
    a_close: '閉じる'
  }
})
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds</h2>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityをファンドへ開示する</h2>
          </STrans>
        </SContent>
        <SAlert mode="danger">
          <STrans lang="en">
            <p>Initial review meeting must be completed before opening proposals to any funds due to the “Initial evaluation by PIC” is set to “Considering investment”.</p>
          </STrans>
          <STrans lang="ja">
            <p>「Initial evaluation by PIC」が「Considering investment」に設定されているため、当Oppoをファンドへ開示する前にInitial Review Meetingを完了する必要があります。</p>
          </STrans>
        </SAlert>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_close"
            @click="$emit('close')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
