<script setup lang="ts">
import { orderBy } from 'lodash-es'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type CompanyPortfolioDataWithFinancialFiguresFrag } from '@/graphql'
import { useCanUpdateCompanyPortfolioData } from '@/composables/policies/CompanyPolicy'
import CompanyFinancialResultGridItem from './CompanyFinancialResultGridItem.vue'
import CompanyFinancialResultGridItemAdd from './CompanyFinancialResultGridItemAdd.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioDataWithFinancialFiguresFrag
}>()

const emit = defineEmits<{
  created: []
  updated: []
  deleted: []
}>()

const { t } = useTrans({
  en: {
    period: 'Period',
    currency: 'Currency',
    net_sales: 'Net sales',
    ordinary_income: 'Ordinary income',
    net_income: 'Net income',
    total_assets: 'Total assets',
    capital: 'Capital',
    net_assets: 'Net assets',
    updated_by: 'Updated by',
    updated_at: 'Updated at'
  },
  ja: {
    period: '決算期',
    currency: '通貨',
    net_sales: '純売上',
    ordinary_income: '経常利益',
    net_income: '純利益',
    total_assets: '総資産',
    capital: '資本金',
    net_assets: '純資産',
    updated_by: '更新者',
    updated_at: '更新日時'
  }
})

const canUpdate = useCanUpdateCompanyPortfolioData(props.portfolioData)

const financialResults = computed(() => orderBy(props.portfolioData.financialFigures?.results ?? [], ['period']))
</script>

<template>
  <div class="CompanyFinancialResultGrid">
    <ul class="header">
      <li class="header-text">{{ t.period }}</li>
      <li class="header-text">{{ t.currency }}</li>
      <li class="header-text">{{ t.net_sales }}</li>
      <li class="header-text">{{ t.ordinary_income }}</li>
      <li class="header-text">{{ t.net_income }}</li>
      <li class="header-text">{{ t.total_assets }}</li>
      <li class="header-text">{{ t.capital }}</li>
      <li class="header-text">{{ t.net_assets }}</li>
      <li class="header-text">{{ t.updated_by }}</li>
      <li class="header-text">{{ t.updated_at }}</li>
    </ul>
    <div class="body">
      <div class="container">
        <CompanyFinancialResultGridItem
          v-for="financialResult in financialResults"
          :key="financialResult.id"
          :portfolio-data="portfolioData"
          :financial-result="financialResult"
          @updated="emit('updated')"
          @deleted="emit('deleted')"
        />
        <CompanyFinancialResultGridItemAdd
          v-if="canUpdate.ok"
          :company-id="portfolioData.financialFigures!.companyId!"
          @created="emit('created')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.CompanyFinancialResultGrid {
  position: relative;
  display: flex;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  background-color: var(--c-bg-elv-3);
  overflow: hidden;
}

.header {
  border-right: 1px solid var(--c-divider-2);
  min-width: 128px;
  background-color: var(--c-soft);
}

.header-text {
  padding-left: 12px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);

  & + & {
    border-top: 1px solid var(--c-divider-2);
  }
}

.body {
  flex-grow: 1;
  overflow-x: auto;
  overscroll-behavior: none;
}

.container {
  display: flex;
  height: 100%;
  align-items: stretch;
}

.text-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
