<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type CompanySupportLogWithMembers } from '@/graph/Company'
import { useDelteCompanySupportLog } from '@/composables/repos/CompanySupportLogRepo'

const props = defineProps<{
  supportLog: CompanySupportLogWithMembers
}>()

const emit = defineEmits<{
  cancel: []
  deleted: [supportLog: CompanySupportLogWithMembers]
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    delete: 'Delete support log'
  },
  ja: {
    cancel: 'キャンセル',
    delete: '支援実績を削除'
  }
})

const { execute, loading } = useDelteCompanySupportLog()

async function remove() {
  await execute(props.supportLog.id)
  emit('deleted', props.supportLog)
}
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Delete support log</h2>
            <p>You are about delete support log <strong>“{{ supportLog.title }}”</strong>. This action cannot be undone. Are you absolutely sure you want to delete this support log?</p>
          </STrans>
          <STrans lang="ja">
            <h2>支援実績を削除する</h2>
            <p>支援実績<strong>「{{ supportLog.title }}」</strong>を削除します。この操作は取り消すことができません。本当にこの支援実績を削除しますか？</p>
          </STrans>
        </SContent>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            :label="t.delete"
            :loading="loading"
            @click="remove"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
