<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconTrash from '~icons/ph/trash-bold'
import { keyBy } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { format } from 'sefirot/support/Num'
import { computed } from 'vue'
import {
  DealReportStatus,
  type DealReportWithDateFrag,
  type DealReportWithIdFrag,
  type DealReportWithNameFrag,
  type DealReportWithStatusFrag,
  type DealReportWithTransactionsFrag,
  type DealWithIdFrag,
  type DealWithSecuritiesFrag,
  type DealWithStatusFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useCurrencyOps } from '@/composables/ops/CurrencyOps'
import { useDealOps } from '@/composables/ops/DealOps'
import { useCanDeleteReport, useCanUpdateReport } from '@/composables/policies/DealPolicy'
import { useDeleteDealReport } from '@/composables/repos/DealReportRepo'
import DealDialogDeleteReport from './DealDialogDeleteReport.vue'

export type Deal =
  & WithPermissionFrag
  & DealWithIdFrag
  & DealWithStatusFrag
  & DealWithSecuritiesFrag

export type DealReport =
  & DealReportWithIdFrag
  & DealReportWithNameFrag
  & DealReportWithStatusFrag
  & DealReportWithDateFrag
  & DealReportWithTransactionsFrag

const props = defineProps<{
  deal: Deal
  report: DealReport
}>()

const emit = defineEmits<{
  (e: 'deleted'): void
}>()

const { t } = useTrans({
  en: {
    status: 'Status',
    invested_date: 'Invested date',
    reported_date: 'Reported date',
    security: 'Security',
    acq_amount: 'Acq. amount',
    acq_amount_fmc: 'Acq. amount (JPY)'
  },
  ja: {
    status: 'ステータス',
    invested_date: '投資日',
    reported_date: '報告日',
    security: '有価証券',
    acq_amount: '取得価額',
    acq_amount_fmc: '取得価額 (JPY)'
  }
})

const canUpdate = useCanUpdateReport(() => props.deal, () => props.report)
const canDelete = useCanDeleteReport(() => props.deal, () => props.report)

const { execute, loading } = useDeleteDealReport()

const currencyOps = useCurrencyOps()
const dealOps = useDealOps()

const deleteDialog = usePower()

const reportLink = computed(() => {
  return `/deals/${props.deal.id}/reports/${props.report.id}`
})

const statusMode = computed(() => {
  return props.report.status === DealReportStatus.Completed ? 'success' : 'mute'
})

const dealCurrency = computed(() => dealOps.currency(props.deal))
const fmcCurrency = computed(() => currencyOps.createJpy())

const transactionDict = computed(() => {
  return keyBy(props.report.transactions, 'securityId')
})

function isZero(value?: string | null) {
  return (value == null) || (Number(value) === 0)
}

function formatValue(value: string | number) {
  return format(Number(value))
}

async function remove() {
  await execute(props.report.id)
  emit('deleted')
}
</script>

<template>
  <div class="DealPageReportsListItem">
    <div class="summary">
      <div class="title">
        <SLink class="name" :class="{ mute: !report.name }" :href="reportLink">
          {{ report.name ?? 'Untitled report' }}
        </SLink>
        <div class="actions">
          <SButton
            v-if="!canUpdate.is('no-auth') && !canUpdate.is('invested')"
            class="button"
            size="small"
            type="text"
            :disabled="!canUpdate.ok"
            :tooltip="{ text: canUpdate.message }"
            :icon="IconNotePencil"
            :href="reportLink"
          />
          <SButton
            class="button"
            size="small"
            type="text"
            :disabled="!canDelete.ok"
            :tooltip="{ text: canDelete.message }"
            :icon="IconTrash"
            @click="deleteDialog.on"
          />
        </div>
      </div>
      <div class="desc">
        <DGrid cols="3">
          <DGridItem no-line>
            <DGridLabel :text="t.status" />
            <DGridState :mode="statusMode" :label="report.status" />
          </DGridItem>
          <DGridItem no-line>
            <DGridLabel :text="t.invested_date" />
            <DGridDate :date="report.investedDate" />
          </DGridItem>
          <DGridItem no-line>
            <DGridLabel :text="t.reported_date" />
            <DGridDate :date="report.reportedDate" />
          </DGridItem>
        </DGrid>
      </div>
    </div>

    <div v-if="report.transactions.length" class="purchases">
      <div class="row">
        <div class="col security">{{ t.security }}</div>
        <div class="col acq-amount">{{ t.acq_amount }}</div>
        <div class="col acq-amount-fmc">{{ t.acq_amount_fmc }}</div>
      </div>
      <div class="row" v-for="security in deal.securities" :key="security.id">
        <div class="cell security">
          <div class="value">{{ security.name }}</div>
        </div>
        <div class="cell acq-amount" :class="{ mute: isZero(transactionDict[security.id]?.totalPrice) }">
          <div class="symbol">{{ dealCurrency.symbol }}</div>
          <div class="value">{{ formatValue(transactionDict[security.id]?.totalPrice ?? 0) }}</div>
          <div class="currency">{{ dealCurrency.name }}</div>
        </div>
        <div class="cell acq-amount-fmc" :class="{ mute: isZero(transactionDict[security.id]?.totalPriceFmc) }">
          <div class="symbol">{{ fmcCurrency.symbol }}</div>
          <div class="value">{{ formatValue(transactionDict[security.id]?.totalPriceFmc ?? 0) }}</div>
          <div class="currency">{{ fmcCurrency.name }}</div>
        </div>
      </div>
    </div>

    <DealDialogDeleteReport
      :open="deleteDialog.state.value"
      :report="report"
      :loading="loading"
      @close="deleteDialog.off"
      @cancel="deleteDialog.off"
      @delete="remove"
    />
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsListItem {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  background-color: var(--c-bg-elv-3);
}

.summary {
  padding: 16px 16px 16px 24px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 16px;
  font-weight: 500;
  transition: color 0.25s;

  &:hover {
    color: var(--c-info-text);
  }

  &.mute {
    color: var(--c-text-2);
  }

  &.mute:hover {
    color: var(--c-info-text);
  }
}

.actions {
  display: flex;
}

.actions :deep(.SButton) {
  justify-content: center;
}

.actions :deep(.SButton .content) {
  width: 100%;
}

.desc {
  padding-top: 8px;
  max-width: 480px;
}

.purchases {
  display: grid;
  gap: 1px;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid transparent;
  background-color: var(--c-gutter);
}

.row {
  display: grid;
  grid-template-columns: 1fr 208px 208px;
}

.col,
.cell {
  display: flex;
  align-items: center;
  height: 40px;

  &:first-child { padding-left: 24px; }
  &:last-child  { padding-right: 24px; }
}

.col {
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
  background-color: var(--c-soft);
}

.cell {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--c-bg-elv-3);

  .row:last-child &:first-child { border-radius: 0 0 0 6px; }
  .row:last-child &:last-child  { border-radius: 0 0 6px 0; }
}

.acq-amount,
.acq-amount-fmc {
  justify-content: flex-end;

  &.mute {
    color: var(--c-text-3);
  }
}

.symbol {
  padding-right: 6px;
}

.value {
  font-feature-settings: "tnum";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.currency {
  padding-left: 6px;
  font-family: var(--font-family-mono);
  color: var(--c-text-2);

  .acq-amount.mute &,
  .acq-amount-fmc.mute & {
    color: var(--c-text-3);
  }
}
</style>
