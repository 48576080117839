import { type Query, useQuery } from 'sefirot/composables/Api'
import { type ProposalViewExportStatusFrag } from '@/graphql'
import { ViewRequest } from '@/requests/ViewRequest'

export type ProposalViewExportStatusList = Query<ProposalViewExportStatusFrag[]>

export function useProposalViewExportStatusList(): ProposalViewExportStatusList {
  return useQuery(() => fetchProposalViewExportStatusList())
}

async function fetchProposalViewExportStatusList(): Promise<ProposalViewExportStatusFrag[]> {
  const res = await new ViewRequest().fetchProposalViewExportStatusAll()
  return res.data.proposalViewExportStatuses
}
