import { toValue } from 'vue'
import { type OpportunityInitialReviewMeeting } from '@/graph/Opportunity'
import {
  type OpportunityConflictManagementCommitteeFrag,
  type OpportunityForDetailsFrag,
  type OpportunityInitialReviewMeetingFrag,
  type OpportunitySourceFrag,
  type OpportunityWithCompanyFrag,
  type OpportunityWithProposalFrag,
  type OpportunityWithStatusFrag,
  type ProposalViewExportStatusFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import {
  type Authorized,
  type PolicyResponse,
  type Resource,
  allow,
  deny,
  pending,
  usePolicy,
  usePolicyResponse
} from '../Policy'
import { useOpportunitySourceOps } from '../ops/OpportunityOps'

export interface OpportunityPolicy {
  canUpdate: Authorized
}

export function useCanUpdateOpportunityPolicy(opportunity?: Resource<WithPermissionFrag>): PolicyResponse {
  const { defineWhen } = usePolicy()

  const canUpdate = defineWhen(opportunity, (u, o) => {
    return u.allow('edit', o)
  })

  return usePolicyResponse(() => {
    return canUpdate.value ? allow() : deny()
  })
}

export function useCanDeleteOpportunitySource(
  oppo: Resource<OpportunityForDetailsFrag>,
  source: Resource<OpportunitySourceFrag>
): PolicyResponse<'undefined' | 'last-one' | 'used-in-proposal' | 'unauthorized'> {
  const canUpdateOpportunity = useCanUpdateOpportunityPolicy(oppo)

  const oppoSourceOps = useOpportunitySourceOps()

  return usePolicyResponse(() => {
    const o = toValue(oppo)
    const s = toValue(source)

    if (!o || !s) {
      return pending()
    }

    if (o.sources.length === 1) {
      return deny('last-one')
    }

    if (oppoSourceOps.isUsedInProposals(s, o.proposals)) {
      return deny('used-in-proposal')
    }

    return canUpdateOpportunity.value.ok ? allow() : deny('unauthorized')
  })
}

export function useCanUpdateInitialEvaluation(
  oppo: Resource<OpportunityForDetailsFrag>
): PolicyResponse {
  return usePolicyResponse(() => {
    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    return o.proposals.length > 0 ? deny() : allow()
  })
}

export function useCanUnlinkSurveyFromOpportunity(
  oppo: Resource<OpportunityForDetailsFrag>
): PolicyResponse {
  const canUpdateOpportunity = useCanUpdateOpportunityPolicy(oppo)

  return usePolicyResponse(() => {
    if (!canUpdateOpportunity.value.ok) {
      return deny()
    }

    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    const hasOpenSurveyTargetProposals = o.proposals.some((p) => {
      return p.fundGroup.isSurveyTarget
    })

    return hasOpenSurveyTargetProposals ? deny() : allow()
  })
}

/**
 * Check if the user are allowed to initiate the initial review meeting
 * request. The oppo must not be "Closed" and the user must be one of
 * the opportunity assignees.
 */
export function useCanInitiateInitialReviewMeetingRequest(
  oppo: Resource<OpportunityWithStatusFrag & OpportunityWithCompanyFrag>
): PolicyResponse {
  const companyOps = useCompanyOps()
  const oppoOps = useOpportunityOps()

  return usePolicyResponse((user) => {
    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    // If the oppo is closed, do not even "God" user initiate the request.
    if (oppoOps.isClosed(o)) {
      return deny()
    }

    if (user.hasRoleGod()) {
      return allow()
    }

    return companyOps.isAssigned(o.company) ? allow() : deny()
  })
}

/**
 * Check if the user can view the opportunity initial review meeting request
 * link. The user must be the one who initiated the request or else they
 * cannot access the Fatima request page.
 */
export function useCanViewOpportunityInitialReviewMeetingRequestLink(
  irm: Resource<OpportunityInitialReviewMeeting | OpportunityInitialReviewMeetingFrag>
): PolicyResponse {
  return usePolicyResponse((user) => {
    if (user.hasRoleGod()) {
      return allow()
    }

    const _irm = toValue(irm)

    if (!_irm) {
      return pending()
    }

    return _irm.initiatedBy.id === user.id ? allow() : deny()
  })
}

/**
 * Check if the user are allowed to initiate the initial review meeting
 * request. The oppo must not be "Closed" and the user must be one of
 * the opportunity assignees.
 */
export function useCanInitiateConflictManagementCommitteeRequest(
  oppo: Resource<OpportunityWithStatusFrag & OpportunityWithCompanyFrag>
): PolicyResponse {
  const companyOps = useCompanyOps()
  const oppoOps = useOpportunityOps()

  return usePolicyResponse((user) => {
    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    // If the oppo is closed, do not even "God" user initiate the request.
    if (oppoOps.isClosed(o)) {
      return deny()
    }

    if (user.hasRoleGod()) {
      return allow()
    }

    return companyOps.isAssigned(o.company) ? allow() : deny()
  })
}

/**
 * Check if the user can view the opportunity conflict management committee request
 * link. The user must be the one who initiated the request or else they
 * cannot access the Fatima request page.
 */
export function useCanViewOpportunityConflictManagementCommitteeRequestLink(
  cmc: Resource<OpportunityConflictManagementCommitteeFrag>
): PolicyResponse {
  return usePolicyResponse((user) => {
    if (user.hasRoleGod()) {
      return allow()
    }

    const _cmc = toValue(cmc)

    if (!_cmc) {
      return pending()
    }

    return _cmc.initiatedBy.id === user.id ? allow() : deny()
  })
}

/**
 * Check if the user is allowed to add proposals. The oppo must not be "Closed"
 * and the user must be one of the opportunity assignees.
 */
export function useCanAddOpportunityProposalPolicy(
  oppo: Resource<OpportunityWithStatusFrag & OpportunityWithCompanyFrag & WithPermissionFrag>
): PolicyResponse<'undefined' | 'opportunity-closed' | 'unauthorized'> {
  const oppoOps = useOpportunityOps()

  return usePolicyResponse((user) => {
    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    if (oppoOps.isClosed(o)) {
      return deny('opportunity-closed')
    }

    return user.allow('edit', o) ? allow() : deny('unauthorized')
  })
}

/**
 * Check if the user is allowed to delete all proposals. The oppo must not be
 * "Closed" and all proposal's last exported time must be before the created
 * time of them.
 */
export function useCanDeleteAllOpportunityProposalPolicy(
  oppo: Resource<OpportunityWithStatusFrag & OpportunityWithCompanyFrag & OpportunityWithProposalFrag & WithPermissionFrag>,
  pves: Resource<ProposalViewExportStatusFrag[]>
): PolicyResponse<'undefined' | 'no-proposals' | 'opportunity-closed' | 'unauthorized'> {
  const oppoOps = useOpportunityOps()

  return usePolicyResponse((user) => {
    const o = toValue(oppo)
    const p = toValue(pves)

    if (!o || !p) {
      return pending()
    }

    if (o.proposals.length === 0) {
      return deny('no-proposals')
    }

    if (oppoOps.isClosed(o)) {
      return deny('opportunity-closed')
    }

    return user.allow('edit', o) ? allow() : deny('unauthorized')
  })
}

export function useCanAddDeal(
  oppo?: Resource<OpportunityForDetailsFrag>
): PolicyResponse<
  | 'undefined'
  | 'opportunity-closed'
  | 'no-round'
  | 'unauthorized'
> {
  const oppoOps = useOpportunityOps()

  return usePolicyResponse((user) => {
    const o = toValue(oppo)

    if (!o) {
      return pending()
    }

    if (oppoOps.isClosed(o)) {
      return deny('opportunity-closed')
    }

    if (!o.round) {
      return deny('no-round')
    }

    return user.allow('add', 'deal', o) ? allow() : deny('unauthorized')
  })
}
