import { type MaybeRefOrGetter, toValue } from '@vueuse/core'
import {
  DealEntryRequest,
  type RequestDdFullInput,
  type RequestDdMinimalInput,
  type RequestEpInput,
  type RequestIcInput,
  type RequestPrcInput,
  type SubmitDocsForEpInput,
  type SubmitDocsForIcInput,
  type SubmitDocsForPrcInput
} from '@/requests/DealEntryRequest'
import { type Mutation, useMutation } from '../Api'

export type RequestPrc = Mutation<void, []>
export type SubmitDocsForPrc = Mutation<void, []>
export type RequestDdFull = Mutation<void, []>
export type RequestDdMinimal = Mutation<void, []>
export type RequestEp = Mutation<void, []>
export type SubmitDocsForEp = Mutation<void, []>
export type RequestIc = Mutation<void, []>
export type SubmitDocsForIc = Mutation<void, []>

export function useRequestPrc(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<RequestPrcInput>
): RequestPrc {
  return useMutation(async () => {
    await new DealEntryRequest().requestPrc(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useSubmitDocsForPrc(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<SubmitDocsForPrcInput>
): SubmitDocsForPrc {
  return useMutation(async () => {
    await new DealEntryRequest().submitDocsForPrc(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useRequestDdFull(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<RequestDdFullInput>
): RequestDdFull {
  return useMutation(async () => {
    await new DealEntryRequest().requestDdFull(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useRequestDdMinimal(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<RequestDdMinimalInput>
): RequestDdMinimal {
  return useMutation(async () => {
    await new DealEntryRequest().requestDdMinimal(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useRequestEp(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<RequestEpInput>
): RequestEp {
  return useMutation(async () => {
    await new DealEntryRequest().requestEp(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useSubmitDocsForEp(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<SubmitDocsForEpInput>
): SubmitDocsForEp {
  return useMutation(async () => {
    await new DealEntryRequest().submitDocsForEp(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useRequestIc(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<RequestIcInput>
): RequestIc {
  return useMutation(async () => {
    await new DealEntryRequest().requestIc(
      toValue(dealId),
      toValue(input)
    )
  })
}

export function useSubmitDocsForIc(
  dealId: MaybeRefOrGetter<number>,
  input: MaybeRefOrGetter<SubmitDocsForIcInput>
): SubmitDocsForIc {
  return useMutation(async () => {
    await new DealEntryRequest().submitDocsForIc(
      toValue(dealId),
      toValue(input)
    )
  })
}
