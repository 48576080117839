import {
  AntiDilutionProvisionListDocument,
  DeleteDealSecurityDocument,
  LiquidationPreferencePatternListDocument,
  UpdateDealSecurityDocument,
  type UpdateDealSecurityInput
} from '@/graphql'
import { Request } from './Request'

export type {
  UpdateDealSecurityInput
}

export class DealSecurityRequest extends Request {
  liquidationPreferencePatternList() {
    return this.request({
      query: LiquidationPreferencePatternListDocument
    })
  }

  antiDilutionProvisionList() {
    return this.request({
      query: AntiDilutionProvisionListDocument
    })
  }

  update(id: number, input: UpdateDealSecurityInput) {
    return this.request({
      query: UpdateDealSecurityDocument,
      variables: { id, input }
    })
  }

  delete(id: number) {
    return this.request({
      query: DeleteDealSecurityDocument,
      variables: { id }
    })
  }
}
