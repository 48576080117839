<script setup lang="ts">
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { useViewCreate } from '@/composables/view/ViewData'

export interface Data {
  name: string | null
  schemaId: number | null
}

const emit = defineEmits<{
  (e: 'added'): void
  (e: 'cancel'): void
}>()

const router = useRouter()

const schemaOptions = [
  { label: 'Companies with Opportunities', value: 1 },
  { label: 'Opportunities with Companies', value: 2 },
  { label: 'Opportunity Proposals with Companies and Opportunities', value: 3 },
  { label: 'Deals with Companies and Opportunities', value: 4 },
  { label: 'Users', value: 5 },
  { label: 'Companies', value: 6 }
]

const { data } = useData<Data>({
  name: null,
  schemaId: null
})

const { validation, validateAndNotify } = useValidation(data, {
  name: { required: required() },
  schemaId: { required: required() }
})

const { loading, execute } = useViewCreate(() => ({ viewSchemaId: data.value.schemaId!, name: data.value.name! }))

async function create() {
  if (await validateAndNotify()) {
    const result = await execute()
    emit('added')

    result && router.push(result.path)
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Create a view</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputText
          label="View name *"
          placeholder="My example view"
          v-model="data.name"
          :validation="validation.name"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputSelect
          label="Search target *"
          placeholder="Select search target"
          :options="schemaOptions"
          v-model="data.schemaId"
          :validation="validation.schemaId"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Create" :loading="loading" @click="create" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
