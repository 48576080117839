import { type InjectionKey, type Ref, inject, provide } from 'vue'
import { type FundWithPerformanceAdmins } from '@/graph/Fund'

interface FundShowPage {
  fund: Ref<FundWithPerformanceAdmins>
}

const fundShowPageKey = Symbol('fund-show-page') as InjectionKey<FundShowPage>

export function provideFundShowPage(
  fund: Ref<FundWithPerformanceAdmins>
): void {
  provide(fundShowPageKey, { fund })
}

export function useFundShowPage(): FundShowPage {
  const fundShowPage = inject(fundShowPageKey)
  if (!fundShowPage) {
    throw new Error('`useFundShowPage` called without a provider.')
  }
  return fundShowPage
}
