<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { type Deal } from '@/models/Deal'
import { useCanAddDeal } from '@/composables/policies/OpportunityPolicy'
import IconProgress from '../icon/IconProgress.vue'

const props = defineProps<{
  deals: Deal[]
  opportunity?: OpportunityForDetailsFrag | null
}>()

const { t } = useTrans({
  en: {
    e_opportunity_closed: 'Deal cannot be created because the opportunity is closed.',
    e_no_round: 'Deal cannot be created because the opportunity does not have round information.',
    no_items_found: 'No items found.'
  },
  ja: {
    e_opportunity_closed: 'Opportunityがクローズしているため、ディールを作成できません。',
    e_no_round: 'Opportunityにラウンド情報がないため、ディールを作成できません。',
    no_items_found: 'アイテムが見つかりません。'
  }
})

const canAddDeal = useCanAddDeal(() => props.opportunity)

const disabledAddButtonTooptip = computed(() => {
  if (canAddDeal.value.is('opportunity-closed')) {
    return { text: t.e_opportunity_closed }
  }

  if (canAddDeal.value.is('no-round')) {
    return { text: t.e_no_round }
  }

  return undefined
})

const items = computed(() => {
  return props.deals.map((deal) => ({
    icon: IconProgress,
    title: deal.title,
    link: deal.path,
    tags: [
      { text: deal.statusValue, color: getStatusColor(deal) },
      { text: `Created at ${deal.createdAt!.format('YYYY/MM/DD')}` }
    ]
  }))
})

function getStatusColor(deal: Deal) {
  if (deal.isInvested()) {
    return 'success'
  }

  if (deal.isDropped()) {
    return 'mute'
  }

  return 'info'
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            Deals
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="opportunity"
              :icon="IconPlus"
              :href="canAddDeal.ok ? `/opportunities/${opportunity.id}/deals/create` : undefined"
              :disabled="!canAddDeal.ok"
              :tooltip="disabledAddButtonTooptip"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock>
      <DCardList>
        <template v-if="items.length">
          <DCardListItem
            v-for="(item, index) in items"
            :key="index"
            :icon="item.icon"
            :title="item.title"
            :link="item.link"
            :tags="item.tags"
          />
        </template>

        <template v-else>
          <DCardListEmpty>
            {{ t.no_items_found }}
          </DCardListEmpty>
        </template>
      </DCardList>
    </SCardBlock>
  </SCard>
</template>
