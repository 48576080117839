import { Fund } from '@/models/Fund'
import { FundRequest } from '@/requests/FundRequest'
import { type Query, useQuery } from '../Api'

export type FundCollection = Query<Fund[]>
export type FundItem = Query<Fund>

export function useFundCollection(): FundCollection {
  return useQuery(async () => {
    const res = await new FundRequest().fetchAll()

    return res.data.funds.map((f) => new Fund(f))
  })
}

export function useFundAvailableCollection(): FundCollection {
  return useQuery(async () => {
    const res = await new FundRequest().fetchAll()

    return res.data.funds
      .map((f) => new Fund(f))
      .filter((f) => f.isAvailable())
  })
}
