<script setup lang="ts">
import { type Avatar } from 'sefirot/components/SDescAvatar.vue'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { type Opportunity } from '@/models/Opportunity'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  opportunity: Opportunity
}>()

const userOps = useUserOps()

const updatedByAvatar = computed<Avatar>(() => ({
  avatar: userOps.avatarPath(props.opportunity.updatedBy as UserFrag),
  name: userOps.name(props.opportunity.updatedBy as UserFrag)
}))

const createdByAvatar = computed<Avatar>(() => ({
  avatar: userOps.avatarPath(props.opportunity.createdBy as UserFrag),
  name: userOps.name(props.opportunity.createdBy as UserFrag)
}))
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            System information
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel value="Created by" />
          <SDescAvatar :avatar="createdByAvatar" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Created at" />
          <SDescDay :value="opportunity.createdAt" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Updated by" />
          <SDescAvatar :avatar="updatedByAvatar" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Updated at" />
          <SDescDay :value="opportunity.updatedAt" format="YYYY-MM-DD HH:mm" />
        </SDescItem>
      </SDesc>
    </SCardBlock>
  </SCard>
</template>
