import { type CompanyVecJapanRegion } from './CompanyVecJapanRegion'
import { CompanyVecSector } from './CompanyVecSector'
import { type Day, Model } from './Model'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export class CompanyVec extends Model implements AccessControlled {
  subject = AccessResource.companyVec

  companyId: number | null
  permittedActions: AccessControlled['permittedActions']
  sector: CompanyVecSector | null
  japanRegion: CompanyVecJapanRegion | null
  isIoT!: boolean

  updatedBy: User
  updatedAt: Day

  constructor(data: Record<string, any>) {
    super()
    this.companyId = data.companyId ?? null
    this.permittedActions = data.permittedActions ?? []
    this.sector = this.hasOne(CompanyVecSector, data.sector)
    this.japanRegion = this.hasOne(CompanyVecSector, data.japanRegion)
    this.isIoT = !!data.isIoT

    this.updatedBy = this.hasOne(User, data.updatedBy as object)
    this.updatedAt = this.day(data.updatedAt)
  }
}
