<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Business plans',
    info: 'The current and future plan on how to expand businesses.',
    ph: 'Expand to 5 more cities in 20XX. Launch a new product in 20XXQ4. etc.'
  },
  ja: {
    label: 'Business plans',
    info: '今、そしてこれからどういった事業を計画しているか。',
    ph: '20XX年に5都市に拡大する。20XXQ4に新商品を発売する。など。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
