<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxLength, required } from 'sefirot/validation/rules'
import { type CompanyName } from '@/models/CompanyName'
import { useCompanyUpdateName } from '@/composables/company/CompanyData'

export interface Data {
  displayName: string | null
  officialName: string | null
  englishName: string | null
  until: Day | null
}

const props = defineProps<{
  name: CompanyName
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data } = useData<Data>({
  displayName: props.name.displayName!,
  officialName: props.name.officialNameLocal!,
  englishName: props.name.officialNameEn!,
  until: props.name.until
})

const { validation, validateAndNotify } = useValidation(data, () => {
  const r: Record<string, any> = {
    displayName: {
      required: required(),
      maxLength: maxLength(100)
    },
    officialName: {
      required: required(),
      maxLength: maxLength(100)
    },
    englishName: {
      required: required(),
      maxLength: maxLength(100)
    }
  }

  if (props.name.until) {
    r.until = { required: required() }
  }

  return r
})

const { execute, loading } = useCompanyUpdateName(props.name.id!, () => ({
  displayName: data.value.displayName!,
  officialNameLocal: data.value.officialName!,
  officialNameEn: data.value.englishName!,
  until: data.value.until ? data.value.until.format('YYYY-MM-DD') : null
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update company name</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="3">
        <SInputText
          label="Display name *"
          placeholder="Awesome Company"
          v-model="data.displayName"
          :validation="validation.displayName"
        />
      </DFormGridItem>
      <DFormGridItem span="3" />
      <DFormGridItem span="3">
        <SInputText
          label="Official name *"
          placeholder="Awesome Company, Inc."
          v-model="data.officialName"
          :validation="validation.officialName"
        />
      </DFormGridItem>
      <DFormGridItem span="3">
        <SInputText
          label="English name *"
          placeholder="English Company"
          v-model="data.englishName"
          :validation="validation.englishName"
        />
      </DFormGridItem>
      <DFormGridItem v-if="name.until" span="4">
        <SInputDate
          label="Until"
          v-model="data.until"
          :validation="validation.until"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
