import { type View } from '@/models/View'
import { type Authorized, type Resource, usePolicy } from '../Policy'

export interface ViewPolicy {
  canAdminExport: Authorized
  canCreate: Authorized
  canDelete: Authorized
  canExport: Authorized
  canShare: Authorized
}

export function useViewPolicy(view?: Resource<View>): ViewPolicy {
  const { define, defineWhen } = usePolicy()

  const canAdminExport = define((u) => u.can('adminExportAuthority', 'view'))
  const canCreate = define((u) => u.hasRoleGod())
  const canDelete = define((u) => u.hasRoleGod())
  const canExport = defineWhen(view, (u, v) => u.can('export', 'view', v))
  const canShare = define((u) => u.hasRoleGod())

  return {
    canAdminExport,
    canCreate,
    canDelete,
    canExport,
    canShare
  }
}
