<script setup lang="ts">
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SInputSelect, { type Value as SelectValue } from 'sefirot/components/SInputSelect.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type CurrencyFrag } from '@/graphql'
import { SecurityKind } from '@/models/Deal'
import DInputMoney from '../DInputMoney.vue'

export interface Security {
  kind: SecurityKind | null
  name: string | null
  plannedTotalPrice: string | null
  liquidationPreferenceMultiple: null
  liquidationPreferencePatternId: null
  antiDilutionProvisionId: null
  annualInterest: null
  repaymentDate: null
  repaymentRight: null
  discount: null
  valuationCap: null
  eligibleFinancingAmount: null
}

const props = defineProps<{
  index: number
  currency: CurrencyFrag
  security: Security
  removable: boolean
}>()

const emit = defineEmits<{
  update: [Security]
  remove: []
}>()

const { t } = useTrans({
  en: {
    security_title: 'Security',
    i_security_kind_label: 'Security kind',
    i_security_name_label: 'Security name',
    i_security_name_info: 'Enter the name of the securities as stated in the contract.',
    i_security_name_ph: 'Enter the actual security name',
    i_total_amount_label: 'Total amount *',
    i_total_amount_info: 'The currency is linked to the currency defined in the round details.'
  },
  ja: {
    security_title: '証券',
    i_security_kind_label: '証券の種類',
    i_security_name_label: '証券の名称',
    i_security_name_info: '契約書に記載されている証券の名称を入力してください。',
    i_security_name_ph: '実際の証券の名称を記載してください。',
    i_total_amount_label: '合計金額 *',
    i_total_amount_info: '通貨はラウンド詳細で定義された通貨とリンクされています。'
  }
})

const { validation } = useValidation(computed(() => props.security), {
  name: { maxLength: maxLength(255) },
  plannedTotalPrice: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) }
})

const securityOptions = [
  { label: 'TBD', value: 'TBD' },
  { label: 'Common Stock', value: SecurityKind.CommonStock },
  { label: 'Preferred Stock', value: SecurityKind.ClassifiedStock },
  { label: 'SAFE', value: SecurityKind.Safe },
  { label: 'J-KISS', value: SecurityKind.JKiss },
  { label: 'Warrant', value: SecurityKind.Warrant },
  { label: 'Convertible Bond', value: SecurityKind.ConvertibleBond },
  { label: 'Convertible Note', value: SecurityKind.ConvertibleNote },
  { label: 'Convertible Load', value: SecurityKind.ConvertibleLoan }
]

function update<K extends keyof Security>(key: K, value: Security[K]) {
  emit('update', { ...props.security, [key]: value })
}

function updateKind(value: SelectValue) {
  const v = (value === 'TBD') ? null : value as SecurityKind | null
  emit('update', { ...props.security, kind: v })
}
</script>

<template>
  <SCardBlock class="DealFormCreateSecuritiesItem">
    <div class="header">
      <div class="header-title">
        {{ t.security_title }} #{{ index }}
      </div>
      <div v-if="removable" class="actions">
        <SButton
          type="text"
          size="mini"
          mode="danger"
          :icon="IconTrash"
          label="Remove"
          @click="$emit('remove')"
        />
      </div>
    </div>

    <div class="form">
      <div class="input kind">
        <SInputSelect
          :label="t.i_security_kind_label"
          :options="securityOptions"
          :value="security.kind ?? 'TBD'"
          @change="v => updateKind(v)"
        />
      </div>
      <div class="input name">
        <SInputText
          :label="t.i_security_name_label"
          :info="t.i_security_name_info"
          :placeholder="t.i_security_name_ph"
          :model-value="security.name"
          :validation="validation.name"
          @update:model-value="v => update('name', v)"
        />
      </div>
      <div class="input amount">
        <DInputMoney
          :label="t.i_total_amount_label"
          placeholder="10,000,000"
          :info="t.i_total_amount_info"
          :currency="currency"
          :model-value="security.plannedTotalPrice"
          :validation="validation.plannedTotalPrice"
          @update:model-value="v => update('plannedTotalPrice', v)"
        />
      </div>
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.DealFormCreateSecuritiesItem {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.header-title {
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}

.form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.kind   { grid-column: span 2; }
.name   { grid-column: span 4; }
.amount { grid-column: span 6; }
</style>
