<script setup lang="ts">
import SState from 'sefirot/components/SState.vue'
import { type SurveyAndAllFrag } from '@/graphql'
import { useSurveyOps } from '@/composables/ops/SurveyOps'

defineProps<{
  survey: SurveyAndAllFrag
}>()

const surveyOps = useSurveyOps()
</script>

<template>
  <SCardBlock class="SurveyCardHeader">
    <div class="id">
      {{ surveyOps.displayId(survey) }}
    </div>
    <div class="state">
      <SState
        :mode="surveyOps.statusMode(survey)"
        :label="surveyOps.statusText(survey)"
      />
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.SurveyCardHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
}

.id {
  font-size: 20px;
  font-weight: 500;
}
</style>
