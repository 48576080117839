<script setup lang="ts">
import IconListBullets from '~icons/ph/list-bullets'
import IconNote from '~icons/ph/note'
import IconNotePencil from '~icons/ph/note-pencil'
import { type CompanyParticipationFrag } from '@/graphql'
import { type Company } from '@/models/Company'
import { useState } from '@/composables/State'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import CompanyCardAssigneesHistory from './CompanyCardAssigneesHistory.vue'
import CompanyFormUpdateAssignees from './CompanyFormUpdateAssignees.vue'
import CompanyFormUpdateParticipantChangeNoteAsync from './CompanyFormUpdateParticipantChangeNoteAsync.vue'

const props = withDefaults(defineProps<{
  company: Company
  title?: string
  editable?: boolean
}>(), {
  title: 'Assignees',
  editable: true
})

const emit = defineEmits<{
  updated: []
}>()

const { canUpdate, canUpdateParticipantChangeNote } = useCompanyPolicy(props.company)

const { data: modal, set, clear } = useState<'note' | 'history' | 'edit'>()

function onUpdated() {
  clear()
  emit('updated')
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            {{ title }}
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="editable"
              :icon="IconListBullets"
              @click="set('history')"
            />
            <SControlActionBarButton
              v-if="canUpdateParticipantChangeNote"
              :icon="IconNote"
              @click="set('note')"
            />
            <SControlActionBarButton
              v-if="canUpdate"
              :icon="IconNotePencil"
              @click="set('edit')"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock>
      <DCardInfo>
        <DCardInfoItem>
          <DCardInfoLabel text="Primary in-charge" />
          <DCardInfoUsers :users="company.primaryInCharge" />
        </DCardInfoItem>
        <DCardInfoItem>
          <DCardInfoLabel text="Deputy in-charge" />
          <DCardInfoUsers :users="company.deputyInCharge" />
        </DCardInfoItem>
        <DCardInfoItem>
          <DCardInfoLabel text="Participants" />
          <DCardInfoUsers :users="company.participants" />
        </DCardInfoItem>
      </DCardInfo>
    </SCardBlock>

    <SModal :open="modal === 'note'">
      <CompanyFormUpdateParticipantChangeNoteAsync
        :company-id="company.id!"
        @updated="onUpdated"
        @cancel="clear"
      />
    </SModal>

    <SModal :open="modal === 'history'">
      <CompanyCardAssigneesHistory
        :company-participations="company.participations as unknown as CompanyParticipationFrag[]"
        @close="clear"
      />
    </SModal>

    <SModal :open="modal === 'edit'">
      <CompanyFormUpdateAssignees
        :company="company"
        @updated="onUpdated"
        @cancel="clear"
      />
    </SModal>
  </SCard>
</template>
