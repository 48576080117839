import { Model } from './Model'

export interface CreatedFundClosingCheckListInput {
  id: number
}

export class CreatedFundClosingCheckList extends Model {
  id: number

  constructor(input: CreatedFundClosingCheckListInput) {
    super()
    this.id = input.id
  }

  path() {
    return `/fund-closing-check-lists/${this.id}`
  }
}
