import 'sefirot/styles/bootstrap.css'
import '@/styles/variables.css'
import '@/styles/base.css'

import { createApp } from 'vue'
import App from './App.vue'
import { components } from './plugins/components'
import { pinia } from './plugins/pinia'
import { router } from './plugins/router'
import { sentry } from './plugins/sentry'

const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(sentry)
app.use(components)

app.mount('#app')
