<script setup lang="ts">
import { computed } from 'vue'
import { useDuration } from '@/composables/Duration'
import { useItem } from '@/composables/repos/StatSourcingRankingRepo'
import DashboardOverviewCard from './DashboardOverviewCard.vue'
import DashboardOverviewSourcingRankingMonth from './DashboardOverviewSourcingRankingMonth.vue'

const { from, hasNext, prev, next } = useDuration('month')

const lastMonth = computed(() => from.value.add(-1, 'month'))

const { data: statLast, loading: loadingLast } = useItem(lastMonth)
const { data: statCurrent, loading: loadingCurrent } = useItem(from)
</script>

<template>
  <DashboardOverviewCard
    title="Sourcing ranking by months"
    :has-next="hasNext"
    @prev="prev"
    @next="next"
  >
    <div class="grid">
      <DashboardOverviewSourcingRankingMonth
        class="item"
        :date="lastMonth"
        :sourcing-rankings="statLast"
        :loading="loadingLast"
      />
      <DashboardOverviewSourcingRankingMonth
        class="item"
        :date="from"
        :sourcing-rankings="statCurrent"
        :loading="loadingCurrent"
      />
    </div>
  </DashboardOverviewCard>
</template>

<style scoped lang="postcss">
.grid {
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2));
}

.item {
  padding: 16px;
  max-height: 300px;
  overflow: auto;
}

.item + .item {
  border-left: 1px solid var(--c-divider-light);
}
</style>
