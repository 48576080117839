<script setup lang="ts">
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type DealWithIdFrag, type DealWithInvestmentInfoFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useUpdateDealInvestment, useUpdateDealSchedule } from '@/composables/repos/DealRepo'

const props = defineProps<{
  deal: DealWithIdFrag & DealWithInvestmentInfoFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { execute: updateSchedule, loading: updatingSchedule } = useUpdateDealSchedule()
const { execute: updateInvestment, loading: updatingInvestment } = useUpdateDealInvestment()

const dealOps = useDealOps()

const loading = computed(() => {
  return updatingSchedule.value || updatingInvestment.value
})

const { data } = useData({
  deadline: dealOps.deadline(props.deal),
  lead: props.deal.lead
})

const { validation, validateAndNotify } = useValidation(data, {
  deadline: { required: required() },
  lead: { required: required() }
})

const leadOptions = [
  { label: 'Lead', value: true },
  { label: 'Follow', value: false }
]

async function update() {
  if (await validateAndNotify()) {
    await Promise.all([
      updateSchedule(props.deal.id, {
        deadline: data.value.deadline!.format('YYYY-MM-DD')
      }),
      updateInvestment(props.deal.id, {
        lead: data.value.lead
      })
    ])

    emit('updated')
  }
}
</script>

<template>
  <DForm size="small">
    <DFormTitle>Update investment</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputDate
          label="Deadline *"
          help="The deadline refers to the expected date of payment to the startup."
          v-model="data.deadline"
          block
          :validation="validation.deadline"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputRadios
          label="Lead or Follow"
          :options="leadOptions"
          nullable
          v-model="data.lead"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
