<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { type SurveyFrag } from '@/graphql'

defineProps<{
  survey: SurveyFrag
}>()

const { t } = useTrans({
  en: {
    company: 'Company',
    name: 'Name',
    title: 'Title',
    email: 'Email',
    funds: 'Accepted funds'
  },
  ja: {
    company: '会社名',
    name: '氏名',
    title: '役職',
    email: 'Email',
    funds: '公開対象ファンド'
  }
})
</script>

<template>
  <SCardBlock class="SurveyCardData s-p-24">
    <SDesc cols="2" gap="24">
      <SDescItem span="1">
        <SDescLabel>{{ t.company }}</SDescLabel>
        <SDescText>{{ survey.companyName }}</SDescText>
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel>{{ t.email }}</SDescLabel>
        <SDescText>{{ survey.responderEmail }}</SDescText>
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel>{{ t.name }}</SDescLabel>
        <SDescText>{{ survey.responderName }}</SDescText>
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel>{{ t.title }}</SDescLabel>
        <SDescText>{{ survey.responderTitle }}</SDescText>
      </SDescItem>
      <SDescItem span="2">
        <SDescLabel>{{ t.funds }}</SDescLabel>
        <SDescPill :pill="survey.fundGroups.map((fg) => ({ label: fg.name }))" />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.SurveyCardData {
  border-bottom: 1px solid var(--c-divider);
}
</style>
