<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type BalanceConfirmationMail } from '@/graph/BalanceConfirmation'

const props = defineProps<{
  balanceConfirmationMail: BalanceConfirmationMail
}>()

const { t } = useTrans({
  en: {
    zip_code: 'ZIP Code',
    address: 'Address',
    address_house_number: 'House number',
    destinations: 'Recipients',
    destinations_name: 'Name',
    destinations_title: 'Title',
    notes: 'Notes'
  },
  ja: {
    zip_code: '郵便番号',
    address: '住所',
    address_house_number: '建物名',
    destinations: '宛先',
    destinations_name: '氏名',
    destinations_title: '役職',
    notes: '備考'
  }
})

const table = useTable({
  records: computed(() => props.balanceConfirmationMail.destinations),
  borderless: true,
  orders: [
    'name',
    'title',
    'empty'
  ],
  columns: {
    name: {
      label: t.destinations_name,
      cell: (_, d) => ({
        type: 'text',
        value: d.name
      })
    },
    title: {
      label: t.destinations_title,
      grow: true,
      cell: (_, d) => ({
        type: 'text',
        value: d.title
      })
    },
    empty: {
      cell: { type: 'empty' }
    }
  }
})
</script>

<template>
  <SCardBlock class="CompanyPortfolioDataDescBalanceConfirmationEmailContent">
    <SDesc gap="16">
      <SDescItem>
        <SDescLabel>{{ t.zip_code }}</SDescLabel>
        <SDescText>{{ balanceConfirmationMail.zipCode }}</SDescText>
      </SDescItem>
      <SDescItem>
        <SDescLabel>{{ t.address }}</SDescLabel>
        <SDescText>{{ balanceConfirmationMail.address }}</SDescText>
      </SDescItem>
      <SDescItem>
        <SDescLabel>{{ t.address_house_number }}</SDescLabel>
        <SDescText>{{ balanceConfirmationMail.addressHouseNumber }}</SDescText>
      </SDescItem>
      <SDescItem :style="{ borderBottom: 0 }">
        <SDescLabel>{{ t.destinations }}</SDescLabel>
        <SCard v-if="balanceConfirmationMail.destinations.length" class="destinations">
          <SCardBlock>
            <STable :options="table" />
          </SCardBlock>
        </SCard>
        <SDescEmpty v-else />
      </SDescItem>
      <SDescItem>
        <SDescLabel>{{ t.notes }}</SDescLabel>
        <SDescText>{{ balanceConfirmationMail.notes }}</SDescText>
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.CompanyPortfolioDataDescBalanceConfirmationEmailContent {
  padding: 16px;
}

.destinations {
  margin-top: 4px;
  overflow: hidden;
}

.destinations :deep(.col-name)  { --table-col-width: 160px; }
.destinations :deep(.col-title) { --table-col-width: 256px; }
.destinations :deep(.col-empty) { --table-col-width: 40px; }
</style>
