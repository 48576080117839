<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import {
  type DealWithFundFrag,
  type DealWithIdFrag,
  type DealWithInvestmentInfoFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealPolicy } from '@/composables/policies/DealPolicy'
import DealFormUpdateInvestment from './DealFormUpdateInvestment.vue'

export type Deal =
  & DealWithIdFrag
  & DealWithFundFrag
  & DealWithInvestmentInfoFrag
  & WithPermissionFrag

const props = defineProps<{
  deal: Deal
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const { canUpdate } = useDealPolicy(() => props.deal)
const dealOps = useDealOps()
const { state: modal, on, off } = usePower(false)

const actions = computedWhen(canUpdate, () => {
  return [{ icon: 'edit', onClick: on }]
}, [])
</script>

<template>
  <DCard title="Investment" :actions="actions">
    <DCardGrid>
      <DCardGridItem span="1">
        <DCardGridLabel text="Fund" />
        <DCardGridText :text="deal.fund.nameAbbreviated" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Deadline" />
        <DCardGridText :text="dealOps.deadline(deal)?.format('YYYY/MM/DD')" />
      </DCardGridItem>
      <DCardGridItem span="2">
        <DCardGridLabel text="Lead or Follow" />
        <DCardGridText :text="dealOps.leadText(deal)" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Est. Deal amount" />
        <DCardGridMoney :money="dealOps.investmentAmount(deal)" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Invested amount" />
        <DCardGridMoney :money="dealOps.investedAmount(deal)" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Invested amount (JPY)" />
        <DCardGridMoney :money="dealOps.investedAmountFmc(deal)" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Book value" />
        <DCardGridMoney :money="dealOps.bookValue(deal)" />
      </DCardGridItem>
    </DCardGrid>

    <SModal :open="modal">
      <DealFormUpdateInvestment
        :deal="deal"
        @updated="off(() => $emit('updated'))"
        @cancel="off"
      />
    </SModal>
  </DCard>
</template>

<style scoped lang="postcss">
.empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.empty-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
