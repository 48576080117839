import { orderBy } from 'lodash-es'
import { type CompanyMarketingModel } from '@/graphql'
import { CompanyMarketingModelRequest } from '@/requests/CompanyMarketingModelRequest'
import { type Query, useQuery } from '../Api'

export type CompanyMarketingModelList = Query<CompanyMarketingModel[], []>

export function useCompanyMarketingModelList(): CompanyMarketingModelList {
  return useQuery(async () => {
    const { data: { companyMarketingModels } } = await new CompanyMarketingModelRequest().fetchAll()

    return orderBy(companyMarketingModels, (c) => c.rank)
  })
}
