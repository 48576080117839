<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { decimal, maxLength, maxValue, required } from 'sefirot/validation/rules'
import { computed, watch } from 'vue'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'
import { useDealWithRound, useWrapupRound } from '@/composables/repos/DealRepo'
import { useEnabledRoundNameList } from '@/composables/repos/RoundNameRepo'
import DInputMoney from '../DInputMoney.vue'

const props = defineProps<{
  id: number
}>()

const emit = defineEmits<{
  back: []
  saved: []
}>()

const { t } = useTrans({
  en: {
    i_round_name_label: 'Round name',
    i_round_name_ph: 'Select round name',
    i_name_suffix_label: 'Name suffix',
    i_name_suffix_ph: '’ / 1 / 2nd',
    i_currency_label: 'Currency',
    i_currency_ph: 'Select currency',
    i_total_amount_label: 'Total amount *',
    i_total_amount_ph: '500,000,000',
    i_pre_valuation_label: 'Pre valuation *',
    i_pre_valuation_ph: '2,000,000,000',
    i_post_valuation_label: 'Post valuation *',
    i_post_valuation_ph: '2,500,000,000',
    i_pre_issued_shares_label: 'Pre issued shares *',
    i_pre_issued_shares_ph: 'Common 10,000\nSeries Seed Preferred 2,234\nSeries Seed 2 Preferred 9,000\nSeries A Preferred 5,000',
    i_participating_investors_label: 'Participating investors *',
    i_participating_investors_ph: 'List investors involved in this round.',
    back: 'Back',
    save: 'Save & Next'
  },
  ja: {
    i_round_name_label: 'ラウンド',
    i_round_name_ph: 'ラウンドを選択してください',
    i_name_suffix_label: 'ラウンドのサフィックス',
    i_name_suffix_ph: '’ / 1 / 2nd',
    i_currency_label: '通貨',
    i_currency_ph: '通貨を選択してください',
    i_total_amount_label: 'ラウンドの調達総額 *',
    i_total_amount_ph: '500,000,000',
    i_pre_valuation_label: 'Pre valuation *',
    i_pre_valuation_ph: '2,000,000,000',
    i_post_valuation_label: 'Post valuation *',
    i_post_valuation_ph: '2,500,000,000',
    i_pre_issued_shares_label: '発行済株式総数 *',
    i_pre_issued_shares_ph: 'Common 10,000\nSeries Seed Preferred 2,234\nSeries Seed 2 Preferred 9,000\nSeries A Preferred 5,000',
    i_participating_investors_label: '参加投資家 *',
    i_participating_investors_ph: 'このラウンドに参加する投資家をリストしてください。',
    back: '戻る',
    save: '保存して次へ'
  }
})

const { data: deal } = useDealWithRound(props.id)
const { data: roundNames } = useEnabledRoundNameList()
const { data: currencies } = useCurrencyList()

const { execute, loading } = useWrapupRound()

const roundOps = useRoundOps()

const { data } = useData({
  roundName: null as number | null,
  nameSuffix: null as string | null,
  currency: null as number | null,
  totalAmount: null as string | null,
  preValuation: null as string | null,
  postValuation: null as string | null,
  preIssuedShares: null as string | null,
  participatingInvestors: null as string | null
})

const { validation, validate, validateAndNotify } = useValidation(data, {
  roundName: { required: required() },
  nameSuffix: { maxLength: maxLength(10) },
  currency: { required: required() },
  totalAmount: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  preValuation: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  postValuation: { required: required(), decimal: decimal(), maxValue: maxValue(Number.MAX_SAFE_INTEGER) },
  preIssuedShares: { required: required(), maxLength: maxLength(500) },
  participatingInvestors: { required: required(), maxLength: maxLength(500) }
})

const currencyModel = computed(() => {
  return currencies.value?.find((c) => c.id === data.value.currency) ?? null
})

const roundOptions = computed<Option[]>(() => {
  return roundNames.value?.map((name) => {
    return { type: 'text', label: name.name!, value: name.id! }
  }) ?? []
})

const currencyOptions = computed<Option[]>(() => {
  return currencies.value?.map((c) => {
    return { type: 'text', label: `${c.symbol} ${c.name}`, value: c.id! }
  }) ?? []
})

watch(deal, (deal) => {
  data.value.roundName = deal!.round.nameId
  data.value.nameSuffix = deal!.round.nameSuffix
  data.value.currency = roundOps.currency(deal!.round).id
  data.value.totalAmount = deal!.round.totalAmount.value
  data.value.preValuation = deal!.round.preValuation?.value ?? null
  data.value.postValuation = deal!.round.postValuation?.value ?? null
  data.value.preIssuedShares = deal!.round.preIssuedShares
  data.value.participatingInvestors = deal!.round.participatingInvestors
  validate()
})

async function save() {
  if (await validateAndNotify()) {
    await execute(props.id, {
      name: data.value.roundName!,
      nameSuffix: data.value.nameSuffix,
      totalAmount: toMoneyInput(data.value.totalAmount!),
      preValuation: toMoneyInput(data.value.preValuation!),
      postValuation: toMoneyInput(data.value.postValuation!),
      preIssuedShares: data.value.preIssuedShares!,
      participatingInvestors: data.value.participatingInvestors!
    })
    emit('saved')
  }
}

function toMoneyInput(value: string) {
  return {
    currencyId: data.value.currency!,
    value
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-48">
      <SDoc>
        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Finalize round information</h2>
              <p>A deal report requires the following round information to be present. Fill in any missing information and also check if all fields are up to date.</p>
            </STrans>
            <STrans lang="ja">
              <h2>ラウンド情報のファイナライズ</h2>
              <p>投資報告書を作成するには以下のラウンド情報が必要です。不足している情報を入力し、すべての項目が最新であることを確認してください。</p>
            </STrans>
          </SContent>
          <SGrid gap="24" cols="6">
            <SGridItem span="2">
              <SInputDropdown
                :label="t.i_round_name_label"
                :placeholder="t.i_round_name_ph"
                :options="roundOptions"
                v-model="data.roundName"
                :validation="validation.roundName"
              />
            </SGridItem>

            <SGridItem span="2">
              <SInputText
                :label="t.i_name_suffix_label"
                :placeholder="t.i_name_suffix_ph"
                v-model="data.nameSuffix"
                :validation="validation.nameSuffix"
              />
            </SGridItem>
            <SGridItem span="2" />

            <SGridItem span="2">
              <SInputDropdown
                :label="t.i_currency_label"
                :placeholder="t.i_currency_ph"
                :options="currencyOptions"
                v-model="data.currency"
                :validation="validation.currency"
              />
            </SGridItem>
            <SGridItem span="4" />

            <SGridItem span="3">
              <DInputMoney
                :label="t.i_total_amount_label"
                :placeholder="t.i_total_amount_ph"
                :currency="currencyModel"
                v-model="data.totalAmount"
                :validation="validation.totalAmount"
              />
            </SGridItem>
            <SGridItem span="3" />

            <SGridItem span="3">
              <DInputMoney
                :label="t.i_pre_valuation_label"
                :placeholder="t.i_pre_valuation_ph"
                :currency="currencyModel"
                v-model="data.preValuation"
                :validation="validation.preValuation"
              />
            </SGridItem>

            <SGridItem span="3">
              <DInputMoney
                :label="t.i_post_valuation_label"
                :placeholder="t.i_post_valuation_ph"
                :currency="currencyModel"
                v-model="data.postValuation"
                :validation="validation.postValuation"
              />
            </SGridItem>

            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_pre_issued_shares_label"
                :placeholder="t.i_pre_issued_shares_ph"
                :rows="5"
                v-model="data.preIssuedShares"
                :validation="validation.preIssuedShares"
              />
            </SGridItem>

            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_participating_investors_label"
                :placeholder="t.i_participating_investors_ph"
                :rows="5"
                v-model="data.participatingInvestors"
                :validation="validation.participatingInvestors"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.back"
            :disabled="loading"
            @click="$emit('back')"
          />
          <SControlButton
            mode="info"
            :label="t.save"
            :loading="loading"
            @click="save"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
