<script setup lang="ts">
import { xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { OpportunityOrderField, OrderDirection } from '@/graphql'
import { Opportunity, type OpportunityStatus } from '@/models/Opportunity'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useOpportunityPage } from '@/composables/repos/OpportunityRepo'
import { useRoundNameList } from '@/composables/repos/RoundNameRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'
import IconTelescope from '@/components/icon/IconTelescope.vue'
import DInputSearch from '../DInputSearch.vue'

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    query: null as string | null,
    status: [] as OpportunityStatus[],
    assignee: [] as number[],
    roundName: [] as number[]
  },
  orderBy: {
    field: OpportunityOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': Number,
    'condition.query': (v) => (v === '' || v === null) ? null : v,
    'condition.assignee': (v) => v.map(Number),
    'condition.roundName': (v) => v.map(Number)
  },
  exclude: ['page.perPage']
})

const { data: oppos, loading: loadingOppos } = useOpportunityPage(options)

const { data: rounds } = useRoundNameList()
const { data: users } = useActiveUserList()

const userOps = useUserOps()
const companyOps = useCompanyOps()
const oppoOps = useOpportunityOps()
const roundOps = useRoundOps()

const table = useTable({
  records: computed(() => oppos.value?.items ?? []),
  loading: loadingOppos,
  borderless: true,
  orders: [
    'name',
    'status',
    'round',
    'assignee',
    'totalAmount',
    'expiresAt',
    'createdAt',
    'updatedAt',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Name',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(OpportunityOrderField.Name, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(OpportunityOrderField.Name, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, oppo) => ({
        type: 'text',
        icon: IconTelescope,
        value: `${companyOps.name(oppo.company)} / ${oppoOps.title(oppo)}`,
        link: oppoOps.path(oppo),
        color: oppoOps.expiresAtModeForTitle(oppo)
      })
    },
    status: {
      label: 'Status',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: false,
          selected: computed(() => options.value.condition.status),
          options: computed(() => {
            return Opportunity.statusEntries().map(([value, label]) => {
              return { label, value }
            })
          }),
          onClick(value) {
            updateFilter(() => {
              options.value.condition.status = xor(options.value.condition.status, [value])
            })
          }
        }
      ]),
      cell: (_, oppo) => ({
        type: 'state',
        mode: oppoOps.statusMode(oppo),
        label: oppoOps.statusText(oppo)
      })
    },
    round: {
      label: 'Round',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.roundName),
          options: computed(() => rounds.value?.map((rn) => ({ label: rn.name, value: rn.id! })) ?? []),
          onClick(value) {
            updateFilter(() => {
              options.value.condition.roundName = xor(options.value.condition.roundName, [value])
            })
          }
        }
      ]),
      cell: (_, oppo) => ({
        type: 'text',
        value: oppoOps.roundName(oppo)
      })
    },
    assignee: {
      label: 'Assignee',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.assignee),
          options: computed(() => userOps.createDropdownOptions(users.value)),
          onClick(value) {
            updateFilter(() => {
              options.value.condition.assignee = xor(options.value.condition.assignee, [value])
            })
          }
        }
      ]),
      cell: (_, oppo) => ({
        type: 'avatars',
        avatars: companyOps.assignees(oppo.company).map((a) => ({
          image: userOps.avatarPath(a),
          name: userOps.name1st(a)
        }))
      })
    },
    totalAmount: {
      label: 'Total amount',
      cell: (_, oppo) => ({
        type: 'text',
        value: oppo.round ? roundOps.totalAmountTextWithCurrency(oppo.round) : null
      })
    },
    expiresAt: {
      label: 'Expires',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(OpportunityOrderField.ExpiresAt, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(OpportunityOrderField.ExpiresAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, oppo) => ({
        type: 'text',
        value: oppoOps.expiresAtReadableText(oppo),
        color: oppoOps.expiresAtMode(oppo)
      })
    },
    createdAt: {
      label: 'Created at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(OpportunityOrderField.CreatedAt, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(OpportunityOrderField.CreatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.createdAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: 'Updated at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: 'Sort ascending (A...Z)', onClick: () => { updateOrder(OpportunityOrderField.UpdatedAt, OrderDirection.Asc) } },
            { label: 'Sort descending (Z...A)', onClick: () => { updateOrder(OpportunityOrderField.UpdatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, oppo) => ({
        type: 'day',
        value: oppoOps.updatedAt(oppo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function updateQuery(value: string | null) {
  options.value.page.page = 0
  options.value.condition.query = value === '' ? null : value
}

function updateFilter(fn: () => void) {
  options.value.page.page = 0
  fn()
}

function updateOrder(field: OpportunityOrderField, direction: OrderDirection) {
  options.value.page.page = 0
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}
</script>

<template>
  <div class="OpportunityCatalogAll">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                placeholder="Search opportunities"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              label="Reset filters"
              @click="reset"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="oppos?.pageInfo.total ?? 0"
              :page="oppos ? oppos.pageInfo.page + 1 : 0"
              :per-page="oppos?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.OpportunityCatalogAll {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-expiresAt .text) {
  font-feature-settings: "tnum";
}

.list :deep(.col-name)        { --table-col-width: 384px; }
.list :deep(.col-status)      { --table-col-width: 136px; }
.list :deep(.col-round)       { --table-col-width: 168px; }
.list :deep(.col-assignee)    { --table-col-width: 256px; }
.list :deep(.col-totalAmount) { --table-col-width: 192px; }
.list :deep(.col-expiresAt)   { --table-col-width: 152px; }
.list :deep(.col-createdAt)   { --table-col-width: 192px; }
.list :deep(.col-updatedAt)   { --table-col-width: 192px; }
.list :deep(.col-expiresAt)   { --table-col-width: 144px; }
.list :deep(.col-spacer)      { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
