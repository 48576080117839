import { onMounted, onUnmounted } from 'vue'

export type Channel = 'company-name-updated' | 'company-exit-status-updated' | 'company-approachable-status-updated' | 'round-name-updated' | 'deal-status-updated'

export function useListener(channel: Channel, fn: () => void) {
  onMounted(() => {
    document.addEventListener(channel, fn)
  })

  onUnmounted(() => {
    document.removeEventListener(channel, fn)
  })
}

export function useBroadcast(channel: Channel) {
  function broadcast() {
    const event = new Event(channel)
    document.dispatchEvent(event)
  }

  return {
    broadcast
  }
}
