<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type CompanySupportLogWithMembers } from '@/graph/Company'
import { type CreateCompanySupportLogInputMember, useCreateCompanySupportLog } from '@/composables/repos/CompanySupportLogRepo'
import CompanySupportLogInputMembers, { type Data as MembersData } from './CompanySupportLogInputMembers.vue'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  added: [supportLog: CompanySupportLogWithMembers]
  cancel: []
}>()

const { t } = useTrans({
  en: {
    i_title_label: 'Title *',
    i_title_placeholder: 'e.g. Sales support, PR support',
    i_description_label: 'Description *',
    i_description_note: 'Max 4,000 characters',
    i_description_placeholder: 'Explain the supports in detail...',
    cancel: 'Cancel',
    add: 'Add support log'
  },
  ja: {
    i_title_label: 'タイトル *',
    i_title_placeholder: '例：営業支援、PR支援',
    i_description_label: '支援内容 *',
    i_description_note: '最大4,000文字',
    i_description_placeholder: '支援内容を詳細に記載してください。',
    cancel: 'キャンセル',
    add: '支援実績を登録'
  }
})

const { execute, loading } = useCreateCompanySupportLog()

const { data } = useData({
  title: null as string | null,
  description: null as string | null,
  members: [{ userId: null, weight: null }] as MembersData[]
})

const { validation, validateAndNotify } = useValidation(data, {
  title: {
    required: required(),
    maxLength: maxLength(255)
  },
  description: {
    required: required(),
    maxLength: maxLength(4000)
  }
})

async function add() {
  if (await validateAndNotify()) {
    const res = await execute({
      companyId: props.companyId,
      title: data.value.title!,
      description: data.value.description!,
      members: data.value.members as CreateCompanySupportLogInputMember[]
    })
    emit('added', res)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Create support log</STrans>
            <STrans lang="ja">支援実績を登録する</STrans>
          </h2>
        </SContent>
        <SInputText
          :label="t.i_title_label"
          :placeholder="t.i_title_placeholder"
          v-model="data.title"
          :validation="validation.title"
        />
        <CompanySupportLogInputMembers
          v-model="data.members"
        />
        <SInputTextarea
          :label="t.i_description_label"
          :note="t.i_description_note"
          :placeholder="t.i_description_placeholder"
          :rows="10"
          v-model="data.description"
          :validation="validation.description"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.add"
            :loading="loading"
            @click="add"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
