<script setup lang="ts">
import { computedArray } from 'sefirot/composables/Utils'
import { useCanViewCapitalistActivities } from '@/composables/policies/DashboardPolicy'

const canViewCapitalistActivities = useCanViewCapitalistActivities()

const menu = computedArray((items) => {
  items.push({ label: 'Overview', link: '/dashboard/overview' })
  items.push({ label: 'Company news', link: '/dashboard/company-news' })
  items.push({ label: 'For DD Team', link: '/dashboard/dd-team' })
  items.push({ label: 'For CMC', link: '/dashboard/cmc' })

  if (canViewCapitalistActivities.value.ok) {
    items.push({ label: 'Capitalist Activities', link: '/dashboard/capitalist-activities' })
  }
})
</script>

<template>
  <DView
    class="Dashboard"
    title="Dashboard"
    :menu="menu"
  />
</template>
