<script setup lang="ts">
import { type RoundFrag } from '@/graphql'
import { useRoundOps } from '@/composables/ops/RoundOps'

defineProps<{
  round: RoundFrag
}>()

const roundOps = useRoundOps()
</script>

<template>
  <SCardBlock class="s-p-24">
    <SDesc cols="4" gap="24">
      <SDescItem span="1">
        <SDescLabel value="Round name" />
        <SDescText :value="roundOps.fullName(round)" />
      </SDescItem>
      <SDescItem span="3">
        <SDescLabel value="Schedule" />
        <SDescText :value="round.schedule" />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Total amount" />
        <SDescNumber
          :lead-unit="round.totalAmount.currency.name"
          :value="roundOps.totalAmountValueAsNumber(round)"
          separator
        />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="GB total amount" />
        <SDescNumber
          :lead-unit="round.gbTotalAmount?.currency.name"
          :value="roundOps.gbTotalAmountValueAsNumber(round)"
          separator
        />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Pre valuation" />
        <SDescNumber
          :lead-unit="round.preValuation?.currency.name"
          :value="roundOps.preValuationValueAsNumber(round)"
          separator
        />
      </SDescItem>
      <SDescItem span="1">
        <SDescLabel value="Post valuation" />
        <SDescNumber
          :lead-unit="round.postValuation?.currency.name"
          :value="roundOps.postValuationValueAsNumber(round)"
          separator
        />
      </SDescItem>
      <SDescItem span="4">
        <SDescLabel value="Pre issued shares" />
        <SDescText :value="round.preIssuedShares" pre-wrap />
      </SDescItem>
      <SDescItem span="4">
        <SDescLabel value="Participating investors" />
        <SDescText :value="round.participatingInvestors" pre-wrap />
      </SDescItem>
      <SDescItem span="4">
        <SDescLabel value="Notes" />
        <SDescText :value="round.notes" pre-wrap />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>
