<script setup lang="ts">
import { type MoneyWithoutIdFrag } from '@/graphql'
import { useMoneyOps } from '@/composables/ops/MoneyOps'

defineProps<{
  money: MoneyWithoutIdFrag
}>()

const moneyOps = useMoneyOps()
</script>

<template>
  <div class="DMoney" :class="{ zero: Number(money.value) === 0 }">
    <span class="symbol">{{ money.currency?.symbol }}</span>
    <span class="value">&nbsp;{{ moneyOps.formatValue(money) }}&nbsp;</span>
    <span class="name">{{ money.currency?.name }}</span>
  </div>
</template>

<style scoped lang="postcss">
.DMoney {
  white-space: nowrap;
}

.DMoney.zero {
  .symbol,
  .value {
    color: var(--c-text-2);
  }
}

.symbol { color: var(--c-text-1); }
.value  { color: var(--c-text-1); }
.name   { color: var(--c-text-2); }
</style>
