<script setup lang="ts">
import IconClipboardText from '~icons/ph/clipboard-text-bold'
import { cloneDeep, isEqual } from 'lodash-es'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed, reactive, ref } from 'vue'
import { useCanAddFundClosingChecklist } from '@/composables/policies/FundClosingCheckListPolicy'
import { type FundClosingCheckListOrder, FundClosingCheckListOrderField, OrderDirection, usePage } from '@/composables/repos/FundClosingCheckListRepo'
import { useFundList } from '@/composables/repos/FundRepo'
import FundClosingCheckListFormAdd from './FundClosingCheckListFormAdd.vue'

const { t } = useTrans({
  en: {
    title: 'Fund Closing Checklist',
    new_report: 'New checklist',
    c_name: 'Report name',
    c_publishe_date: 'Publish date',
    c_created_at: 'Created at',
    c_updated_at: 'Updated at',
    sort_asc: 'Sort ascending (A...Z)',
    sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    title: 'ファンド決算チェックリスト',
    new_report: '新規作成',
    c_name: '報告書名',
    c_publishe_date: '公開日',
    c_created_at: '作成日時',
    c_updated_at: '更新日時',
    sort_asc: 'ソート: 昇順 (A...Z)',
    sort_desc: 'ソート: 降順 (Z...A)'
  }
})

const { state: modal, on, off } = usePower()

const orderBy = ref<FundClosingCheckListOrder>({
  field: FundClosingCheckListOrderField.UpdatedAt,
  direction: OrderDirection.Desc
})

const options = reactive({
  page: { page: 0, perPage: 50 },
  orderBy
})

const initialOptions = cloneDeep(options)

const { data, loading } = usePage(options)
const { data: funds } = useFundList()

const canAddChecklist = useCanAddFundClosingChecklist(funds)

useUrlQuerySync({
  state: options,
  casts: {
    'page.page': Number
  },
  exclude: ['page.perPage']
})

const isDirty = computed(() => !isEqual(options, initialOptions))

const table = useTable({
  orders: [
    'name',
    'date',
    'createdAt',
    'updatedAt'
  ],

  columns: {
    name: {
      label: t.c_name,
      grow: true,
      cell: (_, record) => ({
        type: 'text',
        icon: IconClipboardText,
        value: record.title(),
        link: record.path(),
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    date: {
      label: t.c_publishe_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: t.sort_asc, onClick: () => { updateOrder(FundClosingCheckListOrderField.Date, OrderDirection.Asc) } },
            { label: t.sort_desc, onClick: () => { updateOrder(FundClosingCheckListOrderField.Date, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: record.date,
        format: 'YYYY-MM-DD'
      })
    },
    createdAt: {
      label: t.c_created_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: t.sort_asc, onClick: () => { updateOrder(FundClosingCheckListOrderField.CreatedAt, OrderDirection.Asc) } },
            { label: t.sort_desc, onClick: () => { updateOrder(FundClosingCheckListOrderField.CreatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: record.createdAt,
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: t.c_updated_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            { label: t.sort_asc, onClick: () => { updateOrder(FundClosingCheckListOrderField.UpdatedAt, OrderDirection.Asc) } },
            { label: t.sort_desc, onClick: () => { updateOrder(FundClosingCheckListOrderField.UpdatedAt, OrderDirection.Desc) } }
          ]
        }
      ]),
      cell: (_, record) => ({
        type: 'day',
        value: record.updatedAt,
        format: 'YYYY-MM-DD HH:mm'
      })
    }
  },

  records: computed(() => data.value?.items),
  total: computed(() => data.value?.page.total ?? 0),
  page: computed(() => data.value && data.value?.page.page + 1),
  perPage: computed(() => data.value?.page.perPage),
  loading,
  reset: isDirty,
  onPrev: () => { options.page.page = options.page.page - 1 },
  onNext: () => { options.page.page = options.page.page + 1 },
  onReset: reset
})

function reset() {
  options.page.page = 0
  orderBy.value = { field: FundClosingCheckListOrderField.UpdatedAt, direction: OrderDirection.Desc }
}

function updateOrder(field: FundClosingCheckListOrderField, direction: OrderDirection) {
  orderBy.value.field = field
  orderBy.value.direction = direction
}
</script>

<template>
  <div class="FundClosingCheckListCatalog">
    <div class="header">
      <div class="title">
        {{ t.title }}
      </div>
      <SButton
        v-if="canAddChecklist.ok"
        mode="info"
        :label="t.new_report"
        @click="on"
      />
    </div>
    <STable class="list" :options="table" />
    <SModal :open="modal" @close="off">
      <FundClosingCheckListFormAdd
        @cancel="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.FundClosingCheckListCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name)      { --table-col-width: 256px; }
.list :deep(.col-date)      { --table-col-width: 192px; }
.list :deep(.col-createdAt) { --table-col-width: 192px; }
.list :deep(.col-updatedAt) { --table-col-width: 192px; }
</style>
