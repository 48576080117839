import { orderBy } from 'lodash-es'
import { type CompanySector } from '@/graphql'
import { CompanySectorRequest } from '@/requests/CompanySectorRequest'
import { type Query, useQuery } from '../Api'

export type CompanySectorList = Query<CompanySector[], []>

export function useCompanySectorList(): CompanySectorList {
  return useQuery(async () => {
    const { data: { companySectors } } = await new CompanySectorRequest().fetchAll()

    return orderBy(companySectors, (c) => c.rank)
  })
}
