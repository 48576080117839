<script setup lang="ts">
import { usePower } from 'sefirot/composables/Power'
import { computedWhen } from 'sefirot/composables/Utils'
import { type ActionNote } from '@/models/ActionNote'
import { type Company } from '@/models/Company'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import ActionNoteFormCreate from './ActionNoteFormCreate.vue'
import ActionNoteListItem from './ActionNoteListItem.vue'

const props = defineProps<{
  actionNotes: ActionNote[]
  company: Company
}>()

const emit = defineEmits<{
  (e: 'created'): void
  (e: 'updated'): void
  (e: 'deleted'): void
}>()

const { canUpdate } = useCompanyPolicy(() => props.company)
const { state: modal, on, off } = usePower(false)

const actions = computedWhen(canUpdate, () => {
  return [{ icon: 'add', onClick: on }]
}, [])
</script>

<template>
  <DCard title="Action Notes" :actions="actions">
    <template v-if="actionNotes.length">
      <ActionNoteListItem
        v-for="note in actionNotes"
        :key="note.id!"
        :action-note="note"
        :company="company"
        @updated="emit('updated')"
        @deleted="emit('deleted')"
      />
    </template>

    <template v-else>
      <DCardListEmpty>No items found.</DCardListEmpty>
    </template>

    <SModal :open="modal">
      <ActionNoteFormCreate
        :company="(company as any)"
        @cancel="off"
        @created="off(() => emit('created'))"
      />
    </SModal>
  </DCard>
</template>
