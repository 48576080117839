<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  preIssuedShares: string | null
}>()

const { t } = useTrans({
  en: {
    i_pre_issued_shares_label: 'Pre-issued shares (Number of shares before issue to the Fund)',
    i_pre_issued_shares_ph: 'Common 10,000\nSeries Seed Preferred 2,234\nSeries Seed 2 Preferred 9,000\nSeries A Preferred 5,000'
  },
  ja: {
    i_pre_issued_shares_label: '発行済株式総数（当ファンドへの発行前の株数）',
    i_pre_issued_shares_ph: 'Common 10,000\nSeries Seed Preferred 2,234\nSeries Seed 2 Preferred 9,000\nSeries A Preferred 5,000'
  }
})
</script>

<template>
  <DFormSection>
    <STrans lang="en">
      <DFormTitle>Pre-issued shares</DFormTitle>
      <DFormLead>This value refers to the round information for this deal. If you wish to modify it, please do so from the Opportunities page.</DFormLead>
    </STrans>
    <STrans lang="ja">
      <DFormTitle>発行済株式総数</DFormTitle>
      <DFormLead>この値は本ディールのラウンド情報を参照しています。修正する場合はオポチュニティページより行ってください。</DFormLead>
    </STrans>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputTextarea
          :label="t.i_pre_issued_shares_label"
          :placeholder="t.i_pre_issued_shares_ph"
          :rows="4"
          disabled
          :model-value="preIssuedShares"
        />
      </DFormGridItem>
    </DFormGrid>
  </DFormSection>
</template>
