<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { type FundReportRecordStatus } from '@/graphql'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useCompanyBusinessReportFinancialFiguresAnnotationList } from '@/composables/repos/CompanyBusinessReportFinancialFiguresAnnotationRepo'
import { useCompanyBusinessReportFinancialFiguresUnitList } from '@/composables/repos/CompanyBusinessReportFinancialFiguresUnitRepo'
import { useCompanyBusinessReportItem, useCopiableCompanyBusinessReportList, usePreviousCompanyBusinessReportItem } from '@/composables/repos/CompanyBusinessReportRepo'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'
import CompanyBusinessReportFormUpdate from '@/components/company-business-report/CompanyBusinessReportFormUpdate.vue'

const { t } = useTrans({
  en: {
    title: (companyName: string) => `Business Report – ${companyName}`
  },
  ja: {
    title: (companyName: string) => `業務報告書 – ${companyName}`
  }
})

const route = useRoute()

const companyOps = useCompanyOps()

const { data: companyBusinessReport } = useCompanyBusinessReportItem(() => Number(route.params.id))
const { data: previousCompanyBusinessReport } = usePreviousCompanyBusinessReportItem(() => Number(route.params.id))
const { data: copyableCompanyBusinessReports, execute: executeCopyableCompanyBusinessReports } = useCopiableCompanyBusinessReportList(() => companyBusinessReport.value!, { immediate: false })
const { data: units } = useCompanyBusinessReportFinancialFiguresUnitList()
const { data: annotations } = useCompanyBusinessReportFinancialFiguresAnnotationList()
const { data: currencies } = useCurrencyList()

watch(companyBusinessReport, () => {
  if (companyBusinessReport.value) {
    executeCopyableCompanyBusinessReports()
  }
}, { once: true })

const title = computed(() => companyBusinessReport.value
  ? t.title(companyOps.name(companyBusinessReport.value.company))
  : undefined
)

function updateStatus(status: FundReportRecordStatus, updatedAt: string) {
  if (companyBusinessReport.value) {
    companyBusinessReport.value.status = status
    companyBusinessReport.value.updatedAt = updatedAt
  }
}
</script>

<template>
  <DPage class="CompanyBusinessReportsIdIndex" :title="title">
    <CompanyBusinessReportFormUpdate
      v-if="companyBusinessReport && companyBusinessReport && annotations && currencies"
      :company-business-report="companyBusinessReport"
      :previous-company-business-report="previousCompanyBusinessReport"
      :copyable-company-business-reports="copyableCompanyBusinessReports"
      :units="units!"
      :annotations="annotations"
      :currencies="currencies"
      @updated="updateStatus"
    />
  </DPage>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportsIdIndex {
  display: flex;
  padding: 32px 32px 128px;
}
</style>
