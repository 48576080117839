<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  add: []
}>()

const { t } = useTrans({
  en: {
    b_add: 'New support log'
  },
  ja: {
    b_add: '支援実績を登録'
  }
})
</script>

<template>
  <SCard>
    <SCardBlock class="box">
      <STrans lang="en">
        <div class="content">
          <div class="body">
            <p class="title">No support logs have been added for this company.</p>
            <p class="text">Add support logs to maintain a record of your support history for this company.</p>
          </div>
          <div class="action">
            <SButton size="small" :label="t.b_add" mode="info" @click="$emit('add')" />
          </div>
        </div>
      </STrans>
      <STrans lang="ja">
        <div class="content">
          <div class="body">
            <p class="title">支援実績が登録れていません。</p>
            <p class="text">支援実績を登録することで、これまで当企業に対して行ってきた<br>支援内容を記録しておくことができます。</p>
          </div>
          <div class="action">
            <SButton size="small" :label="t.b_add" mode="info" @click="$emit('add')" />
          </div>
        </div>
      </STrans>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.box {
  padding: 64px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.title {
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
  color: var(--c-text-2);
}

.text {
  max-width: 640px;
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
