<script setup lang="ts">
import IconTrash from '~icons/ph/trash-bold'
import { usePower } from 'sefirot/composables/Power'
import { type User } from '@/models/User'
import { type View } from '@/models/View'
import DAvatar from '@/components/DAvatar.vue'
import ViewHeaderFormSharedUserDelete from './ViewHeaderFormSharedUserDelete.vue'

defineProps<{
  view?: View | null
  user: User
}>()

defineEmits<{
  (e: 'deleted'): void
}>()

const { state: modal, on, off } = usePower()
</script>

<template>
  <div class="ViewHeaderFormSharedUserRecordItem">
    <div class="avatar">
      <DAvatar :user="user" />
    </div>

    <div class="user">
      <div class="user-name">
        {{ user.name }}
      </div>

      <div class="user-email">
        {{ user.email }}
      </div>
    </div>

    <div class="actions">
      <button
        class="action"
        v-if="(view?.sharedUsers.length ?? 0) > 1"
        @click="() => on()"
      >
        <IconTrash class="icon-svg" />
      </button>
    </div>

    <SModal :open="modal" @close="off">
      <ViewHeaderFormSharedUserDelete
        :view="view"
        :user="user"
        @cancel="off"
        @deleted="off(() => $emit('deleted'))"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.ViewHeaderFormSharedUserRecordItem {
  display: flex;
  align-items: center;
}

.avatar {
  padding: 0 16px;
}

.user {
  padding: 8px;
  flex-grow: 1;
}

.user-name {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}

.user-email {
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}

.actions {
  padding: 0 8px;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  transition: color 0.25s, background-color 0.25s;
  color: var(--c-text-2);

  &:hover {
    color: var(--c-text-1);
    background-color: var(--c-bg-elv-down);
  }
}

.icon-svg {
  width: 16px;
  height: 16px;
}
</style>
