<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { type UserFrag } from '@/graphql'
import { useUserOps } from '@/composables/ops/UserOps'
import { useUserAndRoles } from '@/composables/repos/UserRepo'
import { useUserProfilePolicy } from '@/composables/user/UserPolicy'

const route = useRoute()
const userOps = useUserOps()
const { data: user } = useUserAndRoles(() => Number(route.params.id))

const avatarNotFound = ref(false)
const userInitial = computed(() => name.value?.charAt(0).toUpperCase())
const name = computed(() => user.value ? userOps.name(user.value) : null)
const formattedRoles = computed(() => user.value?.roles.map(({ label }) => label).join('・') ?? '')

const { canView } = useUserProfilePolicy(user)
</script>

<template>
  <DPage :authorize="canView">
    <div class="UsersIdProfile">
      <div class="profile">
        <div class="profile-container">
          <div class="avatar">
            <img v-if="!avatarNotFound" :src="userOps.avatarPath(user as UserFrag)" class="avatar-img" @error="avatarNotFound = true">
            <p v-else class="avatar-initial">{{ userInitial }}</p>
          </div>
          <p class="name">{{ name }}</p>
          <p class="role">{{ formattedRoles }}</p>
        </div>
      </div>
      <div class="main">
        <RouterView />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.UsersIdProfile {
  display: flex;
  flex-grow: 1;
}

.profile {
  width: 288px;
  min-width: 288px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  transform: translateY(-78px);
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  border: 1px solid var(--c-divider-2);
  border-radius: 16px;
  background-color: var(--c-bg-elv-1);
  overflow: hidden;
}

.avatar-initial {
  font-size: 64px;
}

.avatar-img {
  width: 208px;
  height: 208px;
}

.name {
  font-size: 20px;
  font-weight: 500;
  padding-top: 12px;
}

.role {
  font-size: 16px;
  font-weight: 500;
  color: var(--c-text-2);
}

.main {
  flex-grow: 1;
  min-width: 736px;
  padding: 12px 64px 96px 0;
}
</style>
