import { type Dayjs } from 'dayjs'
import { type Ref, computed, watch } from 'vue'
import { StatSourcingRanking } from '@/models/StatSourcingRanking'
import StatSourcingRankingRequest from '@/requests/StatSourcingRankingRequest'
import { type UseQueryOptions, useQuery } from '../Api'

export function useItem(
  month: Ref<Dayjs>,
  options?: UseQueryOptions
) {
  const from = computed(() => month.value.startOf('month').toISOString())
  const to = computed(() => month.value.endOf('month').toISOString())

  const query = useQuery(async () => {
    const res = await new StatSourcingRankingRequest().fetch(from.value, to.value)

    return new StatSourcingRanking(res.data.statSourcingRanking)
  }, options)

  watch(month, query.execute)

  return query
}
