import { useLang } from 'sefirot/composables/Lang'

export interface OpsOptions {
  locale: 'en' | 'ja'
  isEn: boolean
  isJa: boolean
}

export function defineOps<T extends object>(
  setup: (options: OpsOptions) => T
): () => T {
  return () => {
    const lang = useLang()

    return setup({
      locale: lang,
      isEn: lang === 'en',
      isJa: lang === 'ja'
    })
  }
}
