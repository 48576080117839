<script setup lang="ts">
import { computed } from 'vue'

export type Size = 'small' | 'medium'
export type Space = 'compact' | 'wide' | 'none'
export type Mode = 'neutral' | 'danger'

const props = defineProps<{
  size?: Size
  space?: Space
  mode?: Mode
}>()

const classes = computed(() => [
  props.size ?? 'medium',
  props.space ?? 'wide',
  props.mode ?? 'neutral'
])
</script>

<template>
  <SMount class="DForm" :class="classes">
    <slot />
  </SMount>
</template>

<style scoped lang="postcss">
.DForm {
  flex-grow: 1;
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 48px;
  background-color: var(--c-bg-elv-3);

  &.compact { padding: 24px; }
  &.wide    { padding: 48px; }
  &.none    { border: 0 !important; padding: 0 !important; }

  &.neutral { border-color: var(--c-divider-2); }
  &.danger  { border-color: var(--c-danger); }

  &.medium {
    padding: 48px;
  }
}

.DForm :deep(.DFormTitle + .DFormLead),
.DForm :deep(.DFormTitle + .DFormMedium) {
  margin-top: 4px;
}

.DForm :deep(.DFormSection > .DFormTitle + .DFormLead) {
  margin-top: 2px;
}

.DForm :deep(.DFormSection > .DFormTitle + .DFormMedium) {
  margin-top: 12px;
}

.DForm :deep(.DFormLead) + .DFormLead,
.DForm :deep(.DFormLead) + .DFormMedium {
  padding-top: 12px;
}

.DForm :deep(.DFormSection) {
  margin-top: 48px;
}

.DForm :deep(.DFormSection) + .DFormSection {
  margin-top: 48px;
}

.DForm :deep(.DFormTitle + .DFormGrid),
.DForm :deep(.DFormLead + .DFormGrid),
.DForm :deep(.DFormGrid + .DFormGrid) {
  margin-top: 24px;
}

.DCard .DForm {
  border: 0;
}

.SModal .DForm {
  margin: 48px auto;
  transition: opacity 0.5s, transform 0.5s;

  &.small {
    padding: 24px;
    max-width: 512px;
  }

  &.medium {
    padding: 48px;
    max-width: 768px;
  }

  &.mount {
    opacity: 0;
    transform: translateY(8px);
  }
}

.small .DForm.medium {
  padding: 24px;
  max-width: 512px;
}

.medium .DForm.medium {
  padding: 48px;
  max-width: 768px;
}

.SModal .container.small .DForm {
  margin: 0;
}

.SModal .DCardBlock .DForm {
  margin: 0;
}
</style>
