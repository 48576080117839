import { Model } from './Model'
import { User } from './User'

export interface StatSourcingRankingApproachInput {
  user: object
  count: number
}

export class StatSourcingRankingApproach extends Model {
  user: User
  count: number

  constructor(input: StatSourcingRankingApproachInput) {
    super()
    this.user = this.hasOne(User, input.user)
    this.count = input.count
  }
}
