import {
  AccessControlledAction,
  AccessControlledObject,
  type Permission as BasePermission
} from '@/graphql'

export const AccessControl = {
  read: AccessControlledAction.Read,
  edit: AccessControlledAction.Edit,
  add: AccessControlledAction.Add,
  delete: AccessControlledAction.Delete,
  claim: AccessControlledAction.Claim,
  complete: AccessControlledAction.Complete,
  preview: AccessControlledAction.Preview,
  share: AccessControlledAction.Share,
  export: AccessControlledAction.Export,
  adminExportAuthority: AccessControlledAction.AdminExportAuthority
} as const

export const AccessResource = {
  company: AccessControlledObject.Company,
  companyPortfolioData: AccessControlledObject.CompanyPortfolioData,
  companyVec: AccessControlledObject.CompanyVec,
  companyLegalAssignee: AccessControlledObject.CompanyLegalAssignee,
  opportunity: AccessControlledObject.Opportunity,
  opportunityFile: AccessControlledObject.OpportunityFile,
  deal: AccessControlledObject.Deal,
  dealEvent: AccessControlledObject.DealEvent,
  dealEventBallot: AccessControlledObject.DealEventBallot,
  survey: AccessControlledObject.Survey,
  view: AccessControlledObject.View,
  fundReport: AccessControlledObject.FundReport,
  companyBusinessReport: AccessControlledObject.CompanyBusinessReport,
  fundClosingCheckList: AccessControlledObject.FundClosingCheckList,
  fundClosingCheckListRecord: AccessControlledObject.FundClosingCheckListRecord
} as const

export interface AccessControlled {
  subject: AccessControlledObject
  permittedActions: Permission[]
}

export interface AccessControllable {
  roleBasedPermissions: Permission[]
  can(action: ActionType, resource: ResourceType): boolean
  can<T extends AccessControlled>(action: ActionType, resource: T): boolean
  can<T extends AccessControlled>(action: ActionType, resource: ResourceType, subject: T): boolean
}

export type ResourceType = keyof typeof AccessResource

export type ActionType = keyof typeof AccessControl

export type Permission = Pick<BasePermission, 'object' | 'action'>

export function hasPermittedAction<T extends AccessControlled>(
  action: ActionType,
  object: AccessControlledObject,
  subject: T
): boolean {
  return hasPermission(subject.permittedActions, action, object)
}

export function hasRolePermission<T extends AccessControllable>(
  action: ActionType,
  object: AccessControlledObject,
  possession: T
): boolean {
  return hasPermission(possession.roleBasedPermissions, action, object)
}

function hasPermission(
  grants: Permission[],
  action: ActionType,
  object: AccessControlledObject
): boolean {
  return grants.some((permission) => (
    permission.action === AccessControl[action]
    && permission.object === object
  ))
}
