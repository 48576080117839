import { useTitle as useTitleBase } from '@vueuse/core'
import { type MaybeRef, type Ref, computed, unref } from 'vue'

export interface Options {
  withoutTemplate?: boolean
}

export function useTitle(
  title: MaybeRef<string | null | undefined>,
  options: Options = {}
): Ref<string | null | undefined> {
  return useTitleBase(computed(() => {
    return options.withoutTemplate ? unref(title) : `${unref(title)} | GB Base`
  }))
}
