import { watch } from 'vue'
import { FundReport } from '@/models/FundReport'
import {
  type BasicPaginationInput,
  type CreateFundReportInput,
  type FundReportCondition,
  type FundReportOrder,
  FundReportOrderField,
  FundReportRequest,
  FundReportType,
  OrderDirection
} from '@/requests/FundReportRequest'
import { useMutation, useQuery } from '../Api'

export type { FundReportOrder }
export { FundReportOrderField, OrderDirection, FundReportType }

export interface UseFundReportCollectionOptions {
  page: BasicPaginationInput
  condition: FundReportCondition
  orderBy: FundReportOrder
}

export function useFundReportCollection(options: UseFundReportCollectionOptions) {
  const query = useQuery(async () => {
    const res = await new FundReportRequest().fetchPage(
      options.page,
      options.condition,
      options.orderBy
    )

    return {
      data: res.data.fundReports.items.map((fr) => new FundReport(fr)),
      page: res.data.fundReports.pageInfo
    }
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useFundReportCreate(
  fundId: () => number,
  input: () => CreateFundReportInput
) {
  return useMutation(async () => {
    const res = await new FundReportRequest().create(fundId(), input())

    return new FundReport(res.data.fundReport)
  })
}
