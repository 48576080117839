import { useTrans } from 'sefirot/composables/Lang'
import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  type DealForReportsFrag,
  DealReportStatus,
  type DealReportWithStatusFrag,
  DealStatus,
  type DealWithEventsFrag,
  type DealWithStatusFrag,
  type FundGroupType,
  type WithPermissionFrag
} from '@/graphql'
import { type User } from '@/models/User'
import {
  type Authorized,
  type PolicyResponse,
  type Resource,
  allow,
  deny,
  pending,
  usePolicy,
  usePolicyResponse
} from '../Policy'
import { useDealOps } from '../ops/DealOps'

export interface DealPolicy {
  canView: Authorized
  canUpdate: Authorized
  canEditEventWithBallot: Authorized
}

export function useDealPolicy(deal?: Resource<WithPermissionFrag>): DealPolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(deal, checkView)
  const canUpdate = defineWhen(deal, checkUpdate)
  const canEditEventWithBallot = defineWhen(deal, checkEditEventWithBallot)

  return {
    canView,
    canUpdate,
    canEditEventWithBallot
  }
}

export function useCanComplete(
  deal: Resource<DealForReportsFrag>
): PolicyResponse<'no-auth' | 'invested' | 'dropped' | 'not_all_report_completed'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to complete this deal.',
      already_invested: 'The deal is already completed.',
      already_dropped: 'The deal is already dropped.',
      not_all_report_completed: 'All deal reports must be completed for deal to be completed.'
    },
    ja: {
      no_auth: 'このディールをコンプリートする権限がありません。',
      already_invested: 'このディールはすでに投資済みです。',
      already_dropped: 'このディールはすでにドロップされています。',
      not_all_report_completed: 'すべての投資報告書をコンプリートしないとディールのコンプリートはできません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkComplete)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const isAllReportStatusCompleted = defineWhen(deal, checkAllReportStatusCompleted)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.already_dropped)
    }

    if (!isAllReportStatusCompleted.value) {
      return deny('not_all_report_completed', t.not_all_report_completed)
    }

    return allow()
  })
}

export function useCanAddReport(
  deal: Resource<DealForReportsFrag>
): PolicyResponse<'no-auth' | 'invested' | 'dropped' | 'has-all-event'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to add a report to this deal.',
      deal_already_invested: 'You cannot add reports to completed deal.',
      deal_already_dropped: 'You cannot add reports to dropped deal.',
      has_all_event_results: 'You must finish adding all deal action results before creating a new deal report.'
    },
    ja: {
      no_auth: 'このディールに投資報告書は追加する権限がありません。',
      deal_already_invested: '投資済みのディールには投資報告書は追加できません。',
      deal_already_dropped: 'ドロップされたディールには投資報告書を追加できません。',
      has_all_event_results: 'すべてのDeal Actionsの結果を入力しないと投資報告書は作成できません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkUpdate)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const hasAllEventResults = defineWhen(deal, checkHasAllEventResults)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.deal_already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.deal_already_dropped)
    }

    if (!hasAllEventResults.value) {
      return deny('has-all-event', t.has_all_event_results)
    }

    return allow()
  })
}

export function useCanUpdateReport(
  deal: Resource<DealWithStatusFrag & WithPermissionFrag>,
  report: Resource<DealReportWithStatusFrag>
): PolicyResponse<'no-auth' | 'completed' | 'invested' | 'dropped'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to update this report.',
      completed: 'You cannot update a completed report',
      deal_already_invested: 'You cannot update a report of invested deal.',
      deal_already_dropped: 'You cannot update a report of dropped deal.'
    },
    ja: {
      no_auth: 'この投資報告書を編集する権限がありません。',
      completed: 'コンプリートしたディールの投資報告書は編集できません。',
      deal_already_invested: '投資済みのディールの投資報告書は編集できません。',
      deal_already_dropped: 'ドロップされたディールの投資報告書は編集できません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkUpdate)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const isReportCompleted = defineWhen(report, checkReportCompleted)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.deal_already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.deal_already_dropped)
    }

    if (isReportCompleted.value) {
      return deny('completed', t.completed)
    }

    return allow()
  })
}

export function useCanDeleteReport(
  deal: Resource<DealWithStatusFrag & WithPermissionFrag>,
  report: Resource<DealReportWithStatusFrag>
): PolicyResponse<'no-auth' | 'completed' | 'invested' | 'dropped'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to delete this report.',
      completed: 'You cannot delete a completed report',
      deal_already_invested: 'You cannot delete a report of invested deal.',
      deal_already_dropped: 'You cannot delete a report of dropped deal.'
    },
    ja: {
      no_auth: 'この投資報告書を削除する権限がありません。',
      completed: 'コンプリートしたディールの投資報告書は削除できません。',
      deal_already_invested: '投資済みのディールの投資報告書は削除できません。',
      deal_already_dropped: 'ドロップされたディールの投資報告書は削除できません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkUpdate)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const isReportCompleted = defineWhen(report, checkReportCompleted)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.deal_already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.deal_already_dropped)
    }

    if (isReportCompleted.value) {
      return deny('completed', t.completed)
    }

    return allow()
  })
}

export function useCanCompleteReport(
  deal: Resource<DealWithStatusFrag & WithPermissionFrag>,
  report: Resource<DealReportWithStatusFrag>
): PolicyResponse<'no-auth' | 'completed' | 'invested' | 'dropped'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to complete this report.',
      completed: 'This report is already completed.',
      deal_already_invested: 'You cannot edit report status of completed deal.',
      deal_already_dropped: 'You cannot edit report status of dropped deal.'
    },
    ja: {
      no_auth: 'この投資報告書をコンプリートする権限がありません。',
      completed: 'すでにコンプリート済みです。',
      deal_already_invested: '投資済みのディールの投資報告書は編集できません。',
      deal_already_dropped: 'ドロップされたディールの投資報告書は編集できません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkUpdate)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const isReportCompleted = defineWhen(report, checkReportCompleted)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.deal_already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.deal_already_dropped)
    }

    if (isReportCompleted.value) {
      return deny('completed', t.completed)
    }

    return allow()
  })
}

export function useCanIncompleteReport(
  deal: Resource<DealWithStatusFrag & WithPermissionFrag>,
  report: Resource<DealReportWithStatusFrag>
): PolicyResponse<'no-auth' | 'completed' | 'invested' | 'dropped'> {
  const { t } = useTrans({
    en: {
      no_auth: 'You are not authorized to convert to draft this report.',
      incompleted: 'This report is already draft.',
      deal_already_invested: 'You cannot edit report status of completed deal.',
      deal_already_dropped: 'You cannot edit report status of dropped deal.'
    },
    ja: {
      no_auth: 'この投資報告書をドラフトに戻す権限がありません。',
      completed: 'すでにドラフトです。',
      deal_already_invested: '投資済みのディールの投資報告書は編集できません。',
      deal_already_dropped: 'ドロップされたディールの投資報告書は編集できません。'
    }
  })

  const { defineWhen } = usePolicy()

  const hasAuth = defineWhen(deal, checkUpdate)
  const isDealInvested = defineWhen(deal, checkDealInvested)
  const isDealDropped = defineWhen(deal, checkDealDropped)
  const isReportCompleted = defineWhen(report, checkReportCompleted)

  return usePolicyResponse(() => {
    if (!hasAuth.value) {
      return deny('no-auth', t.no_auth)
    }

    if (isDealInvested.value) {
      return deny('invested', t.deal_already_invested)
    }

    if (isDealDropped.value) {
      return deny('dropped', t.deal_already_dropped)
    }

    if (!isReportCompleted.value) {
      return deny('completed', t.incompleted)
    }

    return allow()
  })
}

export function checkView(user: User, deal: WithPermissionFrag): boolean {
  return user.allow('read', deal)
}

export function checkUpdate(user: User, deal: WithPermissionFrag): boolean {
  return user.allow('edit', deal)
}

export function checkComplete(user: User, deal: WithPermissionFrag): boolean {
  return user.allow('complete', deal)
}

export function checkEditEventWithBallot(user: User, deal: WithPermissionFrag): boolean {
  return user.allow('edit', 'dealEventBallot', deal)
      && user.allow('delete', 'dealEventBallot', deal)
}

export function checkDealInvested(_user: User, deal: DealWithStatusFrag): boolean {
  return deal.status === DealStatus.Invested
}

export function checkDealDropped(_user: User, deal: DealWithStatusFrag): boolean {
  return deal.status === DealStatus.Dropped
}

export function checkAllReportStatusCompleted(_user: User, deal: DealForReportsFrag): boolean {
  return (
    deal.reports.length > 0
    && deal.reports.every((report) => report.status === DealReportStatus.Completed)
  )
}

export function checkHasAllEventResults(_user: User, deal: DealWithEventsFrag): boolean {
  const dealOps = useDealOps()

  return dealOps.hasAllEventResults(deal)
}

export function checkReportCompleted(_user: User, report: DealReportWithStatusFrag): boolean {
  return report.status === DealReportStatus.Completed
}

export function useCanEditDealSettings(
  fundGroupTypes: MaybeRefOrGetter<FundGroupType[] | null | undefined>
): PolicyResponse {
  return usePolicyResponse(() => {
    const f = toValue(fundGroupTypes)

    return !f
      ? pending()
      : (f.length > 0) ? allow() : deny()
  })
}
