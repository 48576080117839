<script setup lang="ts">
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { OpportunityInitialEvaluation } from '@/graphql'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'

const model = defineModel<OpportunityInitialEvaluation>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Initial evaluation by PIC *'
  },
  ja: {
    label: 'Initial evaluation by PIC *'
  }
})

const oppoOps = useOpportunityOps()

const options = [
  {
    label: oppoOps.initialEvaluationTextByValue(OpportunityInitialEvaluation.Proceed),
    value: OpportunityInitialEvaluation.Proceed
  },
  {
    label: oppoOps.initialEvaluationTextByValue(OpportunityInitialEvaluation.NotToProceed),
    value: OpportunityInitialEvaluation.NotToProceed
  }
]
</script>

<template>
  <SInputRadios
    :label="t.label"
    :options="options"
    v-model="model"
  >
    <template #info>
      <STrans lang="en">When “Considering investment” is chose, the opportunity must go through Initial Review Meeting before opening it to funds. <SLink href="https://docs.google.com/document/d/1qZcl-IQFtcqTca2f0B1d-F-PrT94uP4hnsDBiZJ1Lmg/edit#heading=h.mn5avb72m7l3" @click.stop>Learn more.</SLink></STrans>
      <STrans lang="ja">「Considering investment」を選択した場合、各ファンドへ開示する前に案件初期検討会を実施する必要があります。<SLink href="https://docs.google.com/document/d/1qZcl-IQFtcqTca2f0B1d-F-PrT94uP4hnsDBiZJ1Lmg/edit#heading=h.mn5avb72m7l3" @click.stop>もっと詳しく。</SLink></STrans>
    </template>
  </SInputRadios>
</template>
