<script setup lang="ts">
const props = withDefaults(defineProps<{
  title: string
  showHelpIcon?: boolean
}>(), {
  showHelpIcon: false
})

const emit = defineEmits<{
  (e: 'click-help-icon'): void
}>()
</script>

<template>
  <DCard
    class="UsersProfileCard"
    :title="props.title"
    :collapsable="false"
    :show-help-icon="props.showHelpIcon"
    @click-help-icon="emit('click-help-icon')"
  >
    <slot />
  </DCard>
</template>

<style scoped lang="postcss">
.UsersProfileCard {
  & + & {
    margin-top: 16px;
  }
}

.DCard :deep(.header) {
  background-color: var(--c-bg-soft);
}

:deep(.STable.borderless .box) {
  border: 0;
  border-radius: 0 0 6px 6px;
}

:deep(.STable.borderless .container.head) {
  border-radius: 0;
}

:deep(.STable.borderless .container.body) {
  border-radius: 0 0 6px 6px;
}
</style>
