<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUserOps } from '@/composables/ops/UserOps'
import { useCanViewUserSettings } from '@/composables/policies/UserPolicy'
import { useUserAndRoles } from '@/composables/repos/UserRepo'
import UserSettingsRetire from '@/components/users/UserSettingsRetire.vue'
import UserSettingsUpdateFundAssignmentsAsync from '@/components/users/UserSettingsUpdateFundAssignmentsAsync.vue'
import UserSettingsUpdateRoles from '@/components/users/UserSettingsUpdateRoles.vue'

const route = useRoute()

const { data: user, execute: refresh } = useUserAndRoles(Number(route.params.id))

const userOps = useUserOps()

const canViewSettings = useCanViewUserSettings()

const title = computed(() => {
  return user.value && `${userOps.name(user.value)} - Settings`
})
</script>

<template>
  <DPage class="UsersIdSettings" :title="title" :authorize="canViewSettings.ok">
    <Suspense>
      <div v-if="user" class="container">
        <UserSettingsUpdateRoles :user="user" @updated="refresh" />
        <UserSettingsUpdateFundAssignmentsAsync :user="user" />
        <UserSettingsRetire :user="user" @retired="refresh" />
      </div>
    </Suspense>
  </DPage>
</template>

<style scoped lang="postcss">
.UsersIdSettings {
  padding: 32px 32px 128px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  max-width: 768px;
}
</style>
