<script setup lang="ts">
import IconArrowCounterClockwise from '~icons/ph/arrow-counter-clockwise'
import IconDownloadSimple from '~icons/ph/download-simple'
import IconShareNetwork from '~icons/ph/share-network'
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import STooltip from 'sefirot/components/STooltip.vue'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type View } from '@/models/View'
import { useViewPolicy } from '@/composables/view/ViewPolicy'
import DAvatars from '@/components/DAvatars.vue'
import ViewHeaderFormDelete from '@/components/view/ViewHeaderFormDelete.vue'
import ViewHeaderFormExportComplete from '@/components/view/ViewHeaderFormExportComplete.vue'
import ViewHeaderFormExportConfirm from '@/components/view/ViewHeaderFormExportConfirm.vue'
import ViewHeaderFormSharedUser from '@/components/view/ViewHeaderFormSharedUser.vue'

const props = defineProps<{
  view?: View | null
}>()

const emit = defineEmits<{
  (e: 'exported'): void
  (e: 'updated'): void
  (e: 'run'): void
}>()

const { canExport, canDelete, canShare } = useViewPolicy(() => props.view)
const { state: shareModal, on: shareModalOn, off: shareModalOff } = usePower()
const { state: exportConfirmModal, on: exportConfirmModalOn, off: exportConfirmModalOff } = usePower()
const { state: exportCompleteModal, on: exportCompleteModalOn, off: exportCompleteModalOff } = usePower()
const { state: deleteModal, on: deleteModalOn, off: deleteModalOff } = usePower()

const exportLabel = computed(() => {
  const time = props.view?.exportAvailableAt?.format('MMM D, h:mm a')

  return props.view?.exportAvailableAt
    ? time
    : 'Export'
})

const exportTooltipText = computed(() => {
  const time = props.view?.exportAvailableAt?.format('MMM D, h:mm a')
  const exportedBy = props.view?.lastExportedBy

  return props.view?.exportAvailableAt
    ? `
    ${exportedBy?.name} exported the view recently. The next export will be
    available on ${time}.
  `
    : undefined
})
const notReadyExport = computed(() => !!props.view?.exportAvailableAt)

function onExported() {
  exportCompleteModalOn()
  emit('exported')
}
</script>

<template>
  <div class="ViewHeader">
    <div class="primary-section">
      <div class="title">{{ view?.name }}</div>
      <p class="tag">{{ view?.viewSchema.title }}</p>
    </div>

    <div class="shared-users">
      <DAvatars :users="view?.sharedUsers ?? []" :max="3" tooltip tooltip-position="bottom" />
    </div>

    <div class="actions">
      <SButton
        v-if="canShare"
        class="action"
        size="small"
        mode="info"
        type="outline"
        label="Share"
        :icon="IconShareNetwork"
        @click="shareModalOn"
      />

      <STooltip v-if="canExport" :text="exportTooltipText" position="bottom">
        <SButton
          class="action"
          size="small"
          :mode="notReadyExport ? 'mute' : 'info'"
          type="outline"
          :label="exportLabel"
          :disabled="notReadyExport"
          :icon="IconDownloadSimple"
          @click="exportConfirmModalOn"
        />
      </STooltip>

      <SButton
        v-else
        class="action"
        size="small"
        mode="info"
        label="Run"
        :icon="IconArrowCounterClockwise"
        @click="emit('run')"
      />

      <SButton
        v-if="canDelete"
        class="action"
        size="small"
        mode="danger"
        type="outline"
        label="Delete"
        :icon="IconTrash"
        @click="deleteModalOn"
      />
    </div>
  </div>

  <SModal :open="shareModal" @close="shareModalOff">
    <ViewHeaderFormSharedUser
      :view="view"
      @added="$emit('updated')"
      @deleted="$emit('updated')"
      @cancel="shareModalOff"
    />
  </SModal>
  <SModal :open="exportConfirmModal" @close="exportConfirmModalOff">
    <ViewHeaderFormExportConfirm
      :view="view"
      @exported="exportConfirmModalOff(() => onExported())"
      @cancel="exportConfirmModalOff"
    />
  </SModal>
  <SModal :open="exportCompleteModal" @close="exportCompleteModalOff">
    <ViewHeaderFormExportComplete
      :view="view"
      @close="exportCompleteModalOff"
    />
  </SModal>
  <SModal :open="deleteModal" @close="deleteModalOff">
    <ViewHeaderFormDelete
      :view="view"
      @cancel="deleteModalOff"
    />
  </SModal>
</template>

<style scoped lang="postcss">
.ViewHeader {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 12px 32px;
  background-color: var(--c-bg-elv);
}

.primary-section {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  transform: translateY(1px);
}

.shared-users {
  display: flex;
  align-items: center;
  margin: 0 24px;
}

.actions {
  display: flex;
  gap: 8px;
}

.tag {
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
  background-color: var(--c-bg-mute);
  margin: 4px 8px;
}
</style>
