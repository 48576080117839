<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type Opportunity } from '@/models/Opportunity'
import { useOpportunityUpdateBusiness } from '@/composables/opportunity/OpportunityData'

export interface Data {
  financingPlan: string | null
  serviceDescription: string | null
  trackRecord: string | null
  businessPlans: string | null
  competitiveAdvantages: string | null
  businessModel: string | null
  pastFinancings: string | null
}

const props = defineProps<{
  opportunity: Opportunity
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data } = useData<Data>({
  financingPlan: props.opportunity.financingPlan!,
  serviceDescription: props.opportunity.serviceDescription!,
  trackRecord: props.opportunity.trackRecord ?? null,
  businessPlans: props.opportunity.businessPlans ?? null,
  competitiveAdvantages: props.opportunity.competitiveAdvantages ?? null,
  businessModel: props.opportunity.businessModel ?? null,
  pastFinancings: props.opportunity.pastFinancings ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  financingPlan: { required: required(), maxLength: maxLength(500) },
  serviceDescription: { required: required(), maxLength: maxLength(500) },
  trackRecord: { maxLength: maxLength(500) },
  businessPlans: { maxLength: maxLength(500) },
  competitiveAdvantages: { maxLength: maxLength(500) },
  businessModel: { maxLength: maxLength(500) },
  pastFinancings: { maxLength: maxLength(500) }
})

const { execute, loading } = useOpportunityUpdateBusiness(props.opportunity.id!, () => ({
  financingPlan: data.value.financingPlan!,
  serviceDescription: data.value.serviceDescription!,
  trackRecord: data.value.trackRecord,
  businessPlans: data.value.businessPlans,
  competitiveAdvantages: data.value.competitiveAdvantages,
  businessModel: data.value.businessModel,
  pastFinancings: data.value.pastFinancings
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Update business information</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Financing plan *"
          placeholder="Describe the future financing plan of the startup."
          :rows="5"
          v-model="data.financingPlan"
          :validation="validation.financingPlan"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Service description *"
          placeholder="Describe what kind of service the startup is offering at the moment."
          :rows="5"
          v-model="data.serviceDescription"
          :validation="validation.serviceDescription"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Track record"
          placeholder="Describe how the startup is doing at the moment."
          :rows="5"
          v-model="data.trackRecord"
          :validation="validation.trackRecord"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Business plans"
          placeholder="Describe the business plans of the startup. What are they trying to do in the future?"
          :rows="5"
          v-model="data.businessPlans"
          :validation="validation.businessPlans"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Competitive advantages"
          placeholder="What are the advantages against competitors?"
          :rows="5"
          v-model="data.competitiveAdvantages"
          :validation="validation.competitiveAdvantages"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Business model"
          placeholder="How does the startup make money out of its business?"
          :rows="5"
          v-model="data.businessModel"
          :validation="validation.businessModel"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputTextarea
          label="Past financings"
          placeholder="Has the startup done any financing in the past? If yes, what kind?"
          :rows="5"
          v-model="data.pastFinancings"
          :validation="validation.pastFinancings"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
