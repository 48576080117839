<script setup lang="ts">
import { useFundShowPage } from '@/composables/pages/FundPage'
import { useFundInvestedDealCountPerYearList, useFundPortfolioStatsListCache, useFundStatsItem } from '@/composables/repos/FundRepo'
import FundPageDashboard from '@/components/fund/FundPageDashboard.vue'

const { fund } = useFundShowPage()
const { data: stats } = useFundStatsItem(fund.value.id)
const { data: portfolioStats } = useFundPortfolioStatsListCache(fund.value.id)
const { data: dealCountsPerYear } = useFundInvestedDealCountPerYearList(fund.value.id)
</script>

<template>
  <DPage class="FundsIdDashboard" :title="`${fund.nameAbbreviated} - Dashboard`">
    <FundPageDashboard
      v-if="fund && stats && portfolioStats && dealCountsPerYear"
      :fund
      :stats
      :portfolio-stats
      :deal-counts-per-year
    />
  </DPage>
</template>

<style scoped lang="postcss">
.FundsIdDashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
