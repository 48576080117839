<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputYMD, { type Value as Ymd } from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required, url, ymd } from 'sefirot/validation/rules'
import { type Company } from '@/models/Company'
import { useCompanyUpdateInfo } from '@/composables/company/CompanyData'
import DInputCountry from '../DInputCountry.vue'
import DInputMonth from '../DInputMonth.vue'

export interface Data {
  website: string | null
  founded: Ymd
  fiscalMonth: number | null
  countryId: number
  zipcode: string | null
  address: string | null
  addressHouseNumber: string | null
}

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { data } = useData<Data>({
  website: props.company.website ?? null,
  founded: {
    year: props.company.foundedYear ?? null,
    month: props.company.foundedMonth ?? null,
    date: null
  },
  fiscalMonth: props.company.fiscalMonth ?? null,
  countryId: props.company.country!.id!,
  zipcode: props.company.zipcode ?? null,
  address: props.company.address ?? null,
  addressHouseNumber: props.company.addressHouseNumber ?? null
})

const { validation, validateAndNotify } = useValidation(data, {
  website: {
    url: url(),
    maxLength: maxLength(255)
  },
  founded: {
    ymd: ymd(['y', 'm'])
  },
  countryId: {
    required: required()
  },
  zipcode: {
    maxLength: maxLength(10)
  },
  address: {
    maxLength: maxLength(255)
  },
  addressHouseNumber: {
    maxLength: maxLength(255)
  }
})

const { loading, execute } = useCompanyUpdateInfo(props.company.id!, () => ({
  website: data.value.website,
  foundedYear: data.value.founded.year,
  foundedMonth: data.value.founded.month,
  fiscalMonth: data.value.fiscalMonth,
  countryId: data.value.countryId!,
  zipcode: data.value.zipcode,
  address: data.value.address,
  addressHouseNumber: data.value.addressHouseNumber,
  activeCountryIds: props.company.activeCountries!.map((a) => a.id!),
  marketingModelId: props.company.marketingModel!.id!,
  primarySectorId: props.company.primarySector!.id!,
  subSectorIds: props.company.subSectors!.map((s) => s.id!),
  description: props.company.description!
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <DForm class="CompanyFormUpdateInfo">
    <DFormTitle>Update company information</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputText
          label="Website"
          placeholder="https://example.com"
          v-model="data.website"
          :validation="validation.website"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputYMD
          size="small"
          label="Founded at"
          :no-date="true"
          v-model="data.founded"
          :validation="validation.founded"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <DInputMonth
          label="Fiscal month"
          placeholder="Select month"
          nullable
          v-model="data.fiscalMonth"
        />
      </DFormGridItem>
      <DFormGridItem span="4" />
      <DFormGridItem span="2">
        <DInputCountry
          label="Country"
          placeholder="Select country"
          v-model="data.countryId"
          :validation="validation.countryId"
        />
      </DFormGridItem>
      <DFormGridItem span="2">
        <SInputText
          label="Zip"
          placeholder="1500031"
          v-model="data.zipcode"
          :validation="validation.zipcode"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputText
          label="Address"
          placeholder="10-11 Sakuragaoka-cho, Shibuya-ku, Tokyo"
          v-model="data.address"
          :validation="validation.address"
        />
      </DFormGridItem>
      <DFormGridItem span="6">
        <SInputText
          label="House number"
          placeholder="Central Plaza Saginuma 202"
          v-model="data.addressHouseNumber"
          :validation="validation.addressHouseNumber"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Update" :loading="loading" @click="update" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
