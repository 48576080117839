<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { type TableColumns, useTable } from 'sefirot/composables/Table'
import { computedArrayWhen, computedWhen } from 'sefirot/composables/Utils'
import { computed, ref, watch } from 'vue'
import { OpportunityOrderField, type OpportunitySourceFrag, OrderDirection, type ProposalFrag, type UpdateProposalMutation } from '@/graphql'
import { type CompanyParticipation, CompanyParticipationAuthorities } from '@/models/CompanyParticipation'
import { OpportunityStatus } from '@/models/Opportunity'
import { OpportunitySource } from '@/models/OpportunitySource'
import { Proposal, ProposalStatus, ProposalStatuses } from '@/models/Proposal'
import { useMe } from '@/composables/Auth'
import { useFundGroupActiveCollection } from '@/composables/fund-group/FundGroupData'
import { useCompanyOps, useCompanyParticipationOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useProposalOps } from '@/composables/ops/ProposalOps'
import { useOpportunityPageForStatus } from '@/composables/repos/OpportunityRepo'
import IconTelescope from '@/components/icon/IconTelescope.vue'
import DInputSearch from '../DInputSearch.vue'
import OpportunityFormUpdateProposal from './OpportunityFormUpdateProposal.vue'

export interface SelectedProposal {
  proposal: Proposal
  sources: OpportunitySource[]
}

const { user: me } = useMe()

const query = ref<string | null>(null)

const { data: options } = useData({
  page: {
    page: 0,
    perPage: 1000
  },
  condition: {
    query: null as string | null,
    status: [OpportunityStatus.Open, OpportunityStatus.InProgress],
    assignee: [me.id!]
  },
  orderBy: {
    field: OpportunityOrderField.UpdatedAt,
    direction: OrderDirection.Desc
  }
})

const { data: oppos, loading: loadingOppos } = useOpportunityPageForStatus(options)
const { data: funds } = useFundGroupActiveCollection()

const companyOps = useCompanyOps()
const companyParticipationOps = useCompanyParticipationOps()
const oppoOps = useOpportunityOps()
const proposalOps = useProposalOps()

const selectedProposal = ref<SelectedProposal | null>(null)
const assignedAs = ref<string | null>(null)

const ALL_FUNDS = Number.NEGATIVE_INFINITY
const fund = ref<number>(ALL_FUNDS)
const isAllFundsSelected = computed(() => fund.value === ALL_FUNDS)

const ALL_STATUSES = '__ALL_STATUSES__'
const status = ref<string>(ALL_STATUSES)

const assignedAsOptions = Object.entries(CompanyParticipationAuthorities).map(([value, label]) => {
  return { label, value }
})

const fundOptions = computedWhen(funds, (fs) => {
  return [
    { label: 'All funds', value: ALL_FUNDS },
    ...fs.map((f) => ({ label: f.name!, value: f.id! }))
  ] as Option[]
}, [] as Option[])

const statusOptions: Option[] = [
  { label: 'All statuses', value: ALL_STATUSES },
  ...Object.entries(ProposalStatuses).map(([value, label]) => {
    return { label, value }
  })
]

const recordsByAssign = computedWhen(() => oppos.value?.items, (oppos) => {
  if (!assignedAs.value) {
    return oppos
  }

  return oppos.filter((oppo) => {
    return oppo.company!.participations.some((p) => {
      return p.until === null && p.authority === assignedAs.value && p.userId === me.id
    })
  })
}, [])

const recordsByFund = computed(() => {
  if (fund.value === ALL_FUNDS || status.value === ALL_STATUSES) {
    return recordsByAssign.value
  }

  const _status = status.value === ALL_STATUSES ? null : status.value
  return recordsByAssign.value.filter((o) => {
    return o.proposals.some((p) => p.fundGroupId === fund.value && p.status === _status)
  })
})

watch(fund, () => {
  if (fund.value === ALL_FUNDS) {
    status.value = ALL_STATUSES
  }
})

const table = useTable({
  records: recordsByFund,
  loading: loadingOppos,
  borderless: true,
  orders: computedArrayWhen(funds, (items, gs) => {
    items.push('name')
    items.push('status')
    items.push('expiresAt')
    items.push('assignedAs')
    items.push(...gs.map((g) => g.name!))
    items.push('createdAt')
    items.push('updatedAt')
    items.push('spacer')
  }),
  columns: computedWhen(funds, (gs) => ({
    name: {
      label: 'Name',
      cell: (_: any, oppo: any) => ({
        icon: IconTelescope,
        type: 'text',
        value: `${companyOps.name(oppo.company)} / ${oppoOps.title(oppo)}`,
        link: oppoOps.path(oppo),
        color: oppoOps.expiresAtModeForTitle(oppo)
      })
    },
    status: {
      label: 'Status',
      cell: (_: any, oppo: any) => ({
        type: 'state',
        mode: oppoOps.statusMode(oppo),
        label: oppoOps.statusText(oppo)
      })
    },
    assignedAs: {
      label: 'Assigned as',
      cell(_: any, oppo: any) {
        const participations = oppo.company.participations.find((p: CompanyParticipation) => {
          return p.until === null && p.userId === me.id
        })

        return {
          type: 'text',
          value: companyParticipationOps.authorityAbbr(participations)
        }
      }
    },
    ...gs.reduce<TableColumns<any, any, any>>((columns, g) => {
      columns[g.name!] = {
        label: g.name!,
        className: 'col-fund',
        cell(_: any, oppo: any) {
          const type = 'text'
          const status = oppo.proposals.find((p: Proposal) => p.fundGroupId === g.id)?.status ?? null
          const value = proposalOps.statusText(status)
          const color = getProposalStatusColor(status)

          function onClick(_: any, oppo: any) {
            const proposal = oppo.proposals.find((p: Proposal) => p.fundGroupId === g.id) ?? null

            if (proposal) {
              selectedProposal.value = {
                proposal: new Proposal(proposal),
                sources: oppo.sources.map((s: any) => new OpportunitySource(s))
              }
            }
          }

          return {
            type,
            value,
            color,
            onClick
          }
        }
      }
      return columns
    }, {}),
    expiresAt: {
      label: 'Expires',
      cell: (_: any, oppo: any) => ({
        type: 'text',
        value: oppoOps.expiresAtReadableText(oppo),
        color: oppoOps.expiresAtMode(oppo)
      })
    },
    createdAt: {
      label: 'Created at',
      cell: (_: any, oppo: any) => ({
        type: 'day',
        value: oppoOps.createdAt(oppo),
        format: 'YYYY-MM-DD HH:mm',
        color: 'soft'
      })
    },
    updatedAt: {
      label: 'Updated at',
      cell: (_: any, oppo: any) => ({
        type: 'day',
        value: oppoOps.updatedAt(oppo),
        format: 'YYYY-MM-DD HH:mm',
        color: 'soft'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }), {} as any)
})

function getProposalStatusColor(status: ProposalStatus | null) {
  switch (status) {
    case ProposalStatus.Hidden: return 'mute'
    case ProposalStatus.Open: return 'success'
    case ProposalStatus.Closed: return 'soft'
    default: return 'soft'
  }
}

function updateQuery(value: string | null) {
  options.value.page.page = 0
  query.value = value === '' ? null : value
}

function spliceProposal(proposalFrag: UpdateProposalMutation['proposal']) {
  selectedProposal.value = null

  const oppoId = proposalFrag.opportunityId
  const oppo = oppos.value?.items.find((oppo) => oppo.id === oppoId)

  if (oppo) {
    const proposalId = proposalFrag.id
    const proposal = oppo.proposals.find((p) => p.id === proposalId)

    if (proposal) {
      proposal.status = proposalFrag.status
      proposal.statusUpdatedAt = proposalFrag.statusUpdatedAt
      proposal.closedAt = proposalFrag.closedAt
      proposal.closedReason = proposalFrag.closedReason ?? ''
      proposal.data = proposalFrag.data
    }
  }
}
</script>

<template>
  <div class="OpportunityCatalogByFundStatus">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="input search">
              <DInputSearch
                size="mini"
                placeholder="Search opportunities"
                :model-value="query"
                @enter="updateQuery"
              />
            </div>
            <div class="input sub">
              <SInputDropdown
                size="mini"
                placeholder="Select role"
                :options="assignedAsOptions"
                nullable
                v-model="assignedAs"
              />
            </div>
            <div class="input sub">
              <SInputDropdown
                size="mini"
                placeholder="Select fund"
                :options="fundOptions"
                nullable
                v-model="fund"
              />
            </div>
            <div class="input sub">
              <SInputDropdown
                size="mini"
                placeholder="Select status"
                :options="statusOptions"
                nullable
                :disabled="isAllFundsSelected"
                v-model="status"
              />
            </div>
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
    </SCard>

    <SModal :open="!!selectedProposal" @close="selectedProposal = null">
      <OpportunityFormUpdateProposal
        :proposal="selectedProposal!.proposal as unknown as ProposalFrag"
        :sources="selectedProposal!.sources as unknown as OpportunitySourceFrag[]"
        @close="selectedProposal = null"
        @updated="spliceProposal($event)"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.OpportunityCatalogByFundStatus {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.input.search { width: 320px; }
.input.sub    { width: 192px; }

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)       { --table-col-width: 384px; }
.list :deep(.col-status)     { --table-col-width: 136px; }
.list :deep(.col-assignedAs) { --table-col-width: 112px; }
.list :deep(.col-fund)       { --table-col-width: 80px; }
.list :deep(.col-expiresAt)  { --table-col-width: 128px; }
.list :deep(.col-createdAt)  { --table-col-width: 152px; }
.list :deep(.col-updatedAt)  { --table-col-width: 152px; }
.list :deep(.col-expiresAt)  { --table-col-width: 144px; }
.list :deep(.col-spacer)     { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
