<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useCreateSurvey } from '@/composables/repos/SurveyRepo'
import SurveyInputFundsAsync from '@/components/survey/SurveyInputFundsAsync.vue'
import SurveyFormCreateDoneDialog from './SurveyFormCreateDoneDialog.vue'

const { t } = useTrans({
  en: {
    i_company_label: 'Company name *',
    i_company_ph: 'Example Corporation',
    submit: 'Create survey'
  },
  ja: {
    i_company_label: '会社名 *',
    i_company_ph: 'サンプル・カンパニー',
    submit: 'Surveyを作成する'
  }
})

const router = useRouter()

const createSurveyMutation = reactive(useCreateSurvey())

const surveyOps = useSurveyOps()

const doneDialog = reactive(usePower())

const { data } = useData({
  companyName: null as string | null,
  fundGroupIds: [] as number[]
})

const { validation, validateAndNotify } = useValidation(data, {
  companyName: {
    required: required(),
    maxLength: maxLength(255)
  }
})

async function submit() {
  if (await validateAndNotify()) {
    await createSurveyMutation.execute({
      companyName: data.value.companyName!,
      fundGroupIds: data.value.fundGroupIds
    })
    doneDialog.on()
  }
}

async function onDoneDialogClose() {
  router.push(surveyOps.path(createSurveyMutation.data!))
}
</script>

<template>
  <Suspense>
    <SCard>
      <SCardBlock class="s-p-48">
        <SDoc>
          <SContent>
            <h1>
              <STrans lang="en">Create a new survey</STrans>
              <STrans lang="ja">Surveyを作成する</STrans>
            </h1>
          </SContent>
          <SAlert mode="warning">
            <SContent>
              <STrans lang="en">
                <p><strong>For additional investments</strong>, make sure to include the funds that have already invested. This is because it is “mandatory” to disclose delas to the funds that have already invested.</p>
                <p><strong>When you want to add new funds</strong> to an already obtained survey, also include the funds that were disclosed in the previously obtained survey. This is because the entire survey will need to be replaced in such cases.</p>
              </STrans>
              <STrans lang="ja">
                <p><strong>追加出資の場合</strong>は必ず出資済みファンドも含めて回答してもらってください。出資済みファンドへは「必ず」案件を公開する必要があるためです。</p>
                <p><strong>すでに取得したSurveyの開示先を追加したい場合</strong>もすでに取得したSurveyで開示したファンドを含めて回答してもらってください。このケースではSurveyが丸ごと差し替えになるためです。</p>
              </STrans>
            </SContent>
          </SAlert>
          <SInputText
            :label="t.i_company_label"
            :placeholder="t.i_company_ph"
            v-model="data.companyName"
            :validation="validation.companyName"
          />
          <SurveyInputFundsAsync
            v-model="data.fundGroupIds"
          />
        </SDoc>
      </SCardBlock>
      <SCardBlock size="xlarge" class="s-px-48">
        <SControl>
          <SControlRight>
            <SControlButton
              mode="info"
              :label="t.submit"
              :loading="createSurveyMutation.loading"
              @click="submit"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>

      <SModal :open="doneDialog.state">
        <SurveyFormCreateDoneDialog
          :survey="createSurveyMutation.data!"
          @close="onDoneDialogClose"
        />
      </SModal>
    </SCard>
  </Suspense>
</template>
