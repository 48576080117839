import { ProposalStatus } from '@/graphql'
import { FundGroup } from './FundGroup'
import { type Day, Model } from './Model'
import { OpportunitySource } from './OpportunitySource'

export { ProposalStatus }

export const ProposalStatuses = {
  [ProposalStatus.Hidden]: 'Hidden',
  [ProposalStatus.Open]: 'Open',
  [ProposalStatus.Closed]: 'Closed'
} as const

export class Proposal extends Model {
  id?: number
  opportunityId?: number
  status?: ProposalStatus
  statusUpdatedAt: Day | null
  closedAt: Day | null
  closedReason?: string
  data?: Record<string, any>

  fundGroupId?: number
  fundGroup: FundGroup | null

  sourceId?: number
  source: OpportunitySource | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.opportunityId = data.opportunityId
    this.status = data.status
    this.statusUpdatedAt = this.day(data.statusUpdatedAt)
    this.closedAt = this.day(data.closedAt)
    this.closedReason = data.closedReason
    this.data = data.data

    this.fundGroupId = data.fundGroupId
    this.fundGroup = this.hasOne(FundGroup, data.fundGroup)

    this.sourceId = data.sourceId
    this.source = this.hasOne(OpportunitySource, data.source)
  }

  get statusValue(): string {
    return ProposalStatus[this.status!]
  }

  isHidden(): boolean {
    return this.status === ProposalStatus.Hidden
  }

  isOpen(): boolean {
    return this.status === ProposalStatus.Open
  }

  isClosed(): boolean {
    return this.status === ProposalStatus.Closed
  }
}
