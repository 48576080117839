import { format } from '@/support/Num'
import { Currency } from './Currency'
import { Model } from './Model'

export class Money extends Model {
  currencyId?: number | null
  currency: Currency | null
  value?: string | null

  constructor(data: Record<string, any>) {
    super()
    this.currencyId = data.currencyId
    this.currency = this.hasOne(Currency, data.currency)
    this.value = data.value
  }

  static create(currency: Currency, value: string | null): Money {
    return new this({
      currencyId: currency.id,
      currency,
      value
    })
  }

  static createJpy(value: string | null): Money {
    return new this({
      currency: Currency.createJpy(),
      value
    })
  }

  format(): string {
    const symbol = this.currency?.symbol ?? '?'
    const name = this.currency?.name ?? 'UNK'

    return `${symbol} ${this.formatValue()} ${name}`
  }

  formatValue(): string {
    const value = (this.value ?? '0').split('.')

    value[0] = format(Number(value[0]))

    return value.join('.')
  }
}
