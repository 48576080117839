import { type Mutation, type Query, useMutation, useQuery } from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  type BasicPaginationInput,
  type CreateCompleteSurveyInput,
  type CreateSurveyInput,
  type SurveyAndAllFrag,
  type SurveyFrag,
  type SurveyPageFrag,
  type SurveysCondition
} from '@/graphql'
import { SurveyRequest } from '@/requests/SurveyRequest'

export type SurveyPage = Query<SurveyPageFrag>
export type SurveyItem = Query<SurveyAndAllFrag>

export type CreateSurvey = Mutation<SurveyFrag, [input: CreateSurveyInput]>
export type CreateCompleteSurvey = Mutation<SurveyFrag, [input: CreateCompleteSurveyInput]>

export interface UseSurveyPageOptions {
  page: BasicPaginationInput
  condition: SurveysCondition
}

export function useSurveyPage(options: MaybeRefOrGetter<UseSurveyPageOptions>): SurveyPage {
  return useQuery(async () => {
    const o = toValue(options)
    return (await new SurveyRequest().fetchPage(o.page, o.condition)).data.surveys
  }, { watch: () => toValue(options) })
}

export function useSurveyItem(id: MaybeRefOrGetter<number>): SurveyItem {
  return useQuery(async () => {
    return (await new SurveyRequest().fetch(toValue(id))).data.survey
  }, { watch: () => toValue(id) })
}

export function useCreateSurvey(): CreateSurvey {
  return useMutation(async (_, input: CreateSurveyInput) => {
    return (await new SurveyRequest().create(input)).data.createSurvey
  })
}

export function createCompleteSurvey(): CreateCompleteSurvey {
  return useMutation(async (_, input: CreateCompleteSurveyInput) => {
    return (await new SurveyRequest().createComplete(input)).data.createCompleteSurvey
  })
}
