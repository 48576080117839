<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type DealForDetailsFrag } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'

const props = defineProps<{
  deal: DealForDetailsFrag
}>()

const router = useRouter()

const dealOps = useDealOps()

const show = computed(() => {
  if (!dealOps.isReport(props.deal)) {
    return false
  }

  if (props.deal.reports.length) {
    return false
  }

  return true
})

function addReport() {
  router.push(`/deals/${props.deal.id}/reports/create`)
}
</script>

<template>
  <div v-if="show" class="DealCardNextAction">
    <STrans lang="en">
      <p class="message">All actions for this deal have been completed. Please proceed to create an deal report. As a general rule, the deal report must be submitted to the LP within 1 week after the investment has been completed.</p>
      <div class="action">
        <SButton
          size="medium"
          mode="info"
          label="New deal report"
          @click="addReport"
        />
      </div>
    </STrans>
    <STrans lang="ja">
      <p class="message">当ディールのアクションが全て完了しました。続いて投資報告書を作成してください。原則、払い込み完了後1週間以内にLPへ投資報告書を提出する必要があります。</p>
      <div class="action">
        <SButton
          size="medium"
          mode="info"
          label="投資報告書を作成する"
          @click="addReport"
        />
      </div>
    </STrans>
  </div>
</template>

<style scoped lang="postcss">
.DealCardNextAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border: 1px solid var(--c-info-border);
  border-radius: 6px;
  background-color: var(--c-bg-elv-3);
  padding: 24px 32px 24px 24px;
}

.message {
  max-width: 640px;
  font-size: 14px;
}
</style>
