<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { type Company } from '@/models/Company'
import CompanyFormUpdateExecutive from './CompanyFormUpdateExecutive.vue'

defineProps<{
  company: Company
  canUpdate: boolean | null
}>()

defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    title: 'Executive information',
    l_representative_name: 'Representative name',
    l_representative_title: 'Representative title',
    l_representative_profiles: 'Representative profiles',
    l_key_personnel_notes: 'Key personnel notes'
  },
  ja: {
    title: '経営陣情報',
    l_representative_name: '代表者名',
    l_representative_title: '代表者役職',
    l_representative_profiles: '代表者プロフィール',
    l_key_personnel_notes: 'キーパーソンのメモ'
  }
})

const updateDialog = usePower()
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <SControlText class="s-font-w-500">{{ t.title }}</SControlText>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton v-if="canUpdate" :icon="IconNotePencil" @click="updateDialog.on" />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel :value="t.l_representative_name" />
          <SDescText :value="company.representativeName" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel :value="t.l_representative_title" />
          <SDescText :value="company.representativeTitle" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel :value="t.l_representative_profiles" />
          <SDescText :value="company.representativeProfiles" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel :value="t.l_key_personnel_notes" />
          <SDescText :value="company.keyPersonnelNotes" />
        </SDescItem>
      </SDesc>
    </SCardBlock>

    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <CompanyFormUpdateExecutive
        :company="company"
        @cancel="updateDialog.off"
        @updated="updateDialog.off(() => $emit('updated'))"
      />
    </SModal>
  </SCard>
</template>
