<script setup lang="ts">
import SButton from 'sefirot/components/SButton.vue'
import { type OpportunityForDetailsFrag } from '@/graphql'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()

const canUpdateOpportunity = useCanUpdateOpportunityPolicy(() => props.opportunity)
</script>

<template>
  <SCardBlock class="s-p-24">
    <div v-if="!canUpdateOpportunity.ok" class="body">
      <div class="text mute">
        No round information has been added to this opportunity.
      </div>
    </div>
    <div v-else class="body">
      <div class="text">
        No round information has been added to this opportunity. A round must exists to create deals for this opportunity.
      </div>
      <div class="action">
        <SButton
          size="small"
          mode="info"
          label="Add round"
          @click="emit('click')"
        />
      </div>
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.body {
  display: flex;
  align-items: center;
  gap: 48px;
}

.text {
  flex-grow: 1;
  max-width: 640px;
  font-size: 14px;
}

.text.mute {
  color: var(--c-text-3);
}

.link {
  color: var(--c-text-info-1);
  transition: color 0.25s;

  &:hover {
    color: var(--c-text-info-2);
  }
}
</style>
