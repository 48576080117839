<script setup lang="ts">
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import SInputYMD from 'sefirot/components/SInputYMD.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { createYmd } from 'sefirot/support/Day'
import { maxLength, maxValue, required, requiredYmd, ymd } from 'sefirot/validation/rules'
import { watch } from 'vue'
import { useCompanyForWrapup } from '@/composables/repos/CompanyRepo'
import { useWrapupCompany } from '@/composables/repos/DealRepo'
import DInputMonth from '../DInputMonth.vue'

const props = defineProps<{
  id: number
  companyId: number
}>()

const emit = defineEmits<{
  saved: []
}>()

const { t } = useTrans({
  en: {
    i_founded_label: 'Founded at *',
    i_fiscal_month_label: 'Fiscal month *',
    i_fiscal_month_ph: 'Select month',
    i_zipcode_label: 'Zip *',
    i_zipcode_ph: '1500031',
    i_address_label: 'Address *',
    i_address_ph: '10-11 Sakuragaoka-cho, Shibuya-ku, Tokyo',
    i_address_hn_label: 'House number',
    i_address_hn_ph: 'Central Plaza 202',
    i_num_employees_label: 'Employees *',
    i_num_employees_ph: '100',
    i_representative_name_label: 'Representative name *',
    i_representative_name_ph: 'John Doe',
    i_representative_title_label: 'Representative title *',
    i_representative_title_ph: 'CEO',
    i_representative_profiles_label: 'Representative profiles *',
    i_representative_profiles_ph: 'Fill in representative profile',
    save: 'Save & Next'
  },
  ja: {
    i_founded_label: '設立年月 *',
    i_fiscal_month_label: '決算月 *',
    i_fiscal_month_ph: '月を選択してください',
    i_zipcode_label: '郵便番号 *',
    i_zipcode_ph: '1500031',
    i_address_label: '住所',
    i_address_ph: '東京都渋谷区桜が丘10-11',
    i_address_hn_label: '建物名',
    i_address_hn_ph: 'セントラルプラザ202',
    i_num_employees_label: '従業員数 *',
    i_num_employees_ph: '100',
    i_representative_name_label: '代表者名 *',
    i_representative_name_ph: '山田 太郎',
    i_representative_title_label: '代表者役職 *',
    i_representative_title_ph: 'CEO',
    i_representative_profiles_label: '代表者プロフィール *',
    i_representative_profiles_ph: '代表者のプロフィールを入力してください',
    save: '保存して次へ'
  }
})

const { data: company } = useCompanyForWrapup(props.companyId)
const { execute, loading } = useWrapupCompany()

const { data } = useData({
  founded: createYmd(),
  fiscalMonth: null as number | null,
  zipcode: null as string | null,
  address: null as string | null,
  addressHouseNumber: null as string | null,
  numEmployees: null as number | null,
  representativeName: null as string | null,
  representativeTitle: null as string | null,
  representativeProfiles: null as string | null
})

const { validation, validate, validateAndNotify } = useValidation(data, {
  founded: {
    requiredYmd: requiredYmd(['y', 'm']),
    ymd: ymd(['y', 'm'])
  },
  fiscalMonth: {
    required: required()
  },
  zipcode: {
    required: required(),
    maxLength: maxLength(10)
  },
  address: {
    required: required(),
    maxLength: maxLength(255)
  },
  addressHouseNumber: {
    maxLength: maxLength(255)
  },
  numEmployees: {
    required: required(),
    maxValue: maxValue(1000000)
  },
  representativeName: {
    required: required(),
    maxLength: maxLength(70)
  },
  representativeTitle: {
    required: required(),
    maxLength: maxLength(100)
  },
  representativeProfiles: {
    required: required(),
    maxLength: maxLength(500)
  }
})

watch(company, (company) => {
  data.value.founded = createYmd(company!.foundedYear, company!.foundedMonth)
  data.value.fiscalMonth = company!.fiscalMonth
  data.value.zipcode = company!.zipcode
  data.value.address = company!.address
  data.value.addressHouseNumber = company!.addressHouseNumber
  data.value.numEmployees = company!.numEmployees
  data.value.representativeName = company!.representativeName
  data.value.representativeTitle = company!.representativeTitle
  data.value.representativeProfiles = company!.representativeProfiles
  validate()
})

async function save() {
  if (await validateAndNotify()) {
    await execute(props.id, {
      foundedYear: data.value.founded.year!,
      foundedMonth: data.value.founded.month!,
      fiscalMonth: data.value.fiscalMonth!,
      zipcode: data.value.zipcode!,
      address: data.value.address!,
      addressHouseNumber: data.value.addressHouseNumber,
      numEmployees: data.value.numEmployees!,
      representativeName: data.value.representativeName!,
      representativeTitle: data.value.representativeTitle!,
      representativeProfiles: data.value.representativeProfiles!
    })
    emit('saved')
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-48">
      <SDoc>
        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Finalize company information</h2>
              <p>A deal report requires the following company information to be present. Fill in any missing information and also check if all fields are up to date.</p>
            </STrans>
            <STrans lang="ja">
              <h2>企業情報のファイナライズ</h2>
              <p>投資報告書を作成するには以下の会社情報が必要です。不足している情報を入力し、すべての項目が最新であることを確認してください。</p>
            </STrans>
          </SContent>
        </SDocSection>
        <SDivider />
        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Base information</h2>
              <p>Fill in base company information.</p>
            </STrans>
            <STrans lang="ja">
              <h2>基本情報</h2>
              <p>企業情報を入力してください。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="6">
              <SInputYMD
                :label="t.i_founded_label"
                no-date
                v-model="data.founded"
                :validation="validation.founded"
              />
            </SGridItem>

            <SGridItem span="2">
              <DInputMonth
                :label="t.i_fiscal_month_label"
                :placeholder="t.i_fiscal_month_ph"
                v-model="data.fiscalMonth"
                :validation="validation.fiscalMonth"
              />
            </SGridItem>
            <SGridItem span="4" />

            <SGridItem span="2">
              <SInputText
                :label="t.i_zipcode_label"
                :placeholder="t.i_zipcode_ph"
                v-model="data.zipcode"
                :validation="validation.zipcode"
              />
            </SGridItem>

            <SGridItem span="6">
              <SInputText
                :label="t.i_address_label"
                :placeholder="t.i_address_ph"
                v-model="data.address"
                :validation="validation.address"
              />
            </SGridItem>

            <SGridItem span="6">
              <SInputText
                :label="t.i_address_hn_label"
                :placeholder="t.i_address_hn_ph"
                v-model="data.addressHouseNumber"
                :validation="validation.addressHouseNumber"
              />
            </SGridItem>

            <SGridItem span="2">
              <SInputNumber
                :label="t.i_num_employees_label"
                :placeholder="t.i_num_employees_ph"
                v-model="data.numEmployees"
                :validation="validation.numEmployees"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>

        <SDivider />

        <SDocSection>
          <SContent>
            <STrans lang="en">
              <h2>Representative information</h2>
              <p>Fill in representative information.</p>
            </STrans>
            <STrans lang="ja">
              <h2>代表者情報</h2>
              <p>代表者に関する情報を入力してください。</p>
            </STrans>
          </SContent>

          <SGrid gap="24" cols="6">
            <SGridItem span="3">
              <SInputText
                :label="t.i_representative_name_label"
                :placeholder="t.i_representative_name_ph"
                v-model="data.representativeName"
                :validation="validation.representativeName"
              />
            </SGridItem>

            <SGridItem span="3">
              <SInputText
                :label="t.i_representative_title_label"
                :placeholder="t.i_representative_title_ph"
                v-model="data.representativeTitle"
                :validation="validation.representativeTitle"
              />
            </SGridItem>

            <SGridItem span="6">
              <SInputTextarea
                :label="t.i_representative_profiles_label"
                :placeholder="t.i_representative_profiles_ph"
                v-model="data.representativeProfiles"
                :validation="validation.representativeProfiles"
              />
            </SGridItem>
          </SGrid>
        </SDocSection>
      </SDoc>
    </SCardBlock>

    <SCardBlock size="xlarge" class="s-px-48">
      <SControl>
        <SControlRight>
          <SControlButton
            mode="info"
            :label="t.save"
            :loading="loading"
            @click="save"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
