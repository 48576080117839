<script setup lang="ts">
import IconCaretLeft from '~icons/ph/caret-left-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { type ProposableFundGroupFrag } from '@/graphql'

const props = defineProps<{
  proposableFundGroups: ProposableFundGroupFrag[]
  loading: boolean
}>()

defineEmits<{
  back: []
  cancel: []
  add: []
}>()

const { t } = useTrans({
  en: {
    a_back: 'Back',
    a_cancel: 'Cancel',
    a_add: 'Open to the above funds'
  },
  ja: {
    a_back: '戻る',
    a_cancel: 'キャンセル',
    a_add: '上記ファンドへ開示'
  }
})
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Open this opportunity to funds</h2>
            <p>Opening this opportunity to following funds. <strong>Once the opportunity is open to funds, it cannot be undone</strong>. Be absolutely sure before proceeding.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Opportunityをファンドへ開示する</h2>
            <p>このOpportunityを以下のファンドへ開示します。<strong>一度開示したOpportunityは非開示に戻せません。</strong>改めて開示するファンドに間違いがない確認してください。</p>
          </STrans>
        </SContent>
        <SCard>
          <SCardBlock
            v-for="pfg in props.proposableFundGroups"
            :key="pfg.fundGroup.id"
            size="small"
          >
            <div class="title">
              <div class="fund">{{ pfg.fundGroup.name }}</div>
              <div class="lp">{{ pfg.fundGroup.lp }}</div>
            </div>
          </SCardBlock>
        </SCard>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlLeft>
          <SControlButton
            :icon="IconCaretLeft"
            :label="t.a_back"
            :disabled="loading"
            @click="$emit('back')"
          />
        </SControlLeft>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_add"
            :loading="loading"
            @click="$emit('add')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.title {
  display: flex;
  padding: 0 16px;
}

.fund {
  width: 128px;
  font-size: 14px;
  font-weight: 500;
}

.lp {
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
