<script setup lang="ts">
import IconFileText from '~icons/ph/file-text-bold'
import { usePower } from 'sefirot/composables/Power'
import { type ActionNote } from '@/models/ActionNote'
import { type Company } from '@/models/Company'
import ActionNoteCard from './ActionNoteCard.vue'

defineProps<{
  actionNote: ActionNote
  company: Company
}>()

const emit = defineEmits<{
  (e: 'updated'): void
  (e: 'deleted'): void
}>()

const { state, on, off } = usePower(false)

function onDeleted() {
  off()
  setTimeout(() => emit('deleted'), 500)
}
</script>

<template>
  <DCardListItem
    :icon="IconFileText"
    :title="actionNote.title"
    :tags="[
      { text: `Created at ${actionNote.createdAt!.format('YYYY/MM/DD')}` }
    ]"
    :on-click="on"
  />

  <SModal :open="state" @close="off">
    <ActionNoteCard
      :action-note="(actionNote as any)"
      :company="(company as any)"
      @close="off"
      @updated="emit('updated')"
      @deleted="onDeleted"
    />
  </SModal>
</template>
