<template>
  <div class="DealPageReportsListEmpty">
    <STrans lang="en">No report has been created yet.</STrans>
    <STrans lang="ja">投資報告書が作成されていません。</STrans>
  </div>
</template>

<style scoped lang="postcss">
.DealPageReportsListEmpty {
  border: 1px solid var(--c-divider-2);
  border-radius: 6px;
  padding: 32px;
  text-align: center;
  font-size: 14px;
  color: var(--c-text-2);
  background-color: var(--c-bg-elv-3);
}
</style>
