<script setup lang="ts">
import { keyBy, pick } from 'lodash-es'
import { useData } from 'sefirot/composables/Data'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type UnwrapRef, ref, watch } from 'vue'
import { type DealForReportDetailFrag, type DealReportFrag, DealReportStatus } from '@/graphql'
import { yearMonthFromYmd, ymdFromYearMonth } from '@/support/Day'
import { useMe } from '@/composables/Auth'
import { useDealOps } from '@/composables/ops/DealOps'
import { useDealReportOps } from '@/composables/ops/DealReportOps'
import { useCanCompleteReport, useCanIncompleteReport, useCanUpdateReport } from '@/composables/policies/DealPolicy'
import { useCurrencyList } from '@/composables/repos/CurrencyRepo'
import { useCompleteDealReport, useExportDealReport, useIncompleteDealReport, useUpdateDealReport } from '@/composables/repos/DealReportRepo'
import DealDialogConfirmCompleteReport from './DealDialogConfirmCompleteReport.vue'
import DealDialogConfirmIncompleteReport from './DealDialogConfirmIncompleteReport.vue'
import DealDialogDoneCompleteReport from './DealDialogDoneCompleteReport.vue'
import DealDialogDoneSaveReport from './DealDialogDoneSaveReport.vue'
import DealDialogExportReportCompleted from './DealDialogExportReportCompleted.vue'
import DealFormUpdateReportActionsForAdmin from './DealFormUpdateReportActionsForAdmin.vue'
import DealFormUpdateReportActionsForCapitalist from './DealFormUpdateReportActionsForCapitalist.vue'
import DealFormUpdateReportBase from './DealFormUpdateReportBase.vue'
import DealFormUpdateReportCompany from './DealFormUpdateReportCompany.vue'
import DealFormUpdateReportExtraInfo from './DealFormUpdateReportExtraInfo.vue'
import DealFormUpdateReportPreIssuedShares from './DealFormUpdateReportPreIssuedShares.vue'
import DealFormUpdateReportSecurities from './DealFormUpdateReportSecurities.vue'
import DealFormUpdateReportStatus from './DealFormUpdateReportStatus.vue'

const props = defineProps<{
  deal: DealForReportDetailFrag
  report: DealReportFrag
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

const { user } = useMe()

const { data: currencies } = useCurrencyList()

const updateDealReport = useUpdateDealReport()
const exportDealReport = useExportDealReport()
const completeDealReport = useCompleteDealReport()
const incompleteDealReport = useIncompleteDealReport()

const dealOps = useDealOps()
const dealReportOps = useDealReportOps()

const doneSaveDialog = usePower()
const exportCompletedDialog = usePower()
const confirmCompleteDialog = usePower()
const doneCompleteDialog = usePower()
const confirmIncompleteDialog = usePower()

const canSave = useCanUpdateReport(() => props.deal, () => props.report)
const canComplete = useCanCompleteReport(() => props.deal, () => props.report)
const canIncomplete = useCanIncompleteReport(() => props.deal, () => props.report)

const { data } = useData({
  strict: 'no' as DataYesOrNo,
  editable: props.report.status === DealReportStatus.Draft && canSave.value.ok ? 'yes' : 'no' as DataYesOrNo,
  name: props.report.name,
  investedDate: dealReportOps.investedDate(props.report),
  reportedDate: dealReportOps.reportedDate(props.report),
  company: props.report.company as string | null,
  representativeName: props.report.representativeName as string | null,
  representativeTitle: props.report.representativeTitle as string | null,
  founded: ymdFromYearMonth(props.report.founded),
  fiscalMonth: props.report.fiscalMonth as number | null,
  capitalValue: props.report.capital?.value ?? null,
  capitalCurrency: props.report.capital?.currency ?? null,
  employees: props.report.employees as number | null,
  address: props.report.address as string | null,
  description: props.report.description as string | null,
  preIssuedShares: props.report.preIssuedShares,
  transactions: createTransactionData(),
  extraInfo: pick(props.report.extraInfo, props.report.extraInfoSettings.map((field) => field.name))
})

const { validateAndNotify } = useValidation()

export type DataYesOrNo = 'yes' | 'no'

export type ExtraInfo = UnwrapRef<typeof data>['extraInfo']

function updateExtraInfo(extraInfo: ExtraInfo) {
  Object.entries(extraInfo).forEach(([key, value]) => {
    data.value.extraInfo[key] = value ?? data.value.extraInfo[key]
  })
}

// Defining these loading states manually here because `exporting` and
// `completing` is a mix of `saving`. When exporting/completing, we first
// save the record. During that, we don't want to show spinner on the
// save button.
const saving = ref(false)
const exporting = ref(false)
const completing = ref(false)
const incompleting = ref(false)

watch(currencies, () => {
  data.value.capitalCurrency = dealOps.currency(props.deal)
})

function createTransactionData() {
  const currency = dealOps.currency(props.deal)
  const transactionDict = keyBy(props.report.transactions, 'securityId')

  return props.deal.securities.map((security) => ({
    security,
    currency,
    quantity: transactionDict[security.id]?.quantity ?? null,
    unitPrice: transactionDict[security.id]?.unitPrice ?? null,
    totalPrice: transactionDict[security.id]?.totalPrice ?? null,
    totalPriceFmc: transactionDict[security.id]?.totalPriceFmc ?? null
  }))
}

async function save() {
  data.value.strict = 'no'
  saving.value = true
  const result = await doSave()
  saving.value = false

  if (user.hasRole('Capitalist') && result) {
    doneSaveDialog.on()
  }
}

async function doSave() {
  if (!(await validateAndNotify())) {
    return false
  }

  await updateDealReport.execute(props.report.id, {
    name: data.value.name,
    investedDate: data.value.investedDate?.format('YYYY-MM-DD') ?? null,
    reportedDate: data.value.reportedDate?.format('YYYY-MM-DD') ?? null,
    company: data.value.company!,
    representativeName: data.value.representativeName!,
    representativeTitle: data.value.representativeTitle!,
    Founded: yearMonthFromYmd(data.value.founded),
    fiscalMonth: data.value.fiscalMonth!,
    capital: createCapitalInput(),
    employees: data.value.employees!,
    address: data.value.address!,
    description: data.value.description!,
    preIssuedShares: data.value.preIssuedShares,
    transactions: createTransactionsInput(),
    extraInfo: JSON.stringify(data.value.extraInfo)
  })

  return true
}

async function exportReport() {
  data.value.strict = 'yes'
  exporting.value = true

  const saveOk = await doSave()

  if (saveOk) {
    await exportDealReport.execute(props.report.id)
    exportCompletedDialog.on()
  }

  exporting.value = false
}

async function requestCompleteReport() {
  data.value.strict = 'yes'

  const saveOk = await doSave()

  if (saveOk) {
    confirmCompleteDialog.on()
  }
}

async function completeReport() {
  data.value.strict = 'yes'
  completing.value = true

  const saveOk = await doSave()

  if (saveOk) {
    await completeDealReport.execute(props.report.id)
    emit('updated')
    confirmCompleteDialog.off()
    doneCompleteDialog.on()
  }

  completing.value = false
}

async function incompleteReport() {
  incompleting.value = true

  await incompleteDealReport.execute(props.report.id)
  emit('updated')

  incompleting.value = false
  confirmIncompleteDialog.off()
}

function createCapitalInput() {
  if (data.value.capitalValue === null) {
    return null
  }

  return {
    currencyId: data.value.capitalCurrency!.id,
    value: data.value.capitalValue
  }
}

function createTransactionsInput() {
  return data.value.transactions.map((transaction) => ({
    securityId: transaction.security.id,
    quantity: transaction.quantity,
    unitPrice: transaction.unitPrice,
    totalPrice: transaction.totalPrice,
    totalPriceFmc: transaction.totalPriceFmc
  }))
}
</script>

<template>
  <DCard :header="false">
    <DCardView>
      <DCardBlock>
        <DForm space="wide">
          <DealFormUpdateReportBase
            :strict="data.strict"
            :editable="data.editable"
            v-model:name="data.name"
            v-model:invested-date="data.investedDate"
            v-model:reported-date="data.reportedDate"
          />
          <DealFormUpdateReportCompany
            :editable="data.editable"
            :currencies="currencies"
            v-model:company="data.company"
            v-model:representative-name="data.representativeName"
            v-model:representative-title="data.representativeTitle"
            v-model:founded="data.founded"
            v-model:fiscal-month="data.fiscalMonth"
            v-model:capital-value="data.capitalValue"
            v-model:capital-currency="data.capitalCurrency"
            v-model:employees="data.employees"
            v-model:address="data.address"
            v-model:description="data.description"
          />
          <DealFormUpdateReportPreIssuedShares
            :pre-issued-shares="data.preIssuedShares"
          />
          <DealFormUpdateReportSecurities
            :strict="data.strict"
            :editable="data.editable"
            v-model:transactions="data.transactions"
          />
          <DealFormUpdateReportExtraInfo
            v-if="(report as any).extraInfoSettings.length"
            :fields="(report as any).extraInfoSettings"
            :strict="data.strict"
            :editable="data.editable"
            :data="data.extraInfo"
            @update:data="updateExtraInfo"
          />
        </DForm>

        <DealFormUpdateReportActionsForCapitalist
          v-if="user.hasRole('Capitalist')"
          :saving="saving"
          :can-save="canSave"
          @save="save"
        />

        <DealFormUpdateReportActionsForAdmin
          v-else
          :deal-status="deal.status"
          :report-status="report.status"
          :saving="saving"
          :exporting="exporting"
          :completing="completing"
          :incompleting="incompleting"
          :can-save="canSave"
          :can-complete="canComplete"
          :can-incomplete="canIncomplete"
          @save="save"
          @export="exportReport"
          @complete="requestCompleteReport"
          @incomplete="confirmIncompleteDialog.on()"
        />
      </DCardBlock>

      <DCardBlock size="small" bg="soft">
        <DealFormUpdateReportStatus
          :deal="deal"
          :report="report"
        />
      </DCardBlock>
    </DCardView>

    <DealDialogDoneSaveReport
      :open="doneSaveDialog.state.value"
      @close="doneSaveDialog.off()"
    />

    <DealDialogExportReportCompleted
      :open="exportCompletedDialog.state.value"
      @close="exportCompletedDialog.off()"
    />

    <DealDialogConfirmCompleteReport
      :open="confirmCompleteDialog.state.value"
      :completing="completing"
      @complete="completeReport"
      @close="confirmCompleteDialog.off()"
    />

    <DealDialogDoneCompleteReport
      :open="doneCompleteDialog.state.value"
      @close="doneCompleteDialog.off()"
    />

    <DealDialogConfirmIncompleteReport
      :open="confirmIncompleteDialog.state.value"
      :deal="deal"
      :incompleting="incompleting"
      @incomplete="incompleteReport"
      @close="confirmIncompleteDialog.off()"
    />
  </DCard>
</template>
