import { type CurrencyWithoutIdFrag } from '@/graphql'
import { defineOps } from './Ops'

export const useCurrencyOps = defineOps(() => {
  function createJpy(): CurrencyWithoutIdFrag {
    return {
      symbol: '¥',
      name: 'JPY'
    }
  }

  return {
    createJpy
  }
})
