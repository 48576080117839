<script setup lang="ts">
import IconBuilding from '~icons/ph/buildings'
import { xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computedWhen } from 'sefirot/composables/Utils'
import { computed } from 'vue'
import { DealOrderField, type DealStatus, OrderDirection } from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useDealPage } from '@/composables/repos/DealRepo'
import { useActiveFundList } from '@/composables/repos/FundRepo'
import { useEnabledRoundNameList } from '@/composables/repos/RoundNameRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'
import DInputSearch from '../DInputSearch.vue'

const { data: options, init: reset } = useData({
  page: {
    page: 0,
    perPage: 50
  },
  condition: {
    query: null as string | null,
    round: [] as number[],
    fund: [] as number[],
    status: [] as DealStatus[],
    assignee: [] as number[]
  },
  orderBy: {
    field: DealOrderField.UpdatedAt as DealOrderField,
    direction: OrderDirection.Desc as OrderDirection
  }
})

useUrlQuerySync(options, {
  casts: {
    'page.page': Number,
    'condition.query': (v) => (v === '' || v === null) ? null : v,
    'condition.round': (v) => v.map(Number),
    'condition.fund': (v) => v.map(Number),
    'condition.assignee': (v) => v.map(Number)
  },
  exclude: ['page.perPage']
})

const { data: deals, loading: loadingDeals } = useDealPage(options)
const { data: rounds } = useEnabledRoundNameList()
const { data: funds } = useActiveFundList()
const { data: users } = useActiveUserList()

const dealOps = useDealOps()
const roundOps = useRoundOps()
const userOps = useUserOps()

const table = useTable({
  records: computed(() => deals.value?.items),
  loading: loadingDeals,
  borderless: true,
  orders: [
    'name',
    'round',
    'fund',
    'status',
    'assignee',
    'deadline',
    'investmentAmount',
    'investedAmount',
    'createdAt',
    'updatedAt',
    'spacer'
  ],
  columns: {
    name: {
      label: 'Name',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => updateOrder(DealOrderField.Name, OrderDirection.Asc)
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => updateOrder(DealOrderField.Name, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, deal) => ({
        type: 'text',
        icon: IconBuilding,
        value: dealOps.fullTitle(deal),
        link: dealOps.path(deal),
        color: 'neutral'
      })
    },
    round: {
      label: 'Round',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.round),
          options: computedWhen(rounds, (rounds) => {
            return rounds.map((round) => ({ label: round.name, value: round.id! }))
          }, []),
          onClick(value: number) {
            updateOptions(() => {
              options.value.condition.round = xor(options.value.condition.round, [value])
            })
          }
        }
      ]),
      cell: (_, deal) => ({
        type: 'text',
        value: roundOps.name(deal.round)
      })
    },
    fund: {
      label: 'Fund',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.fund),
          options: computedWhen(funds, (funds) => {
            return funds.map((fund) => ({ label: fund.nameAbbreviated + (fund.group?.lp ? ` (${fund.group?.lp})` : ''), value: fund.id! }))
          }, []),
          onClick(value: number) {
            updateOptions(() => {
              options.value.condition.fund = xor(options.value.condition.fund, [value])
            })
          }
        }
      ]),
      cell: (_, deal) => ({
        type: 'text',
        value: deal.fund.nameAbbreviated
      })
    },
    status: {
      label: 'Status',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.status),
          options: computed(() => {
            return dealOps.statusTextEntries().map(([value, label]) => ({ label, value }))
          }),
          onClick(value: DealStatus) {
            updateOptions(() => {
              options.value.condition.status = xor(options.value.condition.status, [value])
            })
          }
        }
      ]),
      cell: (_, deal) => ({
        type: 'state',
        mode: dealOps.statusMode(deal),
        label: dealOps.statusText(deal)
      })
    },
    assignee: {
      label: 'Assignees',
      dropdown: createDropdown([
        {
          type: 'filter',
          search: true,
          selected: computed(() => options.value.condition.assignee),
          options: computedWhen(users, (users) => {
            return users.map((user) => ({ label: userOps.name(user), value: user.id }))
          }, []),
          onClick(value: number) {
            updateOptions(() => {
              options.value.condition.assignee = xor(options.value.condition.assignee, [value])
            })
          }
        }
      ]),
      cell: (_, deal) => ({
        type: 'avatars',
        avatars: dealOps.mainAssignees(deal).map((user) => ({
          image: userOps.avatarPath(user),
          name: userOps.name1st(user)
        }))
      })
    },
    deadline: {
      label: 'Deadline',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(DealOrderField.Deadline, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(DealOrderField.Deadline, OrderDirection.Desc) }
            }
          ]
        }
      ]),
      cell: (_, deal) => ({
        type: 'day',
        value: dealOps.deadline(deal),
        format: 'YYYY-MM-DD'
      })
    },
    investmentAmount: {
      label: 'Est. Deal amount',
      cell: (_, deal) => ({
        type: 'text',
        value: dealOps.investmentAmountText(deal)
      })
    },
    investedAmount: {
      label: 'Invested amount',
      cell: (_, deal) => ({
        type: 'text',
        value: dealOps.investedAmountText(deal)
      })
    },
    createdAt: {
      label: 'Created at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => { updateOrder(DealOrderField.CreatedAt, OrderDirection.Asc) }
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => { updateOrder(DealOrderField.CreatedAt, OrderDirection.Desc) }
            }
          ]
        }
      ]),
      cell: (_, deal) => ({
        type: 'day',
        value: dealOps.createdAt(deal),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: 'Updated at',
      dropdown: createDropdown([
        {
          type: 'menu',
          options: [
            {
              label: 'Sort ascending (A...Z)',
              onClick: () => updateOrder(DealOrderField.UpdatedAt, OrderDirection.Asc)
            },
            {
              label: 'Sort descending (Z...A)',
              onClick: () => updateOrder(DealOrderField.UpdatedAt, OrderDirection.Desc)
            }
          ]
        }
      ]),
      cell: (_, deal) => ({
        type: 'day',
        value: dealOps.updatedAt(deal),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function updateQuery(value: string | null) {
  updateOptions(() => {
    options.value.condition.query = value === '' ? null : value
  })
}

function updateOrder(field: DealOrderField, direction: OrderDirection) {
  updateOptions(() => {
    options.value.orderBy.field = field
    options.value.orderBy.direction = direction
  })
}

function updateOptions(fn: () => void) {
  options.value.page.page = 0
  fn()
}
</script>

<template>
  <div class="DealCatalog">
    <SCard>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                placeholder="Search deals"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              label="Reset filters"
              @click="reset"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlRight>
            <SControlPagination
              :total="deals?.pageInfo.total ?? 0"
              :page="deals ? deals.pageInfo.page + 1 : 0"
              :per-page="deals?.pageInfo.perPage ?? 0"
              @prev="options.page.page--"
              @next="options.page.page++"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.DealCatalog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 128px;
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)             { --table-col-width: 512px; }
.list :deep(.col-round)            { --table-col-width: 144px; }
.list :deep(.col-fund)             { --table-col-width: 112px; }
.list :deep(.col-status)           { --table-col-width: 192px; }
.list :deep(.col-assignee)         { --table-col-width: 256px; }
.list :deep(.col-deadline)         { --table-col-width: 136px; }
.list :deep(.col-investmentAmount) { --table-col-width: 180px; }
.list :deep(.col-investedAmount)   { --table-col-width: 180px; }
.list :deep(.col-createdAt)        { --table-col-width: 176px; }
.list :deep(.col-updatedAt)        { --table-col-width: 176px; }
.list :deep(.col-spacer)           { --table-col-width: 40px; --table-col-max-width: 100%; }
</style>
