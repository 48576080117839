import { watch } from 'vue'
import { FundClosingCheckList } from '@/models/FundClosingCheckList'
import { CreatedFundClosingCheckList } from '@/models/FundClosingCheckListCreated'
import {
  type BasicPaginationInput,
  type CreateFundClosingCheckListInput,
  type FundClosingCheckListOrder,
  FundClosingCheckListOrderField,
  FundClosingCheckListRequest,
  OrderDirection
} from '@/requests/FundClosingCheckListRequest'
import { type UseQueryOptions, createPage, useMutation, useQuery } from '../Api'

export type { FundClosingCheckListOrder }
export { FundClosingCheckListOrderField, OrderDirection }

export interface PageOptions {
  page: BasicPaginationInput
  orderBy: FundClosingCheckListOrder
}

export function usePage(options: PageOptions) {
  const query = useQuery(async () => {
    const res = await new FundClosingCheckListRequest().fetchPage(
      options.page,
      options.orderBy
    )

    return createPage(
      res.data.fundClosingChecklists.pageInfo,
      res.data.fundClosingChecklists.items.map((f) => new FundClosingCheckList(f))
    )
  })

  watch(() => options, query.execute, { deep: true })

  return query
}

export function useItem(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new FundClosingCheckListRequest().fetch(id)

    return new FundClosingCheckList(res.data.fundClosingChecklist!)
  }, options)
}

export function useCreate(
  input: () => CreateFundClosingCheckListInput
) {
  return useMutation(async () => {
    const res = await new FundClosingCheckListRequest().create(input())

    return new CreatedFundClosingCheckList(res.data.fundClosingChecklist)
  })
}

export function useExport(id: number) {
  return useMutation(async () => {
    await new FundClosingCheckListRequest().export(id)
  })
}
