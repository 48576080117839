<script setup lang="ts">
import IconFileText from '~icons/ph/file-text-bold'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed, ref } from 'vue'
import { type UserFrag } from '@/graphql'
import { type ActionNote } from '@/models/ActionNote'
import { useUserOps } from '@/composables/ops/UserOps'
import ActionNoteCard from '../action-note/ActionNoteCard.vue'

const props = withDefaults(defineProps<{
  query: string | null
  loading: boolean
  total?: number
  actionNotes?: ActionNote[]
}>(), {
  actionNotes: () => []
})

const emit = defineEmits<{
  (e: 'updated'): void
  (e: 'deleted'): void
}>()

const userOps = useUserOps()

const selectedActionNoteId = ref<number | null>(null)
const selectedActionNote = computed(() => props.actionNotes.find((an) => an.id === selectedActionNoteId.value) ?? null)

function onDeleted() {
  setTimeout(() => {
    selectedActionNoteId.value = null
    emit('deleted')
  }, 500)
}

const table = useTable({
  orders: [
    'name',
    'createdBy',
    'createdAt',
    'updatedBy',
    'updatedAt'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: {
        icon: IconFileText,
        type: 'text',
        color: 'neutral',
        iconColor: 'soft',
        onClick: (_, record) => selectedActionNoteId.value = record.id!
      }
    },
    createdBy: {
      label: 'Created by',
      cell: (users) => ({
        type: 'avatars',
        avatars: users,
        color: 'soft'
      })
    },
    createdAt: {
      label: 'Created at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    updatedBy: {
      label: 'Updated by',
      cell: (users) => ({
        type: 'avatars',
        avatars: users,
        color: 'soft'
      })
    },
    updatedAt: {
      label: 'Updated at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    }
  },
  records: computed(() => props.actionNotes.map((an) => ({
    id: an.id,
    name: `${an.company?.name} / ${an.title}`,
    createdBy: [an.createdBy].map((user) => ({
      image: userOps.avatarPath(user as UserFrag),
      name: user?.name ?? ''
    })),
    createdAt: an.createdAt?.format('YYYY/MM/DD') ?? '',
    updatedBy: [an.updatedBy].map((user) => ({
      image: userOps.avatarPath(user as UserFrag),
      name: user?.name ?? ''
    })),
    updatedAt: an.updatedAt?.format('YYYY/MM/DD') ?? ''
  }))),
  total: computed(() => props.total ?? 0),
  loading: computed(() => props.loading)
})

const link = computed(() => {
  return props.query
    ? `/companies/action-notes?condition[query]=${props.query}`
    : '/companies/action-notes'
})
</script>

<template>
  <div class="SearchResultContentActionNotes">
    <div class="catalog">
      <STable class="list" :options="table" />

      <div v-if="!loading && actionNotes?.length" class="action">
        <SButton
          type="outline"
          mode="info"
          :href="link"
          label="View more"
          rounded
        />
      </div>

      <SModal :open="!!selectedActionNote">
        <ActionNoteCard
          v-if="selectedActionNote"
          :action-note="(selectedActionNote as any)"
          :company="(selectedActionNote as any).company"
          @close="selectedActionNoteId = null"
          @updated="emit('updated')"
          @deleted="onDeleted"
        />
      </SModal>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)      { --table-col-width: 512px; }
.list :deep(.col-createdBy) { --table-col-width: 192px; }
.list :deep(.col-createdAt) { --table-col-width: 144px; }
.list :deep(.col-updatedBy) { --table-col-width: 192px; }
.list :deep(.col-updatedAt) { --table-col-width: 144px; }

.action {
  padding-top: 32px;
  text-align: center;

  --button-padding: 0 24px;
}
</style>
