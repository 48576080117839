<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUserCompanyAssignedCollection, useUserItem } from '@/composables/user/UserData'
import UsersProfileSection from '@/components/users/UsersProfileSection.vue'

const route = useRoute()

const { data: user } = useUserItem(Number(route.params.id))
const { data: companies, loading } = useUserCompanyAssignedCollection(Number(route.params.id))

const table = useTable({
  orders: [
    'name',
    'portfolioOf',
    'assignedAs'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: (_, record) => ({
        type: 'text',
        link: record.path
      })
    },
    portfolioOf: {
      label: 'Portfolio of',
      cell: {
        type: 'text'
      }
    },
    assignedAs: {
      label: 'Assigned as',
      cell: {
        type: 'text'
      }
    }
  },
  records: computed(() => companies.value?.map((c) => ({
    id: c.id,
    path: `/companies/${c.id}`,
    name: c.name,
    portfolioOf: [
      ...c.portfolioOf.map(({ nameAbbreviated }) => nameAbbreviated),
      ...c.exPortfolioOf.map(({ nameAbbreviated }) => nameAbbreviated)
    ].join('・'),
    assignedAs: c.primaryInCharge?.id === user.value?.id
      ? 'Primary in-charge'
      : c.deputyInCharge?.id === user.value?.id
        ? 'Deputy in-charge'
        : c.participants.map(({ id }) => id).includes(user.value?.id)
          ? 'Participant'
          : ''
  }))),
  total: computed(() => companies.value?.length),
  loading
})
</script>

<template>
  <UsersProfileSection
    class="UsersProfileCompaniesAssignedCatalog"
    title="Assigned portfolio companies"
  >
    <STable class="list" :options="table" />
  </UsersProfileSection>
</template>

<style scoped lang="postcss">
.list :deep(.col-name)        { --table-col-width: 400px; }
.list :deep(.col-portfolioOf) { --table-col-width: 225px; }
.list :deep(.col-assignedAs)  { --table-col-width: 225px; }
</style>
