<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type CompanyWithAssigneesFrag, type UserFrag } from '@/graphql'
import { type Day } from '@/models/Model'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  updatedBy: UserFrag
  updatedAt: Day
  company: CompanyWithAssigneesFrag
}>()

const { t } = useTrans({
  en: {
    primary_in_charge: 'Primary in-charge',
    deputy_in_charge: 'Deputy in-charge',
    participants: 'Participants',
    updated_by: 'Updated by',
    updated_at: 'Updated at'
  },
  ja: {
    primary_in_charge: '主担当',
    deputy_in_charge: '副担当',
    participants: '協力者',
    updated_by: '更新者',
    updated_at: '更新日時'
  }
})

const userOps = useUserOps()

const userGrids = computed(() => [
  {
    label: t.primary_in_charge,
    users: props.company.primaryInCharge
      ? [
          {
            avatar: userOps.avatarPath(props.company.primaryInCharge),
            name: userOps.name(props.company.primaryInCharge)
          }
        ]
      : []
  },
  {
    label: t.deputy_in_charge,
    users: props.company.deputyInCharge
      ? [
          {
            avatar: userOps.avatarPath(props.company.deputyInCharge),
            name: userOps.name(props.company.deputyInCharge)
          }
        ]
      : []
  },
  {
    label: t.participants,
    users: props.company.participants.map((p) => ({
      avatar: userOps.avatarPath(p),
      name: userOps.name(p)
    }))
  },
  {
    label: t.updated_by,
    users: [{
      avatar: userOps.avatarPath(props.updatedBy),
      name: userOps.name(props.updatedBy)
    }]
  }
])
</script>

<template>
  <SCardBlock class="CompanyBusinessReportInfo s-p-24">
    <SDesc dir="row" label-width="128" gap="16" :divider="false">
      <SDescItem v-for="userGrid in userGrids" :key="userGrid.label">
        <SDescLabel>{{ userGrid.label }}</SDescLabel>
        <SDescAvatar :avatar="userGrid.users" />
      </SDescItem>
      <SDescItem>
        <SDescLabel>{{ t.updated_at }}</SDescLabel>
        <SDescDay :value="updatedAt" format="YYYY-MM-DD HH:mm" />
      </SDescItem>
    </SDesc>
  </SCardBlock>
</template>
