<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { type KadomonyNewsSource, type KadomonyNewsType } from '@/graph/Kadomony'
import { useKadomonyNewsPage } from '@/composables/repos/KadomonyRepo'
import CompanyNewsPageFilters from './CompanyNewsPageFilters.vue'
import CompanyNewsPageList from './CompanyNewsPageList.vue'

const props = defineProps<{
  companyUrl?: string | null
}>()

const { data: options } = useData({
  page: 1,
  conditions: {
    companyUrl: props.companyUrl ?? null,
    types: [] as KadomonyNewsType[],
    sources: [] as KadomonyNewsSource[]
  }
})

const { data: news, loading: loadingNews } = useKadomonyNewsPage(options)

function onTypesUpdate(types: KadomonyNewsType[]) {
  options.value.page = 1
  options.value.conditions.types = types
}

function onSourcesUpdate(sources: KadomonyNewsSource[]) {
  options.value.page = 1
  options.value.conditions.sources = sources
}
</script>

<template>
  <div class="CompanyNewsPage">
    <div class="container">
      <div class="sidebar">
        <CompanyNewsPageFilters
          :types="options.conditions.types"
          :sources="options.conditions.sources"
          @update:types="onTypesUpdate"
          @update:sources="onSourcesUpdate"
        />
      </div>
      <div class="list">
        <CompanyNewsPageList
          :loading="loadingNews"
          :page="news?.meta.page ?? 1"
          :per-page="news?.meta.perPage ?? 0"
          :total="news?.meta.total ?? 0"
          :news="news?.data ?? []"
          @prev="options.page--"
          @next="options.page++"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.CompanyNewsPage {
  padding: 32px 32px 128px;
}

.container {
  display: grid;
  grid-template-columns: 344px 1fr;
  gap: 16px;
  margin: 0 auto;
  max-width: 1216px;
}
</style>
