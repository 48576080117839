<script setup lang="ts">
import { groupBy, orderBy } from 'lodash-es'
import { computed } from 'vue'
import {
  type DealWithEventsAndBallotFrag,
  type DealWithEventsFrag,
  type DealWithIdFrag,
  type WithPermissionFrag
} from '@/graphql'
import DealCardActionsItem from './DealCardActionsItem.vue'

const props = defineProps<{
  deal: DealWithIdFrag & WithPermissionFrag & (DealWithEventsFrag | DealWithEventsAndBallotFrag)
}>()

defineEmits<{
  (e: 'updated'): void
}>()

const eventDict = computed(() => groupBy(props.deal.events, 'templateId'))
const orderedEventTemplates = computed(() => orderBy(props.deal.eventTemplates, ['event.rank']))
</script>

<template>
  <DCard title="Actions">
    <div v-for="template in orderedEventTemplates" :key="template.event.id" class="item">
      <DealCardActionsItem
        :deal="deal"
        :template="template"
        :events="eventDict[template.event.id] ?? []"
        @updated="$emit('updated')"
      />
    </div>
  </DCard>
</template>

<style scoped lang="postcss">
.item {
  border-top: 1px solid var(--c-divider-2);

  &:first-child {
    border-top: none;
  }
}
</style>
