<script setup lang="ts">
import {
  type DealWithIdFrag,
  type DealWithOpportunityIdFrag,
  type DealWithRoundFrag,
  type WithPermissionFrag
} from '@/graphql'
import { useDealOps } from '@/composables/ops/DealOps'

defineProps<{
  deal: DealWithIdFrag & DealWithRoundFrag & DealWithOpportunityIdFrag & WithPermissionFrag
}>()

const dealOps = useDealOps()
</script>

<template>
  <DCard title="Round">
    <DCardGrid>
      <DCardGridItem span="1">
        <DCardGridLabel text="Round name" />
        <DCardGridLink :text="dealOps.roundFullName(deal)" :link="dealOps.opportunityPath(deal)" />
      </DCardGridItem>
      <DCardGridItem span="3">
        <DCardGridLabel text="Schedule" />
        <DCardGridText :text="deal.round.schedule" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Total amount" />
        <DCardGridMoney :money="deal.round.totalAmount" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="GB total amount" />
        <DCardGridMoney :money="deal.round.gbTotalAmount" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Pre valuation" />
        <DCardGridMoney :money="deal.round.preValuation" />
      </DCardGridItem>
      <DCardGridItem span="1">
        <DCardGridLabel text="Post valuation" />
        <DCardGridMoney :money="deal.round.postValuation" />
      </DCardGridItem>
    </DCardGrid>
  </DCard>
</template>

<style scoped lang="postcss">
.empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}

.empty-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
