<script setup lang="ts">
import SPill, { type Mode as SPillMode } from 'sefirot/components/SPill.vue'
import { isString } from 'sefirot/support/Utils'
import { computed } from 'vue'

export type Item = string | ItemObj

export interface ItemObj {
  label: string
  mode?: SPillMode
}

const props = defineProps<{
  items: Item[]
}>()

const _items = computed<ItemObj[]>(() => {
  return props.items.map((item) => {
    return isString(item) ? { label: item } : item
  })
})
</script>

<template>
  <div class="DCardGridPills">
    <div v-if="_items.length" class="list">
      <SPill
        v-for="item in _items"
        :key="item.label"
        as="div"
        size="mini"
        type="dimm"
        :mode="item.mode"
        :label="item.label"
      />
    </div>

    <p v-else class="empty">—</p>
  </div>
</template>

<style scoped lang="postcss">
.DCardGridPills {
  padding: 2px 0;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.empty {
  padding-top: 2px;
  line-height: 20px;
  font-size: 14px;
  max-width: 640px;
  color: var(--c-text-3);
}
</style>
