export function isProd(): boolean {
  return getEnv() === 'production' || import.meta.env.PROD
}

export function isStaging(): boolean {
  return getEnv() === 'staging'
}

export function isLocal(): boolean {
  return getEnv() === 'local' || import.meta.env.DEV
}

export function getEnv(): ImportMetaEnv['VITE_APP_ENV'] {
  return import.meta.env.VITE_APP_ENV ?? 'local'
}

export function hasProp<T extends Record<string, unknown>>(
  obj: T,
  prop: PropertyKey
): prop is keyof T {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}
