import { orderBy } from 'lodash-es'
import { type Get, type Query as SQuery, useGet, useQuery as useSQuery } from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue, watch } from 'vue'
import {
  type FundWithGroup,
  type FundWithPerformanceAdmins,
  type FundWithStats,
  type Fund as RFund
} from '@/graph/Fund'
import { type FundInvestedDealCountPerYear } from '@/graph/FundInvestedDealCountPerYear'
import { type FundPortfolioStats } from '@/graph/FundPortfolioStats'
import { type FundStats } from '@/graph/FundStats'
import {
  type BasicPaginationInput,
  type FundAndOperatorsFrag,
  type FundCondition,
  type FundGroupAndAllFrag,
  type FundGroupType,
  type FundOrder,
  type FundPageFrag,
  FundStatus
} from '@/graphql'
import { FundGroupRequest } from '@/requests/FundGroupRequest'
import { FundRequest } from '@/requests/FundRequest'
import { useCache } from '@/stores/Cache'
import { type Query, useQuery } from '../Api'
import { useMe } from '../Auth'

export type FundGroupList = SQuery<FundGroupAndAllFrag[]>
export type SurveyTargetFundGroupList = SQuery<FundGroupAndAllFrag[]>
export type GetSurveyTargetFundGroupList = Get<FundGroupAndAllFrag[]>

export type FundPage = SQuery<FundPageFrag>
export type FundList = SQuery<FundAndOperatorsFrag[]>
export type FundListCache = SQuery<RFund[]>
export type GetFundList = Get<FundAndOperatorsFrag[]>
export type FundWithGroupList = SQuery<FundWithGroup[]>
export type FundWithPerformanceAdminsList = SQuery<FundWithPerformanceAdmins[]>
export type FundWithStatsList = SQuery<FundWithStats[]>
export type FundItem = Query<FundAndOperatorsFrag, []>
export type GetFundWithPerformanceAdminsItemCache = Get<FundWithPerformanceAdmins, [id: number]>
export type ActiveFundList = Query<FundAndOperatorsFrag[], []>
export type MyActiveFundList = Query<FundAndOperatorsFrag[], []>
export type MyOngoingFundList = Query<FundAndOperatorsFrag[], []>

export type RFundItem = SQuery<RFund | undefined>

export type FundPortfolioStatsListCache = SQuery<FundPortfolioStats[]>
export type FundInvestedDealCountPerYearList = SQuery<FundInvestedDealCountPerYear[]>

export type FundStatsItem = SQuery<FundStats>

export interface UseFundPageOptions {
  page: BasicPaginationInput
  condition: FundCondition
  orderBy: FundOrder
}

export function useFundGroupList(): FundGroupList {
  return useSQuery(async () => {
    const res = await new FundGroupRequest().fetchAll()
    return orderBy(res.data.fundGroups, (fg) => fg.rank)
  })
}

export function useSurveyTargetFundGroupList(): SurveyTargetFundGroupList {
  return useSQuery(fetchSurveyTargetFundGroupList)
}

export function useGetSurveyTargetFundGroupList(): SurveyTargetFundGroupList {
  return useGet(fetchSurveyTargetFundGroupList)
}

async function fetchSurveyTargetFundGroupList(): Promise<FundGroupAndAllFrag[]> {
  const fgs = (await new FundGroupRequest().fetchAll()).data.fundGroups

  return fgs.filter((fg) => {
    return fg.status === 'Active' && fg.isSurveyTarget
  })
}

export function useFundPage(
  options: MaybeRefOrGetter<UseFundPageOptions>
): FundPage {
  return useSQuery(async () => {
    const o = toValue(options)

    const res = await new FundRequest().fetchPage(
      o.page,
      o.condition,
      o.orderBy
    )

    return res.data.funds
  }, {
    watch: () => toValue(options)
  })
}

export function useFundList(): FundList {
  return useSQuery(() => fetchFundList())
}

export function useGetFundList(): GetFundList {
  return useGet(() => fetchFundList())
}

async function fetchFundList(): Promise<FundAndOperatorsFrag[]> {
  const res = await new FundRequest().fetchAll()
  return orderBy(res.data.funds, (f) => f.rank)
}

export function useFundListCache(): FundListCache {
  const cache = useCache()
  return useSQuery((http) => {
    return cache.remember('repo-fund-fund-list', () => http.get<RFund[]>('/api/funds'))
  })
}

export function useFundWithGroupList(): FundWithGroupList {
  return useSQuery((http) => {
    return http.get<FundWithGroup[]>('/api/funds-with-group')
  })
}

export function useFundWithPerformanceAdminsListCache(): FundWithPerformanceAdminsList {
  const cache = useCache()
  return useSQuery((http) => {
    return cache.remember(
      'repo-fund-fund-with-performance-admins-list',
      () => http.get<FundWithPerformanceAdmins[]>('/api/funds-with-performance-admins')
    )
  })
}

export function useFundWithStatsListCache(): FundWithStatsList {
  const cache = useCache()
  return useSQuery((http) => {
    return cache.remember(
      'repo-fund-fund-with-stats-list',
      () => http.get<FundWithStats[]>('/api/funds-with-stats')
    )
  })
}

export function useFundItem(id: MaybeRefOrGetter<number | undefined>): FundItem {
  const query = useQuery<FundAndOperatorsFrag, []>(async () => {
    const idValue = toValue(id)

    if (idValue) {
      const res = await new FundRequest().fetchAll()

      return res.data.funds.find((f) => f.id === idValue)!
    }
  })

  watch(() => toValue(id), query.execute)

  return query
}

export function useGetFundWithPerformanceAdminsItemCache(): GetFundWithPerformanceAdminsItemCache {
  const cache = useCache()
  return useGet((http, id) => {
    return cache.remember(
      `repo-fund-fund-with-performance-admins-item--${id}`,
      () => http.get<FundWithPerformanceAdmins>(`/api/funds-with-performance-admins/${id}`)
    )
  })
}

export function useActiveFundList(): ActiveFundList {
  return useQuery(async () => {
    const { data: { funds } } = await new FundRequest().fetchAll()
    const activeFunds = funds.filter((f) => f.status === 'Active')

    return orderBy(activeFunds, (f) => f.rank)
  })
}

export function useMyActiveFundList(): MyActiveFundList {
  const { user: me } = useMe()

  return useQuery(async () => {
    const res = await new FundRequest().fetchAll()

    const activeMyFunds = res.data.funds.filter((f) => {
      return (
        f.status === 'Active'
        && f.operators.some((o) => o.userID === me.id)
      )
    })

    return orderBy(activeMyFunds, (f) => f.rank)
  })
}

/**
 * Fetch all "ongoing" funds that the user is an operator of. "Ongoing"
 * includes both "Active" and "Active Only Follow On" funds.
 */
export function useMyOngoingFundList(): MyOngoingFundList {
  const { user: me } = useMe()

  return useQuery(async () => {
    const res = await new FundRequest().fetchAll()

    const funds = res.data.funds.filter((f) => {
      if (
        f.status !== FundStatus.Active
        && f.status !== FundStatus.ActiveOnlyFollowOn
      ) {
        return false
      }

      // If the user has God role, they should be able to see all funds
      // so we will stop here and make everything pass.
      if (me.hasRoleGod()) {
        return true
      }

      // Else only return only funds that the user is an operator of.
      return f.operators.some((o) => o.userID === me.id)
    })

    return orderBy(funds, (f) => f.rank)
  })
}

export function useMyAssignedFundGroupTypes() {
  return useQuery<FundGroupType[], []>(async () => {
    const res = await new FundRequest().fetchAllMyAssignedFundGroupTypes()

    return res.data.fundGroupTypes
  })
}

export function useRFundItem(id: MaybeRefOrGetter<number | undefined>): RFundItem {
  return useSQuery(async (http) => {
    const _id = toValue(id)
    return _id ? http.get<RFund>(`/api/funds/${toValue(id)}`) : undefined
  }, { watch: () => toValue(id) })
}

export function useFundPortfolioStatsListCache(id: number): FundPortfolioStatsListCache {
  const cache = useCache()
  return useSQuery((http) => {
    return cache.remember(
      `repo-fund-fund-portfolio-stats-list-${id}`,
      () => http.get<FundPortfolioStats[]>(`/api/funds/${id}/portfolio-stats`))
  })
}

export function useFundInvestedDealCountPerYearList(id: number): FundInvestedDealCountPerYearList {
  return useSQuery((http) => {
    return http.get<FundInvestedDealCountPerYear[]>(`/api/funds/${id}/invested-deal-count-per-year`)
  })
}

export function useFundStatsItem(id: number): FundStatsItem {
  return useSQuery((http) => {
    return http.get<FundStats>(`/api/funds/${id}/stats`)
  })
}
