import { defineStore } from 'pinia'
import { computed, ref, shallowRef } from 'vue'
import { type Me } from '@/models/User'

export const useAuth = defineStore('auth', () => {
  const user = shallowRef<Me | null>(null)
  const auth = ref(false)

  const authenticated = computed(() => !!user.value)
  const authorized = computed(() => authenticated.value && auth.value)

  function setUser(me: Me | null): void {
    user.value = me
  }

  function setAuth(authenticated: boolean): void {
    auth.value = authenticated
  }

  return {
    user,
    auth,
    authenticated,
    authorized,
    setUser,
    setAuth
  }
})
