import {
  AddDealEventDocument,
  type AddDealEventInput,
  AddDealReportDocument,
  AddDealSecurityDocument,
  type AddDealSecurityInput,
  type BasicPaginationInput,
  CompleteDealDocument,
  CreateDealDocument,
  type CreateDealInput,
  type DealCondition,
  DealDocument,
  DealDroppedReasonTypesDocument,
  DealForCheckDocument,
  DealForDetailsAndEventBallotDocument,
  DealForDetailsDocument,
  DealForLocalNavDocument,
  DealForReportDetailDocument,
  DealForReportsDocument,
  DealForSettingsDocument,
  DealForWrapupDocument,
  type DealOrder,
  DealOrderField,
  DealPageDocument,
  DealPageForSearchDocument,
  DealPartnersDocument,
  DealWithRoundDocument,
  DropDealDocument,
  type DropDealInput,
  UpdateDealAssigneesDocument,
  type UpdateDealAssigneesInput,
  UpdateDealExitPlanDocument,
  type UpdateDealExitPlanInput,
  UpdateDealExpenseDocument,
  type UpdateDealExpenseInput,
  UpdateDealInvestmentDocument,
  type UpdateDealInvestmentInput,
  UpdateDealRightsDocument,
  type UpdateDealRightsInput,
  UpdateDealScheduleDocument,
  type UpdateDealScheduleInput
} from '@/graphql'
import { Request } from './Request'

export {
  type DealCondition,
  type DealOrder,
  DealOrderField,
  type UpdateDealScheduleInput,
  type UpdateDealInvestmentInput,
  type UpdateDealRightsInput,
  type UpdateDealExpenseInput,
  type UpdateDealExitPlanInput,
  type UpdateDealAssigneesInput,
  type AddDealEventInput,
  type AddDealSecurityInput
}

export type CreateInput = CreateDealInput

export class DealRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: DealCondition,
    orderBy: DealOrder
  ) {
    return this.request({
      query: DealPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetchPageForSearch(
    page: BasicPaginationInput,
    condition: DealCondition,
    orderBy: DealOrder
  ) {
    return this.request({
      query: DealPageForSearchDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request({
      query: DealDocument,
      variables: { id }
    })
  }

  withRound(id: number) {
    return this.request({
      query: DealWithRoundDocument,
      variables: { id }
    })
  }

  forCheck(id: number) {
    return this.request({
      query: DealForCheckDocument,
      variables: { id }
    })
  }

  fetchForLocalNav(id: number) {
    return this.request({
      query: DealForLocalNavDocument,
      variables: { id }
    })
  }

  forDetails(id: number) {
    return this.request({
      query: DealForDetailsDocument,
      variables: { id }
    })
  }

  forDetailsAndEventBallot(id: number) {
    return this.request({
      query: DealForDetailsAndEventBallotDocument,
      variables: { id }
    })
  }

  forReports(id: number) {
    return this.request({
      query: DealForReportsDocument,
      variables: { id }
    })
  }

  forReportDetail(id: number) {
    return this.request({
      query: DealForReportDetailDocument,
      variables: { id }
    })
  }

  forWrapup(id: number) {
    return this.request({
      query: DealForWrapupDocument,
      variables: { id }
    })
  }

  fetchForSettings(id: number) {
    return this.request({
      query: DealForSettingsDocument,
      variables: { id }
    })
  }

  fetchPartners() {
    return this.request({
      query: DealPartnersDocument
    })
  }

  fetchDroppedReasonTypes() {
    return this.request({
      query: DealDroppedReasonTypesDocument
    })
  }

  create(roundId: number, input: CreateDealInput) {
    return this.request({
      query: CreateDealDocument,
      variables: { roundId, input }
    })
  }

  updateSchedule(id: number, input: UpdateDealScheduleInput) {
    return this.request({
      query: UpdateDealScheduleDocument,
      variables: { id, input }
    })
  }

  updateInvestment(id: number, input: UpdateDealInvestmentInput) {
    return this.request({
      query: UpdateDealInvestmentDocument,
      variables: { id, input }
    })
  }

  updateRights(id: number, input: UpdateDealRightsInput) {
    return this.request({
      query: UpdateDealRightsDocument,
      variables: { id, input }
    })
  }

  updateExpense(id: number, input: UpdateDealExpenseInput) {
    return this.request({
      query: UpdateDealExpenseDocument,
      variables: { id, input }
    })
  }

  updateExitPlan(id: number, input: UpdateDealExitPlanInput) {
    return this.request({
      query: UpdateDealExitPlanDocument,
      variables: { id, input }
    })
  }

  drop(id: number, input: DropDealInput) {
    return this.request({
      query: DropDealDocument,
      variables: { id, input }
    })
  }

  updateAssignees(id: number, input: UpdateDealAssigneesInput) {
    return this.request({
      query: UpdateDealAssigneesDocument,
      variables: { id, input }
    })
  }

  complete(id: number) {
    return this.request({
      query: CompleteDealDocument,
      variables: { id }
    })
  }

  addEvent(id: number, input: AddDealEventInput) {
    return this.request({
      query: AddDealEventDocument,
      variables: { id, input }
    })
  }

  addSecurity(id: number, input: AddDealSecurityInput) {
    return this.request({
      query: AddDealSecurityDocument,
      variables: { id, input }
    })
  }

  addReport(id: number) {
    return this.request({
      query: AddDealReportDocument,
      variables: { id }
    })
  }
}
