<template>
  <div class="DFormLead">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DFormLead {
  max-width: 592px;
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);

  & + & {
    padding-top: 8px;
  }
}

.DFormLead :deep(.link) {
  color: var(--c-info-text);
  transition: color 0.25s;

  &:hover {
    color: var(--c-info-text-dark);
  }
}

.DFormSection .DFormLead {
  max-width: 592px;
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
