<script setup lang="ts">
import IconTrash from '~icons/ph/trash'
import SButton from 'sefirot/components/SButton.vue'
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxValue, required, rule } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { useUserOps } from '@/composables/ops/UserOps'
import { useActiveUserListCache } from '@/composables/repos/UserRepo'

export interface Data {
  userId: number | null
  weight: number | null
}

defineProps<{
  canDelete: boolean
}>()

const model = defineModel<Data>({ required: true })

defineEmits<{
  delete: []
}>()

const { t } = useTrans({
  en: {
    user_ph: 'Select a member',
    weight_ph: '0–100',
    e_must_be_integer: 'The value must not contain decimal points.'
  },
  ja: {
    user_ph: 'メンバーを選択',
    weight_ph: '0–100',
    e_must_be_integer: '小数点は入力できません。'
  }
})

const { data: users } = useActiveUserListCache()

const userOps = useUserOps()

const { validation } = useValidation(() => ({
  userId: model.value.userId,
  weight: model.value.weight
}), {
  userId: {
    required: required()
  },
  weight: {
    required: required(),
    rule: rule((v) => Number.isInteger(v), t.e_must_be_integer),
    maxValue: maxValue(100)
  }
})

const userOptions = computed(() => {
  return userOps.createDropdownOptions(users.value)
})
</script>

<template>
  <div class="CompanySupportLogInputMembersItem">
    <SInputDropdown
      :placeholder="t.user_ph"
      :options="userOptions"
      v-model="model.userId"
      :validation="validation.userId"
      hide-error
    />
    <SInputNumber
      :placeholder="t.weight_ph"
      align="right"
      unit-after="%"
      v-model="model.weight"
      :validation="validation.weight"
      hide-error
    />
    <SButton
      type="text"
      mode="mute"
      :icon="IconTrash"
      :disabled="!canDelete"
      @click="$emit('delete')"
    />
  </div>
</template>

<style scoped lang="postcss">
.CompanySupportLogInputMembersItem {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  gap: 8px;
}
</style>
