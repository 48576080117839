<script setup lang="ts">
import { reactive } from 'vue'
import { type RoundFrag } from '@/graphql'
import { useRoundOps } from '@/composables/ops/RoundOps'
import { useDeleteOpportunityRound } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  round: RoundFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'deleted'): void
}>()

const deleteRoundMutation = reactive(useDeleteOpportunityRound())

const roundOps = useRoundOps()

async function destroy() {
  await deleteRoundMutation.execute(props.round.id)
  emit('deleted')
}
</script>

<template>
  <SCard size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <h2>Delete this round information</h2>
        <p>You are about delete round <strong>“{{ roundOps.fullName(round) }}”</strong>. This action cannot be undone. Are you absolutely sure you want to delete it?</p>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="deleteRoundMutation.loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            label="Delete round"
            :loading="deleteRoundMutation.loading"
            @click="destroy"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
