import { type Day, type Ymd, createYmd, day } from 'sefirot/support/Day'
import { isString } from 'sefirot/support/Utils'
import {
  type CurrencyFrag,
  type DealFullTitleableFrag,
  DealStatus,
  type DealTitleableFrag,
  type DealWithAssigneesFrag,
  type DealWithDeadlineFrag,
  type DealWithEventsFrag,
  type DealWithExitPlansFrag,
  type DealWithExpenseFrag,
  type DealWithIdFrag,
  type DealWithInvestmentInfoFrag,
  type DealWithOpportunityIdFrag,
  type DealWithRoundCurrencyFrag,
  type DealWithRoundNameFrag,
  type DealWithStatusFrag,
  type DealWithTimestampFrag,
  ExitMethod,
  type MoneyFrag,
  type MoneyWithoutIdFrag,
  type UserFrag
} from '@/graphql'
import { type Entries, type ValueOf } from '@/support/Types'
import { useCompanyOps } from './CompanyOps'
import { useMoneyOps } from './MoneyOps'
import { useOpportunityOps } from './OpportunityOps'
import { defineOps } from './Ops'
import { useRoundOps } from './RoundOps'

export const DealStatusTextDict = {
  [DealStatus.InitialReview]: 'Initial Review',
  [DealStatus.InvestmentCommittee]: 'Investment Committee',
  [DealStatus.Contract]: 'Contract',
  [DealStatus.Remittance]: 'Remittance',
  [DealStatus.Finalize]: 'Finalize',
  [DealStatus.Report]: 'Report',
  [DealStatus.Closing]: 'Closing',
  [DealStatus.Invested]: 'Invested',
  [DealStatus.Dropped]: 'Dropped'
} as const

export const DealStatusModeDict = {
  [DealStatus.InitialReview]: 'info',
  [DealStatus.InvestmentCommittee]: 'info',
  [DealStatus.Contract]: 'info',
  [DealStatus.Remittance]: 'info',
  [DealStatus.Finalize]: 'info',
  [DealStatus.Report]: 'info',
  [DealStatus.Closing]: 'info',
  [DealStatus.Invested]: 'success',
  [DealStatus.Dropped]: 'mute'
} as const

export const ExitMethodTextDict = {
  [ExitMethod.Ipo]: 'IPO',
  [ExitMethod.Ma]: 'MA',
  [ExitMethod.SecondarySale]: 'Secondary Sale'
} as const

export function statusTextEntries(): Entries<typeof DealStatusTextDict> {
  return Object.entries(DealStatusTextDict)
}

export function exitMethodTextEntries(): Entries<typeof ExitMethodTextDict> {
  return Object.entries(ExitMethodTextDict)
}

export const useDealOps = defineOps(() => {
  const companyOps = useCompanyOps()
  const roundOps = useRoundOps()
  const oppoOps = useOpportunityOps()
  const moneyOps = useMoneyOps()

  function path(deal: DealWithIdFrag, path?: string): string {
    path = path ? `/${path}` : ''
    return `/deals/${deal.id}${path}`
  }

  function companyPath(deal: DealFullTitleableFrag): string {
    return companyOps.path(deal.round.company)
  }

  function opportunityPath(deal: DealWithOpportunityIdFrag): string {
    return oppoOps.path(deal.round.opportunity)
  }

  function title(deal: DealTitleableFrag): string {
    const f = deal.fund.nameAbbreviated

    // Slice 'YYYY-MM-DD' from 'YYYY-MM-DDTHH:MM:SSZ' string.
    const c = deal.createdAt.slice(0, 10)

    return `${f} ${c}`
  }

  function fullTitle(deal: DealFullTitleableFrag): string {
    return `${companyName(deal)} / ${opportunityTitle(deal)} / ${title(deal)}`
  }

  function companyName(deal: DealFullTitleableFrag): string {
    return companyOps.name(deal.round.company)
  }

  function roundFullName(deal: DealWithRoundNameFrag): string {
    return roundOps.fullName(deal.round)
  }

  function opportunityTitle(deal: DealFullTitleableFrag): string {
    return oppoOps.title(deal.round.opportunity)
  }

  function isReport(deal: DealWithStatusFrag): boolean {
    return deal.status === DealStatus.Report
  }

  function isDropped(deal: DealWithStatusFrag): boolean {
    return deal.status === DealStatus.Dropped
  }

  function hasAllEventResults(deal: DealWithEventsFrag): boolean {
    return deal.eventTemplates.every((template) => deal.events.map((e) => e.templateId).includes(template.eventId))
  }

  function statusText(deal: DealWithStatusFrag | DealStatus): ValueOf<typeof DealStatusTextDict> {
    return isString(deal)
      ? DealStatusTextDict[deal]
      : DealStatusTextDict[deal.status]
  }

  function statusMode(deal: DealWithStatusFrag): 'mute' | 'info' | 'success' {
    return DealStatusModeDict[deal.status]
  }

  function deadline(deal: DealWithDeadlineFrag): Day | null {
    return deal.deadline ? day(deal.deadline) : null
  }

  function leadText(deal: DealWithInvestmentInfoFrag): string {
    return deal.lead ? 'Lead' : 'Follow'
  }

  function currency(deal: DealWithRoundCurrencyFrag): CurrencyFrag {
    return roundOps.currency(deal.round)
  }

  function investmentAmount(deal: DealWithInvestmentInfoFrag): MoneyFrag | null {
    return deal.investmentAmount
      ? moneyOps.create(roundOps.currency(deal.round), deal.investmentAmount)
      : null
  }

  function investmentAmountText(deal: DealWithInvestmentInfoFrag): string | null {
    const money = investmentAmount(deal)
    return money ? moneyOps.formatWithCurrencyName(money) : null
  }

  function investedAmount(deal: DealWithInvestmentInfoFrag): MoneyFrag | null {
    return deal.investedAmount
      ? moneyOps.create(roundOps.currency(deal.round), deal.investedAmount)
      : null
  }

  function investedAmountText(deal: DealWithInvestmentInfoFrag): string | null {
    const money = investedAmount(deal)
    return money ? moneyOps.formatWithCurrencyName(money) : null
  }

  function investedAmountFmc(deal: DealWithInvestmentInfoFrag): MoneyWithoutIdFrag | null {
    return deal.investedAmountFmc
      ? moneyOps.createJpy(deal.investedAmountFmc)
      : null
  }

  function bookValue(deal: DealWithInvestmentInfoFrag): MoneyWithoutIdFrag | null {
    return deal.bookValue
      ? moneyOps.createJpy(deal.bookValue)
      : null
  }

  function ddExpense(deal: DealWithExpenseFrag): MoneyWithoutIdFrag {
    return moneyOps.createJpy(deal.ddExpense)
  }

  function exitMethodText(method: ExitMethod): ValueOf<typeof ExitMethodTextDict> {
    return ExitMethodTextDict[method]
  }

  function estimatedExitTime(deal: DealWithExitPlansFrag): Day | null {
    return deal.estimatedExitTime ? day(deal.estimatedExitTime) : null
  }

  function estimatedExitTimeAsYmd(deal: DealWithExitPlansFrag): Ymd {
    const exitTime = estimatedExitTime(deal)

    return exitTime
      ? createYmd(exitTime.year(), exitTime.month() + 1, exitTime.day())
      : createYmd()
  }

  function mainAssignees(deal: DealWithAssigneesFrag): UserFrag[] {
    const assignees: UserFrag[] = []

    assignees.push(deal.primaryInCharge)
    deal.deputyInCharge && assignees.push(deal.deputyInCharge)
    deal.participants.length && assignees.push(...deal.participants)

    return assignees
  }

  function createdAt(deal: DealWithTimestampFrag): Day {
    return day(deal.createdAt)
  }

  function updatedAt(deal: DealWithTimestampFrag): Day {
    return day(deal.updatedAt)
  }

  return {
    path,
    companyPath,
    opportunityPath,
    title,
    fullTitle,
    companyName,
    roundFullName,
    opportunityTitle,
    isReport,
    isDropped,
    hasAllEventResults,
    statusTextEntries,
    statusText,
    statusMode,
    deadline,
    leadText,
    currency,
    investmentAmount,
    investmentAmountText,
    investedAmount,
    investedAmountText,
    investedAmountFmc,
    bookValue,
    ddExpense,
    exitMethodTextEntries,
    exitMethodText,
    estimatedExitTime,
    estimatedExitTimeAsYmd,
    mainAssignees,
    createdAt,
    updatedAt
  }
})
