/**
 * Asserts that the condition is truthy, throwing immediately if not.
 */
export function assert(
  condition: boolean,
  message: string[]
): asserts condition {
  if (!condition) {
    throw new Error(['[deus]'].concat(message).join(' '))
  }
}

/**
 * Asserts that the condition is falsy, throwing immediately if not.
 */
export function assertNot(
  condition: boolean,
  message: string[]
): asserts condition {
  if (condition) {
    throw new Error(['[deus]'].concat(message).join(' '))
  }
}
