<script setup lang="ts">
import { xor } from 'lodash-es'
import SInputBase from 'sefirot/components/SInputBase.vue'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdownFilter } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed, reactive, shallowRef } from 'vue'
import { type OpportunityForDetailsFrag, SurveyStatus } from '@/graphql'
import { SurveyAlreadyLinkedError } from '@/requests/errors/SurveyAlreadyLinkedError'
import { SurveyMissingOpenProposalsError } from '@/requests/errors/SurveyMissingOpenProposalsError'
import { useMe } from '@/composables/Auth'
import { useCompanyOps } from '@/composables/ops/CompanyOps'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useSurveyOps } from '@/composables/ops/SurveyOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useSurveyTargetFundGroupList } from '@/composables/repos/FundRepo'
import { useUpdateOportunitySurvey } from '@/composables/repos/OportunitySurveyRepo'
import { useSurveyPage } from '@/composables/repos/SurveyRepo'
import { useActiveUserList } from '@/composables/repos/UserRepo'
import OpportunityFormLinkSurveyError from './OpportunityFormLinkSurveyError.vue'

type ExpectedError = SurveyAlreadyLinkedError | SurveyMissingOpenProposalsError

const { t } = useTrans({
  en: {
    c_search_ph: 'Search by ID and Company',
    c_reset_label: 'Reset filters',
    t_id_label: 'Survey ID',
    t_status_label: 'Status',
    t_company_label: 'Company',
    t_funds_label: 'Funds',
    t_created_by_label: 'Created by',
    t_created_at_label: 'Created at',
    t_linked_opportunity_label: 'Linked opportunity',
    error_no_select: 'One survey must be selected.',
    cancel: 'Cancel',
    link: 'Link survey'
  },
  ja: {
    c_search_ph: 'ID、会社名で検索',
    c_reset_label: 'フィルターをリセット',
    t_id_label: 'Survey ID',
    t_status_label: 'ステータス',
    t_company_label: '会社',
    t_funds_label: 'ファンド',
    t_created_by_label: '作成者',
    t_created_at_label: '作成日時',
    t_linked_opportunity_label: '紐づくOpportunity',
    error_no_select: 'アンケートを選択してください。',
    cancel: 'キャンセル',
    link: 'Surveyを紐づけ'
  }
})

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { user } = useMe()

const companyOps = useCompanyOps()
const userOps = useUserOps()
const surveyOps = useSurveyOps()
const oppoOps = useOpportunityOps()

const error = shallowRef<ExpectedError | null>(null)

const errorDialog = reactive(usePower())

const { data: options, init } = useData({
  page: {
    page: 0,
    perPage: 100
  },
  condition: {
    query: null as string | null,
    status: [SurveyStatus.Confirmed],
    fundGroup: [] as number[],
    createdBy: [] as number[]
  }
})

options.value.condition.query = companyOps.latestName(props.opportunity.company).displayName
options.value.condition.createdBy = [user.id!]

const { data } = useData({
  surveyId: null as number | null
})

const { validation, validateAndNotify } = useValidation(data, {
  surveyId: { required: required() }
})

const { data: surveys, loading: fetchingSurveys } = useSurveyPage(options)
const { data: targetFundGroups } = useSurveyTargetFundGroupList()
const { data: users } = useActiveUserList()

const { loading, execute } = useUpdateOportunitySurvey()

const records = computed(() => surveys.value?.items ?? [])

const selectedSurvey = computed(() => records.value.find((record) => record.id === data.value.surveyId))

const table = useTable({
  records,
  indexField: 'id',
  loading: fetchingSurveys,
  borderless: true,
  orders: [
    'id',
    'status',
    'companyName',
    'fundGroups',
    'createdBy',
    'createdAt',
    'opportunity',
    'empty'
  ],
  columns: {
    id: {
      label: t.t_id_label,
      cell: (_, survey) => ({
        type: 'text',
        value: surveyOps.displayId(survey)
      })
    },
    status: {
      label: t.t_status_label,
      cell: (_, survey) => ({
        type: 'state',
        label: surveyOps.statusText(survey),
        mode: surveyOps.statusMode(survey)
      })
    },
    companyName: {
      label: t.t_company_label
    },
    fundGroups: {
      label: t.t_funds_label,
      dropdown: [
        createDropdownFilter({
          search: true,
          selected: computed(() => options.value.condition.fundGroup),
          options: computed(() => targetFundGroups.value?.map((fg) => ({
            label: fg.name,
            value: fg.id
          })) ?? []),
          onClick(value: number) {
            options.value.condition.fundGroup = xor(options.value.condition.fundGroup, [value])
          }
        })
      ],
      cell: (_, survey) => ({
        type: 'pills',
        pills: survey.fundGroups.map((fg) => ({
          label: fg.name
        }))
      })
    },
    createdBy: {
      label: t.t_created_by_label,
      dropdown: [
        createDropdownFilter({
          search: true,
          selected: computed(() => options.value.condition.createdBy),
          options: computed(() => users.value?.map((u) => ({
            label: userOps.name(u),
            value: u.id
          })) ?? []),
          onClick(value: number) {
            options.value.condition.createdBy = xor(options.value.condition.createdBy, [value])
          }
        })
      ],
      cell: (_, survey) => ({
        type: 'avatar',
        image: userOps.avatarPath(survey.createdBy),
        name: userOps.name(survey.createdBy)
      })
    },
    createdAt: {
      label: t.t_created_at_label,
      cell: (_, survey) => ({
        type: 'day',
        value: surveyOps.createdAt(survey),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    opportunity: {
      label: t.t_linked_opportunity_label,
      cell: (_, survey) => ({
        type: 'text',
        value: survey.opportunity?.company ? oppoOps.titleWithCompany(survey.opportunity) : null,
        link: survey.opportunity?.id ? oppoOps.path(survey.opportunity) : null,
        color: 'info'
      })
    },
    empty: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function updateSurveyId(v: number | null) {
  data.value.surveyId = v
  validation.value.surveyId.$touch()
}

async function requestLink() {
  if (await validateAndNotify()) {
    try {
      await execute(props.opportunity.id!, data.value.surveyId!)
      emit('updated')
    } catch (e: unknown) {
      if (isExpectedError(e)) {
        error.value = e
        errorDialog.on()
      } else {
        throw e
      }
    } finally {
      loading.value = false
    }
  }
}

function isExpectedError(e: unknown): e is ExpectedError {
  return e instanceof SurveyAlreadyLinkedError || e instanceof SurveyMissingOpenProposalsError
}
</script>

<template>
  <SCard class="OpportunityFormLinkSurvey" size="xxlarge">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">
              <h2>Link a survey to this opportunity</h2>
              <p>Surveys are filtered by company’s display name and those created by you. Reset filters to view all surveys if you cannot find the one you are looking for. Note that you may only link a survey that is comfirmed by the startup.</p>
            </STrans>
            <STrans lang="ja">
              <h2>Surveyを紐付ける</h2>
              <p>あなたが作成したSurveyのうち、会社の表示名が一致するものを表示しています。もし探しているSurveyが見つからない場合は、フィルターを解除してすべてのSurveyを表示してください。なお、スタートアップから回答を受領しているSurveyしか紐づけられません。</p>
            </STrans>
          </h2>
        </SContent>
        <SInputBase :validation="validation.surveyId">
          <SCard :mode="validation.surveyId.$error ? 'danger' : 'neutral'">
            <SCardBlock size="small" class="s-px-12">
              <SControl>
                <SControlLeft>
                  <SControlInputSearch
                    :placeholder="t.c_search_ph"
                    :model-value="options.condition.query"
                    @enter="options.condition.query = $event"
                  />
                  <SControlButton
                    type="outline"
                    mode="mute"
                    :label="t.c_reset_label"
                    @click="init"
                  />
                </SControlLeft>
              </SControl>
            </SCardBlock>
            <SCardBlock>
              <STable
                :options="table"
                :selected="data.surveyId"
                @update:selected="updateSurveyId"
              />
            </SCardBlock>
            <SCardBlock v-if="surveys && !fetchingSurveys" size="medium" class="s-px-12">
              <SControl>
                <SControlRight>
                  <SControlPagination
                    :page="surveys.pageInfo.page + 1"
                    :per-page="surveys.pageInfo.perPage"
                    :total="surveys.pageInfo.total"
                    @prev="options.page.page--"
                    @next="options.page.page++"
                  />
                </SControlRight>
              </SControl>
            </SCardBlock>
          </SCard>
        </SInputBase>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :loading="loading"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.link"
            :loading="loading"
            @click="requestLink"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SModal :open="errorDialog.state" @close="errorDialog.off">
      <OpportunityFormLinkSurveyError
        :error="error!"
        :survey="selectedSurvey!"
        @close="errorDialog.off"
      />
    </SModal>
  </SCard>
</template>

<style scoped lang="postcss">
.OpportunityFormLinkSurvey {
  --table-max-height: 344px;
}

.OpportunityFormLinkSurvey :deep(.col-__select) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.OpportunityFormLinkSurvey :deep(.col-id)          { --table-col-width: 160px; }
.OpportunityFormLinkSurvey :deep(.col-status)      { --table-col-width: 160px; }
.OpportunityFormLinkSurvey :deep(.col-companyName) { --table-col-width: 256px; }
.OpportunityFormLinkSurvey :deep(.col-fundGroups)  { --table-col-width: 320px; }
.OpportunityFormLinkSurvey :deep(.col-createdBy)   { --table-col-width: 192px; }
.OpportunityFormLinkSurvey :deep(.col-createdAt)   { --table-col-width: 192px; }
.OpportunityFormLinkSurvey :deep(.col-opportunity) { --table-col-width: 384px; }
.OpportunityFormLinkSurvey :deep(.col-empty)       { --table-col-width: 40px; }
</style>
