<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineEmits<{
  close: []
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})
</script>

<template>
  <SCard class="CompanyFormCreateFillByAiError" size="small" mode="danger">
    <SCardBlock class="s-p-24">
      <SContent>
        <p>
          <STrans lang="en">An error occurred. There are cases where AI does not work due to reasons such as the URL being unprocessable. Check the URL and try again.</STrans>
          <STrans lang="ja">エラーが発生しました。URLが処理できないなどの理由でAIが機能しないケースがあります。URLを再度確認してみてください。</STrans>
        </p>
      </SContent>
    </SCardBlock>
    <SCardBlock class="s-px-24" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton :label="t.close" @click="$emit('close')" />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.CompanyFormCreateFillByAiError {
  transition: opacity 0.5s, transform 0.5s;
}

.CompanyFormCreateFillByAiError.fade-enter-from {
  opacity: 1;
  transform: translateY(8px);
}

.CompanyFormCreateFillByAiError.fade-leave-to {
  opacity: 0;
  transform: translateY(-8px);
}
</style>
