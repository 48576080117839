<template>
  <div class="DCardInfoItem">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.DCardInfoItem {
  display: flex;

  & + & {
    border-top: 1px solid var(--c-divider-2);
  }
}
</style>
