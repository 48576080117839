<script setup lang="ts">
import { omit } from 'lodash-es'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import SInputSegments from 'sefirot/components/SInputSegments.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { day } from 'sefirot/support/Day'
import { maxLength, requiredIf } from 'sefirot/validation/rules'
import { computed } from 'vue'
import {
  type OpportunitySourceFrag,
  type ProposalFrag,
  ProposalStatus,
  type UpdateProposalMutation
} from '@/graphql'
import * as Field from '@/support/Field'
import { useOpportunityProposalOps, useOpportunitySourceOps } from '@/composables/ops/OpportunityOps'
import { useUpdateOpportunityProposal } from '@/composables/repos/OpportunityRepo'
import DFields from '../DFields.vue'

const props = defineProps<{
  proposal: ProposalFrag
  sources: OpportunitySourceFrag[]
}>()

const emit = defineEmits<{
  updated: [UpdateProposalMutation['proposal']]
  close: []
}>()

const { execute, loading } = useUpdateOpportunityProposal()

const opportunityProposalOps = useOpportunityProposalOps()
const opportunitySourceOps = useOpportunitySourceOps()

const fields = computed(() => props.proposal.fundGroup.proposalCustomFields as Field.Field[])
const fieldData = computed(() => props.proposal.data ?? {})

const { data } = useData({
  status: props.proposal.status,
  sourceId: props.proposal.sourceId ?? props.sources[0]?.id ?? null,
  closedAt: opportunityProposalOps.closedAt(props.proposal) ?? day(),
  closedReason: props.proposal.closedReason,
  ...Field.createData(fields.value, fieldData.value)
})

const { validation, validateAndNotify } = useValidation(data, {
  closedAt: {
    required: requiredIf(() => data.value.status === ProposalStatus.Closed)
  },
  closedReason: {
    maxLength: maxLength(500)
  }
})

const statusOptions = [
  { label: ProposalStatus.Open, value: ProposalStatus.Open },
  { label: ProposalStatus.Closed, value: ProposalStatus.Closed }
]

const sourceOptions = computed(() => {
  return props.sources.map((source) => ({
    label: opportunitySourceOps.title(source),
    value: source.id
  }))
})

function updateData(inputs: Record<string, any>) {
  data.value = { ...data.value, ...pickCustomFieldData(inputs) }
}

async function update() {
  if (await validateAndNotify()) {
    const isClosed = data.value.status === ProposalStatus.Closed
    const { proposal } = await execute(props.proposal.id, () => ({
      status: data.value.status,
      sourceId: data.value.sourceId,
      closedAt: isClosed ? data.value.closedAt?.format('YYYY-MM-DD') : null,
      closedReason: isClosed ? data.value.closedReason : null,
      data: JSON.stringify(pickCustomFieldData(data.value))
    }))
    emit('updated', proposal)
  }
}

function pickCustomFieldData(inputs: Record<string, any>) {
  return omit(inputs, ['status', 'sourceId', 'closedAt', 'closedReason'])
}
</script>

<template>
  <SCard size="medium" class="OpportunityFormUpdateProposal">
    <SCardBlock class="s-p-24">
      <SDoc>
        <SContent>
          <h2 class="title">Update {{ proposal.fundGroup?.name }} proposal</h2>
        </SContent>
        <SInputSegments
          label="Status"
          :options="statusOptions"
          v-model="data.status"
        />
        <SInputDropdown
          label="Sourcing information"
          :options="sourceOptions"
          v-model="data.sourceId"
          :validation="validation.sourceId"
        />
        <template v-if="data.status === ProposalStatus.Closed">
          <SGridItem>
            <SInputDate
              label="Closed at"
              v-model="data.closedAt"
              :validation="validation.closedAt"
            />
          </SGridItem>
          <SGridItem>
            <SInputTextarea
              label="Closed reason"
              placeholder="The reason of the closing"
              v-model="data.closedReason"
              :validation="validation.closedReason"
            />
          </SGridItem>
        </template>
        <DFields
          :fields="fields"
          :model-value="data"
          @update:model-value="updateData"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-p-24">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            :disabled="loading"
            @click="$emit('close')"
          />
          <SControlButton
            mode="info"
            label="Update proposal"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
