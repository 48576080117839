<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import IconTrash from '~icons/ph/trash-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { computed, reactive } from 'vue'
import { type OpportunityForDetailsFrag, type OpportunitySourceFrag } from '@/graphql'
import { useState } from '@/composables/State'
import { useOpportunitySourceOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'
import {
  useCanDeleteOpportunitySource,
  useCanUpdateOpportunityPolicy
} from '@/composables/policies/OpportunityPolicy'
import OpportunityFormDeleteSource from './OpportunityFormDeleteSource.vue'
import OpportunityFormUpdateSource from './OpportunityFormUpdateSource.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  source: OpportunitySourceFrag
  updatable: boolean
  deletable: boolean
}>()

const emit = defineEmits<{
  updated: []
}>()

const { t } = useTrans({
  en: {
    e_last_one: 'This source cannot be deleted because the opportunity must have at least one sourcing information.',
    e_used_in_proposal: 'This source cannot be deleted because it is linked to proposals.'
  },
  ja: {
    e_last_one: 'Opportunityには少なくとも1つのソーシング情報が必要なため削除できません。',
    e_used_in_proposal: 'Proposalに紐づけられているため削除できません。'
  }
})

const canUpdate = useCanUpdateOpportunityPolicy(() => props.opportunity)
const canDelete = useCanDeleteOpportunitySource(() => props.opportunity, () => props.source)

const userOps = useUserOps()
const opportunitySourceOps = useOpportunitySourceOps()

const dialog = reactive(useState<'update' | 'delete'>())

const promoterAvatar = computed(() => {
  const p = props.source.promoter
  return p
    ? { avatar: userOps.avatarPath(p), name: userOps.name(p) }
    : null
})

const disabledDeleteButtonTooptip = computed(() => {
  if (canDelete.value.is('last-one')) {
    return { text: t.e_last_one }
  }

  if (canDelete.value.is('used-in-proposal')) {
    return { text: t.e_used_in_proposal }
  }

  return undefined
})

function onDeleted() {
  dialog.clear()
  setTimeout(() => emit('updated'), 500)
}
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-16 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            {{ opportunitySourceOps.title(source) }}
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdate.ok"
              :icon="IconNotePencil"
              @click="dialog.set('update')"
            />
            <SControlActionBarButton
              v-if="!canDelete.is('unauthorized')"
              :icon="IconTrash"
              :disabled="canDelete.is('used-in-proposal') || canDelete.is('last-one')"
              :tooltip="disabledDeleteButtonTooptip"
              @click="dialog.set('delete')"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock class="s-p-16">
      <SDesc cols="2" gap="24">
        <SDescItem span="1">
          <SDescLabel value="Date" />
          <SDescDay :value="opportunitySourceOps.date(source)" format="YYYY-MM-DD" />
        </SDescItem>
        <div />
        <SDescItem span="1">
          <SDescLabel value="Promoter" />
          <SDescAvatar :avatar="promoterAvatar" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Promoted source" />
          <SDescText :value="source.promotedSource.name" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Inbound/Outbound" />
          <SDescText :value="source.inboundOrOutbound.name" />
        </SDescItem>
        <SDescItem span="1">
          <SDescLabel value="Investment request" />
          <SDescText :value="source.investmentRequest.name" />
        </SDescItem>
        <SDescItem span="2">
          <SDescLabel value="Contact background" />
          <SDescText :value="source.contactBackground" pre-wrap />
        </SDescItem>
      </SDesc>
    </SCardBlock>
    <SModal :open="dialog.data === 'update'" @close="dialog.clear">
      <OpportunityFormUpdateSource
        :source="source"
        @updated="dialog.clear(() => $emit('updated'))"
        @cancel="dialog.clear"
      />
    </SModal>
    <SModal :open="dialog.data === 'delete'" @close="dialog.clear">
      <OpportunityFormDeleteSource
        :source="source"
        @deleted="onDeleted"
        @cancel="dialog.clear"
      />
    </SModal>
  </SCard>
</template>
