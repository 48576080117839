<script setup lang="ts">
import SInputSelect, { type Option as SelectOption } from 'sefirot/components/SInputSelect.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { computed, reactive } from 'vue'
import { type Deal } from '@/models/Deal'
import { useBroadcast } from '@/composables/Channel'
import { useDealDroppedReasonTypeList, useDropDeal } from '@/composables/repos/DealRepo'

const props = defineProps<{
  deal: Deal
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'dropped'): void
}>()

const { data: reasonTypes } = useDealDroppedReasonTypeList()

const { broadcast } = useBroadcast('deal-status-updated')

const dropDealMutation = reactive(useDropDeal())

const { data } = useData({
  droppedReasonTypeId: null as number | null,
  droppedReason: null as string | null
})

const { validation, validateAndNotify } = useValidation(data, {
  droppedReasonTypeId: { required: required() },
  droppedReason: { required: required(), maxLength: maxLength(2000) }
})

const reasonTypeOptions = computed<SelectOption[]>(() => {
  return reasonTypes.value?.map((type) => {
    return { label: type.name, value: type.id, disabled: !type.enabled }
  }) ?? []
})

async function drop() {
  if (await validateAndNotify()) {
    await dropDealMutation.execute(props.deal.id!, {
      droppedReasonTypeId: data.value.droppedReasonTypeId!,
      droppedReason: data.value.droppedReason!
    })
    broadcast()
    emit('dropped')
  }
}
</script>

<template>
  <SCard size="medium" mode="danger">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Drop this deal</h2>
          <p>You cannot reopen a dropped deal. Also, by dropping the deal, all of the fields will be locked, and you may not update the data anymore.</p>
        </SContent>
        <SInputSelect
          label="Reason type for dropping the deal *"
          placeholder="Select a reason type"
          :no-search="true"
          :options="reasonTypeOptions"
          v-model="data.droppedReasonTypeId"
          :validation="validation.droppedReasonTypeId"
        />
        <SInputTextarea
          label="Reason for dropping the deal *"
          placeholder="Describe the details why you are dropping the deal."
          v-model="data.droppedReason"
          :validation="validation.droppedReason"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="large" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            label="Cancel"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="danger"
            label="Drop deal"
            :loading="dropDealMutation.loading"
            @click="drop"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
