<script setup lang="ts">
import SInputText from 'sefirot/components/SInputText.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type CompanySupportLogWithMembers } from '@/graph/Company'
import { type UpdateCompanySupportLogInputMember, useUpdateCompanySupportLog } from '@/composables/repos/CompanySupportLogRepo'
import CompanySupportLogInputMembers, { type Data as MembersData } from './CompanySupportLogInputMembers.vue'

const props = defineProps<{
  supportLog: CompanySupportLogWithMembers
}>()

const emit = defineEmits<{
  updated: [supportLog: CompanySupportLogWithMembers]
  cancel: []
}>()

const { t } = useTrans({
  en: {
    i_title_label: 'Title *',
    i_title_placeholder: 'e.g. Sales support, PR support',
    i_description_label: 'Description *',
    i_description_note: 'Max 4,000 characters',
    i_description_placeholder: 'Explain the supports in detail...',
    cancel: 'Cancel',
    update: 'Update support log'
  },
  ja: {
    i_title_label: 'タイトル *',
    i_title_placeholder: '例：営業支援、PR支援',
    i_description_label: '支援内容 *',
    i_description_note: '最大4,000文字',
    i_description_placeholder: '支援内容を詳細に記載してください。',
    cancel: 'キャンセル',
    update: '支援実績を更新'
  }
})

const { execute, loading } = useUpdateCompanySupportLog()

const { data } = useData({
  title: props.supportLog.title as string | null,
  description: props.supportLog.description as string | null,
  members: createMembersData()
})

const { validation, validateAndNotify } = useValidation(data, {
  title: {
    required: required(),
    maxLength: maxLength(255)
  },
  description: {
    required: required(),
    maxLength: maxLength(4000)
  }
})

function createMembersData(): MembersData[] {
  return props.supportLog.members.map((member) => ({
    userId: member.user.id,
    weight: member.weight
  }))
}

async function update() {
  if (await validateAndNotify()) {
    const res = await execute(props.supportLog.id, {
      title: data.value.title!,
      description: data.value.description!,
      members: data.value.members as UpdateCompanySupportLogInputMember[]
    })
    emit('updated', res)
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>
            <STrans lang="en">Create support log</STrans>
            <STrans lang="ja">支援実績を登録する</STrans>
          </h2>
        </SContent>
        <SInputText
          :label="t.i_title_label"
          :placeholder="t.i_title_placeholder"
          v-model="data.title"
          :validation="validation.title"
        />
        <CompanySupportLogInputMembers
          v-model="data.members"
        />
        <SInputTextarea
          :label="t.i_description_label"
          :note="t.i_description_note"
          :placeholder="t.i_description_placeholder"
          :rows="10"
          v-model="data.description"
          :validation="validation.description"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.update"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
