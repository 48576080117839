<script setup lang="ts">
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type CompanySupportLogMember } from '@/graph/Company'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  members: CompanySupportLogMember[]
}>()

const { t } = useTrans({
  en: {
    members: 'Members',
    name: 'Member',
    weight: 'Weight'
  },
  ja: {
    members: 'メンバー',
    name: 'メンバー',
    weight: 'ウェイト'
  }
})

const userOps = useUserOps()

const table = useTable({
  records: computed(() => props.members),
  orders: [
    'member',
    'weight'
  ],
  columns: {
    member: {
      label: t.name,
      cell: (_, m) => ({
        type: 'avatar',
        image: userOps.avatarPath(m.user),
        name: userOps.name(m.user)
      })
    },
    weight: {
      label: t.weight,
      cell: (_, m) => ({
        type: 'text',
        value: `${m.weight}%`,
        align: 'right'
      })
    }
  }
})
</script>

<template>
  <div class="CompanySupportLogDescMemberWeightItem">
    <SDescLabel>{{ t.members }}</SDescLabel>
    <STable class="list" :options="table" />
  </div>
</template>

<style scoped lang="postcss">
.CompanySupportLogDescMemberWeightItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  grid-column: span 4;
}

.list :deep(.col-weight) {
  font-feature-settings: "tnum";
}

.list :deep(.STableItem) { --table-col-width: 50%; }
</style>
