import {
  type BasicPaginationInput,
  CreateFundReportDocument,
  type CreateFundReportInput,
  type CreateFundReportMutation,
  type CreateFundReportMutationVariables,
  type FundReportCondition,
  FundReportDocument,
  type FundReportOrder,
  FundReportOrderField,
  FundReportPageDocument,
  type FundReportPageQuery,
  type FundReportPageQueryVariables,
  type FundReportQuery,
  type FundReportQueryVariables,
  FundReportType,
  OrderDirection
} from '@/graphql'
import { Request } from './Request'

export type { BasicPaginationInput, CreateFundReportInput, FundReportCondition, FundReportOrder }
export { FundReportOrderField, OrderDirection, FundReportType }

export class FundReportRequest extends Request {
  fetchPage(
    page: BasicPaginationInput,
    condition: FundReportCondition,
    orderBy: FundReportOrder
  ) {
    return this.request<FundReportPageQuery, FundReportPageQueryVariables>({
      query: FundReportPageDocument,
      variables: { page, condition, orderBy }
    })
  }

  fetch(id: number) {
    return this.request<FundReportQuery, FundReportQueryVariables>({
      query: FundReportDocument,
      variables: { id }
    })
  }

  create(fundId: number, input: CreateFundReportInput) {
    return this.request<CreateFundReportMutation, CreateFundReportMutationVariables>({
      query: CreateFundReportDocument,
      variables: { fundId, input }
    })
  }
}
