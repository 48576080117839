<script setup lang="ts">
import IconBuilding from '~icons/ph/buildings'
import SButton from 'sefirot/components/SButton.vue'
import STable from 'sefirot/components/STable.vue'
import { useTable } from 'sefirot/composables/Table'
import { computed } from 'vue'
import { type UserFrag } from '@/graphql'
import { type Deal } from '@/models/Deal'
import { useUserOps } from '@/composables/ops/UserOps'

const props = withDefaults(defineProps<{
  query: string | null
  loading: boolean
  total?: number
  deals?: Deal[]
}>(), {
  deals: () => []
})

const userOps = useUserOps()

const table = useTable({
  orders: [
    'name',
    'round',
    'fund',
    'status',
    'assignee',
    'deadline',
    'investmentAmount',
    'createdAt',
    'updatedAt'
  ],
  columns: {
    name: {
      label: 'Name',
      cell: (_, record) => ({
        type: 'text',
        icon: IconBuilding,
        link: record.path,
        color: 'neutral',
        iconColor: 'soft'
      })
    },
    round: {
      label: 'Round',
      cell: {
        type: 'text',
        color: 'neutral'
      }
    },
    fund: {
      label: 'Fund',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    status: {
      label: 'Status',
      cell: (value) => ({
        type: 'pill',
        color: value === 'Dropped' ? 'mute' : value === 'Invested' ? 'success' : 'info'
      })
    },
    assignee: {
      label: 'Assignee',
      cell: (users) => ({
        type: 'avatars',
        avatars: users.map((user) => ({
          image: userOps.avatarPath(user as UserFrag),
          name: user?.firstNameEn ?? ''
        })),
        color: 'soft'
      })
    },
    deadline: {
      label: 'Deadline',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    investmentAmount: {
      label: 'Investment amount',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    updatedAt: {
      label: 'Updated at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    },
    createdAt: {
      label: 'Created at',
      cell: {
        type: 'text',
        color: 'soft'
      }
    }
  },
  records: computed(() => props.deals.map((d) => {
    return {
      name: `${d.round?.company?.name} / ${d.round?.opportunity?.title} / ${d.title}`,
      path: d.path,
      round: `${d.round?.name?.name}${d.round?.nameSuffix ? ` ${d.round?.nameSuffix}` : ''}`,
      fund: d.fund?.nameAbbreviated,
      status: d.status,
      assignee: d.deputyInCharge ? [d.primaryInCharge, d.deputyInCharge, ...d.participants] : [d.primaryInCharge, ...d.participants],
      deadline: d.deadline?.format('YYYY/MM/DD'),
      investmentAmount: d.investmentAmount?.format() ?? '',
      updatedAt: d.updatedAt?.format('YYYY/MM/DD'),
      createdAt: d.createdAt?.format('YYYY/MM/DD')
    }
  })),
  total: computed(() => props.total ?? 0),
  loading: computed(() => props.loading)
})

const link = computed(() => {
  return props.query
    ? `/deals?condition[query]=${props.query}`
    : '/deals'
})
</script>

<template>
  <div class="DealCatalog">
    <div class="catalog">
      <STable class="list" :options="table" />

      <div v-if="!loading && deals?.length" class="action">
        <SButton
          type="outline"
          mode="info"
          :href="link"
          label="View more"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-name) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-name)             { --table-col-width: 512px; }
.list :deep(.col-round)            { --table-col-width: 144px; }
.list :deep(.col-fund)             { --table-col-width: 112px; }
.list :deep(.col-status)           { --table-col-width: 168px; }
.list :deep(.col-assignee)         { --table-col-width: 256px; }
.list :deep(.col-deadline)         { --table-col-width: 136px; }
.list :deep(.col-investmentAmount) { --table-col-width: 180px; }
.list :deep(.col-createdAt)        { --table-col-width: 136px; }
.list :deep(.col-updatedAt)        { --table-col-width: 136px; }

.action {
  padding-top: 32px;
  text-align: center;

  --button-padding: 0 24px;
}
</style>
