<script setup lang="ts">
import IconArrowClockwise from '~icons/ph/arrow-clockwise-bold'
import SButton from 'sefirot/components/SButton.vue'
import SInputSegments, { type Option as SegmentsOption } from 'sefirot/components/SInputSegments.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { ref } from 'vue'
import { type CompanyBusinessReportFinancialFiguresUnit, type UserFrag } from '@/graphql'
import { type Ymd } from '@/support/Day'
import CompanyBusinessReportInputFinancialResultsItem from './CompanyBusinessReportInputFinancialResultsItem.vue'

export interface FinancialResultData {
  period: Ymd | null
  unitId: number | null
  netSales: number | null
  ordinaryIncome: number | null
  netIncome: number | null
  totalAssets: number | null
  capital: number | null
  netAssets: number | null
  updatedBy: UserFrag | null
  updatedAt: string | null
}

const props = defineProps<{
  results: FinancialResultData[]
  units: CompanyBusinessReportFinancialFiguresUnit[]
  disabled?: boolean
  isLegacy: boolean
  onUpdate: (results: FinancialResultData[]) => void
}>()

const emit = defineEmits<{
  refresh: []
}>()

const { t } = useTrans({
  en: {
    raw: 'Raw',
    scaled: 'Scaled',
    refresh: 'Refresh',
    period: 'Period',
    unit: 'Currency',
    net_sales: 'Net sales',
    ordinary_income: 'Ordinary income',
    net_income: 'Net income',
    total_assets: 'Total assets',
    capital: 'Capital',
    net_assets: 'Net assets',
    updated_by: 'Updated by',
    updated_at: 'Updated at'
  },
  ja: {
    raw: '元の値',
    scaled: '丸めた値',
    refresh: '更新',
    period: '決算期',
    unit: '通貨',
    net_sales: '純売上',
    ordinary_income: '経常利益',
    net_income: '純利益',
    total_assets: '総資産',
    capital: '資本金',
    net_assets: '純資産',
    updated_by: '更新者',
    updated_at: '更新日時'
  }
})

const view = ref<'raw' | 'scaled'>('raw')

const viewOptions: SegmentsOption<'raw' | 'scaled'>[] = [
  { label: t.raw, value: 'raw' },
  { label: t.scaled, value: 'scaled' }
]

function update(index: number, result: FinancialResultData) {
  props.onUpdate(Object.assign([], props.results, {
    [index]: result
  }))
}
</script>

<template>
  <SCard class="CompanyBusinessReportInputFinancialResults">
    <SCardBlock v-if="!isLegacy" size="small" class="s-px-8">
      <SControl>
        <SControlLeft>
          <SInputSegments
            size="mini"
            v-model="view"
            :options="viewOptions"
          />
        </SControlLeft>
        <SControlRight>
          <SButton
            size="small"
            :icon="IconArrowClockwise"
            :label="t.refresh"
            :disabled="disabled"
            @click="emit('refresh')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>

    <div class="grid">
      <div class="columns">
        <p class="column">{{ t.period }}</p>
        <p class="column">{{ t.unit }}</p>
        <p class="column">{{ t.net_sales }}</p>
        <p class="column">{{ t.ordinary_income }}</p>
        <p class="column">{{ t.net_income }}</p>
        <p class="column">{{ t.total_assets }}</p>
        <p class="column">{{ t.capital }}</p>
        <p class="column">{{ t.net_assets }}</p>
        <p class="column">{{ t.updated_by }}</p>
        <p class="column">{{ t.updated_at }}</p>
      </div>
      <div class="records">
        <div class="items">
          <CompanyBusinessReportInputFinancialResultsItem
            v-for="(result, index) in results"
            :key="index"
            :result="result"
            :units="props.units"
            :view="view"
            @update="update(index, $event)"
          />
        </div>
      </div>
    </div>
  </SCard>
</template>

<style scoped lang="postcss">
.CompanyBusinessReportInputFinancialResults {
  overflow: hidden;
}

.grid {
  position: relative;
  display: flex;
}

.columns {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex-grow: 1;
  border-right: 1px solid var(--c-gutter);
  min-width: 144px;
  background-color: var(--c-gutter);
}

.column {
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 40px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
  background-color: var(--c-bg-elv-3);
}

.records {
  flex-grow: 1;
  padding-left: 144px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, minmax(152px, 1fr));
  gap: 1px;
  width: 100%;
}
</style>
