import {
  type CompanyExecutiveInformationInput,
  type CompanyInformationInput,
  type CompanyLatestAssigneesInput,
  type CompanyNameInput,
  type UpdateCompanyPortfolioDataCapitalInput,
  type UpdateCompanyPortfolioDataIssuedSharesInput,
  type UpdateCompanyPortfolioDataPotentialDilutiveSharesInput,
  type UpdateCompanyPortfolioDataPromoterInput
} from '@/graphql'
import { Company } from '@/models/Company'
import { CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import { CompanyVec } from '@/models/CompanyVec'
import { CompanyRequest } from '@/requests/CompanyRequest'
import { type Mutation, type Query, type UseQueryOptions, useMutation, useQuery } from '../Api'

export type CompanyItem = Query<Company | null>
export type CompanyUpdateInfo = Mutation<Company>
export type CompanyUpdateBusiness = Mutation<Company>
export type CompanyUpdateExecutive = Mutation<Company>
export type CompanyUpdateLatestAssignees = Mutation<Company>

export type CompanyAddName = Mutation<void>
export type CompanyUpdateName = Mutation<void>
export type CompanyDeleteName = Mutation<void>

export type CompanyPortfolioDataItem = Query<CompanyPortfolioData | null>
export type CompanyPortfolioDataFinancialFiguresItem = Query<CompanyPortfolioData | null>
export type CompanyPortfolioDataUpdateCapital = Mutation<void>
export type CompanyPortfolioDataUpdateIssuedShares = Mutation<void>
export type CompanyPortfolioDataUpdatePotentialDilutiveShares = Mutation<void>
export type CompanyPortfolioDataUpdatePromoter = Mutation<void>

export type CompanyVecItem = Query<CompanyVec | null>

export function useCompanyItem(id: number, options?: UseQueryOptions): CompanyItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetch(id)

    return res.data.company ? new Company(res.data.company) : null
  }, options)
}

export function useCompanyItemForCompanyBusinessReportCopy(id: number, options?: UseQueryOptions) {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchForCompanyBusinessReportCopy(id)

    return res.data.company ? new Company(res.data.company) : null
  }, options)
}

export function useCompanyItemForLocalNav(id: number, options?: UseQueryOptions): CompanyItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchForLocalNav(id)

    return res.data.company ? new Company(res.data.company) : null
  }, options)
}

export function useCompanyUpdateInfo(
  id: number,
  input: () => CompanyInformationInput
): CompanyUpdateInfo {
  return useMutation(async () => {
    const res = await new CompanyRequest().updateInfo(id, input())

    return new Company(res.data.company)
  })
}

export function useCompanyUpdateBusiness(
  id: number,
  input: () => CompanyInformationInput
): CompanyUpdateInfo {
  return useMutation(async () => {
    const res = await new CompanyRequest().updateInfo(id, input())

    return new Company(res.data.company)
  })
}

export function useCompanyUpdateExecutive(
  id: number,
  input: () => CompanyExecutiveInformationInput
): CompanyUpdateInfo {
  return useMutation(async () => {
    const res = await new CompanyRequest().updateExecutive(id, input())

    return new Company(res.data.company)
  })
}

export function useCompanyUpdateLatestAssignees(
  id: number,
  input: () => CompanyLatestAssigneesInput
): CompanyUpdateLatestAssignees {
  return useMutation(async () => {
    const res = await new CompanyRequest().updateLatestAssignees(id, input())

    return new Company(res.data.company)
  })
}

export function useCompanyClaim(id: number): CompanyUpdateInfo {
  return useMutation(async () => {
    const res = await new CompanyRequest().claimCompany(id)

    return new Company(res.data.company)
  })
}

export function useCompanyAddName(
  id: number,
  input: () => CompanyNameInput
): CompanyAddName {
  return useMutation(async () => {
    await new CompanyRequest().addName(id, input())
  })
}

export function useCompanyUpdateName(
  companyNameId: number,
  input: () => CompanyNameInput
): CompanyUpdateName {
  return useMutation(async () => {
    await new CompanyRequest().updateName(companyNameId, input())
  })
}

export function useCompanyDeleteName(companyNameId: number): CompanyDeleteName {
  return useMutation(async () => {
    await new CompanyRequest().deleteName(companyNameId)
  })
}

export function useCompanyPortfolioDataItem(companyId: number, options?: UseQueryOptions): CompanyPortfolioDataItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchPortfolioData(companyId)

    return res.data.portfolioData ? new CompanyPortfolioData(res.data.portfolioData) : null
  }, options)
}

export function useCompanyPortfolioDataFinancialFiguresItem(companyId: number, options?: UseQueryOptions): CompanyPortfolioDataFinancialFiguresItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchPortfolioDataFinancialFigures(companyId)

    return res.data.portfolioData ? new CompanyPortfolioData(res.data.portfolioData) : null
  }, options)
}

export function useCompanyPortfolioDataUpdateCapital(companyNameId: number, input: () => UpdateCompanyPortfolioDataCapitalInput): CompanyPortfolioDataUpdateCapital {
  return useMutation(async () => {
    await new CompanyRequest().updateCapital(companyNameId, input())
  })
}

export function useCompanyPortfolioDataUpdateIssuedShares(companyId: number, input: () => UpdateCompanyPortfolioDataIssuedSharesInput): CompanyPortfolioDataUpdateIssuedShares {
  return useMutation(async () => {
    await new CompanyRequest().updateIssuedShares(companyId, input())
  })
}

export function useCompanyPortfolioDataUpdatePotentialShares(companyNameId: number, input: () => UpdateCompanyPortfolioDataPotentialDilutiveSharesInput): CompanyPortfolioDataUpdatePotentialDilutiveShares {
  return useMutation(async () => {
    await new CompanyRequest().updatePotentialShares(companyNameId, input())
  })
}

export function useCompanyPortfolioDataUpdatePromoter(companyNameId: number, input: () => UpdateCompanyPortfolioDataPromoterInput): CompanyPortfolioDataUpdatePromoter {
  return useMutation(async () => {
    await new CompanyRequest().updatePromoter(companyNameId, input())
  })
}

export function useCompanyVecItem(companyId: number, options?: UseQueryOptions): CompanyVecItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchCompanyVec(companyId)

    return res.data.companyVEC ? new CompanyVec(res.data.companyVEC) : null
  }, options)
}
