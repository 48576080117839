import { CompanyMarketingModelsDocument } from '@/graphql'
import { Request } from './Request'

export class CompanyMarketingModelRequest extends Request {
  fetchAll() {
    return this.request({
      query: CompanyMarketingModelsDocument
    })
  }
}
