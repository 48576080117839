import { type Day, Model } from './Model'

export class CompanyName extends Model {
  id?: number
  companyId?: number
  displayName?: string
  officialNameLocal?: string
  officialNameEn?: string
  until: Day | null
  createdAt: Day | null
  updatedAt: Day | null

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.companyId = data.companyId
    this.displayName = data.displayName
    this.officialNameLocal = data.officialNameLocal
    this.officialNameEn = data.officialNameEn
    this.until = this.day(data.until)
    this.createdAt = this.day(data.createdAt)
    this.updatedAt = this.day(data.updatedAt)
  }
}
