<script setup lang="ts">
import SInputDropdown, { type ArrayValue, type PrimitiveValue, type Size } from 'sefirot/components/SInputDropdown.vue'
import { type Validatable } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { useCountryListCache } from '@/composables/repos/CountryRepo'

defineProps<{
  size?: Size
  label?: string
  note?: string
  help?: string
  placeholder?: string
  nullable?: boolean
  closeOnClick?: boolean
  disabled?: boolean
  modelValue: PrimitiveValue | ArrayValue
  validation?: Validatable
}>()

const model = defineModel<PrimitiveValue | ArrayValue>({ required: true })

const { data: countries } = useCountryListCache()

const options = computed(() => {
  return countries.value?.map((country) => {
    return {
      type: 'text',
      label: country.name!,
      value: country.id!,
      disabled: !country.enabled
    } as const
  }) ?? []
})
</script>

<template>
  <SInputDropdown
    class="DInputDropdown"
    :size="size"
    :label="label"
    :note="note"
    :help="help"
    :placeholder="placeholder"
    :close-on-click="closeOnClick"
    :disabled="disabled"
    :no-search="false"
    :options="options"
    :nullable="nullable"
    :validation="validation"
    v-model="model"
  />
</template>
