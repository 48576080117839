<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { reactive } from 'vue'
import {
  type OpportunityForDetailsFrag,
  type ProposalFrag
} from '@/graphql'
import { useDisableOpportunityProposals } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  proposals: ProposalFrag[]
}>()

const emit = defineEmits<{
  close: []
  disabled: []
}>()

const { t } = useTrans({
  en: {
    a_cancel: 'Cancel',
    a_disable: 'Disable proposals'
  },
  ja: {
    a_cancel: 'キャンセル',
    a_disable: 'Proposalを非開示にする'
  }
})

const disableProposalsMutation = reactive(useDisableOpportunityProposals())

async function disable() {
  await disableProposalsMutation.execute(props.opportunity.id)
  emit('disabled')
}
</script>

<template>
  <SCard size="medium" mode="warning">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>WARNING! ACT IMMEDIATELY!</h2>
          <p>Following proposals has been exported.</p>
          <ul>
            <li v-for="proposal in proposals" :key="proposal.id">
              {{ proposal.fundGroup.name }}
            </li>
          </ul>
          <p>If you need to hide this opportunity from the fund listed above, contact fund managers immediately, as they might have already shared the proposals. You can find managers at <SLink href="https://portal.globalbrains.com/org/teams">GB Portal: Teams</SLink>.</p>
          <p>Then, hit the “Disable” button on this dialog. This is going to hide entire proposals from all funds in order to prevent this opportunity getting shared with otehr funds.</p>
          <p>After that, contact a system administrator. They will work through with you to handle the situation.</p>
        </STrans>
        <STrans lang="ja">
          <h2>警告！ いますぐ行動してください！</h2>
          <p>以下のファンドの案件管理表がすでに出力されています。</p>
          <ul>
            <li v-for="proposal in proposals" :key="proposal.id">
              {{ proposal.fundGroup.name }}
            </li>
          </ul>
          <p>もしこれらのファンドへ間違って案件を開示してる場合、いますぐファンド責任者へ連絡してください。すでに案件管理表がファンドへ共有されている可能性があります。ファンド責任者は「<SLink href="https://portal.globalbrains.com/org/teams">GB Portal: Teams</SLink>」で確認できます。</p>
          <p>次に、このダイアログの「Proposalを非開示にする」ボタンをクリックしてください。これにより、全てのProposalが全ての案件管理表に出力されなくなります。これは二次被害を防ぐための措置です。</p>
          <p>最後に、システム管理者へ連絡してください。本件をどのようにハンドリングするべきかガイドします。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="disableProposalsMutation.loading"
            @click="$emit('close')"
          />
          <SControlButton
            mode="warning"
            :label="t.a_disable"
            :loading="disableProposalsMutation.loading"
            @click="disable"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
