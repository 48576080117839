import { type Query, useQuery } from 'sefirot/composables/Api'
import { type FundReport } from '@/graphql'
import { FundReportRequest } from '@/requests/FundReportRequest'

export type FundReportItem = Query<FundReport>

export function useFundReportItem(id: number): FundReportItem {
  return useQuery(async () => {
    return (await new FundReportRequest().fetch(id)).data.fundReport as FundReport
  })
}
