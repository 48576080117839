<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import day from 'dayjs'
import { keyBy, orderBy } from 'lodash-es'
import SModal from 'sefirot/components/SModal.vue'
import STable from 'sefirot/components/STable.vue'
import { usePower } from 'sefirot/composables/Power'
import { type ColorModes, useTable } from 'sefirot/composables/Table'
import { computed, ref, watch } from 'vue'
import { type OpportunityForDetailsFrag, type ProposalFrag, type ProposalViewExportStatusFrag } from '@/graphql'
import { useOpportunityProposalOps } from '@/composables/ops/OpportunityOps'
import { useCanUpdateOpportunityPolicy } from '@/composables/policies/OpportunityPolicy'
import OpportunityCardProposalsItem from './OpportunityCardProposalsItem.vue'
import OpportunityFormUpdateProposal from './OpportunityFormUpdateProposal.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  proposalViewExportStatuses: ProposalViewExportStatusFrag[]
  disabled: boolean
}>()

const emit = defineEmits<{
  updated: []
}>()

const canUpdateOpportunityPolicy = useCanUpdateOpportunityPolicy(() => props.opportunity)

const opportunityProposalOps = useOpportunityProposalOps()

const selectedProposal = ref<ProposalFrag | null>(null)

const { state: detailModal, on: openDetailModal, off: closeDetailModal } = usePower(false)
const { state: updateModal, on: openUpdateModal, off: closeUpdateModal } = usePower(false)

const exportStatusDict = computed(() => {
  return keyBy(props.proposalViewExportStatuses, 'fundGroupId')
})

const orderedProposals = computed(() => {
  return orderBy(props.opportunity.proposals, (p) => {
    return p.fundGroup.rank
  })
})

const showEditButton = computed(() => {
  return (
    !!canUpdateOpportunityPolicy.value.ok
    && !props.disabled
  )
})

const table = useTable({
  records: computed(() => orderedProposals.value),
  borderless: true,
  orders: [
    'fund',
    'lp',
    'status',
    'lastExportedAt',
    'openedAt',
    'closedAt',
    'statusUpdatedAt',
    'actions'
  ],
  columns: {
    fund: {
      label: 'Fund',
      cell: (_, propo) => ({
        type: 'text',
        color: 'info',
        value: propo.fundGroup.name,
        onClick: () => {
          selectedProposal.value = propo
          openDetailModal()
        }
      })
    },
    lp: {
      label: 'LP',
      cell: (_, propo) => ({
        type: 'text',
        value: propo.fundGroup.lp
      })
    },
    status: {
      label: 'Status',
      cell: (_, propo) => ({
        type: 'state',
        label: opportunityProposalOps.statusText(propo),
        mode: opportunityProposalOps.statusMode(propo) as ColorModes
      })
    },
    lastExportedAt: {
      label: 'Last exported at',
      cell: (_, propo) => ({
        type: 'day',
        value: getLastExportedAt(propo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    openedAt: {
      label: 'Opened at',
      cell: (_, propo) => ({
        type: 'day',
        value: opportunityProposalOps.createdAt(propo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    closedAt: {
      label: 'Closed at',
      cell: (_, propo) => ({
        type: 'day',
        value: opportunityProposalOps.closedAt(propo),
        format: 'YYYY-MM-DD'
      })
    },
    statusUpdatedAt: {
      label: 'Status updated at',
      cell: (_, propo) => ({
        type: 'day',
        value: opportunityProposalOps.statusUpdatedAt(propo),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    actions: {
      show: showEditButton,
      cell: (_, propo) => ({
        type: 'actions',
        actions: [
          {
            icon: IconNotePencil,
            onClick: () => {
              selectedProposal.value = propo
              openUpdateModal()
            }
          }
        ]
      })
    }
  }
})

function getLastExportedAt(propo: ProposalFrag) {
  const exportedAt = day(exportStatusDict.value[propo.fundGroupId]?.lastExportedAt ?? null)
  const openedAt = day(propo.createdAt)

  if (!exportedAt.isValid() || exportedAt.isBefore(openedAt)) {
    return null
  }

  return exportedAt
}

function onUpdatedViaItemModal() {
  watch(() => props.opportunity, () => {
    const updated = props.opportunity.proposals.find((propo) => propo.id === selectedProposal.value?.id)
    if (updated) {
      selectedProposal.value = updated
    }
  }, { once: true })
  emit('updated')
}

function onUpdatedViaUpdateModal() {
  selectedProposal.value = null
  emit('updated')
}
</script>

<template>
  <div class="OpportunityCardProposalsItems">
    <STable class="list" :options="table" />
    <SModal :open="detailModal">
      <OpportunityCardProposalsItem
        v-if="selectedProposal"
        :opportunity="opportunity"
        :proposal="selectedProposal"
        :disabled="disabled"
        @updated="onUpdatedViaItemModal"
        @close="closeDetailModal(() => selectedProposal = null)"
      />
    </SModal>
    <SModal :open="updateModal">
      <OpportunityFormUpdateProposal
        v-if="selectedProposal"
        :proposal="selectedProposal"
        :sources="opportunity.sources"
        @updated="closeUpdateModal(onUpdatedViaUpdateModal)"
        @close="closeUpdateModal(() => selectedProposal = null)"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.list {
  --table-head-position: sticky;
  --table-padding-left: 8px;
}

.list :deep(.col-fund) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-actions) {
  --table-col-position: sticky;
  --table-col-right: 0;
  --table-col-left: auto;
  --table-col-z-index: 50;
  --table-col-border-left: 1px;
}

.list :deep(.col-fund)            { --table-col-width: 104px; }
.list :deep(.col-lp)              { --table-col-width: 144px; }
.list :deep(.col-status)          { --table-col-width: 104px; }
.list :deep(.col-lastExportedAt)  { --table-col-width: 168px; }
.list :deep(.col-openedAt)        { --table-col-width: 168px; }
.list :deep(.col-closedAt)        { --table-col-width: 128px; }
.list :deep(.col-statusUpdatedAt) { --table-col-width: 168px; }
.list :deep(.col-actions)         { --table-col-width: 44px; }
</style>
