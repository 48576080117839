<script setup lang="ts">
import IconPlusCircle from '~icons/ph/plus-circle'
import { type ValidationArgs } from '@vuelidate/core'
import SButton from 'sefirot/components/SButton.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { format, toNumberOrNull } from '@/support/Num'
import DInputSharesItem from './DInputSharesItem.vue'

export interface Share {
  name: string | null
  count: number | null
}

export interface ShareRules {
  name?: ValidationArgs
  count?: ValidationArgs
}

const props = defineProps<{
  label?: string
  modelValue: Share[]
  rules?: ShareRules
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:model-value', shares: Share[]): void
}>()

const { t } = useTrans({
  en: {
    add_share: 'ADD SHARE',
    total: 'Total'
  },
  ja: {
    add_share: '株式を追加',
    total: '合計'
  }
})

const total = computed(() => {
  let maxDecimalLength = 0

  const value = props.modelValue.reduce<number>((sum, share) => {
    if (share.count !== null) {
      const parts = String(share.count).split('.')

      if (parts.length === 2) {
        maxDecimalLength = Math.max(maxDecimalLength, parts[1].length)
      }
    }

    sum += toNumberOrNull(share.count) ?? 0
    return sum
  }, 0)

  return format(toNumberOrNull(value.toFixed(maxDecimalLength).replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, '$1$2$3'))!)
})

function updateShare(index: number, value: Share) {
  emit('update:model-value', props.modelValue.map((share, i) => {
    return i === index ? value : share
  }))
}

function add() {
  emit('update:model-value', [
    ...props.modelValue,
    { name: null, count: null }
  ] as Share[])
}

function remove(index: number) {
  emit('update:model-value', props.modelValue.filter((_, i) => {
    return i !== index
  }))
}
</script>

<template>
  <div class="DInputShares">
    <label v-if="label" class="label">{{ label }}</label>

    <div class="container">
      <DInputSharesItem
        v-for="(item, index) in modelValue"
        :id="index"
        :key="index"
        :name="item.name"
        :count="item.count"
        :rules="rules"
        :disabled="disabled"
        @update="updateShare"
        @remove="remove"
      />

      <div class="add">
        <SButton
          type="text"
          mode="info"
          size="mini"
          :icon="IconPlusCircle"
          :label="t.add_share"
          @click="add"
          :disabled="disabled"
        />
      </div>

      <div class="total">
        <p class="total-label">{{ t.total }}</p>
        <p class="total-count">{{ total }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.label {
  display: block;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.container {
  border: 1px solid var(--c-divider-2);
  border-radius: 4px;
  overflow: hidden;
}

.add {
  padding: 10px 4px;
}

.total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--c-divider-2);
  padding: 11px 64px 12px 20px;
  background-color: var(--c-soft);
}

.total-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--c-text-2);
}

.total-count {
  font-size: 14px;
  font-weight: 600;
}
</style>
