<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { useGetRoundNameList } from '@/composables/repos/RoundNameRepo'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<number | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Round name *',
    info: 'The name of the round.',
    ph: 'Select round'
  },
  ja: {
    label: 'Round name *',
    info: 'ラウンドの名前。',
    ph: 'ラウンドを選択'
  }
})

const { execute: getRoundNameList } = useGetRoundNameList()

const { validation } = useValidation({
  model
}, {
  model: {
    required: required()
  }
}, {
  $scope: props.scope
})

const roundNames = await getRoundNameList()

const options: Option[] = roundNames.map((r) => {
  return { label: r.name, value: r.id, disabled: !r.enabled }
})
</script>

<template>
  <SInputDropdown
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :options="options"
    v-model="model"
    :validation="validation.model"
  />
</template>
