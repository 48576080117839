import { format } from './Num'

export function toStringOrNull(value?: string | number | null): string | null {
  return (value === null || value === undefined) ? null : String(value)
}

export function plural(str: [string, string], num?: number): string {
  num = num ?? 0

  return num > 1 ? `${format(num)} ${str[1]}` : `${num} ${str[0]}`
}
