import {
  type Get,
  type Query,
  type UseQueryOptions,
  useGet,
  useQuery
} from 'sefirot/composables/Api'
import { type Http } from 'sefirot/http/Http'
import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  type KadomonyNews,
  type KadomonyNewsSource,
  type KadomonyNewsType
} from '@/graph/Kadomony'
import { type Pagination } from '@/graph/Pagination'

export type KadomonyNewsPage = Query<Pagination<KadomonyNews>>
export type GetKadomonyNewsPage = Get<Pagination<KadomonyNews>>

export interface KadomonyNewsPageOptions {
  page?: number
  perPage?: number
  conditions?: KadomonyNewsPageOptionsCondition
}

export interface KadomonyNewsPageOptionsCondition {
  companyUrl?: string | null
  types?: KadomonyNewsType[]
  sources?: KadomonyNewsSource[]
}

export function useKadomonyNewsPage(
  options: MaybeRefOrGetter<KadomonyNewsPageOptions> = {},
  qoptions: UseQueryOptions = {}
): KadomonyNewsPage {
  return useQuery(async (http) => {
    return fetchKadompnyNewsPage(http, toValue(options))
  }, {
    watch: () => toValue(options),
    ...qoptions
  })
}

export function useGetKadomonyNewsPage(): GetKadomonyNewsPage {
  return useGet(async (http, options: KadomonyNewsPageOptions) => {
    return fetchKadompnyNewsPage(http, options)
  })
}

async function fetchKadompnyNewsPage(
  http: Http,
  options: KadomonyNewsPageOptions
): Promise<Pagination<KadomonyNews>> {
  return http.get<Pagination<KadomonyNews>>('/api/kadomony/news-page', {
    query: {
      page: options.page ?? 1,
      perPage: options.perPage ?? undefined,
      conditions: {
        companyUrl: options.conditions?.companyUrl ?? undefined,
        types: options.conditions?.types?.length ? options.conditions?.types : undefined,
        sources: options.conditions?.sources?.length ? options.conditions?.sources : undefined
      }
    }
  })
}
