<script setup lang="ts">
import SAlert from 'sefirot/components/SAlert.vue'
import SInputDate from 'sefirot/components/SInputDate.vue'
import SInputFile from 'sefirot/components/SInputFile.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useValidation } from 'sefirot/composables/Validation'
import { type Day } from 'sefirot/support/Day'
import { maxFileSize, required } from 'sefirot/validation/rules'
import { useRouter } from 'vue-router'
import { type FundDealEntrySettingsInvestmentCommitteeSubmitDoc } from '@/graph/Fund'
import { type Deal } from '@/models/Deal'
import { useSubmitDocsForIc } from '@/composables/repos/DealEntryRepo'
import DealEntryIcDocsComplete from './DealEntryIcDocsComplete.vue'

const props = defineProps<{
  deal: Deal
  settings: FundDealEntrySettingsInvestmentCommitteeSubmitDoc
}>()

const { t } = useTrans({
  en: {
    i_date_label: 'Date of the committee *',
    i_docs_label: 'Docs *',
    i_docs_help: 'If you need to submit multiple files, zip them and upload the zip.',
    submit: 'Submit request'
  },
  ja: {
    i_date_label: '投資委員会開催日 *',
    i_docs_label: '資料 *',
    submit: '送信する'
  }
})

const router = useRouter()

const { execute: submitDocsForIc, loading } = useSubmitDocsForIc(() => props.deal.id!, () => ({
  date: data.value.date!.format('YYYY-MM-DD'),
  file: data.value.file!
}))

const completeDialog = usePower()

const { data } = useData({
  date: null as Day | null,
  file: null as File | null
})

const { validation, validateAndNotify } = useValidation(data, {
  date: { required: required() },
  file: { required: required(), maxFileSize: maxFileSize('50mb') }
})

async function submit() {
  if (await validateAndNotify()) {
    await submitDocsForIc()
    completeDialog.on()
  }
}

function back() {
  router.push(`${props.deal.path}/entries`)
}
</script>

<template>
  <div class="DealEntryIcRequest">
    <div class="container">
      <SCard>
        <SCardBlock class="s-p-48">
          <SDoc>
            <SContent>
              <STrans lang="en">
                <h1>Submit Docs for Investment Committee</h1>
                <p>You may submit the docs required for the Investment Committee from this form. Once you’ve successfully submit the docs, a new slack channel will be created and you’ll be invited there for further discussion. Finalize the documents after receiving confirmation of the contents from Yasuda-san and others. In the unlikely event of a delay beyond the sending date, please consult Yasuda-san in advance.</p>
                <p>The deadline for doc submission is as follows.</p>
                <ul>
                  <li>AC not required: 2 working days in the morning (for Wednesday events: Monday morning)</li>
                  <li>AC required: 3 working days in the morning (for Wednesday events: Friday morning)</li>
                </ul>
                <p>The finalized docs are shared by fund management team to the GP by the day before the investment committee meeting (in the case DD was performed by the DD team, the DD part is merged by the fund management team).</p>
                <p>If you need to submit multiple files, zip them and upload the zip.</p>
                <ul>
                  <li>Investment Committee Deck</li>
                  <li>Business plan</li>
                  <li>Conservative plan</li>
                </ul>
              </STrans>
              <STrans lang="ja">
                <h1>投資委員会 資料確認依頼</h1>
                <p>投資委員会資料の確認依頼のためのエントリーです。エントリー後、資料の内容を議論するためのSlackチャンネルが自動作成され、招待されます。その後、安田さん等の内容確認を受け、資料の最終化を行ってください。万が一送付期日より遅れる場合は事前に安田さんに相談してください。</p>
                <p>送付期日は以下の通りです。</p>
                <ul>
                  <li>AC対象外: 2営業日前午前中（水曜日開催の場合: 月曜日午前中）</li>
                  <li>AC対象: 3営業日前午前中（水曜日開催の場合: 金曜日午前中）</li>
                </ul>
                <p>最終化された投資委員会資料は投資委員会開催前日までにファンド管理よりGPへ共有します（DDチームによるDDの場合、DDパートはファンド管理チーム側でマージします）。</p>
                <p>資料が複数ある場合、ZIPに固めてアップロードしてください。</p>
                <ul>
                  <li>投資委員会資料</li>
                  <li>事業計画</li>
                  <li>保守計画</li>
                </ul>
              </STrans>
            </SContent>
            <SAlert mode="warning">
              <STrans lang="en">
                <p class="s-font-14">Ensure that the ESG Checklist is included in the Investment Committee deck.</p>
              </STrans>
              <STrans lang="ja">
                <p class="s-font-14">投資委員会資料には必ずESGチェック項目の資料も含めてください。</p>
              </STrans>
            </SAlert>
            <SInputDate
              :label="t.i_date_label"
              v-model="data.date"
              block
              :validation="validation.date"
            />
            <SInputFile
              :label="t.i_docs_label"
              placeholder="No file chosen"
              v-model="data.file"
              :validation="validation.file"
            />
          </SDoc>
        </SCardBlock>
        <SCardBlock size="xlarge" class="s-px-48">
          <SControl>
            <SControlRight>
              <SControlButton
                mode="info"
                :label="t.submit"
                :loading="loading"
                @click="submit"
              />
            </SControlRight>
          </SControl>
        </SCardBlock>
      </SCard>
    </div>

    <SModal :open="completeDialog.state.value">
      <DealEntryIcDocsComplete @close="back" />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.DealEntryIcRequest {
  padding: 32px 32px 128px;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}
</style>
