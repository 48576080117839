<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import SInputNumber from 'sefirot/components/SInputNumber.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useLang, useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, maxValue, required, rule } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type Company } from '@/models/Company'
import { useCompanyUpdateBusiness } from '@/composables/company/CompanyData'
import { useCompanyMarketingModelList } from '@/composables/repos/CompanyMarketingModelRepo'
import { useCompanySectorList } from '@/composables/repos/CompanySectorRepo'
import DInputCountry from '../DInputCountry.vue'

const props = defineProps<{
  company: Company
}>()

const emit = defineEmits<{
  cancel: []
  updated: []
}>()

const lang = useLang()

const isEn = lang === 'en'

const { t } = useTrans({
  en: {
    title: 'Update business information',
    i_active_countries_label: 'Active countries',
    i_active_countries_ph: 'Select countries',
    i_active_countries_help: 'Select countries where the company is actively doing business.',
    i_num_employees_label: 'Number of employees',
    i_num_employees_ph: '100',
    i_marketing_model_label: 'Marketing model *',
    i_marketing_model_ph: 'Select a marketing model',
    i_primary_sector_label: 'Primary sector *',
    i_primary_sector_ph: 'Select a sector',
    i_sub_sectors_label: 'Sub sectors',
    i_sub_sectors_ph: 'Select sectors',
    i_description_label: 'Description *',
    i_description_ph: 'e.g. Global Brain is an independent venture capital firm based in Tokyo, Japan. It supports startups globally through hands-on support, a global ecosystem, and open innovation between startups and major corporations. The firm specializes in seed, early, and later-stage investments.',
    a_cancel: 'Cancel',
    a_update: 'Update business information'
  },
  ja: {
    title: '事業情報を更新',
    i_active_countries_label: '事業を展開している国',
    i_active_countries_ph: '国を選択してください',
    i_active_countries_help: '当社の所在国に関わらず、実際に事業展開している国を選択してください。',
    i_num_employees_label: '従業員数',
    i_num_employees_ph: '100',
    i_marketing_model_label: 'マーケティングモデル *',
    i_marketing_model_ph: 'モデルを選択してください',
    i_primary_sector_label: 'Primary sector *',
    i_primary_sector_ph: 'セクターを選択してください',
    i_sub_sectors_label: 'Sub sectors',
    i_sub_sectors_ph: 'セクターを選択してください',
    i_description_label: '企業概要 *',
    i_description_ph: '例: Global Brainは、東京を拠点とする独立系ベンチャーキャピタルファームです。グローバルなエコシステム、スタートアップと大手企業のオープンイノベーション、ハンズオンサポートを通じて、世界中のスタートアップを支援しています。同社は、シード、アーリー、レーターステージの投資を専門としています。',
    a_cancel: 'キャンセル',
    a_update: '事業情報を更新'
  }
})

const { data: marketingModels } = useCompanyMarketingModelList()
const { data: sectors } = useCompanySectorList()

const { data } = useData({
  activeCountryIds: props.company.activeCountries!.map((a) => a.id!),
  numEmployees: props.company.numEmployees ?? null,
  marketingModelId: props.company.marketingModel!.id!,
  primarySectorId: props.company.primarySector!.id!,
  subSectorIds: props.company.subSectors!.map((s) => s.id!),
  description: props.company.description!
})

const { validation, validateAndNotify } = useValidation(data, {
  numEmployees: {
    maxValue: maxValue(1000000)
  },
  marketingModelId: {
    required: required()
  },
  primarySectorId: {
    required: required()
  },
  subSectorIds: {
    notSameAs: rule(
      (value) => data.value.primarySectorId ? !((value as unknown[]).includes(data.value.primarySectorId)) : true,
      isEn ? 'Some sector is already selected as Primary sector.' : '選択されたセクターがすでにPrimary sectorとして設定されています。'
    )
  },
  description: {
    required: required(),
    maxLength: maxLength(3000)
  }
})

const marketingModelOptions = computed<Option[]>(() => {
  return marketingModels.value?.map((m) => {
    return { type: 'text', label: m.name!, value: m.id!, disabled: !m.enabled }
  }) ?? []
})

const sectorOptions = computed<Option[]>(() => {
  return sectors.value?.map((s) => {
    return { type: 'text', label: s.name!, value: s.id!, disabled: !s.enabled }
  }) ?? []
})

const { loading, execute } = useCompanyUpdateBusiness(props.company.id!, () => ({
  website: props.company.website,
  foundedYear: props.company.foundedYear,
  foundedMonth: props.company.foundedMonth,
  fiscalMonth: props.company.fiscalMonth,
  countryId: props.company.country!.id!,
  zipcode: props.company.zipcode,
  address: props.company.address,
  addressHouseNumber: props.company.addressHouseNumber,
  activeCountryIds: data.value.activeCountryIds,
  numEmployees: data.value.numEmployees,
  marketingModelId: data.value.marketingModelId!,
  primarySectorId: data.value.primarySectorId!,
  subSectorIds: data.value.subSectorIds,
  description: data.value.description!
}))

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>{{ t.title }}</h2>
        </SContent>
        <SGrid cols="6" gap="24">
          <SGridItem span="6">
            <DInputCountry
              :label="t.i_active_countries_label"
              :placeholder="t.i_active_countries_ph"
              nullable
              v-model="data.activeCountryIds"
            />
          </SGridItem>
          <SGridItem span="2">
            <SInputNumber
              :label="t.i_num_employees_label"
              :placeholder="t.i_num_employees_ph"
              separator
              v-model="data.numEmployees"
              :validation="validation.numEmployees"
            />
          </SGridItem>
          <SGridItem span="4" />
          <SGridItem span="3">
            <SInputDropdown
              :label="t.i_marketing_model_label"
              :placeholder="t.i_marketing_model_ph"
              :options="marketingModelOptions"
              v-model="data.marketingModelId"
              :validation="validation.marketingModelId"
            />
          </SGridItem>
          <SGridItem span="3" />
          <SGridItem span="3">
            <SInputDropdown
              :label="t.i_primary_sector_label"
              :placeholder="t.i_primary_sector_ph"
              :options="sectorOptions"
              v-model="data.primarySectorId"
              :validation="validation.primarySectorId"
            />
          </SGridItem>
          <SGridItem span="3" />
          <SGridItem span="6">
            <SInputDropdown
              :label="t.i_sub_sectors_label"
              :placeholder="t.i_sub_sectors_ph"
              :options="sectorOptions"
              nullable
              v-model="data.subSectorIds"
              :validation="validation.subSectorIds"
            />
          </SGridItem>
          <SGridItem span="6">
            <SInputTextarea
              :label="t.i_description_label"
              :placeholder="t.i_description_ph"
              :rows="8"
              v-model="data.description"
              :validation="validation.description"
            />
          </SGridItem>
        </SGrid>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_update"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
