import { type RoleFrag } from '@/graphql'
import { RoleRequest } from '@/requests/RoleRequest'
import { type Query, useQuery } from '../Api'

export type RoleList = Query<RoleFrag[], []>

export function useRoleList(): RoleList {
  return useQuery<RoleFrag[], []>(async () => {
    const res = await new RoleRequest().fetchAll()

    return res.data.roles
  })
}
