<script setup lang="ts">
import { type Company } from '@/models/Company'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import CompanyCardNameHistoryAdd from './CompanyCardNameHistoryAdd.vue'
import CompanyCardNameHistoryItem from './CompanyCardNameHistoryItem.vue'

const props = defineProps<{
  company: Company
}>()

defineEmits<{
  (e: 'close'): void
  (e: 'updated'): void
}>()

const { canUpdate } = useCompanyPolicy(() => props.company)
</script>

<template>
  <DCard
    title="Company names"
    :collapsable="false"
    :closable="true"
    @close="$emit('close')"
  >
    <div class="container">
      <div v-if="canUpdate" class="add">
        <CompanyCardNameHistoryAdd
          :company="company"
          @added="$emit('updated')"
        />
      </div>

      <div class="list">
        <div v-for="name in company.names" :key="name.id!" class="item">
          <CompanyCardNameHistoryItem
            :name="name"
            :company="company"
            @updated="$emit('updated')"
          />
        </div>
      </div>
    </div>
  </DCard>
</template>

<style scoped lang="postcss">
.container {
  padding: 24px;
}

.add {
  padding-bottom: 16px;
}

.item {
  & + & {
    padding-top: 16px;
  }
}
</style>
