<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  open: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})
</script>

<template>
  <DModal size="small" :open="open" @close="$emit('close')">
    <DCard :header="false">
      <DCardClose @close="$emit('close')" />

      <DForm>
        <DFormSection>
          <STrans lang="en">
            <DFormTitle>Exporting report</DFormTitle>
            <DFormMedium>
              <p>Started exporting the report. You should receive the file via Slack shortly. Usually, it should take max few minutes. If you do not receive the file, please contact system administrators.</p>
            </DFormMedium>
          </STrans>
          <STrans lang="ja">
            <DFormTitle>投資報告書のエクスポートを開始</DFormTitle>
            <DFormMedium>
              <p>投資報告書のエクスポートを開始しました。エクスポートが完了するとSlackへファイルが届きます。通常、数分以内に完了します。もししばらく経ってもファイルが届かない場合、システム管理者までお問い合わせください。</p>
            </DFormMedium>
          </STrans>
        </DFormSection>
      </DForm>

      <DCardFooter>
        <DCardFooterActions>
          <DCardFooterAction
            :label="t.close"
            @click="$emit('close')"
          />
        </DCardFooterActions>
      </DCardFooter>
    </DCard>
  </DModal>
</template>
