import { type Mutation, useMutation } from 'sefirot/composables/Api'
import { type CompanySupportLogWithMembers } from '@/graph/Company'

export type CreateCompanySupportLog = Mutation<CompanySupportLogWithMembers, [input: CreateCompanySupportLogInput]>
export type UpdateCompanySupportLog = Mutation<CompanySupportLogWithMembers, [id: number, input: UpdateCompanySupportLogInput]>
export type DeleteCompanySupportLog = Mutation<void, [id: number]>

export interface CreateCompanySupportLogInput {
  companyId: number
  title: string
  description: string
  members: CreateCompanySupportLogInputMember[]
}

export interface CreateCompanySupportLogInputMember {
  userId: number
  weight: number
}

export interface UpdateCompanySupportLogInput {
  title: string
  description: string
  members: UpdateCompanySupportLogInputMember[]
}

export interface UpdateCompanySupportLogInputMember {
  userId: number
  weight: number
}

export function useCreateCompanySupportLog(): CreateCompanySupportLog {
  return useMutation(async (http, input) => {
    return http.post('/api/company-support-logs', input)
  })
}

export function useUpdateCompanySupportLog(): UpdateCompanySupportLog {
  return useMutation(async (http, id, input) => {
    return http.put(`/api/company-support-logs/${id}`, input)
  })
}

export function useDelteCompanySupportLog(): DeleteCompanySupportLog {
  return useMutation(async (http, id) => {
    return http.delete(`/api/company-support-logs/${id}`)
  })
}
