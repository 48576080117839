import { OpportunityInvestmentRequestsDocument } from '@/graphql'
import { Request } from './Request'

export class OpportunityInvestmentRequestRequest extends Request {
  fetchAll() {
    return this.request({
      query: OpportunityInvestmentRequestsDocument
    })
  }
}
