<script setup lang="ts">
import { keyBy } from 'lodash-es'
import { useValidation } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { useGetCurrencyList } from '@/composables/repos/CurrencyRepo'
import RoundInputCurrencyAsync from './input/RoundInputCurrencyAsync.vue'
import RoundInputNotes from './input/RoundInputNotes.vue'
import RoundInputParticipatingInvestors from './input/RoundInputParticipatingInvestors.vue'
import RoundInputPostValuation from './input/RoundInputPostValuation.vue'
import RoundInputPreIssuedShares from './input/RoundInputPreIssuedShares.vue'
import RoundInputPreValuation from './input/RoundInputPreValuation.vue'
import RoundInputRoundNameAsync from './input/RoundInputRoundNameAsync.vue'
import RoundInputRoundNameSuffix from './input/RoundInputRoundNameSuffix.vue'
import RoundInputSchedule from './input/RoundInputSchedule.vue'
import RoundInputTotalAmount from './input/RoundInputTotalAmount.vue'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<{
  roundName: number | null
  nameSuffix: string | null
  schedule: string | null
  currency: number | null
  totalAmount: string | null
  preValuation: string | null
  postValuation: string | null
  preIssuedShares: string | null
  participatingInvestors: string | null
  notes: string | null
}>({ required: true })

const { execute: getCurrencyList } = useGetCurrencyList()

useValidation({}, {}, { $scope: props.scope })

const currencies = await getCurrencyList()

const currencyDict = keyBy(currencies, 'id')

const selectedCurrencyModel = computed(() => {
  return model.value.currency ? currencyDict[model.value.currency] : null
})
</script>

<template>
  <SGrid cols="2" gap="24">
    <SGridItem span="1">
      <RoundInputRoundNameAsync
        v-model="model.roundName"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <RoundInputRoundNameSuffix
        v-model="model.nameSuffix"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="2">
      <RoundInputSchedule
        v-model="model.schedule"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <RoundInputCurrencyAsync
        :currencies="currencies"
        v-model="model.currency"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1" />
    <SGridItem span="1">
      <RoundInputTotalAmount
        :currency="selectedCurrencyModel"
        v-model="model.totalAmount"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1" />
    <SGridItem span="1">
      <RoundInputPreValuation
        :currency="selectedCurrencyModel"
        v-model="model.preValuation"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="1">
      <RoundInputPostValuation
        :currency="selectedCurrencyModel"
        v-model="model.postValuation"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="2">
      <RoundInputPreIssuedShares
        v-model="model.preIssuedShares"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="2">
      <RoundInputParticipatingInvestors
        v-model="model.participatingInvestors"
        :scope="scope"
      />
    </SGridItem>
    <SGridItem span="2">
      <RoundInputNotes
        v-model="model.notes"
        :scope="scope"
      />
    </SGridItem>
  </SGrid>
</template>
