<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import IconSparkle from '~icons/ph/sparkle-bold'
import { orderBy } from 'lodash-es'
import SAlert from 'sefirot/components/SAlert.vue'
import STable from 'sefirot/components/STable.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { useTable } from 'sefirot/composables/Table'
import { computedWhen } from 'sefirot/composables/Utils'
import { computed, ref } from 'vue'
import { type ExtractedOpportunity } from '@/graph/Opportunity'
import {
  type ActionNoteFrag,
  type CompanyAndActionNotesAndPermissionFrag
} from '@/graphql'
import { useActionNoteOps } from '@/composables/ops/ActionNoteOps'
import { useUserOps } from '@/composables/ops/UserOps'
import { useExtractActionNoteToOpportunity } from '@/composables/repos/OpportunityRepo'
import ActionNoteCard from '../action-note/ActionNoteCard.vue'
import ActionNoteFormCreate from '../action-note/ActionNoteFormCreate.vue'

const props = defineProps<{
  company: CompanyAndActionNotesAndPermissionFrag
}>()

const emit = defineEmits<{
  'executed': [oppo: ExtractedOpportunity]
  'action-note-created': [actionNote: ActionNoteFrag]
  'action-note-updated': [actionNote: ActionNoteFrag]
  'action-note-deleted': [actionNote: ActionNoteFrag]
}>()

const { t } = useTrans({
  en: {
    c_title: 'Title',
    c_created_by: 'Created by',
    c_created_at: 'Created at',
    a_new_action_note: 'New action note',
    a_fill_by_ai: 'Auto fill by AI',
    a_fill_by_ai_disabled: 'You must select an action note.'
  },
  ja: {
    c_title: 'タイトル',
    c_created_by: '作成者',
    c_created_at: '作成日時',
    a_new_action_note: '新規Action Note',
    a_fill_by_ai: 'AIで自動入力',
    a_fill_by_ai_disabled: 'Action Noteを選択してください。'
  }
})

const extractActionNoteMutation = useExtractActionNoteToOpportunity()

const userOps = useUserOps()
const actionNoteOps = useActionNoteOps()

const fillStatus = ref<'ready' | 'loading' | 'completed'>('ready')

const actionNoteCreateDialog = usePower()
const actionNoteCardDialog = usePower()

const selectedActionNoteIdForFill = ref<number | null>(null)
const selectedActionNoteIdForDialog = ref<number | null>(null)

const selectedActionNoteForDialog = computedWhen(selectedActionNoteIdForDialog, (id) => {
  return props.company.actionNotes.find((an) => an.id === id)
})

const deletedActionNote = ref<ActionNoteFrag | null>(null)

const table = useTable({
  records: computed(() => orderBy(props.company.actionNotes, ['createdAt'], ['desc'])),
  indexField: 'id',
  borderless: true,
  orders: [
    'title',
    'createdBy',
    'createdAt'
  ],
  columns: {
    title: {
      label: t.c_title,
      grow: true,
      cell: (_, an) => ({
        type: 'text',
        value: an.title,
        onClick() {
          selectedActionNoteIdForDialog.value = an.id
          actionNoteCardDialog.on()
        }
      })
    },
    createdBy: {
      label: t.c_created_by,
      cell: (_, an) => ({
        type: 'avatar',
        image: userOps.avatarPath(an.createdBy),
        name: userOps.name(an.createdBy)
      })
    },
    createdAt: {
      label: t.c_created_at,
      cell: (_, an) => ({
        type: 'day',
        value: actionNoteOps.createdAt(an),
        format: 'YYYY-MM-DD HH:mm'
      })
    }
  }
})

const fillButtonTooltip = computed(() => {
  return selectedActionNoteIdForFill.value === null
    ? t.a_fill_by_ai_disabled
    : undefined
})

function onActionNoteCreated(actionNote: ActionNoteFrag) {
  actionNoteCreateDialog.off()
  emit('action-note-created', actionNote)
}

function onActionNoteUpdated(actionNote: ActionNoteFrag) {
  emit('action-note-updated', actionNote)
}

function onActionNoteDeleted() {
  deletedActionNote.value = selectedActionNoteForDialog.value!
  actionNoteCardDialog.off()
}

function onActionNoteCardUnmounted() {
  if (deletedActionNote.value) {
    emit('action-note-deleted', deletedActionNote.value)
    deletedActionNote.value = null
  }
}

async function onSubmit() {
  fillStatus.value = 'loading'
  const res = await extractActionNoteMutation.execute(selectedActionNoteIdForFill.value!)
  fillStatus.value = 'completed'
  emit('executed', res)
}
</script>

<template>
  <div class="OpportunityFormFillByAi">
    <SCard>
      <SCardBlock class="s-p-16">
        <div class="block">
          <div class="header">
            <STrans lang="en">
              <div class="title">Auto fill from an action note.</div>
              <div class="lead">AI can help you fill in the form from an action note.</div>
            </STrans>
            <STrans lang="ja">
              <div class="title">Action Noteを使ってフォームを入力する。</div>
              <div class="lead">AIを活用して、Action Noteの内容をフォームに入力することができます。</div>
            </STrans>
          </div>
        </div>
      </SCardBlock>
      <SCardBlock>
        <STable
          class="table"
          :options="table"
          v-model:selected="selectedActionNoteIdForFill"
        />
      </SCardBlock>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <SControlButton
              type="text"
              mode="mute"
              :icon="IconPlus"
              :label="t.a_new_action_note"
              @click="actionNoteCreateDialog.on"
            />
          </SControlLeft>
          <SControlRight>
            <SControlButton
              :icon="IconSparkle"
              :label="t.a_fill_by_ai"
              :disabled="(selectedActionNoteIdForFill === null) || (fillStatus === 'loading')"
              :tooltip="fillButtonTooltip"
              @click="onSubmit"
            />
          </SControlRight>
        </SControl>
      </SCardBlock>

      <SModal :open="actionNoteCreateDialog.state.value" @close="actionNoteCreateDialog.off">
        <ActionNoteFormCreate
          :company="company"
          @cancel="actionNoteCreateDialog.off"
          @created="onActionNoteCreated"
        />
      </SModal>

      <SModal :open="actionNoteCardDialog.state.value" @close="actionNoteCardDialog.off">
        <ActionNoteCard
          :action-note="selectedActionNoteForDialog!"
          :company="company"
          @close="actionNoteCardDialog.off"
          @updated="onActionNoteUpdated"
          @deleted="onActionNoteDeleted"
          @vue:unmounted="onActionNoteCardUnmounted"
        />
      </SModal>
    </SCard>
    <div class="message">
      <div v-if="fillStatus === 'loading'" class="loading">
        <STrans lang="en">
          <div class="loading-text">Processing data...</div>
          <div class="loading-text">This may take around 10–15 seconds.</div>
        </STrans>
        <STrans lang="ja">
          <div class="loading-text">データをプロセスしています。</div>
          <div class="loading-text">およそ10–15秒程度かかります。</div>
        </STrans>
      </div>
      <SAlert v-else-if="fillStatus === 'completed'" mode="success">
        <SContent>
          <STrans lang="en">
            <p>AI has filled in the inputs. Scroll down to see the results.</p>
          </STrans>
          <STrans lang="ja">
            <p>AIによる入力が完了しました。下にスクロールして結果をご確認ください。</p>
          </STrans>
        </SContent>
      </SAlert>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.OpportunityFormFillByAi {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.lead {
  font-size: 14px;
  color: var(--c-text-2);
}

.table {
  --table-max-height: 225px;
}

.table :deep(.col-title)     { --table-col-width: 168px; }
.table :deep(.col-createdBy) { --table-col-width: 168px; }
.table :deep(.col-createdAt) { --table-col-width: 168px; }

.loading {
  border: 1px solid var(--c-divider);
  border-radius: 6px;
  padding: 16px;
  text-align: center;
}

.loading-text {
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
