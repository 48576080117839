import { useUpdateUserSettingsLang } from './repos/UserRepo'

export interface Lang {
  update(lang: 'en' | 'ja'): Promise<void>
}

export function useLang(): Lang {
  const updateLang = useUpdateUserSettingsLang()

  async function update(lang: 'en' | 'ja'): Promise<void> {
    return updateLang.execute(lang)
  }

  return {
    update
  }
}
