import { type Opportunity } from '@/models/Opportunity'
import { type Authorized, type Resource, usePolicy } from '../Policy'

export interface OpportunityPolicy {
  canView: Authorized
  canUpdate: Authorized
  canViewFiles: Authorized
  canRequestFiles: Authorized
  canAddDeal: Authorized
}

export function useOpportunityPolicy(oppo?: Resource<Opportunity>): OpportunityPolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(oppo, (u, o) => u.can('read', o))
  const canUpdate = defineWhen(oppo, (u, o) => u.can('edit', o))

  const canViewFiles = defineWhen(oppo, (u, o) => u.can('read', 'opportunityFile', o))
  const canRequestFiles = defineWhen(oppo, (u, o) => {
    return u.can('read', 'opportunityFile', o) && !u.can('add', 'opportunityFile', o)
  })

  const canAddDeal = defineWhen(oppo, (u, o) => {
    return !!o.round && u.can('add', 'deal', o)
  })

  return {
    canView,
    canUpdate,
    canViewFiles,
    canRequestFiles,
    canAddDeal
  }
}
