import { orderBy } from 'lodash-es'
import { type CompanyBusinessReportFinancialFiguresUnitFrag } from '@/graphql'
import CompanyBusinessReportFinancialFiguresUnitRequest from '@/requests/CompanyBusinessReportFinancialFiguresUnitRequest'
import { type Query, useQuery } from '../Api'

export type CompanyBusinessReportFinancialFiguresUnitList = Query<CompanyBusinessReportFinancialFiguresUnitFrag[], []>

export function useCompanyBusinessReportFinancialFiguresUnitList(): CompanyBusinessReportFinancialFiguresUnitList {
  return useQuery(async () => {
    const { data: { companyBusinessReportFinancialFiguresUnits } } = await new CompanyBusinessReportFinancialFiguresUnitRequest().fetchAll()

    return orderBy(companyBusinessReportFinancialFiguresUnits, (c) => c.rank)
  })
}
