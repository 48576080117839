<script setup lang="ts">
import Fuse from 'fuse.js'
import { orderBy, xor } from 'lodash-es'
import STable from 'sefirot/components/STable.vue'
import { useData } from 'sefirot/composables/Data'
import { createDropdown } from 'sefirot/composables/Dropdown'
import { useTrans } from 'sefirot/composables/Lang'
import { useTable } from 'sefirot/composables/Table'
import { useUrlQuerySync } from 'sefirot/composables/Url'
import { computed } from 'vue'
import { type FundStatus, type FundWithStats } from '@/graph/Fund'
import { FundStatus as FundStatusEnum } from '@/graphql'
import { useFundOps } from '@/composables/ops/FundOps'
import { useFundStatsOps } from '@/composables/ops/FundStatsOps'
import { useFundWithStatsListCache } from '@/composables/repos/FundRepo'
import DInputSearch from '../DInputSearch.vue'

export type SortableField = keyof typeof sortableFields

const { t } = useTrans({
  en: {
    c_search_ph: 'Search funds',
    c_reset_filters: 'Reset filters',
    l_name_abbreviated: 'Name',
    l_status: 'Status',
    l_size: 'Size (JPY)',
    l_investable_amount: 'Investable (JPY)',
    l_invested_amount_jpy: 'Invested (JPY)',
    l_deployment_rate: 'Deployment rate',
    l_deal_invested_count: 'Invested deals',
    l_deal_in_progress_count: 'In progress deals',
    l_portfolio_count: 'Portfolio',
    l_portfolio_active_count: 'Active portfolio',
    l_portfolio_ex_count: 'Ex portfolio',
    l_start_date: 'Start date',
    l_investment_end_date: 'Investable until',
    l_end_date: 'End date',
    l_created_at: 'Created at',
    l_updated_at: 'Updated at',
    f_sort_asc: 'Sort ascending (A...Z)',
    f_sort_desc: 'Sort descending (Z...A)'
  },
  ja: {
    c_search_ph: 'ファンドを検索',
    c_reset_filters: 'フィルタをリセット',
    l_name_abbreviated: '名称',
    l_status: 'ステータス',
    l_size: '運用総額 (JPY)',
    l_investable_amount: '投資可能額 (JPY)',
    l_invested_amount_jpy: '投資総額 (JPY)',
    l_deployment_rate: '投資進捗率',
    l_deal_invested_count: '投資済みディール',
    l_deal_in_progress_count: '進行中ディール',
    l_portfolio_count: 'Portfolio',
    l_portfolio_active_count: 'Active portfolio',
    l_portfolio_ex_count: 'Ex portfolio',
    l_start_date: 'ファンド設立日',
    l_investment_end_date: '投資終了日',
    l_end_date: 'ファンド満期',
    l_created_at: '作成日時',
    l_updated_at: '更新日時',
    f_sort_asc: 'ソート: 昇順 (A...Z)',
    f_sort_desc: 'ソート: 降順 (Z...A)'
  }
})

const sortableFields = {
  nameAbbreviated: (f: FundWithStats) => f.nameAbbreviated,
  status: (f: FundWithStats) => f.status,
  size: (f: FundWithStats) => f.size,
  investableAmount: (f: FundWithStats) => Number(f.investableAmount),
  investedAmountJpy: (f: FundWithStats) => Number(f.stats.investedAmountJpy),
  deploymentRate: (f: FundWithStats) => calcDeploymentRate(f),
  dealInvestedCount: (f: FundWithStats) => f.stats.dealsInvestedCount,
  dealInProgressCount: (f: FundWithStats) => f.stats.dealsInProgressCount,
  portfolioCount: (f: FundWithStats) => f.stats.portfolioCount,
  portfolioActiveCount: (f: FundWithStats) => f.stats.portfolioActiveCount,
  portfolioExCount: (f: FundWithStats) => f.stats.portfolioExCount,
  startDate: (f: FundWithStats) => f.startDate,
  investmentEndDate: (f: FundWithStats) => f.investmentEndDate,
  endDate: (f: FundWithStats) => f.endDate,
  createdAt: (f: FundWithStats) => f.createdAt,
  updatedAt: (f: FundWithStats) => f.updatedAt
}

const { data: options, init: reset } = useData({
  condition: {
    query: null as string | null,
    status: [] as FundStatus[]
  },
  orderBy: {
    field: 'updatedAt' as SortableField,
    direction: 'desc' as 'asc' | 'desc'
  }
})

useUrlQuerySync(options, {
  casts: {
    query: (v) => (v === '' || v === null) ? null : v
  }
})

const { data: funds, loading } = useFundWithStatsListCache()

const fundOps = useFundOps()
const statsOps = useFundStatsOps()

const processedFunds = computed(() => {
  const builder = (fs: FundWithStats[]) => {
    let processed = fs
    return {
      queryFilter() {
        if (!options.value.condition.query) { return this }
        processed = new Fuse(processed, {
          keys: ['nameAbbreviated', 'nameEn', 'nameJa'],
          threshold: 0.3
        }).search(options.value.condition.query).map((r) => r.item)
        return this
      },
      statusFilter() {
        if (options.value.condition.status.length === 0) { return this }
        processed = processed.filter((f) => options.value.condition.status.includes(f.status))
        return this
      },
      sort() {
        processed = orderBy(processed, [sortableFields[options.value.orderBy.field]], [options.value.orderBy.direction])
        return this
      },
      build() {
        return processed
      }
    }
  }

  return builder(funds.value ?? [])
    .queryFilter()
    .statusFilter()
    .sort()
    .build()
})

const table = useTable({
  records: computed(() => processedFunds.value ?? []),
  loading,
  borderless: true,
  orders: [
    'nameAbbreviated',
    'status',
    'size',
    'investableAmount',
    'investedAmountJpy',
    'deploymentRate',
    'dealInvestedCount',
    'dealInProgressCount',
    'portfolioCount',
    'portfolioActiveCount',
    'portfolioExCount',
    'startDate',
    'investmentEndDate',
    'endDate',
    'createdAt',
    'updatedAt',
    'spacer'
  ],
  columns: {
    nameAbbreviated: {
      label: t.l_name_abbreviated,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('nameAbbreviated')
        }
      ]),
      cell: (_, f) => ({
        type: 'text',
        value: f.nameAbbreviated,
        link: fundOps.path(f)
      })
    },
    status: {
      label: t.l_status,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('status')
        },
        {
          type: 'filter',
          search: false,
          selected: computed(() => options.value.condition.status),
          options: [
            { label: fundOps.statusTextByValue(FundStatusEnum.Active), value: 'Active' },
            { label: fundOps.statusTextByValue(FundStatusEnum.ActiveOnlyFollowOn), value: 'ActiveOnlyFollowOn' },
            { label: fundOps.statusTextByValue(FundStatusEnum.Inactive), value: 'Inactive' },
            { label: fundOps.statusTextByValue(FundStatusEnum.Liquidated), value: 'Liquidated' },
            { label: fundOps.statusTextByValue(FundStatusEnum.NotReady), value: 'NotReady' }
          ],
          onClick(value) {
            options.value.condition.status = xor(options.value.condition.status, [value])
          }
        }
      ]),
      cell: (_, f) => ({
        type: 'state',
        mode: fundOps.statusMode(f),
        label: fundOps.statusText(f)
      })
    },
    size: {
      label: t.l_size,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('size')
        }
      ]),
      cell: (_, f) => ({
        type: 'text',
        align: 'right',
        value: fundOps.sizeAbbr(f, 2)
      })
    },
    investableAmount: {
      label: t.l_investable_amount,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('investableAmount')
        }
      ]),
      cell: (_, f) => ({
        type: 'text',
        align: 'right',
        value: fundOps.investableAmountAbbr(f, 2)
      })
    },
    deploymentRate: {
      label: t.l_deployment_rate,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('deploymentRate')
        }
      ]),
      cell: (_, f) => ({
        type: 'text',
        align: 'right',
        value: Number(f.investableAmount) ? `${calcDeploymentRate(f)}%` : null,
        color: (Number(f.investableAmount) && Number(f.stats.investedAmountJpy)) ? 'neutral' : 'mute'
      })
    },
    investedAmountJpy: {
      label: t.l_invested_amount_jpy,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('investedAmountJpy')
        }
      ]),
      cell: (_, f) => ({
        type: 'text',
        align: 'right',
        value: statsOps.investedAmountJpyAbbr(f.stats, 2),
        color: Number(f.stats.investedAmountJpy) > 0 ? 'neutral' : 'mute'
      })
    },
    dealInvestedCount: {
      label: t.l_deal_invested_count,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('dealInvestedCount')
        }
      ]),
      cell: (_, f) => ({
        type: 'number',
        align: 'right',
        value: f.stats.dealsInvestedCount,
        color: f.stats.dealsInvestedCount > 0 ? 'neutral' : 'mute',
        link: statsOps.investedDealsPageLink(f.stats)
      })
    },
    dealInProgressCount: {
      label: t.l_deal_in_progress_count,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('dealInProgressCount')
        }
      ]),
      cell: (_, f) => ({
        type: 'number',
        align: 'right',
        value: f.stats.dealsInProgressCount,
        color: f.stats.dealsInProgressCount > 0 ? 'neutral' : 'mute',
        link: statsOps.inProgressDealsPageLink(f.stats)
      })
    },
    portfolioCount: {
      label: t.l_portfolio_count,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('portfolioCount')
        }
      ]),
      cell: (_, f) => ({
        type: 'number',
        align: 'right',
        value: f.stats.portfolioCount,
        color: f.stats.portfolioCount > 0 ? 'neutral' : 'mute',
        link: statsOps.portfolioPageLink(f.stats)
      })
    },
    portfolioActiveCount: {
      label: t.l_portfolio_active_count,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('portfolioActiveCount')
        }
      ]),
      cell: (_, f) => ({
        type: 'number',
        align: 'right',
        value: f.stats.portfolioActiveCount,
        color: f.stats.portfolioActiveCount > 0 ? 'neutral' : 'mute',
        link: statsOps.activePortfolioPageLink(f.stats)
      })
    },
    portfolioExCount: {
      label: t.l_portfolio_ex_count,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('portfolioExCount')
        }
      ]),
      cell: (_, f) => ({
        type: 'number',
        align: 'right',
        value: f.stats.portfolioExCount,
        color: f.stats.portfolioExCount > 0 ? 'neutral' : 'mute',
        link: statsOps.exPortfolioPageLink(f.stats)
      })
    },
    startDate: {
      label: t.l_start_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('startDate')
        }
      ]),
      cell: (_, f) => ({
        type: 'day',
        value: fundOps.startDate(f),
        format: 'YYYY-MM-DD'
      })
    },
    investmentEndDate: {
      label: t.l_investment_end_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('investmentEndDate')
        }
      ]),
      cell: (_, f) => ({
        type: 'day',
        value: fundOps.investmentEndDate(f),
        format: 'YYYY-MM-DD'
      })
    },
    endDate: {
      label: t.l_end_date,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('endDate')
        }
      ]),
      cell: (_, f) => ({
        type: 'day',
        value: fundOps.endDate(f),
        format: 'YYYY-MM-DD'
      })
    },
    createdAt: {
      label: t.l_created_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('createdAt')
        }
      ]),
      cell: (_, f) => ({
        type: 'day',
        value: fundOps.createdAt(f),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    updatedAt: {
      label: t.l_updated_at,
      dropdown: createDropdown([
        {
          type: 'menu',
          options: createSortOptions('updatedAt')
        }
      ]),
      cell: (_, f) => ({
        type: 'day',
        value: fundOps.updatedAt(f),
        format: 'YYYY-MM-DD HH:mm'
      })
    },
    spacer: {
      resizable: false,
      cell: { type: 'empty' }
    }
  }
})

function updateQuery(value: string | null) {
  options.value.condition.query = value === '' ? null : value
}

function createSortOptions(field: SortableField) {
  return [
    { label: t.f_sort_asc, onClick: () => { updateOrder(field, 'asc') } },
    { label: t.f_sort_desc, onClick: () => { updateOrder(field, 'desc') } }
  ]
}

function updateOrder(field: SortableField, direction: 'asc' | 'desc') {
  options.value.orderBy.field = field
  options.value.orderBy.direction = direction
}

function calcDeploymentRate(f: FundWithStats) {
  return (Number(f.stats.investedAmountJpy) / Number(f.investableAmount) * 100).toFixed()
}
</script>

<template>
  <div v-if="funds" class="FundPageIndex">
    <SCard v-if="funds.length === 0">
      <SCardBlock class="empty">
        <div class="empty-text">
          <STrans lang="en">You are not authorized to view any fund details. If you believe you should have access, contact the system administrator.</STrans>
          <STrans lang="ja">あなたが閲覧できる権限を持ったファンドが見つかりませんでした。閲覧できるはずのものが見えていない場合、システム管理者へ問い合わせてください。</STrans>
        </div>
      </SCardBlock>
    </SCard>
    <SCard v-else>
      <SCardBlock size="medium" class="s-px-12">
        <SControl>
          <SControlLeft>
            <div class="s-w-320">
              <DInputSearch
                size="mini"
                :placeholder="t.c_search_ph"
                :model-value="options.condition.query"
                @enter="updateQuery"
              />
            </div>
            <SControlButton
              type="outline"
              mode="mute"
              :label="t.c_reset_filters"
              @click="reset"
            />
          </SControlLeft>
        </SControl>
      </SCardBlock>
      <SCardBlock>
        <STable class="list" :options="table" />
      </SCardBlock>
    </SCard>
  </div>
</template>

<style scoped lang="postcss">
.FundPageIndex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 32px 32px 128px;
  background-color: var(--c-bg-elv-2);
}

.list {
  --table-head-position: sticky;
  --table-head-top: var(--header-height);
}

.list :deep(.col-nameAbbreviated) {
  --table-col-position: sticky;
  --table-col-z-index: 50;
}

.list :deep(.col-nameAbbreviated)      { --table-col-width: 160px; }
.list :deep(.col-status)               { --table-col-width: 160px; }
.list :deep(.col-size)                 { --table-col-width: 160px; font-feature-settings: "tnum"; }
.list :deep(.col-investableAmount)     { --table-col-width: 160px; font-feature-settings: "tnum"; }
.list :deep(.col-investedAmountJpy)    { --table-col-width: 160px; font-feature-settings: "tnum"; }
.list :deep(.col-deploymentRate)       { --table-col-width: 160px; font-feature-settings: "tnum"; }
.list :deep(.col-dealInvestedCount)    { --table-col-width: 160px; }
.list :deep(.col-dealInProgressCount)  { --table-col-width: 160px; }
.list :deep(.col-portfolioCount)       { --table-col-width: 160px; }
.list :deep(.col-portfolioActiveCount) { --table-col-width: 160px; }
.list :deep(.col-portfolioExCount)     { --table-col-width: 160px; }
.list :deep(.col-startDate)            { --table-col-width: 160px; }
.list :deep(.col-investmentEndDate)    { --table-col-width: 160px; }
.list :deep(.col-endDate)              { --table-col-width: 160px; }
.list :deep(.col-createdAt)            { --table-col-width: 192px; }
.list :deep(.col-updatedAt)            { --table-col-width: 192px; }
.list :deep(.col-spacer)               { --table-col-width: 40px; }

.empty {
  padding: 48px;
}

.empty-text {
  margin: 0 auto;
  max-width: 640px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
