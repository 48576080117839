import { OpportunityStatus } from '@/graphql'
import { Company } from './Company'
import { type Deal } from './Deal'
import { type Day, Model, day } from './Model'
import { OpportunitySource } from './OpportunitySource'
import { Proposal } from './Proposal'
import { Round } from './Round'
import { User } from './User'
import { type AccessControlled, AccessResource } from './contracts/AccessControl'

export { OpportunityStatus }

export const OpportunityStatuses = {
  [OpportunityStatus.Open]: 'Open',
  [OpportunityStatus.InProgress]: 'In progress',
  [OpportunityStatus.Closed]: 'Closed'
} as const

export class Opportunity extends Model implements AccessControlled {
  subject = AccessResource.opportunity
  permittedActions: AccessControlled['permittedActions']

  id?: number
  status?: OpportunityStatus
  reviewStatus?: string
  financingPlan?: string
  serviceDescription?: string
  trackRecord?: string | null
  businessPlans?: string | null
  competitiveAdvantages?: string | null
  businessModel?: string | null
  pastFinancings?: string | null
  hasNoPlanToAddFiles?: boolean
  fileComment?: string | null
  expiresAt: Day | null

  createdById?: number
  createdBy: User | null
  createdAt: Day | null
  updatedById?: number
  updatedBy: User | null
  updatedAt: Day | null

  company: Company | null
  sources: OpportunitySource[]
  round: Round | null
  proposals: Proposal[]

  constructor(data: Record<string, any>) {
    super()
    this.permittedActions = data.permittedActions ?? []

    this.id = data.id
    this.status = data.status
    this.reviewStatus = data.reviewStatus
    this.financingPlan = data.financingPlan
    this.serviceDescription = data.serviceDescription
    this.trackRecord = data.trackRecord
    this.businessPlans = data.businessPlans
    this.competitiveAdvantages = data.competitiveAdvantages
    this.businessModel = data.businessModel
    this.pastFinancings = data.pastFinancings
    this.hasNoPlanToAddFiles = data.hasNoPlanToAddFiles
    this.fileComment = data.fileComment
    this.expiresAt = this.day(data.expiresAt)

    this.createdById = data.createdById
    this.createdBy = this.hasOne(User, data.createdBy)
    this.createdAt = this.day(data.createdAt)
    this.updatedById = data.updatedById
    this.updatedBy = this.hasOne(User, data.updatedBy)
    this.updatedAt = this.day(data.updatedAt)

    this.company = this.hasOne(Company, data.company)
    this.sources = this.hasMany(OpportunitySource, data.sources)
    this.round = this.hasOne(Round, data.round)
    this.proposals = this.hasMany(Proposal, data.proposals)
  }

  static statusEntries() {
    return Object.entries(OpportunityStatuses)
  }

  get path(): string {
    return `/opportunities/${this.id}`
  }

  get createDealPath(): string {
    return `${this.path}/deals/create`
  }

  get title(): string {
    const roundName = this.getRoundName()
    const createdAt = this.createdAtAsDay.format('YYYY-MM-DD')

    return `${roundName} ${createdAt}`
  }

  get titleWithCompany(): string {
    return `${this.company?.name} / ${this.title}`
  }

  get statusValue(): string {
    return OpportunityStatuses[this.status!]
  }

  get createdAtAsDay(): Day {
    return day(this.createdAt)
  }

  get updatedAtAsDay(): Day {
    return day(this.updatedAt)
  }

  isOpen(): boolean {
    return this.status === OpportunityStatus.Open
  }

  isInProgress(): boolean {
    return this.status === OpportunityStatus.InProgress
  }

  isClosed(): boolean {
    return this.status === OpportunityStatus.Closed
  }

  protected getRoundName(): string {
    return this.round ? this.round.fullName : 'No Round'
  }

  dealsThroughRound(): Deal[] {
    return this.round?.deals ?? []
  }
}
