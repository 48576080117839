<script setup lang="ts">
import SInputDropdown, { type Option } from 'sefirot/components/SInputDropdown.vue'
import { useData } from 'sefirot/composables/Data'
import { useValidation } from 'sefirot/composables/Validation'
import { required } from 'sefirot/validation/rules'
import { computed } from 'vue'
import { type View } from '@/models/View'
import { useUserActiveCollection } from '@/composables/user/UserData'
import { useViewShare } from '@/composables/view/ViewData'

interface Data {
  userIds: number[]
}

const props = defineProps<{
  view?: View | null
}>()

const emit = defineEmits<{
  (e: 'added'): void
  (e: 'cancel'): void
}>()

const { data: users } = useUserActiveCollection()

const userOptions = computed<Option[]>(() => users.value
  ?.filter((user) => {
    return !(props.view?.sharedUsers ?? []).map(({ id }) => id).includes(user.id)
  })
  .map((user) => {
    return { type: 'avatar', label: user.name!, value: user.id! }
  }) ?? [])

const { data } = useData<Data>({
  userIds: []
})

const { validation, validateAndNotify } = useValidation<Data>(data, {
  userIds: {
    required: required()
  }
})

const { loading, execute } = useViewShare(props.view!.id!, () => ({
  userIds: data.value.userIds
}))

async function add() {
  if (await validateAndNotify()) {
    await execute()
    emit('added')
  }
}
</script>

<template>
  <DForm>
    <DFormTitle>Add shared users</DFormTitle>

    <DFormGrid>
      <DFormGridItem span="6">
        <SInputDropdown
          label="Users"
          placeholder="Select users to share this view"
          :options="userOptions"
          v-model="data.userIds"
          :validation="validation.userIds"
        />
      </DFormGridItem>
    </DFormGrid>

    <DFormFooter>
      <DFormFooterActions>
        <DFormFooterAction label="Cancel" @click="$emit('cancel')" />
        <DFormFooterAction mode="info" label="Add" :loading="loading" @click="add" />
      </DFormFooterActions>
    </DFormFooter>
  </DForm>
</template>
