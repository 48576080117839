<script setup lang="ts">
import SInputSelect from 'sefirot/components/SInputSelect.vue'
import { computed } from 'vue'
import {
  type AddOpportunityProposalsInputItem,
  type FundGroupAndAllFrag,
  type OpportunityForDetailsFrag
} from '@/graphql'
import { useOpportunitySourceOps } from '@/composables/ops/OpportunityOps'
import DFields from '../DFields.vue'

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
  fundGroup: FundGroupAndAllFrag
}>()

const model = defineModel<AddOpportunityProposalsInputItem>({ required: true })

const sourceOps = useOpportunitySourceOps()

const sourceOptions = computed(() => {
  return props.opportunity.sources.map((source) => ({
    label: `${sourceOps.date(source).format('YYYY-MM-DD')} – ${source.promotedSource.name}`,
    value: source.id
  }))
})
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-px-24 s-bg-elv-4">
      <div class="title">
        <div class="fund">{{ fundGroup.name }}</div>
        <div class="lp">{{ fundGroup.lp }}</div>
      </div>
    </SCardBlock>
    <SCardBlock class="s-p-24">
      <SDoc>
        <SInputSelect
          label="Sourcing information"
          :options="sourceOptions"
          v-model="model.sourceId"
        />
        <DFields
          :fields="(fundGroup.proposalCustomFields as any)"
          v-model="model.data"
        />
      </SDoc>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.title {
  display: flex;
  gap: 24px;
}

.fund {
  font-size: 14px;
  font-weight: 500;
}

.lp {
  font-size: 14px;
  color: var(--c-text-2);
}
</style>
