<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  completing: boolean
}>()

defineEmits<{
  complete: []
  close: []
}>()

const { t } = useTrans({
  en: {
    complete: 'Complete deal',
    cancel: 'Cancel'
  },
  ja: {
    complete: 'ディールをコンプリート',
    cancel: 'キャンセル'
  }
})
</script>

<template>
  <SCard size="small">
    <SCardBlock class="s-p-24">
      <SContent>
        <STrans lang="en">
          <h2>Complete this deal</h2>
          <p>After completing the deal, all deal data and the round data will be locked and may not be further updated. Make sure that all data is up to date before completing the deal.</p>
        </STrans>
        <STrans lang="ja">
          <h2>ディールをコンプリートする</h2>
          <p>ディールをコンプリートすると、すべてのディール、およびラウンドのデータはロックされ、これ以上更新することはできなくなります。ディールをコンプリートする前にすべてのデータが最新であることを確認してください。</p>
        </STrans>
      </SContent>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-24">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :loading="completing"
            @click="$emit('close')"
          />
          <SControlButton
            mode="success"
            :label="t.complete"
            :loading="completing"
            @click="$emit('complete')"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
