<script setup lang="ts">
import { useMarkdown } from 'sefirot/composables/Markdown'
import { computed } from 'vue'

const props = defineProps<{
  content?: string
  inline?: boolean
  tag?: string
}>()

const md = useMarkdown({
  config: (md) => {
    md.configure('zero')
      .enable(['link', 'linkify'])
      .set({ linkify: true })
  }
})

const rendered = computed(() => props.content ? md(props.content, props.inline ?? true) : '')
</script>

<template>
  <component :is="tag ?? 'div'" v-html="rendered" />
</template>
