<script setup lang="ts">
import IconNotePencil from '~icons/ph/note-pencil-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import { computed } from 'vue'
import { type Company } from '@/models/Company'
import { useCanUpdateLegalAssignees } from '@/composables/policies/CompanyPolicy'
import { useLatestCompanyParticipationList } from '@/composables/repos/CompanyRepo'
import CompanyFormUpdateLegalAssignees from './CompanyFormUpdateLegalAssignees.vue'

const props = defineProps<{
  company: Company
}>()

const { t } = useTrans({
  en: {
    title: 'Legal assignees',
    label: 'Assignees'
  },
  ja: {
    title: '法務担当',
    label: '担当者'
  }
})

const { data: participations, execute: refresh } = useLatestCompanyParticipationList(props.company.id!)

const canUpdateLegalAssignee = useCanUpdateLegalAssignees(() => props.company as any)

const updateDialog = usePower(false)

const assignees = computed(() => {
  return participations.value?.filter((p) => {
    return p.authority === 'LegalAssignee'
  }).map((p) => p.user) ?? []
})
</script>

<template>
  <SCard>
    <SCardBlock size="small" class="s-pl-24 s-pr-8">
      <SControl>
        <SControlLeft>
          <div class="s-font-14 s-font-w-500">
            {{ t.title }}
          </div>
        </SControlLeft>
        <SControlRight>
          <SControlActionBar>
            <SControlActionBarButton
              v-if="canUpdateLegalAssignee.ok"
              :icon="IconNotePencil"
              @click="updateDialog.on"
            />
            <SControlActionBarCollapse />
          </SControlActionBar>
        </SControlRight>
      </SControl>
    </SCardBlock>
    <SCardBlock>
      <DCardInfo>
        <DCardInfoItem>
          <DCardInfoLabel :text="t.label" />
          <DCardInfoUsers :users="assignees" />
        </DCardInfoItem>
      </DCardInfo>
    </SCardBlock>

    <SModal :open="updateDialog.state.value" @close="updateDialog.off">
      <CompanyFormUpdateLegalAssignees
        :company-id="company.id!"
        :assignees="assignees"
        @cancel="updateDialog.off"
        @updated="updateDialog.off(refresh)"
      />
    </SModal>
  </SCard>
</template>
