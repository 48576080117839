<script setup lang="ts">
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import {
  type SyncCompanyPortfolioDataEmergencyContactsInputItem
} from '@/graphql'
import { type CompanyPortfolioData } from '@/models/CompanyPortfolioData'
import {
  useSyncCompanyPortfolioDataEmergencyContacts
} from '@/composables/repos/CompanyPortfolioDataRepo'
import CompanyPortfolioDataFormUpdateEmergencyContactsInput, {
  type Contact
} from './CompanyPortfolioDataFormUpdateEmergencyContactsInput.vue'

const props = defineProps<{
  portfolioData: CompanyPortfolioData
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    cancel: 'Cancel',
    update: 'Update contacts'
  },
  ja: {
    cancel: 'キャンセル',
    update: 'Contactsを更新する'
  }
})

const {
  execute: syncContacts,
  loading: syncingCntacts
} = useSyncCompanyPortfolioDataEmergencyContacts()

const { data } = useData({
  contacts: createContactsData()
})

const { validateAndNotify } = useValidation()

async function save() {
  if (await validateAndNotify()) {
    await syncContacts(props.portfolioData.companyId!, {
      contacts: createContactsInput()
    })
    emit('updated')
  }
}

function createContactsData() {
  return [1, 2, 3].map((_, i) => {
    const c = props.portfolioData.emergencyContacts[i]
    return {
      name: c?.name ?? null,
      title: c?.title ?? null,
      email: c?.email ?? null
    } as Contact
  })
}

function createContactsInput() {
  return data.value.contacts.reduce<
    SyncCompanyPortfolioDataEmergencyContactsInputItem[]
  >((input, c) => {
    if (c.name && c.title && c.email) {
      input.push({
        name: c.name,
        title: c.title,
        email: c.email
      })
    }
    return input
  }, [])
}
</script>

<template>
  <SCard size="large">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Update emergency contacts</h2>
            <p>You may add up to 3 contacts, which will be used to send critical notices to startups during emergencies. Please choose the addresses carefully.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Emergency contactsの更新</h2>
            <p>最大3つの連絡先を追加できます。この連絡先は非常時にGBからスタートアップへ一斉連絡するために使用されますので、追加する宛先に注意してください。</p>
          </STrans>
        </SContent>
        <SGrid gap="8">
          <SGrid cols="3" gap="8">
            <SGridItem>
              <div class="label">Name</div>
            </SGridItem>
            <SGridItem>
              <div class="label">Job title</div>
            </SGridItem>
            <SGridItem>
              <div class="label">Email</div>
            </SGridItem>
          </SGrid>
          <CompanyPortfolioDataFormUpdateEmergencyContactsInput
            v-for="contact, index in data.contacts"
            :key="index"
            :contact="contact"
            @update="data.contacts[index] = $event"
          />
        </SGrid>
      </SDoc>
    </SCardBlock>
    <SCardBlock size="xlarge" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            @click="emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.update"
            :loading="syncingCntacts"
            @click="save"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>

<style scoped lang="postcss">
.head {
  max-width: 512px;
}

.label {
  padding-left: 4px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
