<script setup lang="ts">
import SInputDropdown from 'sefirot/components/SInputDropdown.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { type Validatable } from 'sefirot/composables/Validation'
import { computed } from 'vue'
import { useUserOps } from '@/composables/ops/UserOps'
import { useUserListCache } from '@/composables/repos/UserRepo'

defineProps<{
  label?: string
  placeholder?: string
  help?: string
  nullable?: boolean
  validation?: Validatable
}>()

const model = defineModel<number[] | number | null>()

const { t } = useTrans({
  en: {
    label: 'Member',
    ph: Array.isArray(model.value) ? 'Select members' : 'Select a member'
  },
  ja: {
    label: 'メンバー',
    ph: 'メンバーを選択してください'
  }
})

const { data: users } = useUserListCache()

const userOps = useUserOps()

const options = computed(() => userOps.createDropdownOptions(users.value))
</script>

<template>
  <SInputDropdown
    class="DInputDropdownUsers"
    :label="label ?? t.label"
    :placeholder="placeholder ?? t.ph"
    :help
    :options
    :nullable
    v-model="model"
  />
</template>
