import { Model } from './Model'

export class Currency extends Model {
  id?: number | null
  symbol?: string
  name?: string
  rank?: number
  enabled?: boolean

  constructor(data: Record<string, any>) {
    super()
    this.id = data.id
    this.symbol = data.symbol
    this.name = data.name
    this.rank = data.rank
    this.enabled = data.enabled
  }

  static createJpy(): Currency {
    return new this({
      symbol: '¥',
      name: 'JPY',
      enabled: true
    })
  }
}
