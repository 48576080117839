<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength } from 'sefirot/validation/rules'
import { type OpportunityFileRequest } from '@/graph/Opportunity'
import { useCreateOpportunityFileRequest } from '@/composables/repos/OpportunityRepo'

const props = defineProps<{
  opportunityId: number
}>()

const emit = defineEmits<{
  cancel: []
  submit: [fileRequest: OpportunityFileRequest]
}>()

const { t } = useTrans({
  en: {
    comment_label: 'Comment',
    comment_ph: 'Please enter your comment here.',
    cancel: 'Cancel',
    request: 'Submit request'
  },
  ja: {
    comment_label: 'コメント',
    comment_ph: 'コメントを入力してください。',
    cancel: 'キャンセル',
    request: 'リクエストを送信'
  }
})

const { data } = useData({
  comment: null
})

const { validation, validateAndNotify } = useValidation(data, {
  comment: {
    maxLength: maxLength(4000)
  }
})

const mutation = useCreateOpportunityFileRequest()

async function request() {
  if (await validateAndNotify()) {
    const fileRequest = await mutation.execute(props.opportunityId, {
      comment: data.value.comment
    })
    emit('submit', fileRequest)
  }
}
</script>

<template>
  <SCard class="OpportunityFileFormRequest" size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <STrans lang="en">
            <h2>Request pitch decks</h2>
            <p>Request company assignees for Pitch Decks. If you have any messages for them, you can include a comment, though it's optional.</p>
            <p>Once you submit this form, the person in charge will be notified. You will receive a Slack notification when the Pitch Deck is added.</p>
          </STrans>
          <STrans lang="ja">
            <h2>Pitch Deckを請求する</h2>
            <p>案件担当者にPitch Deckを追加するよう請求します。担当者へ伝えたいことがある場合は、コメントを添えることもできます。コメントは空欄でも構いません。</p>
            <p>このフォームを送信すると、担当者へ通知が送られ、担当者がPitch Deckを追加すると、あなたへSlack通知が届きます。</p>
          </STrans>
        </SContent>
        <SInputTextarea
          :label="t.comment_label"
          :placeholder="t.comment_ph"
          :rows="5"
          v-model="data.comment"
          :validation="validation.comment"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock class="s-px-32" size="xlarge">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.cancel"
            :disabled="mutation.loading.value"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.request"
            :loading="mutation.loading.value"
            @click="request"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
