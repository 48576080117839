<script setup lang="ts">
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'

const props = defineProps<{
  scope?: string
}>()

const model = defineModel<string | null>({ required: true })

const { t } = useTrans({
  en: {
    label: 'Financing plan *',
    info: 'The current and future financing plan of the startup.',
    ph: 'Planning to raise a Series B round in 20XX. Will need to raise a Series C round in 20XX. Runway until Q3 20XX. etc.'
  },
  ja: {
    label: 'Financing plan *',
    info: '今とこれからの資金調達計画。',
    ph: '20XX年にシードラウンドを調達したい。20XX年にシリーズAラウンドを調達する予定。ランウェイは20XXQ3まで。など。'
  }
})

const { validation } = useValidation({
  model
}, {
  model: {
    required: required(),
    maxLength: maxLength(500)
  }
}, {
  $scope: props.scope
})
</script>

<template>
  <SInputTextarea
    :label="t.label"
    :info="t.info"
    :placeholder="t.ph"
    :rows="5"
    v-model="model"
    :validation="validation.model"
  />
</template>
