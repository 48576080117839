<script setup lang="ts">
import IconPlus from '~icons/ph/plus-bold'
import { useTrans } from 'sefirot/composables/Lang'
import { usePower } from 'sefirot/composables/Power'
import CompanyFormCreateFinancialResult from './CompanyFormCreateFinancialResult.vue'

const props = defineProps<{
  companyId: number
}>()

const emit = defineEmits<{
  created: []
}>()

const { t } = useTrans({
  en: {
    add: 'Add Result'
  },
  ja: {
    add: '財務数値を追加'
  }
})

const { state, on, off } = usePower()
</script>

<template>
  <div class="CompanyFinancialResultAdd">
    <button class="button" @click="on()">
      <IconPlus />
      <span class="label">{{ t.add }}</span>
    </button>

    <SModal :open="state">
      <CompanyFormCreateFinancialResult
        :company-id="props.companyId"
        @created="off(() => emit('created'))"
        @cancel="off"
      />
    </SModal>
  </div>
</template>

<style scoped lang="postcss">
.CompanyFinancialResultAdd {
  display: flex;
  flex: none;
  width: 184px;
  padding: 8px;
}

.button {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--c-text-2);
  border: 1px dashed var(--c-divider-1);
  border-radius: 4px;
  transition: border-color .25s, color .25s;

  &:hover {
    border-color: var(--c-info-text);
    color: var(--c-info-text);
  }
}

.label {
  margin-left: 4px;
}
</style>
