import {
  type Query,
  type Mutation as SMutation,
  type UseQueryOptions,
  useQuery,
  useMutation as useSMutation
} from 'sefirot/composables/Api'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { type BalanceConfirmationEmail, type BalanceConfirmationMail } from '@/graph/BalanceConfirmation'
import { type CompanyPortfolioData, type CompanyPortfolioDataExitStatus } from '@/graph/CompanyPortfolioData'
import {
  type CompanyFinancialResultInput,
  type CompanyPortfolioDataEmergencyContactFrag,
  type CompanyPortfolioDataWithFinancialFiguresForCopyFrag,
  type CompanyPortfolioDataWithFinancialFiguresFrag,
  type SyncCompanyPortfolioDataEmergencyContactsInput,
  type UpdateCompanyFinancialFiguresInput
} from '@/graphql'
import { CompanyRequest } from '@/requests/CompanyRequest'
import { type Mutation, useMutation } from '../Api'

export type CompanyPortfolioDataItem = Query<CompanyPortfolioDataWithFinancialFiguresFrag | null>

export type CompanyPortfolioDataForCompanyBusinessReportCopyItem = Query<CompanyPortfolioDataWithFinancialFiguresForCopyFrag | null>

export type CompanyPortfolioDataBalanceConfirmationEmailItem = Query<BalanceConfirmationEmail | null>
export type UpdateCompanyPortfolioDataBalanceConfirmationEmail = SMutation<BalanceConfirmationEmail, [companyId: number, input: UpdateCompanyPortfolioDataBalanceConfirmationEmailInput]>
export type DeleteCompanyPortfolioDataBalanceConfirmationEmail = SMutation<void, [companyId: number]>

export type CompanyPortfolioDataBalanceConfirmationMailItem = Query<BalanceConfirmationMail | null>
export type UpdateCompanyPortfolioDataBalanceConfirmationMail = SMutation<BalanceConfirmationMail, [companyId: number, input: UpdateCompanyPortfolioDataBalanceConfirmationMailInput]>
export type DeleteCompanyPortfolioDataBalanceConfirmationMail = SMutation<void, [companyId: number]>

export type UpdateCompanyPortfolioDataExitStatus = SMutation<CompanyPortfolioData, [companyId: number, input: UpdateCompanyPortfolioDataExitStatusInput]>

export type UpdateCompanyPortfolioDataFinancialFigures = SMutation<
  void,
  [companyId: number, input: UpdateCompanyFinancialFiguresInput]
>

export type CreateCompanyFinancialResult = SMutation<void, [companyId: number, input: CompanyFinancialResultInput]>

export type UpdateCompanyFinancialResult = SMutation<void, [resultId: number, input: CompanyFinancialResultInput]>

export type DeleteCompanyFinancialResult = SMutation<void, [resultId: number]>

export type SyncCompanyPortfolioDataEmergencyContacts = Mutation<
  CompanyPortfolioDataEmergencyContactFrag[],
  [companyId: number, input: SyncCompanyPortfolioDataEmergencyContactsInput]
>

export interface UpdateCompanyPortfolioDataBalanceConfirmationEmailInput {
  notes: string | null
  destinations: UpdateCompanyPortfolioDataBalanceConfirmationEmailInputDestination[]
}

export interface UpdateCompanyPortfolioDataBalanceConfirmationEmailInputDestination {
  name: string
  title: string
  email: string
  recipientType: 'To' | 'Cc' | 'Bcc'
  isAdmin: boolean
}

export interface UpdateCompanyPortfolioDataBalanceConfirmationMailInput {
  zipCode: string
  address: string
  addressHouseNumber: string | null
  notes: string | null
  destinations: UpdateCompanyPortfolioDataBalanceConfirmationMailInputDestination[]
}

export interface UpdateCompanyPortfolioDataBalanceConfirmationMailInputDestination {
  name: string
  title: string
}

export interface UpdateCompanyPortfolioDataExitStatusInput {
  exitStatus: CompanyPortfolioDataExitStatus | null
  exitDate: string | null
  exitDetails: string | null
  ipoLeadUnderwriter: string | null
  ipoListingMarket: string | null
  isActivePortfolio: boolean
}

export function useCompanyPortfolioDataItemForCompanyBusinessReportCopy(
  id: MaybeRefOrGetter<number>,
  options?: UseQueryOptions
): CompanyPortfolioDataForCompanyBusinessReportCopyItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchPortfolioDataForCompanyBusinessReportCopy(toValue(id))
    return res.data.portfolioData
  }, {
    watch: () => toValue(id),
    ...options
  })
}

export function useCompanyPortfolioDataItem(id: MaybeRefOrGetter<number>, options?: UseQueryOptions): CompanyPortfolioDataItem {
  return useQuery(async () => {
    const res = await new CompanyRequest().fetchPortfolioDataFinancialFigures(toValue(id))
    return res.data.portfolioData
  }, options)
}

export function useCompanyPortfolioDataBalanceConfirmationEmailItem(
  id: MaybeRefOrGetter<number>
): CompanyPortfolioDataBalanceConfirmationEmailItem {
  return useQuery(async (http) => {
    return http.get(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-email`)
  })
}

export function useUpdateCompanyPortfolioDataBalanceConfirmationEmail(): UpdateCompanyPortfolioDataBalanceConfirmationEmail {
  return useSMutation(async (http, id, input) => {
    return http.put(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-email`, input)
  })
}

export function useDeleteCompanyPortfolioDataBalanceConfirmationEmail(): DeleteCompanyPortfolioDataBalanceConfirmationEmail {
  return useSMutation(async (http, id) => {
    await http.delete(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-email`)
  })
}

export function useCompanyPortfolioDataBalanceConfirmationMailItem(
  id: MaybeRefOrGetter<number>
): CompanyPortfolioDataBalanceConfirmationMailItem {
  return useQuery(async (http) => {
    return http.get(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-mail`)
  })
}

export function useUpdateCompanyPortfolioDataBalanceConfirmationMail(): UpdateCompanyPortfolioDataBalanceConfirmationMail {
  return useSMutation(async (http, id, input) => {
    return http.put(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-mail`, input)
  })
}

export function useDeleteCompanyPortfolioDataBalanceConfirmationMail(): DeleteCompanyPortfolioDataBalanceConfirmationMail {
  return useSMutation(async (http, id) => {
    await http.delete(`/api/companies/${toValue(id)}/portfolio-data/balance-confirmation-mail`)
  })
}

export function useUpdateCompanyPortfolioDataExitStatus(): UpdateCompanyPortfolioDataExitStatus {
  return useSMutation(async (http, id, input) => {
    return http.put<CompanyPortfolioData>(`/api/companies/${id}/portfolio-data/exit-status`, input)
  })
}

export function useUpdateCompanyFinancialFigures(): UpdateCompanyPortfolioDataFinancialFigures {
  return useSMutation(async (_, companyId, input) => {
    await new CompanyRequest().updateCompanyFinancialFigures(companyId, input)
  })
}

export function useCreateCompanyFinancialResult(): CreateCompanyFinancialResult {
  return useSMutation(async (_, companyId, input) => {
    await new CompanyRequest().createCompanyFinancialResult(companyId, input)
  })
}

export function useUpdateCompanyFinancialResult(): UpdateCompanyFinancialResult {
  return useSMutation(async (_, resultId, input) => {
    await new CompanyRequest().updateCompanyFinancialResult(resultId, input)
  })
}

export function useDeleteCompanyFinancialResult(): DeleteCompanyFinancialResult {
  return useSMutation(async (_, resultId) => {
    await new CompanyRequest().deleteCompanyFinancialResult(resultId)
  })
}

export function useSyncCompanyPortfolioDataEmergencyContacts(): SyncCompanyPortfolioDataEmergencyContacts {
  return useMutation<
    CompanyPortfolioDataEmergencyContactFrag[],
    [companyId: number, input: SyncCompanyPortfolioDataEmergencyContactsInput]
  >(async (id, input) => {
    const res = await new CompanyRequest().syncPortfolioDataEmergencyContacts(id, input)
    return res.data.emergencyContacts
  })
}
