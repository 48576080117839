<script setup lang="ts">
import SInputRadios from 'sefirot/components/SInputRadios.vue'
import SInputTextarea from 'sefirot/components/SInputTextarea.vue'
import { useData } from 'sefirot/composables/Data'
import { useTrans } from 'sefirot/composables/Lang'
import { useValidation } from 'sefirot/composables/Validation'
import { maxLength, required } from 'sefirot/validation/rules'
import { type OpportunityForDetailsFrag, OpportunityInitialEvaluation } from '@/graphql'
import { useOpportunityUpdateStatus } from '@/composables/opportunity/OpportunityData'
import { useOpportunityOps } from '@/composables/ops/OpportunityOps'
import { useCanUpdateInitialEvaluation } from '@/composables/policies/OpportunityPolicy'

export interface Data {
  initialEvaluation: OpportunityInitialEvaluation | null
  reviewStatus: string | null
}

const props = defineProps<{
  opportunity: OpportunityForDetailsFrag
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated'): void
}>()

const { t } = useTrans({
  en: {
    i_initial_evaluation_label: 'Initial evaluation by PIC',
    i_review_status_label: 'Review status *',
    i_review_status_ph: 'Describe current status of the opportunity.',
    a_cancel: 'Cancel',
    a_submit: 'Update status'
  },
  ja: {
    i_initial_evaluation_label: '担当者初期見解',
    i_review_status_label: '検討状況 *',
    i_review_status_ph: '検討状況を記入してください',
    a_cancel: 'キャンセル',
    a_submit: 'ステータスを更新'
  }
})

const canUpdateInitialEvaluation = useCanUpdateInitialEvaluation(() => props.opportunity)

const oppoOps = useOpportunityOps()

const { data } = useData({
  initialEvaluation: props.opportunity.initialEvaluation === OpportunityInitialEvaluation.Na ? null : props.opportunity.initialEvaluation,
  reviewStatus: props.opportunity.reviewStatus
})

const { validation, validateAndNotify } = useValidation(data, {
  initialEvaluation: { required: required() },
  reviewStatus: { required: required(), maxLength: maxLength(500) }
})

const { execute, loading } = useOpportunityUpdateStatus(props.opportunity.id, () => ({
  initialEvaluation: data.value.initialEvaluation!,
  reviewStatus: data.value.reviewStatus!
}))

const initialEvaluationOptions = [
  {
    label: oppoOps.initialEvaluationTextByValue(OpportunityInitialEvaluation.Proceed),
    value: OpportunityInitialEvaluation.Proceed
  },
  {
    label: oppoOps.initialEvaluationTextByValue(OpportunityInitialEvaluation.NotToProceed),
    value: OpportunityInitialEvaluation.NotToProceed
  }
]

async function update() {
  if (await validateAndNotify()) {
    await execute()
    emit('updated')
  }
}
</script>

<template>
  <SCard size="medium">
    <SCardBlock class="s-p-32">
      <SDoc>
        <SContent>
          <h2>Update review status</h2>
        </SContent>
        <SInputRadios
          v-if="canUpdateInitialEvaluation.ok"
          :label="t.i_initial_evaluation_label"
          :options="initialEvaluationOptions"
          v-model="data.initialEvaluation"
          :validation="validation.initialEvaluation"
        />
        <SInputTextarea
          :label="t.i_review_status_label"
          :placeholder="t.i_review_status_ph"
          :rows="5"
          v-model="data.reviewStatus"
          :validation="validation.reviewStatus"
        />
      </SDoc>
    </SCardBlock>
    <SCardBlock size="large" class="s-px-32">
      <SControl>
        <SControlRight>
          <SControlButton
            :label="t.a_cancel"
            :disabled="loading"
            @click="$emit('cancel')"
          />
          <SControlButton
            mode="info"
            :label="t.a_submit"
            :loading="loading"
            @click="update"
          />
        </SControlRight>
      </SControl>
    </SCardBlock>
  </SCard>
</template>
