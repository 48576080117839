<script setup lang="ts">
import { uniq } from 'lodash-es'
import SAlert from 'sefirot/components/SAlert.vue'
import SButton from 'sefirot/components/SButton.vue'
import { useLang } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type OpportunityFileRequest } from '@/graph/Opportunity'
import { useOpportunityFileRequestOps } from '@/composables/ops/OpportunityOps'
import { useUserOps } from '@/composables/ops/UserOps'

const props = defineProps<{
  canEdit: boolean
  canRequest: boolean
  fileRequests: OpportunityFileRequest[]
}>()

defineEmits<{
  select: void
  request: void
}>()

const lang = useLang()

const userOps = useUserOps()
const requestOps = useOpportunityFileRequestOps()

const hasRequest = computed(() => {
  return props.fileRequests.length > 0
})

const requestedMemberNames = computed(() => {
  return uniq(props.fileRequests.map((request) => userOps.name(request.requestedBy)))
})

const latestRequestTimeText = computed(() => {
  const format = lang === 'en' ? 'MMM D, YYYY, HH:mm' : 'YYYY.MM.DD HH:mm'

  const latest = props.fileRequests.at(-1)

  if (!latest) {
    return ''
  }

  return requestOps.createdAt(latest).format(format)
})
</script>

<template>
  <SCardBlock class="OpportunitySideFilesEmpty">
    <div v-if="canEdit" class="can-edit">
      <STrans lang="en">
        <div v-if="hasRequest" class="s-flex s-flex-col s-gap-12">
          <SAlert mode="info"><p class="alert-text">Pitch Deck is requested.</p></SAlert>
          <SContent class="prompt-request">
            <p>Following members have requested Pitch Decks. Once uploaded, the requester will be notified.</p>
            <ul>
              <li v-for="name in requestedMemberNames" :key="name">{{ name }}</li>
            </ul>
          </SContent>
        </div>
        <div v-else class="can-edit-text">You may attach the pitch deck to be shared with the CVC’s LP. Additionally, you can indicate if there is no scheduled receipt of the document or leave free text.</div>
        <div class="can-edit-action">
          <SButton size="small" label="Add pitch decks" block @click="$emit('select')" />
        </div>
      </STrans>
      <STrans lang="ja">
        <div v-if="hasRequest" class="s-flex s-flex-col s-gap-12">
          <SAlert mode="info"><p class="alert-text">Pitch Deckがリクエストされています。</p></SAlert>
          <SContent class="prompt-request">
            <p>Pitch Deckを追加してください。アップロードするとリクエストした人に通知が届きます。現在リクエストしているのは、以下のメンバーです。</p>
            <ul>
              <li v-for="name in requestedMemberNames" :key="name">{{ name }}</li>
            </ul>
          </SContent>
        </div>
        <div v-else class="can-edit-text">CVCのLPへ共有するPitch Deckを添付できます。また、資料の受領予定がない旨や、テキストを残すこともできます。</div>
        <div class="can-edit-action">
          <SButton size="small" label="Pitch Deckを追加" block @click="$emit('select')" />
        </div>
      </STrans>
    </div>
    <div v-else-if="canRequest" class="can-request">
      <STrans lang="en">
        <div v-if="hasRequest" class="has-request">
          <SAlert mode="info"><p class="alert-text">Pitch Deck requested.</p></SAlert>
          <p class="can-request-text">You have requested pitch deck on {{ latestRequestTimeText }}. Once the pitch deck is added, a notification will be sent to you via Slack. If there is no response for a while, you may request it again.</p>
        </div>
        <div v-else class="can-request-text">A pitch deck has not been registered. You can request the document using the button below. When sending a request, you can also include a comment.</div>
        <div class="can-request-action">
          <SButton size="small" label="Request Pitch Deck" block @click="$emit('request')" />
        </div>
      </STrans>
      <STrans lang="ja">
        <div v-if="hasRequest" class="has-request">
          <SAlert mode="info"><p class="alert-text">Pitch Deckをリクエスト中です。</p></SAlert>
          <div class="can-request-text">
            <p>Pitch Deckを{{ latestRequestTimeText }}にリクエスト済みです。資料が追加されるとSlackに通知が送られます。しばらくしても反応がない場合、再度請求することもできます。</p>
          </div>
        </div>
        <div v-else class="can-request-text">Pitch deckが登録されていません。以下のボタンから、資料を請求できます。その際、コメントを添えて請求することもできです。</div>
        <div class="can-request-action">
          <SButton size="small" label="Pitch Deckを請求" block @click="$emit('request')" />
        </div>
      </STrans>
    </div>
    <div v-else class="empty">
      <STrans lang="en">Not registered</STrans>
      <STrans lang="ja">未登録</STrans>
    </div>
  </SCardBlock>
</template>

<style scoped lang="postcss">
.can-edit,
.can-request {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  line-height: 20px;
  font-size: 12px;
  color: var(--c-text-2);
}

.has-request {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.alert-text {
  color: var(--c-text-1);
}

.prompt-request {
  gap: 4px;
}

.empty {
  padding: 20px 24px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--c-text-2);
}
</style>
