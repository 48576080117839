<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

const { t } = useTrans({
  en: {
    title: 'Fund Reports'
  },
  ja: {
    title: '業務報告書'
  }
})
</script>

<template>
  <DView class="FundReports" :title="t.title" />
</template>
