<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { type CompanyCautionNote } from '@/graph/Company'
import { useCompanyItem } from '@/composables/company/CompanyData'
import { useCompanyPolicy } from '@/composables/company/CompanyPolicy'
import { useCompanyCautionNoteItem, useCompanyForDetailsItem } from '@/composables/repos/CompanyRepo'
import ActionNoteList from '@/components/action-note/ActionNoteList.vue'
import CompanyCardAddCautionNote from '@/components/company/CompanyCardAddCautionNote.vue'
import CompanyClaimCard from '@/components/company/CompanyClaimCard.vue'
import CompanyDescBusiness from '@/components/company/CompanyDescBusiness.vue'
import CompanyDescCautionNote from '@/components/company/CompanyDescCautionNote.vue'
import CompanyDescExecutive from '@/components/company/CompanyDescExecutive.vue'
import CompanyDescInfo from '@/components/company/CompanyDescInfo.vue'
import CompanyDescName from '@/components/company/CompanyDescName.vue'
import CompanyDescStatus from '@/components/company/CompanyDescStatus.vue'
import CompanyDescSystem from '@/components/company/CompanyDescSystem.vue'
import CompanyInfoAssignees from '@/components/company/CompanyInfoAssignees.vue'
import CompanyInfoLegalAssignees from '@/components/company/CompanyInfoLegalAssignees.vue'
import CompanyNewsList from '@/components/company-news/CompanyNewsList.vue'
import DealList from '@/components/deal/DealList.vue'
import OpportunityList from '@/components/opportunity/OpportunityList.vue'

const route = useRoute()
const companyId = Number(route.params.id)

const { data: company, execute } = useCompanyItem(companyId, {
  catch: [401, 404]
})
const { data: companyForDetails } = useCompanyForDetailsItem(Number(route.params.id))
const { data: companyCautionNote } = useCompanyCautionNoteItem(Number(route.params.id))

const { canView, canClaim, canUpdate, canViewLegalAssignee } = useCompanyPolicy(company)

const hideClaimCard = ref(false)

const showClaimCard = computed(() => {
  return canClaim.value && (company.value?.actionStatus === 'Approachable')
})

function closeClaimCard() {
  hideClaimCard.value = false
}

function onCautionNoteUpdated(cautionNote?: CompanyCautionNote) {
  companyCautionNote.value = cautionNote ?? null
}
</script>

<template>
  <DPage class="CompaniesIdProfile" :title="company?.name" :authorize="canView">
    <div class="board">
      <div v-if="showClaimCard && !hideClaimCard" class="claim">
        <CompanyClaimCard
          :company="company!"
          @updated="execute"
          @close="closeClaimCard"
        />
      </div>
      <div class="grid">
        <CompanyDescCautionNote
          v-if="companyCautionNote"
          :company-caution-note="companyCautionNote"
          :can-edit="!!canUpdate"
          @updated="onCautionNoteUpdated"
          @deleted="onCautionNoteUpdated"
        />
        <CompanyDescStatus v-if="companyForDetails" :company="companyForDetails" />
        <CompanyDescName :company="company!" @updated="execute" />
        <CompanyDescInfo :company="company!" @updated="execute" />
        <CompanyDescBusiness :company="company!" :can-update @updated="execute" />
        <CompanyDescExecutive :company="company!" :can-update @updated="execute" />
        <CompanyDescSystem :company="company!" />
      </div>
      <div class="grid">
        <CompanyInfoAssignees :company="company!" @updated="execute" />

        <CompanyInfoLegalAssignees v-if="canViewLegalAssignee" :company="company!" />

        <CompanyNewsList
          :company="(company as any)"
        />

        <ActionNoteList
          :action-notes="company!.actionNotes"
          :company="company!"
          @created="execute"
          @updated="execute"
          @deleted="execute"
        />

        <OpportunityList
          :opportunities="company!.opportunities"
          :company="company!"
        />

        <DealList
          :deals="company!.dealsThroughOppo()"
          :opportunity="null"
        />

        <CompanyCardAddCautionNote
          v-if="canUpdate && companyCautionNote === null"
          :company-id="company!.id!"
          @added="onCautionNoteUpdated"
        />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.CompaniesIdProfile {
  padding: 32px 32px 128px;
}

.board {
  display: grid;
  grid-template-columns: minmax(664px, 768px) minmax(344px, 1fr);
  gap: 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 1216px;
}

.claim {
  grid-column: span 2;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
