<script setup lang="ts">
import { computedWhen } from 'sefirot/composables/Utils'
import { useRoute } from 'vue-router'
import { useDealItemForSettings } from '@/composables/deal/DealData'
import { useDealPolicy } from '@/composables/deal/DealPolicy'
import DealPanelSettings from '@/components/deal/DealPanelSettings.vue'

const route = useRoute()

const { data: deal, execute } = useDealItemForSettings(Number(route.params.id), { catch: [401, 404] })

const { canUpdate } = useDealPolicy(deal)

const title = computedWhen(deal, (d) => {
  const company = d.companyThroughRound()!
  const oppo = d.opportunityThroughRound()!

  return `${company.name} - ${oppo.title} - ${d.title} - Settings`
})
</script>

<template>
  <DPage class="DealsIdSettings" :title="title" :authorize="canUpdate">
    <div class="container">
      <div class="box">
        <DealPanelSettings :deal="deal!" @updated="execute" />
      </div>
    </div>
  </DPage>
</template>

<style scoped lang="postcss">
.DealsIdSettings {
  padding: 32px 32px 128px;
}

.box {
  margin: 0 auto;
  max-width: 768px;
}
</style>
