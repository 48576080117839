import { type Query, useQuery } from 'sefirot/composables/Api'
import { type Http } from 'sefirot/http/Http'
import { type Country } from '@/graph/Country'
import { useCache } from '@/stores/Cache'

export type CountryList = Query<Country[]>

export function useCountryList(): CountryList {
  return useQuery(fetchCountryList)
}

export function useCountryListCache(): CountryList {
  const cache = useCache()
  return useQuery(async (http) => {
    return cache.remember('repo-country-country-list', () => fetchCountryList(http))
  })
}

async function fetchCountryList(http: Http): Promise<Country[]> {
  return http.get<Country[]>('/api/countries')
}
