import { type BallotVoteChoice } from '@/graphql'
import { BallotVoter } from './BallotVoter'
import { Model } from './Model'

export class BallotVote extends Model {
  ballotId?: number
  voterId?: number
  voter: BallotVoter | null
  choice?: BallotVoteChoice
  condition?: string | null
  comment?: string | null

  constructor(data: Record<string, any>) {
    super()
    this.ballotId = data.ballotId
    this.voterId = data.voterId
    this.voter = this.hasOne(BallotVoter, data.voter)
    this.choice = data.choice
    this.condition = data.condition
    this.comment = data.comment
  }
}
