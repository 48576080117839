import qs from 'qs'
import { type Plugin } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/routes'

export const router: Plugin = {
  install(app) {
    const r = createRouter({
      history: createWebHistory(),

      routes,

      parseQuery(search) {
        return qs.parse(search) as any
      },

      stringifyQuery(query) {
        return qs.stringify(query, { encodeValuesOnly: true })
      },

      scrollBehavior(_to, _from, savedPosition) {
        return savedPosition || { top: 0 }
      }
    })

    app.use(r)
  }
}
