<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'
import { computed } from 'vue'
import { type Fund } from '@/graph/Fund'
import { type FundStats } from '@/graph/FundStats'
import { useFundStatsOps } from '@/composables/ops/FundStatsOps'
import StatBlock from '../stat/StatBlock.vue'
import StatBlockHeader from '../stat/StatBlockHeader.vue'
import StatBlockValue from '../stat/StatBlockValue.vue'

const props = defineProps<{
  fund: Fund
  stats: FundStats
}>()

const { t } = useTrans({
  en: {
    title: 'Invested',
    deployment_rate_label: 'Deployment rate'
  },
  ja: {
    title: '投資総額',
    deployment_rate_label: '投資進捗率'
  }
})

const statOps = useFundStatsOps()

const deploymentRate = computed(() => {
  if (!props.fund.investableAmount) {
    return 0
  }
  return Number(props.stats.investedAmountJpy) / Number(props.fund.investableAmount) * 100
})
</script>

<template>
  <StatBlock>
    <StatBlockHeader :title="t.title" currency="JPY" />
    <StatBlockValue :value="statOps.investedAmountJpyAbbr(stats) ?? '???'">
      <template #help>
        <div class="progress">
          <div>{{ t.deployment_rate_label }}</div>
          <div class="bar">
            <div class="bar-denom">
              <div class="bar-num" :style="{ width: `${Math.min(deploymentRate, 100)}%` }" />
            </div>
          </div>
          <div>{{ deploymentRate.toFixed(0) }}%</div>
        </div>
      </template>
    </StatBlockValue>
  </StatBlock>
</template>

<style scoped lang="postcss">
.progress {
  display: flex;
  gap: 12px;
  width: 100%;
}

.bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.bar-denom {
  border-radius: 4px;
  width: 100%;
  height: 4px;
  background-color: var(--c-bg-mute-1);
}

.bar-num {
  border-radius: 4px;
  height: 100%;
  background-color: var(--c-fg-info-1);
}
</style>
