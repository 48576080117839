import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCache = defineStore('cache', () => {
  const data = ref<Record<string, any>>({})
  const pendingList: Record<string, Promise<any> | undefined> = {}

  /**
   * Get existing data from the cache if the key exists, else call the function
   * and store the result in the cache. It will block multiple calls to the
   * same key until the first call is resolved, and return the same result
   * for all calls.
   */
  async function remember<T>(key: string, fn: () => Promise<T>): Promise<T> {
    if (data.value[key]) {
      return data.value[key]
    }

    if (pendingList[key]) {
      return pendingList[key]
    }

    pendingList[key] = fn().then((result) => {
      data.value[key] = result
      delete pendingList[key]
      return result
    })

    return pendingList[key]
  }

  return {
    data,
    remember
  }
})
