<script setup lang="ts">
import IconGear from '~icons/ph/gear-bold'
import IconListBullets from '~icons/ph/list-bullets-bold'
import { computedArray } from 'sefirot/composables/Utils'
import { useCanEditDealSettings } from '@/composables/policies/DealPolicy'
import { useMyAssignedFundGroupTypes } from '@/composables/repos/FundRepo'

const { data: fundGroupTypes } = useMyAssignedFundGroupTypes()
const canEdit = useCanEditDealSettings(fundGroupTypes)

const menu = computedArray((items) => {
  if (canEdit.value.ok) {
    items.push([{ icon: IconListBullets, label: 'Deals', link: '/deals', match: 'exact' }])
    items.push([{ icon: IconGear, label: 'Settings', link: '/deals/settings' }])
  }
})
</script>

<template>
  <DView
    class="Deals"
    title="Deals"
    :menu="menu"
  />
</template>
