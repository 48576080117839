import { type UserFrag } from '@/graphql'
import { type Authorized, type Resource, usePolicy } from '../Policy'

export interface UserProfilePolicy {
  canView: Authorized
}

export function useUserProfilePolicy(user?: Resource<UserFrag>): UserProfilePolicy {
  const { defineWhen } = usePolicy()

  const canView = defineWhen(user, (me, u) => (me.hasRoleGod() || me.hasRoleGeneralPartner() || me.id === u.id))

  return {
    canView
  }
}
