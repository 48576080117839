<script setup lang="ts">
import { useTrans } from 'sefirot/composables/Lang'

defineProps<{
  open: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const { t } = useTrans({
  en: {
    close: 'Close'
  },
  ja: {
    close: '閉じる'
  }
})
</script>

<template>
  <DModal size="small" :open="open" @close="$emit('close')">
    <DCard :header="false">
      <DCardClose @close="$emit('close')" />

      <DForm>
        <DFormSection>
          <STrans lang="en">
            <DFormTitle>Deal report saved</DFormTitle>
            <DFormMedium>
              <p>If there are no further updates to the investment report, please request a review from the fund management team. After the fund management team has reviewed it and there are no issues, the deal will be completed.</p>
            </DFormMedium>
          </STrans>
          <STrans lang="ja">
            <DFormTitle>投資報告書を保存しました</DFormTitle>
            <DFormMedium>
              <p>投資報告書にこれ以上の更新がない場合、ファンド管理チームへレビューを依頼してください。ファンド管理チームがレビューした後、問題がなければディールがクローズされます。</p>
            </DFormMedium>
          </STrans>
        </DFormSection>
      </DForm>

      <DCardFooter>
        <DCardFooterActions>
          <DCardFooterAction
            :label="t.close"
            @click="$emit('close')"
          />
        </DCardFooterActions>
      </DCardFooter>
    </DCard>
  </DModal>
</template>
