import {
  CompanyBusinessReportFinancialFiguresUnitsDocument,
  type CompanyBusinessReportFinancialFiguresUnitsQuery,
  type CompanyBusinessReportFinancialFiguresUnitsQueryVariables
} from '@/graphql'
import { Request } from './Request'

export default class CompanyBusinessReportFinancialFiguresUnitRequest extends Request {
  fetchAll() {
    return this.request<CompanyBusinessReportFinancialFiguresUnitsQuery, CompanyBusinessReportFinancialFiguresUnitsQueryVariables>({
      query: CompanyBusinessReportFinancialFiguresUnitsDocument
    })
  }
}
